<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
        <v-col sm="4" class="mt-4 mb-2">
            <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('modules.procedures.table.search')"
                outlined
                clearable
                class="superTable__search"
            ></v-text-field>
        </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          show-select
          :search="search"
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="examRequestId"
          class="superTable__table"
          style="table-layout: fixed"
        >

          <template v-slot:top>
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                <span
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  @click="activeDialog = !activeDialog"
                >
                  <v-icon size="22" class="mr-2"> mdi-camera-metering-center </v-icon>
                  {{$t('modules.exam_enabler.update.tableTop.description')}}
                </span>
              </div>
              <v-divider></v-divider>
            </div>

            <v-dialog
            v-model="activeDialog"
            max-width="500px"
            >
              <v-card class="pa-5">
              <v-card-title>
                {{$t('modules.exam_enabler.update.modal.bulkProctoring.title')}}
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="proctoringType"
                  :items = "proctoringTypes"
                  item-text="meaning"
                  item-value="value"
                  :label="$t('modules.exam_enabler.update.headers.proctoring')"
                  return-object
                  outlined
                ></v-select>
              </v-card-text>
              <v-card-actions class="justify-space-between">
                <Button
                  :text="$t('actions.cancel')"
                  @clicked="cancelDialog"
                  outlined
                  white
                />
                <Button
                  :text="$t('actions.save')"
                  @clicked="updateProctoring"
                  :disabled="!proctoringType"
                />
              </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.reference`]="{item}" class="reference-column">
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" size="12" :color="item.type !== 'LAB' ? 'indigo' : 'orange'">mdi-circle</v-icon>
              </template>
              <span>{{ item.type !== 'LAB' ? $t('modules.exam_enabler.update.references_card.references.notebooks_exam') : $t('modules.exam_enabler.update.references_card.references.laboratory_exam') }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.proctoring`]="{item}">
            <div class="text-no-wrap">
              <v-select
                v-model="item.proctoringType"
                :label="$t('modules.exam_enabler.update.headers.proctoring')"
                :items = "proctoringTypes"
                item-text="meaning"
                item-value="value"
                @change="popUpProctoring(item, item.proctoringType)"
                outlined
              />
            </div>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <div class="text-no-wrap">
              <v-icon color="red" v-if="item.error || !item.ueExamId">mdi-alert-circle</v-icon>
              <v-icon color="green" v-else>mdi-check-circle</v-icon>
              <span class="ml-1 text-muted">{{ item.error || !item.ueExamId ? $t('modules.exam_enabler.update.items.error') : $t('modules.exam_enabler.update.items.generated') }}</span>
            </div>
          </template>

          <template v-slot:[`item.exam_state`]="{item}">
            <div class="d-flex align-center justify-center">
              <Button
                v-if="item.error || !item.ueExamId"
                :loading="item.generating"
                class="my-3"
                size="sm"
                :text="$t('modules.exam_enabler.update.items.button_actions.generate')"
                outlined
                @clicked="generateExam(item)"
              ></Button>
                <!-- v-else-if="!item.enabled && !item.hasReason" -->
              <Button
                v-else-if="!item.enabled && !item.hasReason"
                class="my-3"
                colorProp="success"
                size="sm"
                :text="$t('modules.exam_enabler.update.items.button_actions.enable')"
                cancelable
                :progressText="$t('modules.exam_enabler.update.items.button_actions.enabling')"
                @clicked="changeState(item)"
              ></Button>
              <Button
                v-else-if="item.enabled && item.score === null"
                class="my-3"
                colorProp="error"
                size="sm"
                :text="$t('modules.exam_enabler.update.items.button_actions.disable')"
                @clicked="openModal(item, 'disabler')"
              ></Button>
              <div v-if="item.enabled && item.score !== null" class="font-weight-bold mr-2 my-2">{{ item.score }}</div>
              <div v-else-if="!item.enabled && item.hasReason" class="d-flex my-3">
                <span class="red--text mr-3 align-self-center">{{ $t('modules.exam_enabler.update.items.states.disabled') }}</span>
                <Button
                  outlined
                  size="sm"
                  icon="mdi-information"
                  isIcon
                  @clicked="openModal(item, 'information')"
                ></Button>
              </div>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3" class="my-8">
          <span class="caption d-inline-block">{{ firstItemShow + ' - ' + lastItemShow + ' de ' + items.length }}</span>
          <!--<v-select
                  v-model="itemsPerPage"
                  :items="itemsPerPageAvailable"
                  :placeholder="''+itemsPerPage"
                  outlined
                  class="superTable__paginationSelect d-inline-block ml-4"
          ></v-select>-->
      </v-col>
      <v-col sm="6" class="text-center">
          <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
          <v-text-field
            v-model="page"
            :max="pageCount"
            outlined
            class="superTable__paginationInput d-inline-block mr-4"
          ></v-text-field>
          <span class="caption d-inline-block">{{$t('modules.procedures.table.goToPage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
  .reference-column
    width: 3rem
  .v-expansion-panels
    .v-expansion-panel-content
        padding-left: 10px
        padding-bottom: 0px
        padding-right: 16px
</style>

<script>
import Button from '@/components/Button/Button'
import {$types, $exams} from '../../Services'
export default {
  name: 'SuperTable',
  components: {
    Button,
  },
  props: {
    items: Array,
    headers: Array,
  },
  data(){
    return {
      activeDialog:false,
      search: '',
      page: 1,
      itemsPerPage: 10,
      proctoringTypes:[],
      proctoringType:'',
      selectedItems:[]
    }
  },
  computed: {
    pageCount(){
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    firstItemShow(){
      return this.itemsPerPage * (this.page-1) + 1;
    },
    lastItemShow(){
      return this.itemsPerPage * this.page < this.items.length ? this.itemsPerPage*this.page : this.items.length;
    },
    areItemsSelected() {
      return !!this.selectedItems.length;
    },
  },
  methods: {
    changeState(item){
      this.$emit('changeState', item)
      // item.status = 'in_progress'
    },
    generateExam(item) {
      this.$set(item, 'generating', true)
      this.$emit('generateExam', item)
    },
    openModal(data, modalType){
      this.$emit('openModal', {modalType, ...data})
    },
    async getProctoring(){
      const { data } = await $types.find(null, null, { params: { type: 'PROCTORING_TYPE'}})
      this.proctoringTypes = data
    },
    popUpProctoring( item ){
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de cambiar el proctoring?',
        content: [{value: item.name }],
        actionPrimary: {text: 'Aceptar', callback: () => this.updateProctoring(item)},
        actionSecondary: {text: 'Cancelar', callback: () => { this.cancelChange(item) }},
        icon: { color: 'warning', name: 'mdi-alert' },
        color: 'primary'
      });
    },
    async updateProctoring(item){
      let listIdExams = []
      if(this.selectedItems.length){
        listIdExams = this.selectedItems.map( data => data.examRequestId )
      }else{
        listIdExams.push(item.examRequestId)
      }
      const body = {
        proctoringType : item?.proctoringType || this.proctoringType.value,
        requestIds: listIdExams
      }
      try {
        await $exams.update(null, body, null, 'students/requests/proctoring')
        this.$store.dispatch('commons/openPopUp', {
          title: 'Los cambios se generaron con éxito',
          actionPrimary: {text: this.$t('actions.accept'), callback() {}},
          icon: { color: 'success', name: 'mdi-check' },
          color: 'primary',
        })
        this.$emit('updateView')
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: error.codeMeaning,
          actionPrimary: { text: 'Aceptar', callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        })
        throw error
      } finally{
        this.cancelDialog()
      }
    },
    cancelChange(item){
     if( item.proctoringType === this.proctoringTypes[1].value){
       item.proctoringType = this.proctoringTypes[0].value
     }else{
       item.proctoringType = this.proctoringTypes[1].value
     }
    },
    cancelDialog(){
      this.activeDialog = false
      this.selectedItems = []
      this.proctoringType = null
    }
  },
  mounted () {
    this.getProctoring()
  },
};
</script>