import ExamEditor from '../ExamEditor';
import ExamEditorTable from '../Views/ExamEditorTable'

const ExamEditorRoute =
{
  path: '/exam-editor',
  meta: {
    name: {
      en: 'Exam editor',
      es: 'Edición de exámenes',
    }
  },
  component: ExamEditor,
  children: [
    {
      path: '/',
      component: ExamEditorTable,
      meta: { name: {en: 'Index', es: 'Lista'}, group: 'portaladministrativo.exams', path: 'editor', permission: 'READ' }   
    }
  ]
};

export default ExamEditorRoute