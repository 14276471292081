<template>
  <v-dialog :value="open" @input="closeDialog" max-width="600px" class="dialog">
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">{{text}}</v-card-text>
          <v-card-text v-if="showList" class="mb-2 text-wrap"> 
            <p> {{ list.first_item }} </p>
            <p> {{ subitem.first_text }} </p>
            <p> {{ list.second_item }} </p>
            <p> {{ subitem.second_text }} </p>
            <p> {{ list.third_item }} </p>
            <p> {{ list.quarter_item }} </p>
            <p> {{ list.fifth_item }} </p>
            <p> {{ list.sixth_item }} </p>
            <p> {{ list.seventh_item }} </p>
            <p> {{ list.eighth_item}} </p>
          </v-card-text>
          <v-card-text>{{footer}}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end px-4 py-4">
        <Button :text="$t('actions.cancel')" white @clicked="closeDialog" class="mr-4" />
        <Button dark v-if="showButton" :text="$t('actions.accept')" @clicked="acceptCondition" class="mr-4" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button/Button.vue';

export default {
  name: 'ModalPP',
  props: {
    open: { type: Boolean, default: false },
    title: {type: String},
    text: {type: String},
    showButton: {type: Boolean},
    list: {type: Object},
    showList: {type: Boolean},
    footer: {type: String},
    subitem: {type: Object},
  },
  components: {
    Button,
  },
  data() {
    return {
      loading: false,
      email: null,
      selectedOption: null,
      shareButton: {
        loading: false,
        success: false,
        error: false,
      },
    }
  },
  methods: {
    acceptCondition(){
      this.$emit('changeValue')
    },
    closeDialog () {
      if (this.open) {
        this.$emit('close');
      }
    },
  },
  computed: {
  },
}
</script>

<style lang="sass" scoped>
  .dialog
    width: 400px
    height: 250px
</style>
