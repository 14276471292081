<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.portalalumno.procedures.reinstatement.title')"></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <template v-if="!createReinstatement">
            <p class="body-2 mb-10">{{ $t('modules.portalalumno.procedures.reinstatement.dataSection.subtitle') }}</p>

               <v-row>
                  <v-col cols="12" sm="6" >
                    <v-autocomplete
                        class="mb-4"
                        v-model="selectedCareers"
                        @change="getCareers"
                        :items="careers"
                        :label="$t('modules.portalalumno.procedures.download.autocompleteCareer.label')"
                        item-text="careerName"
                        item-value="studentCareer"
                        return-object
                        hide-details
                        outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex justify-end">
                      <Button
                        white
                        :text="$t('actions.cancel')"
                        @clicked="$router.push('/procedures')"
                        class="mr-4"
                      ></Button>
                      <Button
                        :loading="createButton.loading"
                        :success="createButton.success"
                        :error="createButton.error"
                        :disabled="!canCreate"
                        :text="$t('actions.apply')"
                        :successText="$t('actions.created')"
                        :errorText="$t('actions.error')"
                        @end="resetButtonValues"
                        @clicked="createReinstatementProcedure"
                      ></Button>
                  </v-col>
                </v-row>
          </template>
          <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import Button from '@/components/Button/Button';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { $studentProcessing } from '../services'
  import { mapGetters } from 'vuex'

  export default {
    name: 'reinstatement',
    components: {
        Breadcrumbs,
        Button,
        SuccessMessage
    },
    data () {
      return {
        selectedCareers: null,
        selectedCareer: null,
        careers: [],
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        createReinstatement: false,
        successMessage: {
          type: null,
          title: null,
          actionPrimary: null
        },
      }
    },
    computed: {
      canCreate() {
        return this.$permissions.portalalumno.procedures.REINSTATEMENT
      },
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentId: 'commons/getStudentId',
        studentUserId: 'commons/getStudentUserID',
        studentUserData: 'commons/getStudentUserData',
        userName: 'commons/getUserName',
      }),
    },
    methods: {
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      async createReinstatementProcedure() {
        try {
          this.createButton.loading = true
          await $studentProcessing.create({
            student: { id: this.studentId, user: { name: this.studentUserData.email } },
            studentRecord:  this.selectedCareer || this.studentRegister,
            processing: { key: 'REINCORPORATION' },
            userName: this.userName,
            userUpdateId: this.studentUserId ? this.studentUserId : null
          })
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.portalalumno.procedures.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.portalalumno.procedures.success_message.actions.accept'), callback: () => {this.$router.push('/procedures')} }
          this.createButton.success = true
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : 'Ocurrió un error solicitando el trámite';
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => {this.createReinstatement = false} }
          this.createButton.error = true
        } finally {
          this.createReinstatement = true
          this.createButton.loading = false
        }
      },
      async getCareers() {
        this.careers = this.$route.params.careers
        if(this.careers.length == 1){
          this.selectedCareers = this.careers[0].studentCareer
        }else if (this.careers.length > 1) {
          this.selectedCareer = this.selectedCareers?.studentCareer
        }
      },
    },
    mounted () {
      this.getCareers()
    }
  }
</script>
