import HistoryView from '../Views/HistoryView'
import PaymentsView from '../Views/PaymentsView';

const PaymentsRoute = [
        {
            path: '/',
            component: PaymentsView,
            name: 'Payments',
            props: true,
            meta: { name: 'Index', group: 'portalalumno.payments', path: 'payments_students', permission: 'READ' }
        }, {
            path: 'history',
            component: HistoryView,
            name: 'HistoryView',
            meta: { name: 'History', group: 'portalalumno.payments', path: 'payments_students', permission: 'READ' }
        }
    ];

export default PaymentsRoute
