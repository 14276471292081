<template>
  <div class="AsignatureSearch d-flex flex-column">
    <div class="title font-weight-bold primary--text px-6 pt-4 pb-2">{{$t('modules.careers.study_plan.drawer.search.title')}}</div>
    <div class="d-flex ">
      <v-text-field
        v-model="search"
        outlined
        rounded
        @keyup.enter="searchItems"
        @keyup="emptyValidation()"
        clearable
        @click:clear="searchItems"
        :placeholder="$t('modules.careers.study_plan.drawer.search.placeholder')"
        class="no-message flex-grow-0 pl-4"
      ></v-text-field>
      <v-btn class="ml-2 mr-4" fab small color="primary" outlined @click="searchItems">
        <v-icon dark>mdi-magnify</v-icon>
      </v-btn>
    </div>
      
      <draggable v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-throttle-delay="1000" class="AsignatureSearch__list px-4 pb-4 flex-grow-1" tag="div" :value="list" @change="emitChange" :group="{name:'foo'}">

            <VCard class="AsignatureSearch__card grey lighten-5 text-left py-2 pl-1 pr-3 my-2 d-flex" v-for="(item, index) in list" :key="index">
            
              <v-icon class="mr-2">mdi-drag-vertical</v-icon>
              <span class="caption">{{item.code}} - {{item.description}}</span>
            
            </VCard>
            
      <div class="text-center py-4">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="busy"
        ></v-progress-circular>
      </div>
      </draggable>
      
  </div>
</template>

<style scoped lang="sass">
.AsignatureSearch
  background: #ebebeb
  width: 25%
  height: auto
  .v-expansion
    &-panel
      min-height: 50px
      background-color: transparent
      &:before
        box-shadow: unset
      &:after
        border: 0
      &-header
        min-height: 50px
  &__list
    overflow-y: auto
    height: calc(100vh - 172px)
  &__card
    box-shadow: 0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06) !important
    transition: all 300ms ease
    &:hover, &:active
      z-index: 2
      box-shadow: 0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
      .v-icon
        opacity: 1
    .v-icon
      opacity: 0
</style>

<script>
  import MugenScroll from 'vue-mugen-scroll'
  import draggable from 'vuedraggable'
  import {$subjects}  from '../Services'

export default {
  name: 'AsignatureSearch',
  components:{
    draggable,
    MugenScroll //eslint-disable-line vue/no-unused-components
  },
  props: {
    items:Array,
    selectedItems: Array,
  },
  data(){return{
    loading: false,
    busy: false,
    nextIndex: 0,
    totalPages: null,
    search: '',
    searchConstant: ''
  }},
  methods:{
    emitChange(event){
      this.$emit('change', event);
    },
    handleScroll(){
      this.loading = true;
      this.loading = false;
    },
    emptyValidation(){
      if(this.search === ''){
        this.searchItems()
      }
    },
    searchItems(){
      this.nextIndex = 0;
      this.totalPages = null;
      this.searchConstant = this.search;
      if(!this.list.length)
        this.loadMore();
      this.list = [];
    },
    resetState(){
      this.nextIndex = 0;
      this.totalPages = null;
      this.search = '';
      this.searchConstant = '';
    },
    async loadMore() {
      try {

        if(this.totalPages != null)
          if(this.nextIndex > this.totalPages) {return;} //PREVENT TO MAKE MORE CALLS THAN PAGES
        
        if(this.busy) return; //PREVENT DUPLICATED CALLS
        
        this.busy = true;
        await this.fetchAsignatures();
        this.busy = false;
        // if(this.list.length < 10)
        //   this.loadMore()
      } catch (error) {
        this.loadMore();
      }
    },
    async fetchAsignatures(){
      //SAVE STATUS OF SEARCH IN THAT INSTANCE;
      const code = this.searchConstant.toUpperCase();
      const name = this.searchConstant.toUpperCase();
      
      //BASIC PARAMS
      let params = { page: this.nextIndex };
      
      //IF THERE IS A SEARCH ON GOING, ADD PARAMS
      if(code.length) params = {...params, code, name};

      //CALL TO API
      const subjects = await $subjects.find(null, null, {params})
      
      //SET TOTAL PAGES AND ADD 1 TO NEXT PAGE
      this.totalPages = subjects.data.totalPages;
      this.nextIndex += 1;

      this.list = [...this.list,...subjects.data.content];
      
    },
  },
  watch:{
    'list.length': function(length){
      if(this.totalPages != null)
        if(this.nextIndex > this.totalPages) return;
      if(length < 50){
        this.loadMore();
      }
    }
  },
  computed:{
    list:{
      set(items){
        this.$emit('update:items', items)
      },
      get(){
        let {items} = this;
        if(items === undefined){
          this.resetState();
          return [];
        }
        if(this.selectedItems.length)
          items = items.filter(subject => !this.selectedItems.find(id => id === subject.id));
        return items;
      }
    }
  }
}
</script>
