<template>
  <div fluid class="pl-3 pr-3 mt-8" >
    <v-row class="credit-banner" :style="borderStyle">
      <v-col cols="1" class="details">
        <img v-if="!isError" class="image" src="@/assets/images/portalalumno/cheque.png" alt="">
        <img v-else class="image" src="@/assets/images/portalalumno/cancelar.png" alt="">
      </v-col>

      <v-col cols="8" class="description justify-start">
        <p v-if="!isError" class="text-left">{{$t('modules.portalalumno.payments.checkingAccount.credits.control.confirm')}}</p>
        <p v-else class="text-left">{{$tc('modules.portalalumno.payments.checkingAccount.credits.control.failed', date, {date})}}</p>
      </v-col>
      <v-col cols="3">
        <p :class="{errorText: isError, rightText: !isError }" class="mb-0">{{points}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CreditsBanner',
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    points: {
      type: Number,
      required: false,
    },
    date: {
      type: String,
      required: false,
    }
  },
  data() {

  },
  computed: {
    borderStyle (_this) {
      if(_this.isError){
        return '--border:' + '#fc0000'
      }else {
        return '--border:' + '#81cc63'
      }
    }
  }

}
</script>

<style lang="sass" scoped>
.credit-banner
    font-family: 'Roboto'
    font-size: 14px
    font-weight: bold
    height: 78px
    width: 480px
    border-radius: 17px
    border: solid 1px var(--border)
    background-color: white
    align-items: center
    padding: 16px
    margin-bottom: 10px
.errorText
  font-size: 20px
  font-weight: bold
  text-align: left
  color: rgba(252, 0, 0, 0.85)
.rightText
  font-size: 20px
  font-weight: bold
  text-align: left
  color: rgba(129, 204, 99, 0.85)
.details
  display: flex
  flex-direction: column
.description
  display: flex
  align-self: center
  justify-content: center
  p
   margin-bottom: 0px
.image
  width: 27px
  height: 27px
</style>
