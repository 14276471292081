<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.prepaids.table.breadcrumbs.title')"
          :description="$t('modules.prepaids.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newPrepaid"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="prepaids"
              :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :deleteTitle="$t('modules.prepaids.table.actions.bulkDelete')"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @searchAction="searchAction"
              @bulk-delete="bulkDeletePopUp"
              @deleteData="deletePopUp"
              @updateData="updatePrepaid"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$prepaids} from '../Services';

export default {
  name: 'PrepaidsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      prepaids: [],
      sort: {
        sortBy: 'code',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.prepaids.table.headers.code'),
          value: 'code'
        }, {
          text: this.$t('modules.prepaids.table.headers.description'),
          value: 'description'
        }, {
          text: this.$t('modules.prepaids.table.headers.dayRange'),
          value: 'detail',
          sortable: false,
          align: 'center'
        }, {
          text: this.$t('modules.prepaids.table.headers.percentage'),
          value: 'percentage',
          sortable: false,
        }, {
          text: this.$t('modules.prepaids.table.headers.studentType'),
          value: 'studentType',
        }, {
          text: this.$t('modules.prepaids.table.headers.scholarship'),
          value: 'scholarship',
        }, {
          text: this.$t('modules.prepaids.table.headers.average'),
          value: 'average',
        }, {
          text: this.$t('modules.prepaids.table.headers.status'),
          value: 'status'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.prepaids.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.prepaids.READ || this.$permissions.portaladministrativo.prices.prepaids.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.prepaids.DELETE;
    },
  },
  methods: {
    deletePopUp(id) {
      const prepaid = this.prepaids.find(prepaid => prepaid.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: prepaid.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deletePrepaid(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.clearSelected = new Date().getMilliseconds().toString();
      this.loading = true;
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search
        };
        const prepaids = await $prepaids.find('list', null, {params});
        this.prepaids = prepaids.data.content.map(item => {
          item.percentage = item.detail ? item.detail.filter(e => e.percentage) : [];
          return item;
        });
        this.totalItems = prepaids.data.totalElements;
        this.pagination.pagesAmount = prepaids.data.totalPages;
      } catch (err) {
        this.prepaids = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    bulkDeletePopUp(prepaids) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: prepaids.map(prepaid => ({value: prepaid.description})),
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(prepaids)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async bulkDelete(prepaids) {
      const itemsForDelete = {
        ids: prepaids.map((prepaid) => {
          return prepaid.id;
        })
      };
      try {
        await $prepaids.bulkDelete(itemsForDelete);
        this.fetchData();
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: this.$t('modules.prepaids.table.messages.bulkDeleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw err;
      }
    },
    newPrepaid() {
      if (this.canCreate) this.$router.push('/prepaids/create');
    },
    updatePrepaid(id) {
      if (this.canUpdate) this.$router.push(`/prepaids/update/${id}`);
    },
    async deletePrepaid(id) {
      try {
        await $prepaids.delete(id);
        this.prepaids = this.prepaids.filter(prepaid => prepaid.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
