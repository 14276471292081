<template>
    <v-main>
        <p class="body-1 font-weight-regular ma-0 px-0 pt-2">{{ $t('modules.comisiones.dashboard.filters.subtitle')}} {{userData.name}}!</p>
        <h1 class="display-1 font-weight-bold ma-0 px-0 pt-2 pb-2">{{ $t('modules.comisiones.dashboard.filters.title') }}</h1>
        <v-row class="d-none d-md-flex pl-3">
            <v-col cols="12" sm="12" md="6" lg="2" class="pt-0 mr-3 px-0">
                <p class="text-uppercase h-6 font-weight-medium">{{ $t('modules.comisiones.dashboard.filters.selects.over.year') }}</p>
                <v-select
                    :items="years"
                    v-model="year"
                    label="Años"
                    prepend-inner-icon="mdi-calendar-text"
                    outlined
                    full-width
                    hide-details 
                    class=""                   
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2" class="pt-0 mr-3 px-0">
                <p   class="text-uppercase  h-6 font-weight-medium">{{ $t('modules.comisiones.dashboard.filters.selects.over.period') }}</p>
                <v-select
                    :items="termsList"
                    label="item"
                    v-model="term"
                    prepend-inner-icon="mdi-calendar-text"
                    outlined
                    flat
                    full-width
                ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2" class="pt-0 mr-3 px-0" v-show="sellers.length == 1 ? false : true">
                <p class="text-uppercase  h-6 font-weight-medium ">{{ $t('modules.comisiones.dashboard.filters.selects.over.agent') }}</p>
                <v-autocomplete
                    :items="sellers"
                    item-text="sellerCode"
                    :label=" $t('modules.comisiones.dashboard.filters.selects.all') "
                    v-model="selectedSellers"
                    prepend-inner-icon="mdi-map-marker"
                    outlined
                    full-width
                    multiple
                    clearable
                    return-object
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2" class="d-flex justify-center align-end pt-0 px-0">
                <v-btn 
                    @click="filter"
                    class="mb-md-2 text-uppercase caption"
                    block
                    height="44"
                    outlined
                    color="primary"
                >
                    <v-icon left>mdi-filter</v-icon>
                    {{ $t('modules.comisiones.dashboard.filters.selects.button') }}
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="2" class="d-flex justify-center align-center pt-6">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-5" v-bind="attrs" v-on="on" large icon color="primary" @click="requestConfirmation">
                            <v-icon>mdi-download</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('modules.comisiones.dashboard.filters.selects.download') }}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" large icon color="primary" @click="openDrawer">
                            <v-icon>mdi-history</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('modules.comisiones.dashboard.filters.selects.my_reports') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row class="d-md-none">
            <v-col cols="12">
                <v-btn icon class="ma-2" @click="expandFilter = !expandFilter"><v-icon>mdi-filter</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-expand-transition>
            <div class="mobileFilter" :style="expandFilter ? 'visibility: visible' : 'visibility: hidden'">
                <v-row class="py-3 px-6">
                    <v-col cols="12" class="d-flex align-center">
                        <span style="flex: 1">Filtros</span>
                        <span><v-btn icon @click="expandFilter = false"><v-icon>mdi-close</v-icon></v-btn></span>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            :items="years"
                            v-model="year"
                            label="Años"
                            prepend-inner-icon="mdi-calendar-text"
                            outlined
                            full-width
                            hide-details                    
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                            <v-select
                            :items="termsList"
                            label="item"
                            v-model="term"
                            prepend-inner-icon="mdi-calendar-text"
                            outlined
                            flat
                            full-width
                            ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            :items="sellers"
                            item-text="sellerCode"
                            :label=" $t('modules.comisiones.dashboard.filters.selects.all') "
                            v-model="selectedSellers"
                            prepend-inner-icon="mdi-map-marker"
                            outlined
                            full-width
                            multiple
                            clearable
                            :menu-props="{closeOnClick: true}"
                            return-object
                            ></v-autocomplete>
                    </v-col>
                    <v-col >
                            <v-btn 
                            @click="filter"
                            class="mt-md-8 text-uppercase caption"
                            block
                            height="44"
                            outlined
                            color="primary"
                        >
                            <v-icon left>mdi-filter</v-icon>
                            {{ $t('modules.comisiones.dashboard.filters.selects.button') }}
                            </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-expand-transition>
        <v-row v-if="showCard">
            <v-col lg="4" cols="12" class="mt-5">
                <v-card class="pa-2 d-flex flex-column justify-space-between" outlined tile elevation="1">
                    <v-card-subtitle class="pa-2  d-flex flex-row justify-space-between">
                        <p >{{ $t('modules.comisiones.dashboard.filters.cards.charge') }}</p>
                         <p>{{dateTotalToPay.from}} al {{dateTotalToPay.to}}</p>
                    </v-card-subtitle>
                    <v-card-title class="pl-2 font-weight-black display-2 pb-2">
                        {{ totalToPay | $currency }}
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col lg="4" cols="12" class="mt-5">
                <v-card class="pa-2 d-flex flex-column justify-space-between" outlined tile elevation="1">
                    <v-card-subtitle class="pa-2 d-flex flex-row justify-space-between">
                        <p>{{ $t('modules.comisiones.dashboard.filters.cards.charged') }}</p>
                        <p>{{dateTotalPayed.from}} al {{dateTotalPayed.to}}</p>
                    </v-card-subtitle>
                    <v-card-title class="pl-2 font-weight-black display-2 pb-2">
                        {{ totalPayed | $currency }}
                    </v-card-title>
                </v-card>
            </v-col>
            <v-col lg="4" cols="12" class="mt-5">
                <v-card class="pa-3 pb-5" outlined tile elevation="1">
                    <v-card-subtitle class="pl-2 pt-2 pb-1">
                        {{ $t('modules.comisiones.dashboard.filters.cards.variableCommissions.head') }}
                    </v-card-subtitle>
                    <v-card-title class="pl-2 py-2">
                        <h3>
                            {{ $t('modules.comisiones.dashboard.filters.cards.variableCommissions.content',{
                                salesNeedToPrize: prizesStatus.salesNeedToPrize,
                                extraPercentagePayment: prizesStatus.extraPercentagePayment,
                            }) }}
                        </h3>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
    import { $terms,$sellers, $sellersReporting } from '../Services/index';
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'Filters',
        props:{
            showCard: {type:Boolean,default:true}
        },
        data() {
            return {
                expandFilter: false,
                years: [],
                year: null,
                termsList: ['1A', '1B', '2A', '2B'],
                term: null,
                selectedSellers:  [],
                totalPayed: 0,
                totalToPay: 0,
                prizesStatus: {
                    extraPercentagePayment: 0,
                    completeSalesAmount: 0,
                    salesNeedToPrize: 0,
                    completeObjetivePercentage: 0
                },
                reports: null,
                sellers:  [],
                terms:  [],
                dateTotalPayed:{  
                            from: null,
                            to: null
                                },
                dateTotalToPay:{  
                            from: null,
                            to: null
                                },
                                

            }
        },
        computed: {
            ...mapGetters([
                'commons/getUserID',
                'commons/getUserRoles',
                'commons/getUserData'
            ]),
            userData(){
                       return this['commons/getUserData']
             },
            userId(){
                return this['commons/getUserID']
            },
            selectedTerm() {
                return this.terms.find(e => e.term == this.term && e.year == this.year)
            },
            isAdmin() {
                return !!this['commons/getUserRoles'].find(rol => {return rol.rolType.meaning == 'Administrador'})
            },
            selectedSellersId() {
                const sellers_id = this.selectedSellers.map(item => item.id)
                if (sellers_id.length) return sellers_id
                else if (this.isAdmin) return []
                else return this.sellers.map(item => item.id)
            }
        },
        async mounted () {
            await this.getSellers()
            await this.getTerms()
            this.filter()
        },
        methods: {
            openDrawer(){
                this.$emit('getCommissionReports')
            },
            async getSellers() {
                try {                    
                    const seller = await $sellers.find('users', null, { params: { user: this.userId } });
                    this.sellers = seller.data
                } catch (error) {
                    this.sellers = []
                }
            },
            async getTerms() {
                try {
                    const terms = await $terms.find(null, null, {params: {orderBy: 'saleDateFrom', orientation: 'desc', termType: 193}});
                    terms.data.content.forEach(term => {
                        const termCode = term.code.split('/')
                        const year = this.years.find(year => year.text == termCode[1])
                        if (Number(termCode[1]) >= 2020) {
                            if (!year) this.years.push({value: termCode[1], text: termCode[1]})
                            this.terms.push({ id: term.id, term: termCode[0], year: termCode[1] })
                        }

                        const saleFrom = moment(term.saleDateFrom)
                        const saleTo = moment(term.saleDateTo)
                        const now = moment()
                        if (now.isSameOrAfter(saleFrom) && now.isSameOrBefore(saleTo) && this.term === null) {
                            this.term = termCode[0]  // eslint-disable-line
                            this.year = termCode[1]  // eslint-disable-line
                        }
                    });
                } catch (error) {
                    this.terms = []
                    this.years = []
                    this.term = null
                    this.year = null
                    throw error
                }
            },
            async getPayingStatus() {
                try {
                    const payingStatus = await $sellers.create(this.selectedSellersId, {params: { term: this.selectedTerm.id }}, `payingStatus/${this.userId}`)
                    this.totalPayed = Math.trunc(payingStatus.data.totalPayed)
                    this.totalToPay = Math.trunc(payingStatus.data.totalToPay)
                    this.dateTotalPayed.from = moment(payingStatus.data.payedFrom).format('DD/MM/YYYY')
                    this.dateTotalPayed.to =  moment(payingStatus.data.payedTo).format('DD/MM/YYYY')
                    this.dateTotalToPay.from =  moment(payingStatus.data.payFrom).format('DD/MM/YYYY')
                    this.dateTotalToPay.to =  moment(payingStatus.data.payTo).format('DD/MM/YYYY')
                } catch (error) {
                    this.totalToPay = 0
                    this.totalPayed = 0
                    throw error
                }
            },
            async getPrizesStatus() {
                try {
                    const prizesStatus = await $sellers.create(this.selectedSellersId, {params: { term: this.selectedTerm.id }}, `prizesStatus/${this.userId}`)
                    this.prizesStatus = prizesStatus.data
                } catch (error) {
                    this.prizesStatus = {
                        extraPercentagePayment: 0,
                        completeSalesAmount: 0,
                        salesNeedToPrize: 0,
                        completeObjetivePercentage: 0
                    }
                    throw error
                } finally {
                    this.$emit('updatePrizes', this.prizesStatus)
                }
            },
            filter(){
                this.getPayingStatus()
                this.getPrizesStatus()
                this.$emit('updateFilters', {term: this.selectedTerm, sellers: this.selectedSellersId})
                this.expandFilter = false
            },
            requestConfirmation() {
                const periodo = `${this.selectedTerm.term}/${this.selectedTerm.year}`
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.reports.table.request.confirmationTitle', {term: periodo }),
                    content: null,
                    actionPrimary: { text: this.$t('actions.confirm'), callback: () => { this.download() }},
                    actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary'
                });
            },
            async download(){
                try {
                    const file = await $sellersReporting.create(this.selectedSellersId, {params: { term: this.selectedTerm.id }}, `report/${this.userId}`)
                    this.reports = file
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.reports.table.request.successTitle'),
                        content: [{value: this.$t('modules.reports.table.request.successContent')}],
                        actionPrimary: { text: this.$t('actions.accept'), callback() { }},
                        icon: { color: 'success', name: 'mdi-check' },
                        color: 'primary'
                    });
                } catch(error){
                     this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('actions.error'),
                        content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.reports.table.request.error')}],
                        actionPrimary: { text: this.$t('actions.accept'), callback() { }},
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                    });
                }
            }
        },
    }
</script>

<style lang="sass" scoped>
    .mobileFilter
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 9999
        background-color: #fff
    p.subtitleCardFilter
        font-size : 11px     
</style>