<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :title="$t('modules.settings.appearance.breadcrumbs.title')"
            :description="$t('modules.settings.appearance.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template v-if="!loading">
            <OutlinedCard :title="$t('modules.settings.appearance.sectionColors.title')" class="mb-6">
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionColors.primary')">
                <div class="mb-4">
                  <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <div class="d-flex align-center">
                        <v-sheet
                          :style="{background: colors.primary}"
                          height="60"
                          v-on="on"
                          width="60"
                          class="colorPicker"
                        ></v-sheet>
                        <span class="ml-6 caption grey--text">{{ colors.primary }}</span>
                      </div>
                    </template>
                    <v-color-picker v-model="colors.primary"></v-color-picker>
                  </v-menu>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionColors.secondary')">
                <div class="mb-4">
                  <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <div class="d-flex align-center">
                        <v-sheet
                          :style="{background: colors.secondary}"
                          height="60"
                          v-on="on"
                          width="60"
                          class="colorPicker"
                        ></v-sheet>
                        <span class="ml-6 caption grey--text">{{ colors.secondary }}</span>
                      </div>
                    </template>
                    <v-color-picker v-model="colors.secondary"></v-color-picker>
                    <!--Falta v-model -->
                  </v-menu>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionColors.accent')">
                <div class="mb-4">
                  <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on }">
                      <div class="d-flex align-center">
                        <v-sheet
                          :style="{background: colors.accent}"
                          height="60"
                          v-on="on"
                          width="60"
                          class="colorPicker"
                        ></v-sheet>
                        <span class="ml-6 caption grey--text">{{ colors.accent }}</span>
                      </div>
                    </template>
                    <v-color-picker v-model="colors.accent"></v-color-picker>
                    <!--Falta v-model -->
                  </v-menu>
                </div>
              </OutlinedCardContent>
            </OutlinedCard>
            <OutlinedCard :title="$t('modules.settings.appearance.sectionImages.title')" class="mb-6">
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionImages.logo')">
                <div class="mb-4">
                  <AvatarPreview
                    width="114"
                    height="64"
                    labelSize="14"
                    :model="images.logo"
                    :image="_self['previews']['logo']"
                    @save="(file)=>createImage(file,'logo')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent
                :label="$t('modules.settings.appearance.sectionImages.userDefault')"
              >
                <div class="mb-4">
                  <AvatarPreview
                    width="64"
                    height="64"
                    labelSize="14"
                    :model="images.userDefault"
                    :image="_self['previews']['userDefault']"
                    @save="(file)=>createImage(file,'userDefault')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionImages.portalAdministrativo')">
                <div class="mb-4">
                  <AvatarPreview
                    width="114"
                    height="64"
                    labelSize="14"
                    :model="images.portalAdministrativo"
                    :image="_self['previews']['portalAdministrativo']"
                    @save="(file)=>createImage(file,'portalAdministrativo')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionImages.portalAlumno')">
                <div class="mb-4">
                  <AvatarPreview
                    width="114"
                    height="64"
                    labelSize="14"
                    :model="images.portalAlumno"
                    :image="_self['previews']['portalAlumno']"
                    @save="(file)=>createImage(file,'portalAlumno')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionImages.comisiones')" v-if="this.$isTeclab">
                <div class="mb-4">
                  <AvatarPreview
                    width="114"
                    height="64"
                    labelSize="14"
                    :model="images.comisiones"
                    :image="_self['previews']['comisiones']"
                    @save="(file)=>createImage(file,'comisiones')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.appearance.sectionImages.examenes')">
                <div class="mb-4">
                  <AvatarPreview
                    width="114"
                    height="64"
                    labelSize="14"
                    :model="images.examenes"
                    :image="_self['previews']['examenes']"
                    @save="(file)=>createImage(file,'examenes')"
                  ></AvatarPreview>
                </div>
              </OutlinedCardContent>
            </OutlinedCard>
            <div class="mt-3 text-sm-right">
          <Button
            :loading="loading"
            :success="success"
            :error="error"
            :text="$t('actions.save')"
            :successText="$t('actions.saved')"
            textError="Error"
            :disabled="!canUpdate"
            @clicked="save()"
            @end="resetButtonValues()"
          ></Button>
        </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.colorPicker {
  border: 1px solid #ebebeb;
  cursor: pointer;
  border-radius: 50%;
}
</style>



<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
import AvatarPreview from '@/components/AvatarPreview/AvatarPreview'
import Button from '@/components/Button/Button'
import { mapGetters } from 'vuex'
import { $settings, $applications } from '../services'

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    AvatarPreview,
    Button
  },
  data () {    return {
      success: false,
      loading: false,
      error: false,
      colors: {
        primary: '#012169',
        secondary: '#012169',
        accent: '#012169',
      },
      images: {
        logo: null,
        userDefault: null,
        portalAdministrativo: null,
        portalAlumno: null,
        comisiones: null, 
        examenes: null,
      },
      imagePreviews: {
        logo: null,
        userDefault: null,
        portalAdministrativo: null,
        portalAlumno: null,
        comisiones: null, 
        examenes: null,
      }
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.system.UPDATE
    },
    
    previews () {

      const logo = this.imagePreviews.logo ? this.imagePreviews.logo : this.logoImage;
      const userDefault = this.imagePreviews.userDefault ? this.imagePreviews.userDefault : this.avatarDefault;
      const portalAdministrativo = this.imagePreviews.portalAdministrativo ? this.imagePreviews.portalAdministrativo : `${this.imageBase}portaladministrativo.png`;
      const portalAlumno = this.imagePreviews.portalAlumno ? this.imagePreviews.portalAlumno : `${this.imageBase}portalalumno.png`;
      const comisiones = this.imagePreviews.comisiones ? this.imagePreviews.comisiones : `${this.imageBase}comisiones.png`;
      const examenes = this.imagePreviews.examenes ? this.imagePreviews.examenes : `${this.imageBase}examenes.png`;
      
      return {
        logo,
        userDefault,
        portalAdministrativo,
        portalAlumno,
        comisiones,
        examenes
      }

    },
    ...mapGetters({
      imageBase: 'commons/getApplicationImageUrlBase',
      logoImage: 'commons/getLogoImageUrl',
      avatarDefault:'commons/getAvatarImageUrl',
      loginImage: 'commons/getApplicationImageUrl',
      })
  },
  methods: {
    async save () {
      if (!this.canUpdate) return false
      this.loading = true;
      const urls = {
        logo:'configurations/logo-image',
        userDefault:'configurations/default-avatar',
        portalAdministrativo: 'portaladministrativo',
        portalAlumno: 'portalalumno',
        comisiones: 'comisiones', 
        examenes:'examenes',
      }
      for (const [key,image] of Object.entries(this.images)) {
        if (image) {
        const formData = new FormData();
        formData.append('file', image);
        try {
          if (urls?.[key]?.includes('configurations')) {
            const splited = urls[key].split('configurations/');
            await $settings.formData(formData,splited[1])
            formData.append('url', this.$APP);
          } else {
            formData.append('url', urls?.[key]);
            await $applications.formData(formData,'image')
          }
          this.loading = false;
          this.images[key] = null;
        } catch {
          this.loading = false;
          this.images[key] = null;
          this.imagePreviews.logo = this.logoImage;
          this.imagePreviews.userDefault = this.avatarDefault;
          this.imagePreviews.portalAdministrativo = `${this.imageBase}portaladministrativo.png`;
          this.imagePreviews.portalAlumno = `${this.imageBase}portalalumno.png`;
          this.imagePreviews.comisiones = `${this.imageBase}comisiones.png`;
          this.imagePreviews.examenes = `${this.imageBase}examenes.png`;
          this.$store.dispatch('commons/openPopUp', {
            title: 'Error',
            content: [{ value: this.$t(`modules.settings.appearance.sectionImages.${key}`)}],
            actionPrimary: { text: this.$t('actions.accept'), callback () { } },
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary',
            });
        }
        }
      }
      
    },
    createImage (file, field) {
      if (!this.canUpdate) return false
      try {
        this.images[field] = file;
        const reader = new FileReader();

        reader.onload = (e) => {
          this.setImagePreview(e.target.result, field);
        };
        reader.onerror = () => {
          this.setImagePreview(null, field);
          reader.abort();
        };
        reader.readAsDataURL(this.images[field]);
      } catch (err) {
        this.setImagePreview(null, field);
      }
    },
    setImagePreview (image, field) {
      this.imagePreviews[field] = image;
    },
    resetButtonValues () {
      this.loading = false
      this.success = false
      this.error = false
    },

  },
}

</script>
