<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="500"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table examExceptionTable"
                    style="table-layout: fixed"
                    @click:row="clickRow"
                >
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<style lang="sass" scoped>
    .examExceptionTable table tbody tr
        cursor: pointer
</style>

<script>
    export default {
        name: 'SuperTable',
        props: {
            items: Array,
            headers: Array,
        },
        data () {
            return {
            }
        },
        methods: {
            clickRow(data){
                this.$emit('subjectSelection',data)
            }
        }
    };
</script>
