import Constants from './constants'
import AuthService from './auth.service'

export default {
  install (Vue) {
    Vue.prototype.$users = this;
  },
  newPassword (id) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/users/${id}/new-password`, Constants.getFetchOptions({}, Constants.HTTP_METHOD_POST))
        .then(data => {
          if (data.status == 200) {
            return data.json()
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  bulkDeleteRoles (items) {
    // /users/bulk
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/roles/bulk`, Constants.getFetchOptions(items, Constants.HTTP_METHOD_POST))
        .then(data => {
          if (data.status == 200) {
            return data.json()
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {

          reject(new Error(error.message));
        });

    });
  },
  bulkDeleteUsers (items) {
    // /users/bulk
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/users/bulk`, Constants.getFetchOptions(items, Constants.HTTP_METHOD_POST))
        .then(data => {
          if (data.status == 200) {
            return data.json()
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  getRoles () {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/roles?orderBy=id&orientation=desc&page=0&length=500`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  getUsers () {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/users`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  createRol (role) {
    const options = Constants.getFetchOptions(role, Constants.HTTP_METHOD_POST);
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/roles`, options)
          .then(data => data.json())
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  updateRole (role) {
    const options = Constants.getFetchOptions(role, Constants.HTTP_METHOD_PUT);
    return new Promise((resolve, reject) => {
      if (!role || !role.id || isNaN(role.id)) {
        reject(new Error('Invalid Role'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/roles/${role.id}`, options)
          .then(data => data.json())
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  getUserData (userName) {
    if (!userName) userName = JSON.parse(localStorage.getItem('user_name'));
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/users/by-name/${userName}`, Constants.getFetchOptions())
          .then(data => 
            // if (data.status === 401) AuthService.logout()
            // else data.json()
            data.json()
          )
          .then((res) => resolve(res))
      } else
        reject(new Error('Not logged in'));
    })
  },
  getStudentData (identification) {
    if (!identification) identification = JSON.parse(localStorage.getItem('user_name'));
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_STUDENT_SERVER}/student-by-identification?identification=${identification}`, Constants.getFetchOptions())
          .then(data => 
            // if (data.status === 401) AuthService.logout()
            // else data.json()
            data.json()
          )
          .then(res => resolve(res))
      } else
        reject(new Error('Not logged in'));
    })
  },
  getUserDataById (id) {
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER  }/users/${  id}`, Constants.getFetchOptions())
          .then(data => 
            // if (data.status === 401) AuthService.logout()
            // else data.json()
            data.json()
          )
          .then((res) => resolve(res))
      } else
        reject(new Error('Not logged in'));
    })
  },


  createUser (user) {
    const options = Constants.getFetchOptions(user, Constants.HTTP_METHOD_POST);
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/users`, options)
          .then(data => data.json())
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  sendAvatar (avatar, id) {
    const options = Constants.getFetchOptions(avatar, Constants.HTTP_METHOD_POST, 'multipart/form-data');
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        delete options.headers['Content-Type'];
        options.body = avatar;
        fetch(`${Constants.URL_DATA_SERVER}/users/${id}/avatar`, options)
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  updateUser (user) {
    const options = Constants.getFetchOptions(user, Constants.HTTP_METHOD_PUT);
    return new Promise((resolve, reject) => {
      if (!user || !user.id || isNaN(user.id)) {
        reject(new Error('Invalid user'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/users/${user.id}`, options)
          .then(data => {
            if (data.status == 200) {
              return data.json();
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  deleteUser (id) {
    const options = Constants.getFetchOptions(null, Constants.HTTP_METHOD_DELETE);
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('Invalid user'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/users/${id}`, options)
          .then(data => {
            if (data.status == 200) {
              return true;
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  deleteRol (id) {
    const options = Constants.getFetchOptions(null, Constants.HTTP_METHOD_DELETE);
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('Invalid user'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_DATA_SERVER}/roles/${id}`, options)
          .then(data => {
            if (data.status == 200) {
              return true;
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  getApps () {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/applications`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {

          reject(new Error(error.message));
        });

    });
  },
  getApplicationModules (applicationId) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/modules/permissions/${applicationId}`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {

          reject(new Error(error.message));
        });

    });
  },
  getRolInfo (rolId) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/roles/${rolId}`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {

          reject(new Error(error.message));
        });

    });
  },
  getRolWithPermissions (rolId) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_DATA_SERVER}/roles/${rolId}/permissions/`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  changePassword (userId) {
    return new Promise((resolve, reject) => {
        const body = {
            prevPassword : this.prevPassword,
            newPassword : this.newPassword
      }
      fetch(`${Constants.URL_DATA_SERVER}/users/${userId}/change-password`, Constants.getFetchOptions(body, 'POST', 'application/x-www-form-urlencoded'))
        .then(data => {
          if (data.status == 200)
            return (data.json());
          else reject(data)
        }).then(data => resolve(data))
        .catch(err => {
          reject(new Error(err.message));
        });
    });
  },


  /******************************************************************************************************************/
  /******************************************QUARTERS CONECTIONS TO ENDPOINT *******************************************/
  /******************************************************************************************************************/

  getQuarter (id) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos/${id}`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {

          reject(new Error(error.message));
        });

    });
  },
  getQuarters () {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos?page=0&length=500`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  createQuarter (role) {
    // TODO: (Diego) Change this to the new api
    const options = Constants.getFetchOptions(role, Constants.HTTP_METHOD_POST);
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos`, options)
          .then(data => {
            if (data.status == 201) {
              return data.json();
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  updateQuarter (quarter) {
    const options = Constants.getFetchOptions(quarter, Constants.HTTP_METHOD_PUT);
    return new Promise((resolve, reject) => {
      if (!quarter || !quarter.id || isNaN(quarter.id)) {
        reject(new Error('Invalid Role'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos/${quarter.id}`, options)
          .then(data => {
            if (data.status == 201) {
              return data.json();
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  bulkDeleteQuarters (items) {
    // /users/bulk
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos/bulk`, Constants.getFetchOptions(items, Constants.HTTP_METHOD_POST))
        .then(data => {
          if (data.status == 200) {
            return data.json()
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  deleteQuarter (id) {
    const options = Constants.getFetchOptions(null, Constants.HTTP_METHOD_DELETE);
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('Invalid user'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_ACADEMIC_SERVER}/ceos/${id}`, options)
          .then(data => {
            if (data.status == 200) {
              return true;
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  /******************************************************************************************************************/
  /************************************** END SEDES CONECTIONS TO ENDPOINT *******************************************/
  /******************************************************************************************************************/

  /******************************************************************************************/
  /* ZONES SERVICES */
  getZone (zoneId) {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_PRICING_SERVER}/zones/${zoneId}`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  getZones () {
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URL_PRICING_SERVER}/zones?length=2000`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  createZone (zone) {
    const options = Constants.getFetchOptions(zone, Constants.HTTP_METHOD_POST);
    return new Promise((resolve, reject) => {
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_PRICING_SERVER}/zones`, options)
          .then(data => {
            if (data.status == 201) {
              return data.json();
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  bulkDeleteZones (items) {
    // /users/bulk
    return new Promise((resolve, reject) => {
      if (!AuthService.checkUserLoggedIn()) {
        reject(new Error('Not logged in'));
      }
      fetch(`${Constants.URR_PRICING_SERVER}/zones/bulk`, Constants.getFetchOptions(items, Constants.HTTP_METHOD_POST))
        .then(data => {
          if (data.status == 200) {
            return data.json()
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  deleteZone (id) {
    const options = Constants.getFetchOptions(null, Constants.HTTP_METHOD_DELETE);
    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error('Invalid user'));
      }
      if (AuthService.checkUserLoggedIn()) {
        fetch(`${Constants.URL_PRICING_SERVER}/zones/${id}`, options)
          .then(data => {
            if (data.status == 200) {
              return true;
            } else {
              throw new Error(data.message);
            }
          })
          .then((res) => {
            resolve(res)
          }).catch(error => {
            reject(new Error(error.message));
          })
      } else
        reject(new Error('Not logged in'));
    })
  },
  regulationUser(studentId, type = 'GENERAL') {
    return new Promise((resolve, reject) => {
      fetch(`${Constants.URL_STUDENT_SERVER}/student/ppTermConditionStatus?studentId=${studentId}&type=${type}`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  },
  modulesBlocked(studentId) {
    return new Promise((resolve, reject) => {
      fetch(`${Constants.URL_STUDENT_SERVER}/sanction/blocked-modules?studentId=${studentId}&page=0&length=20`, Constants.getFetchOptions())
        .then(data => {
          if (data.status == 200) {
            return data.json();
          } else {
            throw new Error(data.message);
          }
        })
        .then((res) => {
          resolve(res)
        }).catch(error => {
          reject(new Error(error.message));
        });

    });
  }




  /*END ZONES SERVICES*/
  /******************************************************************************************/
  /******************************************************************************************/
  /*QUARTERS SERVICES*/
  /*
  getQuarters() {
      return new Promise((resolve, reject) => {
          if (!AuthService.checkUserLoggedIn()) {
              reject(new Error("Not logged in"));
          }
          fetch(`${Constants.URL_DATA_SERVER}/ceos`, Constants.getFetchOptions())
              .then(data => {
                  if (data.status == 200) {
                      return data.json();
                  } else {
                      throw new Error(data.message);
                  }
              })
              .then((res) => {
                  resolve(res)
              }).catch(error => {

              reject(new Error(error.message));
          });

      });
  },*/
}
