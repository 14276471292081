<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.pricing.referred_companies.create.breadcrumbs.title')"
        :description="$t('modules.pricing.referred_companies.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center" v-if="!createNewReferredCompanyStatus">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.pricing.referred_companies.create.codeSection.title')"
            :subtitle="$t('modules.pricing.referred_companies.create.codeSection.subtitle')"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            @switchChange="(val) => {this.status = val}"
            switcher
            class="mb-6"
          >
            <v-row class="mt-5">
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.name.$dirty && $v.name.$invalid) ?
                  ((!$v.name.required) ? $t('modules.pricing.referred_companies.validations.nameRequired') :
                  (!$v.name.alphabeticWithSpaces) ? $t('modules.pricing.referred_companies.validations.nameAlphabeticWithSpaces') :
                  $t('modules.pricing.referred_companies.validations.nameMaxLength')) :
                  ''"
                  :label="`${$t('modules.pricing.referred_companies.create.codeSection.labels.name')} *`"
                  ref="name"
                  outlined
                  @blur="$v.name.$touch()"
                  v-model="$v.name.$model"
                  @keypress="$validateAlphanumeric($event, $v.name.$model,60, true, true)"
                ></v-text-field>
              </v-col>
              <v-col sm="3">
                <v-text-field
                  :loading="loadingCuit"
                  :error-messages="($v.cuit.$dirty && $v.cuit.$invalid) ?
                  ((!$v.cuit.required) ? $t('modules.pricing.referred_companies.validations.cuitRequired') :
                  (!$v.cuit.cuitFormat) ? $t('modules.pricing.referred_companies.validations.cuitFormat') :
                  $t('modules.pricing.referred_companies.validations.cuitUnique')) :
                  ''"
                  :label="`${$t('modules.pricing.referred_companies.create.codeSection.labels.cuit')} *`"
                  ref="cuit"
                  v-mask="'##-########-#'"
                  outlined
                  v-model="$v.cuit.$model"
                  @blur="validateCuit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.contactName.$dirty && $v.contactName.$invalid) ?
                  ((!$v.contactName.required) ? $t('modules.pricing.referred_companies.validations.contactNameRequired') :
                  (!$v.contactName.alphabeticWithSpaces) ? $t('modules.pricing.referred_companies.validations.contactNameAlphabeticWithSpaces') :
                  $t('modules.pricing.referred_companies.validations.contactNameMaxLength')) :
                  ''"
                  :label="`${$t('modules.pricing.referred_companies.create.codeSection.labels.contactName')} *`"
                  ref="contactName"
                  outlined
                  @blur="$v.contactName.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.contactName.$model,60, true, true)"
                  v-model="$v.contactName.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.position.$dirty && $v.position.$invalid) ?
                  ((!$v.position.required) ? $t('modules.pricing.referred_companies.validations.positionRequired') :
                  (!$v.position.alphabeticWithSpaces) ? $t('modules.pricing.referred_companies.validations.positionAlphabeticWithSpaces') :
                  $t('modules.pricing.referred_companies.validations.positionMaxLength')) :
                  ''"
                  :label="`${$t('modules.pricing.referred_companies.create.codeSection.labels.position')} *`"
                  ref="position"
                  outlined
                  @blur="$v.position.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.position.$model,60, true, true)"
                  v-model="$v.position.$model"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6">
                <v-text-field
                  :error-messages="($v.email.$dirty && $v.email.$invalid) ?
                  ((!$v.email.required) ? $t('modules.pricing.referred_companies.validations.emailRequired') :
                  $t('modules.pricing.referred_companies.validations.emailFormat')) :
                  ''"
                  :label="`${$t('modules.pricing.referred_companies.create.codeSection.labels.email')} *`"
                  ref="email"
                  outlined
                  @blur="$v.email.$touch()"
                  v-model="$v.email.$model"
                ></v-text-field>
              </v-col>
              <v-col sm="6">
                <TelephoneInput
                  :value.sync="$v.cellphone.$model"
                  :required="$v.cellphone.$dirty && $v.cellphone.$invalid"
                  :placeholder="`${$t('modules.pricing.referred_companies.create.codeSection.labels.cellphone')} *`"
                  ref="cellphone"
                  @blur="$v.cellphone.$touch()"
                  @validate="isValidPhone"
                />
              </v-col>
            </v-row>
          </OutlinedCard>

          <div class="d-flex justify-end">
            <Button
              :text="$t('actions.cancel')"
              class="mr-4"
              white
              @clicked="$router.replace('/pricing/referred-companies')"
              @end="resetButtonValues"
            ></Button>
            <Button
              :loading="createButton.loading"
              :disabled="!canCreate"
              :text="$t('actions.create')"
              :success="createButton.success"
              :successText="$t('actions.created')"
              :error="createButton.error"
              :errorText="$t('actions.error')"
              @clicked="validateReferredCompany"
              @end="resetButtonValues"
            ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import {required, email, maxLength, helpers} from 'vuelidate/lib/validators';
import {$referredCompanies} from '../Services';

export default {
  name: 'ReferredCompaniesCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    TelephoneInput,
    SuccessMessage,
  },
  data() {
    return {
      name: '',
      contactName: '',
      email: '',
      cellphone: '',
      cuit: '',
      position: '',
      telephoneValid: true,
      status: true,
      cuitUnique: null,
      loadingCuit: false,
      loadingSearchProducts: false,
      loadingPaymentMethods: false,
      createNewReferredCompanyStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.referred_companies.CREATE;
    },
  },
  methods: {
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.cellphone.handleInput(this.cellphone, phoneObject)
    },
    async validateReferredCompany() {
      if (this.$v.$invalid) {
        await this.$v.$touch();
        this.$refs.cellphone.handleBlur()
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
            return true;
          }
        });
      } else {
        this.createReferredCompanyPopUp()
      }
    },
    createReferredCompanyPopUp () {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createReferredCompany() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.createReferredCompany();
      }
    },
    async createReferredCompany() {
      this.createButton.loading = true;
      try {
        const newCompany = {
          name: this.name.trim(),
          cuit: this.cuit,
          status: this.status,
          contactName: this.contactName,
          position: this.position,
          email: this.email,
          cellphone: this.cellphone,
        }

        await $referredCompanies.create(newCompany);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.pricing.referred_companies.create.successMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.close'), callback: () => {
            this.$router.push('/pricing/referred-companies');
          }
        };
        this.successMessage.actionSecondary = {
          text: this.$t('actions.create_another'), callback: () => {
            this.createNewReferredCompanyStatus = false;
          }
        };
        this.createNewReferredCompanyStatus = true;
        this.createButton.success = true;
        this.createButton.loading = false;
        this.clearData();
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.pricing.referred_companies.create.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewReferredCompanyStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewReferredCompanyStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    async validateCuit() {
      this.$v.cuit.$touch();
      if (this.$v.cuit.$model) {
        this.cuitUnique = null;
        this.loadingCuit = true;
        try {
          const response = await $referredCompanies.find('exists', null, {params: {cuit: this.$v.cuit.$model}});
          this.cuitUnique = !response.data.exists;
        } catch (error) {
          this.cuitUnique = null;
          throw error;
        } finally {
          this.loadingCuit = false;
          this.$v.cuit.$touch();
        }
      }
    },
    clearData() {
      this.resetButtonValues();
      this.name = null;
      this.cuit = null;
      this.status = true;
      this.contactName = null;
      this.position = null;
      this.cellphone = null;
      this.email = null;
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(60),
      alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
    },
    cuit: {
      required,
      cuitFormat: helpers.regex('cuitFormat', /^\d{2}-\d{8}-\d$/i),
      unique() {
        return this.cuitUnique != false;
      },
    },
    contactName: {
      required,
      maxLength: maxLength(60),
      alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
    },
    position: {
      required,
      maxLength: maxLength(60),
      alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
    },
    email: {
      required,
      email
    },
    cellphone: {
      valid() {
        return this.telephoneValid
      }
    }
  }
};
</script>
