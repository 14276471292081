<template>
  <v-container class=" pa-0 elevation-1 table-border">
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table :headers="headers" :items="data" :totalItems="totalItems" :items-per-page="itemsPerPage"
          :page.sync="page" :show-select="false" class="superTable__table table-background-color" hide-default-footer
          item-key="id" style="table-layout: fixed" v-model="selectedItems" :sort-by="sortBy" :sort-desc="sortDesc"
          @update:sort-by="
          $emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)">
          <template v-if="permissions.delete" v-slot:top>
            <div :class="[
              'superTable__bulkActions',
              'py-2',
              selectedItems.length ? 'show' : 'hide',
            ]">
              <v-divider></v-divider>
              <div class="py-3">
                <span @click="$emit('bulk-delete', selectedItems)" class="caption mx-4 cursor-pointer hover-7-inverted"
                  v-if="permissions.delete">
                  <v-icon class="mr-2" size="22">mdi-delete</v-icon>{{ deleteTitle }}
                </span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props" v-on="on">
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" :value="isSelected"
              @input="select($event)"></v-simple-checkbox>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{ item.description || 'IMAGEN' }}
          </template>

          <template v-slot:[`item.difficulty`]="{ item }">

            {{ item.difficulty }}

          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon size="12" :color="item.status ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-menu bottom left>
              <template v-if="permissions.update || permissions.delete" v-slot:activator="{ on }">
                <v-icon class="superTable__table__item__action" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="editItem(item.id)" v-if="permissions.update">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{ $t("actions.edit") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="assestmentPreview(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-eye</v-icon>
                    {{ $t("modules.exams_group.statements.actions.preview") }}
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item
                  @click="deleteItem(item.id)"
                  v-if="permissions.delete"
                >
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{ $t("actions.delete") }}
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>

        </v-data-table>

        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>

    <v-row align="end" no-gutters class="pa-4 pt-0">
      <v-col sm="3">
        <span class="caption d-inline-block ml-4">{{
            `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}`
        }}</span>
        <v-select :items="itemsPerPageAvailable" :placeholder="itemsPerPage.toString()"
          class="superTable__paginationSelect d-inline-block ml-4" outlined v-model="itemsPerPage"
          @change="$emit('length-pagination-change', itemsPerPage)"></v-select>
      </v-col>

      <v-col class="text-center" sm="6">
        <v-pagination v-model="page" :length="pageCount" @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)" @input="$emit('input-pagination', $event)"
          class="superTable__pagination"></v-pagination>
      </v-col>

      <v-col align="right" sm="3">
        <span class="caption d-inline-block mr-2">{{ $t("actions.goToPage") }}</span>
        <v-text-field class="superTable__paginationInput d-inline-block mr-4" max="pageCount" outlined
          v-model.number="goToPage" @blur="changePage"></v-text-field>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: 'TableStatements',
  props: {
    data: { type: Array },
    headers: { type: Array },
    clearSelected: String,
    deleteTitle: String,
    permissions: { delete: false, update: false },
    sortBy: String,
    sortDesc: Boolean,
    // pageCount: { type: Number, default: 1 },
    // page: { type: Number, default: 1 },
    totalItems: Number
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      timeout: null,
      itemsPerPage: 5,
      itemsPerPageAvailable: [5, 20, 50, 100, 200],
      goToPage: 1,
      page: 1,
    };
  },
  methods: {
    keyup() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction();
        clearTimeout(this.timeout);
      }, 1000);
    },
    searchAction() {
      this.$emit('searchAction', this.search);
    },
    editItem(id) {
      this.$emit('updateCommission', id);
      this.$router.push(`/exams/statements/statement/${id}/update`)
    },
    /*TODO:previewItem*/
    async assestmentPreview(id) {
      const previewLink = this.$router.resolve({ path: '/practicadeexamen/preview', query: { id } })
      window.open(previewLink.href, '_blank');
    },
    deleteItem(id) {
      this.$emit('deleteCommission', id);
    },
    changePage() {
      if (this.goToPage > this.pageCount) this.goToPage = this.page;
      else {
        this.page = this.goToPage;
        this.$emit('input-pagination', this.goToPage);
      }
    },
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    clearSelected() {
      this.selectedItems = [];
    }
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.data.length,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.data.length) {
        counter.endItem = this.data.length;
      }
      return counter;
    },
    pageCount() {
      let counter = this.data.length / this.itemsPerPage;
      if (counter < 1) {
        counter = 1;
      }
      return Math.ceil(counter);
    }
  }
};
</script>

<style lang="sass" scoped>
.table-border
  border: 1px solid #0000002B
.table-border,.table-background-color
  background-color: rgba(247,247,247,.45)

</style>
