<template>
    <div class="d-flex banner" :style="`background-color: ${backgroundColor}`">
        <img v-if="leftImage" :src="require(`@/assets/${src}`)" :alt="alt">
        <span class="text pa-4" :style="`color: ${textColor}`">{{ title }}</span>
        <img v-if="!leftImage" :src="require(`@/assets/${src}`)" :alt="alt">
    </div>
</template>

<script>
    export default {
        name: 'ImageBanner',
        props: {
            leftImage: {type: Boolean, default: false},
            title: {type: String, default: ''},
            src: {type: String, default: 'default.png'},
            backgroundColor: {type: String, default: '#f6da63'},
            textColor: {type: String, default: '#910012'},
            alt: {type: String, default: ''},
        }
    }
</script>

<style lang="sass" scoped>
    .banner
        border-radius: 8px

        .text
            flex: 1
            font-family: "DM Sans", "Roboto" !important
            font-size: 16px
            letter-spacing: 0.25px
            font-weight: 600
            align-self: center
</style>