
import WhatsappView from '../Views/WhatsappView'

const WhatsappRoute = [
    {
        path: '',
        component: WhatsappView,
        meta: { name: 'Index', group: 'portaladministrativo.messaging', path: 'whatsapp', permission: 'READ' }
    },
]

export default WhatsappRoute