<template>
  <v-container class="superTable pa-0" fluid>
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="users"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          :show-select="false"
          class="superTable__table"
          hide-default-footer
          item-key="key"
          style="table-layout: fixed"
          v-model="selectedItems"
          :server-items-length="totalItems"
          must-sort
        >

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox 
              :value="isSelected" 
              @input="select($event)" 
              class="superTable__table__item__checkbox"
              color="primary">
            </v-simple-checkbox>
          </template>

          <template v-slot:[`item.productType`]="{item}">
            <v-row v-bind:key="type.id" v-for="type in item.productType">
              <v-col class="py-0" sm="12">
                {{ type.name }}
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-icon :color="(item.status) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.percentage`]="{item}">
            <v-row v-bind:key="type.id" v-for="type in item.percentage">
              <v-col class="py-0" sm="12" v-if="type.percentage">
                {{ (type.amount) ? $options.filters.$currency(type.percentage) : `${type.percentage} %` }}
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-menu bottom left>
              <template v-if="permissions.update || permissions.delete" v-slot:activator="{ on }">
                <v-icon class="superTable__table__item__action" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.update" @click="openDetails(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-eye</v-icon>
                    {{$t('actions.details')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="editItem(item.id)"
                  v-if="permissions.update"
                >
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="deleteItem(item.id)"
                  v-if="permissions.delete"
                >
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{ $t('actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>
      <v-col sm="3">
        <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('length-pagination-change', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col class="text-center" sm="6">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)"
          @input="$emit('input-pagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col align="right" sm="3">
        <v-text-field
          max="pageCount"
          outlined
          @blur="changePage"
          v-model.number="goToPage"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
  .v-expansion-panels
    .v-expansion-panel-content
      padding-left: 10px
      padding-bottom: 0px
      padding-right: 16px
</style>

<script>
  import Constants from '@/plugins/constants'

  export default {
    name: 'SuperTable',
    props: {
      users: Array,
      headers: Array,
      clearSelected: String,
      deleteTitle: String,
      sortBy: String,
      sortDesc: Boolean,
      pageCount: { type: Number, default: 1 },
      page: { type: Number, default: 1 },
      totalItems: Number,
      permissions: {
        delete: false,
        update: false
      },
    },
    data() {
      return {
        selectedItems: [],
        search: '',
        itemsPerPage: 20,
        itemsPerPageAvailable: [20, 50, 100, 200],
        markedUsers: [],
        goToPage: 1,
      }
    },
    methods: {
      getAvatar(id) {
        return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
      },
      editItem(id) {
        this.$emit('updateUser', id);
      },
      deleteItem(id) {
        this.$emit('deleteUser', id);
      },
      openDetails(id) {
      this.$emit('openDetails', id);
      },
      keyup () {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchAction()
          clearTimeout(this.timeout);
        },1000)
      },
      searchAction() {
        this.$emit('searchAction', this.search)
      },
      itemsToDelete() {
        this.$emit('bulk-delete', [...this.selectedItems]);
        this.selectedItems = [];
      },
     
      changePage() {
        if (this.goToPage > this.pageCount) this.goToPage = this.page;
        else this.$emit('input-pagination', this.goToPage)
      },
    },
    watch: {
      page (val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val < 1) this.page = 1;
        if (this.page !== this.goToPage) this.goToPage = this.page;
      },
      clearSelected() {
        this.selectedItems = [];
      },
    },
    computed: {
      areItemsSelected() {
        return !!this.selectedItems.length
      },
    
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };
        if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
        return counter
      }
    },
  };
</script>
