import Liquidations from '../Liquidations.vue'
import LiquidationsTable from '../Views/LiquidationsTable.vue';


const LiquidationsRoute =
  {
    path: '/liquidations',
    meta: {
      name: {
        es: 'Liquidaciones',
        en: 'Liquidations',
      }
    },
    component: Liquidations,
    children: [
      {
        path: '',
        component: LiquidationsTable,
        meta: { name: 'Index', group: 'comisiones', path: 'liquidations', permission: 'READ' }
      }
    ]
  };

export default LiquidationsRoute
