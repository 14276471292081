import ModuleControlIndex from '../Views/ModuleControlIndex'
import ModuleControl from '../ModuleControl'

const ModuleControlRoute =
    {
        path: '/module-control',
        meta: {
            name: {
                en: 'Module control',
                es: 'Control de módulos'
            }
        },
        component: ModuleControl,
        children: [
                {
                    path: '/',
                    component: ModuleControlIndex,
                    meta: { name: 'Index', path: 'module_control', permission: 'READ'}
                },
            ]
        };
    export default ModuleControlRoute
        


