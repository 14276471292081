<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs v-if="!saveSuccess && !saveError"
          :title="$t('modules.prices.reindex.breadcrumbs.title')"
          :description="$t('modules.prices.reindex.breadcrumbs.description')"
        ></Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <template v-if="!saveSuccess && !saveError">
              <SuperTableReindex
                :headers="headers"
                :items="prices"
                :filters="filters"
                :page-count="pageCount"
                :getLoading.sync="getLoading"
                :getError.sync="getError"
                :getSuccess.sync="getSuccess"
                :saveLoading.sync="saveLoading"
                :saveError.sync="saveError"
                :saveSuccess.sync="saveSuccess"
                @search="(val)=>getDetails(val)"
                @calculate="(val)=> detailsCalculation(val)"
                @reindex="val => reindex(val)"
              />
            </template>
            <SuccessMessage
              v-else
              :title="successMessage.title"
              :type="successMessage.type"
              :actionPrimary="successMessage.actionPrimary"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTableReindex from '../Components/SuperTable/SuperTableReindex'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $details, $reindex } from '../Services'

export default {
  name: 'PricesReindex',
  components: {
    SuperTableReindex,
    Breadcrumbs,
    SuccessMessage
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('modules.prices.reindex.table.headers.product'),
          value: 'billingProduct.name',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.state'),
          value: 'status',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.student_type'),
          value: 'studentType.meaning',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.modality_type'),
          value: 'modalityType.meaning',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.zone'),
          value: 'zone.zoneDescription',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.from'),
          value: 'from',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.price'),
          value: 'amount',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.index'),
          value: 'indexedPrice',
        },
        {
          text: this.$t('modules.prices.reindex.table.headers.final'),
          value: 'finalPrice',
        },
      ],
      prices: [],
      page: 1,
      itemsPerPage: 20,


      details:{
        notModified: [],
        modified: [],
      },

      filters:{
        products: null,
        student_types: null,
        modality_types: null,
      },

      successMessage: {
        title: '',
        type: '',
        subtitle: '',
        actionPrimary: { text: '', callback: () => { this.$router.push('/prices') } },
      },

      saveSuccess: false,
      saveError: false,
      saveLoading: false,
      getSuccess: false,
      getError: false,
      getLoading: false,
    }
  },

  computed:{
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.prices.UPDATE
    },
    pageCount(){
      return this.prices.length % this.itemsPerPage === 0 ? (this.prices.length / this.itemsPerPage | 0) : (this.prices.length / this.itemsPerPage + 1 | 0 );
    }

  },

  methods: {
    // Calculations

    calculation(value,modifier,percent,roundPrice){

      let calculated = null;

      if(percent){
        calculated =  value + (modifier * value) / 100;
      }else{
        calculated = value + modifier;
      }

      if(roundPrice){

        const rounded = parseInt(calculated);

        return calculated - rounded < 0.5 ? rounded : rounded + 1

      }else{
        return calculated;
      }
    },

    // Front-end Operations

    detailsCalculation(params){

      let percent, modifier;

      if(params.percent){
        percent = true;
        modifier = parseFloat(params.percent)
      }else{
        percent = false;
        modifier = parseFloat(params.amount)
      }

      this.prices.map((detail)=>{
        const calculated = this.calculation(parseFloat(detail.amount),modifier,percent, params.roundPrice)
        detail.indexedPrice = calculated;
        detail.finalPrice = calculated;
      })
    },

    formatDetails(details){

      return details.map((detail)=>{
        const tmpDetail = {
          billingProduct: detail.billingProduct,
          status: detail.priceStatus,
          studentType: detail.studentType,
          modalityType: detail.modalityType,
          zone: detail.zone,
          from: detail.from,
          amount: detail.amount,
          indexedPrice: detail.amount,
          finalPrice: detail.amount,
          detailId: detail.detailId,
          priceId: detail.priceId,
        };

        return tmpDetail
      })

    },

    // Querys

    async reindex(data){
      if (!this.canUpdate) return false
      this.saveLoading = true;
      try {
        await $reindex.create(data)
        this.saveSuccess = true;
        this.successMessage.title = this.$t('modules.prices.reindex.success');
        this.successMessage.type = 'success';
        this.successMessage.actionPrimary.text = this.$t('modules.prices.create.accept');
        this.successMessage.actionPrimary.callback = () => {
          this.prices = []
          this.saveSuccess = false;
          }
      } catch (error) {
        this.saveError = true;
        this.successMessage.title = this.$t('modules.prices.reindex.error');
        this.successMessage.type = 'error';
        this.successMessage.actionPrimary.text = this.$t('modules.prices.create.go_to_back');
        this.successMessage.actionPrimary.callback = () => {this.saveError = false;}
      }finally{
        this.saveLoading = false;
      }

    },

    resetButtonValues () {
      this.getLoading = false;
      this.getSuccess = false;
      this.getError = false;
      this.saveLoading = false;
      this.saveSuccess = false;
      this.saveError = false;
    },

    async getDetails(filters){
      this.getLoading = true;
      try {
        const [prices] = await Promise.all([
          $details.find(null, null, { params: filters })
        ]);
        this.getSuccess = true;
        this.prices = this.formatDetails(prices.data);

      } catch (error) {
        this.getError = true;
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.prices.reindex.getError'),
          actionPrimary: {
            text: this.$t('modules.prices.reindex.retry'),
            callback: () => {
              this.getError = false;
              this.prices = []
            }
          },
          icon: { color: 'error', name: 'mdi-cancel' },
          color: 'primary',
        })
      }finally{
        this.getLoading = false;
      }
    },

  }
}
</script>
