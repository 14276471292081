<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="items"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed; cursor: pointer;"
          @click:row="$emit('clickRow', $event)"
        >
          <template v-slot:[`item.name`]="{ item }">
            <strong dark>{{ item.name }}</strong>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item.status)" dark><b>{{ item.status }}</b></v-chip>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          :value="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('lengthPaginationChange', $event)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          v-model="page"
          :length="pageCount"
          circle
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model.number="page"
          max="pageCount"
          outlined
          @blur="$emit('inputPagination', page)"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('modules.portalalumno.procedures.procedureHistory.table.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SimpleTable',
    props: {
      items: Array,
      itemsPerPage: { type: Number, default: 20 },
      headers: Array,
      status: Array,
      pageCount: { type: Number, default: 1 },
      page: { type: Number, default: 1 },
      totalItems: Number
    },
    data() {
      return {
        selectedItems: [],
        itemsPerPageAvailable: [20, 50, 100, 200]
      };
    },
    methods: {
      getColor(status) {
        for (let i = 0; i < this.status.length; i++) {
          if (status === this.status[i].text) {
            return this.status[i].value;
          }
        }
      },
    },
    watch: {
      page(val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val <= 1) this.page = 1;
      }
    },
    computed: {
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
          endItem: this.page * this.itemsPerPage
        };

        if (counter.endItem > this.items.length) counter.endItem = this.items.length;
        return counter;
      }
    }
  };
</script>