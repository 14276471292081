<template>
    <v-row>
        <v-col cols="12">
            <div class="d-flex banner">
                <span class="text pa-4">{{ $t('modules.portalalumno.payments.paymentsStudents.banners.title', {percentage: percentage ? $options.filters.$roundNumber(percentage) : 0}) }}</span>
                <img :src="require('@/assets/cards.png')" alt="">
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'PaymentBanner',
        props: {
            percentage: Number
        }
    }
</script>

<style lang="sass" scoped>
    .banner
        background-color: #f6da63
        border-radius: 8px

        .text
            flex: 1
            color: #910012
            font-size: 14px
            font-weight: 600
            align-self: center
</style>
