// import services

import settingsService from '@/plugins/settings.service'

export default {

  // Commons actions

  async initData ({ commit, state }) {

    await settingsService.getAllData()
      .then((response) => {

        let value

        state.mutationsDictionary.content.forEach(mutation => {

          if (mutation.type === 'Select') {

            // Transfer string option value to int option value for save in Vuex

            [{value}] = state[mutation.section][mutation.field].options.filter(option => {
              return option.text === response[mutation.field]
            });
          } else {
            value = response[mutation.field]
          }
          commit(mutation.setter, value)
        })

        return response
      })
      .catch((err) => {
        return err
      })

  },

  changeValue ({ commit, state, getters }, change) {
    state.mutationsDictionary.content.forEach(mutation => {
      if (mutation.field === change.field) {

        // Record the change in trash

        let firstChange = true;

        // Search change in trash
        for (const element of state.trash) {
          if (element.setter == mutation.setter) {
            firstChange = false
            break
          }
        }

        // commit trash mutation
        if (firstChange) {
          const oldValue = getters.getAllCurrentValues[mutation.field]
          commit('SAVE_IN_TRASH', { value: oldValue, section: mutation.section, field: mutation.field, setter: mutation.setter })
        }
      }
    })
  },

  undo ({ commit, state }) {
    state.trash.forEach((mutation) => {
      commit(mutation.setter, mutation.value);
    })
    commit('CLEAN_TRASH');
  },

  cleanTrash ({ commit }) {
    commit('CLEAN_TRASH')
  },

  // modify individual fields of sms config

  disabledAllSMSSettings ({ commit, state }) {
    state.sms.smsConfigs.currentValue.forEach((config) => {
      commit('SET_SMSCONFIGFIELD', {
        id: config.id,
        field: 'status',
        value: false,
      })
    })
  },

  updateIndividualSMSConfig ({ commit }, change) {

    commit('SET_SMSCONFIGFIELD', change)

  },

  updateState ({ commit, state }, changes) {

    state.trash.forEach((mutation) => {
      commit(mutation.setter, changes[mutation.field])
    })

  },

  updateAPI ({ getters, state }) {

    if (getters.getChanges.length > 0) {

      const changes = {}

      getters.getChanges.forEach((change) => {

        if (state[change.section][change.field].options) {

          // Transfer int option value to string option value for save in DB

          changes[change.field] = state[change.section][change.field].options.filter(option => {
            return option.value === getters.getAllCurrentValues[change.field]
          })[0].text

        } else {

          changes[change.field] = getters.getAllCurrentValues[change.field]

        }

      })

      // API Service

      settingsService.changeData(1, changes)
        .then((res) => {
          return res

        })
        .catch((err) => {
          return err
        })
    } else {
      throw new Error('No updates!');
    }

  }

}
