
const rolesTranslations = {
  es: {

    tooltips: {
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      enable_not_allowed: 'El módulo no será visible hasta que se habilite de manera global.',
      help_tooltip: 'Desde aquí podrás editar los permisos para este portal',
      alert_tooltip: 'El módulo se encuentra deshabilitado globalmente.'
    },

    table: {
      breadcrumbs: {
        title: 'Roles',
        description: 'Administración de roles.',
      },
      bulk_delete: 'Eliminar roles',
      see_users: 'Ver usuarios',
      headers: {
        name: 'Nombre',
        description: 'Descripción',
        status: 'Estado',
        modules: 'Módulos',
        users: 'Usuarios'
      },
      messages: {
        delete: '¿Estás seguro de eliminar?',
        deleteNotAllowed: 'No puedes eliminar un rol con usuarios asignados.'
      }
    },

    create: {
      breadcrumbs: {
        title: 'Nuevo rol',
        description: 'Crea un nuevo rol'
      },
      stepper: {
        details: {
          title: 'Nombre y descripción',
          fields: {
            name: 'Nombre',
            description: 'Descripción',
            type: 'Tipo de rol',
          },
          validations: {
            nameRequired: 'El nombre es requerido para continuar',
            descriptionRequired: 'La descripción es requerida para continuar',
            typeRequired: 'Selecciona un tipo de rol',
          }
        },
        applications: {
          title: 'Accesos a aplicaciones',
          createRolButton: 'Crear Rol'
        }
      },
      messages: {
        createSuccess: '¡Rol creado con éxito!',
      }
    },

    update: {
      breadcrumbs: {
        title: 'Editar rol',
        description: 'Modifica el rol seleccionado.'
      },
      messages: {
        updateSuccess: '¡Rol actualizado con éxito!'
      }
    }
  },
  en: {

    tooltips: {
      enable: 'Enable',
      disable: 'Disable',
      help_group: '',
    },

  }
};


export default rolesTranslations
