<template>
    <v-dialog v-model="modalOpen" max-width="688px" @click:outside="$emit('close')" content-class="modal-content">
        <v-card class="px-5 py-6">
            <v-card-title class="primary--text py-0 px-3">
                {{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.promotions') }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text width="640" height="320">
                <iframe width="640" height="320" :src="$isTeclab ? 'https://www.mercadopago.com.ar/cuotas?iframe=true' : 'https://www.mercadopago.cl/cuotas?iframe=true'" frameborder="0" allowfullscreen></iframe>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style lang="sass" scoped>
    html
        overflow: hidden !important
</style>

<script>
    export default {
        name: 'PaymentPromotionsModal',
        props:{
            modalOpen: Boolean,
        },
    }
</script>
