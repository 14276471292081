<template>
    <v-container>
        <v-row>
            <v-col cols="8" sm="5">
                <v-text-field 
                        label="DNI"
                        append-icon="mdi-magnify "
                        v-model="dni"
                        @blur="getInfo"
                        outlined
                        class="no-message"
                    ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-show="infoStudentBilling != null">
            <StudentData :infoStudentBilling="infoStudentBilling == null ? {} : infoStudentBilling"/>
        </v-row>
    </v-container>
</template>

<script>
import StudentData from './StudentData'

    export default {
        name: 'StudentCard',
        components:{
            StudentData
        },
        props:{
            infoStudentBilling: { type: Object , default: null },
            dni: { type: String , default: null }
        },
        methods: {
            getInfo(){
            this.$emit('get-info', this.dni)            }
        },
    }
</script>

<style lang="scss" scoped>

</style>