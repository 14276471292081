<template>
    <transition name="modal">
        <div class="modal">
            <div class="modal__mask"></div>
            <div class="modal__wrapper">
                <div class="modal__container">
                    <div class="modal__header mx-4">
                        <h5>{{ $t('modules.payments.view.labels.promotions') }}</h5>
                        <div class="modal__x" @click="$emit('close')">&times;</div>
                    </div>
                    <div class="modal__body">
                        <div v-if="token !== ''" width="800" height="400">
                            <iframe width="800" height="400" :src="url" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="sass" scoped>
    html
        overflow: hidden !important
    .modal
        position: fixed
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        display: flex
        align-items: center
        justify-content: center
        &__mask
            background: rgba(0, 0, 0, 0.7)
            position: absolute
            top: 0
            left: 0
            width: 100vw
            height: 100vh
        &__wrapper
            position: relative
            max-width: 80%
            min-height: 35vmin
            background: #fff
            color: #333
            display: flex
            border-radius: .2em
            box-shadow: 0 0 0 1px rgba(0,0,0,.2), 0 1em 2em -1em
        &__container
            flex: 1
            display: flex
            flex-direction: column
        &__header
            display: flex
            justify-content: space-between
            padding: 1em
            & h5
                color: #012169
        &__body
            flex: 1
            padding: 1em
            overflow: auto
        &__x
            cursor: pointer
            font-size: 1.5rem
            opacity: 0.7
            &:hover
                opacity: 1
        iframe
            max-width: 100%

</style>

<script>
    export default {
        name: 'PaymentPromotionsModal',
        methods: {
            closeDialog() {
                this.$emit('close');
            },
        },
        /*mounted() {
            debugger;
            const url = this.$route.path;;          
        },*/
        props: {
            token:String,
        },
        computed: {
            url () {
                return `http://certificacion.ecertchile.cl/ServicioValidacionRemota/PreguntasIdentidad.aspx?token=${this.token}`
            }
        }
    }
</script>