<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!updateSellerStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.sellers.update.breadcrumbs.title')"
                :description="$t('modules.commissions.sellers.update.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                   <OutlinedCard
                        :title="$t('modules.commissions.sellers.update.sectionData.title')"
                        :subtitle="$t('modules.commissions.sellers.update.sectionData.subtitle')"
                        :switch-label="changeStatuslabel"
                        switcher
                        @switchChange="changeStatus"
                        :switchValue="status"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="8">
                              <v-text-field
                                 :error-messages="($v.sellerName.$dirty && $v.sellerName.$invalid) ?
                                 ((!$v.sellerName.required) ? $t('modules.commissions.sellers.validations.nameRequired') :
                                 (!$v.sellerName.alphaNumWithSpaces) ? $t('modules.commissions.sellers.validations.nameAlphanumericWithSpaces') :
                                 $t('modules.commissions.sellers.validations.nameMaxLength')) :
                                 ''"
                                 :label="$t('modules.commissions.sellers.create.sectionData.items.sellerName')"
                                 v-model="$v.sellerName.$model"
                                 @blur="$v.sellerName.$touch()"
                                 @keypress="$validateAlphanumeric($event, $v.sellerName.$model,60, true)"
                                 outlined
                               ></v-text-field>
                            </v-col>
                            <v-col sm="4">
                              <v-text-field
                                 :error-messages="($v.sellerCode.$dirty && $v.sellerCode.$invalid) ?
                                 ((!$v.sellerCode.required) ? $t('modules.commissions.sellers.validations.codeRequired') :
                                 (!$v.sellerCode.alphaNum) ? $t('modules.commissions.sellers.validations.codeAlphanumeric') :
                                 (!$v.sellerCode.maxLength) ? $t('modules.commissions.sellers.validations.codeMaxLength') :
                                 $t('modules.commissions.sellers.validations.codeExist')) :
                                 ''"
                                 :loading="loadingCode"
                                 :label="$t('modules.commissions.sellers.create.sectionData.items.sellerCode')"
                                 outlined
                                 :value="$v.sellerCode.$model"
                                 @input="$v.sellerCode.$model = $event.toUpperCase()"
                                 @blur="validateCode"
                                 @keypress="$validateAlphanumeric($event, $v.sellerCode.$model,10)"
                               ></v-text-field>
                            </v-col>
                            <v-col cols="8" >
                              <v-text-field
                                 :label="$t('modules.commissions.sellers.create.sectionData.items.email')"
                                 v-model="$v.email.$model"
                                 outlined
                                 :error-messages="$v.email.$dirty && $v.email.$invalid ? $t('modules.commissions.sellers.validations.emailRequired') : ''"
                                 @input="$v.email.$model = $event.toLowerCase()"
                                 @blur="$v.email.$touch()"
                               ></v-text-field>
                            </v-col>
                             <v-col cols="4" >
                                <v-text-field
                                  :label="$t('modules.commissions.sellers.create.sectionData.items.sapCode')"
                                  v-model="sapCode"
                                  @input="sapCode = $event.toUpperCase()"
                                  outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="true">
                               <v-checkbox
                                    v-model="paying"
                                    :label="$t('modules.commissions.sellers.create.sectionData.items.commission')"
                                    color="primary"
                                    hide-details
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <OutlinedCard
                        :title="$t('modules.commissions.sellers.update.sectionLocality.title')"
                        :subtitle="$t('modules.commissions.sellers.update.sectionLocality.subtitle')"
                        class="mb-6"
                    >
                       <v-col class="d-flex px-0" cols="4" sm="3">
                          <v-select
                            :items="statesList"
                            item-text="name"
                            :label="$t('modules.commissions.sellers.update.sectionLocality.province')"
                            outlined
                            return-object
                            v-model="selectState"
                          ></v-select>
                       </v-col>
                       <TransferList
                          :avatar="false"
                          :chips="true"
                          valueDisable="seller"
                          showInChip="message"
                          :loading="loadingSearchLocalities"
                          item-text="description"
                          :availableItems.sync="filteredLocalities"
                          :added-items.sync="localitiesAdded"
                          areEquals="id"
                          :firstListTitle="$t('modules.commissions.sellers.update.sectionLocality.firstListTitle')"
                          :secondListTitle="`${$t('modules.commissions.sellers.create.sectionLocality.secondListTitle')} ${localitiesAdded.length === 0 ? '' : localitiesAdded.length } `"
                       ></TransferList>
                        <v-alert
                          text
                          type="warning"
                          class="mt-4"
                          v-if="localitiesAdded.length === 0 && status === true"
                        >{{ $t('modules.commissions.sellers.create.sectionLocality.error.errorLocality') }}</v-alert>
                    </OutlinedCard>
                    <OutlinedCard
                       :title="$t('modules.commissions.sellers.update.sectionCareers.title')"
                       :subtitle="$t('modules.commissions.sellers.update.sectionCareers.subtitle')"
                       class="mb-6"
                    >
                      <TransferList
                        :avatar="false"
                        :loading="loadingSearchCareers"
                        item-text="description"
                        :availableItems.sync="filteredCareers"
                        :added-items.sync="careersAdded"
                        areEquals="id"
                      ></TransferList>
                    </OutlinedCard>
                    <OutlinedCard
                       :title="$t('modules.commissions.sellers.create.sectionUsers.title')"
                       :subtitle="$t('modules.commissions.sellers.create.sectionUsers.subtitle')"
                       class="mb-6"
                    >
                      <TransferList
                        :avatar="true"
                        :loading="loadingSearchUsers"
                        item-text="description"
                        :availableItems.sync="users"
                        :added-items.sync="usersAdded"
                        areEquals="id"
                      ></TransferList>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/sellers')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="saveButton.loading"
                            :success="saveButton.success"
                            :error="saveButton.error"
                            :text="$t('actions.save')"
                            :successText="$t('actions.saved')"
                            :errorText="$t('actions.error')"
                            :disabled="!canUpdate"
                            @end="resetButtonValues"
                            @clicked="updateSeller"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import TransferList from '@/components/TransferList/TransferList.vue';
    import Button from '@/components/Button/Button';
    import {required, email, maxLength, helpers, alphaNum} from 'vuelidate/lib/validators';
    import Constants from '@/plugins/constants';
    import { mapGetters } from 'vuex';
    import { $careers, $users, $countries , $sellers, $citiesByState } from '../Services';
import Configuration from '../../../../../core/constants/configuration';



    export default {
        name: 'SellersUpdate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
            TransferList
        },
        data() {
            return {
                localitiesAdded: [],
                filteredLocalities:[],
                selectState: null,
                statesList: [],
                sellerName: '',
                status: true,
                phone: null,
                sapCode: null,
                description: null,
                sellerCode: null,
                originalCode: null,
                email: null,
                paying: true,
                careerList: [],
                userList: [],
                citiesList: [],
                loadingSearchLocalities: false,
                loadingSearchUsers: false,
                loadingSearchCareers: false,
                loadingCode: false,
                codeUnique: null,
                usersAdded: [],
                users: [],
                filteredCareers: [],
                careers: {},
                careersAdded: [],
                careersFilters: [],
                availableCareers: [],
                paginationLimit: 150,
                updateSellerStatus: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: '',
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.sellers.UPDATE
            },
            changeStatuslabel() {
              return this.status
                ? this.$t('modules.commissions.sellers.update.sectionData.labelEnable')
                : this.$t('modules.commissions.sellers.update.sectionData.labelDisable');
            },
            ...mapGetters({
                userId: 'commons/getUserID',
              }),
        },

        watch: {
         
          'selectState': async function () {
            this.loadingSearchLocalities = true
            const cities = await $citiesByState.find(null, null, { params: { stateId: this.selectState.id, sellers:true } });

             const newCities = (cities.data.sort((a, b) => a.name < b.name ? -1 : 1)).map(el => {
               if (el.seller) {
                 const newseller = {status: el.seller, message : 'No disponible'}
                 const newElem = {...el, seller:newseller}
                 return newElem
               }else {
                  return { ...el, seller:undefined }
               }
             })
            this.filteredLocalities =  newCities
            this.loadingSearchLocalities = false
         },
        },

        mounted(){
             this.getSeller(); 
        },
        methods: { 
              async validateCode() {
                this.$v.sellerCode.$touch();
                if (this.$v.sellerCode.$model) {
                  if (this.originalCode === this.sellerCode) {
                    this.codeUnique = true;
                    return;
                  }
                  this.codeUnique = null;
                  this.loadingCode = true;
                  try {
                    const response = await $sellers.find('exists', null, {params: {code: this.$v.sellerCode.$model}});
                    this.codeUnique = !response.data.exists;
                    this.loadingCode = false;
                  } catch (error) {
                    this.codeUnique = null;
                    throw error;
                  } finally {
                    this.$v.sellerCode.$touch();
                  }
                }
              },
             searchCareers(event) {
               this.getCareers(event);
             },
             searchCareersAdded(event) {
               this.careersAdded = event;
             },
            
            async getCareers(career) {
             this.loadingSearchCareers = true;
             let careers = [];
              if (career) {
                careers = await $careers.find(null, null, {
                  params: { name: career, length: this.paginationLimit },
                });
              } else {
               careers = await $careers.find(null, null, {
                 params: { length: this.paginationLimit },
                   });
              }
                 if (careers.data && careers.data.content.length) {
                   careers = careers.data.content.map((item) => {
                 return {
                   id: item.id,
                   name: item.legalDescription,
                   careerType: item.careerType,
                 };
                   });
           
                   // CAREERS CATEGORIES
           
                   this.careersFilters.forEach((filter) => {
                     /* eslint-disable */
                     if (filter.meaning === "Todos") {
                       this.careers[filter.meaning] = careers;
                     } else {
                    this.careers[filter.meaning] = careers.filter(
                      (item) => item.careerType.value == filter.value
                    );
                  }
                });
        
                this.filteredCareers = careers;
                this.availableCareers = careers;
              }
              this.loadingSearchCareers = false;
            },
            async getUsers() {
              this.loadingSearchUsers = true;
        
              const users = await $users.find(`list`, null,{params: {page: 0, length: 500, userId: this.userId, orderBy: 'name', orientation: 'asc'}})
              this.users = users.data.content.map(user => {
              return {
                id: user.id,
                avatar: `${Constants.URL_DATA_SERVER}/users/${user.id}/avatar`,
                name: `${user.name} ${user.lastName}`
              };
             })
        
              this.loadingSearchUsers = false;
            },
           
            async getStates() {
               function compare( a, b ) {
               if ( a.name < b.name ){
                 return -1;
              }
              if ( a.name > b.name ){
                return 1;
              }
              return 0;
              }
               try {
                 let country =  Configuration.value('country') || 'argentina'
                 const statesLocal = await $countries.find(null, null, {params: { name: country }})     
                 this.statesList = (statesLocal.data[0].states).sort((a, b) => a.name < b.name ? -1 : 1)
               } catch (error) {
                  throw error
               }
            },
            changeStatus() {
                 this.status = !this.status;
            },

            async getSeller() {
                await this.getCareers();
               await this.getUsers();
               await  this.getStates();
                try {
                    const seller = (await $sellers.findById(this.$route.params.id)).data
                    this.sellerName = seller.sellerName;
                    this.sellerCode = seller.sellerCode;
                    this.originalCode = seller.sellerCode;
                    this.email = seller.email;
                    this.status = seller.status
                    this.paying = seller.paying;
                    this.sapCode = seller.sapCode;
                
                    
                    this.localitiesAdded = seller.citiesList !== null? seller.citiesList : [];
                    this.careersAdded = seller.careerList !== null?  seller.careerList.map( item => {
                      return {
                          id: item.id,
                          name: item.legalDescription
                      }
                    }) : [];
                    this.usersAdded = seller.usersList !== null? seller.usersList.map( item => {
                      return {
                          id: item.id,
                           avatar: `${Constants.URL_DATA_SERVER}/users/${item.id}/avatar`,
                          name: `${item.userData.name} ${item.userData.lastname}`,
                      }
                    }) : [] ;  
                    
                    this.filteredCareers = this.filteredCareers.filter(item => !this.careersAdded.map(item => item.id).includes(item.id)).map(item => ({ name: item.name, id: item.id }));
                    this.users = this.users.filter(item => !this.usersAdded.map(item => item.id).includes(item.id)).map(item => ({ name: item.name, id: item.id , avatar: `${Constants.URL_DATA_SERVER}/users/${item.id}/avatar`, }));
                } catch (error) {
                    throw error
                }
            },
            resetButtonValues() {
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async updateSeller() {

                 if ( this.$v.$invalid || !this.canUpdate || (this.localitiesAdded == 0 && this.status == true)) {
                    this.$v.$touch()
                 }else {

                if(!this.canUpdate) return false;
                    this.saveButton.loading = true;
                    let seller = {
                       sellerName: this.sellerName.trim(),
                       sapCode: this.sapCode,
                       status: this.status,
                       email: this.email,
                       sellerCode: this.sellerCode,
                       paying: this.paying,
                       careerList: [],
                       usersList: [],
                       citiesList: []
                      }
                    this.careersAdded.map(career => {
                      const addcareer = { ...seller, careerList: seller.careerList.push({id:career.id})} 
                     })
                    this.usersAdded.map(user => {
                      const addUser = { ...seller, usersList: seller.usersList.push({id:user.id})} 
                     })
                    this.localitiesAdded.map(city => {
                      const addCities = { ...seller, citiesList: seller.citiesList.push({id:city.id})} 
                     })
                try {
                    await $sellers.update(this.$route.params.id, seller)
                    this.successMessage.type = 'success',
                    this.successMessage.title = this.$t('modules.commissions.sellers.update.success_message.title'),
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/sellers') } }
                    this.saveButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.sellers.update.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.updateSellerStatus = false } }
                    this.saveButton.error = true;
                    throw error;
                } finally {
                    this.saveButton.loading = false;
                    this.updateSellerStatus = true;
                }
             }
            },
        },
        validations: {
    sellerName: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace',/^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
    },
    sellerCode: {
      required,
      alphaNum,
      maxLength: maxLength(10),
      unique() {
        return this.codeUnique !== false;
      },
    },
    email: { required, email },
    //  seller: ['sellerName', 'sellerCode', 'selectState', 'email', ],
    
  },
    }
</script>