<template>
  <v-row>
    <v-col sm="10" class="d-flex flex-column align-start mx-auto">
      <div class="d-flex flex-column">
        <h1 class="headline primary--text">{{ title }}</h1>
        <h6 v-if="description || descriptionBold" class="caption">
          {{ description }} <strong v-if="descriptionBold">{{descriptionBold}}</strong>
        </h6>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'TitleComponent',
    props: {
      title: String,
      description: { type: String, default: '' },
      descriptionBold: { type: String, default: '' }
    },
  }
</script>