<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :title="$t('modules.settings.general.breadcrumbs.title')"
            :description="$t('modules.settings.general.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template v-if="!loading">
            <OutlinedCard :section="$t('modules.settings.general.sectionLanguage')" class="mb-6">
              <OutlinedCardContent :label="$t('modules.settings.general.sectionLanguage.language')">
                <v-select
                  :value="_self['settings/getAllCurrentValues']['language']"
                  :items="_self['settings/getAllOptions']['language']"
                  @change="(val) => changeValue(val,'language')"
                  outlined
                ></v-select>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.general.sectionLanguage.dateFormat')">
                <v-select
                  :value="_self['settings/getAllCurrentValues']['dateFormat']"
                  :items="_self['settings/getAllOptions']['dateFormat']"
                  @change="(val) => changeValue(val,'dateFormat')"
                  outlined
                ></v-select>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.general.sectionLanguage.timeFormat')">
                <v-select
                  :value="_self['settings/getAllCurrentValues']['timeFormat']"
                  :items="_self['settings/getAllOptions']['timeFormat']"
                  @change="(val) => changeValue(val,'timeFormat')"
                  outlined
                ></v-select>
              </OutlinedCardContent>
              <OutlinedCardContent :label="$t('modules.settings.general.sectionLanguage.timeZone')">
                <v-select
                  :value="_self['settings/getAllCurrentValues']['timeZone']"
                  :items="_self['settings/getAllOptions']['timeZone']"
                  @change="(val) => changeValue(val,'timeZone')"
                  outlined
                ></v-select>
              </OutlinedCardContent>
            </OutlinedCard>

            <OutlinedCard :section="$t('modules.settings.general.sectionSecurity.title')" class="mb-6">
              <OutlinedCardContent
                :label="$t('modules.settings.general.sectionSecurity.maxLoginAttempts')"
              >
                <v-text-field
                  min="0"
                  step="1"
                  max="999"
                  type="number"
                  outlined
                  @change="(val) => changeValue(val,'maxLoginAttempts')"
                  @keypress="$validateIntegerInputNumber($event, $v.maxLoginAttempts.$model, null, 3)"
                  :error-messages="validate('maxLoginAttempts')"
                  v-model="$v.maxLoginAttempts.$model"
                ></v-text-field>
              </OutlinedCardContent>
              <OutlinedCardContent
                :label="$t('modules.settings.general.sectionSecurity.maxAttemptsHours')"
              >
                <v-text-field
                  min="0"
                  step="1"
                  max="100"
                  type="number"
                  outlined
                  @change="(val) => changeValue(val,'maxAttemptsHours')"
                  @keypress="$validateDecimalInputNumber($event, $v.maxAttemptsHours.$model, 100)"
                  :error-messages="validate('maxAttemptsHours')"
                  v-model="$v.maxAttemptsHours.$model"
                ></v-text-field>
              </OutlinedCardContent>
            </OutlinedCard>
            <div class="text-sm-right">
            <Button
              :loading="loading"
              :success="success"
              :error="error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              errorText="Error"
              @clicked="save()"
              @end="resetButtonValues()"
              :disabled="!canUpdate || $v.$invalid"
            ></Button>
          </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

// Components Imports
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
import Button from '@/components/Button/Button'

// Vuex imports
import { mapGetters } from 'vuex';

// Vuelidate imports

import { required } from 'vuelidate/lib/validators'



export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    Button
  },
  data () {    return {

      loading: false,
      success: false,
      error: false,

      modifications: {},

      // Validations data

      maxAttemptsHours: null,
      maxLoginAttempts: null,

    }  },
  methods: {
    validate(inputValue) {
      let error = '';
      const that = this;

      String.prototype.toPascalCase = function () {
        return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
      };

      if (this.$v[inputValue].$invalid && this.$v[inputValue].$dirty) {
        const validationParams = this.$v[inputValue].$params;

        // First validate required
        if (validationParams.required) {
          if (!that.$v[inputValue].required) {
            error = that.$t(`modules.settings.general.validations.sectionSecurity.${inputValue}Required`);
          }
        }

        const paramsToArray = Object.values(validationParams);

        paramsToArray.forEach(param => {
          if (param.type !== 'required') {
            if (!that.$v[inputValue][param.type]) {
              error = that.$t(`modules.settings.general.validations.sectionSecurity.${inputValue + (param.type).toPascalCase()}`)
            }
          }
        })
      } else {
        error = '';
      }
      return error;
    },
    save () {
      if (!this.canUpdate) return false
      this.loading = true;
      this.$store.dispatch('settings/updateState', this.modifications)
        .then(() => {

          // Modified on Vuex

          this.$store.dispatch('settings/updateAPI')
            .then(() => {

              this.success = true
              this.loading = false

              // Launch snackbar
              this.$store.dispatch('settings/cleanTrash')

            })
            .catch(() => {
              this.error = true
              this.loading = false

            })
        })
        .catch(() => {
          this.error = true
          this.loading = false

        })

    },
    changeValue (value, field) {
      if (!this.canUpdate) return false
      this.modifications[field] = value;
      this.$store.dispatch('settings/changeValue', { value, field })
    },
    resetButtonValues () {
      this.loading = false;
      this.success = false;
      this.error = false;
    }
  },
  validations: {
    maxAttemptsHours: { required },
    maxLoginAttempts: { required }
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.system.UPDATE
    },
    ...mapGetters([
      'settings/getAllCurrentValues',
      'settings/getAllOptions',
      'settings/getLanguageLocate'
    ])
  },
  mounted () {
    this.maxLoginAttempts = this['settings/getAllCurrentValues'].maxLoginAttempts
    this.maxAttemptsHours = this['settings/getAllCurrentValues'].maxAttemptsHours
  }
}
</script>

