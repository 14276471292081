<template>
    <v-expansion-panels focusable>
        <v-expansion-panel class="rolesStepper__permissions-panel" v-for="(app , i) in applications" :key="`app-${i}`">
            <v-expansion-panel-header class="rolesStepper__permissions-panel__header spacing-reduced">
                <div class="d-flex flex-column flex-fill">
                    <div class="headline primary--text">{{app.name}}</div>
                    <div class="caption text-left">{{app.domain}}</div>
                </div>
                <div class="d-flex justify-end">
                  <v-switch color="primary" @click.native.stop="" v-model="app.status"></v-switch>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="custom-expansion-content">
                <v-list-item-group class="pa-0 d-flex flex-column" v-for="(module, index) in app.modules" :key="`module-${index}`">
                        <v-list-item class="pl-4">
                            <v-list-item-content class="py-0">
                                <v-list-item-title class="title py-6 text-left">
                                    {{module.name}}
                                    <v-tooltip top :key="`tooltips-info-${index}`">
                                        <template v-slot:activator="{ on }">
                                            <v-icon :color="module.allowed ? 'secondary' : 'error'" class="ml-2" v-on="on">
                                            {{ module.allowed ? 'mdi-information-outline' : 'mdi-alert-circle-outline' }}</v-icon>
                                        </template>
                                        <span>{{ module.allowed ? $t('modules.roles.tooltips.help_tooltip') : $t('modules.roles.tooltips.alert_tooltip')}}</span>
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch color="primary" :disabled="!app.status" v-model="module.status"></v-switch>
                            </v-list-item-action>
                        </v-list-item>
                        <template v-if="module.status && app.status">
                            <template v-for="(perm, i) in module.permissions">
                                    <v-list-item v-if="perm.name === 'READ'" :key="`permission-${index}-${i}`" class="pl-8">
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="caption text-left">{{perm.name}}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-switch color="primary" :disabled="!app.status" v-model="module.status"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-else :key="`permission-${index}-${i}`" class="pl-8">
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="caption text-left">{{perm.name}}</v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-switch color="primary" :disabled="!app.status" v-model="perm.status"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                <template v-for="(child, index) in module.children">
                                    <!-- eslint-disable-next-line -->
                                    <v-list-item :key="`submodule-${index}`" class="pl-8">
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="title text-left">
                                                {{child.name}}
                                                <v-tooltip top :key="`tooltips-info-${index}`">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon :color="child.allowed ? 'secondary' : 'error'" class="ml-2" v-on="on">
                                                            {{ child.allowed ? 'mdi-information-outline' : 'mdi-alert-circle-outline' }}</v-icon>
                                                    </template>
                                                    <span>{{ child.allowed ? $t('modules.roles.tooltips.help_tooltip') : $t('modules.roles.tooltips.alert_tooltip')}}</span>
                                                </v-tooltip>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-switch color="primary" :disabled="!module.status || !app.status" v-model="child.status"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <template v-if="child.status">
                                        <template v-for="(perm, i) in child.permissions">
                                            <template v-if="perm.name === 'READ'">
                                                <!-- eslint-disable-next-line -->
                                                <v-list-item :key="`child-permission-${index}-${i}`" class="pl-12">
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title class="caption text-left">{{perm.name}}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                      <v-switch color="primary" :disabled="!app.status" v-model="child.status" @change="child.status = perm.status"></v-switch>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                            <template v-else>
                                                <!-- eslint-disable-next-line -->
                                                <v-list-item :key="`child-permission-${index}-${i}`" class="pl-12">
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title class="caption text-left">{{perm.name}}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                      <v-switch color="primary" :disabled="!app.status" v-model="perm.status"></v-switch>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                </v-list-item-group>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<style scoped lang="sass">
.custom-expansion-content::v-deep .v-expansion-panel-content__wrap
  padding: 0 !important
//.v-list
//    &-item
//        flex-direction: column
//        width: 100%
//        &__content
//            width: 100%
//            flex-wrap: nowrap
//            .v-input
//                &--switch
//                    flex: 1 1 100%
//                    justify-content: flex-end
//                    padding-right: 0.5rem
//                    margin-top: 6px
//                    margin-bottom: -6px
//        &__title
//            display: flex
//            &.headline
//                flex: 0 0 100%
//        &__subtitle
//            flex: 0 0 100%

.rolesStepper
    &__permissions-panel
        border-radius: 5px !important
        border: 1px solid #E4E4E4
        margin-bottom: 1rem

        &:after
            border-color: transparent !important
        &__header
            padding: 1.2rem 1.5rem
</style>

<script>
    export default {
        name: 'RolesStepperPermissions',
        props: {
            applications: [Map, Array, Object],
            forceRerender: Number
        },
    }
</script>