<template>
  <v-container>
  <v-row>
    <v-col sm="8" class="mx-auto">
      <v-card class="mb-0 pa-8">
        <v-list subheader class="d-flex flex-column">
          <h3 class="title">
            {{ myData.status ? 'Activo' : 'Inactivo' }}
            <v-icon color="secondary" class="ml-2">mdi-information-outline</v-icon>
          </h3>
          <v-list-item v-for="(item, i) in myData.items" :key="item[i]" class="py-0 mb-0">
            <!-- <div class=""> -->
            <v-list-item-content class="py-0 h-0">
              <v-list-item-title class="subtitle-2 font-weight-regular">{{ item.name }}</v-list-item-title>
            </v-list-item-content>
            <v-switch v-model="item.status" color="primary darken-3" class="ml-10 mt-0 mb-5 pt-0"></v-switch>
            <!-- </div> -->
          </v-list-item>
        </v-list>
      </v-card>
      <div class="text-right" width="719">
        <v-btn rounded color="primary darken-3" class="body-2 font-weight-bold mt-8" height="47" width="103">Guardar
        </v-btn>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<style scoped lang="sass">
.v-list-item-content
  height: 5px !important
  padding: 0px !important
  margin: 0

.v-input--selection-controls__ripple
  margin-bottom: 30px

// .v-input__slot
//     margin-bottom: 20px !important

.v-input__control
  height: 0px

.v-switch
  height: 0px
</style>

<script>
export default {
  name: 'ApplicationEdit',
  props: {
    myData: Object,
  }
}
</script>
