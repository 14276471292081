<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col sm="4" class="pt-2">
        <span class="caption">{{ label }}</span>
      </v-col>
      <v-col :sm="sm">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'OutlinedCardContent',
  props: {
    label: String,
    sm: {type: String, default: '8'},
  }
}
</script>
