<template>
  <v-sheet v-if="profileDropdown.open" v-closable="{handler: 'onClose'}" class="profileDropdown">
    <v-list class="pa-0">
      <v-list-item class="py-4 px-6" :to="{path: '/profile'}" @click="profileDropdown.open = false">
        <v-avatar color="#f5f5f5" size="32">
          <img :src="userData.avatar" />
        </v-avatar>
        <div class="d-flex flex-column pl-4">
          <div class="title">{{ fullName }}</div>
          <div class="caption blue--text text--accent-4 cursor-pointer">
            {{ adminRol ? adminRol : $t('components.appBar.profileDropdown.title')}}
          </div>
        </div>
      </v-list-item>
      <v-divider></v-divider>
      <!-- <v-list two-line class="pa-0">
        <v-list-item click class="px-6">
          <v-list-item-icon class="my-auto pl-1">
            <v-icon> $notif-icon </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('components.appBar.profileDropdown.notifications')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
      <v-list two-line class="pa-0">
        <v-list-item @click="logout()" class="px-6 pb-2">
          <v-list-item-icon class="my-auto pl-1">
            <v-icon v-text="'mdi-logout'"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('components.appBar.profileDropdown.logout')"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list>
  </v-sheet>
</template>

<style scoped lang="sass">
    .profileDropdown
        position: absolute
        top: 64px
        right: 0
        width: 320px
        box-shadow: 0 16px 24px rgba(0,0,0,0.20)
</style>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProfileDropdown',
    props: {
      profileDropdown: Object
    },
    methods: {
      onClose() {
        this.profileDropdown.open = false
      },
      logout() {
        this.$auth.logout();
      },
    },
    computed: {
      adminRol() {
        return this.$isPortalAlumno && this.$isGestor && this['commons/getUserRoles'] ? this['commons/getUserRoles'][0].name : null
      },
      fullName() {
        return `${this.userData.name} ${this.userData.lastname}`
      },
      userData() {
        return this['commons/getStudentUserData'] ? this['commons/getStudentUserData'] : this['commons/getUserData']
      },
      ...mapGetters([
        'commons/getStudentUserData',
        'commons/getUserData',
        'commons/getUserRoles',
      ])
    }
  }
</script>
