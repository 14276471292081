<template>
  <div
    :class="rounded?'AvatarPreview rounded':'AvatarPreview squared'"
    :style="[setWidth, setHeight]"
  >
    <img class="AvatarPreview__preview" :src="image" />
    <div class="AvatarPreview__button-upload" @click="uploadImage">
      <span class="text-center px-4" :style="[fontSize]">
        <template v-if="hasLabel">{{label}}</template>
        <template v-else>
          <v-icon color="white">mdi-pencil-outline</v-icon>
        </template>
      </span>
    </div>
    <input
      class="AvatarPreview__input-file"
      type="file"
      accept="image/*"
      ref="fileInput"
      @change="createImage"
    />
  </div>
</template>

<style lang="sass">
.AvatarPreview
  position: relative
  overflow: hidden
  transition: all .3s ease
  border: 1px solid #f0f0f0
  &.rounded
    border-radius: 50%
  &.squared
    border-radius: 4px
  &:hover &__button-upload
    cursor: pointer
    opacity: 0.75
  &__preview
    height: 100%
    width: 100%
    transition: all .3s ease
    background-color: #F5F5F5
    object-fit: cover
  &__button-upload
    position: absolute
    bottom: 0
    left: 0
    height: 50%
    width: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    span
      color: white
      font-weight: 300
      font-size: 9px
      line-height: 1
      letter-spacing: 0.25px
      margin-bottom: 4px
</style>

<script>
export default {
  name: 'AvatarPreview',
  props: ['image', 'model', 'rounded', 'label', 'labelSize', 'width', 'height', 'size'],
  data () {    return {
      file: null,
      setWidth: {
        'width': '60px'
      },
      setHeight: {
        'height': '60px'
      },
      fontSize: null,
      hasLabel: false
    }  },
  methods: {
    uploadImage () {
      this.$refs.fileInput.click();
    },
    createImage (e) {
      if (e && e.target && e.target.files[0])
        this.$emit('save', e.target.files[0])
    }
  },
  created () {
    const customWidth = this.width;
    const customHeight = this.height;
    const customSize = this.size;
    const {labelSize} = this;
    const {label} = this;

    if (customSize) {
      this.setWidth = { width: `${customSize  }px` };
      this.setHeight = { height: `${customSize  }px` };
    } else {
      if (customWidth) {
        this.setWidth = { width: `${customWidth  }px` };
      }
      if (customHeight) {
        this.setHeight = { height: `${customHeight  }px` };
      }
    }

    if (labelSize) {
      this.fontSize = { 'font-size': `${this.labelSize  }px` }
    }

    if (label) {
      this.hasLabel = true;
    }
    // default avatar

  },

}
</script>
