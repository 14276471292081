import Users from '../Users.vue'
import UsersTable from '../Views/UsersTable.vue';
import UsersCreate from '../Views/UsersCreate.vue';
import UsersUpdate from '../Views/UsersUpdate';
import UsersDetails from '../Views/UsersDetails';

const UsersRoute =
{
  path: '/users',
  meta: {
    name: {
      en: 'Users',
      es: 'Usuarios'
    }
  },
  component: Users,
  children: [
    {
      path: '/',
      component: UsersTable,
      meta: { name: 'Index', group: 'portaladministrativo', path: 'users', permission: 'READ' }
    },{
      path: 'create',
      component: UsersCreate,
      meta: { name: 'Create User', group: 'portaladministrativo', path: 'users', permission: 'CREATE' }
    },{
      path: 'update/:id',
      component: UsersUpdate,
      meta: { name: 'Update User', group: 'portaladministrativo', path: 'users', permission: 'UPDATE' }
    },
    {
      path: 'details/:id',
      component: UsersDetails,
      meta: { name: 'usersDetails', group: 'portaladministrativo', path: 'users', permission: 'READ' }
    },
  ]
};

export default UsersRoute
