<template>
    <v-container fluid class="fullHeight scrollableContent">
        <v-container>
            <v-row>
                <v-col cols="12" sm="10" lg="8" class="offset-sm-1 offset-lg-2">
                    <v-row>
                        <v-col cols="10">
                            <h2 class="titulo-seccion">Bibliografía</h2>
                        </v-col>
                        <!-- <v-col cols="2">
                            <img src="https://canvas-rsc.ilumno.com/ipp/prod/img/171a/icono-bibliografia.png" width="60" height="60" alt="">
                        </v-col> -->
                    </v-row>
                     <hr>
                      <v-row>
                        <v-col cols="12" >
                            <p class="titulo-modulo">ELEMENTOS DEL CURRICULUM Y EVALUACION</p>
                            <hr>
                        </v-col>   
                    </v-row> 
                    <ElibroModule :contents="contents"/>   
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import ElibroModule from '../Components/ElibroModule.vue';
    import Constants from '@/plugins/constants'

    export default{
        components:{
            ElibroModule,
        },
        data() {
            return{
                contents: [],
                module: null,
            }
        },
        mounted(){
            this.$nextTick(()=>this.getData())
        },
        methods:{
            orderModule(data, module){
                const children = [];
                for (let index = 0; index < data.length; index++) {
                    if(data[index].module == module){
                        children.push(data[index]);
                    }
                }
                return children
            },
            async getData(){
               if (this.$route.query.C) this.code = this.$route.query.C;
                try {
                //    const res = await $books.find(this.code, null, { headers: {'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0' }});
                    const res = await fetch(`${Constants.URL_BASE}/elibro/books/${this.code}`, {
                        method: 'GET',
                        mode: 'cors',
                        headers: {'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0'},
                    })
                
                    const data = await res.json()
                    Object.entries(data.books).forEach(( book) =>{
                        if(book[1].module !== this.module){
                            this.module = book[1].module;
                            const order = this.orderModule(data.books, this.module);
                            this.contents.push(order);
                        }
                    });
                } catch (error) {
                    this.contents = []
                    this.module = null
                    throw error;
                 }
             }
        }
    };   
</script>

<style scoped>
    .titulo-seccion {
        font-weight: bold;
        font-size: 28px;
        margin-top: 5px;
        color: #0449A2;
        display: inline-block;
    }
    .titulo-modulo {
        font-weight: bold;
        font-size: 18px;
        color: #0449A2;
    }
</style>