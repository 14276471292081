<template>
	<section>
		<h3 class="mb-2">
			{{ $t('modules.academic.professionalizingPractice.update.title') }}
		</h3>
		<v-row>
			<v-col class="d-flex flex-coloumn">
				<div class="mr-8">
					<h4 class="font-weight-regular">
						{{ $t('modules.academic.professionalizingPractice.update.steppers.finish.startPP') }}
					</h4>
					<div class="d-flex align-center">
						<v-icon small class="mr-2 primary--text">mdi-calendar-range</v-icon>
						<h3 class="primary--text">{{ dataPP.fechaInicioPP }}</h3>
					</div>
				</div>
				<div class="">
					<h4 class="font-weight-regular">
						{{ $t('modules.academic.professionalizingPractice.update.steppers.finish.endPP') }}
					</h4>
					<div class="d-flex align-center">
						<v-icon small class="mr-2 primary--text">mdi-calendar-range</v-icon>
						<h3 class="primary--text">{{ dataPP.fechaFinPP }}</h3>
					</div>
				</div>
			</v-col>
			<v-col class="d-flex justify-end">
				<v-btn
					class="mr-8 pa-6"
					rounded
					:disabled="datosCompany.estadoPPId != 269 || dataPP.aproboMateriaPP == 364 || loading"
					@click="updateCheckAprobo(364)"
				>
					<v-icon v-if="dataPP.aproboMateriaPP == 364">mdi-check</v-icon>
					{{ $t('modules.academic.professionalizingPractice.update.steppers.finish.disapproved') }}
				</v-btn>
				<v-btn
					class="primary pa-6"
					rounded
					:disabled="datosCompany.estadoPPId != 269 || dataPP.aproboMateriaPP == 363 || loading"
					@click="updateCheckAprobo(363)"
				>
					<v-icon v-if="dataPP.aproboMateriaPP == 363">mdi-check</v-icon>
					{{ $t('modules.academic.professionalizingPractice.update.steppers.finish.approved') }}
				</v-btn>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import { $updateCheckAprobo } from '../../Services';

export default {
	name: 'FinishedPP',
	props: ['dataPP', 'datosCompany', 'loading'],
	methods: {
		async updateCheckAprobo(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckAprobo.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value },
				});
				this.dataPP.aproboMateriaPP = value;
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
	},
};
</script>
