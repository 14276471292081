import { i18n } from '../plugins/i18n';
import store from '../../store'
import AuthService from '../plugins/auth.service'

export function fullfillMiddleware(res) {
  return res;
}

export function rejectMiddleware(error) {
  if (error.status == 401) AuthService.logout()

  const appError = {
    title: '',
    message: '',
    status: 0,
    code: '',
    codeMeaning: ''
  };

  const lang = store.getters['settings/getLanguageLocate'] ? store.getters['settings/getLanguageLocate'] : 'es' 

  if (error.data) {
    if (error.data.errorDescription) {
      appError.code = error.data.errorDescription;
      appError.codeMeaning = i18n.messages[lang].responseCodeMeaning[error.data.errorDescription] ? i18n.messages[lang].responseCodeMeaning[error.data.errorDescription] : i18n.messages[lang].responseCodeMeaning.default
    }

    if (error.data.errorCode) {
      appError.status = error.data.errorCode;
    }

    if (!appError.code) {
      appError.message = error.data
    }
  }
  
  return Promise.reject(appError);
}

export default [
  fullfillMiddleware,
  rejectMiddleware
];
