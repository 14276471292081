import Products from '../Products'
import ProductsCreate from '../views/ProductsCreate';
import ProductsTable from '../views/ProductsTable';
import ProductsUpdate from '../views/ProductsUpdate';

const ProductsRoute =
    {
        path: '/products',
        meta: {
            name: {
                en: 'Product',
                es: 'Producto',
            }
        },
        component: Products,
        children: [
            {
                path: '/',
                component: ProductsTable,
                meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'products', permission: 'READ' }
            }, {
                path: 'create',
                component: ProductsCreate,
                meta: {name: 'ProductsCreate', group: 'portaladministrativo.prices', path: 'products', permission: 'CREATE'}
            }, {
                path: 'update/:id',
                component: ProductsUpdate,
                meta: {name: 'ProductsUpdate', group: 'portaladministrativo.prices', path: 'products', permission: 'READ'}
            },
        ]
    };

export default ProductsRoute
