import Exams from '../Exams.vue';
import ExamsView from '../Views/ExamsView.vue';

const ExamsRoute =
{
    path: '/exams',
    meta: {
        name: {
            es: 'Exámenes',
            en: 'Exams',
        }
    },
    component: Exams,
    children: [
        {
            path: '/',
            component: ExamsView,
            meta: { name: 'Index', group: 'portalalumno', path: 'exams', permission: 'READ' }
        }
    ]
};

export default ExamsRoute;
