<template>
	<section class="d-flex justify-center">
		<v-data-table
			:headers="headers"
			:items="studentDocs"
			:items-per-page="50"
			class="elevation-0 text--secondary"
			hide-default-footer
			style="width: 100%"
			dense
			:loading="loading"
		>
			<template v-slot:item="{ item }">
				<tr v-if="selected && item.id == selected.id" class="grey lighten-3">
					<td style="text-transform: capitalize">
						{{ item.typeDescription ? item.typeDescription.toLowerCase() : '' }}
					</td>
					<td>
						<v-select
							class="mt-2"
							outlined
							:disabled="loading"
							v-if="subtypeSimpleDocs"
							:items="subtypeSimpleDocs"
							item-text="description"
							item-value="id"
							v-model="selected.subtypeId"
						/>
						<span v-else>{{ item.subtypeDescription }}</span>
					</td>
					<td style="text-transform: capitalize">
						{{ item.subtypeSubdescription ? item.subtypeSubdescription.toLowerCase() : '' }}
					</td>
					<td>
						<v-select
							class="mt-2"
							outlined
							:disabled="loading"
							:items="statusTypes"
							:value="item.statusId"
							item-text="text"
							item-value="value"
							v-model="selected.statusId"
						/>
					</td>
					<td>
						<v-btn :disabled="loading" icon small @click="download(item)">
							<v-icon>mdi-image-multiple</v-icon>
						</v-btn>
					</td>
					<td>
						<v-textarea 
							class="mt-2"
							outlined
							rows="1" 
							auto-grow 
							type="text" 
							v-model.trim="selected.commentary" 
							:error-messages="
								$v.selected.commentary.$dirty && $v.selected.commentary.$error
									? $t('modules.academic.documentation.form.invalid')
									: ''
							"
							@blur="$v.selected.commentary.$touch()"
							:disabled="loading" 
						/>
					</td>
					<td>{{ item.dueDate }}</td>
					<td>
						<div class="d-flex">
							<v-btn :disabled="loading" icon small @click="save(item)">
								<v-icon>mdi-check</v-icon>
							</v-btn>
							<v-btn :disabled="loading" icon small @click="selected = {}">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</td>
				</tr>
				<tr v-else>
					<!-- <td>{{ item.studentProcessingId }}</td> -->
					<td style="text-transform: capitalize">
						{{ item.typeDescription.toLowerCase() }}
					</td>
					<td>
						<span class="d-inline-block text-truncate" style="max-width: 150px; text-transform: capitalize">
							{{ item.subtypeDescription.toLowerCase() }}
						</span>
					</td>
					<td style="text-transform: capitalize">
						{{ item.subtypeSubdescription.toLowerCase() }}
					</td>
					<td>
						{{ item.status == 'Denegado' ? $t('modules.academic.documentation.status.rejected') : item.status }}
					</td>
					<td>
						<v-btn icon small @click="download(item)">
							<v-icon>mdi-image-multiple</v-icon>
						</v-btn>
					</td>
					<td>
						<span class="d-inline-block text-truncate" style="max-width: 150px">
							{{ item.commentary }}
						</span>
					</td>
					<td>{{ item.dueDate }}</td>
					<td>
						<v-btn icon small @click="edit(item)">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</td>
				</tr>
			</template>
		</v-data-table>
	</section>
</template>

<script>
import { $documentations } from '../Services';
import download from 'downloadjs';
import { helpers, maxLength } from 'vuelidate/lib/validators';

export default {
	name: 'StudentInnerTable',
	props: {
		student: Object,
		statusTypes: Array,
		docTypes: Array,
		foundStudentDocs: Object,
	},
	data() {
		return {
			headers: [
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.typeDescription'),
					value: 'typeDescription',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.subtypeDescription'),
					value: 'subtypeDescription',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.subtypeSubdescription'),
					value: 'subtypeSubdescription',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.status'),
					value: 'status',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.file'),
					value: 'file',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.commentary'),
					value: 'commentary',
					sortable: false,
				},
				{
					text: this.$t('modules.academic.documentation.studentInnerTable.headers.dueDate'),
					value: 'dueDate',
					sortable: false,
				},
				{
					text: '',
					value: 'edit',
					sortable: false,
				},
			],
			studentDocs: [],
			loading: false,
			selected: {},
			subtypeSimpleDocs: [],
		};
	},
	methods: {
		async fetchStudentDocs() {
			if (!this.student) return;
			this.studentDocs = [];

			if (this.foundStudentDocs?.documentation?.length) this.studentDocs = this.foundStudentDocs.documentation;
			else {
				try {
					this.loading = true;
					const response = await $documentations.find(`students/${this.student.studentId}/docs`, null, { params: {} });
					this.studentDocs = response.data.documentation;
				} catch {
					this.studentDocs = [];
				} finally {
					this.loading = false;
				}
			}
		},
		async save(item) {
			if (this.selected.id != item.id) return;
			try {
				this.loading = true;
				await $documentations.update(`students/${this.selected.id}`, {
					id: this.selected.id,
					commentary: this.selected.commentary ? this.selected.commentary : '',
					status: this.selected.statusId,
					subtypeId: this.selected.subtypeId,
				});
				this.selected = {};
				this.$v.$reset();
				this.$emit('update');
			} catch (e) {
				this.$store.dispatch('commons/openPopUp', {
					title: this.$t('responseCodeMeaning.E208'),
					actionPrimary: {
						text: this.$t('actions.accept'),
						callback() {},
					},
					icon: { color: 'error', name: 'mdi-close' },
					color: 'primary',
				});
				return e;
			} finally {
				this.loading = false;
			}
		},
		edit(item) {
			this.selected = { ...item };
			this.subtypeSimpleDocs = this.docTypes.find(docType => docType.id == item.typeId)?.subtypeSimpleDocs;
			this.$v.$reset();
		},
		async download(item) {
			if (!item) return;
			try {
				this.loading = true;
				const file = await $documentations.find(`students/${item.id}/file`, null, null);
				if (!file.data[0].file) throw 'Error';
				const transform = this.base64ToFile(file.data[0].file, file.data[0].filename);
				await download(transform, file.data[0].filename);
			} catch (e) {
				this.$store.dispatch('commons/openPopUp', {
					title: this.$t('responseCodeMeaning.E208'),
					actionPrimary: {
						text: this.$t('actions.accept'),
						callback() {},
					},
					icon: { color: 'error', name: 'mdi-close' },
					color: 'primary',
				});
				return e;
			} finally {
				this.loading = false;
			}
		},
		base64ToFile(base64data, fileName) {
			const bs = atob(base64data);
			const buffer = new ArrayBuffer(bs.length);
			const ba = new Uint8Array(buffer);
			for (let i = 0; i < bs.length; i++) {
				ba[i] = bs.charCodeAt(i);
			}
			return new File([ba], fileName);
		},
	},
	mounted() {
		this.fetchStudentDocs();
	},
	watch: {
		student: {
			handler() {
				this.fetchStudentDocs();
			},
			deep: true,
		},
	},
	validations: {
		selected: {
			commentary: { maxLength: maxLength(32), alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i) },
		},
	},
};
</script>

<style scoped lang="sass">
.v-select
    max-width:150px !important
</style>
