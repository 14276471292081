const paymentIntentsTranslations = {
  es: {
    breadcrumbs:{
      title:'Precio por mesa',
      description:'Configurar precios de cada mesa de examen'
    },
    card:{
      title:'Configuración de pagos'
    },
    input:{
      try:'Intento N°',
      pay:'Pago'
    },
    messages_success:{
      title:'Modificaciones realizadas con éxito',
      primaryBottom:'Hacer otro cambio',
    },
    messages_error:{
      title:'Se produjo un error al intentar guardar',
    }
  },
  
  en: {
  }
};


export default paymentIntentsTranslations
