
const commissionsTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Comisiones',
                description: 'Administración de comisiones.'
            },
            headers: {
                billingProduct: 'Aplica a',
                commission_percentage: 'Comisión',
                studentType: 'Tipo estudiante',
                date: 'Fecha',
            },
            filters: {
                title: 'Refinar búsqueda',
                product: 'Producto',
                studenType: 'Tipo de estudiante',
                date: 'Fecha',
                from: 'Desde',
                to: 'Hasta',
            },
            bulk_delete_title: 'Eliminar comisiones',
            error_message: {
                delete_message: 'No se pudo eliminar la comisión',
                bulk_delete_message: 'No se pudieron eliminar las comisiones seleccionadas'
            },
        },
        create: {
            breadcrumbs: {
                title: 'Nueva comisión',
                description: 'Crea una nueva comisión.'
            },
            sectionData: {
                title: 'Datos de la comisión',
                subtitle: 'Agrega la información básica de la comisión y su porcentaje.',
                items: {
                    billingProduct: 'Producto de facturación',
                    relation: 'Aplica a',
                    from: 'Aplica desde',
                    percentage: 'Porcentaje',
                },
                error: {
                    selectedStudentTypes: 'Debes seleccionar al menos un tipo de estudiante',
                }
            },
            validations: {
              percentageRequired: 'El porcentaje es requerido y debe ser mayor a cero',
              billingProductRequired: 'Seleccione un producto de facturación',
            },
            success_message: {
              title: 'Comisión creada correctamente',
            },
            error_message: {
                title: 'Se produjo un error al crear la comisión',
            },
          },
          update: {
            breadcrumbs: {
              title: 'Editar comisión',
              description: 'Edita la comisión seleccionada'
            },
            success_message: {
              title: 'Comisión modificada correctamente',
            },
            sectionData: {
              title: 'Datos de la comisión',
              subtitle: 'Edite la información básica de la comisión.',
              labels: {
                billingProduct: 'Producto de facturacíon',
                studentType: 'Tipo de estudiante',
                date: 'Aplica desde',
                percentege: 'Porcentaje',
              },
            },
            validations: {
              percentageRequired: 'El porcentaje es requerido',
            },
            error_message: {
                title: 'Se produjo un error al modificar la comisión',
            },
          },
    },
    en: {
        
    }
};

export default commissionsTranslations