<template>
    <div>
        <v-row>
            <v-col sm="4">
                <div class="v-center pb-2">
                    <p>
                        {{ $t('modules.benefits.create.referred_friend.referred_label') }}
                    </p>
                </div>
            </v-col>
            <v-col sm="3">
                <v-text-field
                    :label="`${$t('modules.benefits.create.referred_friend.amount')} *`"
                    :error-messages="(friend.referredAmount.$error && friend.referredAmount.$dirty) ? $t('actions.error') : ''"
                    :input="friend.referredAmount.$model"
                    type="text"
                    outlined
                    @keypress="$validateDecimalInputNumber($event, friend.referredAmount.$model)"
                    v-model.trim="friend.referredAmount.$model"
                    @blur="friend.referredAmount.$touch()"
                    class="no-message"
                >
                  <template v-slot:prepend>
                    <span>{{ $countryConstants.currency }}</span>
                  </template>
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="4">
                <div class="v-center pb-2">
                    <p>
                        {{ $t('modules.benefits.create.referred_friend.refer_label') }}
                    </p>
                </div>
            </v-col>
            <v-col sm="3">
                <v-text-field
                    :label="`${$t('modules.benefits.create.referred_friend.amount')} *`"
                    :error-messages="(friend.referAmount.$error && friend.referAmount.$dirty) ? $t('actions.error') : ''"
                    type="text"
                    outlined
                    @keypress="$validateDecimalInputNumber($event, friend.referAmount.$model)"
                    v-model.trim="friend.referAmount.$model"
                    @blur="friend.referAmount.$touch()"
                    class="no-message"
                >
                  <template v-slot:prepend>
                    <span>{{ $countryConstants.currency }}</span>
                  </template>
                </v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="sass" scoped>
    .v-center
        height: 100%
</style>

<script>
    export default {
        name: 'ReferredFriend',
        props: {
            friend: Object,
        },
    }
</script>