<template>
    <div :class="`pa-4 text-${align}`" :style="`background-color: ${backgroundColor}; border-radius: 5px;`">
        <span class="d-block title font-weight-bold">{{ title }}</span>
        <span v-if="subtitle" class="d-block body-2">{{ subtitle }}</span>
    </div>
</template>

<script>
    export default {
        name: 'TextBanner',
        props: {
            title: { type: String, default: null },
            subtitle: { type: String, default: null },
            align: { type: String, default: 'center' },
            'background-color': { type: String, default: '#efecec' },
        }
    }
</script>