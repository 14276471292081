<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container class="container-custom px-6">
      <Breadcrumbs
        :title="$t('modules.exam_enabler.table.breadcrumbs.title')"
        :description="$t('modules.exam_enabler.table.breadcrumbs.description')"
      ></Breadcrumbs>
      <div class="px-10 mb-12">
        <div class="d-flex justify-space-between align-center mt-5 mb-2">
          <span class="title">{{ $t('modules.exam_enabler.table.references_card.available_tables') }}</span>
          <div class="justify-center d-flex">
            <span class="font-weight-bold subtitle-2 mr-3">{{ $t('modules.exam_enabler.update.references_card.references.title') }}</span> 
            <div class="d-flex reference mx-3 subtitle-2 align-center">
              <v-icon size="12" color="orange" class="mr-2">mdi-circle</v-icon>
              <span>{{ $t('modules.exam_enabler.update.references_card.references.laboratory_exam') }}</span>
            </div>
            <div class="d-flex reference mx-3 subtitle-2 align-center">
              <v-icon size="12" color="indigo" class="mr-2">mdi-circle</v-icon>
              <span>{{ $t('modules.exam_enabler.update.references_card.references.notebooks_exam') }}</span>
            </div>
          </div>
        </div>
        <v-row class="mb-5">
          <v-col sm="3" md="2">
            <span class="body-1">{{ $t('modules.exam_enabler.table.references_card.filter') }}</span>
          </v-col>
          <v-col sm="4" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="formatDateToCurrent(date)"
                  readonly
                  :label="'Fecha'"
                  append-icon="mdi-calendar"
                  outlined
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @change="$refs.menu.save(date)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="4" md="3">
            <v-autocomplete 
              label="CSE"
              :items="ceos"
              item-text="description"
              v-model="ceoSelected"
              return-object
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <div class="mt-3">
          <v-row>
            <v-col sm="2">
              <div class="font-weight-bold primary--text body-2">
                {{ $t('modules.exam_enabler.table.headers.tables_type') }}
              </div>
            </v-col>
            <v-col  sm="5">
              <div class="font-weight-bold primary--text body-2">
                {{ $t('modules.exam_enabler.table.headers.schedule') }}
              </div>
            </v-col>
            <v-col  sm="2">
              <div class="font-weight-bold primary--text body-2 d-flex justify-center">
                {{ $t('modules.exam_enabler.table.headers.students') }}
              </div>
            </v-col>
            <v-col  sm="3">
              <div class="font-weight-bold primary--text body-2 d-flex justify-center">
                {{ $t('modules.exam_enabler.table.headers.status') }}
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-center py-5 my-2" v-if="items.length === 0">
          {{ $t('modules.exam_enabler.table.empty_boards') }}
        </div>  
        <div class="table-card py-5 my-2" v-for="item in items" :key="item.id" @click="toDetail(item.id)">
          <v-row>
            <v-col sm="2">
              <div class="font-weight-bold primary--text body-2 px-5">
                <v-tooltip top v-if="item.type.laboratory">
                  <template v-slot:activator="{on}">
                    <v-icon v-on="on" size="12" color="orange" class="mr-3">mdi-circle</v-icon>
                  </template>
                  <span>{{ $t('modules.exam_enabler.update.references_card.references.laboratory_exam') }}</span>
                </v-tooltip>
                <v-tooltip top v-if="item.type.notebook">
                  <template v-slot:activator="{on}">
                    <v-icon v-on="on" size="12" color="indigo">mdi-circle</v-icon>
                  </template>
                  <span>{{ $t('modules.exam_enabler.update.references_card.references.notebooks_exam') }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col sm="5">
              <div class="font-weight-bold primary--text body-1">{{ item.schedule }}</div>
            </v-col>
            <v-col sm="2" class="d-flex justify-center">
              <div class="px-3 body-1">{{ item.students }}</div>
            </v-col>
            <v-col sm="3" class="d-flex justify-center">
              <div class="px-3 body-1">
                <v-icon color="red" v-if="item.errors">mdi-alert-circle</v-icon>
                <v-icon color="green" v-else>mdi-check-circle</v-icon>
                <span class="ml-1 text-muted">{{ $tc('modules.exam_enabler.table.items.status', item.errors, {count: item.errors}) }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import {$ceos, $exams} from '../Services'
import moment from 'moment';

export default {
  name: 'ExamEnablerTable',
  components:{
    Breadcrumbs,
  },
  data(){
    return{
      ceos: [],
      ceoSelected: null,
      items:[],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
    }
  },
  methods:{
    formatDateToCurrent (date) {
      return date ? moment(date).format('DD/MM/YYYY') : null
    },
    toDetail(id){
      this.$router.push(`exam-enabler/update/${id}`)
    },
    async initialize(){
      if(this.canRead){
        const ceos = await $ceos.find(null, null, {
          params: {
            length: 500,
            orderBy: 'description',
            orientation: 'asc'
          }
        });
        this.ceos = ceos.data.content
      }else{
        this.ceos = this['commons/getUserCeos']
      }
      if(this.ceos.length === 1) [this.ceoSelected] = this.ceos
    },

    async getItems(){
      const date = this.date.replace(/-/g, '/')
      const exam_boards = await $exams.find(`periods-by-ceo?ceoId=${this.ceoSelected.id}&date=${date}`)
      const now = new Date().toISOString().substr(0, 10)

      exam_boards.data.sort((a, b) => {
        return new Date(`${now}T${a.turnStart}`) - new Date(`${now}T${b.turnStart}`);
      });

      this.items = exam_boards.data.map(board =>{
        return {
          id: board.id[1] ? `${board.id[0]}-${board.id[1]}` : `${board.id[0]}`,
          schedule: this.$t('modules.exam_enabler.table.items.schedule',{start:board.turnStart,end:board.turnEnd}),
          type: {notebook: board.notebook, laboratory: board.laboratory},
          students: this.$t('modules.exam_enabler.table.items.students',{students:board.studentCount, total:board.studentCapacity}),
          errors: board.errors,
        } 
      })
    }
  },
  watch:{
    ceoSelected(val) {
      if (val && this.date) this.getItems()
    },
    date(val) {
      if (val && this.ceoSelected) this.getItems()
    },
  },
  mounted (){
    this.initialize()
  },
  computed:{
    canRead(){
      return this.$permissions.portaladministrativo.exams.enabler.READ
    },
  }
};
</script>

<style lang="sass" scoped>
  .tables-filter
    width: 150px

  .table-card
    margin: auto
    border-radius: 5px
    border: 1px solid #ccc
    &:hover
      border: 1px solid #7986CC
      cursor: pointer
      background: #7986CC22

  .text-muted
    color: #747474
</style>