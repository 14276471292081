<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
        :description="$t('modules.exams_group.statements.subjectsTable.breadcrumbs.description')"
        :title="$t('modules.exams_group.statements.subjectsTable.breadcrumbs.title')"
        >          
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
                :headers="headers"
                :data="subjects"
                :page.sync="page"
                :pageCount="pagesAmount"
                @searchAction="searchAction"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                :itemsPerPage.sync="itemsPerPage"
                :totalItems="totalItems"
                @click-row="openProgram"
                :show-select="false"
                />
          </v-col>
      </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import SuperTable from '../Components/SuperTable'
import { $statements } from '../Services/index'

export default {
name:'SubjectsView',
components: {
  SuperTable,
  Breadcrumbs,
},

data() {
  return {
    subjects: [],
    periods:[],

    page: 1,
    itemsPerPage: 20,
    totalItems: 0,
    pagesAmount: 0,
    search: null,
    sort: {
      sortBy: 'description',
      sortDesc: false,
    },
    
    headers:[
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.code'),
        value: 'code',
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.subject'),
        value: 'description',
      },
      {
        text: this.$t('modules.exams_group.statements.subjectsTable.countPrograms'),
        value: 'countPrograms',
        align:'center',
        sortable:false
      },
    ]
  }
},
mounted () {
  this.getSubjects();
},
methods: {
  async getSubjects() {
    try {
      const params = {
          page: this.page - 1,
          length: this.itemsPerPage,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search
      }
      const subjects = await $statements.find('subjects', null, {params})
      this.totalItems = subjects?.data?.totalElements;
      this.pagesAmount = subjects.data.totalPages;
      this.subjects = subjects.data.content 
      } catch  {
          this.subjects = []
        }
    },
  openProgram(id,code,description) {
    this.$router.push({path: `statements/programs/${id}`, query: {code, description}})
  },
  searchAction(data) {
      this.search = data;
      this.getSubjects();
  },
  onInputPagination(event) {
      this.page = event;
      this.getSubjects();
  },
  onLengthPaginationChange(event) {
        this.page = 1
        this.itemsPerPage = event
        this.pagesAmount= 1
        this.getSubjects();
},
},
watch: {
  sort: {
      handler(){
        this.getSubjects();
      },
      deep: true
  }
}
}
</script>
