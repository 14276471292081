import NotFoundState from '@/components/NotFoundState/NotFoundState'
import AdmissionView from './Admission';
import Preregistration from './Preregistration/Preregistration';
import PreregistrationRoute from './Preregistration/Routes';

const AdmissionRoute = {

  path: '/admission',
  meta: {
    name: {
      en: 'Admission',
      es: 'Admisión'
    },
  },
  component: AdmissionView,
  children:[
    {
      path: '/',
      component: NotFoundState,
    },{
      path:'preregistration',
      meta: { name: { es: 'Preinscripción', en: 'Preregistration' } },
      component: Preregistration,
      children: PreregistrationRoute
    }
  ]
};

export default AdmissionRoute
