<template>
  <v-container class="superTable pa-0" fluid>
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          :placeholder="$t('actions.search')"
          class="superTable__search"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="sanctions"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :totalItems="totalItems"
          show-select
          class="superTable__table"
          hide-default-footer
          item-key="id"
          style="table-layout: fixed"
          v-model="selectedItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
        >
          <template v-if="permissions.delete" v-slot:top>
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                                <span
                                  @click="$emit('bulk-delete', selectedItems)"
                                  class="caption mx-4 cursor-pointer hover-7-inverted"
                                  v-if="permissions.delete"
                                ><v-icon class="mr-2" size="22">mdi-delete</v-icon>{{ deleteTitle }}</span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template  v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props"
                               v-on="on"></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox :value="isSelected" @input="select($event)" class="superTable__table__item__checkbox"
                               color="primary"></v-simple-checkbox>
          </template>

          <template v-slot:[`item.productType`]="{item}">
            <v-row v-bind:key="type.id" v-for="type in item.productType">
              <v-col class="py-0" sm="12">
                {{ type.name }}
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-icon :color="(item.status) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.percentage`]="{item}">
            <v-row v-bind:key="type.id" v-for="type in item.percentage">
              <v-col class="py-0" sm="12" v-if="type.percentage">
                {{ (type.amount) ? $options.filters.$currency(type.percentage) : `${type.percentage} %` }}
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-menu bottom left>
              <template v-if="permissions.update || permissions.delete" v-slot:activator="{ on }">
                <v-icon class="superTable__table__item__action" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.update" @click="openDetails(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-eye</v-icon>
                    {{$t('actions.details')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="deleteItem(item.id)"
                  v-if="permissions.delete"
                >
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{ $t('actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>

      <v-col sm="3">
          <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
          <v-select
            :items="itemsPerPageAvailable"
            :placeholder="itemsPerPage.toString()"
            class="superTable__paginationSelect d-inline-block ml-4"
            outlined
            v-model="itemsPerPage"
            @change="$emit('length-pagination-change', itemsPerPage)"
          ></v-select>
        </v-col>
      


      <v-col class="text-center" sm="6">
        <v-pagination 
        :length="pageCount" 
        class="superTable__pagination" 
        :value="page"
        @next="$emit('next-pagination', page)"
        @previous="$emit('previous-pagination', page)"
        @input="$emit('input-pagination', $event)"
        ></v-pagination>
      </v-col>
      <v-col align="right" sm="3">
        <v-text-field
          class="superTable__paginationInput d-inline-block mr-4"
          max="pageCount"
          outlined
          v-model.number="goToPage"
          @blur="changePage"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
  .v-expansion-panels
    .v-expansion-panel-content
      padding-left: 10px
      padding-bottom: 0px
      padding-right: 16px
</style>

<script>
  import Constants from '@/plugins/constants';

  export default {
    name: 'SuperTable',

    props: {
      sanctions: Array,
      headers: Array,
      clearSelected: String,
      deleteTitle: String,
      page: { type: Number, default: 1 },
      permissions: {update: false, delete: false},
      sortBy: String,
      sortDesc: Boolean,
      pageCount: { type: Number, default: 1 },
      totalItems: Number
    },
    data() {
      return {
        selectedItems: [],
        timeout: null,
        search: '',
        itemsPerPage: 20,
        itemsPerPageAvailable: [20, 50, 100, 200],
        markedUsers: [],
        goToPage: 1,
      }
    },
    methods: {
      getAvatar(id) {
        return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
      },
      editItem(id) {
        this.$emit('updateUser', id);
      },
      deleteItem(id) {
        this.$emit('deleteUser', id);
      },
      openDetails(id) {
      this.$emit('openDetails', id);
      },

      keyup () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
      },
      searchAction() {
        this.$emit('searchAction', this.search)
      },
      changePage() {
        if (this.goToPage > this.pageCount) this.goToPage = this.page;
        else this.$emit('input-pagination', this.goToPage)
      }
    },
    watch: {
        page (val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val < 1) this.page = 1;
        if (this.page !== this.goToPage) this.goToPage = this.page;
      },
      clearSelected() {
        this.selectedItems = []
      },
    },
    computed: {
      areItemsSelected() {
        return !!this.selectedItems.length
      },
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };

        if (counter.endItem > this.totalItems) {
          counter.endItem = this.totalItems
        }

        return counter
      }
    },
  };
</script>
