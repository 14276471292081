
const quartersTranslations = {
  es_PE: {
      errors: {
          sectionContact: {
              items: {
                  address: {
                      cityWithState: 'Debes seleccionar un distrito',
                  },
              }
          }
      },
      create: {
          sectionContact: {
              items: {
                  cityWithState: 'Distrito',
              }
          },
      },
      update: {
          sectionContact: {
              items: {
                  cityWithState: 'Distrito'
              }
          },
      },
      detail: {
          quarter_contact: {
              items: {
                  city: 'Distrito'
              },
          }
      },
  },  
  es: {
    moduleState: {
      enable: 'Habilitado',
      disable: 'Deshabilitado',
    },
    success: {
      popup: {
        btnPrimary: 'Aceptar',
        btnSecondary: 'Crear otro',
        title: 'Centro {action} con éxito'
      }
    },
    warnings: {
      moduleDisable: {
        btnPrimary: 'Aceptar',
        btnCancel: 'Cancelar',
        title: 'Estás creando un CSE deshabilitado',
      },
      list: {
        title: 'Estos CSEs tienen estudiantes'
      }
    },
    errors: {
      popup: {
        btnPrimary: 'Aceptar',
        title: 'El CSE no se pudo {action}.',
      },
      list: {
        title: '¿Estás seguro de eliminar?'
      },
      sectionData: {
        items: {
          description: 'Debes ingresar un nombre de CSE',
          code: {
            duplicate: 'El código ya existe',
            required: 'Debes ingresar un código  '
          }
        }
      },
      sectionContact: {
        items: {
          address: {
            street: 'Debes ingresar una calle',
            cityWithState: 'Debes seleccionar una ciudad',
          },
          celNumber: 'Debes ingresar un celular',
          email: {
            required: 'Debes ingresar un Email',
            email: 'Debes ingresar un Email válido',
          }
        }
      },
      sectionSchedule: {
        items: {
          schedule: 'Debes ingresar un horario'
        }
      },
      sectionBilling: {
        items: {
          bankAccountNumber: 'Debes ingresar un número de cuenta',
          accountName: 'Debes ingresar el nombre de la cuenta',
          bankCode: 'Debes ingresar el código del banco',
          bank: 'Debes ingresar el nombre del banco',
          branchCode: 'Debes ingresar el código de la sucursal',
          branch: 'Debes ingresar el nombre de la sucursal',
        }
      }
    },
    create: {
      breadcrumbs: {
        title: 'Nuevo Centro de Servicios',
        description: 'Crea un nuevo CSE'
      },
      sectionData: {
        title: 'Datos del centro',
        subtitle: 'Ingresa la información básica del nuevo CSE que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        items: {
          description: 'Nombre',
          code: 'Código',
          test: 'Habilita examen',
          testDescription: 'Activa esta opción para otorgarle al CSE el permiso de tomar exámenes.',
        }
      },
      sectionContact: {
        title: 'Datos de contacto',
        subtitle: 'Los datos que definas en esta sección permitirán localizar y contactar al CSE fácilmente. Recuerda mantener estos datos actualizados, ya que también figurarán en los motores de búsqueda.',
        items: {
          addressName: 'Calle',
          addressNumber: 'Número',
          addressFloor: 'Piso',
          addressApartment: 'Dpto.',
          zipCode: 'Código postal',
          zone: 'Región',
          cityWithState: 'Ciudad',
          landline: 'Teléfono fijo',
          mobilePhone: 'Teléfono móvil',
          email: 'Correo Electrónico'
        }
      },
      sectionSchedule: {
        title: 'Horarios de atención',
        subtitle: 'Aquí podrás seleccionar los días y horarios en los cuales el CSE estará disponible.',
        items: {
          weekdays: [
            'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'
          ],
          from: 'Desde',
          to: 'Hasta',
          hours: 'hs.'
        }
      },
      sectionBilling: {
        title: 'Datos de facturación',
        subtitle: 'Proporciona los datos de facturación para realizar diferentes transacciones.',
        items: {
          bankAccountNumber: 'CBU',
          bankAccountName: 'Nombre de cuenta',
          bankCode: 'Código de banco',
          bankName: 'Banco',
          subsidiaryCode: 'Código de sucursal',
          subsidiaryName: 'Sucursal'
        }
      },  
      sectionCareers: {
        title: 'Carreras',
        subtitle: 'En esta sección podrás asignar las carreras que estarán disponibles para el nuevo CSE.'
      },
      sectionUsers: {
        title: 'Usuarios',
        subtitle: 'Selecciona los usuarios para el nuevo CSE. Ten en cuenta que si el usuario que buscas no está disponible en esta lista, es posible que ya se encuentre asignado a otro CSE.'
      },
      warning: {
        no_selected_users: 'Debes seleccionar al menos un usuario',
        no_selected_careers: 'Debes seleccionar al menos una carrera'
      }
    },
    update: {
      breadcrumbs: {
        title: 'Edición de centro',
        description: 'Modifica el CSE seleccionado.'
      },
      sectionData: {
        title: 'Datos del centro',
        subtitle: 'Asigna la información básica del CSE que está creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        items: {
          description: 'Nombre',
          code: 'Código',
          test: 'Habilita examen',
          testDescription: 'Activa esta opción para otorgarle al CSE el permiso de tomar exámenes.',
        }
      },
      sectionContact: {
        title: 'Datos de contacto',
        subtitle: 'Los datos que definas en esta sección, permitirán localizar y contactar al centro facilmente. Recuerda mantener estos datos actualizados, ya que también figurarán en los motores de búsqueda.',
        items: {
          addressName: 'Calle',
          addressNumber: 'Número',
          addressFloor: 'Piso',
          addressApartment: 'Dpto.',
          zipCode: 'Código postal',
          zone: 'Región',
          cityWithState: 'Ciudad',
          landline: 'Teléfono fijo',
          mobilePhone: 'Teléfono móvil',
          email: 'Correo Electrónico'
        }
      },
      sectionSchedule: {
        title: 'Horarios de atención',
        subtitle: 'Aquí podrás seleccionar los días y horarios en los cuales el CSE estará disponible.',
        items: {
          weekdays: [
            'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'
          ],
          from: 'Desde',
          to: 'Hasta',
          hours: 'hs.'
        }
      },
      sectionBilling: {
        title: 'Datos de facturación',
        subtitle: 'Proporciona los datos de facturación que se utilizarán para realizar diferentes transacciones.',
        items: {
          bankAccountNumber: 'CBU',
          bankAccountName: 'Nombre de cuenta',
          bankCode: 'Código de banco',
          bankName: 'Banco',
          subsidiaryCode: 'Código de sucursal',
          subsidiaryName: 'Sucursal'
        }
      },
      sectionCareers: {
        title: 'Carreras',
        subtitle: 'En esta sección podrás asignar las carreras que estarán disponibles para el nuevo CSE.'
      },
      sectionUsers: {
        title: 'Usuarios',
        subtitle: 'Aqui podrás seleccionar los usuarios que corresponderán al nuevo CSE. Ten en cuenta que si el usuario que buscas no está disponible en esta lista, es posible que este asignado a otro CSE'
      },
      warning: {
        no_selected_users: 'Debes seleccionar al menos un usuario',
        no_selected_careers: 'Debes seleccionar al menos una carrera',
        asigned_users: 'La beca tiene asignado usuarios'
      }
    },
    list: {
      title: 'Centros de servicios',
      description: 'Administración de los diferentes CSEs.',
      warning_messages: {
        asigned_users: 'El CSE tiene usuarios asignados'
      },
      asidebar: {
        title: 'Refinar búsqueda',
        nameFilter: 'Nombre',
        zonesFilter: 'Región',
        stateFilter: 'Estado',
        stateActive: 'Activo',
        stateInactive: 'Inactivo',
        careersFilter: 'Carreras',
        studentsFilter: 'Estudiantes',
      },
      table: {
        btnBulkDelete: 'Eliminar CSEs',
        btnDelete: 'Eliminar',
        btnEdit: 'Editar',
        searchPlaceholder: 'Buscar',
        goToPage: 'Ir a la página',
        headers: {
          code: 'Código',
          description: 'Nombre',
          zone: 'Región',
          status: 'Estado',
          careers: 'Carreras',
          students: 'Estudiantes',
        }
      }
    },
    detail: {
      breadcrumbs: {
        title: 'Detalle del CSE',
        button: {
          icon: 'mdi-pencil',
          name: 'Editar',
          color: 'primary'
        }
      },
      quarter_data: {
        title: 'Datos institucionales',
        items: {
          'name': 'Nombre',
          'code': 'Código',
          'status': 'Permiso de examen',
        }
      },
      quarter_contact: {
        title: 'Datos de contacto',
        items: {
          street: 'Calle',
          number: 'Número',
          floor: 'Piso',
          department: 'Departamento',
          postalcode: 'Código postal',
          zone: 'Región',
          notAssigned: 'Sin asignar',
          city: 'Ciudad',
          telephone: 'Teléfono fijo',
          whatsapp: 'Teléfono celular',
          email: 'Correo Electrónico',
        },
      },
      quarter_schelude: {
        title: 'Horario de atención',
        items: {
          'scheludes': 'Horario de atención'
        }
      },
      quarter_billing: {
        title: 'Datos de facturación',
        items: {
          cbu: 'CBU',
          account: 'Nombre de cuenta',
          bankcode: 'Código del banco',
          bank: 'Banco',
          branchcode: 'Código de sucursal',
          branch: 'Sucursal',
        },
      }
    },
  },
  en: {}
};


export default quartersTranslations