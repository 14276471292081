<template>
  <v-sheet class="SpecialCheck-container" :class="{'selected': selected == true}">
    <v-checkbox
      :value="selected"
      :label="label"
      color="primary"
      hide-details
      readonly
      class="mt-0 pt-0"
    ></v-checkbox>
    <h3 class="align-self-center primary--text">{{ price.amount | $currency }}</h3>
  </v-sheet>
</template>

<script>
  export default {
    name: 'SpecialCheck',
    props: {
      label: String,
      price: Object
    },
    data() {
      return {
        selected: false,
      }
    },
    mounted() {
      this.selected = this.price.selected;
    },
    methods: {
      onClick() {
        this.selected = !this.selected
        this.price.selected = this.selected
        this.$emit('update')
      }
    },
  }
</script>

<style lang="sass">
  .SpecialCheck-container
    display: flex
    cursor: pointer
    flex-flow: row nowrap
    justify-content: space-between
    align-content: center
    border-radius: 5px
    border: solid 1px #cecece
    padding: 20px 10px
    background-color: #f5f6f9 !important
    border: 1px solid #d3d3d3 !important

    &.selected
      background-color: #d9e2fa !important
      border: 1px solid #6087e1 !important

</style>
