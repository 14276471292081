<template>
  <v-container fluid class="container-custom pb-8">
    <div>
    <Breadcrumbs
      :title="$t('modules.exams_group.statements.statementsCreate.breadcrumbs.title')"
      :description="$t('modules.exams_group.statements.statementsCreate.breadcrumbs.description')"
    ></Breadcrumbs>
    <v-container class="container-custom px-6" v-if="!newStatementSuccess">
      <v-row>
        <v-col>
          <OutlinedCard
            :title="$t('modules.exams_group.statements.statementsCreate.form.firstCard.title')"
            class="mb-6"
            @switchChange="(val) => (status = val)"
            :switch-label="(status) ? $t('enabled') : $t('disabled')"
            :switch-value="status"
            switcher
          >
            
            <v-row class="py-2" no-gutters>
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{ `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.modalityTypes')}*` }}</span>
              </v-col>
              <v-col class="align-center" sm="3">
                <v-select
                  :error-messages="
                  ($v.modalityType.$invalid && $v.modalityType.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
                  @blur="$v.modalityType.$touch()"
                  v-model="$v.modalityType.$model"
                  ref="modalityType"
                  :items="modalityTypes"
                  item-text="meaning"
                  item-value="value"
                  :label=" `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.modalityTypesLabel')}*` "
                  outlined
                  required
                ></v-select>
              </v-col>
            </v-row>
            
            <v-row class="py-2" no-gutters>
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{ `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.examTypes')}*` }}</span>
              </v-col>
              <v-col class="align-center" sm="3">
                <v-select
                  :error-messages="
                  ($v.examType.$invalid && $v.examType.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
                  @blur="$v.examType.$touch()"
                  v-model="$v.examType.$model"
                  ref="examType"
                  :items="examTypes"
                  item-text="meaning"
                  item-value="value"
                  :label=" `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.examTypesLabel')}*` "
                  outlined
                  required
                ></v-select>
              </v-col>
            </v-row>
            
            <v-row class="py-2" no-gutters>
              <v-col class="d-flex pt-4" sm="3">
                <span class="caption text-uppercase">
                  {{ $t('modules.exams_group.statements.statementsCreate.form.firstCard.questionTypes') }}
                </span>
              </v-col>
              <v-col class="align-center" sm="3">
                <v-radio-group v-model="$v.questionType.$model"
                :error-messages="
                  ($v.questionType.$invalid && $v.questionType.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require')
                  : '' "
                 class="mt-0">
                  <v-radio
                    v-for="n in questionTypes"
                    :key="n.value"
                    :label="`${n.meaning}`"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
           <v-row class="py-2" no-gutters>
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{ `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.difficulty')}*` }}</span>
              </v-col>
              <v-col class="align-center" sm="2">
                <v-select
                  :error-messages="
                  ($v.difficulty.$invalid && $v.difficulty.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
                  @blur="$v.difficulty.$touch()"
                  v-model="difficulty"
                  ref="difficulty"
                  :items="itemsDifficulty"
                  item-text="meaning"
                  item-value="value"
                  :label="`${$t('modules.exams_group.statements.statementsCreate.form.firstCard.difficultyType')}*`"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            
            <v-row class="py-2" no-gutters>
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{
                  "Tiempo de resolucion"
                }}</span>
              </v-col>
              <v-col class="align-center" sm="1">
                <v-text-field
                  @keypress.prevent
                  onPaste = "return false"
                  @keydown.prevent
                  delete = "return false"
                  min="0"
                  step="1"
                  max="10"
                  type="number"
                  outlined
                  v-model="maxTime"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row class="py-2" no-gutters>
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{
                  `${$t('modules.exams_group.statements.statementsCreate.form.firstCard.statementTypes')}*`
                }}</span>
              </v-col>
              <v-col class="align-center" sm="3">
                <v-select
                  :error-messages="
                  ($v.statementType.$invalid && $v.statementType.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
                  @blur="$v.statementType.$touch()"
                  v-model="$v.statementType.$model"
                  :items="statementTypes"
                  ref="statementType"
                  item-text="meaning"
                  item-value="value"
                  :label="`${$t('modules.exams_group.statements.statementsCreate.form.firstCard.statementTypesLabel')}*`"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

          </OutlinedCard>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <OutlinedCard :title="$t('modules.exams_group.statements.statementsCreate.form.secondCard.title')" class="mb-6">
            <v-switch v-model="isImageStatement" :label=" $t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')" @change="removeFileStatement($event)" ></v-switch>
            <v-card-text v-if="isImageStatement">            
              <v-row justify="center" class="" >
                <v-col cols="8" class="" align="center">
                  <DropFiles                  
                    title="Cargar archivo"
                    class=" text-center "
                    :extension="'.jpg, .png, .gif'"
                    :type-file="'image/*'"
                    :limit-size-bytes="'2000000'"
                    :required="$v.fileStatement.$anyError"     
                    ref="fileStatement"             
                    :show-title="true"
                    :show-dashed="true"
                    :size="'lg'"
                    :file-input.sync="$v.fileStatement.$model"
                    @remove="fileStatement = null"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-textarea              
              :error-messages="
              ($v.description.$invalid && $v.description.$dirty)
              ? `${$t('modules.exams_group.statements.statementsCreate.error_message.require')}*` : '' "
              @blur="$v.description.$touch()"
              v-model="$v.description.$model"
              ref="description"
              :label="`${$t('modules.exams_group.statements.statementsCreate.form.secondCard.descriptionLabel')}*`"
              name="input-7-4"
              height="44px"
              class="mt-6"
              outlined
              v-else
            ></v-textarea>
            <v-card-title
              primary-title
              class="headline font-weight-bold ma-0 px-0 pt-4"
            >
              {{ $tc('modules.exams_group.statements.statementsCreate.form.secondCard.answer',isTrueFalse ? 1 : 2) }}
            </v-card-title>

            <div v-if="!isTrueFalse">
            <v-row class="py-2" no-gutters >
              <v-col class="d-flex align-center" sm="3">
                <span class="caption text-uppercase">{{ `${$t('modules.exams_group.statements.statementsCreate.form.secondCard.quantity')}*`}}</span>
              </v-col>
              <v-col class="align-center" sm="1">
                <v-text-field
                  @keypress.prevent
                  onPaste = "return false"
                  v-model="$v.quantity.$model"
                  ref="quantity"
                  @blur="$v.quantity.$touch()"
                  :error-messages="
                    ($v.quantity.$invalid && $v.quantity.$dirty)
                    ? 'valor min 2' : '' "
                  type="number"
                  outlined
                  step="1"
                  max="5"
                  min="3"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card
              class="mb-5"
              v-for="(item, index) in $v.answerList.$each.$iter"
              :key="index"
            >
              <v-card-title
                primary-title
                class="d-flex justify-space-between align-baseline py-0"
              >
                <div class="d-flex align-baseline ml-6">
                  <h4 class="primary--text">
                    {{`${$t('modules.exams_group.statements.statementsCreate.form.secondCard.answerLabel')} N° ${Number(index) + 1}` }}
                  </h4>
                  <div v-if="questionType.value == 75">
                    <v-radio-group 
                      v-model="item.isCorrect.$model" 
                      class="mx-3"
                      hide-details
                      ref="answerList"
                      :error-messages="
                        (item.isCorrect.$invalid && item.isCorrect.$dirty)
                        ? $t('actions.error')
                        : '' " >
                      <v-radio class="ml-2" :label="$t('modules.exams_group.statements.statementsCreate.form.secondCard.isCorrect')" :value="true" @change="answerCorrectSelected(index)"></v-radio>
                    </v-radio-group>
                  </div>
                  <v-checkbox
                    hide-details
                    v-if="questionType.value == 76"
                    :error-messages="
                    (item.isCorrect.$invalid && item.isCorrect.$dirty)
                    ? $t('actions.error'):''"
                    ref="answerList"
                    :label="$t('modules.exams_group.statements.statementsCreate.form.secondCard.isCorrect')"
                    v-model="item.isCorrect.$model"
                    class="mx-5"
                  ></v-checkbox>
                </div>
                <v-switch
                  v-model="item.isImage.$model"
                  :label="$t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')"
                  class="mr-6"
                  @change="cleanFileAnswer($event, item, index)"
                ></v-switch>
              </v-card-title>
              <v-divider class="primary ma-auto" width="90%"></v-divider>
              <v-card-text v-if="item.isImage.$model">
                <v-row justify="center" class="">
                  <v-col cols="8" class="" align="center">
                    
                    <DropFiles
                      title="Cargar archivo"
                      extension=".jpg,.jpeg,.png"
                      typeFile="image.*"
                      limitSizeBytes="2000000"
                      @update:fileInput="setfileAnswers($event, item)"
                      :file-input="answerList[index].file"
                      @remove="removeFileAnswer($event.name, item)"
                      :required="$v.answerList.$each[index].$anyError"
                      :showTitle="true"
                      ref="answerList"
                      :showDashed="true"
                      :idDropFiles="index"
                      class="text-center"
                    />
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-text v-else class="px-10">
                <v-textarea
                  
                  :error-messages="
                  (item.description.$invalid && item.description.$dirty)
                  ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
                  @blur="item.description.$touch()"
                  v-model="item.description.$model"
                  ref="answerList"
                  :label="`${$t('modules.exams_group.statements.statementsCreate.form.secondCard.answerLabel')}*`"
                  name="input-7-4"
                  height="44px"
                  outlined
                  class=""
                ></v-textarea>                
              </v-card-text>
            </v-card>
            </div>

            <div v-else class="mt-4">
              <v-radio-group 
                v-for="(item, index) in $v.answerList.$each.$iter"
                :key="`radio-group-${index}`"
                v-model="item.isCorrect.$model"
                hide-details
                ref="answerList"
                :error-messages=" (item.isCorrect.$invalid && item.isCorrect.$dirty)
                  ? $t('actions.error')
                  : '' "
                 class="mt-0">
                  <v-radio
                    @change="answerCorrectSelected(index)"                                       
                    :label="item.$model.description"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
            </div>

          </OutlinedCard>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <OutlinedCard title="Justificacíon" class="mb-6">
            <v-switch
              v-model="isImageJustification"
              :label="$t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')"
              @change="removeFileJustification($event)"
            ></v-switch>
            <v-card-text v-if="isImageJustification">            
              <v-row justify="center" class="" >
                <v-col cols="8" class="" align="center">
                  <DropFiles
                    title="Cargar archivo"
                    class=" text-center "
                    :extension="'.jpg, .png, .gif'"
                    :type-file="'image/*'"
                    :limit-size-bytes="'2000000'"
                    :required="$v.fileJustification.$anyError"    
                    ref="fileJustification"              
                    :show-title="true"
                    :show-dashed="true"
                    :size="'lg'"
                    :file-input.sync="$v.fileJustification.$model"
                    @remove="fileJustification = null"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-textarea
              :error-messages="
              ($v.justification.$invalid && $v.justification.$dirty)
              ? $t('modules.exams_group.statements.statementsCreate.error_message.require') : '' "
              @blur="$v.justification.$touch()"
              v-model="$v.justification.$model"
              ref="justification"
              :label="`${$t('modules.exams_group.statements.statementsCreate.form.secondCard.descriptionLabel')}*`"
              name="input-7-4"
              height="88px"
              class="mt-6"
              outlined
              v-else
            ></v-textarea>
          </OutlinedCard>
        </v-col>
      </v-row>
      
      <v-row justify="end" class="">
        <div class="d-flex my-8">
          <Button
            class="ml-4"
            @clicked="$router.go(-1)"
            outlined
            depressed
            :text="$t('actions.cancel')"
          ></Button>
          <Button
            :loading="saveButton.loading"
            :success="saveButton.success"
            :error="saveButton.error"
            :successText="$t('actions.saved')"
            :text="$t('actions.save')"
            :errorText="$t('actions.error')"
            @end="resetButtonValues"
            @clicked="checkDisableAndCreate"
            depressed
            class="ml-4"
          ></Button>
        </div>
      </v-row>

    </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </div>
  </v-container>
</template>

<script>
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { $types } from '../Services/index.js';
import { required, requiredIf, requiredUnless } from 'vuelidate/lib/validators';
import DropFiles from '../Components/DropFiles';
import Button from '@/components/Button/Button';
import constants from '@/plugins/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'StatementsCreate',

  components: {
    OutlinedCard,
    Breadcrumbs,
    DropFiles,
    Button,
    SuccessMessage
  }, 

  data() {
    return {
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      itemsDifficulty: [1,3,5],
      modalityTypes: [],
      examTypes:[],
      questionTypes: [],
      statementTypes: [],
      isImageJustification: false,
      isImageStatement: false,
      fileList: [],
      quantity: 0,
      status: true,
      modalityType: null,
      examType:null,
      questionType: {
        value:null,
        meaning:null,
        type:null
      },
      difficulty: null,
      maxTime: 1,
      statementType: null,
      description: '',
      answerList: [],
      justification: '',
      
      
      fileStatement: null,
      fileAnswers: [],
      fileJustification: null,
      imageAnswer: null,
      newStatementSuccess:false,
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },

    }
  },

  validations: {
    modalityType:{ required },
    examType:{ required },
    difficulty: { required },
    statementType:{ required },
    quantity: {  
      required, 
      minTwo(){ return this.quantity > 1 } },
    fileStatement:{ required:requiredIf('requiredImageStatement') },
    fileJustification:{ required:requiredIf('requiredImageJustification') },
    questionType:{
      value:{required}
    },
    description:{ required:requiredUnless('requiredImageStatement') },
    answerList: {
      $each: {
        isImage: { required },
        isCorrect: {
          required,
          anyCorrect(){
            const checks = this.answerList.filter(e => e.isCorrect == true)
            return checks.length > 0 }
            },
        imgName: {required:requiredIf((value)=> value.isImage ) },
        description: { required:requiredUnless((value)=> value.isImage) },
        file:{required:requiredIf('requiredImageAnswer') }
      },      
    },
    justification:{ required:requiredUnless('requiredImageJustification') },
    
  },

  methods: {
    async getExamType() {
      try {
        const params = { type: 'STATEMENT_EXAM_TYPE' };
        const { data } = await $types.find(null, null, { params });
        this.examTypes = data;
      } catch {
        this.examTypes = [];
      }
    },
    async getModalityType() {
      try {
        const params = { type: 'STATEMENT_MOD_TYPE' };
        const { data } = await $types.find(null, null, { params });
        this.modalityTypes = data;
      } catch {
        this.modalityTypes = [];
      }
    },    
    async getQuestionType() {
      try {
        const params = { type: 'QUESTION_TYPE' };
        const res = await $types.find(null, null, { params });
        this.questionTypes = res.data;

        const [questionTypeDefault] = res.data
        this.questionType = questionTypeDefault
      } catch {
        this.questionType = [];
      }
    },            
    async getStatementType() {
      try {
        const params = { type: 'STATEMENT_TYPE' };
        const res = await $types.find(null, null, { params });
        this.statementTypes = res.data;      
      } catch {
        this.statementTypes = [];
      }
    },


    async checkDisableAndCreate(){
      if( this.$v.$invalid ) {
        this.$v.$touch()
          this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.exams_group.statements.statementsCreate.error_message.incomplete'),
          content: null,
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary'
        });
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false;
            if (this.$v[input].$error) {
              if(this.$refs[input][0]){
              for (let index = 0; index < this.$refs[input].length; index++) {
                const element = this.$refs[input][index];
                element.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                }   
              } else {
                this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
            }
              return true
          }
        })
      }
      else if(!this.status){
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.careers.create.disableCareerPopUp'),
            content: null,
            actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
            actionPrimary: { text: this.$t('actions.create'), callback: this.createStatement },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary'
        });
      }
      else
        this.createStatement()
    },
    async createStatement() {
      const statement = JSON.stringify({
        statementExamType: {value:this.examType},
        userId: this.idUser,
        content: { id: this.$route.params.content_id },
        status: this.status,
        modalityType: { value: this.modalityType },
        questionType: this.questionType,
        difficulty: this.difficulty,
        maxTime: this.maxTime,
        statementType: { value: this.statementType },
        description: this.description,
        justification: this.justification,
        answerDto: this.answerList
      });

      const body = {
        statement,
        imageStatement: this.fileStatement,
        imageAnswer: this.fileAnswers,
        imageJustification: this.fileJustification
      };
      
      const formData = new FormData();
      for (const key in body) {
        if (key == 'imageAnswer') {
          body[key].forEach((element) => {
            formData.append(key, element);
          });
        } else {
          formData.append(key, body[key]);
        }
      }
      
      try {
          this.saveButton.loading = true;
          const token = localStorage.getItem('access_token')
          ? JSON.parse(localStorage.getItem('access_token'))
          : '';

          const options = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body:formData
          };
          const res = await fetch(`${constants.URL_BASE}/q-system/statements/answersByStatement`,options);
          if(res.status != 201 && res.status != 200 ) throw res
          this.successMessage.type = 'success',
          this.successMessage.title = this.$t('modules.exams_group.statements.statementsCreate.success_message.title'),
          this.successMessage.actionPrimary = { text: this.$t('modules.exams_group.statements.programsCreate.finish'), callback: () => { this.$router.go(-1) } };
          this.successMessage.actionSecondary = {text: this.$t('actions.create_another'), callback: () => { this.newStatementSuccess = false; } };
          this.saveButton.success = true;
          this.clearData()
      } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = this.$t('modules.exams_group.statements.statementsCreate.error_message.fail_server');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.newStatementSuccess = false } }
          this.saveButton.error = true;
          throw error;
      } finally {
          this.saveButton.loading = false;
          this.newStatementSuccess = true;
      }
    },

    clearData() {
      this.resetButtonValues();
      this.isImageJustification= false,
      this.isImageStatement= false,
      this.fileList= [],
      this.quantity=3,
                  
      this.status= true,
      this.modalityType= null,
      this.difficulty= null,
      this.maxTime= 1,
      this.statementType= null,
      this.description= '',

      this.justification= '',
      
      this.fileStatement= '',
      this.fileAnswers= [],
      this.fileJustification= '',
      this.answerList.forEach( item => {
        item.description = ''
        item.isCorrect = false
        item.isImage = false
        item.file=null
      })
      this.$v.$reset();
    },


    setfileStatement(file) {
      this.fileStatement = file;
    },
    setfileAnswers(file, item) {
      this.fileAnswers.push(file);
      item.imgName.$model = file.name;
      item.file.$model= file;
    },    
    setfileJustification(file) {
      this.fileJustification = file;
    },

    removeFileStatement(event) {      
      if(!event && this.fileStatement){
        this.fileStatement = null
      }
    },

    removeFileAnswer(file, item) {
      const fileFilter = this.fileAnswers.filter(e => e.name != file.name);
      this.fileAnswers = fileFilter
      item.$model.file = null
      item.$model.imgName = ''
      item.$model.multimedia = null
    },

    removeFileJustification(event) {
      if(!event && this.fileJustification){
        this.fileJustification = null
      }    
    },

    cleanFileAnswer(event, item, index){
      if(!event){
        this.removeFileAnswer(this.answerList[index].file, item)
      }
    },

    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },

    addAnswer(newValue, oldValue) {
      if (newValue > oldValue) {
        for (let i = oldValue; i < newValue; i++) {          
          this.answerList.push({
            isImage: false,
            isCorrect: false,
            description:'',
            imgName: '',
            file:null
        });
        }
      } else {
        for (let i = oldValue; i > newValue; i--) {
          this.answerList.pop();
        }
      }
    },
    answerCorrectSelected(index){
      this.answerList.forEach(( item,i ) => {
        if(index != i ){
          item.isCorrect = false
        }
      })
    },
  },

  computed: {
    ...mapGetters(['commons/getUserID']),
    isTrueFalse(){
      return this.questionType.value == 74
    },
    idUser(){
      return this['commons/getUserID']
    },
    requiredImageJustification(){ return this.isImageJustification },
    requiredImageStatement(){ return this.isImageStatement },
    requiredImageAnswer(){ return this.imageAnswer.isImage.$model},
  },

  mounted() {
    this.quantity = +3;
    this.getExamType();
    this.getModalityType();
    this.getStatementType();
    this.getQuestionType();
    
  },

  watch: {
    quantity(newValue, oldValue) {
      if (!this.isTrueFalse){
      this.addAnswer(newValue, oldValue);
      }
    },
    async questionType() {
      if (this.isTrueFalse){
        this.quantity = 2
        this.answerList = []
        await this.addAnswer(2, 0)
        this.answerList[0].description = 'Verdadero'
        this.answerList[1].description = 'Falso'
      }
    }
  }
};

</script>

<style lang="sass" scoped>
</style>