<template>
  <div>
    <template v-if="$v.userInputs.phone.input.$anyDirty">
      <v-alert
        text
        dense
        color="error"
        :value="!$v.userInputs.phone.input.required"
      >{{ $t('modules.login.recovery.user.form.validations.phoneRequired') }}</v-alert>

      <v-alert
        text
        dense
        color="error"
        :value="!$v.userInputs.phone.input.format"
      >{{ $t('modules.login.recovery.user.form.validations.phoneInvalid') }}</v-alert>
    </template>
    <v-text-field
      v-model="userInputs.phone.input"
      :success="!$v.userInputs.phone.input.$invalid"
      :error="$v.userInputs.phone.input.$anyError"
      :loading="$v.userInputs.phone.input.$pending"
      :label="$t('modules.login.recovery.user.form.labels.phone')"
      outlined
      required
      type="text"
      autocomplete="phone"
      @input="$v.userInputs.phone.input.$touch()"
      @blur="$v.userInputs.phone.input.$touch()"
    ></v-text-field>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'


export default {
  name: 'RecoveryUserForm',
  props: {
    userInputs: Object
  },
  validations: {
    userInputs: {
      phone: {
        input: {
          required,
          format (value) {
            return (/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/).test(value)
          },
        }
      }
    }
  },
  data () {    return {
      error: false,
    }  },
  watch: {

    '$v.userInputs.phone.input.$invalid': function (value) {
      this.userInputs.phone.valid = !value;
    }
  }

};
</script>

<style scoped lang="sass">
    .v-messages
        display: none
</style>
