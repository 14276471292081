<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.portalalumno.profile.studentFinder.breadcrumbs.title')"></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">

          <outlinedCard :title="$t('modules.portalalumno.profile.studentFinder.title')">
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                  clearable
                  :items="careers"
                  item-text="legalDescription"
                  item-value="id"
                  v-model="selectedCareer"
                  :label="$t('modules.portalalumno.profile.studentFinder.input.career')"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="4">
                <AutocompleteInput
                  disableButtons
                  :value.sync="selectedcitiesWithState"
                  :label="$t('modules.portalalumno.profile.studentFinder.input.cityWithState')"
                  :required="false"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="inputName"
                  :label="$t('modules.portalalumno.profile.studentFinder.input.nameStudent')"
                  outlined
                />
              </v-col>
            </v-row>
          </outlinedCard>

          <v-row class="mt-5">
            <v-col
              cols="12"
              lg="4"
              md="6"
              v-for="(student, index) in students"
              :key="index"
              @click="$router.push(`studentFinder/${student.id}`)"
              style="cursor: pointer"
            >
              <StudentCard
                :id="student.id"
                :avatar="student.avatar"
                :name="student.name"
                :career="student.career"
                :from="student.from"
              ></StudentCard>
            </v-col>
          </v-row>

          <v-row align="center" no-gutters class="py-6">
            <v-col sm="3">
              <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
              <v-select
                :items="itemsPerPageAvailable"
                :placeholder="itemsPerPage.toString()"
                class="superTable__paginationSelect d-inline-block ml-4"
                outlined
                v-model="itemsPerPage"
                @change="onLengthPaginationChange"
              ></v-select>
            </v-col>
            <v-col class="text-center" sm="6">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @input="getStudentsdata"
                class="superTable__pagination"
              ></v-pagination>
            </v-col>
            <v-col align="right" sm="3">
              <span class="caption d-inline-block mr-4">{{ $t('actions.goToPage') }}</span>
              <v-text-field
                class="superTable__paginationInput d-inline-block"
                :max="pageCount"
                outlined
                v-model.number="goToPage"
                @blur="changePage"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput.vue';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard.vue';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { $careers, $studentsData } from '../Services/index';
import StudentCard from '../Components/StudentCard';
import constants from '@/plugins/constants';
export default {
  name: 'studentFinder',
  components: {
    Breadcrumbs,
    OutlinedCard,
    StudentCard,
    AutocompleteInput
  },
  
  data() {
    return {
      careers: [],
      students: [],
      selectedCareer: '',
      selectedcitiesWithState: null,
      inputName: '',
      timeout: null,

      totalItems: 0,
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      goToPage: 1,
      pageCount: 1,
    };
  },
  methods: {
    onLengthPaginationChange() {
      this.page = 1
      this.getStudentsdata()
    },
    async getStudentsdata() {
      const params = {
        cityId: this.selectedcitiesWithState?.idCity,
        careerId: this.selectedCareer,
        name: this?.inputName,
        regionId: this.selectedcitiesWithState?.idRegion,
        page: this.page - 1,
        size: this.itemsPerPage
      };

      const { data } = await $studentsData.find(null, null, { params });
      this.pageCount = data.totalPages;
      this.totalItems = data.totalItems;
      this.students = data.students.map( ({careerStudyPlan,student}) => {
        return {
          id: String(student.user.id),
          avatar: `${constants.URL_BASE}/core/users/${student.user.id}/avatar`,
          name: `${student.user.userData?.name} ${student.user.userData?.lastname}`,
          career:careerStudyPlan.career.description,
          from:`${student.user.userData?.address.city?.name || ''} ${student.user.userData?.address.region?.regionName || ''}`,
        };
      });
    },
    async getCareers() {
      const { data } = await $careers.find('findAll', null, {
        params: {
          page: 0,
          size: 500,
        }
      });
      this.careers = data.careers;
    },
    changePage() {
      if (this.goToPage > this.pageCount) this.goToPage = this.page;
      else this.page = this.goToPage
      this.getStudentsdata()
    }
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
      return counter
    }
  },
  mounted() {
    this.getCareers();
    this.getStudentsdata();
  },
  watch: {
    selectedCareer() {
      this.page = 1
      this.getStudentsdata()
    },
    selectedcitiesWithState() {
      this.page = 1
      this.getStudentsdata()
    },
    inputName() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.page = 1
        this.getStudentsdata()
      },1000)
    },
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
  },
};
</script>