<template>
  <div>
    <v-row class="px-3" v-if="!massImportStatus">
      <v-col cols="12">
        <div class="mx-5 my-3">
          <h4 class="subtitle-1 font-weight-medium">{{ $t('modules.exams_group.statements.bulkUpload.bulk.description') }}</h4>
          <a href="/templates/template-enunciados.xlsx" download>
            <Button
              outlined
              class="mt-4 mb-8"
              icon="mdi-download"
              :text="$t('actions.download_template')"
            ></Button>
          </a>
        </div>
        <DropFiles
          :key="dropFilesKey"
          v-model="file"
          class="mx-5 mt-5"
          extension=".xls,.xlsx"
          limitSizeBytes="2000000"
          type-file="excel"
        ></DropFiles>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <Button
          white
          class="my-6"
          :text="$t('actions.close')"
          :disabled="sendButton.loading"
          @clicked="closeConfirmation"
        ></Button>
        <Button
          class="mx-5 my-6"
          :loading="sendButton.loading"
          :success="sendButton.success"
          :error="sendButton.error"
          :text="$t('actions.load')"
          :successText="$t('actions.loaded')"
          :errorText="$t('actions.cancel')"
          :disabled="!canUpdate"
          @end="resetButtonValues"
          @clicked="confirmationPopup"
        ></Button>
      </v-col>
    </v-row>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :subtitle="successMessage.subtitle"
      :actionPrimary="successMessage.actionPrimary"
    />
  </div>
</template>

<script>
import DropFiles from '@/components/DropFiles/DropFiles';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $statements} from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'UploadTemplate',
  components: {
    DropFiles,
    Button,
    SuccessMessage,
  },
  props: ['id'],
  data() {
    return {
      massImportStatus: false,
      file: null,
      dropFilesKey: 0,
      sendButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: '',
        subtitle: '',
        actionPrimary: { text: '', callback() {} },
      },
    }
  },
  computed: {
    ...mapGetters({
      userId: 'commons/getUserID',
    }),
    canUpdate() {
      return this.$permissions.portaladministrativo.users.UPDATE
    },
  },
  methods: {
    confirmationPopup() {
      if (!this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.exams_group.statements.massImportDrawer.messages.noFiles'),
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.exams_group.statements.massImportDrawer.messages.sendConfirmation'),
          content: [{ value: this.file.name }],
          actionPrimary: { text: this.$t('actions.send'), callback: () => this.sendFile() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      }
    },
    async sendFile() {
      try {
        this.sendButton.loading = true;

        const formData = new FormData()
        formData.append('file', this.file);
        formData.append('contentId',this.id)

        await $statements.formData(formData, `bulk`);

        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.exams_group.statements.massImportDrawer.messages.success');
        this.successMessage.subtitle = '';
        this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.close() } }
        this.sendButton.success = true;
        this.$emit('update');
      } catch (error) {
        this.file = null;
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.exams_group.statements.massImportDrawer.messages.error');
        switch(error.status) {
          case 219:
            this.successMessage.subtitle = this.$t(`responseCodeMeaning.${error.code}`, { hoja: error.errorDetail?.sheet, columna: error.errorDetail?.column, fila: error.errorDetail?.row });
            break;
          case 220:
            this.successMessage.subtitle = this.$t(`responseCodeMeaning.${error.code}`, { module: error.errorDetail?.module });
            break;
          default:
            this.successMessage.subtitle = error?.code ? this.$t(`responseCodeMeaning.${error.code}`) : null;
            break;
        }
        this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.massImportStatus = false; }}
        this.sendButton.error = true;
        throw error
      } finally {
        this.massImportStatus = true;
        this.sendButton.loading = false;
      }
    },
    resetButtonValues() {
      this.sendButton.loading = false;
      this.sendButton.success = false;
      this.sendButton.error = false;
    },
    closeConfirmation() {
      if (this.file) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.table.massImportDrawer.messages.pendingFiles'),
          actionPrimary: { text: this.$t('actions.yes'), callback: () => this.close() },
          actionSecondary: { text: this.$t('actions.no'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.close()
      }
    },
    close() {
      this.$emit('close')
      setTimeout(() => {
        this.file = null
        this.dropFilesKey++
        this.massImportStatus = false
        this.resetButtonValues()
      }, 2000)
    },
  },
}
</script>
