<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('modules.zones.table.search.search')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :show-select="true"
          :headers="headers"
          :items="users"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:top v-if="permissions.delete">
            <div :class="['superTable__bulkActions', 'py-2',areItemsSelected ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                <span
                  v-if="permissions.delete"
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  @click="bulkDelete"
                >
                  <v-icon size="22" class="mr-2">mdi-delete</v-icon> {{$t('modules.zones.table.search.bulkDelete')}}
                </span>
                <!--                            <span class="caption mx-4 cursor-pointer hover-7-inverted" @click="$emit('bulk-assign', selectedItems)"><v-icon size="22" class="mr-2">mdi-account-card-details-outline</v-icon>Asignar roles</span>-->
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.data-table-select="{ isSelected, select, item }">
            <v-simple-checkbox
              :disabled="!item.canDelete"
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected && item.canDelete"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.name="{item}">
            <div class="superTable__table__item__name">{{ item.name }}</div>
          </template>

          <template v-slot:item.status="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:item.action="{item}" v-if="permissions.update || (permissions.delete && item.canDelete)">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.update" @click="editItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon> {{$t('modules.zones.table.search.edit')}}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="permissions.delete && item.canDelete" @click="deleteItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>{{$t('modules.zones.table.search.delete')}}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model="page"
          max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.zones.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTable',
  props: {
    users: Array,
    headers: Array,
    clearSelected: String,
    permissions: {update: false, delete: false},
  },
  data () {    return {
      selectedItems: [],
      search: '',
      items: [
        { title: '1' },
        { title: '2' },
        { title: '3' },
      ],
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
    }  },
  methods: {
    getAvatar (id) {
      return `${Constants.URL_DATA_SERVER  }/users/${  id  }/avatar`;
    },
    editItem (id) {
      this.$emit('updateUser', id);
    },
    deleteItem (id) {
      this.$emit('deleteUser', id);
    },
    bulkDelete() {
      const items = this.selectedItems.filter(item => {return item.canDelete})
      this.$emit('bulk-delete', items)
    },
  },
  watch: {
    page(val) {
      if (val > this.pageCount)
        this.page = this.pageCount;
      if (val <= 1)
        this.page = 1;
    },
    clearSelected() {
      this.selectedItems = []
    },
  },
  computed: {
    areItemsSelected () {
      return !!this.selectedItems.length
    },
    pageCount() {
      let counter = this.users.length / this.itemsPerPage;

      if (counter < 1) {
        counter = 1
      }

      return Math.ceil(counter)
    },
    itemsCount() {
      const counter = {
        totalItems: this.users.length,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.users.length) {
        counter.endItem = this.users.length
      }

      return counter
    }
  },
};
</script>
