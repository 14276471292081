import api from '@/api';

const { $pricing, $core, $student } = api;
export const { $studentsWithoutBenefit, $studentToken } = $student;
export const { $users } = $core;
export const { $types, $benefits, $products, $documentations, $communityBenefitBilling, $referredCompanies } = $pricing;


// export default function ({pricingDataService}, Vue) {

//     return {
//         install() {
//             Vue.prototype.$benefits = this;
//         },
//         getBenefitsTypes() {
//             return pricingDataService.get('/types?type=BENEFIT_TYPE')
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         createBenefit(body) {
//             return pricingDataService.post('/benefits', body)
//                 .then(res => {
//                     if (res.status === 201)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getBenefit(id) {
//             return pricingDataService.get(`/benefits/${id}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         updateBenefit(id, body) {
//             return pricingDataService.put(`/benefits/${id}`, body)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getBenefits(page, length) {
//             return pricingDataService.get(`/benefits?page=${page}&length=${length}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getBillingProducts() {
//             return pricingDataService.get('/products')
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         deleteBenefit(id) {
//             return pricingDataService.delete(`/benefits/${id}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         bulkDeleteBenefits(body) {
//             return pricingDataService.post('/benefits/bulk', body)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//     }
// }