<template>
  <v-container class="pa-0 pb-8" fluid>
    <template v-if="insideDrawer">
      <v-container class="container-custom">
        <Breadcrumbs
          :title="$t('modules.subjects.competition.breadcrumbs.title')"
          :subtitle="$t('modules.subjects.competition.breadcrumbs.subtitle')"
        ></Breadcrumbs>
      </v-container>
    </template>
    <template v-if="!createNewCompetition">
      <v-container class="container-custom">
        <v-row justify="center">
          <v-col sm="10">
            <OutlinedCard
              class="mb-6"
              :subtitle="$t('modules.subjects.competition.cardOne.subtitle')"
              :title="$t('modules.subjects.competition.cardOne.title')"
            >
              <v-row>
                <v-col sm="6" class="padding_second_textfield">
                  <v-text-field
                    :error="$v.newCompetition.name.$anyError"
                    :error-messages="nameErrors"
                    :label="`${$t('modules.subjects.competition.cardOne.items.name')} *`"
                    outlined
                    v-model.trim="$v.newCompetition.name.$model"
                    @blur="()=>$v.newCompetition.name.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="10" class="padding_first_textfield">
                  <v-textarea
                    :error="$v.newCompetition.description.$anyError"
                    :error-messages="descriptionErrors"
                    :label="`${$t('modules.subjects.competition.cardOne.items.description')} *`"
                    outlined
                    v-model.trim="$v.newCompetition.description.$model"
                    @blur="() => $v.newCompetition.description.$touch()"
                  />
                </v-col>
              </v-row>
            </OutlinedCard>
            <div class="d-flex justify-end">
              <Button
                white
                :text="$t('actions.cancel')"
                @clicked="cancel"
                @end="resetButtonValues()"
                class="mr-4"
              ></Button>

              <Button
                :loading="createButton.loading"
                :success="createButton.success"
                :error="createButton.error"
                :text="$t('actions.create')"
                :successText="$t('actions.created')"
                :errorText="'Error'"
                :disabled="!canCreate || !canCreateCompetition"
                @end="resetButtonValues()"
                @clicked="createCompetition"
              ></Button>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $competitions } from '../Services';

export default {
  name: 'competencesCreate',
  components: {
    OutlinedCard,
    Breadcrumbs,
    Button,
    SuccessMessage
  },
  data() {
    return {
      loading: false,
      insideDrawer: false,
      newCompetition: {
        name: '',
        description: ''
      },
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      createNewCompetition: false,
      successMessage: {
        title: null,
        actionPrimary: null
      },
      finishButton: {
        loading: false,
        success: false,
        error: false,
        text: this.$t('actions.create'),
        successText: this.$t('actions.created'),
        errorText: 'error'
      }
    };
  },
  mounted() {},
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.subjects.CREATE
    },
    canCreateCompetition() {
      let validationCount = 0;
      Object.keys(this.newCompetition).forEach(key => {
        if (this.newCompetition[key].length === 0) validationCount++;
      });
      if (validationCount > 0) return false;
      else return true;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.newCompetition.name.$anyError) return [];
      if (!this.$v.newCompetition.name.required) errors.push(this.$t('modules.subjects.competition.validations.nameRequired'));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.newCompetition.description.$anyError) return [];
      if (!this.$v.newCompetition.description.required) errors.push(this.$t('modules.subjects.competition.validations.descriptionRequired'));
      return errors;
    }
  },
  methods: {
    cancel() {
      this.$v.$reset();
      this.$emit('closeDrawer');
    },
    async createCompetition() {
      if (!this.canCreate) return false
      this.createButton.loading = true;
      try {
        await $competitions.create(this.newCompetition)
        this.successMessage.type = 'success'
        this.successMessage.title = this.$t('modules.subjects.create.success_message.competence_title');
        this.successMessage.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('closeDrawer')}};
        this.successMessage.actionSecondary = {
          text: this.$t('modules.subjects.create.success_message.actions.secondary_text'),
          callback: () => {
            this.createNewCompetition = false;
            this.resetButtonValues();
            this.resetCompetencesValues();
            this.$v.$reset();
          }
        };
        this.loading = false;
        this.finishButton.success = true;
        this.createNewCompetition = true;
      } catch {
        this.successMessage.type = 'error'
        this.successMessage.title = this.$t('modules.subjects.create.error_message.competence_title');
        this.successMessage.actionPrimary = {text: this.$t('modules.subjects.create.success_message.actions.back'), callback: () => { this.createNewCompetition = false }};
        this.successMessage.actionSecondary = null
        this.loading = false;
        this.finishButton.error = true;
        this.createNewCompetition = true;
        this.resetButtonValues()
      }
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    resetCompetencesValues() {
      this.newCompetition.name = '';
      this.newCompetition.description = '';
    }
  },
  validations: {
    newCompetition: {
      name: {required},
      description: {required}
    }
  }
};
</script>

<style scoped>
</style>
