var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px","persistent":_vm.$store.state.commons.hasExpired},on:{"click:outside":_vm.resetButtonValues},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(!_vm.statusOk)?_c('OutlinedCard',{staticClass:"border-change-password",attrs:{"title":_vm.$t('components.changePasswordAlert.changePassword.label'),"subtitle":_vm.$store.state.commons.changePasswordPopUp ? _vm.$t('components.changePasswordAlert.changePassword.description2') : _vm.$t('components.changePasswordAlert.changePassword.description')}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.passwordOld.$dirty && _vm.$v.passwordOld.$invalid) ?
              (!_vm.$v.passwordOld.required ? _vm.$t('components.changePasswordAlert.sectionData.validations.passwordOldRequired') :
              _vm.$t('components.changePasswordAlert.sectionData.validations.passwordOldInconrrect')) :
              '',"label":_vm.$t('components.changePasswordAlert.create.passswordOld'),"outlined":"","append-icon":_vm.showOld ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showOld ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.passwordOld.$touch()},"change":function($event){_vm.incorrectPasswordOld = true},"click:append":function($event){_vm.showOld = !_vm.showOld}},model:{value:(_vm.$v.passwordOld.$model),callback:function ($$v) {_vm.$set(_vm.$v.passwordOld, "$model", $$v)},expression:"$v.passwordOld.$model"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.passwordNew.$dirty && _vm.$v.passwordNew.$invalid) ?
              (!_vm.$v.passwordNew.maxLength) ? _vm.$t('components.changePasswordAlert.sectionData.validations.passwordMax') :
              ((!_vm.$v.passwordNew.required) ? _vm.$t('components.changePasswordAlert.sectionData.validations.passwordNewRequired') :
              (!_vm.$v.passwordNew.minLength) ?  _vm.$t('components.changePasswordAlert.sectionData.validations.passwordMin') :
              (!_vm.$v.passwordNew.mediumLevel) ?  _vm.$t('components.changePasswordAlert.sectionData.validations.minRequired') :
              (!_vm.$v.passwordNew.strongLevel) ?  _vm.$t('components.changePasswordAlert.sectionData.validations.minRequired') :
              _vm.$t('components.changePasswordAlert.sectionData.validations.passwordNewRepeat')) :
              '',"label":_vm.$t('components.changePasswordAlert.create.passswordNew'),"outlined":"","append-icon":_vm.showNew ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNew ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.passwordNew.$touch()},"click:append":function($event){_vm.showNew = !_vm.showNew}},model:{value:(_vm.$v.passwordNew.$model),callback:function ($$v) {_vm.$set(_vm.$v.passwordNew, "$model", $$v)},expression:"$v.passwordNew.$model"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.passwordConfirmation.$dirty && _vm.$v.passwordConfirmation.$invalid) ?
               ((!_vm.$v.passwordConfirmation.required) ? _vm.$t('components.changePasswordAlert.sectionData.validations.passwordConfirmationRequired') :
              (!_vm.$v.passwordConfirmation.sameAsNewPass) ? _vm.$t('components.changePasswordAlert.sectionData.validations.sameAsNewPass') :
              '' ) :
              '',"label":_vm.$t('components.changePasswordAlert.create.passswordConfirmation'),"outlined":"","append-icon":_vm.showConfirmation ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmation ? 'text' : 'password'},on:{"blur":function($event){return _vm.$v.passwordConfirmation.$touch()},"click:append":function($event){_vm.showConfirmation = !_vm.showConfirmation},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.$v.passwordConfirmation.$model),callback:function ($$v) {_vm.$set(_vm.$v.passwordConfirmation, "$model", $$v)},expression:"$v.passwordConfirmation.$model"}}),_c('br'),_c('div',{staticClass:"linear-container",staticStyle:{"min-height":"4px"}},[_c('v-progress-linear',{attrs:{"background-color":"#eee","color":_vm.securityProgressBar.color,"value":_vm.progressBarValue}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.securityProgressBar.label))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.securityProgressBar.category))])])],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[(!_vm.$store.state.commons.hasExpired)?_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":_vm.resetButtonValues}}):_vm._e(),_c('Button',{attrs:{"loading":_vm.saveButton.loading,"success":_vm.saveButton.success,"error":_vm.saveButton.error,"text":_vm.$t('actions.save'),"successText":_vm.$t('actions.saved'),"errorText":_vm.$t('actions.error')},on:{"clicked":_vm.changePassword}})],1)],1):_c('SuccessMessage',{staticClass:"white",attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }