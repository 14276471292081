import Applications from '../Applications'
import ApplicationsIndex from '../Views/ApplicationsIndex'
import Application2 from '../Views/Application2'
import ApplicationEditView from '../Views/ApplicationEditView'
import Modal from '@/components/Modal/Modal'

const ApplicationsRoute =
{
  path: '/applications',
  meta: { 
    name: {
      en: 'Applications',
      es: 'Aplicaciones',
    }
  },
  component: Applications,
  children: [
    {
      path: '/',
      component: ApplicationsIndex,
      meta: { name: 'Index', path: 'applications', permission: 'READ' }
    },{
      path: 'application',
      meta: { name: 'Application' },
      component: Application2,
    },{
      path: 'applicationedit',
      meta: { name: 'ApplicationEditView' },
      component: ApplicationEditView,
    },{
      path: 'modaltest',
      meta: { name: 'modaltest' },
      component: Modal,
    }
  ]
}

export default ApplicationsRoute
