<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6" fluid>
        <Breadcrumbs
          :title="$t('modules.conciliation.table.breadcrumbs.title')"
          :description="$t('modules.conciliation.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newConciliation"
            :icon="'mdi-plus'"
            :text="$t('modules.conciliation.table.newButon')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="conciliation"
              :clear-selected="clearSelected"
              :permissions="{users: {read: canReadUsers}, conciliation: {update: canUpdate, delete: canDelete}}"
              @bulk-delete="(conciliation)=>{bulkDeletePopUp(conciliation)}"
              @deleteUser="(id)=>deleteConciliationPopUp(id)"
              @openDetails="(id)=>{seeDetails(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button'
import { $conciliations } from '../Services';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'ConciliationTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data () {
    return {
      clearSelected: '',
      asidebar: {
        pagination: {
          limit: 150,
          page: 0
        },
      },
      headers: [
        {
          text: this.$t('modules.conciliation.table.headers.date'),
          value: 'date',
        },
        {
          text: this.$t('modules.conciliation.table.headers.fileName'),
          value: 'fileName',
        },
        {
          text: this.$t('modules.conciliation.table.headers.updatedBy'),
          value: 'updatedBy',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      conciliation: [],
      filteredConciliation: []
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    canReadUsers() {
      return this.$permissions.portaladministrativo.users.READ
    },
    canCreate() {
      return this.$permissions.portaladministrativo.prices.conciliations.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.conciliations.READ || this.$permissions.portaladministrativo.prices.conciliations.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.conciliations.DELETE
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions'
    })
  },
  methods: {
    deleteConciliationPopUp (id) {
      const conciliation = this.conciliation.find(conciliation => conciliation.id == id);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.conciliation.table.messages.delete'),
          content: [{
            value: conciliation.fileName
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteConciliation(id) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {

            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
    },
    async fetchData () {
      let conciliation;
      try {
        conciliation = await $conciliations.find('detail', null, { params: {
            page: this.asidebar.pagination.page,
            length: this.asidebar.pagination.limit
        }});
        this.conciliation = this.formatConciliations(conciliation.data.content);
        // this.rawConciliation = JSON.parse(JSON.stringify(conciliation));
        this.asidebar.pagination.pagesAmount = conciliation.data.totalPages;
      } catch (error) {
        this.conciliation = []
        throw error
      }
    },
    formatConciliations(conciliations) {
      return conciliations.map(conciliation => {
        return {
          id:  conciliation.filename ? conciliation.filename : '',
          updatedBy: conciliation.username ? conciliation.username : '',
          fileName: conciliation.filename ? conciliation.filename : '',
          date: this.configurationValues.dateFormat
            ? moment(conciliation.date).add(1, 'days').format(
                this.configurationOptions.dateFormat
                  .find(
                    config =>
                      config.value === this.configurationValues.dateFormat
                  )
                  .text.toUpperCase()
              )
            : moment(conciliation.date).add(1, 'days').format('YYYY-MM-DD')
        };
      });
    },
    newConciliation () {
      if (this.canCreate) this.$router.push('/conciliations/create')
    },
    seeDetails (id) {
      if (this.canUpdate) this.$router.push(`conciliations/detail/${id}`)
    },
    async deleteConciliation (id) {
       try {
        await $conciliations.delete(`?filename=${id}`);
        this.conciliation = this.conciliation.filter(conciliation => conciliation.id !== id);
        this.filteredConciliation = this.filteredConciliation.filter(conciliation => conciliation.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.conciliation.table.delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error
      }
    }
  }
}
</script>

