<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!newPriceSuccess">
        <Breadcrumbs v-if="!newPriceSuccess && !newPriceError"
          :title="$t('modules.prices.update.breadcrumbs.title')"
          :description="$t('modules.prices.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard
                :title="$t('modules.prices.update.section_product.title')"
                :subtitle="$t('modules.prices.update.section_product.subtitle')"
                :switchLabel="formattedPriceStatus"
                switcher
                :switchValue="status"
                @switchChange="status = !status"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="4" class="py-0">
                    <v-select
                      :error-messages="$v.selectedBillingProduct.$dirty && $v.selectedBillingProduct.$invalid ? $t('modules.prices.create.section_product.product_error'):''"
                      :items="billingProducts"
                      @blur="$v.selectedBillingProduct.$touch()"
                      class="mt-3"
                      item-text="name"
                      :label="$t('modules.prices.update.section_product.product')"
                      outlined
                      return-object
                      v-model="$v.selectedBillingProduct.$model"
                      :value-comparator="compareProduct"
                    ></v-select>
                  </v-col>
                  <v-col sm="4">
                    <v-menu
                      :close-on-content-click="false"
                      :return-value.sync="$v.date.$model"
                      min-width="290px"
                      offset-y
                      ref="menu"
                      transition="scale-transition"
                      v-model="menu"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :error-messages="$v.date.$invalid && $v.date.$dirty ? $t('modules.prices.create.section_zone.items.date_error'):''"
                          :label="$t('modules.prices.update.section_product.from')"
                          append-icon="mdi-calendar"
                          outlined
                          readonly
                          v-model="$v.date.$model"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="(()=>(new Date().toISOString().substr(0, 10)))()"
                        color="primary"
                        no-title
                        scrollable
                        v-model="$v.date.$model"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn @click="$refs.menu.save($v.date.$model)" color="primary" text>OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <div class="d-flex justify-end" v-if=canCreateProducts>
                  <Button
                    @clicked="productsDrawer.open = true"
                    :text="$t('modules.prices.create.section_product.new_product')"
                    depressed
                    outlined
                    class="px-6 body-2"
                  ></Button>
                </div>
              </OutlineCard>

              <!-- CAREERS -->
              <OutlineCard
                :title="$t('modules.prices.create.section_career.title')"
                :subtitle="$t('modules.prices.create.section_career.subtitle')"
                class="mb-6"
              >
                <v-col class="d-flex px-0" cols="12" sm="6">
                  <v-select
                    :items="careersFilters"
                    item-text="meaning"
                    :label="$t('modules.prices.update.section_career.study_type')"
                    outlined
                    return-object
                    v-model="selectedCareerFilter"
                  ></v-select>
                </v-col>
                 <TransferList
                  :avatar="false"
                  :loading="loadingSearchCareers"
                  :availableItems.sync="filteredCareers"
                  :added-items.sync="careersAdded"
                  :firstListTitle="$t('modules.prices.create.section_career.transferList.firstTitle')"
                  :secondListTitle="$t('modules.prices.create.section_career.transferList.secondTitle')"
                  areEquals="id"
                  @searchItemLeftOnBackend="searchCareers"
                  @searchItemRightOnBackend="searchCareersAdded"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="careersAdded.length === 0"
                >{{ $t('modules.prices.create.section_career.no-selected-warning') }}</v-alert>
              </OutlineCard>
              <OutlineCard
                :title="$t('modules.prices.update.section_details.title')"
                class="mb-6"
              >
                <UpdateDetailsTable :headers="headers" :details="$v.details.$each.$iter"></UpdateDetailsTable>
              </OutlineCard>
              <div class="d-flex justify-end">
                <v-btn text rounded large class="cancelbutton mr-6" @click="goBack">Cancelar</v-btn>

                <Button
                  :disabled="!canUpdate && ($v.formValid.$anyError || careersAdded.length === 0)"
                  @clicked="checkStatus"
                  @end="resetButtonValues()"
                  class="crearbutton"
                  large
                  rounded
                  :loading="sendingNewPrice"
                  :error="newPriceError"
                  :success="newPriceSuccess"
                  successText="Guardado"
                  errorText="Error"
                  text="Guardar"
                  primary
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
        <template v-else>
        <SuccessMessage
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
          />
        </template>
        <TemporaryRightDrawer
          v-if="canCreateProducts"
          @tmpDrawerOpen="productsDrawerClose"
          :open="productsDrawer.open"
          :title="$t('modules.prices.create.section_product.new_product')"
          :description="$t('modules.prices.create.section_product.new_product_description')"
        >
          <ProductsCreate
            @closeDrawer="()=>{this.productsDrawer.open = false}"
            :insideDrawer="true"
          ></ProductsCreate>
        </TemporaryRightDrawer>
    </v-container>
  </div>
</template>


<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import ProductsCreate from '#/portaladministrativo/Products/views/ProductsCreate'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
import UpdateDetailsTable from '../Components/UpdateDetailsTable';
import { $prices, $careers, $products, $types } from '../Services';


import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    Breadcrumbs,
    OutlineCard,
    TransferList,
    Button,
    SuccessMessage,
    UpdateDetailsTable,
    ProductsCreate,
    TemporaryRightDrawer
  },

  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.prices.UPDATE
    },
    canCreateProducts() {
      return this.$permissions.portaladministrativo.prices.products.CREATE
    },
    formattedPriceStatus () {
      return this.status ? this.$t('modules.prices.update.section_product.enabled') : this.$t('modules.prices.update.section_product.disabled')
    },
  },

  data () {
    return {

      productsDrawer: {
          open: false,
      },

      headers: [
        {
          text: this.$t('modules.prices.update.section_details.table.headers.modality_type'),
          value: 'model.modalityType.meaning',
        },
        {
          text: this.$t('modules.prices.update.section_details.table.headers.student_type'),
          value: 'model.studentType.meaning',
        },
        {
          text: this.$t('modules.prices.update.section_details.table.headers.zone'),
          value: 'model.zone.zoneDescription'
        },
        {
          text: this.$t('modules.prices.update.section_details.table.headers.price'),
          value: 'amount',
        },
        {
          text: '',
          value: 'action'
        }
      ],

      details: [],
      // INFO VARS
      saving: false,
      errorOnFetch: false,
      sendingNewPrice: false,
      newPriceError: false,
      newPriceSuccess: false,
      // SELECTABLE DATA VARS
      billingProducts: [],
      selectedCareerFilter: 
        {
          meaning: 'Todos',
          type: 'ALL',
          value: 0
        },
      careersFilters: [],
      availableCareers: [],
      filteredCareers: [],
      careers: [],
      careersAdded: [],

      // NEW OBJECT DATA
      paginationLimit : 150,
      loadingSearchCareers: false,
      status: true,
      selectedBillingProduct: {},
      date: 'XXXX-XX-XX',
      selectedCareers: [],
      //OTHERS ?

      menu: null,
      successMessage: {
        title: '',
        type: '',
        subtitle: '',
        actionPrimary: { text: '', callback: () => { this.$router.push('/prices') } },
      },
      openCreateProductPanel: false,
      openCreateRegionPanel: false,
    }
  },
  validations: {
    selectedBillingProduct: { required },
    date: { required },
    details: {
      $each: { 
        amount: { required } 
      }
    },
    formValid: ['selectedBillingProduct', 'date']
  },
  methods: {

    checkStatus() {
      if(this.status){
        this.updatePrice();
      }else{
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.prices.update.warning_popup.title'),
          actionPrimary: {
            text: this.$t('modules.prices.update.warning_popup.update'),
            callback: this.updatePrice
          },
          actionSecondary: {
            text: this.$t('modules.prices.update.warning_popup.cancel'),
            callback() {}
          },
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      }
    },

    async productsDrawerClose () {
      const allData = Promise.all([
        $products.find(null, null, { params: { page: 0, length: 500,orderBy: 'id', orientation: 'desc'} }),
      ]);

      this.productsDrawer.open = !this.productsDrawer.open;
      try {
        const [products] = await allData;
        this.billingProducts = products.data.content;
      } catch (error) {
        this.errorOnFetch = true;
        throw error;
      }

    },

    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1];
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    removeItems (removedItems) {
      removedItems.forEach(removedItem => {
        const index = this.selectedItems.indexOf(removedItem);
        this.selectedItems.splice(index, 1);
      })
    },
    removeGroup (index) {
      this.groups.splice(index, 1)
    },
    addGroup () {
      const mockup =
      {
        zone: {},
        price: 0,
        date: new Date().toISOString().substr(0, 10), // mockup
      };
      this.groups.push({
        ...mockup
      })
    },
    async updatePrice () {
      if (!this.canUpdate) return false
      this.sendingNewPrice = true;
      const {id} = this.$route.params;
      const data = {
        status: this.status,
        from: this.date,
        priceDetails: this.details,
        billingProducts: { id: this.selectedBillingProduct.id },
        careers: this.careersAdded.map(career => ({ id: career.id })),
      };

      try {
        await $prices.update(id, data);
        this.successMessage.title = this.$t('modules.prices.update.success_title');
        this.successMessage.type = 'success';
        this.successMessage.actionPrimary.text = this.$t('modules.prices.update.accept');
        this.newPriceSuccess = true;
      } catch (error) {
        this.successMessage.title = this.$t('modules.prices.update.error_title');
        this.successMessage.type = 'error';
        this.successMessage.actionPrimary.text = this.$t('modules.prices.update.go_to_back');
        this.successMessage.actionPrimary.callback = () => {this.newPriceError = false;}
        this.newPriceError = true;
        throw error;
      } finally {
        this.sendingNewPrice = false;
      }
    },
    compareProduct (option) {
      return option === this.selectedBillingProduct.name
    },

    searchCareers (event) {
      this.getCareers(event);
    },
    searchCareersAdded(event) {
      this.careersAdded = event
    },
    async getCareers(career) {
      this.loadingSearchCareers = true;
      let careers = [];
      if (career) {
        careers = await $careers.find(null, null, {
          params: { name: career , length: this.paginationLimit, status: true}
        });
      } else {
        careers = await $careers.find(null, null, {
          params: { length: this.paginationLimit ,status: true}
        });
      }
      if (careers.data && careers.data.content.length) {
        careers = careers.data.content.map(item => {
          return {
            id: item.id,
            name: item.legalDescription ? item.legalDescription : item.description,
            careerType: item.careerType
          };
        });
        this.careers = careers;
        this.filteredCareers = careers;
      }
      this.loadingSearchCareers = false;
    },
    resetButtonValues () {
      this.sendingNewPrice = null;
      this.newPriceSuccess = null;
      this.newPriceError = null;
    },
    async fetchData (priceId) {
      await this.getCareers()
      const allData = Promise.all([
        $prices.findById(priceId),
        $products.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } }),
        $types.find(null, null, { params: {type: 'CAREER_TYPE' } }),
      ]);

      try {
        const [prices, products, careerType] = await allData;

        this.date = prices.data.from;
        this.billingProducts = products.data.content;
        this.selectedBillingProduct = prices.data.billingProducts;
        this.details = prices.data.priceDetails;
        this.status = prices.data.status;

        this.careersAdded = prices.data.careers
          ? prices.data.careers.map(item => {
              return {
                id: item.id,
                name: item.legalDescription ? item.legalDescription : item.description,
                careerType: item.careerType
              };
            })
          : [];
        this.filteredCareers = this.careers
            .filter(
              item =>
                !this.careersAdded.map(item => item.id).includes(item.id)
            )
            .map(item => ({ id: item.id, name: item.name, careerType: item.careerType }));
        this.billingProducts = products.data.content;
        this.careersFilters = careerType.data;
      } catch (error) {
        this.errorOnFetch = true;
        throw error;
      }
    }
  },
  watch: {
    'selectedCareerFilter': function (filter) {
      if (!filter || filter === {}) {
        this.filteredCareers = this.careers;
      } else {
        this.filteredCareers = this.careers
        .filter(item => {
          if( item.careerType.value === filter.value ) {
            return item
          }
          })
      }
    }
  },
  mounted () {
    const price = this.$route.params.id;
    this.fetchData(price);
  }
}
</script>
