<template>
  <v-container fluid class="superTable">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          @input="helperSearcher()"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :loading="loading"
          show-select
          :headers="headers"
          :items="users"
          :page="page"
          :items-per-page="itemsPerPage"
          :multi-sort="false"
          :totalItems="totalItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          @click:row="(val) => $emit('click:row', val.id)"
          hide-default-footer
          item-key="id"
          class="superTable__table Benefits-supertable"
          style="table-layout: fixed"
        >
          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox
              v-show="(canSelect || isSelected) && showSelect(item)"
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.identification`]="{item}">
            <div class="superTable__table__item__name">
              <div>{{ item.identification }}</div>
            </div>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">
              <v-avatar size="32" class="mr-4">
                <img :src="item.avatar" />
              </v-avatar>
              <div>{{ item.name }} {{ item.lastname }}</div>
            </div>
          </template>

        </v-data-table>
        <v-divider class="mt-0 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('lengthPaginationChange', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          v-model="page"
          :length="pageCount"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          :value="page"
          ref="directPage"
          :max="pageCount"
          outlined
          @change="directPageInput($event)"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.benefits.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'

export default {
  name: 'SuperTableAssign',
  props: {
    users: Array,
    headers: Array,
    loading: Boolean,
    clearSelected: String,
    canSelect: Boolean,
    totalItems: {
      type: Number,
      default: 0
    },
    pageCount: Number,
    sortBy: String,
    sortDesc: Boolean,
    allowItemDeletion: {
      type: Boolean,
      default: true
    },
    withAverage: {
      type: Boolean,
      default: () => false,
    },
    minAverage: {
      type: Number,
      default: () => false,
    }
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      items: [],
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
      timeout:null
    };
  },
  methods: {
    helperSearcher(){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
    },
    searchAction(){
      this.page = 1
      this.$emit('searchName', this.search)
      this.$emit('inputPagination', this.page);
    },
    showSelect(item) {
      if (this.withAverage) return item.average != null && item.average >= 0 && item.average <= this.$countryConstants.maxAverage
      else return true
    },
    getAvatar(id) {
      return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
    },
    directPageInput(event) {
      let newPage = +event;

      if(newPage > +this.pageCount) newPage = +this.pageCount;
      else if(newPage < 1 || +this.pageCount == 1) newPage = 1;

      this.page = newPage;
      this.$emit('inputPagination', this.page);
    }
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val <= 1) this.page = 1;
    },
    clearSelected() {
      this.selectedItems = [];
    },
    selectedItems(val){
      this.$emit('toggleItem', val);
    }
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems;
      }
      return counter;
    }
  },
};
</script>

<style lang="sass">
  .Benefits-supertable
    table tr
      // cursor: pointer !important
</style>
