<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!createNewObjectives">
            <Breadcrumbs
                :title="$t('modules.commissions.objectives.create.breadcrumbs.title')"
                :description="$t('modules.commissions.objectives.create.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">

                    <OutlinedCard :title="$t('modules.prices.create.section_student.title')" class="mb-6">
                        <div>
                            <div class="d-flex">
                                <v-checkbox
                                    :key="index"
                                    :label="studentType.meaning"
                                    :value="studentType"
                                    class="mr-8 mt-0 no-message"
                                    color="primary"
                                    v-for="(studentType, index) in studentTypes"
                                    v-model="$v.selectedStudentTypes.$model"
                                ></v-checkbox>
                            </div>
                            <v-alert
                                text
                                type="error"
                                v-if="$v.selectedStudentTypes.$anyError"
                            >{{$t('modules.commissions.objectives.create.validations.studentType')}}</v-alert>
                        </div>
                    </OutlinedCard>

                    <OutlinedCard
                        :title="$t('modules.prices.create.section_career.title')"
                        :subtitle="$t('modules.commissions.objectives.create.sectionCareer.subtitle')"
                        class="mb-6"
                    >
                        <v-col class="d-flex px-0" cols="12" sm="6">
                            <v-autocomplete
                                :items="careers"
                                v-model="selectedCarrear"
                                item-text="name"
                                :label="$t('modules.commissions.objectives.update.sectionData.labels.careers')"
                                outlined
                                return-object
                            ></v-autocomplete>
                        </v-col>
                    </OutlinedCard>

                    <OutlinedCard :title="$t('modules.commissions.objectives.create.sectionMultiple.title')" class="mb-6">
                        <v-row no-gutters>
                            <v-col sm="11">
                                <MultipleFormGroup
                                    :items="$v.groups.$each.$iter"
                                    :length="groups.length"
                                    @addGroup="addGroup"
                                    @removeGroup="removeGroup"
                                >
                                    <template v-slot:default="{item}">
                                        <v-container fluid class="pa-0">
                                            <v-row>
                                                <v-col class="pb-0" sm="4">
                                                    <v-autocomplete
                                                        :error-messages="(item.range.$invalid && item.range.$dirty && $t('modules.commissions.objectives.create.validations.range')) || '' "
                                                        @blur="item.range.$touch()"
                                                        :items="ranges"
                                                        :label="$t('modules.commissions.objectives.create.sectionData.items.range')"
                                                        outlined
                                                        item-value="id"
                                                        item-text="description"
                                                        return-object
                                                        v-model="item.range.$model"
                                                    >
                                                        <template v-slot:item="data">
                                                            <v-list-item-content>
                                                                <v-list-item-title v-html="data.item.description"></v-list-item-title>
                                                                <v-list-item-subtitle v-html="`Del ${data.item.startDate} al ${data.item.endDate}`"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col class="pb-0" sm="4">
                                                    <v-autocomplete
                                                        :error-messages="(item.seller.$invalid && item.seller.$dirty && $t('modules.commissions.objectives.create.validations.seller')) || '' "
                                                        @blur="item.seller.$touch()"
                                                        :items="sellers"
                                                        :label="$t('modules.commissions.objectives.create.sectionData.items.seller')"
                                                        outlined
                                                        return-object
                                                        v-model="item.seller.$model"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col class="pb-0" sm="3">
                                                    <v-text-field
                                                        :error-messages="(item.objective.$invalid && item.objective.$dirty && $t('modules.commissions.objectives.create.validations.objective')) || ''"
                                                        :label="$t('modules.commissions.objectives.create.sectionData.items.objective')"
                                                        outlined
                                                        v-model="item.objective.$model"
                                                        @blur="item.objective.$touch()"
                                                        @keypress="$validateIntegerInputNumber($event, item.objective.$model, null, 4, 1)"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </MultipleFormGroup>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/objectives')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="createButton.loading"
                            :success="createButton.success"
                            :error="createButton.error"
                            :text="$t('actions.create')"
                            :successText="$t('actions.created')"
                            :errorText="'Error'"
                            :disabled="$v.$invalid || !canCreate"
                            @end="resetButtonValues"
                            @clicked="createObjective"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
    import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import moment from 'moment';
    import { required } from 'vuelidate/lib/validators';
    import { $types, $careers, $range, $sellers, $rangeObjective } from '../Services';
    import {mapGetters} from 'vuex'

    export default {
        name: 'ConfigCreate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
            MultipleFormGroup,
        },
        data () {
            return {
                groups: [
                    {
                        range: null,
                        seller: null,
                        objective: null,
                    }
                ],
                studentTypes: [],
                careers: [],
                ranges: [],
                sellers: [],

                createNewObjectives: null,
                selectedStudentTypes: [],
                selectedCarrear: {},
                errorMesagesSelected: false,
                withAverage: true,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                item: {
                    menu: false,
                },
                successMessage: {
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
             ...mapGetters([
                'commons/getUserID',
            ]),
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.objectives.CREATE
            },
            userId(){
                return this['commons/getUserID']
            },
        },
        mounted () {
            this.getStudentType();
            this.getCareers();
            this.getRanges();
            this.getSellers();
        },
        methods: {
            async getRanges () {
                try {
                    const range = await $range.find(null, null, {params: {page: 0, length: 60} });
                    this.ranges = range.data.content.map(item => {
                        item.startDate = moment(item.startDate).format('DD-MM');
                        item.endDate = moment(item.endDate).format('DD-MM');
                        return item;
                    });
                } catch (error) {
                    this.ranges = []
                }
            },
            async getSellers () {
                try {
                    const seller = await $sellers.find('users', null, { params: { user: this.userId } });
                    this.sellers = seller.data.map(item => {
                        return {
                            value: item.id,
                            text: item.sellerName,
                        }
                    });
                } catch (error) {
                    this.sellers = []
                }
            },
            async getCareers () {
                try {
                    const careers = await $careers.find('list', null, {params: {page: 0, length: 500, status: true} })
                    this.careers = careers.data.content.sort((a, b) => (a.name > b.name) ? 1 : -1)
                } catch (error) {
                    this.careers = []
                }
            },
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            async getStudentType() {
                const fetchedStudentTypes = await $types.find(null, null, { params: { type: 'STUDENT_TYPE'}})
                this.studentTypes = fetchedStudentTypes.data;
            },
            removeGroup (index) {
                this.groups.splice(index, 1)
            },
            addGroup () {
                this.groups.push({
                    range: null,
                    seller: null,
                    objective: null,
                })
            },
            async createObjective () {
                if(!this.canCreate) return false;
                const data = {
                    careerId: null,
                    listDto: [],
                    studentTypes: []
                };
                
                data.careerId = this.selectedCarrear.id ? this.selectedCarrear.id : null;
                data.studentTypes = this.selectedStudentTypes.map(itemStudentType => {
                    return { value: itemStudentType.value }
                });

                this.groups.forEach(group => {
                    data.listDto.push({
                        seller: { id: group.seller.value },
                        range: { id: group.range.id },
                        objectiveAmount: group.objective,
                    })
                })   

                try {
                    this.createButton.loading = true;
                    await $rangeObjective.create(data);
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.commissions.objectives.create.success_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('modules.commissions.objectives.create.success_message.actions.primary_text'), callback: () => {this.$router.push('/commissions/objectives')} };
                    this.successMessage.actionSecondary = { text: this.$t('modules.commissions.objectives.create.success_message.actions.secondary_text'), callback: () => {this.createNewObjectives = false} };
                    this.createButton.success = true;
                    this.createNewObjectives = true;
                    this.clearData()
                } catch (error) {
                    this.createButton.error = true;
                    this.successMessage.type = 'error';
                    this.successMessage.title = error.codeMeaning && error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.commissions.objectives.create.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('modules.commissions.objectives.create.errors.popup.btnPrimary'), callback: () => {this.createNewObjectives = false} };
                    this.successMessage.actionSecondary = null
                    this.createNewObjectives = true;
                } finally {
                    this.createButton.loading = false;
                }
            },
            clearData () {
                this.groups = [{
                    range: null,
                    seller: null,
                    objective: null,
                }];

                this.selectedStudentTypes = []
                this.selectedCarrear = {}
                this.$v.$reset()
                this.resetButtonValues()
            }
        },
        validations: {
            selectedStudentTypes: { required },
            groups: {
                $each: {
                    range: { required },
                    seller: { required },
                    objective: { 
                        required,
                        minValue(value) { return value > 0 }
                    },
                }
            },
        },
    }
</script>