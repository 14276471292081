
// Import commons store

import actions from './settings.actions'
import getters from './settings.getters'
import mutations from './settings.mutations'
import state from './settings.state'

// Export

export default {

  namespaced: true,

  actions,
  getters,
  mutations,
  state

}
