<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :title="$t('modules.settings.termsConditions.breadcrumbs.title')"
            :description="$t('modules.settings.termsConditions.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template v-if="!loading">
            <OutlinedCard :title="$t('modules.settings.termsConditions.title')">
              <v-text-field
                v-model="$v.url.$model"
                label="url"
                outlined
                class="mx-10 pl-0 mb-15"
                @change="(val) => changeValue(val)"
                :error-messages="validate('url')"
              ></v-text-field>
            </OutlinedCard>
            <div class="mt-8 text-sm-right">
            <Button
              :loading="loading"
              :success="success"
              :error="error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              errorText="Error"
              @clicked="save()"
              @end="resetButtonValues()"
              :disabled="!canUpdate || $v.$invalid"
            ></Button>
          </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import Button from '@/components/Button/Button'
import { mapGetters } from 'vuex'

import { required, url } from 'vuelidate/lib/validators'

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button
  },
  data () {    return {
      loading: false,
      success: false,
      error: false,

      modifications: {},

      url: null,
    }  },
  methods: {
    validate(inputValue) {
      let error = '';
      const that = this;

      String.prototype.toPascalCase = function () {
        return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
      };

      if (this.$v[inputValue].$invalid && this.$v[inputValue].$dirty) {
        const validationParams = this.$v[inputValue].$params;

        // First validate required
        if (validationParams.required) {
          if (!that.$v[inputValue].required) {
            error = that.$t(`modules.settings.termsConditions.fields.validations.${inputValue}Required`);
          }
        }

        const paramsToArray = Object.values(validationParams);

        paramsToArray.forEach(param => {
          if (param.type !== 'required') {
            if (!that.$v[inputValue][param.type]) {
              error = that.$t(`modules.settings.termsConditions.fields.validations.${inputValue + (param.type).toPascalCase()}`)
            }
          }
        })
      } else {
        error = '';
      }
      return error;
    },
    save () {
      if (!this.canUpdate) return false
      this.loading = true;
      this.$store.dispatch('settings/updateState', this.modifications)
        .then(() => {
          // Modified on Vuex

          this.$store.dispatch('settings/updateAPI')
            .then(() => {
              this.success = true
              // Launch snackbar
              this.$store.dispatch('settings/cleanTrash')

            })
            .catch(() => {
              this.error = true

            })
        })
        .catch(() => {
          this.error = true

        })
    },
    resetButtonValues () {
      this.loading = false
      this.success = false
      this.error = false
    },
    changeValue (value) {
      if (!this.canUpdate) return false
      this.modifications.termsConditions = value;
      this.$store.dispatch('settings/changeValue', { value, field: 'termsConditions' })
    },
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.legales.UPDATE
    },
    ...mapGetters([
      'settings/getAllCurrentValues'
    ])
  },
  validations: {
    url: { required, url }
  },
  mounted () {
    this.url = this['settings/getAllCurrentValues'].termsConditions
  }
}
</script>
