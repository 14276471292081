export default {

  // Setting general getters

  getAllCurrentValues(state) {
    const data = {}

    state.mutationsDictionary.content.forEach((element) => {
      data[element.field] = state[element.section][element.field].currentValue
    });

    return data
  },

  getAllOptions(state) {
    const data = {}

    state.mutationsDictionary.content.forEach((element) => {

      if (element.type === 'Select')
        data[element.field] = state[element.section][element.field].options
    });

    return data
  },

  getChanges(state) {

    const data = []

    state.trash.forEach((change) => {
      data.push({ field: change.field, section: change.section })
    })

    return data

  },
  getUndo(state) {
    return state.undo
  },

  getLanguageLocate(state) {

    const current = state.general.language.currentValue
    if(current) {
      return state.general.language.options.filter(option => {
        return option.value === current
      })[0].locate
    }
  },

  getUrls(state) {
    return state.urls
  },

  
  getDateFormat(state) {
    //TODO: VALIDATE UNDEFINED 
    const dateFormat = state.general.dateFormat.options.find((dateFormat)=> dateFormat.value === state.general.dateFormat.currentValue);
    if(typeof(dateFormat) !== 'undefined' && dateFormat !== null) return dateFormat.text.toUpperCase();
    else return 'YYYY/MM/DD';
  },
  // Settings Terms and Privacy

  getBilling(state) {
    return state.billing
  },
}
