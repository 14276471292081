<template>
    <div>
        <MultipleFormGroup
            :items="academic.groups.$each.$iter"
            :length="academic.groups.$model.length"
            @removeGroup="removeGroup"
            @addGroup="addGroup"
        >
            <template v-slot:default="{item}">
                <v-container fluid class="py-0">
                    <v-row>
                        <v-col sm="5">
                            <v-select
                                :label="`${$t('modules.benefits.create.academic_performance.apply_to')} *`"
                                :error-messages="(item.selectedProduct.$dirty && item.selectedProduct.$invalid) ? 'Error' : ''"
                                :items="productsAvailable(item.selectedProduct.$model ? item.selectedProduct.$model.id : null)"
                                item-text="name"
                                outlined
                                return-object
                                class="no-message"
                                @blur="item.selectedProduct.$touch()"
                                v-model="item.selectedProduct.$model"
                            ></v-select>
                        </v-col>
                        <v-col sm="3">
                            <v-text-field
                                :label="$t('modules.benefits.create.academic_performance.percentage')"
                                :error-messages="(item.percentage.$error && item.percentage.$dirty && (item.amount.$model === null || item.amount.$model === '')) ? 'Error' : ''"
                                prepend-inner-icon="mdi-percent"
                                :disabled="item.amount.$model !== null && item.amount.$model !== ''"
                                type="text"
                                outlined
                                @keypress="$validateDecimalInputNumber($event, item.percentage.$model, 100)"
                                v-model.trim="item.percentage.$model"
                                @blur="item.percentage.$touch()"
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="1">
                            <v-layout wrap align-center class="v-center pb-2  text-center">
                                <v-flex>
                                    {{ $t('modules.benefits.create.academic_performance.or') }}
                                </v-flex>
                            </v-layout>
                        </v-col>
                        <v-col sm="3">
                            <v-text-field
                                :label="$t('modules.benefits.create.academic_performance.amount')"
                                :error-messages="(item.amount.$error && item.amount.$dirty && (item.percentage.$model === null || item.percentage.$model === '')) ? 'Error' : ''"
                                :disabled="item.percentage.$model !== null && item.percentage.$model !== ''"
                                type="text"
                                outlined
                                @keypress="$validateDecimalInputNumber($event, item.amount.$model)"
                                v-model="item.amount.$model"
                                @blur="item.amount.$touch()"
                                class="no-message"
                            >
                              <template v-slot:prepend>
                                <span>{{ $countryConstants.currency }}</span>
                              </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </MultipleFormGroup>
        <v-row>
            <v-col sm="4" class="d-flex align-start pb-2">
                <span class="mt-2">{{ `${$t('modules.benefits.create.academic_performance.average')} *` }}</span>
            </v-col>
            <v-col sm="2">
                <v-text-field
                    :error-messages="(academic.average.$dirty && academic.average.$error) ? $t('modules.benefits.create.academic_performance.validation_errors.average', { maxAverage: $countryConstants.maxAverage }) :  ''"
                    v-model.trim="academic.average.$model"
                    @blur="academic.average.$touch()"
                    @keypress="$validateDecimalInputNumber($event, academic.average.$model, $countryConstants.maxAverage)"
                    placeholder="0"
                    type="text"
                    outlined
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="4" class="d-flex align-start pb-2">
                <span class="mt-2">{{ `${$t('modules.benefits.create.academic_performance.subjects')} *` }}</span>
            </v-col>
            <v-col sm="2">
                <v-text-field
                    :error-messages="(academic.subjects.$dirty && academic.subjects.$error) ? $t('modules.benefits.create.academic_performance.validation_errors.subjects') : ''"
                    v-model="academic.subjects.$model"
                    @blur="academic.subjects.$touch()"
                    @keypress="$validateIntegerInputNumber($event, academic.subjects.$model, 1000)"
                    placeholder="0"
                    type="text"
                    outlined
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="sass" scoped>
    .v-center
        height: 100%
</style>

<script>
    import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';

    export default {
        name: 'AcademicPerformance',
        props: ['billingProducts', 'academic'],
        components: {
            MultipleFormGroup,
        },
        methods: {
            productsAvailable (idSelected) {
                const products = this.billingProducts.filter((product)=>{
                    let found = false;
                    this.academic.$model.groups.forEach((group)=>{
                        if (group.selectedProduct && group.selectedProduct.name === product.name && group.selectedProduct.id !== idSelected){
                            found = true;
                            return null;
                        }
                    });
                    if (found) return null
                    else return product
                })
                return products
            },
            removeGroup(index) {
                this.$emit('removeGroup', index)
            },
            addGroup() {
                const mockup = {
                    selectedProduct: [],
                    amount: null,
                    percentage: null,
                };

                this.$emit('addGroup', mockup);
            },
        },
    }
</script>
