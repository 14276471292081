<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.commissions.prizes.table.breadcrumbs.title')"
                    :description="$t('modules.commissions.prizes.table.breadcrumbs.description')"
                >
                    <Button v-if="canCreate" @clicked="newCommission" icon="mdi-plus" :text="$t('actions.new')" depressed></Button>
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="commissions"
                            :clear-selected="clearSelected"
                            :pageCount="asidebar.pagination.pagesAmount"
                            :page="asidebar.pagination.page"
                            :permissions="{update: canUpdate, delete: canDelete}"
                            :deleteTitle="$t('modules.commissions.prizes.table.bulk_delete_title')"
                            :sortBy.sync="sort.sortBy"
                            :sortDesc.sync="sort.sortDesc"
                            :totalItems="totalItems"
                            @updateCommission="updateCommission"
                            @deleteCommission="deleteCommissionsPopUp"
                            @bulk-delete="bulkDeletePopUp"
                            @searchAction="searchAction"
                            @input-pagination="onInputPagination"
                            @length-pagination-change="onLengthPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable';
    import AsideBar from '@/components/AsideBar/AsideBar';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import { $types, $commissionPrizes } from '../Services';

    export default {
        name: 'PrizesTable',
        components: {
            AsideBar,
            Breadcrumbs,
            SuperTable,
            Button,
        },
        data() {
            return {
                totalItems: 0,
                clearSelected: null,
                search: null,
                commissions: [],
                sort: {
                    sortBy: 'dateFrom',
                    sortDesc: true,
                },
                headers: [
                    {
                        text: this.$t('modules.commissions.prizes.table.headers.studentType'),
                        value: 'studentType'
                    },{
                        text: this.$t('modules.commissions.prizes.table.headers.percentageFrom'),
                        value: 'percentageFrom'
                    },{
                        text: this.$t('modules.commissions.prizes.table.headers.extraPayment'),
                        value: 'extraPayment'
                    },{
                        text: this.$t('modules.commissions.prizes.table.headers.date'),
                        value: 'dateFrom'
                    },{
                        text: '',
                        value: 'action',
                        sortable: false,
                    }
                ],
                asidebar: {
                    title: this.$t('modules.commissions.prizes.table.filters.title'),
                    pagination: {
                        limit: 20,
                        page: 1,
                        pagesAmount: 1
                    },
                    items: [
                        {
                            name: this.$t('modules.commissions.prizes.table.filters.studenType'),
                            filters: null
                        },{
                            name: this.$t('modules.commissions.prizes.table.filters.date'),
                            filters: [
                                {
                                    name: this.$t('modules.commissions.prizes.table.filters.from'),
                                    type: 'date',
                                    value: null,
                                    isCalendarOpen: false,
                                },{
                                    name: this.$t('modules.commissions.prizes.table.filters.to'),
                                    type: 'date',
                                    value: null,
                                    isCalendarOpen: false,
                                },
                            ]
                        }
                    ]
                },
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.prizes.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.prizes.READ || this.$permissions.portaladministrativo.commissions.prizes.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.commissions.prizes.DELETE
            },
        },        
        async mounted () {
            await this.getStudentType();
            this.fetchData();
        },
        methods: {
            deleteCommissionsPopUp(id) {            
                const commissions = this.commissions.find(commissions => commissions.id == id);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.confirmation_delete'),
                    content: [{ value: commissions.name }],
                    actionPrimary: { text: this.$t('actions.delete'), callback: () => this.deleteCommissions(id) },
                    actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            bulkDeletePopUp(commissions) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.confirmation_delete'),
                    content: commissions.map(commission => ({ value: `${commission.studentType.meaning} - ${commission.percentageFrom}% - ${commission.extraPayment}%` })),
                    actionPrimary: { text: this.$t('actions.delete'), callback: () => {this.bulkDelete(commissions)} },
                    actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            async bulkDelete(commissions) {
                const itemsForDelete = commissions.map(commission => ({ id: commission.id }));

                try {
                    await $commissionPrizes.bulkDelete(itemsForDelete);
                } catch {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.commissions.prizes.table.error_message.bulk_delete_message'),
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                    });
                } finally {
                    this.fetchData();
                }
            },
            async deleteCommissions(id) {
                try {
                    await $commissionPrizes.delete(id);
                } catch {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.commissions.prizes.table.error_message.delete_message'),
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                    });
                } finally {
                    this.fetchData();
                }
            },
            async getStudentType() {
                const fetchedStudentTypes = await $types.find(null, null, { params: { type: 'STUDENT_TYPE'}})
                this.asidebar.items[0].filters = fetchedStudentTypes.data.map(item => {
                    return {
                        id: item.value,
                        name: item.meaning,
                        type: 'check',
                        items: [],
                        value: true,
                        dataFiltered: ''
                    }
                });
            },
            async fetchData() {
                try {
                    this.clearSelected = new Date().getMilliseconds().toString();
                    let sortCol = 'dateFrom';
                    if (this.sort.sortBy == 'studentType') sortCol = 'studentType.meaning'
                    else if (this.sort.sortBy == 'billingProduct') sortCol = 'billingProduct.name'
                    else sortCol = this.sort.sortBy

                    const params = {
                        page: this.asidebar.pagination.page - 1,
                        length: this.asidebar.pagination.limit,
                        orderBy: sortCol,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                    }

                    const filterParams = {                        
                        studentType: [],
                        from: null,
                        to: null,
                        search: null,
                    }
                    this.asidebar.items[0].filters.forEach(studentType => {
                        if (studentType.value) filterParams.studentType.push(studentType.id);
                    })

                    filterParams.from = this.asidebar.items[1].filters[0].value 
                    filterParams.to = this.asidebar.items[1].filters[1].value 

                    filterParams.search = this.search ;
                    const resp = await $commissionPrizes.filter(filterParams, { params });
                    this.totalItems = resp.data.totalElements;
                    this.asidebar.pagination.pagesAmount = resp.data.totalPages;

                    this.commissions = resp.data.content

                } catch (error) {
                    this.commissions = []
                    this.asidebar.pagination.page = 1
                    this.asidebar.pagination.pagesAmount = 1
                    this.totalItems = 0
                    throw error;
                }
            },
            newCommission() {
                if (this.canCreate) this.$router.push('/commissions/prizes/create');
            },
            updateCommission(id) {
                if (this.canUpdate) this.$router.push(`/commissions/prizes/update/${id}`);
            },
            async searchAction(data) {
                this.search = data;
                this.fetchData();
            },
            async onInputPagination(event) {
                this.asidebar.pagination.page = event;
                this.fetchData();
            },
            onLengthPaginationChange(event) {
                this.asidebar.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.fetchData();
            },
            changed(index, i, value) {
                this.asidebar.items[index].filters[i].value = value
                this.asidebar.items[1].filters[0].maxDate = this.asidebar.items[1].filters[1].value;
                this.asidebar.items[1].filters[1].minDate = this.asidebar.items[1].filters[0].value;
                this.fetchData();
            },
        },
        watch: {
            sort: {
                handler(){
                    this.fetchData();
                },
                deep: true
            }
        }
    }
</script>