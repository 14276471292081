import { render, staticRenderFns } from "./ValidatorIndex.vue?vue&type=template&id=571e1ec2&scoped=true&"
import script from "./ValidatorIndex.vue?vue&type=script&lang=js&"
export * from "./ValidatorIndex.vue?vue&type=script&lang=js&"
import style0 from "./ValidatorIndex.vue?vue&type=style&index=0&id=571e1ec2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571e1ec2",
  null
  
)

export default component.exports