<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewZoneStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs :title="$t('modules.zones.create.breadcrumbs.title')" :description="$t('modules.zones.create.breadcrumbs.subtitle')"></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.zones.create.title')"
                :subtitle="$t('modules.zones.create.description')"
                :switch-label="(newZone.status) ? $t('modules.zones.enabled') : $t('modules.zones.disabled')"
                :switch-value="newZone.status"
                @switchChange="(val) => switchChange(val)"
                class="mb-6"
                switcher
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      :error="$v.newZone.zoneDescription.$anyError"
                      :error-messages="nameErrors"
                      :label="`${$t('modules.zones.create.labels.name')} *`"
                      outlined
                      v-model.trim="$v.newZone.zoneDescription.$model"
                      @keypress="$validateAlphanumeric($event, $v.newZone.zoneDescription.$model, 40, true, true)"
                      @blur="$v.newZone.zoneDescription.$touch()"
                    />
                  </v-col>
                </v-row>
              </OutlinedCard>
              <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
              <!-- <OutlinedCard
                :title="$t('modules.zones.create.quarters_title')"
                :subtitle="$t('modules.zones.create.quarters_description')"
                class="mb-6"
              > -->
              <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
              
              <!-- :TODO - Fix zonas, descomentar codigo al subir cambios -->
              <OutlinedCard
                :title="$t('modules.zones.create.sectionLocality.title')"
                :subtitle="$t('modules.zones.create.sectionLocality.subtitle')"
                class="mb-6"
              >
                  <v-col class="d-flex px-0" cols="4" sm="3">
                  <v-autocomplete
                    :items="statesList"
                    item-text="name"
                    :label="$t('modules.zones.create.sectionLocality.province')"
                    outlined
                    return-object
                    v-model.trim="$v.selectState.$model"
                    @blur="$v.selectState.$touch()"
                  ></v-autocomplete>
                </v-col>
                <!-- :TODO - Fix zonas, descomentar codigo al subir cambios -->

                <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                <!-- <TransferList
                  :avatar="false"
                  chips
                  item-text="description"
                  :loading="loadingSearchCeos"
                  :availableItems.sync="ceos"
                  :added-items.sync="ceosAdded"
                  areEquals="id"
                  :firstListTitle="$t('modules.zones.available')"
                  :secondListTitle="$t('modules.zones.selected')"
                  searchParameter="description"
                  showInChip="zoneDescription"
                  valueDisable="zone"
                  @searchItemLeftOnBackend="searchCeos"
                  @searchItemRightOnBackend="searchCeosAdded"
                ></TransferList> -->
                <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                <!-- :TODO - Fix zonas, descomentar bloque al subir cambios -->
                <TransferList
                  :avatar="false"
                  chips
                  item-text="description"
                  valueDisable="seller"
                  showInChip="message"
                  :loading="loadingSearchLocalities"
                  :availableItems.sync="filteredLocalities"
                  :added-items.sync="localitiesAdded"
                  areEquals="id"
                  :firstListTitle="$t('modules.zones.create.sectionLocality.firstListTitle')"
                  :secondListTitle="`${$t('modules.zones.create.sectionLocality.secondListTitle')} ${localitiesAdded.length === 0 ? '' : localitiesAdded.length } `"
                ></TransferList>
                <!-- :TODO - Fix zonas, descomentar bloque al subir cambios -->
                <v-alert text type="warning" class="mt-4" v-if="!localitiesAdded.length">{{ $t('modules.zones.create.sectionLocality.error.errorLocality') }}</v-alert>   <!-- :TODO - Fix zonas, descomentar linea al subir cambios -->
                <!-- <v-alert text type="warning" class="mt-4" v-if="!ceosAdded.length">{{ $t('modules.zones.create.validation_errors.ceos') }}</v-alert>     :TODO - Fix zonas, borrar linea al subir cambios -->
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="!insideDrawer ? $router.replace('/zones') : $emit('closeDrawer'); clearData()"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate || !canCreateZone"
                  @end="resetButtonValues()"
                  @clicked="validateZone"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successText.type"
        :title="successText.title"
        :actionPrimary="successText.actionPrimary"
        :actionSecondary="successText.actionSecondary"
      />
      <AlertPopup v-if="isStatusDisable"></AlertPopup>
    </v-container>
  </div>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import AlertPopup from '@/components/AlertPopup/AlertPopup';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import {required} from 'vuelidate/lib/validators';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $citiesByState, $countries, $zones } from '../Services';   // :TODO - Fix zonas, descomentar linea al subir cambios
// import { $zones, $ceos } from '../Services';   // :TODO - Fix zonas, borrar linea al subir cambios
import Configuration from '../../../../core/constants/configuration';

export default {
  name: 'ZoneCreate',
  props: {
    insideDrawer: Boolean
  },
  components: {
    TransferList,
    AlertPopup,
    OutlinedCard,
    Breadcrumbs,
    Button,
    SuccessMessage,
  },
  data () {
    return {
      //loadingSearchCeos: false,   // :TODO - Fix zonas, borrar linea al subir cambios
      paginationLimit: 100,
      createNewZoneStatus: null,
      newZone: {
        zoneDescription: '',
        //ceos: [],    // :TODO - Fix zonas, borrar linea al subir cambios
        city: [],    // :TODO - Fix zonas, descomentar linea al subir cambios
        status: true,
      },
      //ceos: [],    // :TODO - Fix zonas, borrar linea al subir cambios
      //ceosAdded: [],    // :TODO - Fix zonas, borrar linea al subir cambios
      //existingCeos: [],    // :TODO - Fix zonas, borrar linea al subir cambios
      /* :TODO - Fix zonas, descomentar bloque al subir cambios */
      statesList: [],
      selectState: null,
      loadingSearchLocalities: false,
      localitiesAdded: [],
      filteredLocalities:[],
      /* :TODO - Fix zonas, descomentar bloque al subir cambios */
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successText: {
        type: 'success',
        title: this.$t('modules.zones.create.success_message.title'),
        actionPrimary: this.$t('modules.zones.create.success_message.actions.primary_text'),
        actionSecondary: {text: this.$t('modules.zones.create.success_message.actions.secondary_text')}
      },
      isStatusDisable: false
    }
  },
  mounted () {
    //this.getCeos()  // :TODO - Fix zonas, borrar linea al subir cambios
    this.getStates()    // :TODO - Fix zonas, descomentar linea al subir cambios
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.zones.CREATE
    },
    canCreateZone () {
      // return this.newZone.zoneDescription.length && this.ceosAdded ? this.ceosAdded.length : 0    // :TODO - Fix zonas, borrar linea al subir cambios
      return this.newZone.zoneDescription.length && this.localitiesAdded ? this.localitiesAdded.length : 0   // :TODO - Fix zonas, descomentar linea al subir cambios
    },
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
    selectStateErrors () {
      const errors = [];
      if (!this.$v.selectState.$anyError) return errors;
      if (!this.$v.selectState.required)
        errors.push(
          this.$t('modules.zones.create.sectionLocality.provinceRequired')
        );
      return errors;
    },
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
    nameErrors () {
      const errors = [];
      if (!this.$v.newZone.zoneDescription.$anyError) return [];
      if (!this.$v.newZone.zoneDescription.required) errors.push(this.$t('modules.zones.create.validation_errors.name'));
      return errors;
    },
  },
  methods: {
    cancelCreation() {
      if(this.insideDrawer) {      
        this.$emit('closeDrawer');
        this.createNewZoneStatus = false
        this.newZone = {
          zoneDescription: '',
          // ceos: [],   // :TODO - Fix zonas, borrar linea al subir cambios
          city: [],  // :TODO - Fix zonas, descomentar linea al subir cambios
          status: true,
        };
        // this.getCeos()  // :TODO - Fix zonas, borrar linea al subir cambios
        this.getStates()  // :TODO - Fix zonas, descomentar linea al subir cambios
        this.resetButtonValues();
        this.$v.$reset();
      }else{
        this.$router.replace('/zones');
      }
    },
    // searchCeos (event) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   this.getCeos(event);
    // },
    // searchCeosAdded (event) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   this.ceosAdded = event
    // },
    // async getCeos(ceo) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   this.loadingSearchCeos = true;
    //   let ceos = [];
    //   if (ceo) ceos = await $ceos.find(null, null, {params: { name: ceo, length: this.paginationLimit }});
    //   else ceos = await $ceos.find(null, null, {params: { length: this.paginationLimit }});
    //   if (ceos.data && ceos.data.content.length) this.ceos = ceos.data.content
    //   this.loadingSearchCeos = false;
    // },
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
    async getStates() {
      try {
        const country =  Configuration.value('country') || 'argentina'
        const statesLocal = await $countries.find(null, null, {params: { name: country }})
        this.statesList = (statesLocal.data[0].states).sort((a, b) => a.name < b.name ? -1 : 1)
      } catch (error) {
        this.statesList = []
        throw error
      }
    },
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
    switchChange (val) {
      this.newZone.status = val
    },
    validateZone () {
      if (!this.newZone.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.zones.create.alert_message.title'),
          content: '',
          actionPrimary: {text: this.$t('modules.zones.create.alert_message.actions.primary_text'), callback: this.createZone},
          actionSecondary: {text: this.$t('modules.zones.create.alert_message.actions.secondary_text'), callback() {}},
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      } else {
        this.createZone()
      }
    },
    async createZone () {
      if (!this.canCreate) return false
      this.createButton.loading = true;

      // if ( this.ceosAdded && this.ceosAdded.length) {   // :TODO - Fix zonas, borrar linea al subir cambios
        // const ceos = this.ceosAdded.map(ceo => {return { id: ceo.id }});   // :TODO - Fix zonas, borrar linea al subir cambios
        // this.newZone.ceos = ceos   // :TODO - Fix zonas, borrar linea al subir cambios

      
      // :TODO - Fix zonas, descomentar bloque al subir cambios
      if ( this.localitiesAdded && this.localitiesAdded.length) {
        this.newZone.city = this.localitiesAdded.map(locality => { return {id: locality.id} })
        // :TODO - Fix zonas, descomentar bloque al subir cambios

        try {
          await $zones.create(this.newZone)

          this.successText.type = 'success';
          this.successText.title = this.$t('modules.zones.create.success_message.title')
          if (!this.insideDrawer) {
            this.successText.actionPrimary = {text: this.$t('modules.zones.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/zones') }}
          } else {
            this.successText.actionPrimary = {text: this.$t('actions.accept'), callback: () => {this.$emit('closeDrawer'); this.clearData()}}
          }
          this.successText.actionSecondary = {text: this.$t('modules.zones.create.success_message.actions.secondary_text'), callback: () => { this.clearData() }}
          this.createNewZoneStatus = true;
          this.createButton.loading = false;
          this.createButton.success = true;

        } catch {

          this.successText.type = 'error';
          this.successText.title = this.$t('modules.zones.create.error_message.title');
          this.successText.actionPrimary = {text: this.$t('modules.zones.create.success_message.actions.back'), callback: () => { this.createNewZoneStatus = false }}
          this.successText.actionSecondary = null;
          this.createButton.error = true;
          this.createButton.loading = false;
          this.createNewZoneStatus = true;
          this.resetButtonValues()
        }
      }
    },
    clearData() {
      this.createNewZoneStatus = false;
      this.newZone = {
        zoneDescription: '',
        // ceos: [],   // :TODO - Fix zonas, borrar linea al subir cambios
        city: [],   // :TODO - Fix zonas, descomentar linea al subir cambios
        status: true,
      };
      // this.ceosAdded = []   // :TODO - Fix zonas, borrar linea al subir cambios
      // this.getCeos();   // :TODO - Fix zonas, borrar linea al subir cambios
      this.localitiesAdded = []   // :TODO - Fix zonas, descomentar linea al subir cambios
      this.getStates();   // :TODO - Fix zonas, descomentar linea al subir cambios
      this.resetButtonValues();
      this.$v.$reset();
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    newZone: {
      zoneDescription: { required },
    },
    selectState: { required },   // :TODO - Fix zonas, descomentar linea al subir cambios
  },
  watch: {
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
    async selectState() {
      this.loadingSearchLocalities = true
      const cities = await $citiesByState.find(null, null, { params: { stateId: this.selectState.id, sellers:true } });

      const newCities = (cities.data.sort((a, b) => a.name < b.name ? -1 : 1)).map(el => {
        if (el.seller) {
          const newseller = {status: el.seller, message : 'No disponible'}
          const newElem = {...el, seller:newseller}
          return newElem
        }else {
          return { ...el, seller:undefined }
        }
      })
      this.filteredLocalities =  newCities
      this.loadingSearchLocalities = false
    },
    /* :TODO - Fix zonas, descomentar bloque al subir cambios */
  }
}
</script>