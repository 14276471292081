<template>
  <v-list style="background: #ebebeb" class="md-6 px-0 pt-1 pb-2">
    <v-list-item-group v-for="(category, i) in categories" :key="i" class="pa-0">
      <v-subheader class="px-6">{{ category.title }}</v-subheader>
      <v-list-item v-for="(item, i) in category.items" :key="i" class="py-0 px-10" :to="item.path" exact>
        <v-list-item-title class="primary--text">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'AsideBar',
  props: {
    categories: Array
  },

}
</script>
