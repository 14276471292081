const helpMenuTranslations = {
  es: {
    'helpSection': {
      'title': 'Ayuda',
      'items': {
        'documentation': 'Documentación',
        'news': 'Novedades',
        'about': 'Acerca de SIS',
        'shortcuts': 'Atajos de teclado'
      }
    },
    'legalSection': {
      'title': 'Asuntos legales',
      'items': {
        'privacyPolicy': 'Políticas de privacidad',
        'termsConditions': 'Términos y condiciones'
      }
    }
  },

  en: {
    //
  }
};

export default helpMenuTranslations




