<template>
  <v-container>
    <v-row>
        <v-col v-for="(content,id) in contents" :key="id"  cols="12" class="seccion" >
            <v-row class="fondo-numero-modulo">
                Módulo {{ content[0].module }}
            </v-row>
            <v-row  v-if="content[0].type.meaning === 'Obligatorio' || content[1].type.meaning === 'Obligatorio'">
                <v-col cols="12">
                    <span class="titulo-subseccion">Lecturas Obligatorias</span>
                </v-col>
                <v-col cols="12" class="contenido-subseccion">
                    <ul>
                        <li v-if="content[0] && content[0].type.meaning === 'Obligatorio'">
                            <a :href="content[0].link">{{content[0].reading}}</a>
                        </li>
                        <li v-if="content[1] && content[1].type.meaning === 'Obligatorio'">
                            <a :href="content[1].link">{{content[1].reading}}</a>
                        </li>
                    </ul>
                </v-col>
            </v-row>
            <v-row v-if="(content[1] && content[1].type.meaning === 'Complementario') || (content[2] && content[2].type.meaning === 'Complementario')">
                <v-col cols="12">
                    <span class="titulo-subseccion">Lecturas Complementarias</span>
                </v-col>
                <v-col cols="12" class="contenido-subseccion">
                    <ul>
                        <li v-if="content[1] && content[1].type.meaning === 'Complementario'">
                            <a :href="content[1].link"> {{content[1].reading}}</a>
                        </li>
                        <li v-if="content[2] && content[2].type.meaning === 'Complementario'">
                            <a :href="content[2].link"> {{content[2].reading}}</a>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ElibroModule',
    props:{
        contents: Array
    }
}
</script>

<style scoped>
    .seccion {
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
    }
    .fondo-numero-modulo {
        color: #FFF;
        line-height: 40px;
        font-weight: bold;
        background: #0449A2;
        width: 120px;
        height: 40px;
        margin-bottom: 20px;
        border-radius: 20px;
        text-align: center;
        justify-content: center;
    }
    .titulo-subseccion {
        font-weight: bold;
        font-size: 20px;
        color: #0449A2;
        display: inline-block;
        vertical-align: middle;
    }
    .contenido-subseccion {
        text-align: justify;
        margin-top: 30px;
    }
    a {
        color: #0449A2;
    }
</style>
