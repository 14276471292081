<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.pricing.referred_companies.table.breadcrumbs.title')"
          :description="$t('modules.pricing.referred_companies.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newReferredCompany"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="companies"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @deleteData="deletePopUp"
              @updateData="updateReferredCompany"
              @input-pagination="fetchData"
              @length-pagination-change="fetchData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$referredCompanies} from '../Services';

export default {
  name: 'ReferredCompaniesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      companies: [],
      sort: {
        sortBy: 'code',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.pricing.referred_companies.table.headers.name'),
          value: 'name'
        }, {
          text: this.$t('modules.pricing.referred_companies.table.headers.cuit'),
          value: 'cuit',
          align: 'center'
        }, {
          text: this.$t('modules.pricing.referred_companies.table.headers.contactName'),
          value: 'contactName'
        }, {
          text: this.$t('modules.pricing.referred_companies.table.headers.status'),
          value: 'status'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.referred_companies.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.referred_companies.READ || this.$permissions.portaladministrativo.prices.referred_companies.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.referred_companies.DELETE;
    },
  },
  methods: {
    deletePopUp(id) {
      const referredCompany = this.companies.find(company => company.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: referredCompany.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteCompany(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.loading = true;
      try {
        const data = await $referredCompanies.find();
        this.companies = data.data.companies;
      } catch (err) {
        this.companies = [];
        throw err;
      } finally {
        this.loading = false;
      }
    },
    newReferredCompany() {
      if (this.canCreate) this.$router.push('/pricing/referred-companies/create');
    },
    updateReferredCompany(id) {
      if (this.canUpdate) this.$router.push(`/pricing/referred-companies/update/${id}`);
    },
    async deleteCompany(id) {
      try {
        await $referredCompanies.delete(id);
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
