import { $careers, $types, $products, $zones } from '../Services';

export default {
  data () {
    return {
      billingProducts: [],
      studentTypes: [],
      modalityTypes: [],
      availableCareers: [],
      filteredCareers: [],
      zones: [],
      tmpDrawer: {
        open: false,
        title: this.$t('modules.discounts.update.section_zone.drawer.title'),
        description: this.$t('modules.discounts.update.section_zone.drawer.description')
      },
    }
  },
  methods: {
    async fetchZones () {
      const zones = await $zones.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } });
      this.zones = zones.data.content;
    },
    async fetchData () {
      const allData = Promise.all([
        $careers.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } }),
        $products.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } }),
        $types.find(null, null, { params: { type: 'STUDENT_TYPE' } }),
        $types.find(null, null, { params: { type: 'MODALITY_TYPE' } }),
        $zones.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } }),
      ]);

      const [careers, products, studentTypes, modalityTypes, zones] = await allData;

      this.availableCareers = careers.data.content.map(item => {
        item.name = item.legalDescription;
        return item;
      });
      this.filteredCareers = this.availableCareers;
      this.billingProducts = products.data.content;
      this.studentTypes = studentTypes.data;
      this.modalityTypes = modalityTypes.data;
      this.zones = zones.data.content;
    },
    async onCloseDrawer () {
      this.tmpDrawer.open = false;
      await this.fetchZones()
    }
  }
}
