import StudyPlanView from '../Views/StudyPlanView'

const StudyPlanRoute =[
  {
    path: '/',
    component: StudyPlanView,
    meta: { name: 'Index', group: 'portalalumno.academic_charge', path: 'study_plan', permission: 'READ' }
  }
];

export default StudyPlanRoute