import Prices from '../Prices';
import PricesCreate from '../Views/PricesCreate';
import PricesTable from '../Views/PricesTable';
import PriceUpdate from '../Views/PriceUpdate'
import PricesReindex from '../Views/PricesReindex'


const PricesRoute =
{
  path: '/prices',
  meta: {
    name: {
      en: 'Prices',
      es: 'Precios',
    }
  },
  component: Prices,
  children: [
    {
      path: '/',
      component: PricesTable,
      meta: { name: { en: 'Index', es: 'Lista' }, group: 'portaladministrativo.prices', path: 'prices', permission: 'READ' },
    }, {
      path: 'create',
      component: PricesCreate,
      meta: { name: { en: 'Create', es: 'Creación' }, group: 'portaladministrativo.prices', path: 'prices', permission: 'CREATE' },
    }, {
      path: 'update/:id',
      component: PriceUpdate,
      meta: { name: { en: 'Update', es: 'Actualización' }, group: 'portaladministrativo.prices', path: 'prices', permission: 'READ' },
    }, {
      path: 'reindex',
      component: PricesReindex,
      meta: { name: { en: 'Reindex', es: 'Reindexación' }, group: 'portaladministrativo.prices', path: 'prices', permission: 'UPDATE' },
    },
  ]
};

export default PricesRoute
