<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom ">
        <Breadcrumbs
        :title="$t('modules.messaging.template.templateTable.breadcrumbs.title')"
        :description="$t('modules.messaging.template.templateTable.breadcrumbs.description')"
        >
         <Button 
            :disabled="!canCreate" 
            icon="mdi-plus"
            @clicked="showArea"
            :text="$t('actions.new')"
            depressed></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces ">
            <SuperTable
                :periods="periods"
                :headers="headers"
                :data="messages"
                :show-select="false"
                :page.sync="page"
                :pageCount="pagesAmount"
                :itemsPerPage.sync="itemsPerPage"
                :totalItems="totalItems"
                :permissions="{update: canUpdate, delete: canDelete}"
                @click-row="showTemplates"
                @searchAction="searchAction"
                @inputPagination="onInputPagination"
                @lengthPaginationChange="onLengthPaginationChange"
                @updateData="updateArea"
                @deleteData="deletePopUp"
                />
          </v-col>
      </v-row>
      </v-container>
       <NewArea
          :dialog="dialog"
          :canCreate="canCreate"
          :canUpdate="canUpdate"
          @hideArea="hideArea"
          :data="dataArea"
          @getMessages="getMessages"
        />
    </v-container>
      
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import SuperTable from '../Components/SuperTable'
import { $workflowAreas } from '../Services/index'
import NewArea from '../Components/NewArea'
import Button from '@/components/Button/Button';

export default {
name:'TemplateTable',
components: {
  SuperTable,
  Breadcrumbs,
  Button,
  NewArea
},
data() {
  return {
    messages: [],
    periods:[],
    page: 1,
    dialog:false,
    itemsPerPage: 20,
    totalItems: 0,
    pagesAmount: 0,
    search: null,
    clearSelected:'',
    sort: {
      sortBy: 'name',
      sortDesc: false,
    }, 
     pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    
    headers:[
      {
        text: this.$t('modules.messaging.template.templateTable.area'),
        value: 'name',
      },
      {
        text: this.$t('modules.messaging.template.templateTable.messageCount'),
        value: 'amountTemplates',
      },
      {
        text: '',
        value: 'action',
        sortable: false,
    },
    
    ],
    dataArea:null
  }
  },
  mounted () {
    this.getMessages();
  },
  methods: {
    async getMessages() {
      this.clearSelected = new Date().getMilliseconds().toString();
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          search: this.search
        };


        const messages = await $workflowAreas.find(null, null, { params })
        this.totalItems = messages?.data?.totalItems;
        this.pagesAmount = messages.data.totalPages;
        this.messages = messages.data.areas
      } catch (error) {
        this.messages = []
      }
    },
  showTemplates(id, name) {
    this.$router.push({path: `templates/area/${id}`, query: {name}}) 
  },
  searchAction(data) {
    this.search = data;
    this.getMessages();
  },
  onInputPagination(event) {
      this.page = event;
      this.getSubjects();
  },
  onLengthPaginationChange(event) {
        this.page = 1
        this.itemsPerPage = event
        this.pagesAmount = 1
        this.getSubjects();
  },
  showArea() {
    this.dialog = true
  },
  hideArea() {
    this.dialog = false
    setTimeout(() => {
      this.dataArea = null
      }, 1000);
  },
  updateArea(item){
    this.dataArea = item
    this.showArea()
  },
  deletePopUp(id) {
      const message = this.messages.find(message => message.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: message.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteArea(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
  },
   async deleteArea(id) {
      try {
        await $workflowAreas.delete(id);
        this.getMessages()
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.banners.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
  },
  computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.messaging.templates.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.messaging.templates.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.messaging.templates.DELETE
      },
      canSendMessage() {
        return this.$permissions.portaladministrativo.messaging.templates.SEND
      },
    },
}
</script>

<style lang="scss" scoped/>
