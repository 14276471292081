<template>
  <div v-if="disableButtons">
    <v-combobox
      v-model="$v.select.$model"
      :error-messages="($v.select.$dirty && $v.select.$invalid) ? errorMessages || $t('components.autocompleteInput.isRequired') : null"
      item-text="cityState"
      return-object
      :disabled="disabled"
      :loading="loading"
      :items="items"
      :label="label"
      :search-input.sync="search"
      :error="error"
      flat
      outlined
      @blur="saveInput"
      @change="saveInput"
      :clearable="clearable"
    ></v-combobox>
  </div>
  <div v-else class="independentSelect">
    <v-combobox @click="hide = false" @blur.native="cancelInput"
      v-model="select"
      item-text="cityState"
      return-object
      :loading="loading"
      :items="items"
      :label="label"
      :search-input.sync="search"
      :error="error"
      :error-messages="errorMessages || $t('components.autocompleteInput.isRequired')"
      class="independentSelect__input"
      flat
      outlined
      :disabled="disabled"
    ></v-combobox>
    <div class="independentSelect__actions">
      <v-btn small class="px-0" v-show="!hide" @click="saveInput">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
      <v-btn small class="px-0" v-show="!hide" @click="cancelInput">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import api from '@/api';
const { $core } = api;
export const { $citiesWithState } = $core;
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'AutocompleteInput',
  props: {
    disableButtons: Boolean,
    label: String,
    error: Boolean,
    required: { type: Boolean, default: false },
    errorMessages: String,
    list: [],
    value: Object,
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
  },
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: { cityState: '' },
      originalData: {},
      text: '',
      state: [],
      hide: true,
      error_message: this.$t('components.autocompleteInput.isRequired'),
      timeout: null
    }
  },
  validations: {
    select: {
      required: requiredIf('isRequired')
    }
  },
  computed: {
    isRequired() {
      return this.required
    }
  },
  watch: {
    search (val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (val) {
          if (val == this.select) {
            this.select = null
            this.$emit('update:value', null);
          } else {
            this.querySelections(val)
          }
        } else {
          this.items = []
        }
        clearTimeout(this.timeout);
      },1000)
    },
    value(val) {
      this.select = typeof this.select === 'string' ? this.originalData : val; 
      this.$emit('update:value', this.select);
      this.text = val?.cityState || ''
    }
  },
  mounted () {
    if (this.value) {
      this.originalData = Object.keys(this.value).reduce(
        (accumulator, current) => {
          accumulator[current] = null; 
          return accumulator
        }, {})
        
      if(this.value.idCity !== null) this.select = this.value
      else this.select = null
    } else {
      this.select = this.value
    }
    if (this.list) {
      this.items = this.list
    }
  },
  methods: {
    async querySelections (v) {
      if (v.length < 3) { this.items = []; return; }
      if (v === this.select) return;

      this.loading = true;

      try {
        const cities = await $citiesWithState.find(null, null, { params: { city: v } });
        this.items = cities.data;
      } finally {
        this.loading = false;
      }
    },
    cancelInput () {

      this.select = this.value ? this.value.cityState : this.search
      this.hide = true;
    },
    saveInput () {
      if(this.search === '') {
        this.select = null
        this.$emit('update:value', this.select)
        this.$emit('save', this.select);
        this.$v.$reset()
        this.$v.$touch()
        this.hide = true;
      } else if(this.search === null ){
        this.select = null
        this.$v.$reset()
        this.$v.$touch()
        this.hide = true;
      } else if (this.select ? this.select?.cityState || null : null !== this.value ? this.value?.cityState || null : null) {
        this.$emit('update:value', typeof this.select === 'string' ? this.originalData :  this.select)
        this.$emit('save', typeof this.select === 'string' ? this.originalData : this.select); // TODO: (Diego) This event should be removed is unnecesary using value prop with .sync
        this.hide = true;
      } else {
        this.cancelInput();
      }
    },
    triggerOuterValidation() {
      this.$v.$touch()
    }
  },
}
</script>
