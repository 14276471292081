import ExamEnabler from '../ExamEnabler';
import ExamEnablerTable from '../Views/ExamEnablerTable'
import ExamEnablerUpdate from '../Views/ExamEnablerUpdate'

const ExamEnablerRoute =
{
  path: '/exam-enabler',
  meta: {
    name: {
      en: 'Exam Enabler',
      es: 'Habilitador de exámenes',
    }
  },
  component: ExamEnabler,
  children: [
    {
      path: '/',
      component: ExamEnablerTable,
      meta: { name: {en: 'Index', es: 'Lista'}, group: 'portaladministrativo.exams', path: 'enabler', permission: 'READ' }   
    },
    {
      path: 'update/:id',
      component: ExamEnablerUpdate,
      meta: { name: {en: 'Update', es: 'Modificacion'}, group: 'portaladministrativo.exams', path: 'enabler', permission: 'UPDATE'}
    },
  ]
};

export default ExamEnablerRoute