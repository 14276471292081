<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs  :title="breadcrumbs.title" :description="breadcrumbs.description">
                <div class="mr-4">
                    <Button
                        v-if="canCreate"
                        @clicked="assignedSanction"
                        :text="breadcrumbs.button.assign"
                        outlined
                        class="px-0"
                    ></Button>
                </div>
                <div>
                    <Button
                        v-if="canCreate"
                        @clicked="newSanction"
                        :icon="breadcrumbs.button.icon"
                        :text="breadcrumbs.button.name"
                        depressed
                    ></Button>
                </div>
                </Breadcrumbs>
                <v-row  justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                        :sanctions="sanctions"
                        :headers="headers"
                        :permissions="{ update: canUpdate, delete: canDelete }"
                        @bulk-delete="(sanctions)=>{bulkDeletePopUp(sanctions)}"
                        @deleteUser="(id)=>{deleteSanctionPopUp(id)}"
                        @updateUser="(id)=>{updateSanction(id)}"
                        @openDetails="(id)=>{openDetails(id)}"
                        :clear-selected="clearSelected"
                        :page-count="pagination.pagesAmount"
                        :page="pagination.page"
                        :total-items="totalItems"
                        :deleteTitle="$t('modules.academic.sanctions.table.bulkDelete')"
                        :sortBy.sync="sort.sortBy"
                        :sortDesc.sync="sort.sortDesc"
                        @searchAction="searchAction"
                        @input-pagination="onInputPagination"
                        @length-pagination-change="onLengthPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>

        </v-container>
    </v-container>

</template>

<script>
import SuperTable from '../Components/Supertable/Supertable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { $sanction } from '../Services'
import Button from '@/components/Button/Button';

    export default {
        name:'SanctionsTable',
        components:{
            Breadcrumbs,
            SuperTable,
            Button
        },
        data() {
            return {
                breadcrumbs:{
                    title: this.$t('modules.academic.sanctions.table.breadcrumbs.title'),
                    description: this.$t('modules.academic.sanctions.table.breadcrumbs.description'),
                    button: {
                        name: this.$t('modules.academic.sanctions.table.breadcrumbs.button.name'),
                        assign: this.$t('modules.academic.sanctions.table.breadcrumbs.button.assign'),
                        icon: 'mdi-plus',
                        color: 'primary'
                        }
                },
                sanctions: [],
                headers: [
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.code'),
                    value: 'code',
                    },
                    // {
                    // text: 'Nombre',
                    // value: 'name',
                    // },
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.title'),
                    value: 'title',
                    },
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.type'),
                    value: 'sanctionType.meaning',
                    },
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.level'),
                    value: 'sanctionLevelType.meaning',
                    },
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.duration'),
                    value: 'daysDurationLetter',
                    },
                    {
                    text: this.$t('modules.academic.sanctions.table.headers.status'),
                    value: 'status',
                    },
                    {
                    text: '',
                    value: 'action',
                    sortable: false,
                    },
                ],
                clearSelected: null,
                sort: {
                    sortBy: 'dateTo',
                    sortDesc: true,
                },
                pagination: {
                    limit: 20,
                    page: 1,
                    pagesAmount: 1
                },
                loading: false,
                search: null,
                page: 1,
                totalItems: null,
            }
        },
        computed:{
            canCreate() {
                return this.$permissions.portaladministrativo.academic.sanctions.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.academic.sanctions.READ || this.$permissions.portaladministrativo.academic.sanctions.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.academic.sanctions.DELETE
            },
        },
        methods: {
            async getSanctions(){
                if (this.loading) return false;
                this.clearSelected = new Date().getMilliseconds().toString();
                this.loading = true;

                try {
                    const params = {
                        page: this.pagination.page - 1,
                        length: this.pagination.limit,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                        ...(this.search ? {search:this.search} : {}),
                    };

                    const resp = await $sanction.find(null, null, { params })
                    resp.data.content.forEach(el => {
                        el.daysDuration ? el.daysDurationLetter = `${el.daysDuration} días` : el.daysDurationLetter = 'Permanente';
                    })
                    this.sanctions = resp.data.content
                    this.totalItems = resp.data.totalElements;
                    this.pagination.pagesAmount = resp.data.totalPages;
                } catch (error) {
                    this.banners = [];
                    this.pagination.page = 1
                    this.pagination.pagesAmount = 1
                    this.totalItems = 0;
                    throw error;
                }finally {
                    this.loading = false;
                }
            },
            newSanction () {
                if (this.canCreate)  this.$router.push('sanctions/create')
            },
            async bulkDelete (sanctions) {
                const itemsForDelete = sanctions.map((sanction) => {
                    return { id: sanction.id }
                });

                try {
                    await $sanction.bulkDelete(itemsForDelete)
                    this.clearSelected = new Date().getMilliseconds().toString()
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{ value: this.$t('modules.benefits.table.bulk_delete_error') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback () { } },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary',
                    });
                    throw error;
                }
            },
            bulkDeletePopUp (sanctions) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: sanctions.map(sanction => ({
                    value: sanction.description
                    })),
                    actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(sanctions) },
                    actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary',
                })
            },
            deleteSanctionPopUp (id) {
                const sanction = this.sanctions.find(sanction => sanction.id == id);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: [{ value: sanction.description }],
                    actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteSanction(id) },
                    actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary',
                })
            },
            async deleteSanction (id) {
                try {
                    await $sanction.delete(id);
                    this.getSanctions()
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{ value: this.$t('modules.benefits.table.delete_error') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback () { } },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary',
                    });
                    throw error
                }
            },
            assignedSanction(){
                this.$router.push('sanctions/assigned');
            },
            openDetails(id) {
                this.$router.push(`sanctions/details/${id}`);
            },
            updateSanction (id) {
                if (this.canUpdate) this.$router.push(`sanctions/update/${id}`)
            },
            searchAction(data) {
                this.pagination.page = 1;
                this.search = data;
                this.getSanctions();
            },
            onInputPagination(event) {
                this.pagination.page = event;
                this.getSanctions();
            },
            onLengthPaginationChange(event) {
                this.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.getSanctions();
            },
        },
        mounted() {
            this.getSanctions()
        },
        watch: {
            sort: {
                handler() {
                    this.getSanctions();
                },
                deep: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
