import CreditCardInterestTable from '../Views/CreditCardInterestTable';
import CreditCardInterestCreate from '../Views/CreditCardInterestCreate';
import CreditCardInterestUpdate from '../Views/CreditCardInterestUpdate';


const CreditCardInterestRoute =[

    {
        path:'/',
        component: CreditCardInterestTable,
        meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'interest', permission: 'READ' }
    },
    {
        path:'create',
        component: CreditCardInterestCreate,
        meta: { name: 'CreditCardInterestCreate', group: 'portaladministrativo.prices', path: 'interest', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: CreditCardInterestUpdate,
        meta: { name: 'CreditCardInterestUpdate', group: 'portaladministrativo.prices', path: 'interest', permission: 'UPDATE' }
    }
];

export default CreditCardInterestRoute