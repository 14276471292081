import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class Students extends Api {
  constructor(http, config = {}) {
    super(http, url, 'students', config);
  }
  async signContract(apiMethod = null, parameters, config = {}) {
    config = { ...this._config, ...config };

    return this._http.post(`${this._baseUrl}/${apiMethod}`, parameters, config);
  }

  async validateEcert(token, config = {}) {
    config = { ...this._config, ...config };

    return this._http.post(`${this._baseUrl}/ecert/notification?token=${token}` , config);
  }
}
