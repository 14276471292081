// import services

export default {

  setRecoveryInfo ({ commit }, recoveryData) {
    commit('SET_RECOVERYINFO', recoveryData)
  },
  setValidationCode ({ commit }, validationCode) {
    commit('SET_VALIDATIONCODE', validationCode)
  },
  clearErrorLogin ({ commit }) {
    commit('CLEAR_ERRORLOGIN');
  }

}
