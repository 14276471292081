<template>
  <v-container>
      <h4 class="primary--text"> {{ $t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.title') }}</h4>
        <v-row>
            <v-col cols="12" sm="6">
                <v-card outlined class="py-2 rounded-lg d-flex align-center justify-start pl-4">
                    <div class="d-flex flex-row">
                        <v-icon large :class="studentSign ? 'teal--text' : 'gray--text'" class="mr-2">mdi-checkbox-marked-circle</v-icon>
                        <div class="d-flex flex-column">
                            <h4 class="text--secondary">
                                {{$t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.item_one')}}
                            </h4>
                            <span class="text--secondary caption" v-if="signatures && signatures.nomAlumno">
                                {{ signatures.nomAlumno }}
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card outlined class="py-2 rounded-lg d-flex align-center justify-start pl-4">
                    <div class="d-flex flex-row">
                        <v-icon large :class="companySign ? 'teal--text' : 'gray--text'" class="mr-2 h1">mdi-checkbox-marked-circle</v-icon>
                        <div class="d-flex flex-column">
                            <h4 class="text--secondary">
                                {{$t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.item_two')}}
                            </h4>
                            <span class="text--secondary caption" v-if="signatures && signatures.nomEmpresa">
                                {{ signatures.nomEmpresa }}
                            </span>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <div v-if="!$isTeclab">
            <p>
                {{`${ $t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.manage_signature')}`}}
            </p>
            <p>
                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.days1')}}
                <a
                    style="text-decoration:none" 
                    class="primary--text"
                    :href="`mailto:${$t('modules.portalalumno.practice.stepper.dateStep.requirements.email')}`"
                    >
                    {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.email') }}
                </a>
                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.days2')}}
            </p>
        </div>
  </v-container>
</template>

<script>
export default {
    name: 'AgreementPP',
    props: {
        signatures: {type: Object},
    },
    computed: {
        studentSign(){
            return this.signatures ? this.signatures.firmaAlumno : false
        },
        companySign(){
            return this.signatures ? this.signatures.firmaEmpresa : false
        },
    }
}
</script>

<style>

</style>