<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom">
        <template v-if="!createExamStatus">
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.sistemaQ.create.card.title')"
                :subtitle="$t('modules.sistemaQ.create.card.subtitle')"
                :switch-label="$t('modules.sistemaQ.create.card.switch')"
                :switchLabel="formattedStatus"
                :switchValue="newExamBoard.status"
                switcher
                @switchChange="onSwitchChange"
                class="mb-6"
              >
                <v-row v-if="!isOnlyCeo">
                  <v-col sm="4">
                    <v-card-text>{{ $t('modules.sistemaQ.create.ceoSelectLabel') }}</v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      outlined
                      :label="$t('modules.sistemaQ.create.ceoSelectLabel')"
                      v-model="newExamBoard.ceoId"
                      :items="formattedCeos"
                      :error-messages="$v.newExamBoard.ceoId.$error ? $t('modules.sistemaQ.errors.required') : ''"
                      @blur="$v.newExamBoard.ceoId.$touch()">
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="4">
                    <v-card-text>{{ $t('modules.sistemaQ.create.card.date') }}</v-card-text>
                  </v-col>
                  <v-col sm="3">
                    <v-menu
                      ref="dateFromMenu"
                      v-model="dateFromMenu"
                      :close-on-content-click="false"
                      :return-value.sync="newExamBoard.turnStart"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent(newExamBoard.turnStart)"
                          :label="$t('modules.sistemaQ.create.card.from')"
                          persistent-hint
                          append-icon="mdi-calendar"
                          readonly
                          color="primary"
                          outlined
                          v-on="on"
                          :error-messages="($v.newExamBoard.turnStart.$dirty && !newExamBoard.turnStart) ? $t('modules.sistemaQ.errors.dateRequired') : ($v.newExamBoard.turnStart.$error ? $t('modules.sistemaQ.errors.datePair') : '')"
                          @blur="$v.newExamBoard.turnStart.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newExamBoard.turnStart"
                        color="primary"
                        no-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="onCancelDatePickerClick('dateFromMenu')"
                        >{{ $t('actions.cancel') }}</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onOkDatePickerClick('dateFromMenu', newExamBoard.turnStart)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col sm="3">
                    <v-menu
                      ref="dateToMenu"
                      v-model="dateToMenu"
                      :return-value.sync="newExamBoard.turnEnd"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent(newExamBoard.turnEnd)"
                          :label="$t('modules.sistemaQ.create.card.to')"
                          persistent-hint
                          append-icon="mdi-calendar"
                          readonly
                          color="primary"
                          outlined
                          v-on="on"
                          :error-messages="($v.newExamBoard.turnEnd.$dirty && !newExamBoard.turnEnd) ? $t('modules.sistemaQ.errors.dateRequired') : ($v.newExamBoard.turnEnd.$error ? $t('modules.sistemaQ.errors.datePair') : '')"
                          @blur="$v.newExamBoard.turnEnd.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newExamBoard.turnEnd"
                        color="primary"
                        no-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="onCancelDatePickerClick('dateToMenu')"
                        >{{ $t('actions.cancel') }}</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onOkDatePickerClick('dateToMenu', newExamBoard.turnEnd)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col sm="4">
                    <v-card-text>{{ $t('modules.sistemaQ.create.card.time') }}</v-card-text>
                  </v-col>
                  <v-col sm="5">
                    <MultipleFormGroup
                      :items="$v.groups.$each.$iter"
                      :length="groups.length"
                      @removeGroup="removeGroup"
                      @addGroup="addGroup"
                      styleless
                    >
                      <template v-slot:default="{item}">
                        <v-container fluid class="py-0">
                          <v-row>
                            <v-col sm="12" class="px-0 py-0">
                              <v-select
                                :label="$t('modules.sistemaQ.create.card.time')"
                                :items="list"
                                outlined
                                class="ancho2"
                                v-model="item.time.$model"
                                :error-messages="item.time.$dirty && item.time.$invalid ? ( !item.time.required ? $t('modules.sistemaQ.errors.hourRequired') : $t('modules.sistemaQ.errors.hourRepeated') ) : ''"
                                @blur="item.time.$touch()"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </MultipleFormGroup>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="4">
                    <v-card-text>{{$t('modules.sistemaQ.create.card.type')}}</v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="4">
                    <v-card-text>{{$t('modules.sistemaQ.create.card.lab')}}</v-card-text>
                  </v-col>
                  <v-col sm="6">
                    <MultipleFormGroup
                      :items="$v.groupLaboratory.$each.$iter"
                      :length="groupLaboratory.length"
                      @removeGroup="removeGroupLaborator"
                      @addGroup="addGroupLaboratory"
                      styleless
                    >
                      <template v-slot:default="{item}">
                        <v-container fluid class="py-0">
                          <v-row class="justify-space-between">

                            <v-col sm="5" class="px-0 py-0">
                              <v-select
                                v-model="item.proctoringId.$model"
                                label="Proctoring"
                                :items="proctoringTypes"
                                item-text="meaning"
                                outlined
                                class="ancho2"
                                :error-messages="(item.quantity.$model > 0) && item.proctoringId.$invalid 
                                ? (!item.proctoringId.required ? $t('modules.sistemaQ.errors.proctoringRequired') 
                                : $t('modules.sistemaQ.errors.proctoringRepeated')) : '' "
                                @blur="item.$touch()"
                              ></v-select>
                            </v-col>
                            <v-col sm="5" class="px-0 py-0">
                              <v-text-field
                                type="number"
                                v-model.number="item.quantity.$model"
                                class="ancho2"
                                outlined
                                placeholder="0"
                                min="0"
                                max="350"
                                @keypress="$validateIntegerInputNumber($event, item.quantity, 350)"
                                :error-messages="item.quantity && item.quantity.$error ? $t('modules.sistemaQ.errors.quantityMinValue') : ''"
                                @blur="item.quantity.$touch()">
                              </v-text-field>
                            </v-col>

                          </v-row>
                        </v-container>
                      </template>
                    </MultipleFormGroup>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col sm="4">
                    <v-card-text>{{$t('modules.sistemaQ.create.card.notebook')}}</v-card-text>
                  </v-col>
                  <v-col sm="6">
                    <MultipleFormGroup
                      :items="$v.groupNotebook.$each.$iter"
                      :length="groupNotebook.length"
                      @removeGroup="removeGroupNotebook"
                      @addGroup="addGroupNotebook"
                      styleless
                    >
                      <template v-slot:default="{item}">
                        <v-container fluid class="py-0">
                          <v-row class="justify-space-between">

                            <v-col sm="5" class="px-0 py-0">
                              <v-select
                                v-model="item.proctoringId.$model"
                                label="Proctoring"
                                :items="proctoringTypes"
                                item-text="meaning"
                                outlined
                                class="ancho2"
                                :error-messages="(item.quantity.$model > 0) && item.proctoringId.$invalid 
                                ? (!item.proctoringId.required ? $t('modules.sistemaQ.errors.proctoringRequired') 
                                : $t('modules.sistemaQ.errors.proctoringRepeated')) : ''"
                                @blur="item.$touch()"
                              ></v-select>
                            </v-col>
                            <v-col sm="5" class="px-0 py-0">
                              <v-text-field
                                type="number"
                                v-model.number="item.quantity.$model"
                                class="ancho2"
                                outlined
                                placeholder="0"
                                min="0"
                                max="350"
                                @keypress="$validateIntegerInputNumber($event, item.quantity, 350)"
                                :error-messages="item.quantity && item.quantity.$error ? $t('modules.sistemaQ.errors.quantityMinValue') : ''"
                                @blur="item.quantity.$touch()">
                              </v-text-field>
                            </v-col>

                          </v-row>
                        </v-container>
                      </template>
                    </MultipleFormGroup>
                  </v-col>
                </v-row>

              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  @clicked="tmpDrawerOpen"
                  :text="$t('modules.sistemaQ.create.button.cancel')"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('modules.sistemaQ.create.button.create')"
                  :successText="$t('actions.created')"
                  :errorText="createButton.errorText"
                  :disabled="!canCreate || isCreateButtonDisabled"
                  @clicked="createExamBoard"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
        <SuccessMessage
          v-else
          :type="successMessage.type"
          :title="successMessage.title"
          :actionPrimary="successMessage.actionPrimary"
          :actionSecondary="successMessage.actionSecondary"
        />
      </v-container>
    </v-container>
  </div>
</template>

<script>
  import { $exams, $types } from '../services';
  import { required, minLength, helpers, minValue, requiredIf } from 'vuelidate/lib/validators';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import Button from '@/components/Button/Button';
  import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import { mapGetters } from 'vuex';
  import moment from 'moment'

  const datePairValid = (fieldNameStart, fieldNameEnd) => (value, vm) => !helpers.req(value && vm[fieldNameEnd] && vm[fieldNameStart]) || moment(vm[fieldNameStart]).isSameOrBefore(moment(vm[fieldNameEnd]))

  export default {
    name: 'SistemaQExamCreate',
    components: {
      OutlinedCard,
      Button,
      MultipleFormGroup,
      SuccessMessage
    },
    props: {
      selectedDate: {type: String},
      clearDrawer: {type: Boolean},
      availableCeos: {type: Array},
      selectedCeo: {type:Array}
    },
    data() {
      return {
        selectedTimeFormat: 'HH:MM',
        createExamStatus: false,
        newExamBoard: {
          ceoId: null,
          userId: null,
          turnStart: '',
          turnEnd: '',
          laboratory: false,
          notebook: false,
          labQuantity: 0,
          noteQuantity: 0,
          status: true,
          schedules: [],
          proctoringType:''
        },
        selects: [],
        dateFromMenu: false,
        dateToMenu: false,
        createButton: {
          loading: false,
          success: true,
          error: true,
          errorText: 'Error'
        },
        list: [],
        rawList: [],
        groups: [{time: null}],

        groupLaboratory: [ {examPeriodType:'70' ,proctoringId:'',quantity:0} ],
        groupNotebook: [ {examPeriodType:'71' ,proctoringId:'',quantity:0} ],
        proctoringTypes:[],
        successMessage: {
          type: 'success',
          title: this.$t('modules.sistemaQ.create.success_message.title'),
          actionPrimary: { text: this.$t('modules.sistemaQ.create.success_message.actions.primary_text'), callback: () => { this.tmpDrawerOpen() } },
          actionSecondary: { text: this.$t('modules.sistemaQ.create.success_message.actions.secondary_text'), callback: () => { this.clearComponent() } }
        },
      };
    },
    validations: {
      newExamBoard: {
        ceoId: {required},
        turnStart: {
          required,
          datePairValid: datePairValid('turnStart', 'turnEnd')
        },
        turnEnd: {
          required,
          datePairValid: datePairValid('turnStart', 'turnEnd')
        },
        status: {required}
        },
      groups: {
        minLength: minLength(1),
        $each: {
          time: {
            required,
            isUnique(value) {
              return this.groups.filter(item => item.time == value).length <= 1
            }
          },
        }
      },
      groupLaboratory: {
        $each: {
          examPeriodType:{required},
          proctoringId: {
            required:requiredIf( (value) => value.quantity > 0 ),
            isUnique(value) {
                return this.groupLaboratory.filter(item => item.proctoringId == value).length <= 1
            }
          },
          quantity: {
            minValue: minValue(0)
          },
        }
      },
      groupNotebook: {
        $each: {
          examPeriodType:{required},
          proctoringId: {
            required:requiredIf( (value) => value.quantity > 0 ),
            isUnique(value) {
                return this.groupNotebook.filter(item => item.proctoringId == value).length <= 1
              }
          },
          quantity: {
            minValue: minValue(0)
          },
        }
      },
    },

    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.exams.creation.CREATE
      },
      isCreateButtonDisabled () {
        return this.$v.$invalid || (!this.hasQuantityLaboratory && !this.hasQuantityNotebook) 
      },
      isOnlyCeo () {
        return this.availableCeos.length <= 1
      },
      formattedCeos () {
        return this.availableCeos.map(ceo => ({value: ceo.id, text: ceo.description}))
      },
      formattedStatus () {
        return this.newExamBoard.status ? this.$t('modules.sistemaQ.create.cards.switch.enable') : this.$t('modules.sistemaQ.create.cards.switch.disable')
      },
      hasQuantityLaboratory(){
        const res = this.groupLaboratory.filter( (item) => item.quantity > 0 )
        return res.length > 0 ? true : false
      },
      hasQuantityNotebook(){
        const res = this.groupNotebook.filter( (item) => item.quantity > 0 )
        return res.length  > 0 ? true : false
      },
      hasNotebook(){
        this.groupNotebook.forEach( (item) => {
          item.quantity !== 0 
            ? this.newExamBoard.notebook = true 
            : this.newExamBoard.notebook = false
        })
        return this.newExamBoard.notebook
      },
      hasLaboratory(){
        this.groupLaboratory.forEach( (item) => {
          item.quantity !== 0 
            ? this.newExamBoard.laboratory = true 
            : this.newExamBoard.laboratory = false
        })
        return this.newExamBoard.laboratory
      },
      ...mapGetters({
        userId: 'commons/getUserID'
      }),
    },
    mounted () {
      this.newExamBoard.userId = this.userId
      this.newExamBoard.ceoId = this.selectedCeo[0]?.value;
      //this.selectedTimeFormat = this.configurationOptions.timeFormat.find(format => format.value === this.configurationValues.timeFormat)
      this.generateTimes('HH:MM') //this.selectedTimeFormat
      this.getProctoringTypes()
    },
    methods: {
      async getProctoringTypes() {
        const { data } =  await $types.find(null, null, { params: { type: 'PROCTORING_TYPE' } })
        this.proctoringTypes = data
      },
      onSelectChange (ev, index) {
        this.selects[index] = ev
        this.list = this.rawList.filter(schedule => !this.groups.map(group => group.time).includes(schedule.value))
      },
      clearComponent () {
        this.newExamBoard = {
          ceoId: null,
          userId: this.newExamBoard.userId,
          turnStart: '',
          turnEnd: '',
          laboratory: false,
          notebook: false,
          labQuantity: null,
          noteQuantity: null,
          status: true,
          schedules: []
        }
        this.groups = [{time: null}]
        this.generateTimes(this.selectedTimeFormat)
        this.$v.$reset()
        setTimeout(() => {
          this.createExamStatus = false;
        }, 1000);
      },
      async triggerExamBoardCreation (newExamBoard) {
        if (!this.canCreate) return false;

        const proctoring = []
        this.groupNotebook.forEach((item)=> proctoring.push(item))
        this.groupLaboratory.forEach((item)=> proctoring.push(item))
        newExamBoard.proctoring = proctoring

        newExamBoard.notebook = this.hasNotebook
        newExamBoard.laboratory = this.hasLaboratory
        const examBoardToCreate = {...newExamBoard}
        examBoardToCreate.schedules = this.groups.map(item => item.time)
        try {
          await $exams.create(examBoardToCreate, {}, 'periods')
          this.$emit('successfulCreation')
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.sistemaQ.create.success_message.title');
          this.successMessage.actionPrimary = { text: this.$t('modules.sistemaQ.create.success_message.actions.primary_text'), callback: () => { this.tmpDrawerOpen() } },
          this.successMessage.actionSecondary = { text: this.$t('modules.sistemaQ.create.success_message.actions.secondary_text'), callback: () => { this.clearComponent() } };
        } catch (err) {
          this.successMessage.type = 'error';
          this.successMessage.title = err.codeMeaning;
          this.successMessage.actionPrimary = { text: this.$t('modules.sistemaQ.create.success_message.actions.back'), callback: () => { this.createExamStatus = false } },
          this.successMessage.actionSecondary = null;
        } finally {
          this.createExamStatus = true
        }
      },
      async createExamBoard () {
        if (!this.newExamBoard.status) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.sistemaQ.create.warning_popup.title'),
            content: this.$t('modules.sistemaQ.create.warning_popup.content'),
            actionPrimary: {
              text: this.$t('modules.sistemaQ.create.button.create'),
              callback: async () => {await this.triggerExamBoardCreation(this.newExamBoard)}
            },
            actionSecondary: {
              text: this.$t('actions.cancel'),
              callback: () => {this.resetButtonValues()}
            },
            icon: { color: 'warning', name: 'mdi-eye-off-outline' },
            color: 'primary',
          })
        } else {
          await this.triggerExamBoardCreation(this.newExamBoard)
        }
      },
      onSwitchChange () {
        this.newExamBoard.status = !this.newExamBoard.status
      },
      generateTimes (format) {
        if (format.text === 'AM/PM') {
          this.list = [
            {text: '00:00:00 AM', value: '00:00:00'},
            {text: '01:00:00 AM', value: '01:00:00'},
            {text: '02:00:00 AM', value: '02:00:00'},
            {text: '03:00:00 AM', value: '03:00:00'},
            {text: '04:00:00 AM', value: '04:00:00'},
            {text: '05:00:00 AM', value: '05:00:00'},
            {text: '06:00:00 AM', value: '06:00:00'},
            {text: '07:00:00 AM', value: '07:00:00'},
            {text: '08:00:00 AM', value: '08:00:00'},
            {text: '09:00:00 AM', value: '09:00:00'},
            {text: '10:00:00 AM', value: '10:00:00'},
            {text: '11:00:00 AM', value: '11:00:00'},
            {text: '12:00:00 AM', value: '12:00:00'},
            {text: '01:00:00 PM', value: '13:00:00'},
            {text: '02:00:00 PM', value: '14:00:00'},
            {text: '03:00:00 PM', value: '15:00:00'},
            {text: '04:00:00 PM', value: '16:00:00'},
            {text: '05:00:00 PM', value: '17:00:00'},
            {text: '06:00:00 PM', value: '18:00:00'},
            {text: '07:00:00 PM', value: '19:00:00'},
            {text: '08:00:00 PM', value: '20:00:00'},
            {text: '09:00:00 PM', value: '21:00:00'},
            {text: '10:00:00 PM', value: '22:00:00'},
            {text: '11:00:00 PM', value: '23:00:00'},
            {text: '12:00:00 PM', value: '24:00:00'}
          ]
        } else {
          this.list = [
            {text: '00:00:00', value: '00:00:00'},
            {text: '01:00:00', value: '01:00:00'},
            {text: '02:00:00', value: '02:00:00'},
            {text: '03:00:00', value: '03:00:00'},
            {text: '04:00:00', value: '04:00:00'},
            {text: '05:00:00', value: '05:00:00'},
            {text: '06:00:00', value: '06:00:00'},
            {text: '07:00:00', value: '07:00:00'},
            {text: '08:00:00', value: '08:00:00'},
            {text: '09:00:00', value: '09:00:00'},
            {text: '10:00:00', value: '10:00:00'},
            {text: '11:00:00', value: '11:00:00'},
            {text: '12:00:00', value: '12:00:00'},
            {text: '13:00:00', value: '13:00:00'},
            {text: '14:00:00', value: '14:00:00'},
            {text: '15:00:00', value: '15:00:00'},
            {text: '16:00:00', value: '16:00:00'},
            {text: '17:00:00', value: '17:00:00'},
            {text: '18:00:00', value: '18:00:00'},
            {text: '19:00:00', value: '19:00:00'},
            {text: '20:00:00', value: '20:00:00'},
            {text: '21:00:00', value: '21:00:00'},
            {text: '22:00:00', value: '22:00:00'},
            {text: '23:00:00', value: '23:00:00'},
            {text: '24:00:00', value: '24:00:00'}
          ]
        }
        this.rawList = [...this.list]
      },
      onCancelDatePickerClick (model) {
        this[model] = false
      },
      onOkDatePickerClick (ref, date) {
        this.$refs[ref].save(date)
      },
      formatDateToCurrent (date) {
        return date ? moment(date).format('DD/MM/YYYY') : null
      },
      removeGroup(index) {
        this.groups.splice(index, 1);
        // this.selects.splice(index, 1);
        // this.list = this.rawList.filter(schedule => !this.groups.map(group => group.time).includes(schedule.value))
      },
      addGroup() {
        this.groups.push({time: null});
      },
      removeGroupLaborator(index) {
        this.groupLaboratory.splice(index, 1);
      },
      removeGroupNotebook(index) {
        this.groupNotebook.splice(index, 1);
      },
      addGroupLaboratory() {
        this.groupLaboratory.push({examPeriodType:'70' ,proctoringId:'',quantity:0} );
      },
      addGroupNotebook() {
        this.groupNotebook.push({examPeriodType:'71' ,proctoringId:'',quantity:0} );
      },
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      tmpDrawerOpen() {
        this.$emit('tmpDrawerOpen');
        this.clearComponent()
      },
      async getProctoring(){
        const { data } = await $types.find(null, null, { params: { type: 'PROCTORING_TYPE'}})
        this.proctoringTypes = data
      }
    },

    watch: {
      selectedDate (newVal) {
        if (newVal) {
          this.newExamBoard.turnStart = newVal
          this.newExamBoard.turnEnd = newVal
          this.$v.newExamBoard.turnStart.$touch()
          this.$v.newExamBoard.turnEnd.$touch()
        }
      },
      clearDrawer () {
        this.clearComponent()
      }
    },
  };
</script>