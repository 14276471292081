import Zones from '../Zones';
import ZonesTable from '../views/ZonesTable';
import ZonesCreate from '../views/ZonesCreate';
import ZonesUpdate from '../views/ZonesUpdate';

const ZonesRoute =
{
  path: '/zones',
  meta: { 
    name: {
      en: 'Zones',
      es: 'Zonas',
      es_CL: 'Regiones',
      }
    },
  component: Zones,
  children: [
    {
      path: '/',
      component: ZonesTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'zones', permission: 'READ' }
    }, {
      path: 'create',
      component: ZonesCreate,
      meta: { name: 'Zones Create', group: 'portaladministrativo.prices', path: 'zones', permission: 'CREATE' }
    }, {
      path: 'update/:id',
      component: ZonesUpdate,
      meta: { name: 'Zones Update', group: 'portaladministrativo.prices', path: 'zones', permission: 'READ' }
    },
  ]
};

export default ZonesRoute
