import Roles from '../Roles';
import RolesCreate from '../Views/RolesCreate';
import RolesUpdate from '../Views/RolesUpdate';
import RolesTable from '../Views/RolesTable';

const RolesRoute =
{
  path: '/roles',
  meta: { 
    name: {
      en: 'Roles',
      es: 'Roles',
      }
    },
  component: Roles,
  children: [
    {
      path: '/',
      component: RolesTable,
      meta: { name: 'Index', group: 'portaladministrativo', path: 'roles', permission: 'READ' }
    },
    {
      path: 'create',
      component: RolesCreate,
      meta: { name: 'Create Role', group: 'portaladministrativo', path: 'roles', permission: 'CREATE' }
    },
    {
      path: 'update/:id',
      component: RolesUpdate,
      meta: { name: 'Update Role', group: 'portaladministrativo', path: 'roles', permission: 'READ' }
    },
  ]
};

export default RolesRoute
