<template>
  <div>
    <template class="container-custom d-flex align-center justify-center" v-if="!documentationError">
      <v-row>
        <v-col v-if="loading" sm="12" class="d-flex justify-center">
          <v-progress-circular block class="mt-5" indeterminate color="primary"></v-progress-circular>
        </v-col>
        <v-col v-else sm="10" class="d-flex flex-column mx-auto pt-0">
          <v-card-text>
            <div class="title ma-0 ml-1 px-0 pt-0 primary--text font-weight-bold">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.title') }}</div> 
            <div class="subtitle-1 ma-0 ml-1 px-0 pt-2">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.description') }}</div>
          </v-card-text>
          <OutlinedCard class="mb-6" containerClass="mt-0">
            <v-row>
              <v-col sm="12" class="d-flex flex-column mx-auto">
                <v-sheet class="" color="grey lighten-3 justify-start info-sheet">
                  <v-row>
                    <v-col cols="12" class="ml-7">
                      <div class="primary--text">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.box.title') }}</div>
                      <div v-if="boxValue" class="font-weight-bold">{{ boxValue }}</div>
                      <div v-else class="font-weight-bold">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.box.noValue') }}</div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-if="commentary">
              <v-col sm="12" class="d-flex flex-column mx-auto">
                <v-sheet class="" color="grey lighten-3 justify-start info-sheet">
                  <v-row>
                    <v-col cols="12" class="ml-7">
                      <div class="primary--text">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.commentary') }}</div>
                      <div class="font-weight-bold">{{ commentary }}</div>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>

            <!-- new charge data -->
            <v-card elevation="0" class="py-2 px-0 my-2">
              <div class="d-flex flex-wrap align-start justify-lg-space-between my-2">
                <v-card 
                  outlined
                  v-for="(documentation,index) in filesRequired" 
                  :class="['pa-5 documentation-card ma-1',oldFilesRejected[index] ? 'documentation-rejected' : '']"
                  :elevation="oldFilesRejected[index] ? 2 : 0"
                  :key="`req-doc-${index}`"
                >
                  <div class="d-block">
                    <h1 class="body-1 font-weight-bold ma-0">{{ documentation.name }}</h1>
                    <h2 class="body-2 mt-1 mb-3">{{ documentation.description }}</h2>
                    <template v-if="oldFilesComputed[index] && oldFilesComputed[index].length > 0">
                      <v-divider/>
                      <v-list class="files-list">
                        <h2 class="caption my-1">{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.benefitRenewal.revisedDocumentation') }}</h2>
                        <v-list-item v-for="(item, i) in oldFilesComputed[index]" :key="i">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.filename }}</v-list-item-title>
                          </v-list-item-content>
                          <div class="d-flex align-center caption font-weight-bold">
                            <v-icon class="mr-2" :class="item.statusClass">{{ item.statusIcon }}</v-icon>
                            {{ item.statusText }}
                          </div>
                        </v-list-item>
                      </v-list>
                    </template>
                    <v-divider/>
                    <FileUploader :files.sync="files[index]" />
                  </div>
                </v-card>
              </div>
            </v-card>
            <!-- /new charge data -->
          </OutlinedCard>

          <div class="d-flex justify-end mb-12">
            <Button
              white
              class="mr-4"
              :text="$t('actions.cancel')"
              @clicked="$emit('goBack')"
              @end="resetButtonValues"
            ></Button>
            <Button
              :loading="sendLoading"
              :success="createButton.success"
              :error="createButton.error"
              :disabled="disabledSave"
              :text="$t('actions.apply')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              @end="resetButtonValues"
              @clicked="sendDocumentation"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </template>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
    ></SuccessMessage>
  </div>
</template>

<script>
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import { required } from 'vuelidate/lib/validators';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import Button from '@/components/Button/Button';
  import FileUploader from '@/components/FileUploader/FileUploader'
  import { $studentProcessing } from '../../services'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Renewal',
    components: {
      OutlinedCard,
      Button,
      FileUploader,
      SuccessMessage,
    },
    props: {
      procedure:{ type: Number },
      type: { type: String }
    },
    data () {
      return {
        sendLoading: false,
        documentationItems: [
          this.$t('modules.portalalumno.procedures.procedureCeoChange.documentationSection.item1'),
          this.$t('modules.portalalumno.procedures.procedureCeoChange.documentationSection.item2'),
          this.$t('modules.portalalumno.procedures.procedureCeoChange.documentationSection.item3')
        ],
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        files: [],
        oldFiles: [],
        filesRequired: [],
        saved: 0,
        ok: true,
        commentary: null,
        successMessage: {
          type: null,
          title: null,
          actionPrimary: null
        },
        documentationError: false,
        loading: false,
      }
    },
    computed: {
      ...mapGetters({
        studentData: 'commons/getStudentData',
        studentUserId: 'commons/getStudentUserID'
      }),
      disabledSave() {
        if (!!this.procedure || this.oldFiles?.length < 1) return !!this.files?.find(doc=>doc?.length<1)
        else return this.oldFilesRejected?.find(status => status)
      },
      boxValue() {
        return (this.type == 'benefitRenewal' && this.studentData.benefit) ? this.studentData.benefit.description : ''
      },
      oldFilesRejected(){
        const rejecteds = []
        this.oldFilesComputed.forEach((docType, index)=>{
          rejecteds[index] = !!docType?.find(doc=> !doc.isApproved) && this.files[index].length < 1;
        })
        return rejecteds
      },
      oldFilesComputed(){
        const documentation = [];
        this.filesRequired.forEach(docType => {
          const docsFiltered = this.oldFiles?.filter(doc=> docType.id === doc.documentation.id)
                                            .map(docFiltered => { return this.mapDocumentationState(docFiltered) })
          documentation.push(docsFiltered);
        });
        return documentation
      },
    },
    mounted () {
      this.initialize();
    },
    methods: {
      async initialize(){
        this.loading = true
        this.studentData.benefit?.benefitDocumentation?.forEach(doc => {
          this.filesRequired.push(doc.documentation);
          this.files.push([]);
        })

        if (this.procedure) {
          try {
            const procedureData = await $studentProcessing.findById(this.procedure);
            this.commentary = procedureData.data.commentary;
            this.oldFiles = procedureData.data.documentations;
          } catch (error) {
            this.commentary = null
            this.oldFiles = []
            throw error
          }
        }
        this.loading = false
      },
      resetButtonValues() {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      makeSingleDocumentationArray() {
        this.toUpload = [];
        this.files.forEach((docType, index)=>{
          docType.forEach((doc,docIndex) => {
            this.toUpload.push({
              idDocumentationToReplace: (this.oldFilesComputed[index]?.length > docIndex && !this.oldFilesComputed[index][docIndex].isApproved) ? this.oldFilesComputed[index][docIndex].id : null,
              idDocumentationType: this.filesRequired[index].id,
              file: doc,
            });
          });
        });
      },
      mapDocumentationState(documentation) {
        const isApproved = documentation.docStatus.value === 94;
        return {
          isApproved,
          id: documentation.id,
          filename: documentation.fileName,
          status: isApproved ? 'A' : 'R',
          statusIcon: isApproved ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline',
          statusClass: isApproved ? 'aproved' : 'rejected',
          statusText: isApproved ? 'Aprobado' : 'Rechazado',
        }
      },
      async sendDocumentation() {
        this.sendLoading = true
        this.makeSingleDocumentationArray();
        this.ok = true;
        this.saved = 0;
        await this.toUpload.forEach(async (doc)=>{
          try {
            // Make Model
            const model = {
              user: this.studentUserId ? this.studentUserId : null,
              studentRecord: this.studentData.academicRegister,
              benefit: {id:this.studentData.benefit.id},
              student: {
                id: this.studentData.student.id,
                user: { name: this.studentData.student.user.name },
              },
              idDocumentation: doc.idDocumentationToReplace,
            };

            // Add data to Form
            const formData = new FormData()
            formData.append('documentation', doc.file);
            formData.append('model', JSON.stringify(model));
            formData.append('idDocumentation', doc.idDocumentationType);

            // Send
            await $studentProcessing.formData(formData, 'uploadDocumentation')
          } catch {
            this.ok = false;
          } finally {
            this.saved++
          }
        });
      }
    },
    watch: {
      saved(val) {
        if (!val < this.toUpload?.length){
          if (this.ok) {
            this.documentationError = true
            this.successMessage.type = 'success'
            this.successMessage.title = 'Documentación cargada correctamente'
            this.successMessage.actionPrimary = { text: this.$t('actions.next'), callback: () => { this.$emit('uploadDocumentation') } }
          } else {
            this.documentationError = true
            this.successMessage.type = 'error'
            this.successMessage.title = 'Error al cargar documentación'
            this.successMessage.actionPrimary = { text: this.$t('actions.goBack'), callback: () => { this.files = []; this.documentationError = false; } }
          }
          this.sendLoading = false;
        }
      }
    },
    validations: {
      procedureCeoChange: {
        city: { value: { required } },
        state: { value: { required } },
        ceo: { value: { required } },
        reason: { required }
      }
    },
  }
</script>

<style lang="sass" scoped>
.info-sheet
  border-radius: 5px
  border: solid 1px #cecece
  height: 100%
  font-size: 14px

.documentation-card
  width: 49%
  min-height: 300px
  transition: .5s

.documentation-rejected
  border: 1px solid #ff8686 !important

.files-list
  .v-list-item
    min-height: 0
    font-size: 0.9em
    font-weight: bold
    color: #aaa !important

    .v-list-item__content
      padding-top: 10px !important
      padding-bottom: 10px !important

    .v-icon.uploaded
      color: #007eff !important

    .v-icon.aproved
      color: #39c139 !important

    .v-icon.rejected
      color: #ff0000 !important

</style>