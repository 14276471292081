export default {

  // TRASH

  SAVE_IN_TRASH (state, value) {
    state.trash.push(value)
  },
  CLEAN_TRASH (state) {
    state.trash = []
  },

  // Discounts Table/List
  SET_DISCOUNTS (state, value) {
    state.discounts = value;
  }
}
