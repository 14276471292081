<template>
    <div class="banner">
        <img class="banner__image" :src="banner.image">
        <div class="banner__content">
            <div class="banner__content__text">
                <div class="banner__content__text__title" v-html="banner.text.title"></div>
                <div class="banner__content__text__comment" v-html="banner.text.comment"></div>
            </div>
            <v-btn
                depressed
                rounded
                class="banner__content__button"
                target="_blank"
                @click="buttonClick(banner.button)"
            >
                {{ banner.button.text }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'OnboardingCentralSecondaryBanner',
        props: {
            banner: Object
        },
        computed: {
          ...mapGetters({
            canvasId: 'commons/getCanvasId',
            studentData: 'commons/getStudentData',
          }),
        },
        methods: {
            buttonClick(button) {
                this.$trackEvent(button.analytics.category, button.analytics.action, button.analytics.label)
                this.$redirectToCanvas(this.canvasId, this.studentData.student.user.name)
            }
        }
    };
</script>

<style lang="sass" scoped>
    .banner
        min-height: 58px
        width: 100%
        background-color: #FFFFFF
        border: 1px solid #D4D4D4
        border-radius: 5px
        position: relative
        display: flex
        margin-top: 24px
        &__image
            position: absolute
            left: 0
            bottom: 0
            height: 116%
            max-height: 65px
        &__content
            display: flex
            width: 100%
            align-items: center
            justify-content: space-between
            &__text
                margin-left: 158px
                &__title
                    font-size: small
                    font-weight: bold
                &__comment
                    color: #00000099
                    font-size: small
            &__button
                color: #0081BDDE
                background-color: #DFEEF1 !important
                font-weight: bold
                letter-spacing: 0
                padding: 0 36px !important
                margin-right: 32px

</style>