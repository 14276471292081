<template>
  <SecurityCodeForm
    @validateCode="validateSecurityCode"
    @resendCode="generateSecurityCode"
    :canGoNextStep="this.canGoToNextStep"
    :securityCodeData="securityCodeData"
    :loading="loading"
    :logoPath="logoPath"
  >
    <template v-if="currentPath === '/login/security-code-password'">
      <SecurityCodePassForm :passCode="passCode" :redirectPath="redirectPath"></SecurityCodePassForm>
    </template>
    <template v-else>
      <SecurityCodeUserForm :userCode="userCode" @userCodeSuccess="validateSecurityCode"></SecurityCodeUserForm>
    </template>
  </SecurityCodeForm>
</template>

<script>
import SecurityCodeForm from '../Components/SecurityCodeForm/SecurityCodeForm'
import SecurityCodePassForm from '../Components/SecurityCodeForm/SecurityCodePassForm'
import SecurityCodeUserForm from '../Components/SecurityCodeForm/SecurityCodeUserForm'
import {mapGetters} from 'vuex'
export default {
  components: {
    SecurityCodeForm,
    SecurityCodePassForm,
    SecurityCodeUserForm
  },
  props: {
    logoPath: String,
  },
  data () {
    return {
      loading: false,
      redirectPath: 'new-password',
      currentPath: '',
      securityCodeData: {
        title: '',
        description: '',
        formTitle: '',
        inputs: []
      },
      passCode: {
        text: '',
        error: ''
      },
      userCode: {
        text: '',
        error: '',
        valid: false
      }
    }
  },
  mounted () {
    const routeMatched = this.$route.matched;
    this.currentPath = routeMatched[routeMatched.length - 1].path;

    if (this.currentPath === '/login/security-code-password') {

      if (!this['login/getRecoveryData'].partialPhone && !this['login/getRecoveryData'].partialMail)
        this.$router.replace('/login/recovery-password');

      this.securityCodeData.title = this.$t('modules.login.securityCode.password.title');
      this.securityCodeData.description = this.$t('modules.login.securityCode.password.description');
      this.securityCodeData.formTitle = this.$t('modules.login.securityCode.password.form.title');
      if (this['login/getRecoveryData'].byMail)
        this.securityCodeData.inputs.push({ icon: 'mdi-email', text: this['login/getRecoveryData'].partialMail });
      if (this['login/getRecoveryData'].bySms)
        this.securityCodeData.inputs.push({ icon: 'mdi-cellphone', text: this['login/getRecoveryData'].partialPhone });

    } else {

      if (!this['login/getRecoveryData'].partialPhone)
        this.$router.replace('/login/');

      this.securityCodeData.title = this.$t('modules.login.securityCode.user.title');
      this.securityCodeData.description = this.$t('modules.login.securityCode.user.description');
      this.securityCodeData.formTitle = this.$t('modules.login.securityCode.user.form.title');
      this.securityCodeData.inputs = [
        { icon: 'mdi-cellphone', text: this['login/getRecoveryData'].partialPhone }
      ]
    }
  },
  methods: {
    validateSecurityCode () {
      if (this.currentPath === '/login/security-code-password') {
        // This is not supposed to be use
      } else {
        this.$router.push('recovered-user')
      }
    },
    generateSecurityCode () {
      if (this.currentPath === '/login/security-code-password')
        this.$router.replace('/login/recovery-password');
      else
        this.$router.replace('/login/recovery-user')
    }
  },
  computed: {
    ...mapGetters(['login/getRecoveryData']),
    canGoToNextStep () {
      if (this.currentPath === '/login/security-code-user') return this.userCode.valid;
      else return false;
    }
  }
}
</script>

