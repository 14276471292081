
import SubjectsView from '../Views/SubjectsView'

const SubjectsRoute = [
    {
        path: '/',
        component: SubjectsView,
        meta: { name: 'Index', group: 'portalalumno.academic_charge', path: 'subjects', permission: 'READ' }
    },
];

export default SubjectsRoute