<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!updateDegreeStatus">
        <Breadcrumbs :title="$t('modules.degrees.update.breadcrumbs.title')" :description="$t('modules.degrees.update.breadcrumbs.description')"></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard :title="$t('modules.degrees.update.title')" class="mb-6">
                <v-row>
                  <v-col sm="3" class="py-2">
                    <span class="caption text-uppercase">{{ $t('modules.degrees.update.labels.name') }}</span>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.degrees.update.validation_errors.name') : ''"
                      :class="($v.description.$dirty && $v.description.$invalid) ? 'mb-0' : 'no-message mb-0'"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.degrees.update.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <span class="caption text-uppercase">{{ $t('modules.degrees.update.labels.female_name') }}</span>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      v-model="description_female"
                      @blur="$v.formValid.$touch()"
                      :label="$t('modules.degrees.update.labels.female_name')"
                      outlined
                      class="no-message mb-0"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <span class="caption text-uppercase">{{ $t('modules.degrees.update.labels.type') }}</span>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedDegreeType.$dirty && $v.selectedDegreeType.$invalid) ? $t('modules.degrees.update.validation_errors.type') : ''"
                      :class="($v.selectedDegreeType.$dirty && $v.selectedDegreeType.$invalid) ? 'mb-0' : 'no-message mb-0'"
                      :label="`${$t('modules.degrees.update.labels.type')} *`"
                      :items="degreeTypes"
                      item-text="meaning"
                      @blur="$v.selectedDegreeType.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedDegreeType.$model"
                    ></v-select>
                  </v-col>
                </v-row>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="cancel"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate || !$v.formValid.$anyDirty || $v.formValid.$anyError"
                  @end="resetButtonValues()"
                  @clicked="updateDegree"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { required } from 'vuelidate/lib/validators';
import { $types, $degrees } from '../Services';

export default {
  name: 'DegreesUpdate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage,
  },
  data () {
    return {
      updateDegreeStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.degrees.update.success_message.title'),
        actionPrimary: { text: this.$t('modules.degrees.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/degrees') } },
      },

      degreeTypes: [],

      // SELECTED DATA
      description: null,
      description_female: null,
      selectedDegreeType: null,
    }
  },
  validations: {
    description: { required },
    selectedDegreeType: { required },
    formValid: ['description', 'selectedDegreeType']
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.degrees.UPDATE
    },
  },
  methods: {
    cancel() {
      if (this.$v.formValid.$anyDirty) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('cancelMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => { this.$router.replace('/degrees'); }},
          actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.$router.replace('/degrees');
      }
    },
    async updateDegree () {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;
      const updatedDegree = {
        description: this.description,
        alternativeName: this.description_female,
        type: { value: this.selectedDegreeType.value },
      };

      try {
        await $degrees.update(this.$route.params.id, updatedDegree);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.degrees.update.success_message.title');
      } catch (error) { // eslint-disable-line
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.degrees.update.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.degrees.update.error_message.actions.primary_text'), callback: () => { this.updateDegreeStatus = false; } }
        this.successMessage.actionSecondary = { text: this.$t('modules.degrees.update.error_message.actions.secondary_text'), callback: () => { this.$router.push('/degrees') } }
      } finally {
        this.updateDegreeStatus = true;
        this.saveButton.loading = false;
        this.saveButton.error = true;
      }
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    async fetchData () {
      const allData = Promise.all([
        $types.find(null, null, { params: { type: 'DEGREES_TYPE' } }),
        $degrees.findById(this.$route.params.id),
      ]);

      const [degreesType, degrees] = await allData;
      this.degreeTypes = degreesType.data;
      this.description = degrees.data.description;
      this.description_female = degrees.data.alternativeName;
      this.selectedDegreeType = degrees.data.type;
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
