<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="py-8 px-10">
          <h3>{{reportInfo.name}}</h3><br>
          <p>{{ report.description}}</p>
          
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in report.detail"
                  :key="item.label"
                >
                  <td><strong> {{item.label}} </strong></td>
                  <td v-html="item.value"> </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <h3 class="mt-8">{{ $t(`modules.reports.table.drawer_info.example`) }}</h3>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="item in headers"
                  :key="item"
                  >
                    <h3>{{item}}</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, i) in datas" :key="i">
                  <td v-for="(item, id) in data" :key="id">{{ item }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card>
      </v-col>
      <v-col class="pb-8 d-flex justify-contentn-end justify-end">
        <Button
          white
          :text="$t('actions.close')"
          @clicked="$emit('closeDrawer');"
          class="mr-4"
        ></Button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
import info from '../ReportsInfo/index.js';

export default {
  name: 'ReportsInfo',
  components: {
    Button
  },
  props: {
    reportInfo: Object
  },
  data() {
    return {
      report:{},
      headers:[],
      datas:[]
    }
  },
  methods: {
    getInfo(){
      const service = Object.entries(info).find(([key]) => key === this.reportInfo.service);
      if (service) {
        const [,{ [this.reportInfo.url]: report }] = service;
        if (report) {
          this.report = report;
          this.headers = this.report.example.header;
          this.datas = this.report.example.data;
        }
      }
    }
  },
  mounted(){
    this.getInfo()
  }
};
</script>

<style scoped lang="sass">
  ::v-deep .country-chip
    align-items: center
    cursor: default
    display: inline-flex
    line-height: 20px
    max-width: 100%
    outline: none
    overflow: hidden
    padding: 0 12px
    position: relative
    text-decoration: none
    transition-duration: .28s
    transition-property: box-shadow,opacity
    transition-timing-function: cubic-bezier(.4,0,.2,1)
    vertical-align: middle
    white-space: nowrap
    border-radius: 16px
    font-size: 14px
    height: 32px
    color: #fff

  ::v-deep .chile
    background-color: #216DC2
    
  ::v-deep .argentina
    background-color: #62BEF6

  ::v-deep .peru
    background-color: #F44336

  ::v-deep .mexico
    background-color: #4CAF50
    
</style>
