<template>
        <v-container fluid class="pa-0 pb-8">
                <v-container class="container-custom" v-if="!updateInterestStatus">
                        <Breadcrumbs
                                :title="$t('modules.pricing.interest.update.breadcrumbs.title')"
                                :description="$t('modules.pricing.interest.update.breadcrumbs.subtitle')"
                        ></Breadcrumbs>
                        <v-row justify="center">
                                <v-col class="viewSpaces" sm="12">
                                        <OutlinedCard
                                        :title="$t('modules.pricing.interest.update.installmentsSection.title')"
                                        :subtitle="$t('modules.pricing.interest.update.installmentsSection.subtitle')"
                                        :switch-label="(status) ? $t('enabled') : $t('disabled')"
                                        :switch-value="status"
                                        @switchChange="(val) => {this.status = val}"
                                        switcher
                                        class="mb-6">
                                                <v-row>
                                                        <v-col sm="6">
                                                                <v-text-field
                                                                        ref="installments"
                                                                        :label="`${$t('modules.pricing.interest.update.installmentsSection.installments')} *`"
                                                                        outlined
                                                                        v-model="$v.installments.$model"
                                                                        @blur="$v.installments.$touch()"
                                                                        @keypress="$validateIntegerInputNumber($event, $v.installments.$model, 100, null, 1)"
                                                                        :error-messages="($v.installments.$dirty && $v.installments.$invalid)
                                                                        ?
                                                                        ((!$v.installments.numeric) ? $t('modules.pricing.interest.create.validations.numeric'):
                                                                        (!$v.installments.maxValue) ? $t('modules.pricing.interest.create.validations.maxInstallments'):
                                                                        $t('modules.pricing.interest.create.validations.installments.required')) 
                                                                        : ''"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="3">
                                                                <v-text-field
                                                                        ref="percentage"
                                                                        :label="`${$t('modules.pricing.interest.update.installmentsSection.percentage')} *`"
                                                                        append-icon="mdi-percent"
                                                                        outlined
                                                                        :error-messages="($v.percentage.$dirty && $v.percentage.$invalid)
                                                                        ?
                                                                        ((!$v.percentage.decimal) ? $t('modules.pricing.interest.create.validations.decimal') :
                                                                        (!$v.percentage.maxValue) ? $t('modules.pricing.interest.create.validations.maxValue') :
                                                                        $t('modules.pricing.interest.create.validations.percentageRequired')) : ''"
                                                                        @keypress="$validateDecimalInputNumber($event, $v.percentage.$model, 1000, null, 0)"
                                                                        @blur="$v.percentage.$touch()"
                                                                        v-model="$v.percentage.$model"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="3">
                                                                <v-text-field
                                                                        :loading="loadingCode"
                                                                        :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                                                                        ((!$v.code.required) ? $t('modules.pricing.interest.update.validations.codeRequired') :
                                                                        (!$v.code.alphaNum) ? $t('modules.pricing.interest.update.validations.codeAlphanumeric') :
                                                                        (!$v.code.maxLength) ? $t('modules.pricing.interest.update.validations.codeMaxLength') :
                                                                        $t('modules.pricing.interest.update.validations.codeUnique')) :
                                                                        ''"
                                                                        ref="code"
                                                                        :label="`${$t('modules.pricing.interest.update.installmentsSection.code')} *`"
                                                                        outlined
                                                                        @blur="validateCode"
                                                                        v-model="$v.code.$model"
                                                                        @keypress="$validateAlphanumeric($event, $v.code.$model,10)"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="3">
                                                                <v-text-field
                                                                        ref="tea"
                                                                        :label="`${$t('modules.pricing.interest.update.installmentsSection.tea')} *`"
                                                                        append-icon="mdi-percent"
                                                                        outlined
                                                                        @keypress="$validateDecimalInputNumber($event, $v.tea.$model, 1000, null, 0)"
                                                                        v-model="$v.tea.$model"
                                                                        :error-messages="($v.tea.$dirty && $v.tea.$invalid)
                                                                        ?
                                                                        ((!$v.tea.decimal) ? $t('modules.pricing.interest.update.validations.decimal') :
                                                                        (!$v.tea.maxValue) ? $t('modules.pricing.interest.update.validations.maxValue') :
                                                                        $t('modules.pricing.interest.update.validations.teaRequired')) : ''"
                                                                        @blur="$v.tea.$touch()"
                                                                        :min="0"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="3">
                                                                <v-text-field
                                                                        ref="cft"
                                                                        :label="`${$t('modules.pricing.interest.update.installmentsSection.cft')} *`"
                                                                        append-icon="mdi-percent"
                                                                        outlined
                                                                        @keypress="$validateDecimalInputNumber($event, $v.cft.$model, 1000, null, 0)"
                                                                        v-model="$v.cft.$model"
                                                                        :error-messages="($v.cft.$dirty && $v.cft.$invalid)
                                                                        ?
                                                                        ((!$v.cft.decimal) ? $t('modules.pricing.interest.update.validations.decimal') :
                                                                        (!$v.cft.maxValue) ? $t('modules.pricing.interest.update.validations.maxValue') :
                                                                        $t('modules.pricing.interest.update.validations.cftRequired')) : ''"
                                                                        @blur="$v.cft.$touch()"
                                                                        :min="0"
                                                                ></v-text-field>
                                                        </v-col>
                                                        <v-col sm="3" >
                                                                <v-menu
                                                                ref="dateFromMenu"
                                                                v-model="dateFromMenu"
                                                                :return-value.sync="from"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                                max-width="290px"
                                                                min-width="290px"
                                                                >
                                                                        <template v-slot:activator="{ on }">
                                                                                <v-text-field
                                                                                        :value="formatDateToCurrent(from)"
                                                                                        :label="$t('modules.pricing.interest.create.installmentsSection.startDate')"
                                                                                        persistent-hint
                                                                                        append-icon="mdi-calendar"
                                                                                        readonly
                                                                                        ref="from"
                                                                                        color="primary"
                                                                                        outlined
                                                                                        v-on="on"
                                                                                        :error-messages="($v.from.$dirty && !from) ? 
                                                                                        $t('modules.pricing.interest.create.validations.startDateRequired') : 
                                                                                        ($v.from.$error ? $t('modules.pricing.interest.create.validations.datePair') : '')"
                                                                                        @blur="$v.from.$touch()"
                                                                                ></v-text-field>
                                                                        </template>
                                                                        <v-date-picker
                                                                                v-model="from"
                                                                                @change="$refs.dateFromMenu.save(from)"
                                                                                color="primary"
                                                                                no-title
                                                                                :min="(()=>(new Date().toISOString().substr(0, 10)))()"
                                                                                >
                                                                        </v-date-picker>
                                                                </v-menu>
                                                        </v-col>
                                                        <v-col sm="3">
                                                                <v-menu
                                                                ref="dateToMenu"
                                                                :return-value.sync="to"
                                                                v-model="dateToMenu"
                                                                :close-on-content-click="false"
                                                                transition="scale-transition"
                                                                offset-y
                                                                max-width="290px"
                                                                min-width="290px">
                                                                        <template v-slot:activator="{ on }">
                                                                                <v-text-field
                                                                                        :value="formatDateToCurrent(to)"
                                                                                        :label="$t('modules.pricing.interest.create.installmentsSection.endDate')"
                                                                                        persistent-hint
                                                                                        append-icon="mdi-calendar"
                                                                                        readonly
                                                                                        ref="to"
                                                                                        color="primary"
                                                                                        outlined
                                                                                        v-on="on"
                                                                                        :error-messages="($v.to.$error ? $t('modules.pricing.interest.create.validations.datePair') : '')"
                                                                                        @blur="$v.to.$touch()"
                                                                                        >
                                                                                        <template v-slot:append>
                                                                                                <div class="mt-n2">
                                                                                                <v-icon v-if="$v.to.$model" @click="to = ''"> 
                                                                                                        mdi-close
                                                                                                </v-icon>
                                                                                                <v-icon v-else>
                                                                                                        mdi-calendar
                                                                                                </v-icon>
                                                                                                </div>
                                                                                        </template>
                                                                                </v-text-field>
                                                                        </template>
                                                                        <v-date-picker
                                                                                v-model="to"
                                                                                @change="$refs.dateToMenu.save(to)"
                                                                                color="primary"
                                                                                no-title
                                                                                :min="(()=>(from ? shiftDate(from, true) : shiftDate(new Date(), true)))()"
                                                                                >
                                                                        </v-date-picker>
                                                                </v-menu>
                                                        </v-col>
                                                        <v-col cols="6" sm="6" md="4">
                                                                <v-checkbox
                                                                        v-model="isBank"
                                                                        color="primary"
                                                                        :label="`${$t('modules.pricing.interest.create.installmentsSection.isBank')}`"
                                                                ></v-checkbox>
                                                        </v-col> 
                                                </v-row>
                                        </OutlinedCard>

                                        <OutlinedCard :title="$t('modules.pricing.interest.update.studentType')" class="mb-6">
                                                <v-checkbox
                                                        :key="index"
                                                        :label="studentType.meaning"
                                                        :value="studentType.value"
                                                        class="mr-8 mt-0 no-message"
                                                        color="primary"
                                                        ref="selectedStudentType"
                                                        v-for="(studentType, index) in studentTypes"
                                                        v-model="$v.selectedStudentType.$model"
                                                ></v-checkbox>
                                                <v-alert
                                                text
                                                type="error"
                                                v-if="$v.selectedStudentType.$anyError"
                                                >{{ $t('modules.pricing.interest.create.validations.studentType') }}
                                                </v-alert>
                                        </OutlinedCard>

                                        <OutlinedCard
                                                :title="$t('modules.pricing.interest.update.careersSection.title')"
                                                :subtitle="$t('modules.pricing.interest.update.careersSection.subtitle')"
                                                class="mb-6"
                                        >
                                                <TransferList
                                                :avatar="false"
                                                :loading="loadingSearchCareers"
                                                :addedItems.sync="$v.selectedCareers.$model"
                                                areEquals="id"
                                                ref="selectedCareers"
                                                :availableItems.sync="availableCareers"
                                                :firstListTitle="$t('modules.pricing.interest.update.transferList.firstTitle')"
                                                :secondListTitle="$t('modules.pricing.interest.update.transferList.firstTitle')"
                                                ></TransferList>
                                                <v-alert
                                                text
                                                type="error"
                                                class="mt-4"
                                                v-if="$v.selectedCareers.$dirty && $v.selectedCareers.$anyError"
                                                >{{ $t('modules.pricing.interest.update.validations.noSelectedCareers') }}
                                                </v-alert>
                                        </OutlinedCard>
                                        
                                        <div class="d-flex justify-end">
                                                <Button
                                                white
                                                :text="$t('actions.cancel')"
                                                @clicked="$router.replace('/pricing/interest')"
                                                @end="resetButtonValues"
                                                class="mr-4"
                                                ></Button>
                                                <Button
                                                :loading="updateButton.loading"
                                                :success="updateButton.success"
                                                :error="updateButton.error"
                                                :text="$t('actions.save')"
                                                :successText="$t('actions.created')"
                                                :errorText="$t('actions.error')"
                                                :disabled="!canUpdate"
                                                @clicked="updateInterest()"
                                                @end="resetButtonValues"
                                                ></Button>
                                        </div>
                                </v-col>
                        </v-row>
                </v-container>
                <SuccessMessage
                v-else
                :type="successMessage.type"
                :title="successMessage.title"
                :actionPrimary="successMessage.actionPrimary"
                :actionSecondary="successMessage.actionSecondary"
                />
        </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import TransferList from '@/components/TransferList/TransferList';
import {required, maxValue, maxLength, alphaNum, helpers, numeric, minValue} from 'vuelidate/lib/validators';
import {$careers, $types, $installments} from '../Services';
import moment from 'moment'

const datePairValid = (fieldNameStart, fieldNameEnd) => (value, vm) => !helpers.req(value && vm[fieldNameEnd] && vm[fieldNameStart]) || moment(vm[fieldNameStart]).isSameOrBefore(moment(vm[fieldNameEnd]))

export default {
        name: 'CreditCardInterestUpdate',
        components: {
                Breadcrumbs,
                Button,
                SuccessMessage,
                OutlinedCard,
                TransferList,
        },
        data() {
                return {
                        code: null,
                        tea: null,
                        cft: null,
                        percentage: null,
                        originalCode: null,
                        codeUnique: true,
                        studentTypes: [],
                        availableCareers: [],
                        selectedCareers: [],
                        loadingSearchCareers: false,
                        loadingCode: false,
                        selectedStudentType: null,
                        status: true,
                        isBank: false,
                        updateInterestStatus: false,
                        dateFromMenu: false,
                        dateToMenu: false,
                        updateButton: {
                                loading: false,
                                success: false,
                                error: false
                        },
                        successMessage: {
                                type: null,
                                title: null,
                                actionPrimary: null,
                                actionSecondary: null
                        },
                        installments: null,
                        to: null,
                        from: null,
                };
        },
        computed: {
                        canUpdate() {
                                return this.$permissions.portaladministrativo.prices.interest.UPDATE;
                        },
                },      
        async mounted() {
                this.getStudentType();
                await this.getCareers();
                this.fetchInstallments();
        },
        methods: {
                async getStudentType() {
                        try {
                        const fetchedStudentTypes = await $types.find(null, null, {params: {type: 'STUDENT_TYPE'}});
                        this.studentTypes = fetchedStudentTypes.data;
                        } catch (err) {
                        this.studentTypes = [];
                        throw err;
                        }
                },
                formatDateToCurrent (date) {
                        return date ? moment(date).format('YYYY-MM-DD') : null
                },
                async getCareers() {
                        this.loadingSearchCareers = true;
                        let careers = [];
                        try {
                                careers = await $careers.find(null, null, {
                                        params: {
                                                page: 0,
                                                length: 500,
                                                orderBy: 'id',
                                                orientation: 'desc',
                                        }
                                });
                                this.availableCareers = careers.data.content.map(item => {
                                        item.name = `${item.code} ${item.description}`;
                                        return item;
                                });
                        } catch (err) {
                                this.availableCareers = [];
                                throw err;
                        } finally {
                                this.loadingSearchCareers = false;
                        }
                },
                async fetchInstallments() {
                        const installment = await $installments.findById(this.$route.params.id);
                        this.code = installment.data.code;
                        this.installments = installment.data.installment;
                        this.originalCode = installment.data.code;
                        this.percentage = installment.data.percentage;
                        this.tea = installment.data.tea;
                        this.isBank = installment.data.isBank
                        this.cft = installment.data.cft;
                        this.selectedStudentType = installment.data.studentTypes.map(item => item.value);
                        this.from =  installment.data.from
                        this.to = installment.data.to
                        this.status = installment.data.status;
                        if(installment.data.careers){
                                this.selectedCareers = Object.values(installment.data.careers.reduce((r, item) => (r[`${item.id}`] = {
                                        code: item.code,
                                        description: item.description,
                                        id: item.id,
                                        name: `${item.code} ${item.description}`
                                }, r), {}));
                                const careerIds = installment.data.careers.map(item => item.id);
                                this.availableCareers = this.availableCareers.filter(career => !careerIds.includes(career.id));
                        }
                        
                },
                async updateInterest() {
                        this.updateButton.loading = true;
                        try {
                                if (this.$v.$invalid || !this.codeUnique) {
                                this.$v.$touch();
                                if(!this.codeUnique) {
                                this.$refs.code.$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                                }else {
                                Object.keys(this.$v).some(input => {
                                if (input.includes('$')) return false;
                                if (this.$v[input].$error) {
                                        this.$refs[input][0]
                                        ? this.$refs[input][0].$el.scrollIntoView({block: 'center', behavior: 'smooth'})
                                        : this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                                        return true
                                        }
                                })
                                }
                                                this.updateButton.error = true;
                                                this.updateButton.loading = false;
                                        } else {
                                        const newInstallment = {
                                                code: this.code,
                                                percentage: Number(this.percentage),
                                                status: this.status,
                                                isBank: this.isBank,
                                                installment: Number(this.installments),
                                                tea: Number(this.tea),
                                                cft: Number(this.cft),
                                                careers:  this.selectedCareers.map(item =>{
                                                        return {id: item.id};
                                                }),
                                                studentTypes:  this.selectedStudentType.map(item => {
                                                                return  {value: item};
                                                }),
                                                from: this.formatDateToCurrent(this.from),
                                                to: this.formatDateToCurrent(this.to)
                                        };
                                        await $installments.update(this.$route.params.id, newInstallment);
                                        this.successMessage.type = 'success';
                                        this.successMessage.title = this.$t('modules.pricing.interest.update.sucessMessage.title');
                                        this.successMessage.actionPrimary = {
                                                text: this.$t('actions.continue'), callback: () => {
                                                        this.$router.push('/pricing/interest');
                                                }
                                        };
                                        this.updateInterestStatus = true;
                                        this.updateButton.success = true;
                                        this.updateButton.loading = false;
                                        this.clearData();
                                }
                        }
                        catch (error) {
                                this.successMessage.type = 'error';
                                this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.pricing.interest.update.errorMessage.title');
                                this.successMessage.actionPrimary = {
                                text: this.$t('actions.return'), callback: () => {
                                this.updateInterestStatus = false;
                                }
                                };
                                this.successMessage.actionSecondary = null;
                                this.updateInterestStatus = true;
                                this.updateButton.loading = false;
                                this.updateButton.error = true;
                                throw error;
                        }
                },
                resetButtonValues() {
                        this.updateButton.loading = false;
                        this.updateButton.success = false;
                        this.updateButton.error = false;
                },
                clearData() {
                        this.resetButtonValues();
                        this.installments = null;
                        this.percentage = null;
                        this.code = null;
                        this.cft = null;
                        this.tea = null;
                        this.isBank = null;
                        this.status = true;
                        this.selectedCareers = [];
                        this.$v.$reset();
                },
                async validateCode() {
                this.$v.code.$touch();
                if (this.$v.code.$model) {
                        if (this.originalCode === this.code) {
                                this.codeUnique = true;
                                return;
                        }

                        this.codeUnique = null;
                        this.loadingCode = true;
                        try {
                                const response = await $installments.find('exists', null, {params: {code: this.$v.code.$model}});
                                this.codeUnique = !response.data.exists;
                                this.loadingCode = false;
                        } catch (error) {
                                this.codeUnique = null;
                                throw error;
                        } finally {
                                this.$v.code.$touch();
                        }
                }
                },
                shiftDate(date, add) {
                        return add ? moment(date).add(1, 'days').toISOString().substr(0, 10) :
                        moment(date).subtract(1, 'days').toISOString().substr(0, 10);
                },
        },
        validations: {
                installments: {
                        required,
                        numeric,
                        maxValue: maxValue(100),
                        minValue: minValue(1),
                },
                percentage: {
                        required,
                        decimal: helpers.regex('decimal', /^\d+(\.\d{1,2})?$/i),
                        maxValue: maxValue(1000),
                        },
                code: {
                        required,
                        alphaNum,
                        maxLength: maxLength(10),
                        unique() {
                                return this.codeUnique != false;
                        },
                },
                tea:{ 
                        required,
                        decimal: helpers.regex('decimal', /^\d+(\.\d{1,2})?$/i),
                        maxValue: maxValue(1000),
                },
                cft:{ 
                        required,
                        decimal: helpers.regex('decimal', /^\d+(\.\d{1,2})?$/i),
                        maxValue: maxValue(1000),
                },
                selectedStudentType: {required},
                selectedCareers: {required},
                from: {
                        required,
                        datePairValid: datePairValid('from', 'to')
                },
                to: {
                        datePairValid: datePairValid('from', 'to')
                },
            }    
};
</script>
