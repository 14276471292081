<template>
  <div>
    <div v-for="(item, index) in  $v.selectedBillingProducts.$each.$iter" :key="index">
      <OutlinedCard class="mb-6 pt-0">
         <v-card-title class="headline font-weight-bold ma-0 px-0 mb-4 pt-2">{{item.$model.name}}</v-card-title>
        <v-row>
          <v-col cols="4" class="px-0 py-0">
            <v-select
              outlined
              :label="$t('modules.scholarships.create.sectionData.items.typeDiscount')"
              :items="discountType"
              :error-messages="(item.$model.discountValue === '' && $t('modules.scholarships.create.validations.typeRequired')) || ''"
              item-text="name"
              :value="item.$model.discountValue ? item.$model.discountValue.charAt(0).toUpperCase() + item.$model.discountValue.slice(1) : ''"
              @change="($event)=>{item.$model.discountValue=$event; validateGroup($v.selectedBillingProducts.$model)}"
            ></v-select>
          </v-col>
          <v-col cols="7" v-if="!withAverage" class="mx-0 py-0">
            <v-row v-for="(item2, index2) in item.details.$each.$iter" :key="index2" align="center">
              <div class="align-center justify-items-center ml-6 mb-2">{{ $t('modules.scholarships.create.multilpleFormGroup.apply')}}</div>
              <v-col cols="5" class="py-0">
                <v-text-field
                  v-if="item.$model.discountValue.charAt(0).toUpperCase() + item.$model.discountValue.slice(1) === $t('modules.scholarships.create.discountType.percentege')"
                  :label="`${$t('modules.scholarships.create.sectionData.items.percent')} *`"
                  prepend-inner-icon="mdi-percent"
                  type="text"
                  min="0"
                  max="100"
                  @paste="onPaste"
                  @keypress="$validateDecimalInputNumber($event, item2.percentage.$model, 100)"
                  @blur="()=>{validateGroup($v.selectedBillingProducts.$model); item2.percentage.$touch()}"
                  :error-messages="(item2.percentage.$invalid && item2.percentage.$dirty && $t('modules.scholarships.create.validations.percentRequired')) || ''"
                  v-model.trim="item2.percentage.$model"
                  required
                  outlined
                ></v-text-field>
                <v-text-field v-else
                  :label="`${$t('modules.scholarships.create.sectionData.items.amount')} *`"
                  type="text"
                  min="1"
                  @paste="onPaste"
                  @keypress="$validateDecimalInputNumber($event, item2.amount.$model)"
                  @blur="()=>{validateGroup($v.selectedBillingProducts.$model);  item2.amount.$touch()}"
                  :error-messages="(item2.amount.$invalid && item2.amount.$dirty && $t('modules.scholarships.create.validations.amountRequired')) || ''"
                  v-model="item2.amount.$model"
                  required
                  outlined
                >
                  <template v-slot:prepend>
                    <span>{{ $countryConstants.currency }}</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row  v-if="withAverage">
          <div
            v-for="(item2, index2) in item.details.$each.$iter" :key="index2"
            :class="['multipleFormGroup__form-group', Number(index2) != selectedBillingProducts[index].details.length ? 'mb-6' : '']"
          >
            <div class="align-center justify-items-center">{{ $t('modules.scholarships.create.multilpleFormGroup.withAverage')}}</div>
            <v-col sm="3">
              <v-text-field
                :label="`${$t('modules.scholarships.create.sectionData.items.average')} *`"
                type="text"
                min="1"
                max="$countryConstants.maxAverage"
                @paste="onPaste"
                @blur="()=>{validateGroup($v.selectedBillingProducts.$model); item2.average.$touch()}"
                @keypress="$validateDecimalInputNumber($event, item2.average.$model, $countryConstants.maxAverage)"
                :error-messages="(item2.average.$invalid && item2.average.$dirty && $t('modules.scholarships.create.validations.averageRequired')) || (validateAverage(Number(item2.average.$model)) && $t('modules.scholarships.create.validations.averageInvalid')) || ''"
                v-model.trim="item2.average.$model"
                required
                outlined
              ></v-text-field>
            </v-col>
            <div class="align-center justify-items-center ml-6">{{ $t('modules.scholarships.create.multilpleFormGroup.apply')}}</div>
            <v-col
              sm="3"
              v-if="item.$model.discountValue.charAt(0).toUpperCase() + item.$model.discountValue.slice(1) === $t('modules.scholarships.create.discountType.percentege')"
            >
              <v-text-field
                :label="`${$t('modules.scholarships.create.sectionData.items.percent')} *`"
                prepend-inner-icon="mdi-percent"
                type="text"
                min="0"
                max="100"
                @paste="onPaste"
                @keypress="$validateDecimalInputNumber($event, item2.percentage.$model, 100)"
                @blur="()=>{validateGroup($v.selectedBillingProducts.$model); item2.percentage.$touch()}"
                :error-messages="(item2.percentage.$invalid && item2.percentage.$dirty && $t('modules.scholarships.create.validations.percentRequired')) || ''"
                v-model.trim="item2.percentage.$model"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col sm="3" v-else>
              <v-text-field
                :label="`${$t('modules.scholarships.create.sectionData.items.amount')} *`"
                prepend-inner-icon="mdi-currency-usd"
                type="text"
                min="1"
                @paste="onPaste"
                @keypress="$validateDecimalInputNumber($event, item2.amount.$model)"
                @blur="()=>{validateGroup($v.selectedBillingProducts.$model);  item2.amount.$touch()}"
                :error-messages="(item2.amount.$invalid && item2.amount.$dirty && $t('modules.scholarships.create.validations.amountRequired')) || ''"
                v-model="item2.amount.$model"
                required
                outlined
              ></v-text-field>
            </v-col>
            <div
              :class="['multipleFormGroup__form-group__actions', (Number(index2)+1 != selectedBillingProducts[index].details.length) ? 'simpleAction' : '']"
            >
              <div
                class="multipleFormGroup__form-group__actions__remove cursor-pointer"
                v-if="!(Number(index2) == 0 && Number(index2)+1 == selectedBillingProducts[index].details.length)"
                @click="() => removeGroup(item.$model.id, index, index2)"
              >-</div>
              <div
                @click="() => addGroup(item.$model.id, index, index2)"
                class="multipleFormGroup__form-group__actions__add cursor-pointer"
                v-if="(Number(index2)+1 == selectedBillingProducts[index].details.length)"
              >+</div>
            </div>
          </div>
        </v-row>
      </OutlinedCard>
      <!-- <v-divider class="my-4"></v-divider> -->
    </div>
  </div>
</template>


<script>
// import { isEqual, differenceWith } from "lodash";
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MultipleFormGroupCustom',
  components: {
    OutlinedCard
  },
  data() {
    return {
      errorMessages: '',
      error: true,
      indexError: null,
      index2Error: null,
      fieldError: '',
      arrayErrors: [],
      countErrors: 0,
    };
  },
  validations: {
    selectedBillingProducts: {
      $each: {
        details: {
          $each: {
            average: {
              required
            },
            amount: {
              required
            },
            percentage: {
              required
            }
          }
      }
      }
    }
  },
  props: {
    selectedBillingProducts: [Array, Object],
    discountType: [Array],
    withAverage: Boolean
  },
  watch: {
    selectedBillingProducts (newValue) {
      if(newValue.length > 0){
        newValue.forEach((element) => {
          this.validateFormat(element)
      })
      if(this.countErrors > 0){
          this.error = true
        } else {
          this.error = false
        }
        this.$emit('errorMultipleForm', this.error);
        this.countErrors = 0
      }
    },
    withAverage(value) {
      for (let i = 0; i < this.selectedBillingProducts.length; i++) {
        if(!value) {
          const { 0: item } = this.selectedBillingProducts[i].details;
          item.average = null;
          this.selectedBillingProducts[i].details = [item];
        }
        if(this.$v.selectedBillingProducts[i])
          this.$v.selectedBillingProducts[i].average.required = value;

      }
    }
  },
  methods: {
    removeGroup(id, index, index2) {
      this.selectedBillingProducts[index].details.splice(index2, 1);
      this.validateFormat(this.selectedBillingProducts[index])
      if(this.countErrors > 0){
        this.error = true
      } else {
        this.error = false
      }
      this.$emit('errorMultipleForm', this.error);
      this.countErrors = 0
    },
    addGroup(id, index) {
      if (!(index === this.indexError && this.error)) {
        const mockup = {
          billingProduct: {
            id
          },
          error:true,
          average: null,
          percentage: null,
          amount: null
        };
        this.selectedBillingProducts[index].details.push({
          ...mockup
        });

      }
      this.validateFormat(this.selectedBillingProducts[index])
      if(this.countErrors > 0){
        this.error = true
      } else {
        this.error = false
      }
      this.$emit('errorMultipleForm', this.error);
      this.countErrors = 0
    },
    onPaste() {
      event.preventDefault();
    },
    validateAverage(value) {
      if(value > this.$countryConstants.maxAverage) {
        this.error = true;
        this.$emit('errorMultipleForm', this.error);
        return true
      } else {
        return false
      }
    },
    validateGroup(value){
      if(value.length > 0){
        value.forEach((element) => {
          this.validateFormat(element)
        })
      }
      if(this.countErrors > 0){
        this.error = true
      } else {
        this.error = false
      }
      this.$emit('errorMultipleForm', this.error);
      this.countErrors = 0
    },
    validateFormat(value){
      for (let i = 0; i < value.details.length; i++) {
        let error = false
        if(value.discountValue === this.$t('modules.scholarships.create.discountType.amount') || value.discountValue === this.$t('modules.scholarships.create.discountType.amount_lowercase')) {
          if(value.details[i].amount === null || value.details[i].amount === '') {
            error = true
          }
        } else  if(value.discountValue === this.$t('modules.scholarships.create.discountType.percentege') || value.discountValue === this.$t('modules.scholarships.create.discountType.percentege_lowercase')) {
          if(value.details[i].percentage === null || value.details[i].percentage === '') {
            error = true
          }
        }
        if(this.withAverage && (value.details[i].average === null || value.details[i].average === '')) {
            error = true
        }
        value.details[i].error = error
      }
      value.details.forEach(obj => {
          if(obj.error) {
            this.countErrors++
          }
      })
    },
  }
};
</script>

<style scoped lang="sass">
    .multipleFormGroup
      &__form-group
          display: flex
          position: relative
          background-color: #F6F6F6
          border-radius: 5px
          border: 1px solid #CECECE
          line-height: 70px
          height: 110px
          padding: 1rem
          width: calc(100% - 56px)
          &__content
              width: calc(100% - 16px)
          &__actions
              position: absolute
              right: -20px
              top: 50%
              transform: translate(50%, -50%)
              display: flex
              &.simpleAction
                  right: 0
              &__remove, &__add
                  height: 32px
                  width: 32px
                  border-radius: 50%
                  border: 1px solid var(--v-primary-base)
                  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16)
                  font-size: 24px
                  color: var(--v-primary-base)
                  background-color: #FFFFFF
                  display: flex
                  justify-content: center
                  align-items: center
                  transition: all 300ms ease
                  &:hover, &:active
                      background-color: var(--v-primary-base)
                      color: #FFFFFF
                      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3)
              &__add
                  margin-left: 0.5rem
</style>
