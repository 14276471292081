<template>
    <div class="message-container fullHeight px-8 py-10">
        <OutlinedCard v-if="message" remove-border>
            <v-row class="d-flex flex-column-reverse flex-sm-row">
                <v-col cols="12" sm="6" class="d-flex flex-column justify-center">
                    <h4 class="title">{{ message.title }}</h4>
                    <h4 class="body-2">{{ message.subtitle }}</h4>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-end align-center">
                    <p class="caption grey--text text-right">{{ message.date }}</p>
                </v-col>
            </v-row>
            <v-row class="mb-4">
                <v-col cols="12" v-html="message.message"></v-col>
                <v-col cols="12" sm="6" class="d-flex justify-start align-center">
                    <v-avatar color="grey lighten-2" :size="!$isMobile ? 50 : 40">
                        <v-img :src="`data:image/jpeg;base64,${message.avatar}`"></v-img>
                    </v-avatar>
                    <div class="ms-4">
                        <p class="font-weight-medium mb-0">{{ message.name }}</p>
                        <p class="body-2 mb-0" v-if="message.place">{{ message.place }}</p>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-start justify-sm-center align-center">
                    <img width="auto" height="32" :src="logoPath" />
                </v-col>
            </v-row>
        </OutlinedCard>
        <div v-else class="fullHeight d-flex flex-column align-center justify-center">
            <img width="300" height="200" :src="require('@/assets/seleccionamensaje.svg')" />
            <p class="text-center text-body-1 mt-4" v-html="$t('modules.portalalumno.communications.no_message_selected')"></p>
        </div>
    </div>
</template>

<script>
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import { mapGetters } from 'vuex'
    import { $studentmessage } from '../Services';
    import moment from 'moment';

    export default {
        name: 'Detail',
        props: {
            messageSelected: { type: Object, default: null }
        },
        components: {
            OutlinedCard,
        },
        data() {
            return {
                message: null,
            }
        },
        computed: {
            formatDate(){
                return moment(this.messageSelected.createdDate).locale('es').format('dddd, hh:mm') 
            },
            ...mapGetters({
                studentId: 'commons/getStudentId',
                logoPath: 'commons/getLogoImageUrl',
            }),
            messageId() {
                return this.messageSelected ? this.messageSelected.id : null
            }
        },
        methods: {
            async getMessageDetail() {
                if (!this.messageId) return false
                try {
                    const { data } = await $studentmessage.findById(this.messageId, null, {params: { studentId: this.studentId, isAdmin: this.$isGestor }} )
                    data.date = moment(data.createdDate).locale('es').format('dddd D [de] MMMM, HH:mm[hs]')
                    this.message = data
                } catch (error) {
                    this.message = null
                    throw error
                }
            },
            isMobileMessage() {
                   this.message = this.messageSelected
                    this.getMessageDetail()
            }
        },
        created() {
            if(this.$isMobile) this.isMobileMessage()
        },
        watch: {
            messageId(id) {
                if (id) this.getMessageDetail()
            },
        }
    }
</script>

<style lang="sass" scoped>
    .message-container
        width: 100%
        overflow-y: auto

    .fullHeight
        height: 100%
</style>