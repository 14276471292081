<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewDegreeStatus">
        <template v-if="!insideDrawer">
          <Breadcrumbs
            :title="$t('modules.degrees.create.breadcrumbs.title')"
            :description="$t('modules.degrees.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </template>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlineCard :title="$t('modules.degrees.create.title')" class="mb-6">
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.degrees.create.validation_errors.name') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                      :label="`${$t('modules.degrees.create.labels.name')} *`"
                      outlined
                      :class="($v.description.$dirty && $v.description.$invalid) ? '' : 'no-message'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-switch
                      class="mt-0"
                      v-model="status_female"
                      :label="$t('modules.degrees.create.labels.female_switch')"
                      color="primary"
                      @change="(!status_female) ? description_female = '' : null"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      :error-messages="($v.description_female.$dirty && $v.description_female.$invalid) ? $t('modules.degrees.create.validation_errors.female_name') : ''"
                      :class="($v.description_female.$dirty && $v.description_female.$invalid) ? '' : 'no-message'"
                      @blur="$v.description_female.$touch()"
                      v-model="$v.description_female.$model"
                      :label="$t('modules.degrees.create.labels.female_name')"
                      outlined
                      :disabled="!status_female"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <v-select
                      :error-messages="($v.selectedDegreeType.$dirty && $v.selectedDegreeType.$invalid) ? $t('modules.degrees.create.validation_errors.type') : ''"
                      :class="($v.selectedDegreeType.$dirty && $v.selectedDegreeType.$invalid) ? '' : 'no-message'"
                      :label="`${$t('modules.degrees.create.labels.type')} *`"
                      :items="degreeTypes"
                      item-text="meaning"
                      @blur="$v.selectedDegreeType.$touch()"
                      outlined
                      return-object
                      v-model="$v.selectedDegreeType.$model"
                    ></v-select>
                  </v-col>
                </v-row>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="cancel"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate || !$v.formValid.$anyDirty || $v.formValid.$anyError"
                  @end="resetButtonValues()"
                  @clicked="createDegree"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { $types, $degrees } from '../Services';


export default {
  name: 'DegreesCreate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage,
  },
  props: {
    insideDrawer: { type: Boolean, default: false }
  },
  data () {
    return {
      createNewDegreeStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      tmpDrawer: {
        open: false,
        title: 'Título',
        description: 'Descripción'
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.degrees.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.degrees.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/degrees') } },
        actionSecondary: { text: this.$t('modules.degrees.create.success_message.actions.secondary_text'), callback: () => { this.createNewDegreeStatus = false } }
      },

      status_female: false,
      degreeTypes: [],

      // SELECTED DATA
      description: null,
      description_female: null,
      selectedDegreeType: null,
    }
  },
  validations: {
    description: { required },
    selectedDegreeType: { required },
    description_female: {
      required: requiredIf(function () {
        return this.status_female
      })
    },
    formValid: ['description', 'description_female', 'selectedDegreeType']
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.degrees.CREATE
    },
  },
  methods: {
    cancel() {
      if (this.$v.formValid.$anyDirty) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('cancelMessage') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => { this.$router.replace('/degrees'); }},
          actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.$router.replace('/degrees');
      }
    },
    tmpDrawerOpen () {
      this.tmpDrawer.open = !this.tmpDrawer.open;
    },
    async createDegree () {
      if (!this.canCreate) return false
      this.createButton.loading = true;
      const newDegree = {
        description: this.description,
        alternativeName: this.description_female,
        type: { value: this.selectedDegreeType.value },
      };

      try {
        await $degrees.create(newDegree);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.degrees.create.success_message.title');
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.degrees.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/degrees') } };
          this.successMessage.actionSecondary = { text: this.$t('modules.degrees.create.success_message.actions.secondary_text'), callback: () => { this.createNewDegreeStatus = false } };
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.degrees.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
          this.successMessage.actionSecondary = null;
        }

        this.clearData();
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.degrees.create.error_message.title');
        this.successMessage.actionSecondary = null;
        if (!this.insideDrawer) {
          this.successMessage.actionPrimary = { text: this.$t('modules.degrees.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/degrees') } }
        } else {
          this.successMessage.actionPrimary = { text: this.$t('modules.degrees.create.success_message.actions.primary_text'), callback: () => { this.$emit('closeDrawer'); } }
        }
      } finally {
        this.createNewDegreeStatus = true;
        this.createButton.loading = false;
        this.createButton.success = true;
      }
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.status_female = false;
      this.description = null;
      this.description_female = null;
      this.selectedDegreeType = null;
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    async fetchData () {
      const degreesType = await $types.find(null, null, { params: { type: 'DEGREES_TYPE' } });
      this.degreeTypes = degreesType.data;
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
