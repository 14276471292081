/* eslint-disable no-console */
<template>
    <v-container fluid class="d-flex pa-0 pb-8 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom" v-if="!createdNewSanction">
            <v-row>
                <v-col>
                    <Breadcrumbs
                        :title="$t('modules.academic.sanctions.create.breadcrumbs.title')"
                        :description="$t('modules.academic.sanctions.create.breadcrumbs.description')"
                    ></Breadcrumbs>
                </v-col>
            </v-row>
            <v-row justify="center"  >
                    <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
                        <OutlinedCard
                            :title="$t('modules.academic.sanctions.create.title')"
                            :subtitle="$t('modules.academic.sanctions.create.subtitle')"
                            :switch-label="(status) ? $t('enabled') : $t('disabled')"
                            :switch-value="status"
                            @switchChange="(val) => {this.status = val;}"
                            switcher
                            class="mb-6"
                        >
                        <v-row>
                            <v-col sm="6">
                                <v-text-field
                                :label="`${$t('modules.academic.sanctions.create.labels.title')} *`"
                                outlined
                                ref="name"
                                v-model="$v.name.$model"
                                @blur="$v.name.$touch()"
                                :error-messages="($v.name.$dirty && $v.name.$invalid) ? !$v.name.required ? $t('modules.academic.sanctions.create.errors_validations.name.required') : !$v.name.maxLength ?$t('modules.academic.sanctions.create.errors_validations.name.maxLength') :!$v.name.minLength ? $t('modules.academic.sanctions.create.errors_validations.name.minLength') : !$v.name.alphaNumWithSpaces ? $t('modules.academic.sanctions.create.errors_validations.name.alphaNumWithSapces'): null : ''"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="3">
                                    <v-text-field
                                    v-model="$v.code.$model"
                                    @blur="validateCode"
                                    ref="code"
                                    :label="`${$t('modules.academic.sanctions.create.labels.code')} *`"
                                    outlined
                                    :loading="loadingCode"
                                    :error-messages="($v.code.$dirty && $v.code.$invalid) ? !$v.code.required ? $t('modules.academic.sanctions.create.errors_validations.code.required') : !$v.code.maxLength ? $t('modules.academic.sanctions.create.errors_validations.code.maxLength') :!$v.code.minLength ? $t('modules.academic.sanctions.create.errors_validations.code.minLength') : !$v.code.alphaNum ? $t('modules.academic.sanctions.create.errors_validations.code.alphaNum'): !$v.code.unique ? $t('modules.academic.sanctions.create.errors_validations.code.unique') : null : ''"
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8">
                                <v-textarea
                                    v-model="$v.description.$model"
                                    @blur="$v.description.$touch()"
                                    outlined
                                    ref="description"
                                    no-resize
                                    :label="`${$t('modules.academic.sanctions.create.labels.description')} *`"
                                    :error-messages="($v.description.$dirty && $v.description.$invalid) ? !$v.description.required ? 'es requerido ' : !$v.description.maxLength ?'Muy largo' :!$v.description.minLength ? 'muy corto' : !$v.description.alphaNumWithSpaces ? 'Caracteres no permitidos': null : ''"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="4">
                                <v-autocomplete
                                        v-model="$v.typeSanction.$model"
                                        @blur="$v.typeSanction.$touch()"
                                        :label="`${$t('modules.academic.sanctions.create.labels.type')} *`"
                                        :items="sanctionTypes"
                                        outlined
                                        ref="typeSanction"
                                        item-text="meaning"
                                        item-value="id"
                                        return-object
                                        :error-messages="($v.typeSanction.$dirty && $v.typeSanction.$invalid) ? !$v.typeSanction.required ? $t('modules.academic.sanctions.create.errors_validations.typeSanction.required') : null : ''"
                                    >
                                    </v-autocomplete>
                            </v-col>
                            <v-col sm="4">
                                <v-select
                                    :label="`${$t('modules.academic.sanctions.create.labels.level')} *`"
                                    :items="typesLevels"
                                    item-text="meaning"
                                    v-model="$v.level.$model"
                                    @blur="$v.level.$touch()"
                                    ref="level"
                                    outlined
                                    return-object
                                    :error-messages="($v.level.$dirty && $v.level.$invalid) ? !$v.level.required ? $t('modules.academic.sanctions.create.errors_validations.level.required') : null : ''"

                                ></v-select>


                            </v-col>
                    </v-row>

                    <v-row>
                        <v-col sm="3" class="ml-4">
                            <v-row>
                                <v-switch
                                    class="my-2"
                                    :label="$t('modules.academic.sanctions.create.labels.switch.discharge')"
                                    color="primary"
                                    v-model="discharge"
                                ></v-switch>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="4" >
                            <h3>Vigencia</h3>
                            <v-row>
                                <v-col cols="12" sm="12">
                                    <v-radio-group v-model="isPermanent">
                                        <v-row>
                                            <v-col cols="4" >
                                                <v-radio
                                                class="mt-2"
                                                label="Dias"
                                                :value="false"
                                                ></v-radio>
                                            </v-col>
                                            <v-col cols="5">
                                                <v-text-field
                                                    @blur="$v.duration.$touch()"
                                                    :disabled="isPermanent"
                                                    v-model="$v.duration.$model"
                                                    type="number"
                                                    ref="duration"
                                                    outlined
                                                    min="0"
                                                    max="670"
                                                     @keypress="$validateIntegerInputNumber($event, $v.duration.$model, null, 5)"
                                                    :error-messages="($v.duration.$dirty && $v.duration.$invalid) ? !$v.duration.required ? $t('modules.academic.sanctions.create.errors_validations.duration.required') : !$v.duration.between ? $t('modules.academic.sanctions.create.errors_validations.duration.between') : null : '' "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-radio
                                            :label="$t('modules.academic.sanctions.create.labels.switch.isPermanent')"
                                            :value="true"
                                        ></v-radio>
                                        </v-radio-group>

                                </v-col>
                            </v-row>

                        </v-col>

                    </v-row>


                    <v-divider class="mt-6 mb-8"></v-divider>
                </OutlinedCard>
                <OutlinedCard
                    :title="$t('modules.academic.sanctions.create.sectionModules.title')"
                    :subtitle="$t('modules.academic.sanctions.create.sectionModules.subtitle')"
                    class="mb-6"
                >
                    <TransferList
                        :avatar="false"
                        :firstListTitle="$t('modules.academic.sanctions.create.sectionModules.transferList.firstTitle')"
                        :secondListTitle="$t('modules.academic.sanctions.create.sectionModules.transferList.secondTitle')"
                        :availableItems.sync="listStudentModules"
                        areEquals="id"
                        :loading="loadingStudentModules"
                        :added-items.sync="sanctionsAdded"
                    ></TransferList>
                </OutlinedCard>
                <div class="d-flex justify-end mb-4">
                    <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="$router.replace('/academic/sanctions')"
                    class="mr-4"
                    ></Button>

                    <Button
                        :disabled="!canCreate"
                        :loading="createButton.loading"
                        :success="createButton.success"
                        :error="createButton.error"
                        :text="$t('actions.create')"
                        :successText="$t('actions.created')"
                        :errorText="'Error'"
                        @clicked="createSanctionPopUp"
                    ></Button>
                </div>
                </v-col>
            </v-row>
        </v-container>
            <SuccessMessage
                v-else
                :type="successMessage.type"
                :title="successMessage.title"
                :actionPrimary="successMessage.actionPrimary"
                :actionSecondary="successMessage.actionSecondary"
                />
    </v-container>
</v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList.vue';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import Button from '@/components/Button/Button';
import { $sanction, $types } from '../Services';
import { mapGetters } from 'vuex';
import { required, maxLength, minLength, helpers, alphaNum, between } from 'vuelidate/lib/validators';

    export default {
        name: 'SanctionsCreate',
        components:{
            Breadcrumbs,
            OutlinedCard,
            TransferList,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                createdNewSanction: false,
                status: true,
                successMessage: {
                    type: 'success',
                    title: this.$t('modules.academic.sanctions.create.success_message.title'),
                    actionPrimary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/sanctions') } },
                    actionSecondary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.secondary_text'), callback: () => { this.createdNewSanction = false } }
                },
                 sanctionsAdded:[],
                 createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                listStudentModules: [],
                loadingStudentModules: false,
                sanctionTypes:[],
                typesLevels:[],
                typeExam:[],
                name:'',
                description: '',
                code:'',
                typeSanction : '',
                level : '',
                duration: '',
                isPermanent: false,
                discharge:false,
                codeUnique: null,
                loadingCode: false,
            }
        },
        mounted() {
            this.getStudentModules();
            this.getTypesSantions();
            this.getLevelsSanctions();
        },
        computed:{
            canCreate () {
                return this.$permissions.portaladministrativo.academic.sanctions.CREATE
            },
            ...mapGetters({
                userId: 'commons/getUserID',
            }),
        },
        methods: {
             async getStudentModules (){
                 this.loadingStudentModules= true
                try {
                const response = await $sanction.find('studentModules', null, null)
                   this.listStudentModules = response.data
                } catch (error) {
                    this.listStudentModules = [];

                } finally {
                     this.loadingStudentModules= false
                }
            },
            createSanctionPopUp () {
                if (!this.status) {
                    this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('alert'),
                    content: [{ value: this.$t('disabledMessage') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createSanction() },
                    actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                    icon: { color: 'warning', name: 'mdi-alert' },
                    color: 'primary',
                    });
                } else {
                     this.createSanction();
                }
            },
           async createSanction(){
            if (!this.canCreate) return false
                if (this.$v.$invalid) {
                    this.$v.$touch();
                    Object.keys(this.$v).some(input => {
                        if (input.includes('$')) return false;
                        if (this.$v[input].$error) {
                            this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                            return true
                        }
                        })
                    this.createButton.error = true;
                    this.createButton.loading = false;
                }else{
                        this.createButton.loading = true;
                        const newSanction = {
                                discharge:this.discharge,
                                code:  this.code,
                                title: this.name,
                                description: this.description,
                                status : this.status,
                                daysDuration:!this.isPermanent ? this.duration : null,
                                sanctionType: {
                                    value : this.typeSanction.value
                                },
                                sanctionLevelType:{
                                    value: this.level.value
                                },
                                moduleList: this.sanctionsAdded.map(el => ({'id': el.id })),
                                userLogged: this.userId
                            }
                        try {
                            await $sanction.create(newSanction, {}, null);
                            this.successMessage.type = 'success';
                            this.successMessage.title = this.$t('modules.academic.sanctions.create.success_message.title');
                            this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/academic/sanctions') } };
                            this.successMessage.actionSecondary = null;
                            this.createdNewSanction = true;
                            this.createButton.success = true;
                        } catch(error) {
                            this.successMessage.type = 'error';
                            this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.sanctions.create.error_message.title');
                            this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createdNewSanction = false; }}
                            this.successMessage.actionSecondary = null;
                            this.createButton.loading = true;
                            this.createButton.error = true;
                        }
                }

            },
            clearData() {
                this.resetButtonValues();
                this.name = null;
                this.code = null,
                this.description = true,
                this.duration = null,
                this.level = null,
                this.typeSanction = null,
                this.sanctionsAdded = [],
                this.sanctionTypes = []
                this.codeUnique = null;
                this.loadingCode = false;
                // this.$v.$reset();
            },
            resetButtonValues() {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            async getTypesSantions(){
                try {
                    const response = await $types.find(null, null, { params: { type: 'SANCTION_TYPE' } });
                    this.sanctionTypes = response.data

                } catch (error) {
                    this.sanctionType = [];
                }
            },
            async getLevelsSanctions(){
                try {
                   const response = await $types.find(null, null, { params: { type: 'SANCTION_LEVEL_TYPE' } });
                    this.typesLevels = response.data.sort((itemA, itemB) => itemA.meaning.split(' ')[1] - itemB.meaning.split(' ')[1])

                } catch (error) {
                  this.typesLevels = [];
                }
            },
             async validateCode () {
                this.$v.code.$touch();
                if (this.$v.code.$model) {
                    this.codeUnique = null;
                    this.loadingCode = true;
                    try {
                    const response = await $sanction.find('exists', null, { params: { code: this.$v.code.$model } });
                    this.codeUnique = !response.data.exists;
                    this.loadingCode = false;
                    } catch (error) {
                    this.codeUnique = null;
                    throw error
                    } finally {
                    this.$v.code.$touch();
                    }
                }
                },
        },
        validations:{
            name: {
                required,
                alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
                maxLength: maxLength(50),
                minLength: minLength(4)
                },
            code: {
                required,
                alphaNum,
                maxLength: maxLength(10),
                minLength: minLength(3),
                unique () {
                    return this.codeUnique !== false;
                },
            },
             description: {
                 required,
                 alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
                 maxLength: maxLength(70),
                 minLength: minLength(10)
             },
            typeSanction: {
                required,
            },
            level: {
                required,
            },
            duration: {
                between: between(1,670)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
