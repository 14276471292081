    <template>
      <v-container>
        <v-row>
          <template v-for="(data, i) in myData">
            <v-col cols="4" :key=i>
              <!--                <v-card-->
              <!--                    class="mx-auto pa-4"-->
              <!--                    width= 100%-->
              <!--                    outlined-->
              <!--                    :to="path: 'applications/application'}"-->
              <!--                >-->
              <v-card class="mx-auto pa-4" width=100% outlined>
                <v-row no-gutters>
                  <v-col cols="8">
                    <div class="title primary--text  font-weight-regular">{{ data.name }}</div>
                    <div class="subtitle-2 primary--text text--darken-4 font-weight-regular mt-2">{{ data.modulos }}</div>
                    <div class="body-2 mt-4">{{ data.texto }}</div>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-column justify-space-between align-content-end">
                    <v-avatar size="80" class="ml-auto mb-4" dark color="grey lighten-3">
                      <v-icon>{{data.imagen}}</v-icon>
                    </v-avatar>
                    <!--                            <v-avatar size="80" class="ml-auto">-->
                    <!--                                <v-img-->
                    <!--                                    :src="data.image"-->
                    <!--                                    alt="Avatar"-->
                    <!--                                ></v-img>-->
                    <!--                            </v-avatar>-->
                    <v-chip class="ml-auto px-3 success--text" small color="success lighten-5">
                      <v-avatar left>
                        <v-icon size="14">mdi-check</v-icon>
                      </v-avatar>
                      {{data.estado ? 'Activo' : 'Inactivo'}}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-container>
</template>

<script>
export default {
  name: 'ApplicationsGrid',
  props: {
    myData: Array,
  }
}
</script>
