<template>
	<section>
		<h3>{{ $t('modules.academic.professionalizingPractice.update.steppers.manage.requirements') }}</h3>
		<v-row class="d-flex align-center">
			<v-col class="d-flex justify-start align-center">
				<v-checkbox
					class="mr-12"
					v-model="dataPP.empresaCumpleRequisitos"
					label="Si"
					:readonly="disableCheckboxEmpresaCumpleRequisitos"
					@change="updateCompanyRequirements(true)"
				/>
				<v-checkbox
					v-model="invertedEmpresaCumpleRequisitos"
					label="No"
					:readonly="disableCheckboxEmpresaCumpleRequisitosInverted"
					@change="disableCheckboxes()"
				/>
			</v-col>
			<v-col class="d-flex justify-end">
				<Button
					v-if="!dataPP.empresaCumpleRequisitos"
					:text="$t('modules.academic.professionalizingPractice.update.steppers.manage.search')"
					class="mr-3"
					primary
					:disabled="!(datosCompany.validacion || !dataPP.automaticSend) || loading"
					@clicked="updatePP(267)"
				/>
			</v-col>
		</v-row>
		<h3>{{ $t('modules.academic.professionalizingPractice.update.steppers.manage.company_process') }}</h3>
		<article class="d-flex justify-space-start align-center py-2">
			<v-checkbox
				v-model="dataPP.empresaContinuaProceso"
				label="Si"
				:readonly="disableCheckboxEmpresaContinuaProceso"
				@change="updateCompanyContinuity(true)"
				class="mr-12"
			/>
			<v-checkbox
				v-model="invertedEmpresaContinuaProceso"
				label="No"
				:readonly="disableCheckboxEmpresaContinuaProcesoInverted"
				@change="
					updateCompanyContinuity(false);
					dataPP.empresaContinuaProceso = !dataPP.empresaContinuaProceso;
				"
			/>
		</article>
	</section>
</template>

<script>
import Button from '@/components/Button/Button';
import { $updateCheckEmpresaCumpleRequisitos, $updateCheckEmpresaContinuaProceso } from '../../Services';

export default {
	name: 'CheckRequirementsContinue',
	props: ['dataPP', 'step', 'datosCompany', 'updatePP', 'loading'],
	components: {
		Button,
	},
	methods: {
		async updateCompanyRequirements(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckEmpresaCumpleRequisitos.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value },
				});
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
		async updateCompanyContinuity(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckEmpresaContinuaProceso.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value },
				});
				value ? (this.datosCompany.estadoPPId = 279) : (this.datosCompany.estadoPPId = 268);
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
		disableCheckboxes() {
			this.updateCompanyRequirements(false);
			this.dataPP.empresaCumpleRequisitos = false;
			this.updateCompanyContinuity(false);
			this.dataPP.empresaContinuaProceso = false;
		},
	},
	computed: {
		invertedEmpresaCumpleRequisitos: {
			get() {
				return !this.dataPP.empresaCumpleRequisitos;
			},
			set(value) {
				return value;
			},
		},
		invertedEmpresaContinuaProceso: {
			get() {
					return !this.dataPP.empresaContinuaProceso;
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxEmpresaCumpleRequisitos: {
			get() {
				return this.dataPP.empresaCumpleRequisitos 
				|| (this.datosCompany.estadoPPId != 268 && this.datosCompany.estadoPPId != 279) 
				|| this.loading 
				|| !(this.datosCompany.validacion || !this.dataPP.automaticSend)
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxEmpresaCumpleRequisitosInverted: {
			get() {
				return (
					!this.dataPP.empresaCumpleRequisitos 
					|| (this.datosCompany.estadoPPId != 268 && this.datosCompany.estadoPPId != 279) 
					|| this.loading 
					|| !(this.datosCompany.validacion || !this.dataPP.automaticSend)
				);
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxEmpresaContinuaProceso: {
			get() {
				return !this.dataPP.empresaCumpleRequisitos 
					|| this.dataPP.empresaContinuaProceso 
					|| (this.datosCompany.estadoPPId != 268 && this.datosCompany.estadoPPId != 279) 
					|| this.loading 
					|| !(this.datosCompany.validacion || !this.dataPP.automaticSend)
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxEmpresaContinuaProcesoInverted: {
			get() {
				return !this.dataPP.empresaCumpleRequisitos 
				|| !this.dataPP.empresaContinuaProceso 
				|| (this.datosCompany.estadoPPId != 268 && this.datosCompany.estadoPPId != 279) 
				|| this.loading 
				|| !(this.datosCompany.validacion || !this.dataPP.automaticSend)
			},
			set(value) {
				return value;
			},
		},
	},
};
</script>
