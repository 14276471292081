
const productsTranslations = {
    es: {
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        inputErrors: {
            required: 'Campo obligatorio',
            minLengthOne: 'Selecciona al menos uno',
            alreadyExists: 'Ya existe un producto con este código'
        },
        table: {
            breadcrumbs: {
                title: 'Productos de facturación',
                description: 'Administración de productos de facturación'
            },
            headers: {
                code: 'Código',
                name: 'Nombre',
                status: 'Estado',
            },
            bulk_delete: 'Eliminar productos',
        },
        validation_errors: {
            billing_props: 'Ingresa un valor',
            billing_props_required: 'Selecciona al menos una habilitación',
        },
        sections: {
            infoTitle: 'Datos',
            enabledTitle: 'Habilitaciones',
            relatedProduct: 'Productos relacionados'
        },
        create: {
            alert_message: {
                title: 'Estás creando un producto deshabilitado',
                actions: {
                  primary_text: 'Aceptar',
                  secondary_text: 'Cancelar'
                }
            },
            breadcrumbs: {
                title: 'Nuevo producto de facturación',
                description: 'Crea un nuevo producto de facturación'
            },
            title: 'Datos',
            subtitle: 'Define un nombre e ingresa un código para el nuevo producto de facturación',
            labels: {
                name: 'Nombre',
                sap: 'Código SAP',
                description: 'Descripción',
            },
            success_message: {
                title: 'Producto creado correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear el producto',
                actions: {
                    primary_text: 'Cerrar',
                    go_back_text: 'Volver'
                }
            },
        },
        update: {
            breadcrumbs: {
                title: 'Editar producto',
                description: 'Modifica el producto seleccionado'
            },
            labels: {
                name: 'Nombre',
                code: 'Código Sap',
                description: 'Descripción'
            },
            success_message: {
                title: '',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar el producto',
                actions: {
                    primary_text: 'Cerrar',
                    go_back_text: 'Volver'
                }
            },
            section_name: {
                title: 'Datos',
                subtitle: 'Define un nombre e ingresa un código para el producto de facturación seleccionado',
                switchLabel: 'Habilitado'
            },
            alert_message: {
                title: 'Estás deshabilitando un producto',
                actions: {
                  primary_text: 'Aceptar',
                  secondary_text: 'Cancelar'
                }
            },
        },
        detail: {

        }
    },
    en: {

    }
};

export default productsTranslations