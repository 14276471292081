<template>
  <v-container class="pa-0 pb-8 StudentPreRegistration" fluid>
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.preRegistration.create.breadcrumbs.title')" :description="$t('modules.preRegistration.create.breadcrumbs.description')">
        <div class="mr-3" v-if="status && status.meaning == 'Preinscripto' && canDelete">
          <v-menu offset-y bottom>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <div class="primary--text">
                  {{ $t('modules.preRegistration.create.breadcrumbs.iconDescription') }}
                </div>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-if="canDelete" @click="deletePreRegistrationPopUp($route.params.id)">
                <v-icon>mdi-delete</v-icon>
                <v-list-item-title>{{ $t('modules.preRegistration.detail.unsubscribe') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </Breadcrumbs>

      <!-- Personal Info -->
      <v-row class="mx-6">
        <v-col cols="12">
          <v-expansion-panels multiple :value="[0]" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="py-0 my-0 pl-8 pr-2">
                <v-row>
                  <v-col sm="6">
                    <h2 class="title font-weight-bold">{{ $t('modules.preRegistration.detail.data') }}</h2>
                  </v-col>
                  <v-col sm="6" class="text-right" v-if="canUpdate">
                    <v-btn @click="editPreRegistrationUserData()" rounded color="primary" class="mr-4" outlined small>{{ $t('modules.preRegistration.detail.editdata') }}</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="px-8">
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.completeName') }}</h3>
                    <h2 class="body-2 text-uppercase">{{ userData.name }} {{ userData.lastname }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.rut') }}</h3>
                    <h2 class="body-2 text-uppercase">{{ userData.identification }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.serialNumber') }}</h3>
                    <h2 class="body-2">{{ userData.serialNumber }}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.email') }}</h3>
                    <h2 class="body-2">{{ userData.email }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.gender') }}</h3>
                    <h2 class="body-2 text-uppercase">{{ userData.gender ? userData.gender.meaning : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.birthday') }}</h3>
                    <h2 class="body-2">{{ userData.birthDate }}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.nationality') }}</h3>
                    <h2 class="body-2">{{ userData.nationality ? userData.nationality.demonym : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.maritalStatus') }}</h3>
                    <h2 class="body-2">{{ userData.maritalStatus ? userData.maritalStatus.meaning : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.cellphone') }}</h3>
                    <h2 class="body-2">{{ userData.cellphone }}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.address') }}</h3>
                    <h2 class="body-2">{{ street }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.city') }}</h3>
                    <h2 class="body-2">{{ userData.address ? userData.address.cityWithState.cityState : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.phone') }}</h3>
                    <h2 class="body-2">{{ userData.phone }}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.site') }}</h3>
                    <h2 class="body-2">{{ ceo ? ceo.description : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.career') }}</h3>
                    <h2 class="body-2">{{ career ? `(${career.code}) ${career.description}` : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.form.good_average') }}</h3>
                    <h2 class="body-2">{{ goodAverage ? $t('actions.yes') : $t('actions.no') }}</h2>
                  </v-col>

                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.admission') }}</h3>
                    <h2 class="body-2">{{ term ? term.code : '' }}</h2>
                  </v-col>
                  <v-col sm="4">
                    <h3 class="caption primary--text font-weight-bold">{{ $t('modules.preRegistration.detail.requestStatus') }}</h3>
                    <h2 class="body-2">{{ status ? status.meaning : ''}}</h2>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <!-- !Personal Info -->

      <!-- EXPANSION2 -->
      <v-row class="mx-6">
        <v-col cols="12">
          <v-expansion-panels multiple>
            <v-expansion-panel class="no-shadow">
              <v-expansion-panel-header class="py-3 my-0 pl-8 pr-2">
                <h2 class="title">{{ `${$t('modules.preRegistration.detail.tariff')} ${term ? term.code : ''}` }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-0">
                <v-row>
                  <v-col sm="12" class="py-0">
                    <v-card class="mb-5 py-5 px-7"> 

                      <!-- Matricula -->
                      <div class="row" v-for="(product, index) in prices" :key="index">
                        <v-col sm="12" class="py-0">
                          <v-row class="py-0">
                            <v-col sm="6" class="align-self-center">
                              <h2 class="title">{{ product ? product.billingProduct.name.trim() : '' }}</h2>
                            </v-col>
                            <v-col sm="6" class="align-self-center">
                              <h2 class="title text-right">{{ product.amount | $currency }}</h2>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col sm="12" class="py-0">
                          <v-divider></v-divider>
                        </v-col>

                        <template v-if="product.discount">
                          <v-col sm="12" class="py-0">
                            <v-row class="py-0 green--text">
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1">{{ product.discount.description }} - {{ productDiscount(product.amount, product.discount.discountDetails, true) }} %off</h2>
                              </v-col>
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1 text-right"> - {{ productDiscount(product.amount, product.discount.discountDetails) | $currency }}</h2>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col sm="12" class="py-0">
                            <v-divider></v-divider>
                          </v-col>
                        </template>

                        <template v-if="product.benefit">
                          <v-col sm="12" class="py-0">
                            <v-row class="py-0 green--text">
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1">{{ product.benefit.description }} - {{ productBenefit(product.amount, product.benefit.benefitDetail, true) }} %off</h2>
                              </v-col>
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1 text-right"> - {{ productBenefit(product.amount, product.benefit.benefitDetail) | $currency }}</h2>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col sm="12" class="py-0">
                            <v-divider></v-divider>
                          </v-col>
                        </template>

                        <template v-if="product.scholarship">
                          <v-col sm="12" class="py-0">
                            <v-row class="py-0 green--text">
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1">{{ product.scholarship.description }} - {{ product.scholarship.amount }} %off</h2>
                              </v-col>
                              <v-col sm="6" class="align-self-center">
                                <h2 class="body-1 text-right"> - {{ product.scholarship.amount | $currency }}</h2>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col sm="12" class="py-0">
                            <v-divider></v-divider>
                          </v-col>
                        </template>
                        <!-- !Matricula -->
                      </div>
                      
                      <div class="row">
                        <v-col sm="12" class="py-0">
                          <v-row class="py-0">
                            <v-col sm="6" class="align-self-center">
                              <h2 class="title">{{$t('modules.preRegistration.detail.total')}}</h2>
                            </v-col>
                            <v-col sm="6" class="align-self-center primary--text">
                              <h2 class="title text-right">{{ totalAmount | $currency }}</h2>
                            </v-col>
                          </v-row>
                        </v-col>

                      </div>
                    </v-card>
                  </v-col>
                
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="6">
                        <h2 class="caption font-weight-bold primary--text ml-7">{{ $t('modules.preRegistration.detail.dues') }}</h2>
                      </v-col>                                                        
                      <v-col sm="6">
                        <h2 class="caption font-weight-bold primary--text mr-7 text-right">{{ $t('modules.preRegistration.detail.amount') }}</h2>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col sm="12" class="py-0" v-for="item in accounts" :key="item.id">
                    <v-row>
                      <v-col sm="6" class="align-self-center">
                        <h2 class="body-2 ml-7">{{ item.description }}</h2>
                      </v-col>
                      <v-col sm="6" class="align-self-center">
                        <h2 class="title text-right mr-7">{{ item.amount | $currency }}</h2>
                      </v-col>
                      <v-col sm="12" class="py-0">
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- EXPANSION3 -->
            <v-expansion-panel class="no-shadow">
              <v-expansion-panel-header class="py-0 my-0 pl-8 pr-2">
                <v-row>
                  <v-col sm="6">
                    <h2 class="title font-weight-bold">{{ $t('modules.preRegistration.detail.documentation') }}</h2>
                  </v-col>
                  <v-col sm="6" class="d-flex flex-row-reverse">
                    <v-btn rounded color="primary" class="mr-4" small @click="goToProcess">{{ $t('modules.preRegistration.detail.gotoProcess') }}</v-btn>
                    <!-- <v-btn rounded color="primary" class="mr-4" outlined small @clicked="calendarDrawerOpen">{{$t('modules.preRegistration.detail.viewHistory')}}</v-btn> -->
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-2">
                <v-row v-for="item in obligatoryDocumentation" :key="item.id" class="mb-8">
                  <v-col cols="12">
                    <h4 class="mb-2">{{ item.description }}</h4>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="5">
                        <h2 class="caption font-weight-bold primary--text">{{$t('modules.preRegistration.detail.file')}}</h2>
                      </v-col>
                      <v-col sm="3" class="text-center">
                        <h2 class="caption font-weight-bold primary--text ml-2">{{$t('modules.preRegistration.detail.date')}}</h2>
                      </v-col>
                      <v-col sm="2" class="text-center">
                        <h2 class="caption font-weight-bold primary--text pl-0">{{$t('modules.preRegistration.detail.statusTitle')}}</h2>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="pa-0">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col sm="12" class="py-0">
                    <v-row>
                      <v-col sm="5">
                        <h2 class="body-2">{{ item.name }}</h2>
                      </v-col>
                      <v-col sm="3" class="text-center">
                        <h2  class="body-2 pl-0">{{ formatDateAndTime(item.dateUpload) }}</h2>
                      </v-col>
                      <v-col sm="2" class="text-center">
                        <v-icon v-if="item.status == 'Aprobado'" size="14" color="success" class="mr-2">mdi-check-circle</v-icon>
                        <v-icon v-if="item.status == 'Pendiente'" size="14" color="orange" class="mr-2">mdi-alert-circle</v-icon>
                        <v-icon v-if="item.status == 'Rechazado'" size="14" color="red" class="mr-2">mdi-close-circle</v-icon>
                        <h2 class="subtitle-2 d-inline-block">{{ item.status }}</h2>
                      </v-col>
                      <v-col sm="2" class="d-flex justify-end">
                        <v-btn @click="downloadDoc(item.name, item.id)" rounded color="primary" outlined x-small>{{ $t('modules.preRegistration.detail.viewFile') }}</v-btn>
                        <!-- <a :href="finalUrl" target="_blank" download ref="downloadButton" v-show="false"></a> -->
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" class="pa-0"> 
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { $studentProcessing, $preRegister } from '../services'
import moment from 'moment'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import download from 'downloadjs'
import { mapGetters } from 'vuex'

export default {
  name: 'StudentPreRegistrationDetail',
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      userData: {},
      prices: {
        matricula: null,
        arancel: null
      },
      obligatoryDocumentation: [],
      matriculaID: 0,
      arancelID: 0,
      term: null,
      ceo: null,
      career: null,
      status: null,
      goodAverage: false,
    };
  },
  computed: {
    ...mapGetters({
      userId: 'commons/getUserID',
    }),
    canUpdate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.admission.pre_registration.DELETE
    },
    street() {
      if (!this.userData.address) return ''
      const street = this.userData.address.street ? this.userData.address.street : ''
      const number = this.userData.address.number ? ` ${this.userData.address.number}` : ''
      const floor = this.userData.address.floor ? ` ${this.userData.address.floor}` : ''
      const apartment = this.userData.address.apartment ? ` ${this.userData.address.apartment}` : ''
      return `${street}${number}${floor}${apartment}`
    },
    totalAmount() {
      let total = 0
      total += this.prices.matricula.amount
      if (this.prices.matricula.discount) total -= this.productDiscount(this.prices.matricula.amount, this.prices.matricula.discount.discountDetails)
      if (this.prices.matricula.benefit) total -= this.productBenefit(this.prices.matricula.amount, this.prices.matricula.benefit.benefitDetail)
      if (this.prices.matricula.scholarship) total -= this.productScholarship(this.prices.matricula.amount, this.prices.matricula.scholarship.discountDetails)
      total += this.prices.arancel.amount
      if (this.prices.arancel.discount) total -= this.productDiscount(this.prices.arancel.amount, this.prices.arancel.discount.discountDetails)
      if (this.prices.arancel.benefit) total -= this.productBenefit(this.prices.arancel.amount, this.prices.arancel.benefit.benefitDetail)
      if (this.prices.arancel.scholarship) total -= this.productScholarship(this.prices.arancel.amount, this.prices.arancel.scholarship.discountDetails)
      return total
    },
    accounts() {
      if (!this.prices.arancel.installment) return []
      const accounts = []
      for (let index = 1; index <= this.prices.arancel.installment; index++) {
        accounts.push({
          id: index,
          description: `Cuota ${index}/${this.prices.arancel.installment}`,
          amount: this.prices.arancel.installmentAmount
        })
      }
      return accounts
    }
  },
  methods: {
    productDiscount(totalAmount, detail, percentage = false) {
      if (!detail) return 0
      if (percentage) {
        if (detail.bimesterPercentage) return detail.bimesterPercentage
        else if (detail.bianualPercentage) return detail.bianualPercentage
        else {
          const amount = detail.bimesterAmount ? detail.bimesterAmount : detail.bianualAmount
          return amount / totalAmount * 100
        }
      } else {
        if (detail.bimesterAmount) return detail.bimesterAmount
        else if (detail.bianualAmount) return detail.bianualAmount
        else {
          const percentage = detail.bimesterPercentage ? detail.bimesterPercentage : detail.bianualPercentage
          return totalAmount * percentage / 100
        }
      }
    },
    productBenefit(totalAmount, detail, percentage = false) {
      if (!detail) return 0
      if (percentage) {
        if (detail.percentage) return detail.percentage
        else return detail.amount / totalAmount * 100
      } else {
        if (detail.amount) return detail.amount
        else return totalAmount * detail.percentage / 100
      }
    },
    productScholarship(totalAmount, detail, percentage = false) {
      if (!detail) return 0
      if (percentage) {
        if (detail.percentage) return detail.percentage
        else return detail.amount / totalAmount * 100
      } else {
        if (detail.amount) return detail.amount
        else return totalAmount * detail.percentage / 100
      }
    },
    goToProcess() {
      this.$router.push(`/procedures?rut=${this.userData.identification}`)
    },
    async downloadDoc(name, id){
      const response = await $studentProcessing.getDocument(id, {responseType: 'blob'})
      const content = response.headers['content-type'];
      download(response.data, name, content)
    },
    formatDateAndTime(date) {
      return moment(date).format('YYYY-MM-DD HH:MM:SS')
    },
    deletePreRegistrationPopUp(id){
      if (!this.canDelete) return false
      this.$store.dispatch('commons/openPopUp', {
          title:  this.$t('modules.preRegistration.table.messages.askDelete'),
          content: [{value: id}],
          actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deletePreRegitration(id)},
          actionSecondary: {text: this.$t('actions.cancel'), callback () {}},
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
      })
    },
    async deletePreRegitration (id) {
      try {
        await $preRegister.update(id, {value: 56, user: this.userId})
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.preRegistration.table.messages.success'),
          content: '',
          actionPrimary: {text: this.$t('actions.accept'), callback: () => {this.$router.push('/preregistration')} },
          actionSecondary: null,
          icon: {color: 'success', name: 'mdi-check'},
          color: 'primary',
        });
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: (error.codeMeaning != '') ? error.codeMeaning : 'Ocurrió un error al eliminar la entidad',
          content: '',
          actionPrimary: {text: this.$t('actions.accept'), callback() {} },
          actionSecondary: null,
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        });
      }  
    },
    editPreRegistrationUserData(){
      this.$router.push(`/preregistration/update/${this.$route.params.id}`)
    },
    async getPreRegister() {
      try {
        this.matriculaID = this.$sisConstants('modules.products.matricula')
        this.arancelID = this.$sisConstants('modules.products.arancel')

        const resp = await $preRegister.findById(this.$route.params.id)
        if (resp.data.student.user) this.userData = resp.data.student.user.userData
        this.term = resp.data.term
        this.ceo = resp.data.ceo
        this.career = resp.data.career
        this.status = resp.data.careerStatus
        this.prices.matricula = resp.data.studentPreregistation.find(price => price.billingProduct.id == this.matriculaID)
        this.prices.arancel = resp.data.studentPreregistation.find(price => price.billingProduct.id == this.arancelID)
        this.obligatoryDocumentation = resp.data.obligatoryDocumentationList
        this.goodAverage = !!resp.data.career.good_average
      } catch (error) {
        this.userData = {}
      }
    }, 
  },
  mounted() {
    this.getPreRegister();
  }
};
</script>

<style lang="sass" scoped>
.banner
    background-color: #f6da63
    border-radius: 8px

    .text
        flex: 1
        color: #910012
        font-size: 14px
        font-weight: 600
        align-self: center

.inputpadding
    padding-bottom: 0px
    padding-top: 0px

.numero
    width: 73px
    padding-right: 0px
    margin-right: 0px
.depto
    width: 49px
    padding-right: 0px
    margin-right: 0px

.inputw 
    width: 162px

.hidden-block
    visibility: hidden

.no-border
    border: none !important

.payment-container
    min-height: 62px

.border-row
    border-left: 1px solid rgba(0, 0, 0, 0.12)
    height: 100%

.main-amount
    margin-left: 60px


.text-line-through
    text-decoration: line-through !important

.section-grey
    background: #f5f6f9

.img-coin
    width: 70%

.img-scroll
    width: 70%

.switch
    .v-input__slot
        margin-top: 12px !important
        margin-bottom: 0 !important

.StudentPreRegistration
    .v-expansion-panels
        .v-expansion-panel
            margin-bottom: 1em

            &:not(:first-child)::after
                border-top: none !important

            &.no-shadow:before
                box-shadow: none !important

            .v-expansion-panel-header
                border-bottom: 1px solid lightgrey
                min-height: auto
                max-heihgt: auto
                padding: 0.7em 0
                border-radius: 0
            
            .v-expansion-panel-content
                .v-expansion-panel-content__wrap
                    padding: 1.5em 0

                    .benefit-label
                        font-size: 14px
                        text-transform: uppercase
                    
                    .info-container
                        background: #f1f7ff
                        width: 100%
                        padding: 20px 15px

                        &.with-shadow
                            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16)
    .v-expansion-panel-content__wrap
        padding-top: 0 rem
</style>
