<template>
    <v-container fluid>
            <v-container class="container-custom">
                <Breadcrumbs :title="sanction.title" :description="sanction.description">
                    <v-menu offset-y >
                        <template v-slot:activator="{ on: menu }">
                            <v-btn text color="primary" v-on="{ ...menu }" >
                                <v-icon>mdi-dots-vertical</v-icon>
                                {{ $t('modules.academic.sanctions.details.breadcrumbs.actions') }}
                            </v-btn>
                        </template>
                        <v-list class="pa-0">
                            <v-list-item @click="editSanction(sanction.id)">
                                <v-list-item-title>
                                    <v-icon>mdi-pencil</v-icon>
                                    {{ $t('actions.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteSanctionPopUp(sanction.id)">
                                <v-list-item-title>
                                    <v-icon>mdi-delete</v-icon>
                                    {{ $t('actions.delete') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </Breadcrumbs>
                <v-row justify="center" align-content="center" class="mt-10">
                    <v-col cols="12 pt-0 mt-10">
                        <v-sheet color="#f5f6f9" class="px-3 BenefitsDetails-info-sheet">
                            <v-row>
                                <v-col cols="12" class="py-0 my-3" >
                                    <table class="BenefisDetails-info-table " height="100%">
                                        <thead>
                                            <tr >
                                                <th class="primary--text text-center">{{$t('modules.academic.sanctions.details.table.type')}}</th>
                                                <th class="primary--text text-center">{{$t('modules.academic.sanctions.details.table.level')}}</th>
                                                <th class="primary--text text-center">{{$t('modules.academic.sanctions.details.table.duration')}}</th>
                                                <th  class="primary--text text-center">{{$t('modules.academic.sanctions.details.table.examCancellation.title')}}</th>
                                                <th  class="primary--text text-center">{{$t('modules.academic.sanctions.details.table.discharge.title')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="with-line">
                                                <td class="text-center">{{sanction.sanctionType ? sanction.sanctionType.meaning : null}}</td>
                                                <td class="text-center">{{sanction.sanctionLevelType ? sanction.sanctionLevelType.meaning : null}}</td>
                                                <td class="text-center" v-if="sanction.daysDuration">{{sanction.daysDuration}}</td>
                                                <td class="text-center" v-else>{{$t('modules.academic.sanctions.details.table.permanent')}}</td>
                                                <td  class="text-center">{{$t('modules.academic.sanctions.details.table.examCancellation.noApply')}}</td>
                                                <td  class="text-center">{{sanction.discharge ? $t('modules.academic.sanctions.details.table.discharge.apply'): $t('modules.academic.sanctions.details.table.discharge.noApply')}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
                <OutlinedCard :title="$t('modules.academic.sanctions.details.sectionModules.title')">
                    <v-row>
                        <v-col cols="12">
                            <ul v-if="sanction.moduleList != null">
                                <li v-for="module in sanction.moduleList" :key=module.id>{{module.name}}</li>
                            </ul>
                            <h3 v-else>{{$t('modules.academic.sanctions.details.sectionModules.noModules.message')}}</h3>
                        </v-col>
                    </v-row>
                </OutlinedCard>
            </v-container>


    </v-container>
</template>

<script>
import { $sanction } from '../Services';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';

    export default {
        name:'SanctionDetail',
        components:{
            Breadcrumbs,
            OutlinedCard
        },
        data() {
            return {
                sanction: {},

            }
        },
        methods:{
            async getSanction() {
                try {
                    const sanction = await $sanction.findById(this.$route.params.id)
                    this.sanction = sanction.data;
                } catch (error) {
                    this.sanction = {}
                    throw error
                }
            },
            editSanction(id) {
                 this.$router.push(`/academic/sanctions/update/${id}`);
            },
            deleteSanctionPopUp(id) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.academic.sanctions.details.DeletePopUp.title'),
                    content: [{value: this.sanction.description}],
                    actionPrimary: {text: 'Aceptar', callback: () => this.deleteSanction(id)},
                    actionSecondary: {text: 'Cancelar', callback() {}},
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            async deleteSanction (id) {
                try {
                    await $sanction.delete(id);
                    this.$router.push(`/academic/sanctions`);
                } catch (e) {
                    if(e.code === 'E000'){
                      this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.academic.sanctions.delete.delete_message'),
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                      })

                    }else {
                      this.$store.dispatch('commons/openPopUp', {
                        title: e.codeMeaning,
                        content: null,
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary'
                      })
                    }
                  throw e;
                }
            },
        },
        mounted() {
            this.getSanction()
        },
    }
</script>

<style lang="sass" scoped>
.BenefitsDetails-info-sheet
    border-radius: 5px
    border: solid 1px #cecece
    height: 100%

    span, strong
      font-size: 14px

    h1
      font-size: 40px

    .BenefisDetails-info-table
      background: transparent
      width: 100%
      border-spacing: 0px

      tr
        td, th
          text-align: left
          padding-top: 10px
          padding-bottom: 10px
          border-bottom: none !important
          font-size: 14px

        &.with-line td
          border-top: 1px solid #cecece !important
</style>
