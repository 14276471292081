import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('payment');

export class Config extends Api {
  
  constructor(http, config = {}) {
    super(http, url, 'config', config);
  }

}