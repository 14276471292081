<template>
  <v-container>
      <v-card class="d-flex justify-space-between align-center pa-4"
            :class="data_text == 'Finalizados' ? 'teal--text' : (data_text == 'Gestión') ? 'green--text' : 'primary--text'"
      >
        <div class="d-flex flex-column">
            <h5 class="pt-0">{{data_text}}</h5>
            <h2 class="pb-0 font-weight-bold pl-0" 
            >{{data_procedures.cantidad ? data_procedures.cantidad : 0 }}</h2>
        </div>
        <v-progress-circular
          :rotate="360"
          :size="60"
          :width="7"
          :value="data_procedures.porcentaje"
          :color="data_text == 'Finalizados' ? 'teal' : (data_text == 'Gestión') ? 'green' : 'primary'"
        >
          {{data_procedures.porcentaje ? data_procedures.porcentaje : 0}} %
        </v-progress-circular>
      </v-card>
  </v-container>
</template>

<script>
export default {
    name: 'PanelDataTable',
    props: {
        data_procedures: {type: Object},
        data_text: {type: String}
    }

}
</script>

<style>

</style>