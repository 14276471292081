<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!updateZoneStatus">
        <Breadcrumbs
          :title="$t('modules.zones.update.breadcrumbs.title')"
          :description="$t('modules.zones.update.breadcrumbs.description')">
        </Breadcrumbs>
        <template>
            <v-row justify="center">
              <v-col class="viewSpaces" sm="12">
                <OutlinedCard
                  :switch-value="newZone.status"
                  @switchChange="(val)=> switchChange(val)"
                  class="mb-6"
                  :switch-label="(newZone.status) ? $t('modules.zones.enabled') : $t('modules.zones.disabled')"
                  switcher
                  :title="$t('modules.zones.create.title')"
                  :subtitle="$t('modules.zones.create.description')"
                >
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        :error="$v.newZone.zoneDescription.$anyError"
                        :error-messages="nameErrors"
                        :label="`${$t('modules.zones.update.labels.name')} *`"
                        outlined
                        v-model.trim="$v.newZone.zoneDescription.$model"
                        @keypress="$validateAlphanumeric($event, $v.newZone.zoneDescription.$model, 40, true, true)"
                        @blur="$v.newZone.zoneDescription.$touch()"
                      />
                    </v-col>
                  </v-row>
                </OutlinedCard>
                <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                <!-- <OutlinedCard
                  :title="$t('modules.zones.create.quarters_title')"
                  :subtitle="$t('modules.zones.create.quarters_description')"
                  class="mb-6"
                > -->
                <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                <!-- :TODO - Fix zonas, descomentar bloque al subir cambios -->
                <OutlinedCard
                  :title="$t('modules.zones.update.sectionLocality.title')"
                  :subtitle="$t('modules.zones.update.sectionLocality.subtitle')"
                  class="mb-6"
                >
                  <v-col class="d-flex px-0" cols="4" sm="3">
                    <v-autocomplete
                      :items="statesList"
                      item-text="name"
                      :label="$t('modules.zones.update.sectionLocality.province')"
                      outlined
                      return-object
                      v-model="selectState"
                    ></v-autocomplete>
                  </v-col>
                  <TransferList
                    :avatar="false"
                    :chips="true"
                    valueDisable="seller"
                    showInChip="message"
                    :loading="loadingSearchLocalities"
                    item-text="description"
                    :availableItems.sync="filteredLocalities"
                    :added-items.sync="localitiesAdded"
                    areEquals="id"
                    :firstListTitle="$t('modules.zones.update.sectionLocality.firstListTitle')"
                    :secondListTitle="`${$t('modules.zones.create.sectionLocality.secondListTitle')} ${localitiesAdded.length === 0 ? '' : localitiesAdded.length } `"
                  ></TransferList>
                  <!-- :TODO - Fix zonas, descomentar bloque al subir cambios -->
                  <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                  <!-- <TransferList
                    :avatar="false"
                    chips
                    item-text="description"
                    :loading="loadingSearchCeos"
                    :availableItems.sync="ceos"
                    :added-items.sync="ceosAdded"
                    areEquals="id"
                    :firstListTitle="$t('modules.zones.available')"
                    :secondListTitle="$t('modules.zones.selected')"
                    searchParameter="description"
                    showInChip="zoneDescription"
                    valueDisable="zone"
                    @searchItemLeftOnBackend="searchCeos"
                    @searchItemRightOnBackend="searchCeosAdded"
                  ></TransferList>
                  <v-alert text type="warning" class="mt-4" v-if="!ceosAdded.length">{{ $t('modules.zones.create.validation_errors.ceos') }}</v-alert> -->
                  <!-- :TODO - Fix zonas, borrar bloque al subir cambios -->
                  <v-alert text type="warning" class="mt-4" v-if="!localitiesAdded.length">{{ $t('modules.zones.create.sectionLocality.error.errorLocality') }}</v-alert>   <!--  :TODO - Fix zonas, descomentar linea al subir cambios -->
                </OutlinedCard>
                <div class="d-flex justify-end">
                  <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="$router.replace('/zones')"
                    @end="resetButtonValues()"
                    class="mr-4"
                  ></Button>

                  <Button
                    :loading="saveButton.loading"
                    :success="saveButton.success"
                    :error="saveButton.error"
                    :text="$t('actions.save')"
                    :successText="$t('actions.saved')"
                    :errorText="'Error'"
                    :disabled="!canUpdate || !canCreateZone"
                    @clicked="validateZone"
                    @end="resetButtonValues()"
                  ></Button>
                </div>
              </v-col>
            </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successText.type"
        :title="successText.title"
        :actionPrimary="successText.actionPrimary"
      />
    </v-container>
  </div>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TransferList from '@/components/TransferList/TransferList';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { required } from 'vuelidate/lib/validators'
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {$countries, $zones, $citiesByState} from '../Services';    // :TODO - Fix zonas, descomentar linea al subir cambios
// import { $zones, $ceos } from '../Services';    // :TODO - Fix zonas, borrar linea al subir cambios

import Configuration from '../../../../core/constants/configuration';

export default {
  name: 'ZoneUpdate',
  components: {
    TransferList,
    OutlinedCard,
    Breadcrumbs,
    Button,
    SuccessMessage,
  },
  data () {
    return {
      // loadingSearchCeos: false,   // :TODO - Fix zonas, borrar linea al subir cambios
      paginationLimit: 100,
      updateZoneStatus: false,
      newZone: {
        zoneDescription: '',
        // ceos: [],   // :TODO - Fix zonas, borrar linea al subir cambios
        city: [],   // :TODO - Fix zonas, descomentar linea al subir cambios
        status: true,
      },
      // ceos: [],   // :TODO - Fix zonas, borrar linea al subir cambios
      // ceosAdded: [],   // :TODO - Fix zonas, borrar linea al subir cambios
      // existingCeos: [],   // :TODO - Fix zonas, borrar linea al subir cambios
      /* :TODO - Fix zonas, descomentar codigo al subir cambios */
      statesList: [],
      selectState: null,
      loadingSearchLocalities: false,
      filteredLocalities: [],
      localitiesAdded: [],
      /* :TODO - Fix zonas, descomentar codigo al subir cambios */
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successText: {
        type: 'success',
        title: this.$t('modules.zones.update.success_message.title'),
        actionPrimary: this.$t('modules.zones.update.success_message.actions.primary_text'),
      },
      isStatusDisable: false
    }
  },
  async mounted () {
    // await this.getCeos()  // :TODO - Fix zonas, borrar linea al subir cambios
    this.getStates()  // :TODO - Fix zonas, descomentar linea al subir cambios
    this.fetchData()
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.zones.UPDATE
    },
    canCreateZone () {
      // return this.newZone.zoneDescription.length && this.ceosAdded ? this.ceosAdded.length : 0  // :TODO - Fix zonas, borrar linea al subir cambios
      return this.newZone.zoneDescription.length && this.localitiesAdded ? this.localitiesAdded.length : 0   // :TODO - Fix zonas, descomentar linea al subir cambios
    },
    nameErrors () {
      const errors = [];
      if (!this.$v.newZone.zoneDescription.$anyError) return [];
      if (!this.$v.newZone.zoneDescription.required) errors.push('El nombre de la zona es requerido');
      return errors;
    }
  },
  methods: {
    switchChange (val) {
      this.newZone.status = val;
      this.$v.$touch();
    },
    // searchCeos (event) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   this.getCeos(event);
    // },
    // searchCeosAdded (event) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   this.ceosAdded = event
    // },
    // async getCeos(ceo) {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //     this.loadingSearchCeos = true;
    //     let ceos = [];
    //     if (ceo) {
    //       ceos = await $ceos.find(null, null, {params: { name: ceo , length: this.paginationLimit }});
    //     } else {
    //       ceos = await $ceos.find(null, null, {params: { length: this.paginationLimit }});
    //     }
    //     if (ceos.data && ceos.data.content.length) {
    //       this.ceos = ceos.data.content
    //     }
    //   this.loadingSearchCeos = false;
    // },
    async getStates() {
      // :TODO - Fix zonas, descomentar metodo al subir cambios
      try {
        const country =  Configuration.value('country') || 'argentina'
        const statesLocal = await $countries.find(null, null, {params: { name: country }})
        this.statesList = (statesLocal.data[0].states).sort((a, b) => a.name < b.name ? -1 : 1)
      } catch (error) {
        this.statesList = []
        throw error
      }
    },
    async fetchData() {
      // :TODO - Fix zonas, descomentar metodo al subir cambios
      const {id} = this.$route.params;
      this.newZone = await this.$users.getZone(id);
      this.localitiesAdded = this.newZone.city ? this.newZone.city.map(item => {return item}) : [];
      for(let i = 0; i < this.filteredLocalities.length; i++){
        if(this.localitiesAdded.length) {
          for(let j = 0; j < this.localitiesAdded.length; j++){
            if(this.filteredLocalities[i].id === this.localitiesAdded[j].id) {
              this.filteredLocalities[i].zone = null
              this.localitiesAdded[j].zone = null
            }
          }
        }
      }
      this.filteredLocalities = this.filteredLocalities.filter(item => !this.localitiesAdded.map(item => item.id).includes(item.id))
    },
    // async fetchData () {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   const {id} = this.$route.params;
    //   this.newZone = await this.$users.getZone(id);
    //   this.ceosAdded = this.newZone.ceos ? this.newZone.ceos.map(item => {return item}) : [];
    //   for(let i = 0; i < this.ceos.length; i++){
    //     if(this.ceosAdded.length) {
    //       for(let j = 0; j < this.ceosAdded.length; j++){
    //         if(this.ceos[i].id === this.ceosAdded[j].id) {
    //           this.ceos[i].zone = null
    //           this.ceosAdded[j].zone = null
    //         }
    //       }
    //     }
    //   }

    //   this.ceos = this.ceos.filter(item => !this.ceosAdded.map(item => item.id).includes(item.id))
    // },
    validateZone () {
      if (!this.newZone.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.zones.update.alert_message.title'),
          content: '',
          actionPrimary: {text: this.$t('modules.zones.update.alert_message.actions.primary_text'), callback: this.createZone},
          actionSecondary: {text: this.$t('modules.zones.update.alert_message.actions.secondary_text'), callback() {}},
          icon: { color: 'warning', name: 'mdi-eye-off-outline' },
          color: 'primary',
        })
      } else {
        this.createZone()
      }
    },
    // async createZone () {
    //   // :TODO - Fix zonas, borrar metodo al subir cambios
    //   if (this.$v.$invalid || !this.canUpdate) {
    //     this.$v.$touch()
    //     return false
    //   }
      
    //   try {
    //     this.saveButton.loading = true;
    //     const ceos = this.ceosAdded.map(ceo => {return { id: ceo.id }});
    //     this.newZone.ceos = ceos
    //     await $zones.create(this.newZone)

    //     this.successText.type = 'success',
    //     this.successText.title = this.$t('modules.zones.update.success_message.title'),
    //     this.successText.actionPrimary = {text: this.$t('modules.zones.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/zones') }}
    //     this.saveButton.success = true;
    //     this.updateZoneStatus = true;
    //   } catch {
    //     this.successText.type = 'error';
    //     this.successText.title = this.$t('modules.zones.update.error_message.title')
    //     this.successText.actionPrimary = {text: this.$t('modules.zones.update.success_message.actions.back'), callback: () => { this.updateZoneStatus = false }}
    //     this.saveButton.error = true;
    //     this.saveButton.loading = false;
    //     this.updateZoneStatus = true;
    //     this.resetButtonValues()
    //   }
    // },
    async createZone () {
      // :TODO - Fix zonas, descomentar metodo al subir cambios
      if ( this.$v.$invalid || !this.canUpdate || this.localitiesAdded == 0) {
        this.$v.$touch()
      } else {
        if (!this.canUpdate) return false
        this.saveButton.loading = true;
        this.newZone.city = this.localitiesAdded.map(locality => {
          return {id: locality.id}
        })

      try {
        await $zones.create(this.newZone)

        this.successText.type = 'success',
        this.successText.title = this.$t('modules.zones.update.success_message.title'),
        this.successText.actionPrimary = {text: this.$t('modules.zones.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/zones') }}
        this.saveButton.success = true;
        this.updateZoneStatus = true;
      } catch {
        this.successText.type = 'error';
        this.successText.title = this.$t('modules.zones.update.error_message.title')
        this.successText.actionPrimary = {text: this.$t('modules.zones.update.success_message.actions.back'), callback: () => { this.updateZoneStatus = false }}
        this.saveButton.error = true;
        this.saveButton.loading = false;
        this.updateZoneStatus = true;
        this.resetButtonValues()
        }
      }
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
  },
  validations: {
    newZone: {
      zoneDescription: { required }
    },
  },
  watch: {
    async selectState() {
      // :TODO - Fix zonas, descomentar bloque al subir cambios
      this.loadingSearchLocalities = true
      const cities = await $citiesByState.find(null, null, { params: { stateId: this.selectState.id, sellers:true } });

      const newCities = (cities.data.sort((a, b) => a.name < b.name ? -1 : 1)).map(el => {
        if (el.seller) {
          const newseller = {status: el.seller, message : 'No disponible'}
          const newElem = {...el, seller:newseller}
          return newElem
        }else {
          return { ...el, seller:undefined }
        }
      })
      this.filteredLocalities =  newCities
      this.loadingSearchLocalities = false
    },
  }
}
</script>
