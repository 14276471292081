import Quarters from '../Quarters'
import QuartersCreate from '../views/QuartersCreate';
import QuartersTable from '../views/QuartersTable';
import QuartersUpdate from '../views/QuartersUpdate';

const QuartersRoute =
{
  path: '/quarters',
  meta: {
    name: {
      en: 'Quarters',
      es: 'Centros de servicios',
      }
    },
  component: Quarters,
  children: [
    {
      path: '/',
      component: QuartersTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'quarters', permission: 'READ' }
    }, {
      path: 'create',
      component: QuartersCreate,
      meta: { name: 'QuartersCreate', group: 'portaladministrativo.prices', path: 'quarters', permission: 'CREATE' }
    }, {
      path: 'update/:id',
      component: QuartersUpdate,
      meta: { name: 'QuartersUpdate', group: 'portaladministrativo.prices', path: 'quarters', permission: 'READ' }
    },
  ]
};

export default QuartersRoute
