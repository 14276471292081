<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
      :title="$t('modules.portalalumno.payments.checkingAccount.credits.breadcrumb.title')"
      ></Breadcrumbs>

      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">

          <v-row v-if="creditsDataTable && creditsInfo">
            <v-col cols="12">
              <h4 class="subtitle">{{$t('modules.portalalumno.payments.checkingAccount.credits.subtitle')}}</h4>
              <h4 class="subtitle" v-html="$t('modules.portalalumno.payments.checkingAccount.credits.description')"></h4>

              <TeclabCreditsControl :data="creditsInfo" />
              <v-divider />
              <TeclabCreditsTable :data="creditsDataTable" />
            </v-col>

          </v-row>
        </v-col>
      </v-row>


    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import TeclabCreditsControl from '../Components/TeclabCreditsControl';
import TeclabCreditsTable from '../Components/CreditTeclabTable';
import { $receipt } from '../Services';
import { mapGetters } from 'vuex';
export default {
  name: 'TeclabCredits',
  components: {
    Breadcrumbs,
    TeclabCreditsControl,
    TeclabCreditsTable
  },
  data() {
    return {
      creditsDataTable: null,
      creditsInfo: null
    };
  },
  created() {
   this.getInitialData()
  },
  methods: {
    async getInitialData() {
      try{
      const { data }  = await $receipt.find('credits', null, {params: { studentId: this.studentId }})
      this.creditsDataTable = [...data.credits, ...data.redeemedCredits];
      this.creditsInfo = {
        accumulated: data.accumulatedCreditsAmount,
        redeemed: data.redeemedCreditsAmount,
        due: data.dueCreditsAmount,
        dueDate: data.dueDate
      } ;
    }catch{
      this.creditsDataTable = null;
      this.creditsInfo = null;
    }
    }
  },
  computed: {
    ...mapGetters({
      studentId: 'commons/getStudentId'
    }),
  }
}
</script>
<style lang="sass" scoped>
.subtitle
 color: #707070
</style>
