<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.academic.practiceTerms.table.breadcrumbs.title')"
          :description="$t('modules.academic.practiceTerms.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newPracticeTerms"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="practiceTerms"
              :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @searchAction="searchAction"
              @deleteData="deletePopUp"
              @updateData="updatePracticeTerms"
              @toogleInscripcion="toogleInscripcion"
              @toogleAplicativo="toogleAplicativo"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$professionalizingPractices} from '../Services';
import moment from 'moment'

export default {
  name: 'PracticeTermsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      practiceTerms: [],
      sort: {
        sortBy: 'enabled',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.academic.practiceTerms.table.headers.termDescription'),
          value: 'termDescription'
        },
        {
          text: this.$t('modules.academic.practiceTerms.table.headers.initialDatePP'),
          value: 'initialDatePP'
        }, {
          text: this.$t('modules.academic.practiceTerms.table.headers.finalDate'),
          value: 'finalDate'
        }, {
          text: this.$t('modules.academic.practiceTerms.table.headers.lastApprovedExam'),
          value: 'lastApprovedExam',
          align: 'center'
        }, {
          text: this.$t('modules.academic.practiceTerms.table.headers.management'),
          value: 'management',
          align: 'center',
          sortable: false,
        }, {
          text: this.$t('modules.academic.practiceTerms.table.headers.enabled'),
          align: 'center',
          value: 'enabled'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.professional_practice_terms.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.professional_practice_terms.READ || this.$permissions.portaladministrativo.academic.professional_practice_terms.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.professional_practice_terms.DELETE;
    },
  },
  methods: {
    deletePopUp(id) {
      const practiceTerm = this.practiceTerms.find(practiceTerm => practiceTerm.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: practiceTerm.initialDatePP}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deletedPracticeTerm(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.clearSelected = new Date().getMilliseconds().toString();
      this.loading = true;
      try {
        const params = {
           page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc'
        };
        const filterParams = {
          search: this.search
        }
        const {data} = await $professionalizingPractices.filter(filterParams, {params});
        this.practiceTerms = data.content.map(item => {
          item.initialDatePP = moment(item.initialDatePP).format('DD-MM-YYYY');
          item.finalDate = moment(item.finalDate).format('DD-MM-YYYY');
          item.lastApprovedExam = moment(item.lastApprovedExam).format('DD-MM-YYYY');
          return item
        });
        this.totalItems = data.totalElements;
        this.pagination.pagesAmount = data.totalPages;
      } catch (err) {
        this.practiceTerms = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    newPracticeTerms() {
      if (this.canCreate) this.$router.push('/academic/practice-terms/create');
    },
    updatePracticeTerms(id) {
      if (this.canUpdate) this.$router.push(`/academic/practice-terms/update/${id}`);
    },
    async deletedPracticeTerm(id) {
      try {
        await $professionalizingPractices.delete(id);
        this.practiceTerms = this.practiceTerms.filter(practiceTerm => practiceTerm.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    async toogleInscripcion(value) {
       const params = {
            enabled: !value.enabled,
          };
          await $professionalizingPractices.update(value.id, null, {params}, 'enabled');
          this.fetchData();
    },
    async toogleAplicativo(value) {
       const params = {
            management: !value.management,
          };
          await $professionalizingPractices.update(value.id, null, {params}, 'management');
          this.fetchData();
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
