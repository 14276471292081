import ConfigTable from '../Views/ConfigTable'
import ConfigCreate from '../Views/ConfigCreate'
import ConfigUpdate from '../Views/ConfigUpdate'

const configRoute = [
    {
        path:'',
        component: ConfigTable,
        meta: { name: 'Index', group: 'portaladministrativo.commissions', path: 'config', permission: 'READ' }
    },
    {
        path:'create',
        component: ConfigCreate,
        meta: { name: 'Create Commission', group: 'portaladministrativo.commissions', path: 'config', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: ConfigUpdate,
        meta: { name: 'Update Commission', group: 'portaladministrativo.commissions', path: 'config', permission: 'READ' }
    },
];

export default configRoute
