import Procedures from '../Procedures'
import ProceduresInitTable from '../Views/ProceduresInitTable'
import ProceduresInitUpdate from '../Views/ProceduresInitUpdate'

const ProceduresRoute = {
  path: '/procedures',
  meta: { name: { en: 'Procedures', es: 'Trámites' } },
  component: Procedures,
  children: [
    {
      path: '',
      component: ProceduresInitTable,
      meta: { name: 'Index', group: 'portaladministrativo', path: 'procedures', permission: 'READ' }
    },
    {
      path: ':id',
      component: ProceduresInitUpdate,
      meta: { name: 'ProceduresInitUpdate', group: 'portaladministrativo', path: 'procedures', permission: 'READ' }
    },
  ]
}

export default ProceduresRoute;