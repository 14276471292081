<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <div class="settings-container">
      <div class="title primary--text px-6 pt-4">{{ $t('modules.settings.asideBar.title') }}</div>
      <AsideBar :categories="items"></AsideBar>
    </div>
    <v-container fluid class="scrollableContent pa-0">
      <router-view></router-view>
    </v-container>
  </v-container>
</template>

<style scoped lang="css">
.v-list-item--link {
  margin-left: 10px;
}

.settings-container {
  background: #ebebeb;
  width: 300px;
}

.config-container {
  background: #ebebeb;
}
</style>

<script>
import AsideBar from './Components/AsideBar/AsideBar'

export default {
  components: {
    AsideBar
  },
  mounted () {
    this.getCategoriesByPermissions()
    this.$store.dispatch('settings/initData')
      .then(() => {
      })
      .catch(() => {

      })
  },
  data () {
    return {
      items: [],
      categories: [
        {
          title: this.$t('modules.settings.asideBar.categories.system.title'),
          items: [
            {
              name: this.$t('modules.settings.asideBar.categories.system.items.general'),
              path: '/settings/'
            },
            {
              name: this.$t('modules.settings.asideBar.categories.system.items.appareance'),
              path: 'appearance'
            }
          ]
        },
        {
          title: this.$t('modules.settings.asideBar.categories.communications.title'),
          items: [
            {
              name: this.$t('modules.settings.asideBar.categories.communications.items.email'),
              path: 'email',
            },
            {
              name: this.$t('modules.settings.asideBar.categories.communications.items.sms'),
              path: 'sms'
            }
          ]
        },

        {
          title: this.$t('modules.settings.asideBar.categories.legal.title'),
          items: [
            {
              name: this.$t('modules.settings.asideBar.categories.legal.items.privacy'),
              path: 'privacy'
            },
            {
              name: this.$t('modules.settings.asideBar.categories.legal.items.terms'),
              path: 'terms'
            }
          ]
        },

        {
          title: this.$t('modules.settings.asideBar.categories.commissions.title'),
          items: [
            {
              name: this.$t('modules.settings.asideBar.categories.commissions.items.liquidations'),
              path: 'liquidations'
            },            
            {
              name: this.$t('modules.settings.asideBar.categories.commissions.items.billings'),
              path: 'billings'
            },            
          ]
        }
      ]
    };
  },
  methods: {
    getCategoriesByPermissions(){
      if(this.$permissions.settings.system.READ == true){
        this.items.push(this.categories[0])
      }
      if(this.$permissions.settings.communication.READ == true){
        this.items.push(this.categories[1])
      }
      if(this.$permissions.settings.legales.READ == true){
        this.items.push(this.categories[2])
      }
      if(this.$permissions.settings.billing.READ == true){
        this.items.push(this.categories[3])
      }
    }
  }
};
</script>
