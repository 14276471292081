<template>
  <v-sheet
    class="special-check-container align-center"
    :color="getColor"
    :style="`border: 1px solid ${localItem.selected ? (textColor == 'success' ? 'var(--v-success-base)' : textColor) : '#d3d3d3'} !important`"
    @click="onClick"
  >
    <v-checkbox
      v-if="hasCheckbox"
      v-model="localItem.selected"
      :label="localItem.label"
      :disabled="disabled"
      :color="textColor == 'success' ? 'var(--v-success-base)' : 'primary'"
      hide-details
      readonly
      class="mt-0 pt-0"
    ></v-checkbox>
    <span v-else v-html="localItem.label" :style="disabled ? 'color: #6A6A6A87' : ''"></span>
    <v-chip :disabled="disabled" label :light="disabled" :color="disabled ? '' : color" :text-color="textColor" class="align-self-center">{{ localItem.amount | $currency }}</v-chip>
  </v-sheet>
</template>

<script>
export default {
  name: 'SpecialCheck',
  props: {
    item: { type: Object, default:() => ({label: '', amount: 0, selected: false}) },
    color: { type: String, default: 'primary' },
    textColor: String,
    disabled: { type: Boolean, default: false},
    hasCheckbox: { type: Boolean, default: true}
  },
  data() {
    return {
      localItem: this.item,
    }
  },
  watch: {
    item(val) {
      this.localItem = val
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.localItem.selected = this.hasCheckbox ? !this.localItem.selected : this.localItem.selected
        this.$emit('updateItem')
      }
    }
  },
  computed: {
    getColor() {
      return this.localItem.selected && !this.textColor ? '#d9e2fa' : 'white'
    }
  }
}
</script>

<style lang="sass" scoped>
  .special-check-container
    height: 46px
    display: flex
    cursor: pointer
    flex-flow: row nowrap
    justify-content: space-between
    align-content: center
    border-radius: 5px
    padding: 10px 10px
    border: 1px solid #d3d3d3 !important
</style>
