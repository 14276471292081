var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 pb-8",attrs:{"fluid":""}},[_c('v-container',{staticClass:"container-custom"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.users.details.workSection.drawer.form.title')}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-select',{ref:"selectedWorkingStatusType",attrs:{"items":_vm.workingStatusTypes,"label":_vm.$t('modules.users.details.workSection.drawer.form.workingStatus'),"item-text":"meaning","return-object":"","outlined":"","error-messages":(_vm.$v.selectedWorkingStatusType.$dirty && _vm.$v.selectedWorkingStatusType.$invalid) ?
                    _vm.$t('modules.users.details.workSection.drawer.form.validations.workingStatusRequired') : ''},on:{"blur":function($event){return _vm.$v.selectedWorkingStatusType.$touch()}},model:{value:(_vm.selectedWorkingStatusType),callback:function ($$v) {_vm.selectedWorkingStatusType=$$v},expression:"selectedWorkingStatusType"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{ref:"selectedWorkingQuantityType",attrs:{"items":_vm.workingQuantityTypes,"label":_vm.$t('modules.users.details.workSection.drawer.form.workersQuantity'),"item-text":"meaning","return-object":"","outlined":"","error-messages":(_vm.$v.selectedWorkingQuantityType.$dirty && _vm.$v.selectedWorkingQuantityType.$invalid) ?
                    _vm.$t('modules.users.details.workSection.drawer.form.validations.workersQuantityRequired') : ''},on:{"blur":function($event){return _vm.$v.selectedWorkingQuantityType.$touch()}},model:{value:(_vm.selectedWorkingQuantityType),callback:function ($$v) {_vm.selectedWorkingQuantityType=$$v},expression:"selectedWorkingQuantityType"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{ref:"companyName",attrs:{"label":_vm.$t('modules.users.details.workSection.drawer.form.companyName'),"outlined":"","error-messages":(_vm.$v.companyName.$dirty && _vm.$v.companyName.$invalid) ?
                    ((!_vm.$v.companyName.required) ? _vm.$t('modules.users.details.workSection.drawer.form.validations.companyNameRequired') :
                    (!_vm.$v.companyName.alphaNumWithSpaces) ? _vm.$t('modules.users.details.workSection.drawer.form.validations.companyNameAlphanumericWithSpaces') :
                    _vm.$t('modules.users.details.workSection.drawer.form.validations.companyNameMaxLength')) :
                    ''},on:{"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.companyName.$model,60, true, false)},"blur":function($event){return _vm.$v.companyName.$touch()}},model:{value:(_vm.$v.companyName.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyName, "$model", $$v)},expression:"$v.companyName.$model"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{ref:"bossName",attrs:{"label":_vm.$t('modules.users.details.workSection.drawer.form.bossName'),"outlined":"","error-messages":(_vm.$v.bossName.$dirty && _vm.$v.bossName.$invalid) ?
                    ((!_vm.$v.bossName.alphabeticWithSpaces) ? _vm.$t('modules.users.details.workSection.drawer.form.validations.bossNameAlphabeticWithSpaces') :
                    _vm.$t('modules.users.details.workSection.drawer.form.validations.bossNameMaxLength')) :
                    ''},on:{"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.bossName.$model,60, true, true)},"blur":function($event){return _vm.$v.bossName.$touch()}},model:{value:(_vm.$v.bossName.$model),callback:function ($$v) {_vm.$set(_vm.$v.bossName, "$model", $$v)},expression:"$v.bossName.$model"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{ref:"bossEmail",attrs:{"label":_vm.$t('modules.users.details.workSection.drawer.form.bossEmail'),"outlined":"","error-messages":(_vm.$v.bossEmail.$dirty && _vm.$v.bossEmail.$invalid) ?
                    _vm.$t('modules.users.details.workSection.drawer.form.validations.bossEmailFormat') : ''},on:{"blur":function($event){return _vm.$v.bossEmail.$touch()}},model:{value:(_vm.$v.bossEmail.$model),callback:function ($$v) {_vm.$set(_vm.$v.bossEmail, "$model", $$v)},expression:"$v.bossEmail.$model"}})],1)],1),_c('v-row',[_c('v-col',[_c('TelephoneInput',{ref:"bossTelephone",attrs:{"value":_vm.$v.bossTelephone.$model,"placeholder":_vm.$t('modules.users.details.workSection.drawer.form.bossTelephone')},on:{"update:value":function($event){return _vm.$set(_vm.$v.bossTelephone, "$model", $event)},"validate":_vm.isValidPhone,"blur":function($event){return _vm.$v.bossTelephone.$touch()}}})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$emit('closeDrawer')}}}),_c('Button',{attrs:{"loading":_vm.saveButton.loading,"success":_vm.saveButton.success,"error":_vm.saveButton.error,"text":_vm.$t('actions.save'),"successText":_vm.$t('actions.saved'),"errorText":_vm.$t('actions.error'),"disabled":!_vm.canUpdateUser},on:{"clicked":_vm.validateWorkData,"end":_vm.resetButtonValues}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }