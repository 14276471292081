import ExamException from '../ExamException'
import ExamExceptionTable from '../Views/ExamExceptionTable'
import ExamExceptionCreate from '../Views/ExamExceptionCreate'

const ExamExceptionRoute = {
    path: '/exam-exception',
    meta: {
        name: {
            en: 'Exam exception',
            es: 'Examen por excepción',
        }
    },
    component: ExamException,
    children: [
        {
            path: '/',
            component: ExamExceptionTable,
            meta: { name: 'Index', group: 'portaladministrativo.exams', path: 'exception', permission: 'READ' }
        },{
            path: 'create/:id',
            component: ExamExceptionCreate,
            meta: { name: 'ExamExceptionCreate', group: 'portaladministrativo.exams', path: 'exception', permission: 'CREATE' }
        }
    ]
};

export default ExamExceptionRoute
