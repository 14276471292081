const dashboardTranslations = {
    es_PE: {
        aranceles:{
            selects:{
                over:{
                    location:'Distrito'
                },
            }
        }
    },
    es: {
        progress: {
            title: 'Avance',
            cardSales: {
                title: 'Ventas Acumuladas',
                subtitle: 'Arancelados',
                description: 'vs Objetivo Total del Periodo',
                titleTwo: 'Objetivo Total del Periodo'
            },
            cardSubPeriod : {
                title: 'Ventas Acumuladas',
                subtitle: 'Arancelados',
                description: 'vs Objetivo acumulado del Periodo',
                titleTwo: 'Objetivo Acumulado del Periodo'
            },
            cardCommission : {
                titleDay: 'dias',
                title: 'Comisión Variable',
                subtitle: 'Necesitas',
                description: 'Arancelados'
            },
        },
        filters:{
            title:'Tablero de Comisiones',
            subtitle: 'Hola',
            selects:{
                all:'Todos',
                over:{
                    year:'Año',
                    period:'Periodo/subperiodo',
                    agent:'Agente',
                    subtitleYear: 'Año deshabilitado para aquellos que empiezan en el 2021',
                    subtitlePeriod:'Seleccionar periodo',
                    subtitleSeller: 'Seleccionar agente'
                },
                button:'Aplicar filtro',
                download:'Descargar reporte',
                my_reports:'Mis reportes'
            },
            cards:{
                subtitle: 'Fecha de inicio a cierre',
                charge:'A cobrar',
                charged:'Cobradas',
                variableCommissions:{
                    head:'Comisines Variables',
                    content:`¡Estás a {salesNeedToPrize} Arancelados para lograr un {extraPercentagePayment}% más!`,
                    footer:'Conseguilos y sumá {termObjetiveAmount} ARS',
                },
            }
        },
        aranceles:{
            selects:{
                all:'Todos',
                over:{
                careers:'Carreras',
                location:'Localidad',
                paymentMethod:'Medio de pago',
                benefit:'Beneficios',
                },
            },
            moreInfo:{
                more:'Ver mas',
                back:'Volver'
            },
            cardLeft:{
                head:{
                    arancelados:'Arancelados',
                    career:'Carrera',
                    commision:'Comisión',
                }
            },
            cardRigth:{
                top5:'Ubicacion geografica (top 5)',
                paymentMethod:'Medios de pago',
                grafic:{
                    credit:'Credito',
                    debit:'Debito',
                    cash:'Efectivo',
                    subscription:'subscripcion'

                }                
            }
        }
    },
    en: {},
}

export default dashboardTranslations
