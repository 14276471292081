<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters class="justify-space-between">
      <v-col sm="4" class="mt-4 mb-2 d-flex" >
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :show-select="false"
          :headers="headers"
          :items="data"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed; cursor:pointer"         
          @click:row="(row) => $emit('click-row', row.id, row.name)"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)" 
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)" 
        >
          <template v-slot:[`item.name`]="{item}">
              <v-row >
                  <v-col sm="12" class="py-0">
                      {{ item.name }}
                  </v-col>
              </v-row>
          </template>

         <template v-slot:[`item.amountTemplates`]="{item}">
              <v-row >
                  <v-col sm="12" class="py-0">
                      {{ item.amountTemplates }}
                  </v-col>
              </v-row>
          </template>
        
         <template v-slot:[`item.status`]="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template> 

          <template v-slot:[`item.action`]="{ item }" >
              <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list class="pa-0">
                      <v-list-item v-if="permissions.update" @click="editItem(item)">
                          <v-list-item-title>
                              <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="permissions.delete" @click="deleteItem(item.id)">
                          <v-list-item-title>
                              <v-icon>mdi-delete</v-icon> {{ $t('actions.delete') }}
                          </v-list-item-title>
                      </v-list-item>
                  </v-list>
              </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-4"
          @change="$emit('lengthPaginationChange', itemsPerPage)"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination 
        v-model="page" 
        :length="pageCount" 
        class="superTable__pagination"
        @input="$emit('inputPagination', $event)"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          :max="pageCount"
          outlined
          v-model.number="goToPage"
          @blur="changePage"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.v-expansion-panels
  .v-expansion-panel-content
    padding-left: 10px
    padding-bottom: 0px
    padding-right: 16px
</style>

<script>

export default {
  name: 'TemplateTable',
  components: {
    
  },
  props: {
    data: Array,
    headers: Array,
    sortBy: String,
    sortDesc: Boolean,
    pageCount: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalItems: Number,
    permissions: {update: false, delete: false},
  },

  data() {
    return {
      search: '',
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      goToPage: 1,
      timeout: null,
    };
  },
  methods: {
    keyup() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction();
        clearTimeout(this.timeout);
      }, 1000);
    },
    searchAction() {
      this.$emit('searchAction', this.search);
    },
    changePage() {
      if (this.goToPage > this.pageCount) {
        this.goToPage = this.page;
      } else {
        this.$emit('inputPagination', this.goToPage);
      }
    },
    editItem (item) {
      this.$emit('updateData', item);
    },
    deleteItem (id) {
      this.$emit('deleteData', id);
    }
  },

  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
  },

  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage,
      };

      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems;
      }

      return counter;
    },
  },
};
</script>
