<template>
    <div>
        <v-row class="mt-5" v-if="title">
            <v-col cols="12">
                <h4 class="primary--text">{{ $t('modules.portalalumno.payments.paymentsStudents.view.labels.billingTitle') }}</h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="8">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.name') + ' *'"
                    v-model="$v.data.name.$model"
                    outlined
                    :error-messages="$v.data.name.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.data.name.$touch()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
                <IdentificationInput
                    v-model="$v.data.id.$model"
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.idoncard', { identificationType: this.$isTeclab ? 'DNI' : 'RUT' }) + ' *'"
                    :error-messages="$v.data.id.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.data.id.$touch()"
                    @validateRut="validateRut"
                ></IdentificationInput>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="8">
                <v-text-field
                    :label="`${$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetaddress')} *`"
                    v-model="$v.data.street.$model"
                    outlined
                    :error-messages="$v.data.street.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.data.street.$touch()"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
                <v-text-field
                    type="number"
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetnumber') + ' *'"
                    v-model.number="$v.data.number.$model"
                    @keypress="$validateIntegerInputNumber($event, $v.data.number.$model, null, 7)"
                    outlined
                    :error-messages="$v.data.number.$error ? $t('modules.portalalumno.payments.paymentsStudents.inputErrors.required') : ''"
                    @blur="$v.data.number.$touch()"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="8">
                <AutocompleteInput
                    :value.sync="$v.data.city.$model"
                    :errorMessages="$t('modules.portalalumno.payments.paymentsStudents.inputErrors.required')"
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.city') + ' *'"
                    disable-buttons
                ></AutocompleteInput>
            </v-col>

            <v-col cols="12" sm="2">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetfloor')"
                    v-model="data.floor"
                    @keypress="$validateIntegerInputNumber($event, data.floor, null, 3)"
                    outlined
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
                <v-text-field
                    :label="$t('modules.portalalumno.payments.paymentsStudents.view.labels.streetapartment')"
                    v-model="data.apartment"
                    @keypress="$validateLengthInput($event, 6)"
                    outlined
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
    import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
    import { required } from 'vuelidate/lib/validators'
    import { $billingData } from '../../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'PaymentBillingData',
        props: {
            title: {type: Boolean, default: true},
        },
        components: {
            AutocompleteInput,
            IdentificationInput,
        },
        inject:['studentId'],
        data() {
            return {
                data: {
                    validRut: false,
                    name: null,
                    id: null,
                    city: null,
                    street: null,
                    number: null,
                    floor: null,
                    apartment: null,
                }
            }
        },
        computed: {
            ...mapGetters({
                studentData: 'commons/getStudentUserData'
            })
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            async fetchData() {
                try {
                    const { data } = await $billingData.find(null,  null , {params: {  idStudent : this.studentId }})

                    this.data.validRut = true
                    this.data.name = `${data.student.name} ${data.student.lastname}`
                    this.data.id = data.docNumber
                    this.data.street = data.address
                    this.data.number = data.addressNumber
                    this.data.floor = data.addressFloor
                    this.data.apartment = data.addressDepartment
                    this.data.city = data.cityWithState

                    this.$emit('validate', !this.$v.$invalid && this.data.validRut)
                } catch (error) {

                    this.data.validRut = false
                    this.data.name = null
                    this.data.id = null
                    this.data.street = null
                    this.data.number = null
                    this.data.floor = null
                    this.data.apartment = null
                    this.data.city = null
                    throw error
                }
            },
            async save() {
                try {
                    await $billingData.create({
                        student: {id: this.studentId},
                        identificationType: {value: 17},
                        docNumber: this.data.id,
                        name: this.data.name,
                        address: this.data.street,
                        addressNumber: this.data.number,
                        addressFloor: this.data.floor,
                        addressDepartment: this.data.apartment,
                        city: this.data.city.idCity,
                        region: this.data.city.idRegion,
                        contactName : this.data.name,
                    });
                    return true
                } catch {
                    return false
                }
            },
            validateRut(status) {
                this.data.validRut = status
                if (this.$v.$anyDirty) this.$emit('validate', !this.$v.$invalid && status)
            },
        },
        watch:{
            data: {
                handler(value) { this.$emit('update', value) },
                deep: true
            },
            $v: {
                handler(value) { if (value.$anyDirty) this.$emit('validate', !value.$invalid && this.data.validRut) },
                deep: true
            },
        },
        validations: {
            data: {
                name: {required},
                id: {required},
                city: {required},
                street: {required},
                number: {required},
            }
        },
    }
</script>
