<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @keyup="keyup"
                    @keyup.esc="search = ''"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :show-select="false"
                    :headers="headers"
                    :items="data"
                    :search="search"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table"
                    style="table-layout: fixed"
                >

                    <template v-slot:[`item.status`]="{ item }">
                        <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-menu bottom left v-if="canShow('debtInscription', item) || canShow('confirmPreregistration', item) || canShow('revertEnrollment', item) || canShow('paymentAdjustment', item) || canShow('changeModality', item)">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item v-if="canShow('debtInscription', item)" @click="$emit('confirm-action', 'debtInscription', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-currency-usd</v-icon> {{ $t('modules.exceptions.table.actions.debt_inscription') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="canShow('paymentAdjustment', item)" @click="$emit('confirm-action', 'paymentAdjustment', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-currency-usd</v-icon> {{ $t('modules.exceptions.table.actions.payment_adjustment') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="canShow('confirmPreregistration', item)" @click="$emit('confirm-action', 'confirmPreregistration', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-signature-freehand</v-icon> {{ $t('modules.exceptions.table.actions.confirm_preregistration') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="canShow('revertEnrollment', item)" @click="$emit('confirm-action', 'revertEnrollment', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-history</v-icon> {{ $t('modules.exceptions.table.actions.revert_enrollment') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="canShow('changeModality', item)" @click="$emit('confirm-action', 'changeModality', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-file-sync-outline</v-icon> {{ $t('modules.exceptions.table.actions.change_modality') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="canShow('allowReenrollment', item)" @click="$emit('confirm-action', 'allowReenrollment', item)">
                                    <v-list-item-title>
                                        <v-icon>mdi-file-document-edit-outline</v-icon> {{ $t('modules.exceptions.table.actions.allow_reenrollment') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="''+itemsPerPage"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                    v-model="page"
                    max="pageCount"
                    outlined
                    class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{ $t('modules.communities.table.goToPage') }}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'SuperTable',
        props: {
            data: Array,
            headers: Array,
            permissions: {
                debt_inscription: false,
                confirm_preregistration: false,
                revert_enrollment: false,
                change_modality: false,
                payment_adjustment: false,
                allow_reenrollment: false,
            },
        },
        data() {
            return {
                search: '',
                timeout: null,
                page: 1,
                itemsPerPage: 20,
                itemsPerPageAvailable: [20, 50, 100, 200],
            }
        },
        methods: {
            keyup () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.searchAction()
                    clearTimeout(this.timeout);
                },1000)
            },
            searchAction() {
                this.$emit('search-action', this.search)
            },
            canShow(action, career) {
                if (action == 'debtInscription') return this.permissions.debt_inscription && career.exception && career.careerStatus.value == 54
                else if (action == 'confirmPreregistration') return this.permissions.confirm_preregistration && career.careerStatus.value == 51
                else if (action == 'revertEnrollment') return this.permissions.revert_enrollment && career.careerStatus.value == 54
                else if (action == 'changeModality') return this.permissions.change_modality && !career.oldModality && career.careerStatus.value == 54
                else if (action == 'paymentAdjustment') return this.permissions.payment_adjustment
                else if (action == 'allowReenrollment') return this.permissions.allow_reenrollment && !career.allowReenrollment
            }
        },
        watch:{
            page(val) {
                if(val > this.pageCount) this.page = this.pageCount;
                if(val <= 1) this.page = 1;
            },
        },
        computed: {
            pageCount () {
                let counter = this.data.length / this.itemsPerPage;
                if (counter < 1) counter = 1
                return Math.ceil(counter)
            },
            itemsCount () {
                const counter = {
                    totalItems: this.data.length,
                    startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) +  1,
                    endItem: this.page * this.itemsPerPage
                }
                if ( counter.endItem > this.data.length ) counter.endItem = this.data.length
                return counter
            }
        },
    };
</script>