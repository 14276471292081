
// Import commons store

import actions from './discounts.actions'
import getters from './discounts.getters'
import mutations from './discounts.mutations'
import state from './discounts.state'

// Export

export default {

  namespaced: true,

  actions,
  getters,
  mutations,
  state

}
