import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('q-system');

export class Exams extends Api {
  constructor(http, config = {}) {
    super(http, url, 'exams', config);
  }

  /**
   * @param  {object} instance - resource to create
   * @param  {string} id
   * @param  {string} apiMethod=null
   * @param  {object} config={} - axios' configuration object
   * @return {Promise<resource>}
   */
  async saveAnswer(instance, id, apiMethod = null, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(id, apiMethod);

    return this._http.post(uri, instance, config);
  }

  async getExams(id, config = {}) {
    config = {...this._config, ...config};
    const url = this._uriConstructor(undefined,`students`)
    return this._http.get(`${url}/${id}`, {params: {}}, config)
  }

  async generateExam(id, config = {}) {
    config = {...this._config, ...config};
    const url = this._uriConstructor(undefined, 'generate-request')
    return this._http.post(`${url}?requestId=${id}`, {}, config);
  }
}
