<template>
  <v-container>
       <div v-if="$isIPP">
        <article>
            <v-list class="text--secondary body-2">
                <v-list-item-group>
                    <v-list-item-title >
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.first_item')}}
                    </v-list-item-title>
                    <v-list-item-title >
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.second_item')}}
                    </v-list-item-title>
                </v-list-item-group>
            </v-list>
        </article>
        <article class="text--secondary body-2">
            <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.next')}}</p>
            <div>
                <v-list class="text--secondary body-2">
                    <v-list-item-group>
                        <v-list-item-title class="text-wrap" >
                            <p class="mb-0">
                                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.step_one')}}
                            </p>
                            <v-list class="pl-5">
                                <v-list-item-group>
                                    <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_one')}}
                                    </v-list-item-title>
                                    <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                        {{$t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_two')}}
                                    </v-list-item-title>
                                    <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                        {{$t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_three')}}
                                    </v-list-item-title>
                                    <div class="ml-6">
                                        <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                            {{ `a. ${$t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.item_one')}` }}
                                        </v-list-item-title>
                                        <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                            {{`b. ${$t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.item_two')}`}}
                                        </v-list-item-title>
                                        <v-list-item-title class="mb-2 text--secondary body-1 text-wrap">
                                            {{`c. ${$t('modules.portalalumno.practice.stepper.dateStep.agreement.option_two.requirements.item_three')}`}}
                                        </v-list-item-title>
                                    </div>
                                </v-list-item-group>
                            </v-list>
                        </v-list-item-title>
                        <v-list-item-title class="mb-2 text-wrap">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.step_two')}}
                        </v-list-item-title>
                    </v-list-item-group>
                </v-list>
                <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.text')}}</p>
                <p>
                    {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.days1')}}
                    <a
                        style="text-decoration:none" 
                        class="primary--text"
                        :href="`mailto:${$t('modules.portalalumno.practice.stepper.dateStep.requirements.email')}`"
                      >
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.email') }}
                    </a>
                    {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.days2')}}
                </p>
            </div>
        </article>
    </div>
    <div v-else>
        <article>
            <v-list disabled>
                <v-list-item-group class="text--secondary body-2">
                    <v-list-item-title class="">
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.first_item')}}
                    </v-list-item-title>
                    <v-list-item-title class="">
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.second_item')}}
                    </v-list-item-title>
                    <v-list-item-title v-if="$isTeclab" class="">
                        {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.third_item')}}
                    </v-list-item-title>
                </v-list-item-group>
            </v-list>
        </article>
        <article>
            <h3 v-if="selectedCompany && selectedCompany == 264" class="primary--text my-3">
                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.subtitle')}}
                <span v-if="companyPractice.razonSocial"> {{ companyPractice.razonSocial }}. </span>
            </h3>
            <h3 v-else class="primary--text my-3">
                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.subtitle')}}
                <span v-if="companyPractice.razonSocial"> {{ companyPractice.razonSocial }} </span>
                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.subtitle_2')}}
            </h3>
            <p class="text--secondary body-1 mb-0">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.next')}}</p>
            <p class="text--secondary body-1">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.subtitle_steps')}}</p>
            <div>
                <v-list class="text--secondary body-1">
                    <v-list-item-group>
                        <v-list-item-title class="text-wrap">
                            <p v-if="selectedCompany && selectedCompany == 264" class="mb-0">
                                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.step_one')}}
                            </p>
                            <p v-else class="mb-0">
                                {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.step_one')}}
                            </p>
                            <v-list class="pl-5 mb-2">
                                <v-list-item-group>
                                    <v-list-item-subtitle class="text--secondary body-1 text-wrap">
                                        {{`- ${ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_one')}`}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text--secondary body-1 text-wrap">
                                        {{`-${ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_two')}`}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text--secondary body-1 text-wrap">
                                        {{ `- ${$t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_three')}`}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="text--secondary body-1 text-wrap">
                                        {{ `- ${$t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.sub_condition_four')}` }}
                                    </v-list-item-subtitle>
                                </v-list-item-group>
                            </v-list>
                        </v-list-item-title>
                        <v-list-item-title class="mb-2 text-wrap">
                            {{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.step_two')}}
                        </v-list-item-title>
                    </v-list-item-group>
                </v-list>
                <p class="text--secondary body-1">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.text')}}</p>
                <div v-if="selectedCompany && selectedCompany == 264" class="text--secondary body-1">
                    <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.days')}}</p>
                    <p class="font-weight-bold mb-0">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.important')}}</p>
                    <p class="mb-0">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.important_one')}}</p>
                    <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.otherCompany.important_two')}}</p>
                </div>
                <p v-else class="text--secondary body-1 mt-0">{{ $t('modules.portalalumno.practice.stepper.dateStep.assignCompany.option_one.actualCompany.days')}}</p>
            </div>
        </article>
    </div>
  </v-container>
</template>

<script>
export default {
    name: 'AssignCompany',
    props: {
        selectedCompany: {type: Number},
        companyPractice: {type: Object},
    },
    data(){
        return {
            // selectedCompany: 265
        }
    }

}
</script>

<style>

</style>