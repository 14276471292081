<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.communities.table.breadcrumbs.title')"
          :subtitle="$t('modules.communities.table.breadcrumbs.subtitle')"
        >
          <Button
            @clicked="newCommunity"
            :icon="'mdi-plus'"
            :text="$t('actions.new_female')"
            depressed
            v-if="canCreateCommunity"
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :items="communities"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdateCommunity, delete: canDeleteCommunity}"
              @bulk-delete="(items)=>bulkDeletePopUp(items)"
              @delete="(id)=>deletePopUp(id)"
              @update="(id)=>update(id)"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>

  import SuperTable from '../Components/SuperTable/SuperTable';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import Button from '@/components/Button/Button';

  import { $communities } from '../Services';

  export default {
    name: 'CommunitiesList',
    components:{
      SuperTable,
      Breadcrumbs,
      Button
    },
    data(){return {
      headers: [
        {
          text: this.$t('modules.communities.table.headers.company'),
          value: 'name',
        },
        {
          text: this.$t('modules.communities.table.headers.activity'),
          value: 'activity',
        },
        {
          text: this.$t('modules.communities.table.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.communities.table.headers.benefit'),
          value: 'benefit',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      communities: [],
      clearSelected: '0',
    }},
    computed: {
      canCreateCommunity() {
        return this.$permissions.portaladministrativo.prices.communities.CREATE
      },
      canUpdateCommunity() {
        return this.$permissions.portaladministrativo.prices.communities.READ || this.$permissions.portaladministrativo.prices.communities.UPDATE
      },
      canDeleteCommunity() {
        return this.$permissions.portaladministrativo.prices.communities.DELETE
      },
    },
    methods: {
      deletePopUp(id){
        const community = this.communities.find(item => item.id == id);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.communities.table.actions.delete'),
          content: [{
            value: community.name
          }],
          actionPrimary: {text: this.$t('actions.accept'), callback: () => this.delete(id)},
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        })
      },
      async fetchData() {
        const {data} = await $communities.find(null, null, {params: {page:0, length: 500, orderBy: 'id', orientation: 'desc'}});
        this.communities = data.content.map(item => {
          const community = {
            id: item.id,
            name: item.name,
            activity: item.activity,
            status: item.status,
            benefit: item.benefit.description
          };
          return community;
        });
      },
      bulkDeletePopUp(communities) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.communities.table.actions.delete'),
          content: communities.map(item => ({
            value: item.name
          })),
          actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(communities)},
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {}
          },
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        })
      },
      async bulkDelete(communities) {
        const itemsToDelete = communities.map((item) => {
          return {id: item.id}
        });

        try {
          await $communities.bulkDelete(itemsToDelete);
        }
        catch(e) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.communities.table.actions.errorDelete'),
            content: '',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            actionSecondary: null,
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          });
        }
        await this.fetchData();
      },
      newCommunity() {
        if (this.canCreateCommunity) this.$router.push('communities/create')
      },
      update(id) {
        if (this.canUpdateCommunity) this.$router.push(`communities/update/${id}`)
      },
      async delete(id) {
        try {
          await $communities.delete(id);
        }
        catch(e) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.communities.table.actions.errorDelete'),
            content: '',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            actionSecondary: null,
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          });
        }
        this.fetchData();
      }
    },
    mounted() {
      this.fetchData();
    }
  }
</script>
