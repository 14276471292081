<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6" v-if="!createPaymentIntents">
        <Breadcrumbs 
          :title="$t('modules.paymentIntents.breadcrumbs.title')"
          :description="$t('modules.paymentIntents.breadcrumbs.description')"
        />
        
        <v-row justify="center">
          <v-col class="viewSpaces" cols ="12">
            <OutlinedCard
              :title="$t('modules.paymentIntents.card.title')"
              class="mb-6"
            >
              <v-row justify="center" class="mt-8">
                <MultipleFormGroup
                  :items="$v.groups.$each.$iter"
                  :length="groups.length"
                  @addGroup="addGroup"
                  @removeGroup="removeGroup"
                >
                  <template v-slot:default="{item}">
                    <v-container fluid class="pa-0">
                      <v-row>
                        <v-col class="pb-0" sm="5">
                          <v-text-field
                            @keypress="$validateIntegerInputNumber($event, item.attemptNumber.$model, 200, null, 1)"
                            :label="` ${$t('modules.paymentIntents.input.try')} *`"
                            v-model="item.attemptNumber.$model"
                            outlined
                            @blur="item.attemptNumber.$touch()"
                            :error-messages="(item.attemptNumber.$dirty && item.attemptNumber.$invalid)
                              ? ( (!item.attemptNumber.required) 
                                ? $t('modules.commissions.prizes.create.validations.percentageRequired') 
                                : (!item.attemptNumber.minValue) 
                                  ? $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '1', beMax:'15'}) 
                                  : $t('modules.commissions.prizes.create.validations.percentageUnique'))
                              : '' "
                            append-icon=""
                          ></v-text-field>
                        </v-col>
                        <v-col class="pb-0" sm="5">
                          <v-text-field
                            @keypress="$validateIntegerInputNumber($event,item.paymentAmount.$model, 10000, null, 1)"
                            :label="`${$t('modules.paymentIntents.input.pay')} *`"
                            v-model="item.paymentAmount.$model"
                            outlined
                            @blur="item.paymentAmount.$touch()"
                            :error-messages="(item.paymentAmount.$error && item.paymentAmount.$dirty)
                            ? (!item.paymentAmount.required && item.paymentAmount.minValue
                              ? $t('modules.commissions.prizes.create.validations.percentageRequired') 
                              : $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '1', beMax:'10000'}) )
                            : ''"
                          >
                            <template v-slot:prepend>
                              <span>{{ $countryConstants.currency }}</span>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>

                </MultipleFormGroup>
              </v-row>
            </OutlinedCard>
          </v-col>
        </v-row>
        
        <v-row justify="end" class="viewSpaces">
          <Button
            :text="$t('actions.save')"
            @clicked="updatePopUp"
            @end="resetButtonValues"
            :loading="createButton.loading"
            :error="createButton.error"
            :success="createButton.success"
            :errorText="'Error'"
            :successText="$t('actions.saved')"
            :disabled="!canUpdate"
          />
        </v-row>

      </v-container>
      <SuccessMessage 
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </v-container>
</template>

<script>
  import { $examAttemps } from '../Services';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
  import Button from '@/components/Button/Button';
  import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
  import { required } from 'vuelidate/lib/validators';
  export default {
    name: 'PaymentIntentsView',
    components:{
      Breadcrumbs,
      OutlinedCard,
      SuccessMessage,
      Button,
      MultipleFormGroup
    },
    data() {
      return {
        createPaymentIntents: false,
        successMessage: {
          title: '',
          actionPrimary: '',
          actionSecondary: '',
        },
        groups: [
          {
            attemptNumber: null,
            paymentAmount: null,
          }
        ],
        ranges: [
          {
            description: null,
            menu_from: false,
            menu_to: false,
            from: null,
            to: null,
          }
        ],
        createButton: {
          loading: false,
          success: false,
          error: false
        },
      }
    },
    computed: {
      canUpdate() {
        return this.$permissions.portaladministrativo.prices.paymentIntents.UPDATE
      }
    },
    mounted () {
      this.getValues()
    },
    methods: {
      addGroup () {
        this.groups.push({ attemptNumber: null, paymentAmount: null })
      },
      removeGroup (index) {
        this.groups.splice(index, 1)
      },
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      async setValues(){
        try {
          this.createButton.loading = true
          await $examAttemps.create(this.groups)
          this.successMessage.type = 'success',
          this.successMessage.title = this.$t('modules.paymentIntents.messages_success.title')
          this.successMessage.actionPrimary = {text: this.$t('modules.paymentIntents.messages_success.primaryBottom'), callback: () => { this.createPaymentIntents = false; } };
          this.successMessage.actionSecondary = { text: this.$t('actions.return'), callback: () => { this.$router.go(-1) } };
          this.createButton.success = true
        } catch (error) {
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.paymentIntents.messages_error.title');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createPaymentIntents = false } }
          this.createButton.success = true
          throw error
        } finally {
          this.createPaymentIntents = true
          this.createButton.loading = false
        }
      },
      async getValues(){
        const res = await $examAttemps.find()
        if (res.data.length) this.groups = res.data
      },
      updatePopUp() {
        if (this.$v.$invalid) return this.$v.$touch()
        this.$store.dispatch('commons/openPopUp', {
          title: '¿Esta seguro de modificar?',
          actionPrimary: {text: 'Aceptar', callback: () => this.setValues()},
          actionSecondary: {text: 'Cancelar', callback() {}},
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary'
        });
      },
    },
    validations: {
      groups: {
        $each:{
          attemptNumber: {
            required,
            isUnique(value){
              return this.groups.filter(e => e.attemptNumber == value).length == 1
            },
            minValue(value) {
              return value > 0 && value <= 15
            }
          },
          paymentAmount:{ 
            required,
            minValue(value) {
              return value > 0 && value <= 10000
            }      
          },
        }
      }
    }
  }
</script>
