<template>
  <v-container class="pt-0" fluid>
    <v-container class="container-custom" fluid>
      <v-row justify="center" no-gutters>
        <v-col sm="10">
          <Breadcrumbs
            :description="$t('modules.settings.sms.breadcrumbs.description')"
            :title="$t('modules.settings.sms.breadcrumbs.title')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="10">
          <template v-if="initialized">
            <v-tabs class="settingsTabs" height="68px" hide-slider>
              <v-tab class="text-capitalize">
                <div class="tab-container">
                  <span class="tab-title">
                    {{ $t('modules.settings.sms.tabs.advanced.title') }}
                  </span>
                </div>
              </v-tab>
              <v-tab class="text-capitalize">
                <div class="tab-container">
                  <span class="tab-title">{{ $t('modules.settings.sms.tabs.test.title') }}</span>
                </div>
              </v-tab>
              <!-- (Hasta el 20/01/2020)
              <v-tab
                class="text-capitalize body-2"
              >{{ $t('modules.settings.sms.tabs.template.title') }}</v-tab>
              -->
              <v-tab-item class="mt-0">
                <div class="d-flex flex-row align-center">
                  <OutlinedCard :removeBorderTop="true" class="mb-6">
                    <v-expansion-panels accordion>
                      <v-expansion-panel :key="index" class="mb-3" v-for="(cfg, index) in configs">
                        <v-expansion-panel-header class="title font-weight-bold">
                          <v-row>
                            <v-col sm="8">
                              {{$t('modules.settings.sms.tabs.advanced.items.'+lw(cfg.nameService)+'.title')}}
                            </v-col>
                            <v-col sm="2">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on">mdi-help</v-icon>
                                </template>
                                <span>{{ $t('modules.settings.tooltips.sms.'+lw(cfg.nameService)+'.help') }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col sm="2">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-switch
                                    @change="changeConfigField(cfg.status, cfg.id, 'status')"
                                    @click.native.stop
                                    class="ma-0"
                                    color="primary darken-3"
                                    hide-details
                                    v-model="cfg.status"
                                    v-on="on"
                                  ></v-switch>
                                </template>
                                <span
                                  v-if="cfg.status"
                                >{{ $t('modules.settings.tooltips.sms.disable') }}</span>
                                <span v-else>{{ $t('modules.settings.tooltips.sms.enable') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <span
                            class="caption"
                          >{{$t('modules.settings.sms.tabs.advanced.items.'+lw(cfg.nameService)+'.subtitle')}}</span>
                          <v-row class="mb-2 mt-4" no-gutters>
                            <v-col class="pt-2" sm="4">
                              <span
                                class="caption mt-3 text-uppercase"
                              >{{ $t('modules.settings.sms.tabs.advanced.items.'+lw(cfg.nameService)+'.phone') }}</span>
                            </v-col>
                            <v-col class="ml-4 d-flex flex-row" sm="6">
                              <TelephoneInput
                                :error="$v[cfg.nameService].phoneNumber.$invalid"
                                @validate="(val) => changeConfigField(val, cfg.id,'phoneNumber')"
                                placeholder
                                v-model="cfg.phoneNumber"
                              ></TelephoneInput>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    class="ml-4 mb-8 text--primary"
                                    v-on="on"
                                  >mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span>{{ $t('modules.settings.tooltips.sms.'+lw(cfg.nameService)+'.help_phone') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <h4>Ajustes de conexión</h4>
                          <v-row class="pt-8" no-gutters>
                            <v-col class="pt-2" sm="4">
                              <span
                                class="caption text-uppercase"
                              >{{ $t('modules.settings.sms.tabs.advanced.items.'+lw(cfg.nameService)+'.sidAccount') }}</span>
                            </v-col>
                            <v-col class="ml-4 d-flex flex-row" sm="6">
                              <v-text-field
                                :error="$v[cfg.nameService].sidAccount.$invalid"
                                @input="changeConfigField(cfg.sidAccount, cfg.id, 'sidAccount')"
                                outlined
                                v-model="cfg.sidAccount"
                              ></v-text-field>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    class="ml-4 mb-8 text--primary"
                                    v-on="on"
                                  >mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span>{{ $t('modules.settings.tooltips.sms.'+lw(cfg.nameService)+'.help_sid_account') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0" no-gutters>
                            <v-col class="pt-2" sm="4">
                              <span
                                class="caption text-uppercase"
                              >{{ $t('modules.settings.sms.tabs.advanced.items.'+lw(cfg.nameService)+'.token') }}</span>
                            </v-col>
                            <v-col class="d-flex flex-row ml-4" sm="6">
                              <v-text-field
                                :error="$v[cfg.nameService].authToken.$invalid"
                                @input="changeConfigField(cfg.authToken, cfg.id, 'authToken')"
                                outlined
                                v-model="cfg.authToken"
                              ></v-text-field>
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    class="ml-4 mb-8 text--primary"
                                    v-on="on"
                                  >mdi-help-circle-outline
                                  </v-icon>
                                </template>
                                <span>{{ $t('modules.settings.tooltips.sms.'+lw(cfg.nameService)+'.help_token') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <!-- aca -->
                  </v-expansion-panels>
                  </OutlinedCard>
                </div>
                <div class="mt-1 text-sm-right">
                  <!--Add Events-->
                  <Button
                    :disabled="!canUpdate || $v.$invalid || modifications.smsConfigs.length == 0"
                    :error="error"
                    :loading="loading"
                    :success="success"
                    :successText="$t('actions.saved')"
                    :text="$t('actions.save')"
                    @clicked="save()"
                    @end="resetButtonValues()"
                    errorText="Error"
                  ></Button>
                </div>
              </v-tab-item>
              <!--SMS test-->
              <v-tab-item class="mt-0">
                <div class="d-flex flex-row align-center">
                  <OutlinedCard
                    :removeBorderTop="true"
                    :subtitle="$t('modules.settings.sms.tabs.test.items.subtitle')"
                    :title="$t('modules.settings.sms.tabs.test.items.title')"
                  >
                    <OutlinedCardContent :label="$t('modules.settings.sms.tabs.test.items.phone')">
                      <TelephoneInput
                        @validate="(val) => telephoneValidation(val)"
                        placeholder
                      ></TelephoneInput>
                    </OutlinedCardContent>
                    <OutlinedCardContent
                      :label="$t('modules.settings.sms.tabs.test.items.adminMessage')"
                    >
                      <v-text-field
                        :error-messages="validate('message')"
                        class="ma-auto"
                        outlined
                        v-model="$v.message.$model">
                      </v-text-field>
                    </OutlinedCardContent>
                  </OutlinedCard>
                </div>
                <div class="mt-1 text-sm-right">
                  <!--Add Events-->
                  <Button
                    :disabled="!canUpdate || $v.smsTest.$invalid"
                    :error="error"
                    :loading="loading"
                    :success="success"
                    :successText="$t('actions.sent')"
                    :text="$t('actions.send')"
                    @clicked="sendSMSTest()"
                    errorText="Error"
                  ></Button>
                </div>
              </v-tab-item>
              <!--Templates-->
              <!-- (Hasta el 20/01/2020)
              <v-tab-item class="mt-4">
                <OutlinedCard
                  :title="$t('modules.settings.sms.tabs.template.items.title')"
                  :subtitle="$t('modules.settings.sms.tabs.template.items.subtitle')"
                >
                  <v-textarea
                    :value="_self['settings/getAllCurrentValues']['smsTemplate']"
                    @change="(val) => changeTemplateValue(val)"
                    outlined
                    class="pa-0"
                  ></v-textarea>
                </OutlinedCard>
                <div class="mt-3 text-sm-right">

                  <Button
                    :loading="loading"
                    :success="success"
                    :error="error"
                    :text="$t('actions.accept')"
                    :successText="$t('actions.accepted')"
                    errorText="Error"
                    @clicked="save()"
                    @end="resetButtonValues()"
                  ></Button>
                </div>
              </v-tab-item>
              -->
            </v-tabs>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style lang="sass">
  .settingsTabs
    .v-expansion-panels
      margin-left: 2px

      .v-expansion-panel
        border-radius: 5px !important

    .container .container--fluid
      padding: 0px !important

    .container
      padding-top: 0px

    .v-tabs-bar
      top: 4px

    .v-card__text
      padding-left: 0px

    .v-tabs-slider-wrapper
      color: #FFFFFF
      border-radius: 10px 10px 0 0

    .v-tab
      &:first-child
        .tab-container
          border-left: none
          border-right: none

      &:last-child
        .tab-container
          border-left: none
          border-right: none

      border-radius: 5px 5px 0 0
      margin-right: 0px
      color: #7e7e7e !important
      background-color: #f5f5f5
      border-bottom: solid 4px #c2c2c2
      width: 50%
      height: 68px
      max-width: initial
      padding: 0px
      font-weight: initial

      .tab-container
        min-height: 20px
        min-width: 100% !important
        border-left: 0.5px solid grey
        border-right: 0.5px solid grey

      .v-slide-group__wrapper
        margin-left: 12px

    .v-tab--active
      background-color: #FFFFFF
      opacity: 1.0
      font-weight: bold
      border-top: solid 1px #c2c2c2
      border-right: solid 1px #c2c2c2
      border-left: solid 1px #c2c2c2
      border-bottom: solid 1px #FFFFFF
      color: #7e7e7e !important
      border-radius: 5px 5px 0 0

      .tab-container
        border-left: none
        border-right: none

        .tab-title
          padding: 20px 0px
          border-bottom: 5px solid #0b22ff

</style>

<script>
  import {required} from 'vuelidate/lib/validators'
  import settingsService from '@/plugins/settings.service'

  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
  import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
  import TelephoneInput from '@/components/TelephoneInput/TelephoneInput'

  import Button from '@/components/Button/Button'

  /* eslint-disable */
  import {mapGetters, mapActions} from "vuex";
  /* eslint-enable */

  export default {
    components: {
      Breadcrumbs,
      OutlinedCard,
      OutlinedCardContent,
      Button,
      TelephoneInput
    },
    data: () => ({

      //SMS test
      telephone: null,
      message: null,


      loading: false,
      initialized: false,
      success: false,
      error: false,

      configs: [],

      modifications: {
        smsConfigs: [],
      },
      // validation data
    }),
    methods: {

      validate(inputValue) {
        let error = '';
        const that = this;

        String.prototype.toPascalCase = function () {
          return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
            return g1.toUpperCase() + g2.toLowerCase();
          });
        };

        if (this.$v[inputValue].$invalid && this.$v[inputValue].$dirty) {
          const validationParams = this.$v[inputValue].$params;

          // First validate required
          if (validationParams.required) {
            if (!that.$v[inputValue].required) {
              error = that.$t(`modules.settings.sms.tabs.test.validations.${inputValue}Required`);
            }
          }

          const paramsToArray = Object.values(validationParams);

          paramsToArray.forEach(param => {
            if (param.type !== 'required') {
              if (!that.$v[inputValue][param.type]) {
                error = that.$t(`modules.settings.sms.tabs.test.validations.${inputValue + (param.type).toPascalCase()}`)
              }
            }
          })
        } else {
          error = '';
        }
        return error;
      },

      telephoneValidation(value) {
        this.telephone = value;
      },

      lw(text) {
        return text.toLowerCase();
      },

      changeConfigField(value, id, field) {
        if (!this.canUpdate) return false
        if (field == 'status' && value) {
          this.configs.filter(item => item.id != id && item.status == true).forEach(item => {
            item.status = false;
            this.changeConfigField(false, item.id, 'status');
          });
        }
        const res = this.modifications.smsConfigs.filter(item => item.id != id || item.field != field);
        this.modifications.smsConfigs = res;

        this.modifications.smsConfigs.push({value, id, field});
        this.$store.dispatch('settings/changeValue', {field: 'smsConfigs', value: null});
      },

      changeTemplateValue(value) {
        if (!this.canUpdate) return false
        this.modifications.smsTemplate = value;
        this.$store.dispatch('settings/changeValue', {field: 'smsTemplate', value: null})
      },

      save() {
        if (!this.canUpdate) return false
        this.loading = true;

        if (this.modifications.smsConfigs.length > 0) {
          this.modifications.smsConfigs.forEach((modification) => {
            this.$store.dispatch('settings/updateIndividualSMSConfig', modification)
              .catch(() => {
                this.error = false;

              })
          })
        }

        if (this.modifications.smsTemplate) {
          // Modification template only.
          this.$store.dispatch('settings/updateState', {smsTemplate: this.modifications.smsTemplate})
            .catch(() => {
              this.error = false;

            })
        }

        this.$store.dispatch('settings/updateAPI')
          .then(() => {
            this.loading = false;
            this.success = true;
            this.$store.dispatch('settings/cleanTrash');
            setTimeout(() => this.modifications.smsConfigs = [], 2500);
          })
          .catch(() => {
            this.error = false;
          })

      },
      resetButtonValues() {
        this.loading = false;
        this.success = false;
        this.error = false;
      },

      sendSMSTest() {
        if (!this.canUpdate) return false
        const data = {
          cellphone: this.telephone,
          message: this.message
        };
        this.loading = true;
        settingsService.sendSMSTest(data)
          .then(() => {
            this.success = true;
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
    computed: {
      canUpdate() {
        return this.$permissions.settings.communication.UPDATE
      },
      ...mapGetters([
        'settings/getAllCurrentValues'
      ])
    },
    validations: {

      telephone: {required},
      message: {required},
      smsTest: ['telephone', 'message']

    },
    mounted() {

      const _self = this;
      settingsService.getAllData()
        .then((result) => {
          _self.configs = result.smsConfigs;

          result.smsConfigs.forEach((el) => {
            _self.$v[el.nameService] = {
              phoneNumber: {required},
              sidAccount: {required},
              authToken: {required}
            }
          });
          return result;
        })
        .then(() => _self.initialized = true);
    }
  }
</script>
