var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 pb-8",attrs:{"fluid":""}},[(!_vm.updateNewReferredCompanyStatus)?_c('v-container',{staticClass:"container-custom"},[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.pricing.referred_companies.update.breadcrumbs.title'),"description":_vm.$t('modules.pricing.referred_companies.update.breadcrumbs.description')}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.pricing.referred_companies.update.codeSection.title'),"subtitle":_vm.$t('modules.pricing.referred_companies.update.codeSection.subtitle'),"switch-label":(_vm.status) ? _vm.$t('enabled') : _vm.$t('disabled'),"switch-value":_vm.status,"switcher":""},on:{"switchChange":(val) => {this.status = val}}},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{ref:"name",attrs:{"error-messages":(_vm.$v.name.$dirty && _vm.$v.name.$invalid) ?
                ((!_vm.$v.name.required) ? _vm.$t('modules.pricing.referred_companies.validations.nameRequired') :
                (!_vm.$v.name.alphabeticWithSpaces) ? _vm.$t('modules.pricing.referred_companies.validations.nameAlphabeticWithSpaces') :
                _vm.$t('modules.pricing.referred_companies.validations.nameMaxLength')) :
                '',"label":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.name')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.name.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.name.$model,60, true, true)}},model:{value:(_vm.$v.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.name, "$model", $$v)},expression:"$v.name.$model"}})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##-########-#'),expression:"'##-########-#'"}],ref:"cuit",attrs:{"loading":_vm.loadingCuit,"error-messages":(_vm.$v.cuit.$dirty && _vm.$v.cuit.$invalid) ?
                ((!_vm.$v.cuit.required) ? _vm.$t('modules.pricing.referred_companies.validations.cuitRequired') :
                (!_vm.$v.cuit.cuitFormat) ? _vm.$t('modules.pricing.referred_companies.validations.cuitFormat') :
                _vm.$t('modules.pricing.referred_companies.validations.cuitUnique')) :
                '',"label":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.cuit')} *`,"outlined":""},on:{"blur":_vm.validateCuit},model:{value:(_vm.$v.cuit.$model),callback:function ($$v) {_vm.$set(_vm.$v.cuit, "$model", $$v)},expression:"$v.cuit.$model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{ref:"contactName",attrs:{"error-messages":(_vm.$v.contactName.$dirty && _vm.$v.contactName.$invalid) ?
                ((!_vm.$v.contactName.required) ? _vm.$t('modules.pricing.referred_companies.validations.contactNameRequired') :
                (!_vm.$v.contactName.alphabeticWithSpaces) ? _vm.$t('modules.pricing.referred_companies.validations.contactNameAlphabeticWithSpaces') :
                _vm.$t('modules.pricing.referred_companies.validations.contactNameMaxLength')) :
                '',"label":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.contactName')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.contactName.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.contactName.$model,60, true, true)}},model:{value:(_vm.$v.contactName.$model),callback:function ($$v) {_vm.$set(_vm.$v.contactName, "$model", $$v)},expression:"$v.contactName.$model"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{ref:"position",attrs:{"error-messages":(_vm.$v.position.$dirty && _vm.$v.position.$invalid) ?
                ((!_vm.$v.position.required) ? _vm.$t('modules.pricing.referred_companies.validations.positionRequired') :
                (!_vm.$v.position.alphabeticWithSpaces) ? _vm.$t('modules.pricing.referred_companies.validations.positionAlphabeticWithSpaces') :
                _vm.$t('modules.pricing.referred_companies.validations.positionMaxLength')) :
                '',"label":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.position')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.position.$touch()},"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.position.$model,60, true, true)}},model:{value:(_vm.$v.position.$model),callback:function ($$v) {_vm.$set(_vm.$v.position, "$model", $$v)},expression:"$v.position.$model"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{ref:"email",attrs:{"error-messages":(_vm.$v.email.$dirty && _vm.$v.email.$invalid) ?
                ((!_vm.$v.email.required) ? _vm.$t('modules.pricing.referred_companies.validations.emailRequired') :
                _vm.$t('modules.pricing.referred_companies.validations.emailFormat')) :
                '',"label":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.email')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", $$v)},expression:"$v.email.$model"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('TelephoneInput',{ref:"cellphone",attrs:{"value":_vm.$v.cellphone.$model,"required":_vm.$v.cellphone.$dirty && _vm.$v.cellphone.$invalid,"placeholder":`${_vm.$t('modules.pricing.referred_companies.update.codeSection.labels.cellphone')} *`},on:{"update:value":function($event){return _vm.$set(_vm.$v.cellphone, "$model", $event)},"blur":function($event){return _vm.$v.cellphone.$touch()},"validate":_vm.isValidPhone}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"mr-4",attrs:{"text":_vm.$t('actions.cancel'),"white":""},on:{"clicked":function($event){return _vm.$router.replace('/pricing/referred-companies')},"end":_vm.resetButtonValues}}),_c('Button',{attrs:{"loading":_vm.updateButton.loading,"disabled":!_vm.canUpdate,"text":_vm.$t('actions.save'),"success":_vm.updateButton.success,"successText":_vm.$t('actions.saved'),"error":_vm.updateButton.error,"errorText":_vm.$t('actions.error')},on:{"clicked":_vm.updateReferredCompany,"end":_vm.resetButtonValues}})],1)],1)],1)],1):_c('SuccessMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }