<template>
    <v-container fluid class="pa-0 pb-8 dashboard fullHeight d-flex">
        <v-container class="container-custom viewSpaces d-flex flex-column">
            <v-row>
                <v-col cols="12" >
                    <TemporaryRightDrawer
                        :open="open"
                        @tmpDrawerOpen="open = false"
                    >
                        <ReportsDownload
                            @closeDrawer="open= false"
                            :lastReports="reports"
                        />
                    </TemporaryRightDrawer>
                    <Filters
                        @updateFilters="filters = $event"
                        @updatePrizes="prizes = $event"
                        :showCard='topFive'
                        @getCommissionReports="getCommissionReports"
                    ></Filters>
                </v-col>
                <v-col cols="12" v-show='topFive'>
                    <Progress :filters="filters" :prizes="prizes" />
                </v-col>   
                <v-col cols="12" >
                    <Aranceles :filters='filters' @toggle-career="topFive=$event"></Aranceles>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Filters from '../Components/Filters.vue'
import Progress from '../Components/Progress.vue'
import Aranceles from '../Components/Aranceles'
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import ReportsDownload from '#/portaladministrativo/Reports/Views/ReportsDownload';
import { $student } from '../Services/index'
import {mapGetters} from 'vuex'
    export default {
        components: { 
            Filters,
            Progress,
            Aranceles,
            TemporaryRightDrawer,
            ReportsDownload
        },
        name: 'DashboardView',
        data () {
            return {
                filters: null,
                prizes: null,
                topFive:true,
                open: false,
                reports: null
            }
        },
        computed: {
            ...mapGetters({
                userId: 'commons/getUserID',
            }),
        },
        methods: {
            async getCommissionReports(){
                this.open = true
                try {
                    const data = await $student.find('getFilesNames', null, {params: {userId: this.userId, commission: true}})
                    this.reports = data.data
                } catch(error){
                    this.reports = null
                    throw error
                }
            }
        }
    }
</script>

