import SanctionsTable from '../Views/SanctionsTable'
import SanctionsCreate from '../Views/SanctionsCreate'
import SanctionDetail from '../Views/SanctionDetail'
import SanctionsUpdate from '../Views/SanctionUpdate'
import SanctionAssignedTable from '../Views/SanctionAssignedTable'
import SanctionAssign from '../Views/SanctionAssign'
import SanctionAssignedUpdate from '../Views/SanctionAssignedUpdate';

const SanctionsRoute =
    [
        {
            path: '',
            component: SanctionsTable,
            meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'sanctions', permission: 'READ'}
          },
        {
            path: 'create',
            component: SanctionsCreate,
            meta: { name: 'Create', group: 'portaladministrativo.academic', path: 'sanctions', permission: 'CREATE' }
          },
        {
            path: 'update/:id',
            component: SanctionsUpdate,
            meta: { name: 'Update', group: 'portaladministrativo.academic', path: 'sanctions', permission: 'READ' }
          },
        {
            path: 'details/:id',
            component: SanctionDetail,
            meta: { name: 'Detail', group: 'portaladministrativo.academic', path: 'sanctions', permission: 'READ' }
          },
        {
          path: 'assigned',
          component: SanctionAssignedTable,
          meta: {name: 'Assign', group: 'portaladministrativo.academic',path: 'sanctions', permission: 'ASSIGNED_READ'}
          },
        {
          path: 'assigned/create',
          component: SanctionAssign,
          meta: {name: 'Assign', group: 'portaladministrativo.academic',path: 'sanctions', permission: 'ASSIGNED_CREATE'}
          },
        {
          path: 'assigned/update/:id',
          component: SanctionAssignedUpdate,
          meta: {name: 'Update', group: 'portaladministrativo.academic',path: 'sanctions', permission: 'ASSIGNED_READ'}
         }
    ];


export default SanctionsRoute;
