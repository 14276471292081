import api from '@/api';
import getRawDocument from './getRawDocument';

const { $student, $pricing, $core, $academic, $billing } = api;


export const { $approvedSubjects, $comparePrices } = $student;

const {$procedures} = $student
Object.defineProperties($procedures,{getRawDocument:{value:getRawDocument}})

export {$procedures}
export const { $types } = $pricing;
export const { $processings } = $core;
export const { $careers } = $academic;
export const { $receipt } = $billing
