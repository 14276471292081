import Exceptions from '../Exceptions.vue'
import ExceptionsTable from '../Views/ExceptionsTable.vue'
import ExceptionsPaymentsTable from '../Views/ExceptionsPaymentsTable.vue'

const ExceptionsRoute = {
    path: '/exceptions',
    meta: {
        name: {
            en: 'Exceptions',
            es: 'Excepciones'
        }
    },
    component: Exceptions,
    children: [{
        path: '/',
        component: ExceptionsTable,
        meta: { name: 'Index', group: 'portaladministrativo', path: 'exceptions', permission: 'READ' }
    },{
        path: 'payments/:studentRecord',
        component: ExceptionsPaymentsTable,
        meta: { name: 'Excepciones', group: 'portaladministrativo', path: 'exceptions', permission: 'PAYMENT_ADJUSTMENT' }
    }]
};

export default ExceptionsRoute
