<template>
  <v-container >
    <template >
        <Breadcrumbs
        :title="$t('modules.procedures.title.breadcrumbs.title')"
        ></Breadcrumbs>
    </template>
    <template class="container-custom fullHeight d-flex align-center justify-center">
        <title-component :title="title" :description="description" :descriptionBold="descriptionBold"></title-component>
        <v-row>
          <v-col sm="10" class="d-flex flex-column mx-auto">
          <OutlinedCard
                :title="$t('modules.procedures.raicProcedure.dataSection.title')"
                :subtitle="$t('modules.procedures.raicProcedure.dataSection.subtitle')"
                class="mb-6"
              >
                 <v-row>
                  <v-col sm="12">
                     <v-textarea
                      outlined
                      :error="$v.raicProcedure.reason.$anyError"
                      :label="$t('modules.procedures.raicProcedure.dataSection.reason')"
                      v-model.trim="$v.raicProcedure.reason.$model"
                      @blur="$v.raicProcedure.reason.$touch()"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <div class="d-flex justify-end mb-12">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="goBack"
                  @end="resetButtonValues"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :disabled="!canCreate"
                  :text="$t('actions.apply')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  @end="resetButtonValues"
                  @clicked="createXXX"
                ></Button>
              </div>
            </v-col>
        </v-row>
    </template>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TitleComponent from '@/components/TitleComponent/TitleComponent';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/Button/Button';

export default {
  name: 'RaicProcedure',
  components: {
      Breadcrumbs,
      OutlinedCard,
      TitleComponent,
      Button
  },
  validations: {
    raicProcedure: {
      reason: {
        required
      }
    }
  },
  computed: {
    canCreate () {
      return true
    }
  },
  data () {
    return {
        title: this.$t('modules.procedures.raicProcedure.title'),
        description: this.$t('modules.procedures.raicProcedure.description'),
        descriptionBold: this.$t('modules.procedures.raicProcedure.descriptionBold'),
        raicProcedure: {
            reason: null,
        },
        createButton: {
          loading: false,
          success: false,
          error: false
        }
    }
  },
  methods: {
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1]
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    createXXX () {
      return true
    }
  },
}
</script>
