import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class Procedures extends Api {
  
  constructor(http, config = {}) {
    super(http, url, 'studentProcessing', config);
  }

  async getDocument(id, config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, 'documentation');
    return this._http.get(`${uri}/${id}`, config);
  }

}