<template>
  <v-card>
    <v-row no-gutters class="primary">
      <v-col cols="10">
        <div class="ml-6 align-center">
          <h6 class="white--text head">{{ title }}</h6>
        </div>
      </v-col>
      <v-col cols="1">
        <!-- <div class="ml-6 align-center text-right">
            <v-btn @click="print" icon><v-icon class="white--text pointer">mdi-printer</v-icon></v-btn>
        </div> -->
      </v-col>
      <v-col cols="1">
        <div class="mr-6 align-center text-right">
          <!-- <v-btn @click="download" icon><v-icon class="white--text pointer">mdi-download</v-icon></v-btn> -->
          <a :href="src" target="_blank" download ref="downloadButton" v-show="false"></a>
        </div>
      </v-col>
    </v-row>
    <div class="embed-container" :style="{height: height}">
      <object :data="src" type="application/pdf" width="100%" height="100%"></object>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DocumentPreview',
  props: {
    title: String,
    src: String,
    height: { type: String, default: '100vh' }
  },
  data() { return {
    uniqueId: ''
  }},
  methods: {
    print() {
      const pdf = document.getElementById(this.uniqueId);
      pdf.contentWindow.focus();
      pdf.contentWindow.print();
    },
    download() {
      this.$refs.downloadButton.click();
    }
  },
  created() {
    this.uniqueId = `componentDocumentPreview-${Date.now()}${Math.round(Math.random())}`;
  }
};
</script>

<style lang="sass" scoped>
  .head
    font-size: 10px

  .pointer
    cursor: pointer

  .embed-container
    display: flex
    flex-flow: row nowrap
    justify-content: center
    align-items: stretch
  
  iframe
    border: none

</style>