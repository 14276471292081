
// Import commons store

import actions from './login.actions'
import getters from './login.getters'
import mutations from './login.mutations'
import state from './login.state'

// Export

export default {

  namespaced: true,

  actions,
  getters,
  mutations,
  state

}
