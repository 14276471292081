var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex pa-0 fullHeight",attrs:{"fluid":""}},[(!_vm.editTemplateStatus)?_c('v-container',{staticClass:"container-custom px-6"},[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.messaging.template.editMessage.breadcrumbs.title'),"description":_vm.$t('modules.messaging.template.editMessage.breadcrumbs.description')}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.messaging.template.editMessage.messageBody'),"switchLabel":_vm.formattedStatusTemplates,"switcher":"","switchValue":_vm.messageContent.status},on:{"switchChange":function($event){_vm.messageContent.status = !_vm.messageContent.status}}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","lg":"12"}},[_c('v-text-field',{ref:"title",staticClass:"mb-8",attrs:{"label":_vm.$t('modules.messaging.template.editMessage.messageTitle'),"outlined":"","required":"","error-messages":(_vm.$v.messageContent.title.$dirty && _vm.$v.messageContent.title.$invalid) ?
                ((!_vm.$v.messageContent.title.required) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.title.requerido') :

                (!_vm.$v.messageContent.title.maxLength) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.title.maxLength') :
                '') :
                ''},on:{"blur":function($event){return _vm.$v.messageContent.title.$touch()}},model:{value:(_vm.$v.messageContent.title.$model),callback:function ($$v) {_vm.$set(_vm.$v.messageContent.title, "$model", $$v)},expression:"$v.messageContent.title.$model"}}),_c('v-text-field',{ref:"subtitle",staticClass:"mb-6",attrs:{"label":_vm.$t('modules.messaging.template.editMessage.messageSubtitle'),"outlined":"","required":"","error-messages":(_vm.$v.messageContent.subtitle.$dirty && _vm.$v.messageContent.subtitle.$invalid) ?
                ((!_vm.$v.messageContent.subtitle.required) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.subtitle.requerido') :

                (!_vm.$v.messageContent.subtitle.maxLength) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.subtitle.maxLength') :
                '') :
                ''},on:{"blur":function($event){return _vm.$v.messageContent.subtitle.$touch()}},model:{value:(_vm.$v.messageContent.subtitle.$model),callback:function ($$v) {_vm.$set(_vm.$v.messageContent.subtitle, "$model", $$v)},expression:"$v.messageContent.subtitle.$model"}}),_c('div',[_c('h2',{staticClass:"mb-6 body-1"},[_vm._v(" "+_vm._s(_vm.$t('modules.messaging.template.editMessage.messageBodyHtml')))]),_c('vue-editor',{ref:"message",model:{value:(_vm.messageContent.message),callback:function ($$v) {_vm.$set(_vm.messageContent, "message", $$v)},expression:"messageContent.message"}})],1)],1)],1)],1),_c('OutlinedCard',{attrs:{"title":_vm.$t('modules.messaging.template.editMessage.messageSignature')}},[_c('v-row',{staticClass:"signature-container"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2","lg":"2"}},[_c('AvatarUpload',{attrs:{"size":_vm.formattSize,"rounded":true,"image":_vm.imagePreview,"label":_vm.$t('modules.messaging.template.editMessage.messageImage')},on:{"save":(file) => _vm.createImage(file)}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","lg":"6"}},[_c('v-text-field',{ref:"name",staticClass:"mb-6",attrs:{"label":_vm.$t('modules.messaging.template.editMessage.messageFullname'),"outlined":"","required":"","error-messages":(_vm.$v.messageContent.name.$dirty && _vm.$v.messageContent.name.$invalid) ?
                ((!_vm.$v.messageContent.name.required) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.name.requerido') :

                (!_vm.$v.messageContent.name.maxLength) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.name.maxLength') :
                '') :
                ''},on:{"blur":function($event){return _vm.$v.messageContent.name.$touch()}},model:{value:(_vm.$v.messageContent.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.messageContent.name, "$model", $$v)},expression:"$v.messageContent.name.$model"}}),_c('v-text-field',{ref:"place",staticClass:"mb-6",attrs:{"label":_vm.$t('modules.messaging.template.editMessage.messagePosition'),"outlined":"","required":"","error-messages":(_vm.$v.messageContent.place.$dirty && _vm.$v.messageContent.place.$invalid) ?
                ((!_vm.$v.messageContent.place.required) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.place.requerido') :

                (!_vm.$v.messageContent.place.maxLength) ? _vm.$t('modules.messaging.template.createMessage.validations.messageContent.place.maxLength') :
                '') :
                ''},on:{"blur":function($event){return _vm.$v.messageContent.place.$touch()}},model:{value:(_vm.$v.messageContent.place.$model),callback:function ($$v) {_vm.$set(_vm.$v.messageContent.place, "$model", $$v)},expression:"$v.messageContent.place.$model"}})],1)],1)],1)],1),_c('OutlinedCard',{attrs:{"title":_vm.$t('modules.messaging.template.editMessage.destinatarios.title'),"subtitle":_vm.$t('modules.messaging.template.editMessage.destinatarios.subtitle')}},[_c('div',{staticClass:"mx-5 d-flex justify-end"},[_c('a',{attrs:{"href":"/templates/envio-mensajeria.xlsx","download":""}},[_c('Button',{staticClass:"mb-8",attrs:{"outlined":"","icon":"mdi-download","text":_vm.$t('modules.messaging.template.areaTemplates.sendMessage.download_template')}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('DropFiles',{key:_vm.dropFilesKey,staticClass:"mx-5 mt-5",attrs:{"extension":".xls,.xlsx","limitSizeBytes":"2000000","type-file":"excel"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('Button',{staticClass:"mr-4",attrs:{"depressed":"","white":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$router.go(-1)},"end":_vm.resetButtonValues}}),_c('Button',{attrs:{"loading":_vm.createButton.loading,"success":_vm.createButton.success,"error":_vm.createButton.error,"text":_vm.$t('actions.edit'),"successText":_vm.$t('actions.created'),"errorText":'Error',"color":"primary"},on:{"end":_vm.resetButtonValues,"clicked":_vm.validateMessage}})],1)],1)],1)],1):_c('SuccessMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }