const settingsTranslations = {
  es_PE: {
      appearance: {
          sectionImages: {
              portalAlumno:'IMAGEN PORTAL ESTUDIANTE'
          },
      },
  },  
  es: {
    general: {
      breadcrumbs: {
        title: 'General',
        description: 'Información para la configuración del sistema.'
      },
      sectionLanguage: {
        title: 'Idioma, fecha y hora',
        language: 'IDIOMA',
        dateFormat: 'FORMATO DE FECHA',
        timeFormat: 'FORMATO DE HORA',
        timeZone: 'ZONA HORARIA',
      },
      sectionSecurity: {
        title: 'Seguridad y Autenticación',
        maxLoginAttempts: 'INTENTOS MÁXIMOS',
        maxAttemptsHours: 'HORAS DE BLOQUEO',
      },
      validations: {
        sectionSecurity: {
          maxLoginAttemptsRequired: 'Debes ingresar un número de intentos máximos de autentificación',
          maxAttemptsHoursRequired: 'Ingresa las horas de bloqueo al superar el límite de intentos fallidos'
        }
      }
    },

    appearance: {
      breadcrumbs: {
        title: 'Apariencia',
        description: 'Información para la configuración del sistema.'
      },
      sectionColors: {
        title: 'Colores',
        primary: 'PRIMARIO',
        secondary: 'SECUNDARIO',
        accent: 'ACENTO',
      },
      sectionImages: {
        title: 'Imágenes',
        logo: 'LOGOTIPO',
        login: 'IMAGEN LOGIN',
        userDefault: 'IMAGEN USER POR DEFECTO',
        portalAdministrativo: 'IMAGEN PORTAL ADMIN',
        portalAlumno:'IMAGEN PORTAL ALUMNO',
        comisiones:'IMAGEN COMISIONES',
        examenes:'IMAGEN EXAMENES',
      },
    },

    email: {
      breadcrumbs: {
        title: 'E-Mail',
        description: 'Información para la configuración del sistema.'
      },
      tabs: {
        advanced: {
          title: 'Opciones Avanzadas',
          items: {
            mail: {
              label: 'CORREO ELECTRÓNICO DESDE'
            },
            name: {
              label: 'NOMBRE DESDE'
            },
            host: {
              label: 'HOST SMTP'
            },
            port: {
              label: 'PUERTO SMTP'
            },
            encriptationType: {
              label: 'ENCRIPTACIÓN',
              options: {
                noEncrypt: 'Sin encriptación',
                ssl: 'Usar encriptación SSL',
                tls: 'Usar encriptación TLS'
              }
            },
            authentication: {
              label: 'AUTENTICACIÓN',
              options: {
                yes: 'Si: usar autenticación SMTP',
                no: 'No: no usar autenticación SMTP'
              }
            },
            userName: {
              label: 'USUARIO'
            },
            password: {
              label: 'CONTRASEÑA'
            },
          },
          validations: {
            smtpFromRequired: 'Ingresa un email',
            smtpFromEmail: 'El mail ingresado no es válido',
            smtpNameRequired: 'Ingresar un nombre',
            smtpHostRequired: 'Ingresa el HOST. Ej. "smtp.dominio.com"',
            smtpPortRequired: 'Ingresa el puerto SMPT',
            smtpPortEmail: 'El puerto SMPT debe ser un número. Ej. "465"',
            smtpUserNameRequired: 'Ingresa el usuario',
            smtpUserNameEmail: 'El usuario debe ser un mail',
            smtpPasswordRequired: 'Ingresa la contraseña',
            emailTestRequired: 'Ingresa un correo electrónico',
            emailTestEmail: 'Ingresa un correo electrónico válido',
          }
        },
        test: {
          title: 'Envío de prueba',
          items: {
            title: 'Enviar email de ejemplo',
            email: 'CORREO ELECTRÓNICO',
          },
        },
        templates: {
          title: 'Plantilla',
          items: {
            title: 'Plantilla de mail',
          }
        }
      }
    },

    sms: {
      breadcrumbs: {
        title: 'SMS - Mensajes de texto',
        description: 'Información para la configuración del sistema.'
      },
      tabs: {
        advanced: {
          title: 'Opciones avanzadas',
          items: {
            twilio: {
              title: 'Notificaciones Twilio',
              subtitle: 'Aquí va un párrafo explicativo sobre las opciones que sis le ofrece al usuario.',
              sidAccount: 'CUENTA SID',
              token: 'TOKEN DE AUTENTICACIÓN',
              phone: 'NÚMERO TELEFÓNICO'
            },
            celcom: {
              title: 'Notificaciones Celcom',
              subtitle: 'Aquí va un párrafo explicativo sobre las opciones que sis le ofrece al usuario.',
              sidAccount: 'CUENTA SID',
              token: 'TOKEN DE AUTENTICACIÓN',
              phone: 'NÚMERO TELEFÓNICO'
            }
          },
          validations: {
            twilio: {
              phoneRequired: 'Ingresa el número de teléfono',
              sidAccountRequired: 'Ingresa la cuenta SID (Identificador de Seguridad)',
              tokenRequired: 'Ingresa el token de autenticación',
            },
            cellkromm: {
              phoneRequired: 'Ingresa el número de teléfono',
              sidAccountRequired: 'Ingresa la cuenta SID (Identificador de Seguridad)',
              tokenRequired: 'Ingresa el token de autenticación',
            }
          }
        },
        test: {
          title: 'SMS test',
          items: {
            title: 'Enviar SMS de prueba',
            subtitle: '',
            phone: 'NÚMERO TELEFÓNICO',
            adminMessage: 'ADMINISTRAR MENSAJE',
          },
          validations:{
            messageRequired:'Ingresa un mensaje para ser enviado.',
            telephoneRequired:'Ingresa un número telefónico válido.'
          }
        },
        template: {
          title: 'Templates',
          items: {
            title: 'Título explicativo',
            subtitle: 'Aquí va un texto explicativo',
          }
        }
      }
    },

    privacyPolicy: {
      breadcrumbs: {
        title: 'Política de Privacidad',
        description: 'Información para la configuración del sistema.'
      },
      title: '',
      description: 'Ingresa aquí la URL',
      fields: {
        label: {
          url: 'URL'
        },
        validations: {
          urlRequired: 'Debes ingresar una URL',
          urlUrl: 'Debes ingresar una URL válida'
        }
      }
    },

    termsConditions: {
      breadcrumbs: {
        title: 'Términos y condiciones',
        description: 'Información para la configuración del sistema.'
      },
      title: '',
      description: 'Ingresa aquí la URL',
      fields: {
        label: {
          url: 'URL'
        },
        validations: {
          urlRequired: 'Debes ingresar una URL',
          urlUrl: 'Debes ingresar una URL válida'
        }
      }
    },
    
    liquidations: {
      breadcrumbs: {
        title: 'Liquidaciones',
        description: 'Periodo para la liquidación.'
      },
  
      sectionSecurity: {
        title: 'Seguridad y Autenticación',
        startDay: 'PRIMER DIA DE LIQUIDACIÓN',
        finishDay: 'ULTIMO DIA DE LIQUIDACIÓN',
      },
      validations: {
        sectionSecurity: {
          startDayRequired: 'Debes ingresar el primer día de la liquidación',
          finishDayRequired: 'Debes ingresar el último día de la liquidación'
        }
      }
    },
    
    tooltips: {
      select_color: 'Selecciona un color',
      select_image: 'Selecciona una imagen',
      sms: {
        enable: 'Habilitar',
        disable: 'Deshabilitar',
        twilio: {
          help: 'Las notificaciones activas permitirán el envío de SMS',
          help_phone: 'Ingresa el número con código de país y área sin 0 y sin 15',
          help_sid_account: 'Ingresa el SID de la cuenta',
          help_token: 'El token debe ser alfanumérico de no más de 4 dígitos',
        },
        celcom: {
          help: 'Las notificaciones activas permitirán el envío de SMS',
          help_phone: 'Ingresa el número con código de país y área sin 0 y sin 15',
          help_sid_account: 'Ingresa el SID de la cuenta',
          help_token: 'El token debe ser alfanumérico de no más de 4 dígitos',
        }
      },
    },

    asideBar: {
    'title': 'Configuración',
    'categories': {
      'system': {
        'title': 'Sistema',
        'items': {
          'general': 'General',
          'appareance': 'Apariencia'
        }
      },
      'communications': {
        'title': 'Comunicaciones',
        'items': {
          'email': 'Correo electrónico',
          'sms': 'SMS'
        }
      },
      'legal': {
        'title': 'Asuntos legales',
        'items': {
          'privacy': 'Política de privacidad',
          'terms': 'Términos y condiciones'
        }
      },
      'commissions': {
        'title': 'Facturación',
        'items': {
          'liquidations': 'Liquidaciónes',
          'billings': 'Facturante',
        }
      }
    }
    },
    billings: {
      breadcrumbs: {
        title: 'Facturacion',
        description: 'Cuenta de facturante.'
      },
      sectionSecurity: {
        title: 'Seguridad y Autenticación',
        user: 'USUARIO',
        pass: 'CONTRASEÑA',
        company:'ID COMPAÑIA',
      },
      validations: {
        sectionSecurity: {
          user: 'Debes ingresar el usuario',
          pass: 'Debes ingresar la contraseña',
          company:'Debes ingresar el Id de la compañía',
        }
      }
    }
  },

  en: {
    general: {
      breadcrumbs: {
        title: 'General',
        description: 'Information for system configuration.'
      },
      sectionLanguage: {
        title: 'Language, Date and Time',
        language: 'LANGUAGE',
        dateFormat: 'DATE FORMAT',
        timeFormat: 'TIME FORMAT',
        timeZone: 'TIME ZONE',
      },
      sectionSecurity: {
        title: 'Security and Authentication',
        maxLoginAttempts: 'MAXIMUM ATTEMPTS',
        maxAttemptsHours: 'LOCK HOURS',
      }
    },
    appearance: {
      breadcrumbs: {
        title: 'Appareance',
        description: 'Information for system configuration.'
      },
      sectionColors: {
        title: 'Colors',
        primary: 'PRIMARY',
        secondary: 'SECONDARY',
        accent: 'ACCENT',
      },
      sectionImages: {
        title: 'Images',
        logotype: 'LOGOTYPE',
        loginImage: 'LOGIN IMAGE',
        userDefaultImage: 'USER DEFAULT IMAGE'
      }
    },
    email: {
      breadcrumbs: {
        title: 'E-Mail',
        description: 'Information for system configuration.'
      },
      tabs: {
        avanced: {
          title: 'Avanced Options',
          items: {
            mail: {
              label: 'E-MAIL TO'
            },
            name: {
              label: 'NAME TO'
            },
            host: {
              label: 'HOST SMTP'
            },
            port: {
              label: 'PORT SMTP'
            },
            encriptationType: {
              label: 'ENCRYPTION',
              options: {
                noEncrypt: 'No encryption',
                ssl: 'Use SSL encryption',
                tls: 'Use TLS encryption'
              }
            },
            authentication: {
              label: 'AUTHENTICATION',
              options: {
                yes: 'Yes: Use SMTP authentication',
                no: 'No: No use SMTP authentication'
              }
            },
            userName: {
              label: 'USERNAME'
            },
            password: {
              label: 'PASSWORD'
            },
          }
        },
        test: {
          title: 'E-Mail test',
          items: {
            title: 'Title',
            email: 'E-MAIL',
          }
        },
        templates: {
          title: 'Templates',
          items: {
            title: 'Title',
          }
        }
      }
    },
    sms: {
      breadcrumbs: {
        title: 'SMS - Text messages',
        description: 'Information for system configuration.'
      },
      tabs: {
        avanced: {
          title: 'Avanced options',
          items: {
            twilio: {
              title: 'Twilio notifications',
              subtitle: 'Explanatory text.',
              sidAccount: 'SID ACCOUNT',
              token: 'AUTHENTICATION TOKEN',
              phone: 'PHONE NUMBER'
            },
            cellkromm: {
              title: 'Cellkromm notifications',
              subtitle: 'Explanatory text.',
              sidAccount: 'SID ACCOUNTS',
              token: 'AUTHENTICATION TOKEN',
              phone: 'PHONE NUMBER'
            }
          }
        },
        test: {
          title: 'SMS test',
          items: {
            title: 'Title',
            subtitle: 'Explanatory text',
            phone: 'PHONE NUMBER',
            adminMessage: 'MESSAGE ADMINISTRATION',
          }
        },
        template: {
          title: 'Templates',
          items: {
            title: 'Title',
            subtitle: 'Explanatory text',
          }
        }
      }
    },

    privacyPolicy: {
      breadcrumbs: {
        title: 'Privacy Policy',
        description: 'Information for system configuration.'
      },
      input: {
        title: 'Title'
      }
    },

    termsConditions: {
      breadcrumbs: {
        title: 'Terms and Conditions',
        description: 'Information for system configuration.'
      },
      input: {
        title: 'Title'
      }
    },

    tooltips: {
      select_color: 'Select a color',
      select_image: 'Select an image',
      sms: {
        enable: 'Enable',
        disable: 'Disable',
        twilio: {
          help: '',
          help_phone: '',
          help_sid_account: '',
          help_token: '',
        },
        cellkromm: {
          help: '',
          help_phone: '',
          help_sid_account: '',
          help_token: '',
        }
      },
    },
  }
};

export default settingsTranslations
