const rangesTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Configuración de rangos',
                description:'Administración de rangos',
            },
            from: 'Del',
            to: 'al',
            headers: {
                id: '#',
                term: 'Periodo',
                description: 'Descripción',
                range: 'N° Rango',
                start_date: 'Desde',
                end_date: 'Hasta',
            },
            filters: {
                title: 'Refinar búsqueda',
                term: 'Periodo',
                date: 'Fecha',
                from: 'Desde',
                to: 'Hasta',
            },
            actions: {
                errorDelete: 'Error al eliminar',
                bulk_delete: 'Eliminar rangos',
            },
            messages: {
                bulk_delete_error: 'No se pudieron eliminar los rangos seleccionados',
                delete_error: 'No se pudo eliminar el rango seleccionado',
            }
        },
        create: {
            breadcrumbs: {
                title: 'Nuevo rango',
                description:'Crea uno nuevo rango de fechas',
            },
            from: 'Del',
            to: 'al',
            data: {
                title: 'Rangos',
                subtitle: 'Seleccione el periodo correspondiente para poder asociar los rangos de fecha',
                from: 'Desde',
                to: 'Hasta',
                period: 'Periodo',
                description: 'Descripción',
            },
            validations: {
                term: 'Seleccione un periodo',
                description: 'Ingrese la descripción del rango',
                to: 'Seleccione una fecha',
            },
            success_message: {
                title: 'Rango creado correctamente',
            },
            error_message: {
                title: 'Ocurrió un error al crear el rango',
            }
        },
        update: {
            breadcrumbs: {
                title: 'Editar rango',
                description: 'Modifica el rango seleccionado',
            },
            sectionData: {
                title: 'Datos del rango',
                subtitle: 'Edite la información básica del rango',
                labels: {
                    term: 'Periodo',
                    description: 'Descripción',
                    from: 'Fecha desde',
                    to: 'Fecha hasta',
                },
                validations: {
                    description: 'Ingrese una descripción del rango',
                    date: 'Seleccione una fecha valida',
                }
            },
            success_message: {
                title: 'Rango actualizado correctamente',
            },
            error_message: {
                title: 'Ocurrió un error al actualizar el rango',
            }
        },
    },
    en: {
        
    }
};


export default rangesTranslations