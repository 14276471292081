import Dashboard from '../Dashboard'
import DashboardView from '../Views/DashboardView'
import AccessDenied from '@/components/AccessDenied/AccessDenied'
import ModuleInactive from '@/components/ModuleInactive/ModuleInactive'

const DashboardRoute =
{
    path: '/',
    meta: {
        name: {
            en: 'Index',
            es: 'Inicio'
        }
    },
    component: Dashboard,
    children: [
        {
            path: '/',
            component: DashboardView,
            meta: { name: 'Dashboard' }
        },{
            path: '/access-denied',
            component: AccessDenied,
            meta: { name: 'Access denied' }
        },{
            path: '/module-inactive',
            component: ModuleInactive,
            meta: { name: 'Module inactive' }
        },
    ]
};

export default DashboardRoute
