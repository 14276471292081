const emptyStateTranslations = {
  es: {
    module_title: 'Estamos trabajando en',
    description: 'Mientras, puedes navegar por el resto de la aplicación.'
  },

  en: {
    module_title: 'We are working in',
    description: 'Meanwhile, you can navigate through the app'
  }
};


export default emptyStateTranslations
