import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('academic');

export class Careers extends Api {
  constructor(http, config = {}) {
    super(http, url, 'careers', config);
  }

  async checkIfExists(code){
    const url = this._uriConstructor(undefined,`exists`)
    return this._http.get(url, {params: {code}})
  }
}
