import Degrees from '../Degrees.vue'
import DegreesTable from '../Views/DegreesTable.vue'
import DegreesCreate from '../Views/DegreesCreate.vue'
import DegreesUpdate from '../Views/DegreesUpdate.vue'
import DegreesDetail from '../Views/DegreesDetail.vue'


const DegreesRoute =
{
  path: '/degrees',
  meta: {
    name: {
      en: 'Degrees',
      es: 'Títulos'
    }
  },
  component: Degrees,
  children: [
    {
      path: '/',
      component: DegreesTable,
      meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'degrees', permission: 'READ' }
    },
    {
      path: 'create',
      component: DegreesCreate,
      meta: { name: 'Degrees Create', group: 'portaladministrativo.academic', path: 'degrees', permission: 'CREATE' }
    },
    {
      path: 'update/:id',
      component: DegreesUpdate,
      meta: { name: 'Degrees Update', group: 'portaladministrativo.academic', path: 'degrees', permission: 'READ' }
    },
    {
      path: 'detail',
      component: DegreesDetail,
      meta: { name: 'Degrees Detail', group: 'portaladministrativo.academic', path: 'degrees', permission: 'READ' }
    },
  ]
};

export default DegreesRoute
