import exam from './exam.js';
import student from './student.js';
import academic from './academic.js';
import payment from './payment.js';

const info = {
    exam,
    student,
    academic,
    payment
}

export default info