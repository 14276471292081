<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="py-8 px-10">
          <h3>{{ $t('modules.reports.download.drawer.title') }}</h3><br>
          <p>{{ $t('modules.reports.download.drawer.description') }}</p>
          <SuperTable
            :headers="headers"
            :items="lastReports"
            @downloadReport="downloadReport"
          ></SuperTable>
        </v-card>
      </v-col>
      <v-col class="pb-8 d-flex justify-contentn-end justify-end">
        <Button
          white
          :text="$t('actions.close')"
          @clicked="$emit('closeDrawer');"
          class="mr-4"
        ></Button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SuperTable from '#/portaladministrativo/Reports/Components/SuperTable';
import Button from '@/components/Button/Button';
import {$reports} from '../Services';
import download from 'downloadjs';
import {mapGetters} from 'vuex';

export default {
  name: 'ReportsDownload',
  components: {
    SuperTable,
    Button
  },
  props: {
    lastReports: Array
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('modules.reports.download.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('modules.reports.download.headers.date'),
          sortable: false,
          value: 'date'
        },
        {
          text: this.$t('modules.reports.download.headers.period'),
          sortable: false,
          value: 'termDescription'
        },
        {
          text: this.$t('modules.reports.download.headers.status'),
          sortable: false,
          value: 'status'
        }, 
        {
          text: '',
          sortable: false,
          value: 'download'
        }
      ]
    }
  },
  methods: {
    async downloadReport(report) {
      try {
        const {headers, data} = await $reports.student.find('getReportFile', null, { params: { fileName: `${report.name}.xlsx`, userId: this.userId } })
        download(data, this.$t('modules.reports.download.fileName', { reportName: report.name }), headers.get('content-type'))
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.reports.download.error')}],
          actionPrimary: { text: this.$t('actions.accept'), callback() { }},
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        });
        throw error
      }
    }
  },
  computed: {
    ...mapGetters({
      userId: 'commons/getUserID',
    })
  }
};
</script>

<style scoped>

</style>
