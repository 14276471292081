<template>
  <div class="Summary-container sticky">
    <h2 class="primary--text mb-3">{{ $t('modules.preRegistration.summary.summary') }}</h2>
    <hr>

    <ul class="my-3 pa-0">
      <li>
        <span class="font-weight-bold">{{ resumen.items[0].title }}</span>
        <span class="font-weight-bold primary--text">{{ resumen.items[0].monto | $currency }}</span>
      </li>
      <li v-for="(item, index) in paymentItems" :key="`total-${index}`">
        <span class="text--muted-1 caption">{{ item.title }}</span>
        <span class="text--muted-2 caption">{{ item.monto | $currency }}</span>
      </li>
    </ul>

    <ul class="my-3 pa-0">
      <li>
        <span class="font-weight-bold ">{{ resumen.items[1].title }}</span>
        <span class="font-weight-bold primary--text">{{ resumen.items[1].monto | $currency }}</span>
      </li>
      <li v-for="(item, index) in discountItems" :key="`dto-${index}`">
        <span class="text--muted-1 success--text  caption">{{ item.title }}</span>
        <span class="font-weight-bold success--text caption text-no-wrap">{{ item.monto | $currency }}</span>
      </li>
    </ul>

    <v-card class="border-total mb-4" outlined>
      <ul class="my-0 pa-4">
        <li>
          <h2 class="primary--text">{{$t('modules.preRegistration.summary.total')}}</h2>
          <h2 class="primary--text">{{ resumen.total | $currency }}</h2>
        </li>
      </ul>
    </v-card>
    <div class="text-center my-2" v-if="resumen.creditCard && resumen.due"><span class="grey--text">T.E.A {{ resumen.tea }} - C.F.T {{ resumen.cft }}</span></div>
    <div class="text-center"><small class="text-center text--muted-1">{{ $t('modules.preRegistration.summary.periodPromotion') }} {{ today }}</small></div>
  </div>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'Summary',
    props: {
      resumen: Object,
    },
    data() {
      return {
        today: moment().format('DD-MM-YYYY'),
      }
    },
    computed: {
      paymentItems() {
        return this.resumen.items[0].items.filter(item => item.monto > 0)
      },
      discountItems() {
        return this.resumen.items[1].items.filter(item => item.monto > 0)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .sticky
    position: sticky !important
    top: 20px !important
    z-index: 0

  .Summary-container
    width: 100%
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.13)
    position: absolute
    top: -20px
    padding: 30px 40px
    z-index: 1

    ul
      list-style: none

      li
        display: flex
        flex-flow: row nowrap
        justify-content: space-between
        padding: 5px 0
    
    .text--muted-1
      color: rgba(0, 0, 0, 0.7)

    .text--muted-2
      color: rgba(0, 0, 0, 0.55)

    .border-total
      border-color: #6087e1 !important
      background-color: #eeeeee

</style>