import Communities from '../Communities.vue'
import CommunitiesTable from '../Views/CommunitiesTable.vue';
import CommunitiesCreate from '../Views/CommunitiesCreate.vue';
import CommunitiesUpdate from '../Views/CommunitiesUpdate.vue';

const CommunitiesRoute =
  {
    path: '/communities',
    meta: {
      name: {
        es: 'Comunidades',
        en: 'Communities'
      }
    },
    component:  Communities,
    children: [
      {
        path: '',
        component: CommunitiesTable,
        meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'communities', permission: 'READ' }
      },
      {
        path: 'create',
        component: CommunitiesCreate,
        meta: { name: 'CommunitiesCreate', group: 'portaladministrativo.prices', path: 'communities', permission: 'CREATE' }
      },
      {
        path: 'update/:id',
        component: CommunitiesUpdate,
        meta: { name: 'CommunitiesUpdate', group: 'portaladministrativo.prices', path: 'communities', permission: 'READ' }
      },
    ]
  };

export default CommunitiesRoute
