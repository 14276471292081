import ReferredCompaniesTable from '../Views/ReferredCompaniesTable';
import ReferredCompaniesCreate from '../Views/ReferredCompaniesCreate.vue';
import ReferredCompaniesUpdate from '../Views/ReferredCompaniesUpdate.vue';

const ReferredCompaniesRoute = [
  {
    path:'',
    component: ReferredCompaniesTable,
    meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'referred_companies', permission: 'READ' }
  },
  {
    path:'create',
    component: ReferredCompaniesCreate,
    meta: { name: 'ReferredCompaniesCreate', group: 'portaladministrativo.prices', path: 'referred_companies', permission: 'CREATE' }
  },
  {
    path: 'update/:id',
    component: ReferredCompaniesUpdate,
    meta: { name: 'ReferredCompaniesUpdate', group: 'portaladministrativo.prices', path: 'referred_companies', permission: 'READ' }
  }
]

export default ReferredCompaniesRoute
