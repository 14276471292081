<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
          <Button
            v-if="canCreate"
            @clicked="newQuarter"
            :icon="breadcrumbs.button.icon"
            :text="breadcrumbs.button.name"
            depressed
          ></Button>
        </breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <super-table
              :headers="headers"
              :data="quarters"
              :permissions="{update: canUpdate, delete: canDelete}"
              @inputPagination="onInputPagination"
              @lengthPaginationChange="onLengthPaginationChange"
              @bulk-delete="(ceos)=>{bulkDeletePopUp(ceos)}"
              @ceo:delete="(id)=>deleteQuarterPopUp(id)"
              @ceo:update="(id)=>{updateQuarter(id)}"
              @ceo:detail="(id)=>{}"
            ></super-table>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { $zones, $ceos } from '../services';

export default {
  name: 'QuartersList',
  components: {
    SuperTable: () => import('../components/SuperTable/SuperTable.vue'),
    Breadcrumbs: () => import('@/components/Breadcrumbs/Breadcrumbs.vue'),
    Button: () => import('@/components/Button/Button.vue'),
  },
  data () {
    return {
      breadcrumbs: {
        title: this.$t('modules.quarters.list.title'),
        description: this.$t('modules.quarters.list.description'),
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      loadingUsers: false,
      headers: [
        {
          text: this.$t('modules.quarters.list.table.headers.code'),
          value: 'code',
        },
        {
          text: this.$t('modules.quarters.list.table.headers.description'),
          value: 'description',
        },
        {
          text: this.$t('modules.quarters.list.table.headers.zone'),
          value: 'region',
        },
        {
          text: this.$t('modules.quarters.list.table.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.quarters.list.table.headers.careers'),
          value: 'careers',
        },
        {
          text: this.$t('modules.quarters.list.table.headers.students'),
          value: 'students',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      quarters: [],
      asidebar: {
        pagination: {
          limit: 500,
          page: 1,
          currentPage: 1,
          pagesAmount: 1,
        },
        title: this.$t('modules.quarters.list.asidebar.title'),
        items: []
      }
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.quarters.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.quarters.READ || this.$permissions.portaladministrativo.prices.quarters.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.quarters.DELETE
    },
  },
  methods: {
    changed () {
    },
    async fetchData () {
      try {
        const resonse = await $ceos.find(null, null, {
          params: {
            page: this.asidebar.pagination.page-1,
            length: this.asidebar.pagination.limit,
            orderBy: 'id',
            orientation: 'desc'
          }
        });
        this.asidebar.pagination.pagesAmount = resonse.data.totalPages;
        this.quarters = resonse.data.content.map(item => {
          item.careers = item.careers ? item.careers : 0;
          item.students = item.students ? item.students : 0;
          item.region = item.zone && item.zone.zoneDescription ? item.zone.zoneDescription : 'No asignado';
          return item;
        });
      } catch {
        this.quarters = []
      }
    },
    deleteQuarterPopUp (id) {
      const quarter = this.quarters.find(rol => rol.id == id);
      if (quarter.students)
        this.$store.dispatch('commons/openPopUp', {
          // title: this.$t('modules.quarters.list.asidebar.title'),
          title: this.$t('modules.quarters.list.warning_messages.asigned_users'),
          content: [{
            value: quarter.description
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback() { } },
          actionSecondary: null,
          icon: { color: 'warning', name: 'mdi-cancel' },
          color: 'primary',
        });
      else
        this.$store.dispatch('commons/openPopUp', {
          title: '¿Esta seguro de eliminar?',
          content: [{
            value: quarter.description
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback: async () => this.deleteQuarter(id) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {

            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
    },
    bulkDeletePopUp (quarters) {
      const quartersWithStudents = quarters.filter(quarter => !!quarter.students);
      if (quartersWithStudents.length) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.quarters.warnings.list.title'),
          content: quartersWithStudents.map(quarter => ({
            value: quarter.description
          })),
          actionPrimary: { text: this.$t('actions.accept'), async callback() { } },
          actionSecondary: null,
          icon: { color: 'warning', name: 'mdi-cancel' },
          color: 'primary',
        });
      } else {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.quarters.errors.list.title'),
          content: quarters.map(quarter => ({
            value: quarter.description
          })),
          actionPrimary: { text: this.$t('actions.accept'), callback: async () => this.bulkDelete(quarters) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {

            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        });
      }
    },
    async bulkDelete (quarters) {
      const itemsForDelete = quarters.map((quarter) => {
        return { id: quarter.id }
      });

      try {
        await $ceos.bulkDelete(itemsForDelete)
      } catch (error) { // eslint-disable-line
        throw error;
      } finally {
        await this.fetchData()
      }
    },
    newQuarter () {
      if (this.canCreate) this.$router.push('/quarters/create')
    },
    updateQuarter (id) {
      if (this.canUpdate) this.$router.push(`/quarters/update/${id}`)
    },
    async deleteQuarter (id) {
      try {
        await $ceos.delete(id)
        this.quarters = this.quarters.filter(quarter => quarter.id !== id);
      } catch (error) { // eslint-disable-line
      }
    },
    async filterFactory () {
      let zones = await $zones.find();

      zones = zones.data.content.map((item) => ({
        type: 'check',
        value: true,
        name: item.zoneDescription
      }));

      const nameFilter = {
        name: this.$t('modules.quarters.list.asidebar.nameFilter'),
        filters: [{
          type: 'text',
          name: this.$t('modules.quarters.list.asidebar.nameFilter'),
          value: '',
        }]
      }

      const zonesFilter = {
        name: this.$t('modules.quarters.list.asidebar.zonesFilter'),
        filters: zones,
      }

      const stateFilter = {
        name: this.$t('modules.quarters.list.asidebar.stateFilter'),
        filters: [
          {
            type: 'check',
            value: true,
            name: this.$t('modules.quarters.list.asidebar.stateActive'),
          },
          {
            type: 'check',
            value: false,
            name: this.$t('modules.quarters.list.asidebar.stateInactive'),
          },
        ]
      }

      const careersFilter = {
        name: this.$t('modules.quarters.list.asidebar.careersFilter'),
        filters: [{
          type: 'range',
          name: '',
          value: 30,
          max: 100,
          min: 0,
        }]
      };

      const studentsFilter = {
        name: this.$t('modules.quarters.list.asidebar.studentsFilter'),
        filters: [{
          type: 'range',
          name: '',
          value: 30,
          max: 100,
          min: 0,
        }]
      };

      this.asidebar.items = [
        nameFilter,
        zonesFilter,
        stateFilter,
        careersFilter,
        studentsFilter,
      ];
    },
    onLengthPaginationChange (event) {
      this.asidebar.pagination = {
        page: 1,
        limit: event,
        currentPage: 1,
        pagesAmount: 1,
      }
      this.fetchData()
    },
    async onInputPagination () {
    },
  },
  async mounted () {
    this.fetchData();
    this.filterFactory();
  },
}
</script>

<style lang="sass" scoped>
</style>
