<template>
    <v-container class="pt-0" :class="!$vuetify.breakpoint.mobile ? 'px-8':''">
        <v-form>
            <h3 class="mb-4">
                {{ $t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.first_section') }}
            </h3>
            <div>
                 <v-text-field
                    v-model="inscription.razonSocial.$model"
                    class="pb-3"
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.reason')}*`" 
                    :error-messages=" inscription.razonSocial.$dirty && !inscription.razonSocial.required ? $t('modules.academic.periods.create.errors.required') : ''"
                    @blur="inscription.razonSocial.$touch()"
                    outlined>
                </v-text-field>
            </div>
            <div>
                 <v-text-field
                    v-model="inscription.direccion.$model"
                    class="pb-3"
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.address')}*`" 
                    :error-messages=" inscription.direccion.$dirty && !inscription.direccion.required ? $t('modules.academic.periods.create.errors.required') : ''"
                    @blur="inscription.direccion.$touch()"                    
                    outlined>
                </v-text-field>
            </div>
            <div>
                 <v-text-field v-mask="mask" 
                    v-model="inscription.cuit.$model"
                    class="pb-3" 
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.cuit')}*`" 
                    :error-messages=" inscription.cuit.$dirty && !inscription.cuit.required ? $t('modules.academic.periods.create.errors.required') : (!inscription.cuit.minLength) ? $t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.invalid') : ''"
                    @blur="inscription.cuit.$touch()" 
                    outlined>
                 </v-text-field>
            </div>
            <h3 class="mb-4">
                {{ $t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.second_section') }}
            </h3>
            <div>
                 <v-text-field
                    v-model="inscription.contacto.$model"
                    class="pb-3"
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.name_company')}*`" 
                    :error-messages=" inscription.contacto.$dirty && !inscription.contacto.required ? $t('modules.academic.periods.create.errors.required') : ''"
                    @blur="inscription.contacto.$touch()"     
                    @keypress="$validateAlphanumeric($event, inscription.contacto.$model,60, true, true)"
                    outlined>
                </v-text-field>
            </div>
            <div>
                 <v-text-field
                    v-model="inscription.cargoReferente.$model"
                    class="pb-3"
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.referrer')}*`" 
                    :error-messages=" inscription.cargoReferente.$dirty && !inscription.cargoReferente.required ? $t('modules.academic.periods.create.errors.required') : ''"
                    @blur="inscription.cargoReferente.$touch()"      
                    @keypress="$validateAlphanumeric($event, inscription.cargoReferente.$model,60, true, true)"
                    outlined>
                </v-text-field>
            </div>
            <div>
                <v-text-field 
                    type="email"
                    v-model="inscription.email.$model"
                    class="pb-3" 
                    :label="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.email')}*`" 
                    :error-messages=" inscription.email.$dirty && !inscription.email.required ? $t('modules.academic.periods.create.errors.required') : (!inscription.email.email) ? $t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.emailRequired') : ''"
                    @blur="inscription.email.$touch()"     
                    outlined>
                </v-text-field>
            </div>
            <div>
                <TelephoneInput
                    :value.sync="inscription.telefono.$model"
                    :placeholder="`${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.tel')}*`"
                    :required="inscription.telefono.$dirty && !inscription.telefono.required"
                    ref="telephone"
                    @blur="inscription.telefono.$touch()"
                    @validate="isValidPhone"
                />
            </div>
            <article v-if="selectCompany == 264">
                <h3>
                    {{ $t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.otherCompany.third_section') }}
                </h3>
                <p class="font-weight-bold body-2 text--secondary mb-4">
                    {{ 
                        `${$t('modules.portalalumno.practice.stepper.dateStep.about.optionCompany.form.otherCompany.selectDate')}*`
                    }}
                </p>
                <v-row no-gutters>
                    <v-col v-for="(item, i) in arrayDays" :key="i" cols="6" md="2" sm="4" xs="4" class="mb-1">
                        <v-card outlined class="rounded-lg mx-1 pa-2" >
                            <p class="text-center caption text--secondary font-weight-bold mb-0">{{item.day}}</p>
                            <v-item-group v-for="(dia, i) in item.horarios" :key="i" multiple>
                                <v-row>
                                    <v-col>
                                        <v-item v-slot="{ active, toggle }">
                                            <v-card
                                                outlined
                                                class="d-flex justify-center caption rounded-xl align-center px-1"
                                                :class="active ? 'primary--text' : 'text--secondary'"
                                                @click="toggle();getDay(item.day, dia.mañana[1], active, dia.mañana[0])"
                                                :style="active ? 'border: 1px solid' : '' "
                                            >
                                                <v-scroll-y-transition>
                                                    <span> {{dia.mañana[1]}} </span>
                                                </v-scroll-y-transition>
                                            </v-card>
                                        </v-item>
                                        <v-item v-slot="{ active, toggle }">
                                            <v-card
                                                outlined
                                                class="d-flex justify-center caption rounded-xl mt-3 align-center px-1"
                                                :class="active ? 'primary--text' : 'text--secondary'"
                                                @click="toggle();getDay(item.day, dia.tarde[1], active, dia.tarde[0])"
                                                :style="active ? 'border: 1px solid' : '' "
                                            >
                                                <v-scroll-y-transition>
                                                    <span> {{dia.tarde[1]}} </span>
                                                </v-scroll-y-transition>
                                            </v-card>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </v-card>
                    </v-col>
                </v-row>
                <section class="d-flex justify-space-between" primary>
                </section>
                <p v-if="invalidDays && !inscription.practicaHorariosDTOList.required" class="red--text">{{$t('modules.academic.periods.create.errors.required')}}</p>
                <section v-if="$isTeclab">
                    <DropFiles
                        :title="$t( 'modules.portalalumno.practice.stepper.dateStep.about.cv.title' )"
                        :subtitle=" $t( 'modules.portalalumno.practice.stepper.dateStep.about.cv.subtitle' ) "
                        :extension="'.pdf'"
                        :type-file="['application/pdf']"
                        limitSizeBytes="2000000"
                        :showTitle="true"
                        :showDashed="true"
                        v-model="fileSync"
                    />
                    <p class="body-2 text--secondary mb-0 mt-2">
                        {{ $t( 'modules.portalalumno.practice.stepper.dateStep.about.cv.text' ) }}
                        <a href="/templates/template-cv.docx" download>
                            {{ $t( 'modules.portalalumno.practice.stepper.dateStep.about.cv.link' ) }}
                        </a>
                    </p>
                </section>
            </article>
        </v-form>
    </v-container>
</template>

<script>
import DropFiles from '@/components/DropFiles/DropFiles';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
export default {
    name: 'FormPP',
    props:[
        'selectCompany',
        'studentLocation',
        'inscription',
        'invalidDays',
        'file',
    ],
    components: {
        TelephoneInput,
        DropFiles,
    },
    data(){
        return {
            itemId: 0,
            selectedDay: {},
            arrayDays: [
                { 
                    day: 'Lunes',
                    horarios: [
                        {
                            mañana: [1, 'Mañana'],
                            tarde: [2, 'Tarde'],
                        }
                    ]
                        
                },
                {
                    day: 'Martes',
                    horarios: [
                        {
                            mañana:[3, 'Mañana'],
                            tarde: [4, 'Tarde'],
                        }
                    ]
                        
                },
                {
                    day: 'Miercoles',
                    horarios: [
                        {
                            mañana: [5, 'Mañana'],
                            tarde: [6, 'Tarde'],
                        }
                    ]
                        
                },
                {
                    day: 'Jueves',
                    horarios: [
                        {
                            mañana: [7, 'Mañana'],
                            tarde: [8, 'Tarde'],
                        }
                    ]
                        
                },
                {
                    day: 'Viernes',
                    horarios: [
                        {
                            mañana: [9, 'Mañana'],
                            tarde: [10, 'Tarde'],
                        }
                    ]
                        
                },
                {
                    day: 'Sabado',
                    horarios: [
                        {
                            mañana: [11,'Mañana'],
                            tarde: [12, 'Tarde'],
                        }
                    ]
                        
                }
            ],
            selectSchedule: false,
            cleanSelectedDay: {},
        }
    },
    computed: {
        isRUT(){
            return this.$isIPP ? true : false
        },
        mask() {
            if (this.isRUT) return ['#####-X','######-X','#######-X', '########-X']
            else{
                return '##-########-#'
            }
        },
        fileSync: {
            get() {
                return this.file;
                },
            set(value) {
                return this.$emit('update:file', value);
            },
        },
    },
    methods: {
        isValidPhone(phoneObject) {
            this.telephoneValid = phoneObject.isValid
            this.$refs.telephone.handleInput(this.telephone, phoneObject)
        },
        getDay(day, schedule, active, idDay){
            if(!active){
                this.selectedDay = {
                    dia: day,
                    turno: schedule,
                    id: idDay,
                }
                this.inscription.practicaHorariosDTOList.$model.push(this.selectedDay)
                this.selectSchedule = true
            } else {
                this.$emit('changeValueInvalid', true)
                this.selectedDay = {}
                this.cleanSelectedDay = {
                    dia: day,
                    turno: schedule,
                    id: idDay,
                }
                const practicaHorarios = this.inscription.practicaHorariosDTOList.$model.filter((e) => e.dia !== this.cleanSelectedDay.dia || e.turno !== this.cleanSelectedDay.turno)
                this.inscription.practicaHorariosDTOList.$model = practicaHorarios
            }
        },
    },
}
</script>
