<template>
  <div class="independentInput">
    <v-text-field
      single-line
      :prepend-inner-icon="icon"
      outlined
      :error-messages="errors"
      :disabled="disabled"
      :class="(size === 'small') ? 'independentInput__input size_small' : 'independentInput__input'"
      v-model="$v.value.$model"
      :prefix="(type === 'price') ? $ : ''"
      @click="hide = false"
      ref="textfield"
    >
    </v-text-field>
    <div class="independentInput__actions">
      <v-btn small class="px-0" v-show="!hide" @click="saveInput">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
      <v-btn small class="px-0" v-show="!hide" @click="cancelInput">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="sass">
.independentInput
  position: relative
  &.size
    &_small
      max-width: 120px
  .error--text
    fieldset
      border-color: red !important
    .v-input__control
      .v-text-field__details
        .v-messages
          display: block
  &__input
    .v-input__slot
      min-height: 44px !important
      margin: 0
      .v-label
        top: 13px
      fieldset
        border-color: transparent
        background: transparent !important
    &:hover
      .v-input__slot
        background-color: rgba(var(--v-primary-accent2), 0.1) !important
        fieldset
          border-color: var(--v-primary-base) !important
    .v-text-field__details
      min-height: 0
      margin-bottom: 0 !important
      .v-messages
        display: none
    &.v-input--is-focused
      fieldset
        border-color: var(--v-primary-base) !important
        border-width: 2px
      & + .independentInput__actions
        height: auto
        opacity: 1
        top: 46px

  &__actions
    z-index: 2
    display: flex
    justify-content: flex-end
    position: absolute
    top: 0
    right: 0
    height: 0
    opacity: 0
    overflow: hidden
    .v-btn
      min-width: 40px
      margin: 4px
    &:hover
      height: auto
      opacity: 1
      top: 46px
</style>

<script>
/*
*  @props
* */
export default {
  name: 'IndependentInput',
  props: [
    'input',
    'disabled',
    'type',
    'icon',
    'error-messages',
    'size',
    'v'
  ],
  inject: { params: '$validationParams' },
  data() {
    return {
      value: '',
      hide: false,
      errors: '',
    }
  },
  methods: {
  
    cancelInput() {
      this.value = this.input;
      this.hide = true;
      this.errors = '';
    },
    saveInput() {
      if(this.validate()) {
        this.$emit('save', this.value);
        this.hide = true;
      } else {
        this.$refs.textfield.focus();
      }
    },
    validate() {
      const that = this;
      const validationParams = this.$v.value.$params;

      if( validationParams ) {
        if (this.$v.value.$invalid && this.$v.value.$dirty) {
          // First validate required
          if (validationParams.required) {
            if (!that.$v.value.required) {
              that.errors = that.errorMessages.required;
              return false;
            }
          }

          const paramsToArray = Object.values(validationParams);

          paramsToArray.forEach(param => {
            if (param.type !== 'required') {
              if (!that.$v.value[param.type]) {
                that.errors = that.errorMessages[param.type];
                return false;
              }
            }
          })
        } else {
          this.errors = '';
          return true;
        }
      } else {
        this.errors = '';
        return true;
      }
    },
  },
  created () {
    this.value = this.input;
  },
  validations() {
    if(this.v) {
      const validations = {};
      this.v.forEach(param => (validations[param] = this.params[param]));
      return {
        value: validations
      };
    } else {
      return {
        value: {}
      }
    }
  },
  watch:{
    'input': function(val){
      this.value = val;
    },
  }
}
</script>
