import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class Exceptions extends Api {
    constructor(http, config = {}) {
        super(http, url, 'tools', config);
    }

    async debtInscription(studentRecord, config = {}) {
        config = { ...this._config, ...config };
        const uri = this._uriConstructor(null, 'updateException');
        return this._http.get(`${uri}?studentCareer=${studentRecord}`, config);
    }

    async confirmPreregistration(studentRecord, config = {}) {
        config = { ...this._config, ...config };
        const uri = this._uriConstructor(null, 'updateStudentCareerStatus');
        return this._http.get(`${uri}?studentCareer=${studentRecord}`, config);
    }
    
    // async revertEnrollment(studentRecord, config = {}) {
    //     config = { ...this._config, ...config };
    //     const uri = this._uriConstructor(null, 'revertReenrollment');
    //     return this._http.get(`${uri}?studentCareer=${studentRecord}`, config);
    // }

    async revertEnrollment(config = {}) {
        config = { ...this._config, ...config };
        const uri = this._uriConstructor(null, 'revertReenrollment');
        return this._http.get(`${uri}`, config);
    }

    async changeModality(instance, config = {}) {
        config = { ...this._config, ...config };
        const uri = this._uriConstructor(instance);
        return this._http.put(uri,null, config);
    }

    async allowReenrollment(studentRecord, config = {}) {
        config = { ...this._config, ...config };
        const uri = this._uriConstructor(null, 'allowReenrollment');
        return this._http.get(`${uri}?studentCareer=${studentRecord}`, config);
    }
}
