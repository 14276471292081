var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"container-custom pb-8",attrs:{"fluid":""}},[_c('div',[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.exams_group.statements.statementsCreate.breadcrumbs.title'),"description":_vm.$t('modules.exams_group.statements.statementsCreate.breadcrumbs.description')}}),(!_vm.newStatementSuccess)?_c('v-container',{staticClass:"container-custom px-6"},[_c('v-row',[_c('v-col',[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.title'),"switch-label":(_vm.status) ? _vm.$t('enabled') : _vm.$t('disabled'),"switch-value":_vm.status,"switcher":""},on:{"switchChange":(val) => (_vm.status = val)}},[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.modalityTypes')}*`))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"3"}},[_c('v-select',{ref:"modalityType",attrs:{"error-messages":(_vm.$v.modalityType.$invalid && _vm.$v.modalityType.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"items":_vm.modalityTypes,"item-text":"meaning","item-value":"value","label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.modalityTypesLabel')}*`,"outlined":"","required":""},on:{"blur":function($event){return _vm.$v.modalityType.$touch()}},model:{value:(_vm.$v.modalityType.$model),callback:function ($$v) {_vm.$set(_vm.$v.modalityType, "$model", $$v)},expression:"$v.modalityType.$model"}})],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.examTypes')}*`))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"3"}},[_c('v-select',{ref:"examType",attrs:{"error-messages":(_vm.$v.examType.$invalid && _vm.$v.examType.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"items":_vm.examTypes,"item-text":"meaning","item-value":"value","label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.examTypesLabel')}*`,"outlined":"","required":""},on:{"blur":function($event){return _vm.$v.examType.$touch()}},model:{value:(_vm.$v.examType.$model),callback:function ($$v) {_vm.$set(_vm.$v.examType, "$model", $$v)},expression:"$v.examType.$model"}})],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex pt-4",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.questionTypes'))+" ")])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"3"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":(_vm.$v.questionType.$invalid && _vm.$v.questionType.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require')
                : ''},model:{value:(_vm.$v.questionType.$model),callback:function ($$v) {_vm.$set(_vm.$v.questionType, "$model", $$v)},expression:"$v.questionType.$model"}},_vm._l((_vm.questionTypes),function(n){return _c('v-radio',{key:n.value,attrs:{"label":`${n.meaning}`,"value":n}})}),1)],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.difficulty')}*`))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"2"}},[_c('v-select',{ref:"difficulty",attrs:{"error-messages":(_vm.$v.difficulty.$invalid && _vm.$v.difficulty.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"items":_vm.itemsDifficulty,"item-text":"meaning","item-value":"value","label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.difficultyType')}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.difficulty.$touch()}},model:{value:(_vm.difficulty),callback:function ($$v) {_vm.difficulty=$$v},expression:"difficulty"}})],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s("Tiempo de resolucion"))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"1"}},[_c('v-text-field',{attrs:{"onPaste":"return false","delete":"return false","min":"0","step":"1","max":"10","type":"number","outlined":""},on:{"keypress":function($event){$event.preventDefault();},"keydown":function($event){$event.preventDefault();}},model:{value:(_vm.maxTime),callback:function ($$v) {_vm.maxTime=$$v},expression:"maxTime"}})],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.statementTypes')}*`))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"3"}},[_c('v-select',{ref:"statementType",attrs:{"error-messages":(_vm.$v.statementType.$invalid && _vm.$v.statementType.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"items":_vm.statementTypes,"item-text":"meaning","item-value":"value","label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.firstCard.statementTypesLabel')}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.statementType.$touch()}},model:{value:(_vm.$v.statementType.$model),callback:function ($$v) {_vm.$set(_vm.$v.statementType, "$model", $$v)},expression:"$v.statementType.$model"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.title')}},[_c('v-switch',{attrs:{"label":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')},on:{"change":function($event){return _vm.removeFileStatement($event)}},model:{value:(_vm.isImageStatement),callback:function ($$v) {_vm.isImageStatement=$$v},expression:"isImageStatement"}}),(_vm.isImageStatement)?_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","align":"center"}},[_c('DropFiles',{ref:"fileStatement",staticClass:"text-center",attrs:{"title":"Cargar archivo","extension":'.jpg, .png, .gif',"type-file":'image/*',"limit-size-bytes":'2000000',"required":_vm.$v.fileStatement.$anyError,"show-title":true,"show-dashed":true,"size":'lg',"file-input":_vm.$v.fileStatement.$model},on:{"update:fileInput":function($event){return _vm.$set(_vm.$v.fileStatement, "$model", $event)},"update:file-input":function($event){return _vm.$set(_vm.$v.fileStatement, "$model", $event)},"remove":function($event){_vm.fileStatement = null}}})],1)],1)],1):_c('v-textarea',{ref:"description",staticClass:"mt-6",attrs:{"error-messages":(_vm.$v.description.$invalid && _vm.$v.description.$dirty)
            ? `${_vm.$t('modules.exams_group.statements.statementsCreate.error_message.require')}*` : '',"label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.descriptionLabel')}*`,"name":"input-7-4","height":"44px","outlined":""},on:{"blur":function($event){return _vm.$v.description.$touch()}},model:{value:(_vm.$v.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.description, "$model", $$v)},expression:"$v.description.$model"}}),_c('v-card-title',{staticClass:"headline font-weight-bold ma-0 px-0 pt-4",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$tc('modules.exams_group.statements.statementsCreate.form.secondCard.answer',_vm.isTrueFalse ? 1 : 2))+" ")]),(!_vm.isTrueFalse)?_c('div',[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"sm":"3"}},[_c('span',{staticClass:"caption text-uppercase"},[_vm._v(_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.quantity')}*`))])]),_c('v-col',{staticClass:"align-center",attrs:{"sm":"1"}},[_c('v-text-field',{ref:"quantity",attrs:{"onPaste":"return false","error-messages":(_vm.$v.quantity.$invalid && _vm.$v.quantity.$dirty)
                  ? 'valor min 2' : '',"type":"number","outlined":"","step":"1","max":"5","min":"3"},on:{"keypress":function($event){$event.preventDefault();},"blur":function($event){return _vm.$v.quantity.$touch()}},model:{value:(_vm.$v.quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.quantity, "$model", $$v)},expression:"$v.quantity.$model"}})],1)],1),_vm._l((_vm.$v.answerList.$each.$iter),function(item,index){return _c('v-card',{key:index,staticClass:"mb-5"},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-baseline py-0",attrs:{"primary-title":""}},[_c('div',{staticClass:"d-flex align-baseline ml-6"},[_c('h4',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(`${_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.answerLabel')} N° ${Number(index) + 1}`)+" ")]),(_vm.questionType.value == 75)?_c('div',[_c('v-radio-group',{ref:"answerList",refInFor:true,staticClass:"mx-3",attrs:{"hide-details":"","error-messages":(item.isCorrect.$invalid && item.isCorrect.$dirty)
                      ? _vm.$t('actions.error')
                      : ''},model:{value:(item.isCorrect.$model),callback:function ($$v) {_vm.$set(item.isCorrect, "$model", $$v)},expression:"item.isCorrect.$model"}},[_c('v-radio',{staticClass:"ml-2",attrs:{"label":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.isCorrect'),"value":true},on:{"change":function($event){return _vm.answerCorrectSelected(index)}}})],1)],1):_vm._e(),(_vm.questionType.value == 76)?_c('v-checkbox',{ref:"answerList",refInFor:true,staticClass:"mx-5",attrs:{"hide-details":"","error-messages":(item.isCorrect.$invalid && item.isCorrect.$dirty)
                  ? _vm.$t('actions.error'):'',"label":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.isCorrect')},model:{value:(item.isCorrect.$model),callback:function ($$v) {_vm.$set(item.isCorrect, "$model", $$v)},expression:"item.isCorrect.$model"}}):_vm._e()],1),_c('v-switch',{staticClass:"mr-6",attrs:{"label":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')},on:{"change":function($event){return _vm.cleanFileAnswer($event, item, index)}},model:{value:(item.isImage.$model),callback:function ($$v) {_vm.$set(item.isImage, "$model", $$v)},expression:"item.isImage.$model"}})],1),_c('v-divider',{staticClass:"primary ma-auto",attrs:{"width":"90%"}}),(item.isImage.$model)?_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","align":"center"}},[_c('DropFiles',{ref:"answerList",refInFor:true,staticClass:"text-center",attrs:{"title":"Cargar archivo","extension":".jpg,.jpeg,.png","typeFile":"image.*","limitSizeBytes":"2000000","file-input":_vm.answerList[index].file,"required":_vm.$v.answerList.$each[index].$anyError,"showTitle":true,"showDashed":true,"idDropFiles":index},on:{"update:fileInput":function($event){return _vm.setfileAnswers($event, item)},"remove":function($event){return _vm.removeFileAnswer($event.name, item)}}})],1)],1)],1):_c('v-card-text',{staticClass:"px-10"},[_c('v-textarea',{ref:"answerList",refInFor:true,attrs:{"error-messages":(item.description.$invalid && item.description.$dirty)
                ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.answerLabel')}*`,"name":"input-7-4","height":"44px","outlined":""},on:{"blur":function($event){return item.description.$touch()}},model:{value:(item.description.$model),callback:function ($$v) {_vm.$set(item.description, "$model", $$v)},expression:"item.description.$model"}})],1)],1)})],2):_c('div',{staticClass:"mt-4"},_vm._l((_vm.$v.answerList.$each.$iter),function(item,index){return _c('v-radio-group',{key:`radio-group-${index}`,ref:"answerList",refInFor:true,staticClass:"mt-0",attrs:{"hide-details":"","error-messages":(item.isCorrect.$invalid && item.isCorrect.$dirty)
                ? _vm.$t('actions.error')
                : ''},model:{value:(item.isCorrect.$model),callback:function ($$v) {_vm.$set(item.isCorrect, "$model", $$v)},expression:"item.isCorrect.$model"}},[_c('v-radio',{attrs:{"label":item.$model.description,"value":true},on:{"change":function($event){return _vm.answerCorrectSelected(index)}}})],1)}),1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":"Justificacíon"}},[_c('v-switch',{attrs:{"label":_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.isImage')},on:{"change":function($event){return _vm.removeFileJustification($event)}},model:{value:(_vm.isImageJustification),callback:function ($$v) {_vm.isImageJustification=$$v},expression:"isImageJustification"}}),(_vm.isImageJustification)?_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8","align":"center"}},[_c('DropFiles',{ref:"fileJustification",staticClass:"text-center",attrs:{"title":"Cargar archivo","extension":'.jpg, .png, .gif',"type-file":'image/*',"limit-size-bytes":'2000000',"required":_vm.$v.fileJustification.$anyError,"show-title":true,"show-dashed":true,"size":'lg',"file-input":_vm.$v.fileJustification.$model},on:{"update:fileInput":function($event){return _vm.$set(_vm.$v.fileJustification, "$model", $event)},"update:file-input":function($event){return _vm.$set(_vm.$v.fileJustification, "$model", $event)},"remove":function($event){_vm.fileJustification = null}}})],1)],1)],1):_c('v-textarea',{ref:"justification",staticClass:"mt-6",attrs:{"error-messages":(_vm.$v.justification.$invalid && _vm.$v.justification.$dirty)
            ? _vm.$t('modules.exams_group.statements.statementsCreate.error_message.require') : '',"label":`${_vm.$t('modules.exams_group.statements.statementsCreate.form.secondCard.descriptionLabel')}*`,"name":"input-7-4","height":"88px","outlined":""},on:{"blur":function($event){return _vm.$v.justification.$touch()}},model:{value:(_vm.$v.justification.$model),callback:function ($$v) {_vm.$set(_vm.$v.justification, "$model", $$v)},expression:"$v.justification.$model"}})],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticClass:"d-flex my-8"},[_c('Button',{staticClass:"ml-4",attrs:{"outlined":"","depressed":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$router.go(-1)}}}),_c('Button',{staticClass:"ml-4",attrs:{"loading":_vm.saveButton.loading,"success":_vm.saveButton.success,"error":_vm.saveButton.error,"successText":_vm.$t('actions.saved'),"text":_vm.$t('actions.save'),"errorText":_vm.$t('actions.error'),"depressed":""},on:{"end":_vm.resetButtonValues,"clicked":_vm.checkDisableAndCreate}})],1)])],1):_c('SuccessMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }