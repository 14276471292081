import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('practica');

export class Agreements extends Api {
  constructor(http, config = {}) {
    super(http, url, 'agreements', config);
  }
}
