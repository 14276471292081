<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
          <Button
            v-if="canCreate"
            @clicked="newDegree"
            :icon="breadcrumbs.button.icon"
            :text="breadcrumbs.button.name"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="degrees"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdate, delete: canDelete}"
              @deleteUser="(id)=>deleteDegreePopUp(id)"
              @updateUser="(id)=>{updateDegree(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button';
import { $degrees } from '../Services';

export default {
  name: 'DegreesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      clearSelected: '',
      breadcrumbs: {
        title: this.$t('modules.degrees.table.breadcrumbs.title'),
        description: this.$t('modules.degrees.table.breadcrumbs.description'),
        button: {
          name: this.$t('actions.new'),
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      degrees: [],
      headers: [
        {
          text: this.$t('modules.degrees.table.headers.name'),
          value: 'description',
        },
        {
          text: this.$t('modules.degrees.table.headers.type'),
          value: 'type',
        },
        {
          text: '',
          value: 'action'
        }
      ],
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.degrees.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.degrees.READ || this.$permissions.portaladministrativo.academic.degrees.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.degrees.CREATE
    },
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    deleteDegreePopUp (id) {
      const degree = this.degrees.find(degree => degree.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('actions.confirmation_delete'),
        content: [{ value: degree.description }],
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteDegree(id) },
        actionSecondary: { text: this.$t('actions.cancel'), callback() { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    async fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();

      try {
        const degrees = await $degrees.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } })
        this.degrees = degrees.data.content.map(degree => {
          degree.type = degree.type.meaning;
          return degree;
        });
      } catch {
        this.degrees = []
      }
    },
    newDegree () {
      if (this.canCreate) this.$router.push('/degrees/create')
    },
    updateDegree (id) {
      if (this.canUpdate) this.$router.push(`degrees/update/${id}`)
    },
    async deleteDegree (id) {
      try {
        await $degrees.delete(id);
        this.degrees = this.degrees.filter(degree => degree.id !== id);
      } catch(error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{value: (error.codeMeaning == '') ? this.$t('modules.degrees.table.delete_error') : error.codeMeaning}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
      }
    }
  }
}
</script>
