<template>
  <v-container fluid v-if="step != 3" class="mb-4">
    <h3 class="ml-4 py-1">
      {{
        $t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.companyPP'
        )
      }}:
      <span class="font-weight-regular">
        {{ dataPP.dondeHaraLaPP }}
      </span>
    </h3>
    <div v-if="datosCompany.tipoEmpresaSeleccionId == 264">
      <h3 class="ml-4 py-1">
        {{
          $t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.subject'
          )
        }}:
        <span class="font-weight-regular">
          {{ datosCompany.materia }}
        </span>
      </h3>
    </div>
    <v-row v-if="disponibilidadHoraria && disponibilidadHoraria.length > 0">
      <v-col v-for="day in days" :key="day">
        <v-card>
          <v-card-title class="text--secondary d-flex justify-center">
            {{ day }}
          </v-card-title>
          <div class="d-flex flex-column justify-center align-center px-4">
            <v-chip
              outlined
              class="ma-2 d-flex justify-center"
              style="width: 100%"
              :color="getTurnoManiana(day) ? 'primary' : ''"
            >
              Mañana
            </v-chip>
            <v-chip
              outlined
              class="ma-2 d-flex justify-center"
              style="width: 100%"
              :color="getTurnoTarde(day) ? 'primary' : ''"
            >
              Tarde
            </v-chip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PPLocationAndShifts',
  props: ['datosCompany', 'dataPP', 'disponibilidadHoraria', 'step'],
  data() {
    return {
      days: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
    };
  },
  methods: {
    getTurnoManiana(day) {
      let response = false;
      if (this.disponibilidadHoraria.length > 0) {
        const dia = this.disponibilidadHoraria.find((dh) => dh.dia == day);
        if (dia && dia.turno == 'Mañana') {
          response = true;
        }
      }
      return response;
    },
    getTurnoTarde(day) {
      let response = false;
      if (this.disponibilidadHoraria.length > 0) {
        const dia = this.disponibilidadHoraria.find((dh) => dh.dia == day);
        if (dia && dia.turno == 'Tarde') {
          response = true;
        }
      }
      return response;
    },
  },
};
</script>
