import BillingCreate from '../Views/BillingCreate';
import BillingUpdate from '../Views/BillingUpdate';
import BillingTable from '../Views/BillingTable';


const BillingRoute =[

    {
      path:'',
      component: BillingTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'billing' }
    },
    {
      path:'create',
      component: BillingCreate,
      name:'billing-create',
      meta: { name: 'Create', group: 'portaladministrativo.prices', path: 'billing' }
    },
    {
      path:'update/:id',
      component: BillingUpdate,
      name:'billing-update',
      meta: { name: 'Update', group: 'portaladministrativo.prices', path: 'billing', props: true }
    },
    {
      path:'generate',
      component: BillingUpdate,
      name:'billing-generate',
      meta: { name: 'Generate', group: 'portaladministrativo.prices', path: 'billing', props: true }
    },
    
  ];

export default BillingRoute
