var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',[_c('v-container',{staticClass:"container-custom"},[_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"icon":"","light":""},on:{"click":function($event){return _vm.$emit('hideArea')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"px-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.data ? _vm.$t('modules.messaging.template.newArea.editar') : _vm.$t('modules.messaging.template.newArea.create'))+" "+_vm._s(_vm.$t('modules.messaging.template.newArea.title')))])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.name.$dirty && _vm.$v.name.$invalid) ?
              ((!_vm.$v.name.required) ? _vm.$t('modules.messaging.template.newArea.error.name.requerido') :

              (!_vm.$v.name.maxLength) ? _vm.$t('modules.messaging.template.newArea.error.name.maxLength') :
              '') :
              '',"outlined":"","label":_vm.$t('modules.messaging.template.newArea.labelName')},on:{"blur":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.$v.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.name, "$model", $$v)},expression:"$v.name.$model"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.code.$dirty && _vm.$v.code.$invalid) ?
              ((!_vm.$v.code.required) ? _vm.$t('modules.messaging.template.newArea.error.code.requerido') :

              (!_vm.$v.code.maxLength) ? _vm.$t('modules.messaging.template.newArea.error.code.maxLength') :
              '') :
              '',"outlined":"","label":_vm.$t('modules.messaging.template.newArea.labelCode')},on:{"blur":function($event){return _vm.$v.code.$touch()}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-end px-12 mb-4"},[_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$emit('hideArea')}}}),_c('Button',{attrs:{"loading":_vm.createButton.loading,"success":_vm.createButton.success,"error":_vm.createButton.error,"text":_vm.data ? _vm.$t('actions.edit') :  _vm.$t('actions.create'),"successText":_vm.$t('actions.created'),"errorText":_vm.$t('actions.error'),"disabled":!_vm.data ? !_vm.canCreate : !_vm.canUpdate},on:{"clicked":_vm.submit}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }