var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 pb-8",attrs:{"fluid":""}},[(!_vm.createNewPracticeTermsStatus)?_c('v-container',{staticClass:"container-custom"},[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.studentSubjects.enrollment.breadcrumbs.titlePractice'),"description":_vm.$t('modules.studentSubjects.enrollment.breadcrumbs.description')}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.studentSubjects.create.codeSection.title'),"subtitle":_vm.$t('modules.studentSubjects.create.codeSection.subtitle')}},[_c('br'),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"sm":"6"}},[_c('v-select',{attrs:{"error-messages":(_vm.$v.newReferredCompany.selectedTerm.$dirty && _vm.$v.newReferredCompany.selectedTerm.$invalid) ?
                      _vm.$t('modules.studentSubjects.create.error_message.requiredTerms') : '',"label":`${_vm.$t('modules.studentSubjects.create.textField.term')} *`,"items":_vm.terms,"item-text":"term.description","outlined":"","return-object":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.selectedTerm.$touch()}},model:{value:(_vm.$v.newReferredCompany.selectedTerm.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.selectedTerm, "$model", $$v)},expression:"$v.newReferredCompany.selectedTerm.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"sm":"6"}},[_c('v-autocomplete',{attrs:{"error-messages":(_vm.$v.newReferredCompany.selectedSubject.$dirty && _vm.$v.newReferredCompany.selectedSubject.$invalid) ?
                      _vm.$t('modules.studentSubjects.create.error_message.requiredSubjects') : '',"label":`${_vm.$t('modules.studentSubjects.create.textField.subject')} *`,"items":_vm.subjects,"item-text":"description","outlined":"","return-object":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.selectedSubject.$touch()}},model:{value:(_vm.$v.newReferredCompany.selectedSubject.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.selectedSubject, "$model", $$v)},expression:"$v.newReferredCompany.selectedSubject.$model"}})],1)],1)],1),_c('OutlinedCard',[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"sm":"12"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.newReferredCompany.businessName.$dirty && _vm.$v.newReferredCompany.businessName.$invalid) ?
                        ((!_vm.$v.newReferredCompany.businessName.required) ? _vm.$t('modules.studentSubjects.create.error_message.requiredBusinessName') :
                        (!_vm.$v.newReferredCompany.businessName.alphabeticWithSpaces) ? _vm.$t('modules.studentSubjects.create.error_message.businessNameAlphabeticWithSpaces') :
                        _vm.$t('modules.studentSubjects.create.error_message.businessNameMaxLength')) :
                        '',"label":`${_vm.$t('modules.studentSubjects.create.textField.Namebusiness')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.businessName.$touch()}},model:{value:(_vm.$v.newReferredCompany.businessName.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.businessName, "$model", $$v)},expression:"$v.newReferredCompany.businessName.$model"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"sm":"3"}},[_c('IdentificationInput',{ref:"identification",attrs:{"error-messages":(_vm.$v.newReferredCompany.identification.$dirty && _vm.$v.newReferredCompany.identification.$invalid) ?
                      (!_vm.$v.newReferredCompany.identification.required ? _vm.$t('modules.studentSubjects.create.error_message.requiredIdentification') :
                      _vm.$t('modules.studentSubjects.create.error_message.identificationUnique')) :
                      '',"label":`${_vm.$t('modules.studentSubjects.create.textField.identification')} *`,"id-type":_vm.$isTeclab ? 'CUIT' : 'RUT',"loading":_vm.loadingIdentification},on:{"posTimer":() => { this.validateIdentification() },"validateRut":(status) => { this.validRUT = status },"blur":function($event){return _vm.$v.newReferredCompany.identification.$touch()}},model:{value:(_vm.$v.newReferredCompany.identification.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.identification, "$model", $$v)},expression:"$v.newReferredCompany.identification.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"error-messages":(_vm.$v.newReferredCompany.address.$dirty && _vm.$v.newReferredCompany.address.$invalid) ?
                      _vm.$t('modules.studentSubjects.create.error_message.requiredAddress') : '',"label":`${_vm.$t('modules.studentSubjects.create.textField.address')} *`,"outlined":""},on:{"blur":function($event){return _vm.$v.newReferredCompany.address.$touch()}},model:{value:(_vm.$v.newReferredCompany.address.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.address, "$model", $$v)},expression:"$v.newReferredCompany.address.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"3"}},[_c('AutocompleteInput',{ref:"cityWithState",attrs:{"required":_vm.$v.newReferredCompany.cityWithState.$invalid,"errorMessages":_vm.$t('modules.studentSubjects.create.error_message.requiredCityWithState'),"value":_vm.$v.newReferredCompany.cityWithState.$model,"label":`${_vm.$t('modules.studentSubjects.create.textField.city')} *`,"disable-buttons":""},on:{"update:value":function($event){return _vm.$set(_vm.$v.newReferredCompany.cityWithState, "$model", $event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"sm":"4"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('modules.studentSubjects.create.textField.contactName')} * `,"error-messages":_vm.$v.newReferredCompany.contactName.$dirty && _vm.$v.newReferredCompany.contactName.$invalid ?
                    _vm.$t('modules.studentSubjects.create.error_message.requiredContactName') : '',"outlined":"","type":"text","maxlength":"40"},on:{"blur":function($event){return _vm.$v.newReferredCompany.contactName.$touch()}},model:{value:(_vm.$v.newReferredCompany.contactName.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.contactName, "$model", $$v)},expression:"$v.newReferredCompany.contactName.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"sm":"5"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('modules.studentSubjects.create.textField.email')} * `,"error-messages":_vm.$v.newReferredCompany.email.$dirty && _vm.$v.newReferredCompany.email.$invalid ?
                    _vm.$t('modules.studentSubjects.create.error_message.requiredEmail') : '',"outlined":"","type":"text"},on:{"blur":function($event){return _vm.$v.newReferredCompany.email.$touch()}},model:{value:(_vm.$v.newReferredCompany.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.newReferredCompany.email, "$model", $$v)},expression:"$v.newReferredCompany.email.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"sm":"3"}},[_c('TelephoneInput',{ref:"cellphone",attrs:{"value":_vm.$v.newReferredCompany.cellphone.$model,"required":_vm.$v.newReferredCompany.cellphone.$dirty && _vm.$v.newReferredCompany.cellphone.$invalid,"placeholder":`${_vm.$t('modules.studentSubjects.create.textField.cellphone')} *`},on:{"update:value":function($event){return _vm.$set(_vm.$v.newReferredCompany.cellphone, "$model", $event)},"blur":function($event){return _vm.$v.newReferredCompany.cellphone.$touch()},"validate":_vm.isValidPhone}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"mr-4",attrs:{"white":"","text":_vm.$t('actions.cancel')},on:{"clicked":function($event){return _vm.$router.go(-1)},"end":_vm.resetButtonValues}}),_c('Button',{attrs:{"loading":_vm.createButton.loading,"success":_vm.createButton.success,"error":_vm.createButton.error,"text":_vm.$t('actions.save'),"successText":_vm.$t('actions.saved'),"errorText":_vm.$t('actions.error'),"disabled":!_vm.canCreate},on:{"end":_vm.resetButtonValues,"clicked":_vm.createPracticeTerms}})],1)],1)],1)],1):_c('SuccessMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }