import Prepaids from '../Prepaids.vue'
import PrepaidsTable from '../Views/PrepaidsTable.vue';
import PrepaidsCreate from '../Views/PrepaidsCreate.vue';
import PrepaidsUpdate from '../Views/PrepaidsUpdate.vue';

const PrepaidsRoute =
  {
    path: '/prepaids',
    meta: {
      name: {
        es: 'Pago Anticipado',
        en: 'Prepaids'
      }
    },
    component:  Prepaids,
    children: [
      {
        path: '',
        component: PrepaidsTable,
        meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'prepaids', permission: 'READ' }
      }, {
        path: 'create',
        component: PrepaidsCreate,
        meta: { name: 'PrepaidsCreate', group: 'portaladministrativo.prices', path: 'prepaids', permission: 'CREATE' }
      }, {
        path: 'update/:id',
        component: PrepaidsUpdate,
        meta: { name: 'PrepaidsUpdate', group: 'portaladministrativo.prices', path: 'prepaids', permission: 'READ' }
      },
    ]
  };

export default PrepaidsRoute
