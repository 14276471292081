<template>
  <OutlinedCard class="usersCreatePersonalForm">
    <form class="usersCreatePersonalForm__content">
      <v-row no-gutters>
        <v-col sm="3">
          <AvatarPreview size="110" rounded="true" :label="$t('modules.users.create.personalForm.avatar')" labelSize="14"
            :model="userData.avatar" :image="imagePreview" @save="(file)=>createImage(file)">
          </AvatarPreview>
        </v-col>
        <v-col sm="9">
          <v-row>
            <v-col sm="12">
              <v-text-field
                v-model="userData.name.$model"
                :error-messages="(userData.name.$dirty && userData.name.$invalid) ? $t('modules.users.create.validation_errors.personalForm.name') : ''"
                :label="$t('modules.users.create.personalForm.name') + ' *'"
                @input="userData.name.$touch"
                @blur="userData.name.$touch"
                outlined
                type="text">
              </v-text-field>
            </v-col>
            <v-col sm="12">
              <v-text-field
                v-model="userData.lastname.$model"
                :error-messages="(userData.lastname.$dirty && userData.lastname.$invalid) ? $t('modules.users.create.validation_errors.personalForm.lastname') : ''"
                :label="$t('modules.users.create.personalForm.lastname') + ' *'"
                @blur="userData.lastname.$touch"
                outlined
                type="text">
              </v-text-field>
            </v-col>
            <v-col sm="6">
              <BirthdayDatePicker
                v-model="userData.birthDate.$model"
                :label="$t('modules.users.create.personalForm.birthDate') + ' *'">
              </BirthdayDatePicker>
            </v-col>
            <v-col sm="6">
              <v-select
                v-model="userData.gender.$model"
                :items="genreOptions"
                :error-messages="(userData.gender.$dirty && userData.gender.$invalid) ? $t('modules.users.create.validation_errors.personalForm.gender') : ''"
                :label="$t('modules.users.create.personalForm.genre') + ' *'"
                @blur="userData.gender.$touch()"
                outlined
                return-object
                item-text="meaning">
              </v-select>
            </v-col>
            <v-col sm="6">
              <v-select
                v-model="userData.identificationType.$model"
                :items="identificationOptions"
                :error-messages="(userData.identificationType.$dirty && userData.identificationType.$invalid) ? $t('modules.users.create.validation_errors.personalForm.identificationType') : ''"
                :label="$t('modules.users.create.personalForm.identification') + ' *'"
                return-object
                @blur="userData.identificationType.$touch()"
                item-text="meaning"
                outlined>
              </v-select>
            </v-col>
            <v-col sm="6">
              <IdentificationInput
                v-model="userData.identification.$model"
                :loading="loadingCode"
                @preTimer="$emit('update:codeUnique', null)"
                @posTimer="$emit('validateCode')"
                :label="`${$t('modules.users.create.personalForm.identificationNumber')} *`"
                :error-messages= 'identificationErrorMessages'
                @blur="userData.identification.$touch()"
                @validateRut="(status) => {this.validRut = status}"
                :idType="userData.identificationType.$model.meaning"
              ></IdentificationInput>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </OutlinedCard>
</template>

<style lang="sass">
    .usersCreatePersonalForm
        &__content
            .birthdayDatePicker__input
                .v-input__slot
                    fieldset
                        border-color: rgba(0, 0, 0, 0.24) !important
</style>

<script>
import BirthdayDatePicker from '@/components/BirthdayDatePicker/BirthdayDatePicker'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import AvatarPreview from '@/components/AvatarPreview/AvatarPreview'
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput'
import { $types } from '../../services';
import { mapGetters } from 'vuex';

export default {
  name: 'UsersCreatePersonalForm',
  components: {
    BirthdayDatePicker,
    OutlinedCard,
    AvatarPreview,
    IdentificationInput,
  },
  props: {
    userData: Object,
    nextStep: Function,
    activeStep: Boolean,
    avatarFile: File,
    loadingCode: Boolean,
    codeUnique: Boolean,
  },
  data () {
    return {
      genreOptions: [],
      identificationOptions: [],
      RUTmessage: '',
      validRut: false,
    }
  },
  mounted () {
    this.fetchData();
  },
  computed: {
    identificationErrorMessages() {
      return (this.userData.identification.$dirty && this.userData.identification.$invalid)
                ? (!this.userData.identification.unique
                    ? this.$t('modules.users.create.validation_errors.personalForm.identification_unique')
                    : this.$t('modules.users.create.validation_errors.personalForm.identification'))
                : '';
    },
    ...mapGetters({
      imagePreview: 'commons/defaultAvatar',
    }),
  },
  methods: {
    async fetchData() {
      const genders = await $types.find(null, null, { params: { type: 'GENDER_TYPE' } });
      this.genreOptions = genders.data;

      const identifications = await $types.find( null, null, { params: { type: 'IDENTIFICATION_TYPE' } });
      this.identificationOptions = identifications.data;
    },
    createImage (file) {
      try {

        this.$emit('update:avatarFile',file);
        this.userData.avatar = file;
        const reader = new FileReader();

        reader.onload = (e) => {
          this.setImagePreview(e.target.result);
        };
        reader.onerror = () => {
          this.setImagePreview(null);
          reader.abort();
        };
        reader.readAsDataURL(this.userData.avatar);
      } catch (err) {
        this.setImagePreview(null);
      }
    },
    setImagePreview (image) {
      this.imagePreview = image;
    },
    validateStep(value) {
      if (this.activeStep) {
        if (!value.name.$anyDirty || value.name.$anyError ||
          !value.lastname.$anyDirty || value.lastname.$anyError ||
          !value.gender.$anyDirty || value.gender.$anyError ||
          !value.identification.$anyDirty || value.identification.$anyError || !this.codeUnique ||
          value.identificationType.$anyError || !this.validRut) {
          this.$emit('disableNextStep');
        } else {
          this.$emit('nextStep');
        }
      }
    },
  },
  watch: {
    activeStep() {
      this.validateStep(this.userData);
    },
    userData: {
      handler(value){
        this.validateStep(value);
      },
      deep: true
    },
    codeUnique(value) {
      if (value) this.validateStep(this.userData);
      else this.$emit('disableNextStep');
    },
  },
}
</script>
