<template>
    <div>
        <v-container fluid class="pa-0 pb-8">
            <template v-if="!insideDrawer">
                <v-container class="container-custom">
                    <Breadcrumbs 
                        :title="$t('modules.product.create.breadcrumbs.title')" 
                        :description="$t('modules.product.create.breadcrumbs.description')">
                    </Breadcrumbs>
                </v-container>
            </template>
            <v-container class="container-custom px-8">
                <template v-if="!createNewProductStatus">
                    <v-row justify="center">
                        <v-col class="py-0 pl-7" sm="12">
                            <OutlinedCard
                                :title="$t('modules.product.create.title')"
                                :subtitle="$t('modules.product.create.subtitle')"
                                :switch-value="status"
                                @switchChange="(val) => {this.status = val;}"
                                :switch-label="(status) ? $t('enabled') : $t('disabled')"
                                switcher
                                class="mb-6"
                            >
                                <v-row>
                                    <v-col sm="9">
                                        <v-text-field
                                            counter="75"
                                            outlined
                                            :label="`${$t('modules.product.create.labels.name')} *`"
                                            :error-messages="$v.name.$error ? $t('modules.product.inputErrors.required') : ''"
                                            @blur="$v.name.$touch()"
                                            v-model="$v.name.$model"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="3">
                                        <v-text-field
                                            outlined
                                            :label="`${$t('modules.product.create.labels.sap')} *`"
                                            :error-messages="$v.billingCodeSap.$error ? $t('modules.product.inputErrors.required') : (codeSapWasChecked && codeSapCheckError) ? $t('modules.product.inputErrors.alreadyExists') : ''"
                                            @blur="onCodeSapBlur"
                                            v-mask="'###############'"
                                            v-model="$v.billingCodeSap.$model"
                                        ></v-text-field>
                                        <v-progress-linear 
                                        class="linear-progess-code"
                                        color="primary" 
                                        indeterminate 
                                        v-if="codeCheckingLoading">
                                        </v-progress-linear>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col sm="12">
                                        <v-textarea
                                            outlined
                                            :label="`${$t('modules.product.create.labels.description')} *`"
                                            :error-messages="$v.description.$error ? $t('modules.product.inputErrors.required') : ''"
                                            @blur="$v.description.$touch()"
                                            v-model="$v.description.$model"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </OutlinedCard>
                            <OutlinedCard
                                :title="$t('modules.product.sections.enabledTitle')"
                                class="mb-6"
                            >
                                <v-row>
                                     <v-col sm="6"  class="pt-0 pb-0 d-flex" v-for="(prop, index) in productProps"  v-bind:key="prop.id">
                                        <div class="d-flex align-center">
                                            <v-checkbox
                                                color="primary" 
                                                class="pa-0 mt-0"
                                                v-model="prop.status"
                                                :label="prop.description"/>
                                            <v-text-field
                                                class="ml-6 mt-0"
                                                placeholder="0"
                                                min="0"
                                                max="100"
                                                outlined
                                                :error-messages="prop.status && $v.productProps.$each[index].$error ? $t('modules.product.inputErrors.required') : ''"
                                                @keypress="$validateIntegerInputNumber($event, prop.value, 100)"
                                                type="number"
                                                :disabled="isDisabled(prop)"
                                                v-model="prop.value"
                                                @blur="onBlur($v.productProps.$each[index])"
                                            />
                                        </div> 
                                    </v-col>
                                </v-row>
                            </OutlinedCard>
                            <OutlinedCard
                                :title="$t('modules.product.sections.relatedProduct')"
                                class="mb-6"
                            >
                                <v-row>
                                    <v-col sm="6" class="pt-0 pb-0 d-flex"
                                        v-for="product in productsRelated"
                                        :key="product.id"
                                    >
                                        <v-checkbox
                                            v-model="selectedProducts"
                                            color="primary"
                                            :label="product.name"
                                            :value="product.id"
                                            class="mr-8 mt-0"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </OutlinedCard>
                            <div class="d-flex justify-end">
                                <Button
                                    white
                                    :text="$t('actions.cancel')"
                                    @clicked="cancelCreation"
                                    @end="resetButtonValues()"
                                    class="mr-4"
                                >
                                </Button>

                                <Button
                                    :loading="createButton.loading"
                                    :success="createButton.success"
                                    :error="createButton.error"
                                    :text="$t('actions.create')"
                                    :successText="$t('actions.created')"
                                    :errorText="'Error'"
                                    :disabled="!canCreate"
                                    @end="resetButtonValues()"
                                    @clicked="createProduct"
                                >
                                </Button>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <SuccessMessage v-else
                    :type="successMessage.type"
                    :title="successMessage.title"
                    :actionPrimary="successMessage.actionPrimary"
                    :actionSecondary="successMessage.actionSecondary"
                />
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import Button from '@/components/Button/Button'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { required, requiredIf } from 'vuelidate/lib/validators';
    import { $products, $billingProps } from '../Services';

    export default {
        name: 'ProductsCreate',
        props: {
            insideDrawer: {type: Boolean, default: false}
        },
        components: {
            OutlinedCard,
            Breadcrumbs,
            Button,
            SuccessMessage
        },
        data() {
            return {
                codeCheckingLoading: false,
                codeSapCheckError: false,
                codeSapWasChecked: false,
                createNewProductStatus: false,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {},
                status: true,
                name: null,
                description: null,
                billingCodeSap: null,
                selectedProducts: [],
                productProps: [],
                productsRelated: [],
                productPropsProducts: [],
            }
        },
        validations: {
            name: {
                required
            },
            billingCodeSap: {
                required
            },
            description: {
                required
            },
            productProps: {
                $each: {
                    value: {
                        required: requiredIf(function (nestedModel) {
                            return nestedModel.status
                        })
                    }
                }
            },
            selectedProducts: {
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.prices.products.CREATE && this.$v.$anyDirty && !this.$v.$anyError;
            },
        },
        methods:{
            cancelCreation() {
                if(this.insideDrawer) {      
                    this.$emit('closeDrawer');
                    this.clearData();
                }else{
                    this.$router.replace('/products');
                }
            },

            async onCodeSapBlur () {
                this.$v.billingCodeSap.$touch()
                this.codeCheckingLoading = true
                this.codeSapWasChecked = true
                const request = await $products.find('exists', null, {params: {code: this.$v.billingCodeSap.$model}})
                this.codeSapCheckError = request.data.exists
                this.codeCheckingLoading = false
            },
            onBlur (vuelidateLink) {
                vuelidateLink.$touch()
            },
            async createProduct() {
                if (!this.status) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.product.create.alert_message.title'),
                        content: '',
                        actionPrimary: {
                            text: this.$t('modules.product.create.alert_message.actions.primary_text'),
                            callback: () => {
                            this.triggerProductCreation()
                            }
                        },
                        actionSecondary: {
                            text: this.$t('modules.product.create.alert_message.actions.secondary_text'),
                            callback: () => {
                                this.resetButtonValues()
                            }
                        },
                        icon: { 
                            color: 'warning', 
                            name: 'mdi-eye-off-outline' 
                        },
                        color: 'primary',
                    })
                } else {
                    this.triggerProductCreation()
                }
            },
            async triggerProductCreation () {
                if (!this.canCreate) return false
                this.createButton.loading = true;
                const props = [];
                this.productProps.forEach((item) => {
                    if (item.status){
                        props.push({
                            count: item.value,
                            status: true,
                            billingProps: {id: item.id}
                        });
                    }

                });
                const related = [];
                this.selectedProducts.forEach(item => {
                    related.push({parent: item});
                });
                try {
                    const productToCreate = {
                        name: this.name,
                        status: this.status,
                        description: this.description,
                        billingCodeSap: this.billingCodeSap,
                        productProps: props,
                        productsRelated: related
                    }
                    await $products.create(productToCreate)
                    this.successMessage.type = 'success';
                    this.successMessage.title = `Nuevo producto de facturación ${this.name} creado`;
                    if (!this.insideDrawer) {
                        this.successMessage.actionPrimary = {
                            text: this.$t('modules.product.create.success_message.actions.primary_text'), 
                            callback: ()=>{this.$router.push('/products')}
                        };
                        this.successMessage.actionSecondary = {
                            text: this.$t('modules.product.create.success_message.actions.secondary_text'), 
                            callback: ()=>{this.createNewProductStatus = false}
                        };
                    } else {
                        this.successMessage.actionPrimary = {
                            text: this.$t('modules.product.create.success_message.actions.primary_text'),
                            callback: ()=>{
                                this.createNewProductStatus = false;
                                this.$emit('closeDrawer');
                            }
                        }
                        this.successMessage.actionSecondary = null;
                    }
                    this.createNewProductStatus = true;
                    this.createButton.loading = false;
                    this.createButton.success = true;
                    this.clearData();
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = this.$t('modules.product.create.error_message.title');
                    this.successMessage.actionSecondary = null;
                    this.successMessage.actionPrimary = {
                        text: this.$t('modules.product.create.error_message.actions.go_back_text'), 
                        callback: ()=>{
                            this.createNewProductStatus = false
                        }
                    }
                    this.createNewProductStatus = true;
                    this.createButton.loading = false;
                    this.createButton.error = true;
                }
            },
            isDisabled (prop) {
                let status = false
                if (!prop.status){
                    prop.value = ''
                    status =  true
                } else{
                    status =  false
                }
                return status
            },
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            clearData() {
                this.resetButtonValues();
                this.$v.$reset();
                this.status = true;
                this.name = null;
                this.description = null;
                this.billingCodeSap = null;
                this.productProps = [];
                this.selectedProducts = [];
            },
            async fetchData() {
                const productBilling = await $billingProps.find()
                this.productProps = productBilling.data.content.map((prop)=>{
                    const tmpProp = {
                        description: prop.description,
                        id:prop.id,
                        propType: prop.propType,
                        status: false,
                        value: ''
                    }
                    return tmpProp
                })
                const productList = await $products.find(null, null, { params: { page: 0, length: 2000 } })
                this.productsRelated = productList.data.content;
            },
        },
        mounted(){
            this.fetchData();
        },
    }
</script>

<style scoped>
.linear-progess-code {
    top: -10px;
}
</style>