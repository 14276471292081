<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom px-6" v-if="!createNewSubject">
        <template v-if="!insideDrawer">
          <Breadcrumbs :title="$t('modules.applications.breadcrumbs.title')"></Breadcrumbs>
        </template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <ApplicationsGrid :myData="myData"></ApplicationsGrid>
            </v-col>
          </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import ApplicationsGrid from '../Components/ApplicationsGrid/ApplicationsGrid'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    ApplicationsGrid

  },
  data () {
    return {
      myData: [
        {
          name: 'Administrativo',
          texto: 'Descripción de la aplicación.',
          status: 'Estado',
          imagen: 'mdi-account-outline',
          estado: true,
          modulos: '17 Módulos'
        },
        {
          name: 'Docente',
          texto: 'Descripción de la aplicación.',
          status: 'Estado',
          imagen: 'mdi-school',
          estado: true,
          modulos: '9 Módulos'
        },
        {
          name: 'Estudiante',
          texto: 'Descripción de la aplicación.',
          status: 'Estado',
          imagen: 'mdi-apps',
          estado: true,
          modulos: '12 Módulos'
        }
      ],
      breadcrumbs: {
        title: 'Aplicaciones'
      }

    }
  }
}
</script>
