<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :description="$t('modules.prices.table.breadcrumbs.description')"
          :title="$t('modules.prices.table.breadcrumbs.title')"
        >
          <Button
            v-if="canUpdate"
            @clicked="reindex"
            :text="$t('modules.prices.table.reindex')"
            depressed
            outlined
            class="mr-4"
          ></Button>
          <Button
            v-if="canCreate"
            @clicked="newPrice"
            :icon="breadcrumbs.button.icon"
            :text="$t('modules.prices.table.create')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :items="prices"
              :clear-selected="clearSelected"
              :pageCount="pageCount"
              :totalItems="prices.length"
              :permissions="{update: canRead || canUpdate, delete: canDelete}"
              @pageChange="(val) => page=val"
              @lengthPaginationChange="(val) => itemsPerPage=val"
              @update="(id)=>{updatePrice(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button';
import { $prices, $products } from '../Services';
// import moment from 'moment'

export default {
  name: 'PricesList',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      clearSelected: '',
      breadcrumbs: {
        title: 'Precios',
        description: 'Precios disponibles en el sistema.',
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      headers: [
        {
          text: this.$t('modules.prices.table.headers.product'),
          value: 'product',
        },
        {
          text: this.$t('modules.prices.table.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.prices.table.headers.from'),
          value: 'from',
        },
        {
          text: this.$t('modules.prices.table.headers.careers'),
          value: 'careers',
        },
        {
          text: this.$t('modules.prices.table.headers.details'),
          value: 'details',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      prices: [],
      page: 1,
      itemsPerPage: 20,
      itemsInAPI: null,
    }
  },
  computed: {
    canRead() {
      return this.$permissions.portaladministrativo.prices.prices.READ
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.prices.UPDATE
    },
    canCreate() {
      return this.$permissions.portaladministrativo.prices.prices.CREATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.prices.DELETE
    },
    pageCount () {
      return this.prices.length % this.itemsPerPage === 0 ? (this.prices.length / this.itemsPerPage | 0) : (this.prices.length / this.itemsPerPage | 0) + 1
    },
  },
  methods: {

    async fetchData (page, itemsPerPage) {
      this.clearSelected = new Date().getMilliseconds().toString();
      this.prices = [];

      try {
        const [prices, billingProducts] = await Promise.all([
          $prices.find(null, null, { params: { page: page - 1, length: itemsPerPage, orderBy: 'id', orientation: 'desc' } }),
          $products.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } })
        ]);

        this.prices = this.formatPrices(prices.data.content,billingProducts.data.content)
        this.itemsInAPI = prices.data.totalElements
      } catch {
        this.prices = []
      }
    },

    formatProductsFilter (products) {
      return products.map(product => {

        const tmpFilter = {
          name: product.name,
          type: 'check',
          value: false,
        };
        return tmpFilter
      })
    },

    formatPrices (prices, billingProducts) {
      return prices.map(price => {
        const billingProduct = billingProducts.find((bp) => price.billingProducts.id == bp.id);

        const tmpPrice = {
          id: price.id,
          product: billingProduct ? billingProduct.name : '',
          status: price.status,
          from: price.from,
          careers: price.careerCount,
          details: price.detailsCount
        };

        return tmpPrice;
      })
    },

    // redirects
    newPrice () {
      if (this.canCreate) this.$router.push('/prices/create')
    },
    updatePrice (id) {
      if (this.canRead || this.canUpdate) this.$router.push(`prices/update/${id}`)
    },
    reindex () {
      if (this.canUpdate) this.$router.push('/prices/reindex')
    },

  },

  mounted () {
    this.fetchData(this.page, 500) // this.itemsPerPage (Backend's pagination)
  },
}
</script>
