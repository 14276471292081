<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
          <Button
            v-if="canCreate"
            @clicked="newDiscount"
            :icon="breadcrumbs.button.icon"
            :text="breadcrumbs.button.name"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="discounts"
              :clear-selected="clearSelected"
              :deleteTitle="$t('modules.discounts.table.bulk_delete')"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="bulkDeletePopUp"
              @deleteUser="deleteDiscountPopUp"
              @updateUser="updateDiscount"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              @searchAction="searchAction"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $discounts } from '../Services';

export default {
  name: 'DiscountsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      clearSelected: '',
      breadcrumbs: {
        title: this.$t('modules.discounts.table.breadcrumbs.title'),
        description: this.$t('modules.discounts.table.breadcrumbs.description'),
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      totalItems: null,
      pagination: {
        limit: 20,
        page: 1,
        pageAmount: 1
    },
    sort: {
        sortBy: 'code',
        sortDesc: false,
    },
      discounts: [],
      headers: [
        {
          text: this.$t('modules.discounts.table.headers.code'),
          value: 'code',
        },
        {
          text: this.$t('modules.discounts.table.headers.name'),
          value: 'description',
        },
        {
          text: this.$t('modules.discounts.table.headers.product_type'),
          sortable: false,
          value: 'productType',
        },
        {
          text: this.$t('modules.discounts.table.headers.status'),
          value: 'status',
        },
        {
          text: '',
          sortable: false,
          value: 'action'
        }
      ],
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.discounts.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.discounts.READ || this.$permissions.portaladministrativo.prices.discounts.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.discounts.DELETE
    },
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    changed (parent, filter, value) {
      this.asidebar.items[parent].filters[filter].value = value
    },
    deleteDiscountPopUp (id) {
      const discount = this.discounts.find(discount => discount.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [{ value: discount.description }],
        actionPrimary: { text: 'Aceptar', callback: () => this.deleteDiscount(id) },
        actionSecondary: { text: 'Cancelar', callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    async fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();
      try {
        const params = {
            page: this.pagination.page - 1,
            length: this.pagination.limit,
            orderBy: this.sort.sortBy,
            orientation: this.sort.sortDesc ? 'desc' : 'asc',
            search: this.search
        }
        const discounts = await $discounts.find(null, null, { params});
        this.discounts = discounts.data.content.map(item => {
          return {
          id: item.id,
          code: item.code,
          description: item.description,
          productType: item.products.map (({name}) => ({name})),
          status: item.status,
          }
        })
        this.totalItems = discounts.data.totalElements
        this.pagination.pagesAmount = discounts.data.totalPages
      } catch (error) {
        this.discounts = []
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw error
      }
    },
    bulkDeletePopUp (discounts) {
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: discounts.map(discount => ({
          value: discount.description
        })),
        actionPrimary: { 
          text: 'Aceptar', 
          callback: async () => {
            try { 
              await this.bulkDelete(discounts);
            } catch {
              this.$store.dispatch('commons/openPopUp', {
                title: this.$t(
                  'modules.discounts.table.bulk_delete_error'
                ),
                content: discounts.map(discount => ({
                  value: discount.description
                })),
                actionPrimary: { text: 'Aceptar', callback() {} },
                icon: { color: 'error', name: 'mdi-close' },
                color: 'primary'
                });
              }
          }
        },
        actionSecondary: { text: 'Cancelar', callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
        });
    },
    async bulkDelete (discounts) {
      const itemsForDelete = discounts.map((discount) => {
        return { id: discount.id }
      });

      await $discounts.bulkDelete(itemsForDelete)
      itemsForDelete.forEach(item => {
        this.discounts = this.discounts.filter(discount => discount.id !== item.id);
      });
    },
    newDiscount () {
      if (this.canCreate) this.$router.push('/discounts/create')
    },
    updateDiscount (id) {
      if (this.canUpdate) this.$router.push(`discounts/update/${id}`)
    },
    async deleteDiscount (id) {
      try {
        await $discounts.delete(id);
        this.discounts = this.discounts.filter(discount => discount.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.discounts.table.delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error
      }
    },
    onInputPagination(event) {
        this.pagination.page = event;
        this.fetchData();
    },
    onLengthPaginationChange(event) {
        this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.fetchData();
    },
    searchAction(data) {
        this.pagination.page = 1;
        this.search = data;
        this.fetchData();
    },
  },
   watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
}
</script>
