import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('commission');

export class Payings extends Api {
  constructor(http, config = {}) {
    super(http, url, 'payings', config);
  }
}
