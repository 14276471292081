<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewPracticeTermsStatus">
      <Breadcrumbs
        :title="$t('modules.studentSubjects.enrollment.breadcrumbs.titlePractice')"
        :description="$t('modules.studentSubjects.enrollment.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.studentSubjects.create.codeSection.title')"
            :subtitle="$t('modules.studentSubjects.create.codeSection.subtitle')"
            class="mb-6"
          >
          <br>
            <v-row>
              <v-col sm="6" class="py-2">
                      <v-select
                        :error-messages="($v.newReferredCompany.selectedTerm.$dirty && $v.newReferredCompany.selectedTerm.$invalid) ?
                        $t('modules.studentSubjects.create.error_message.requiredTerms') : ''"
                        :label="`${$t('modules.studentSubjects.create.textField.term')} *`"
                        :items="terms"
                        item-text="term.description"
                        @blur="$v.newReferredCompany.selectedTerm.$touch()"
                        outlined
                        return-object
                        v-model="$v.newReferredCompany.selectedTerm.$model"
                      ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                      <v-autocomplete
                        :error-messages="($v.newReferredCompany.selectedSubject.$dirty && $v.newReferredCompany.selectedSubject.$invalid) ?
                        $t('modules.studentSubjects.create.error_message.requiredSubjects') : ''"
                        :label="`${$t('modules.studentSubjects.create.textField.subject')} *` "
                        :items="subjects"
                        item-text="description"
                        @blur="$v.newReferredCompany.selectedSubject.$touch()"
                        outlined
                        return-object
                        v-model="$v.newReferredCompany.selectedSubject.$model"
                      ></v-autocomplete>
                  </v-col>
                </v-row>
                </OutlinedCard>
                <OutlinedCard>
                  <v-row>
                      <v-col sm="12" class="py-2">
                        <v-text-field
                          :error-messages="($v.newReferredCompany.businessName.$dirty && $v.newReferredCompany.businessName.$invalid) ?
                          ((!$v.newReferredCompany.businessName.required) ? $t('modules.studentSubjects.create.error_message.requiredBusinessName') :
                          (!$v.newReferredCompany.businessName.alphabeticWithSpaces) ? $t('modules.studentSubjects.create.error_message.businessNameAlphabeticWithSpaces') :
                          $t('modules.studentSubjects.create.error_message.businessNameMaxLength')) :
                          ''"
                          @blur="$v.newReferredCompany.businessName.$touch()"
                          :label="`${$t('modules.studentSubjects.create.textField.Namebusiness')} *`"
                          outlined
                          v-model="$v.newReferredCompany.businessName.$model"
                        ></v-text-field>
                      </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="py-2">
                    <IdentificationInput
                        :error-messages="($v.newReferredCompany.identification.$dirty && $v.newReferredCompany.identification.$invalid) ?
                        (!$v.newReferredCompany.identification.required ? $t('modules.studentSubjects.create.error_message.requiredIdentification') :
                        $t('modules.studentSubjects.create.error_message.identificationUnique')) :
                        ''"
                        :label="`${$t('modules.studentSubjects.create.textField.identification')} *`"
                        :id-type="$isTeclab ? 'CUIT' : 'RUT'"
                        :loading="loadingIdentification"
                        v-model="$v.newReferredCompany.identification.$model"
                        ref="identification"
                        @posTimer="() => { this.validateIdentification() }"
                        @validateRut="(status) => { this.validRUT = status }"
                        @blur="$v.newReferredCompany.identification.$touch()"
                  ></IdentificationInput>
                  </v-col>
                  <v-col sm="6" class="py-2">
                      <v-text-field
                        :error-messages="($v.newReferredCompany.address.$dirty && $v.newReferredCompany.address.$invalid) ?
                        $t('modules.studentSubjects.create.error_message.requiredAddress') : ''"
                        @blur="$v.newReferredCompany.address.$touch()"
                        :label="`${$t('modules.studentSubjects.create.textField.address')} *`"
                        outlined
                        v-model="$v.newReferredCompany.address.$model"
                      ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-2">
                    <AutocompleteInput
                      :required="$v.newReferredCompany.cityWithState.$invalid"
                      :errorMessages="$t('modules.studentSubjects.create.error_message.requiredCityWithState')"
                      :value.sync="$v.newReferredCompany.cityWithState.$model"
                      :label="`${$t('modules.studentSubjects.create.textField.city')} *`"
                      disable-buttons
                      ref="cityWithState">
                    </AutocompleteInput>
                  </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" class="py-2">
                <v-text-field
                      :label="`${$t('modules.studentSubjects.create.textField.contactName')} * `"
                      :error-messages="$v.newReferredCompany.contactName.$dirty && $v.newReferredCompany.contactName.$invalid ?
                      $t('modules.studentSubjects.create.error_message.requiredContactName') : ''"
                      @blur="$v.newReferredCompany.contactName.$touch()"
                      outlined
                      type="text"
                      v-model="$v.newReferredCompany.contactName.$model"
                      maxlength="40"
                    ></v-text-field>
              </v-col>
              <v-col sm="5" class="py-2">
                    <v-text-field
                      :label="`${$t('modules.studentSubjects.create.textField.email')} * `"
                      :error-messages="$v.newReferredCompany.email.$dirty && $v.newReferredCompany.email.$invalid ?
                      $t('modules.studentSubjects.create.error_message.requiredEmail') : ''"
                      @blur="$v.newReferredCompany.email.$touch()"
                      outlined
                      type="text"
                      v-model="$v.newReferredCompany.email.$model"
                    >
                  </v-text-field>
                </v-col>
              <v-col sm="3" class="py-2">
                <TelephoneInput
                    :value.sync="$v.newReferredCompany.cellphone.$model"
                    :required="$v.newReferredCompany.cellphone.$dirty && $v.newReferredCompany.cellphone.$invalid"
                    :placeholder="`${$t('modules.studentSubjects.create.textField.cellphone')} *`"
                    ref="cellphone"
                    @blur="$v.newReferredCompany.cellphone.$touch()"
                    @validate="isValidPhone"
                  />
              </v-col>
            </v-row>
          </OutlinedCard>

          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.go(-1)"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="createPracticeTerms"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
      />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {$enrollmentByException, $professionalPracticeTerms, $students} from '../Services';
import { required, helpers, maxLength, email} from 'vuelidate/lib/validators';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import IdentificationInput from '@/components/IdentificationInput/IdentificationInput';


export default {
  name: 'EnrollPractice',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    TelephoneInput,
    AutocompleteInput,
    IdentificationInput
  },
  data() {
    return {
      terms: [],
      subjects: [],
      createNewPracticeTermsStatus: false,
      loadingIdentification: false,
      validRUT: false,
      telephoneValid: true,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
      newReferredCompany: {
        selectedSubject: null,
        selectedTerm: null,
        identification: null,
        businessName: null,
        contactName: null,
        address: null,
        email: null,
        cellphone: null,
        cityWithState: null
      },
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.students.subjects.CREATE
    },
  },
  async mounted() {
    await this.getTerms();
    await this.getSubjectsPP();
  },
  methods: {
    async createPracticeTerms() {
      this.createButton.loading = true;
      try {
        if (this.$v.newReferredCompany.$invalid) {
          await this.$v.newReferredCompany.$touch();
          this.$refs.cellphone.handleBlur()
          this.$refs.cityWithState.triggerOuterValidation()
          Object.keys(this.$v.newReferredCompany).some(input => {
              if (input.includes('$')) return false;
            })
          this.createButton.error = true;
          this.createButton.loading = false;
        } else {
        const requestBody = {
            cuit: this.newReferredCompany.identification,
            razonSocial: this.newReferredCompany.businessName,
            direccion: this.newReferredCompany.address,
            contacto: this.newReferredCompany.contactName,
            email: this.newReferredCompany.email,
            telefono: this.newReferredCompany.cellphone,
            legajo: this.$route.params.id,
            localidadId: this.newReferredCompany.cityWithState.idCity,
            regionId: this.newReferredCompany.cityWithState.idRegion,
            ppId: this.newReferredCompany.selectedTerm.id
          };
          await $enrollmentByException.create(requestBody, {params:{subjectId: this.newReferredCompany.selectedSubject.id, termId: this.newReferredCompany.selectedTerm.term.id}} );
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.studentSubjects.create.successMessage.title');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/student-subjects');
            }
          };
          this.createNewPracticeTermsStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
          this.clearData();
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.studentSubjects.create.errorMessage.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewPracticeTermsStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewPracticeTermsStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.newReferredCompany = {
        selectedSubject: null,
        selectedTerm: null,
        identification: null,
        businessName: null,
        contactName: null,
        address: null,
        email: null,
        cellphone: null,
        cityWithState: null
      }
      this.$v.newReferredCompany.$reset()
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    async getTerms() {
      try {
        const terms = await $professionalPracticeTerms.find('terms');
        this.terms = terms.data
      } catch (error) {
        this.terms = [];
        throw error;
      }
    },
    async getSubjectsPP() {
      try {
        const subjectsData = await $students.findById(this.$route.params.id, 'pp-subjects');
        this.subjects = subjectsData.data.map(item =>{
          item.description = `${item.code} - ${item.description}`
          return item
        })
      } catch (error) {
        this.subjects = [];
        this.successMessage.type = 'error';
        this.successMessage.title = error.codeMeaning = this.$t('modules.studentSubjects.create.error_message.subjectNull');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.close'), callback: () => {
              this.$router.push('/student-subjects');
            }
        };
        this.successMessage.actionSecondary = null;
        this.createNewPracticeTermsStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
      }
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.cellphone.handleInput(this.newReferredCompany.cellphone, phoneObject)
    },
    
  },
  validations: {
    newReferredCompany:{
      selectedSubject:{required},
      selectedTerm: {required},
      identification: {
        required,
        validationFormat(value) { return this.validRUT || /^\d{2}-\d{8}-\d$/i.test(value) }
      },
      businessName:{
          required,
          maxLength: maxLength(60),
          alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
          },
      email: {
          required,
          email
        },
      cellphone: {
          valid() {
            return this.telephoneValid
          }
      },
      address: {required},
      cityWithState: { required },
      contactName:{
          required,
          alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
          },
    },
  }
};
</script>