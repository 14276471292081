import rangesTranslations from './Ranges/Translations'
import configTranslations from './Config/Translations'
import objectivesTranslations from './Objectives/Translations'
import prizesTranslations from './Prizes/Translations'
import sellersTranslations from './Sellers/Translations'

const commissionsTranslations = {
    es: {
        ranges: { ...rangesTranslations.es },
        config: { ...configTranslations.es },
        objectives: { ...objectivesTranslations.es },
        prizes: { ...prizesTranslations.es },
        sellers: { ...sellersTranslations.es },
    },
    en: {
        ranges: { ...rangesTranslations.en },
        config: { ...configTranslations.en },
        objectives: { ...objectivesTranslations.en },
        prizes: { ...prizesTranslations.en },
        sellers: { ...sellersTranslations.en },
    }
};

export default commissionsTranslations