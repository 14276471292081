<template>
  <v-container fluid class="d-flex flex-row pa-0 fullHeight">
    <v-container class="container-custom">
      <template class="container-custom fullHeight d-flex align-center justify-center">
  
        <v-row>
          <v-col cols="11" class="d-flex flex-column mx-auto">
            <v-sheet color="#f5f6f9"> 
              <v-row class="m-2">
                
                <v-col sm="3">
                  <div class="pl-5 ml-5"> 
                    <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.name') }}</h4>
                    <span>{{ procedure.student ? procedure.student.user.userData.name : '' }}</span>
                  </div>
                </v-col>
                <v-col sm="3"> 
                  <div class="pr-5 mr-5">
                    <h4 class="primary--text">{{ $t('modules.procedures.initUpdate.lastname') }}</h4>
                    <span>{{ procedure.student ? procedure.student.user.userData.lastname : '' }}</span>
                  </div>
                </v-col>
                <v-col sm="3"> 
                  <div class="pr-5 mr-5">
                    <h4 class="primary--text">{{ $isTeclab ? $t('modules.procedures.initUpdate.identificationTeclab') : $t('modules.procedures.initUpdate.identificationIpp')  }}</h4>
                    <span>{{ procedure.student && procedure.student.user.userData.identification ? procedure.student.user.userData.identification : $t('modules.procedures.initUpdate.identificationEmpty') }}</span>
                  </div>
                </v-col>
                <v-col sm="3"> 
                  <div class="pr-5 mr-5">
                    <h4 class="primary--text">{{$t('modules.procedures.initUpdate.status')}}</h4>
                    <span>{{ procedure.processingStatus ? procedure.processingStatus.meaning : $t('modules.procedures.initUpdate.stateEmpty') }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
            <v-col sm=11 class="d-flex flex-column mx-auto">
              <from-to-card
                :from="{
                  title: procedure.student ? procedure.student.studentCareers.career.description : '',
                  subtitle: procedure.student ? procedure.student.studentCareers.career.studyPlan.description : ''
                }"
                :to="{title: destinationCareer, subtitle: studyPlan ? studyPlan.code : ''}"
              ></from-to-card>
              <v-card block class="cards-outlined mb-3" outlined color="#F6F7FB">
                <v-row>
                  <v-col sm="12">
                    <h5 class="primary--text pl-6">{{$t('modules.procedures.initUpdate.reason')}}</h5>
                    <h4 class="pl-6">{{ procedure.commentary && procedure.commentary !== '' ? procedure.commentary : procedure.processingsReasonType ? procedure.processingsReasonType.meaning : $t('modules.procedures.initUpdate.noReason') }}</h4>
                  </v-col>
                </v-row>
              </v-card>
               <v-card block class="cards-outlined mb-3" outlined color="#F6F7FB">
                <v-row>
                  <v-col sm="10">
                    <h5 class="primary--text pl-6">{{$t('modules.procedures.initUpdate.changeStudyPlan')}}</h5>
                      <v-autocomplete
                        class="pl-6 mt-2"
                        v-model="studyPlanSelected"
                        outlined
                        :label="$t('modules.portalalumno.procedures.careerChange.dataSection.studyPlan')"
                        :items="studyPlans"
                        item-text="description"
                        return-object
                        hide-details
                        @change="$emit('getData', studyPlanSelected)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
               </v-card>
              <MultipleStatusGroups
                :items="subjects"
                :noItemsMessage="$t('modules.procedures.initUpdate.subjectsEmpty')"
                class="mt-5"
              ></MultipleStatusGroups>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm=11 class="d-flex flex-column mx-auto">
              <MultipleProductsCosts :items="prices"></MultipleProductsCosts>
            </v-col>
          </v-row>
          <v-row>
          </v-row>
          <v-row v-if="canUpdateState">
            <v-col sm=10 class="d-flex flex-column mx-auto">
              <div class="d-flex justify-end mb-12">
              <Button
                white
                :text="$t('actions.reject')"
                @clicked="disapprove"
                @end="resetButtonValues"
                :disabled="!canUpdate"
                class="mr-4"
              ></Button>
              <Button
                :loading="updateButton.loading"
                :success="updateButton.success"
                :error="updateButton.error"
                :disabled="!canUpdate"
                :text="$t('actions.approve')"
                :successText="$t('actions.created')"
                :errorText="'Error'"
                @end="resetButtonValues"
                @clicked="approve"
              ></Button>
            </div>
            </v-col>
          </v-row>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import MultipleStatusGroups from '@/components/MultipleStatusGroups/MultipleStatusGroups'
import MultipleProductsCosts from '@/components/MultipleProductsCosts/MultipleProductsCosts'
import FromToCard from '@/components/FromToCard/FromToCard'
import Button from '@/components/Button/Button'
import { $careers } from '../Services'

export default {
  name: 'ChangeCareerProcedure',
  components: {
    Button,
    MultipleStatusGroups,
    FromToCard,
    MultipleProductsCosts,
  },
  props: {
    procedure: Object,
    prices: Array,
    subjects: Array,
    studyPlan: Object
    
  },
  computed: {
    canUpdateState() {
      return this.procedure.processingStatus && this.procedure.processingStatus.value == 91
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.procedures.UPDATE
    },
  },
  data () {
    return {
        destinationCareer: '',
        studyPlans: null,
        studyPlanSelected: this.studyPlan,
        updateButton: {
          loading: false,
          success: false,
          error: false
        },
    }
  },
  methods: {
    approve() {
      if (this.canUpdate) this.$emit('approve')
    },
    disapprove() {
      if (this.canUpdate) this.$emit('disapprove')
    },
    resetButtonValues () {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    },
    goBack () {
      this.$router.push('/procedures')
    },
    async getDestinationCareer(){
      const destinationCareer = await $careers.findById(this.procedure.destination, null, null)
      this.destinationCareer = destinationCareer.data.description
      this.studyPlans = destinationCareer.data.studyPlans.map((e) => {
        return ({
          description: `${e.studyPlan.description} - ${e.degreesFinal.description}` ,
          code: e.studyPlan.description,
          id: e.studyPlan.id
        })
      })
    },
  },
  async mounted(){
    await this.getDestinationCareer()
  },
  watch: {
    studyPlanSelected(){
      this.getDestinationCareer()
    }
  }
}
</script>