<template>
  <div class="px-4">
    <v-row>
      <v-col>
        <strong class="ContractPreviewAcademicDetailsCard-title">
          {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.title') }}
        </strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.career') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ careerName }}
          </span>
        </div>
      </v-col>
      <v-col cols="3" offset="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.modality') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ modality }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.city') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ city }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.semesterLength') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ semesters }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.jornada') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ jornada }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.semiannualListTariff') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ arancel | $currency}}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.finalSemesterTariff') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ finalArancel | $currency}}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.paymentMethod') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ formaPago }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.enrollmentValue') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ valorMatricula | $currency }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.installmentsNumber') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ cuotas }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.academicSection.installmentValue') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewAcademicDetailsCard-detail--description">
            {{ valorCuota | $currency }}
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: [
    'arancel',
    'careerName',
    'city',
    'cuotas',
    'finalArancel',
    'formaPago',
    'jornada',
    'modality',
    'semesters',
    'valorCuota',
    'valorMatricula'
  ]
}
</script>

<style lang="sass" scoped>
.ContractPreviewAcademicDetailsCard
  &-title
    color: #011856
  &-detail--title
    color: #2a3d71
    font-size: 13px
  &-detail--description
    font-size: 13px
</style>