<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template v-if="!insideDrawer">
        <v-container class="container-custom">
          <Breadcrumbs
            :title="$t('modules.roles.create.breadcrumbs.title')"
            :description="$t('modules.roles.create.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-container>
      </template>
      <template v-if="!createNewRolStatus">
        <Stepper
          :stepper="stepper"
          :currentStep="currentStep"
          navButtons
          :loading="loading"
          :canFinish="canCreateRol"
          :finishButton="finishButton"
          :canGoToNextStep="canGoToNextStep"
          :insideDrawer="insideDrawer"
          @changeStep="changeStep"
          @confirm="createNewRol"
          @resetFinishButtonValues="resetFinishButtonValues"
          @cancelAction="cancelAction"
          v-if="!createNewRolStatus"
        >
          <v-container class="container-custom mt-4">
            <v-row no-gutters justify="center">
              <v-col sm="10">
                <v-stepper-content class="pa-0 text-center" step="1">
                  <roles-stepper-form :formData="newRol" :rolTypeOptions="rolTypeOptions" ref="form"></roles-stepper-form>
                </v-stepper-content>
                <v-stepper-content class="pa-0 text-center" step="2">
                  <roles-stepper-permissions
                    :applications="applications"
                  ></roles-stepper-permissions>
                </v-stepper-content>
              </v-col>
            </v-row>
          </v-container>
        </Stepper>
      </template>
      <SuccessMessage
        v-else
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Stepper from '@/components/Stepper/Stepper';
import RolesStepperForm from '../Components/RolesStepperForm/RolesStepperForm';
import RolesStepperPermissions from '../Components/RolesStepperPermissions/RolesStepperPermissions';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $types, $applications } from '../Services';

export default {
  props: {
    insideDrawer: Boolean
  },
  components: {
    Stepper,
    Breadcrumbs,
    RolesStepperForm,
    RolesStepperPermissions,
    SuccessMessage,
  },
  data () {
    return {
      currentStep: 1,
      stepper: [
        {
          title: this.$t('modules.roles.create.stepper.details.title'),
          button: this.$t('actions.next'),
          available: true,
        }, {
          title: this.$t('modules.roles.create.stepper.applications.title'),
          button: this.$t('modules.roles.create.stepper.applications.createRolButton'),
          available: false,
        },
      ],
      finishButton: {
        loading: false,
        success: false,
        error: false,
        text: this.$t('actions.create'),
        successText: this.$t('actions.created'),
        errorText: 'error',
      },
      newRol: {
        name: '',
        description: '',
        rolType: {},
      },
      rolTypeOptions: [],
      loading: false,
      applications: [],
      createNewRolStatus: null,
      successMessage: {
        title: this.$t('modules.roles.create.messages.createSuccess'),
        actionPrimary: null
      }
    }
  },
  watch: {
    canGoToNextStep(value) {
      this.stepper = this.stepper.map(step => {
        step.available = value;
        return step
      })
    }
  },
  computed: {
    redirectPath () {
      return this.insideDrawer ? '' : '/roles'
    },
    canGoToNextStep () {
      return !!this.newRol.name.length && !!this.newRol.description.length && !!this.newRol.rolType.meaning
    },
    canCreateRol () {
      return this.canCreate && !!this.newRol.name.length;
    },
    canCreate() {
      return this.$permissions.portaladministrativo.roles.CREATE
    }
  },
  methods: {
    cancelAction() {
      if (this.insideDrawer) {
        this.clearData();
        this.$emit('closeDrawer')
      }
    },
    clearData() {
      this.createNewRolStatus = false
      this.currentStep = 1
      this.stepper[0].available = true
      this.stepper[1].available = false
      this.newRol = {
        name: '',
        description: '',
        rolType: {}
      }
      this.loading = false
      this.applications = []
      this.resetFinishButtonValues()
      if (this.$refs.form) this.$refs.form.resetValidations()
      this.fetchData()
    },
    filterChildren (child) {
      if (child.status) {
        if (child.children) child.children = child.children.filter(this.filterChildren)
        if (child.permissions) child.permissions = child.permissions.filter(perm => perm.name == 'READ' ? true : perm.status)
      }
      return child.status;
    },
    cleanUselessData (child) {
      if (child.children) child.children = child.children.map(this.cleanUselessData)
      if (child.permissions) child.permissions = child.permissions.map(perm => perm.name === 'READ' ? ({ id: perm.id, status: true }) : ({ id: perm.id, status: perm.status }))
      return { id: child.id, children: child.children, permissions: child.permissions };
    },
    createNewRol () {
      if (!this.canCreate) return false;
      let applications = JSON.parse(JSON.stringify(this.applications));

      if (applications) {
        //FILTRA APLICACIONES DESHABILITADAS
        applications = applications.filter((app) => app.status);

        //FILTRA MODULOS DESHABILITADOS
        applications = applications.map(app => {
          if (app.modules) app.modules = app.modules.filter(module => module.status);
          return app;
        });

        //RECURSIVA SOBRE CHILDS
        applications = applications.map(app => {
          if (app.modules) app.modules = app.modules.filter(this.filterChildren)
          return app;
        })

        //CLEAN USELESS DATA
        applications = applications.map(app => {
          if (app.modules) app.modules = app.modules.map(this.cleanUselessData)
          return { id: app.id, modules: app.modules }
        })
      }

      this.finishButton.loading = true;
      this.$users.createRol({ id: null, ...this.newRol, applications })
        .then(() => {
          if (!this.insideDrawer) this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.$router.push('/roles') } }
          else this.successMessage.actionPrimary = { text: this.$t('actions.accept'), callback: () => { this.cancelAction() } }
          this.loading = false;
          this.finishButton.success = true;
          this.createNewRolStatus = true;
        }).catch(() => {
          this.loading = false;
          this.finishButton.error = true;
          this.createNewRolStatus = false;
        })
    },
    setPermissionsToFalse (child) {
      if (child.children) child.children = child.children.map(this.setPermissionsToFalse)
      child.allowed = child.status;
      child.status = false;

      if (child.permissions) child.permissions = child.permissions.map((perm) => { perm.status = false; return perm; })
      return child;
    },
    async fetchData () {
      this.loading = true;

      try {
        let applications = await $applications.find();
        const rolesTypes = await $types.find(null, null, { params: { type: 'ROLS_TYPE' } })
        this.rolTypeOptions = rolesTypes.data
        applications = applications.data.map(app => {
          if (app.modules) app.modules = app.modules.map(this.setPermissionsToFalse)
          return app;
        });
        this.applications = applications;
      } finally {
        this.loading = false;
      }
    },
    changeStep (step) {
      this.currentStep = step;
    },
    resetFinishButtonValues () {
      this.finishButton.loading = false;
      this.finishButton.success = false;
      this.finishButton.error = false
    },
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>
