import api from '@/api'

const { $qSystem, $core } = api;

export const { $exams } = $qSystem;
export const { $users } = $core;

export {default as pefByCourseAndUser} from './pefByCourseAndUser'
export {default as getExam} from './getExam'
export {default as getExamPreview} from './getExamPreview'
