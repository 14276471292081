import Benefits from '../Benefits.vue'
import BenefitsTable from '../Views/BenefitsTable.vue';
import BenefitsCreate from '../Views/BenefitsCreate.vue';
import BenefitsUpdate from '../Views/BenefitsUpdate.vue';
import BenefitsDetails from '../Views/BenefitsDetails.vue';


const BenefitsRoute =
{
  path: '/benefits',
  meta: {
    name: {
      es: 'Beneficios',
      en: 'Benefits',
    }
  },
  component: Benefits,
  children: [
    {
      path: '',
      component: BenefitsTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'benefits', permission: 'READ' }
    },
    {
      path: 'create',
      component: BenefitsCreate,
      meta: { name: 'BenefitsCreate', group: 'portaladministrativo.prices', path: 'benefits', permission: 'CREATE' }
    },
    {
      path: 'update/:id',
      component: BenefitsUpdate,
      meta: { name: 'BenefitsUpdate', group: 'portaladministrativo.prices', path: 'benefits', permission: 'READ' }
    },
    {
      path: 'details/:id',
      component: BenefitsDetails,
      meta: { name: 'BenefitsDetails', group: 'portaladministrativo.prices', path: 'benefits', permission: 'READ' }
    },

  ]
};

export default BenefitsRoute
