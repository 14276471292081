const documentation = {
    es_PE: {
        main: {
            delivered: 'Estudiantes con Documentos Aprobados',
        },
        table: {
            headers: {
                name: 'Estudiante'
            },
        },
    },
    es: {
        breadcrumbs: {
            title: 'Gestión de Documentación',
            description: 'Estado de documentación obligatoria',
        },
        main: {
            delivered: 'Alumnos con Documentos Aprobados',
            activeDocs: 'Documentos Activos',
        },
        filters: {
            title: 'Refinar búsqueda',
            status: 'Estado',
            sub1: 'Estados',
        },
        status: {
            delivered: 'Aprobados',
            rejected: 'Rechazados',
            overdue: 'Vencidos',
            pending: 'Pendientes',
            revision: 'Revision',
        },
        panels:{
            tooltip:{
                94: 'Documentos que cumplen con los requisitos solicitados',
                95: 'Documentos que no cumplen con los requisitos solicitados',
                257: 'Documentos que se vencieron y tiene que renovar',
                96: 'Documentos no entregados a los CEOs',
                258: 'Documentos esperando ser verificados',
            },
            delivered: 'Estudiantes con Documentos Aprobados',
        },
        table: {
            headers: {
                studentProcessingId: 'Tramite',
                name: 'Alumno',
                identification: 'DNI',
                studentCareerId: 'Legajo',
                career: 'Carrera',
            },
            search: 'Buscar por identificacion',
        },
        studentInnerTable: {
            headers: {
                studentProcessingId: 'Tramite',
                typeDescription: 'Tipo',
                subtypeDescription: 'Sub-Tipo',
                subtypeSubdescription: 'Descripción',
                status: 'Estado',
                file: 'Archivo',
                commentary: 'Comentario',
                dueDate: 'Vencimiento',
            },
        },
        docTypes: {
            addNewTypeTextField: 'Tipo',
            addNewTypeBtn: 'Agregar Nuevo Tipo',
            addNewTypeName: 'Nombre del Nuevo Tipo',
            addNewSubTypeBtn: 'Agregar Sub-Tipo',
            headers: {
                description: 'Sub-Tipo',
                subDescription: 'Sub-Description',
                validity: 'Vigencia',
            },
        },
        form: {
            required: 'Invalido y requerido',
            invalid: 'Datos inválidos',
        },
        popUp: {
            type: {
                insert: 'Está por crear un nuevo Tipo: ',
                inserted: 'Tipo creado correctamente.',
                name: 'Estudiante'
            },
            subtype: {
                insert: 'Está por crear un nuevo Sub-Tipo: ',
                inserted: 'Sub-Tipo creado correctamente.',
                update: 'Está por actualizar el Sub-Tipo: ',
                updated: 'Sub-Tipo actualizado correctamente.',
                delete: 'Está por eliminar el Sub-Tipo: ',
                deleted: 'Sub-Tipo eliminado correctamente.',
            },
            continue: '¿Desea continuar?',
        },
    },
};

export default documentation;
