<template>
  <div>
    <v-container fluid class="container-custom fullHeight">
      <Breadcrumbs
        :title="$t('modules.exams_group.statements.programsUpdate.breadcrumbs.title')">
      </Breadcrumbs>
      <v-container class="container-custom px-6" v-if="!createNewProgram">
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <OutlinedCard
              :title="$t('modules.exams_group.statements.programsCreate.programName')"
              class="mb-6"
              @switchChange="changeStatus"
              :switch-value="status"
              :switch-label="(status) ? $t('enabled') : $t('disabled')" 
              switcher>
                <v-row>
                  <v-col sm="6" class="py-2">
                  <v-text-field
                    :label="`${$t('modules.exams_group.statements.programsCreate.insertName')}*`"
                    v-model="$v.program.description.$model"
                    outlined
                    :error-messages="($v.program.description.$dirty && $v.program.description.$invalid) ?
                      ((!$v.program.description.required) ? $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionRequired') :
                      (!$v.program.description.alphaNumWithSpaces) ? $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionAlphanumericWithSpaces') :
                      $t('modules.exams_group.statements.programsCreate.validation_errors.descriptionMaxLength')) :
                      ''"
                    @keypress="$validateAlphanumeric($event, $v.program.description.$model,60, true)"
                    @blur="$v.program.description.$touch()"
                  ></v-text-field>
                </v-col>
                  <v-col sm="3" class="py-2">
                    <v-text-field
                      :label="`${$t('modules.exams_group.statements.programsCreate.code')} *`"
                      outlined
                      v-model="$v.program.code.$model"
                      :error-messages="($v.program.code.$dirty && $v.program.code.$invalid) ?
                        ((!$v.program.code.required) ? $t('modules.exams_group.statements.programsCreate.validation_errors.codeRequired') :
                        (!$v.program.code.maxLength) ? $t('modules.exams_group.statements.programsCreate.validation_errors.codeMaxLength') :
                        $t('modules.exams_group.statements.programsCreate.validation_errors.codeAlphanumeric')) :
                        ''"
                      @keypress="$validateAlphanumeric($event, $v.program.code.$model,10)"
                      @blur="$v.program.code.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
            </OutlinedCard>
          </v-col>
        </v-row>

        <div class="d-flex justify-end">
          <Button
            class="ml-4"
            @clicked="$router.go(-1)"
            depressed
            white
            :text="$t('modules.exams_group.statements.programsCreate.cancel')"
          >
          </Button>
          <Button
            :loading="createButton.loading"
            :success="createButton.success"
            :error="createButton.error"
            depressed
            class="ml-4"
            :text="$t('modules.exams_group.statements.programsUpdate.save')"
            :successText="$t('actions.created')"
            :errorText="$t('actions.error')"
            @end="resetButtonValues()"
            @clicked="updateProgramPopUp"
          ></Button>
        </div>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import Button from '@/components/Button/Button'
import { mapGetters } from 'vuex'
import { required, maxLength, alphaNum, helpers } from 'vuelidate/lib/validators';
import { $statements } from '../Services'

export default {
  name: 'ProgramsUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage
  },
  props: {
    switcher: Boolean,
    switchValue: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isActive: true,
      createNewProgram: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
      program: {
          id: '',
          description: '',
          code: '',  
      },
      status: true,
      userId: '',
    }
  },
  computed: {
    ...mapGetters(['commons/getUserData', 'commons/getUserID'])
  },
  mounted ()   {
      this.getProgramsData();
  },
  methods: {
    async getProgramsData (){
      try {
        const programData = await $statements.find(`/program/${this.$route.params.program_id}`);
          this.program.code = programData.data.code,
          this.program.description = programData.data.description,
          this.program.status = programData.data.status
          this.status = programData.data.status
          this.program.id = programData.data.id
      } catch (error) {
          this.programData
      }
    },
    async updateProgram () {
      if( this.$v.$invalid ) {
        return this.$v.$touch()
      }
      try {
        this.createButton.loading = true
        const userId = this['commons/getUserID'];
        const {program} = this;
        program.status = this.status;
        await $statements.update(`program`, program, {params:{userId}})
        this.createButton.success = true;
        this.createButton.loading = false;
        this.createdSuccesfully()
      } catch (error) {
        this.createButton.loading = false;
        this.createButton.error = true;
      }
    },
    changeStatus() {
      this.status = !this.status;
    },
    resetButtonValues() {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    updateProgramPopUp () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('errorMessage') }],
          actionPrimary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      }    
      else if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('alert'),
          content: [{ value: this.$t('disabledMessageUpdate') }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.updateProgram() },
          actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary',
        });
      } else {
        this.updateProgram();
      }
    }, 
    createdSuccesfully() {
      this.successMessage.type = 'success';
      this.successMessage.title = this.$t(
        'modules.exams_group.statements.programsUpdate.success_message.title'
      );
      this.successMessage.actionPrimary = {
        text: this.$t('modules.exams_group.statements.programsCreate.finish'),
        callback: () => {
          this.$router.go(-1)
        }
      };
      this.createNewProgram = true;
    },
  },
  validations: {
    program: {
        description: {
          required,
          maxLength: maxLength(60),
          alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]*(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
        },
        code: {
          required,
          maxLength: maxLength(10),
          alphaNum,
        },
      },
  },
};
</script>

<style lang="sass" scoped></style>
