<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template v-if="!succeed">
      <v-container class="container-custom">
        <Breadcrumbs
          :title="$t('modules.quarters.update.breadcrumbs.title')"
          :description="$t('modules.quarters.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <v-row justify="center">
          <v-col class="viewSpaces" sm="12">
            <OutlinedCard
              :title="$t('modules.quarters.update.sectionData.title')"
              :subtitle="$t('modules.quarters.update.sectionData.subtitle')"
              :switch-value.sync="newQuarter.status"
              :switch-label="newQuarter.status
                ? $t('modules.quarters.moduleState.enable')
                : $t('modules.quarters.moduleState.disable')"
              switcher
              class="mb-6"
            >
              <v-row>
                <v-col sm="9">
                  <v-text-field
                    outlined
                    :label="`${$t('modules.quarters.create.sectionData.items.description')} *`"
                    v-model="$v.newQuarter.description.$model"
                    :error="$v.newQuarter.description.$error"
                    :error-messages="$v.newQuarter.description.$error
                      ? $t('modules.quarters.errors.sectionData.items.description')
                      : ''"
                    required
                  />
                </v-col>
                <v-col sm="3">
                  <v-text-field
                    :loading="loadingCode"
                    :error-messages="errorCode
                      ? $t('modules.quarters.errors.sectionData.items.code.duplicate')
                      : $v.newQuarter.code.$error
                        ? $t('modules.quarters.errors.sectionData.items.code.required')
                        : ''"
                    :label="`${$t('modules.quarters.create.sectionData.items.code')} *`"
                    v-model="$v.newQuarter.code.$model"
                    :error="errorCode || $v.newQuarter.code.$error"
                    @blur="validateCode"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <div class="d-flex">
                <v-checkbox
                  color="primary"
                  class="pa-0 mt-0"
                  v-model="newQuarter.exam"
                  :label="$t('modules.quarters.update.sectionData.items.test')"/>
              </div>
              <span class="body-2">
                {{ $t('modules.quarters.update.sectionData.items.testDescription') }}
              </span>
            </OutlinedCard>
            <OutlinedCard
              :title="$t('modules.quarters.update.sectionContact.title')"
              :subtitle="$t('modules.quarters.update.sectionContact.subtitle')"
              class="mb-6"
            >
              <v-row>
                <v-col sm="6" class="pt-0 pb-0">
                  <v-text-field
                    :label="`${$t('modules.quarters.create.sectionContact.items.addressName')} *`"
                    v-model="$v.newQuarter.address.street.$model"
                    :error="$v.newQuarter.address.street.$error"
                    :error-messages="$v.newQuarter.address.street.$error
                      ? $t('modules.quarters.errors.sectionContact.items.address.street')
                      : ''"
                    outlined
                    required
                  />
                </v-col>
                <v-col sm="2" class="pt-0 pb-0">
                  <v-text-field
                    type="number"
                    min="0"
                    max="999999"
                    :label="$t('modules.quarters.create.sectionContact.items.addressNumber')"
                    v-model="newQuarter.address.number"
                    @keypress="$validateIntegerInputNumber($event, newQuarter.address.number, null, 6)"
                    outlined
                  />
                </v-col>
                <v-col sm="2" class="pt-0 pb-0 smallinput">
                  <v-text-field
                    type="number"
                    min="0"
                    max="300"
                    :label="$t('modules.quarters.create.sectionContact.items.addressFloor')"
                    v-model="newQuarter.address.floor"
                    @keypress="$validateIntegerInputNumber($event, newQuarter.address.floor, 300)"
                    requried
                    outlined
                  />
                </v-col>
                <v-col sm="2" class="pt-0 pb-0 smallinput">
                  <v-text-field
                    :label="$t('modules.quarters.create.sectionContact.items.addressApartment')"
                    v-model="newQuarter.address.apartment"
                    outlined
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6" class="pt-0 pb-0">
                  <autocomplete-input
                    :value.sync="$v.newQuarter.address.cityWithState.$model"
                    :label="`${$t('modules.quarters.create.sectionContact.items.cityWithState')} *`"
                    :error="$v.newQuarter.address.cityWithState.idCity.$error"
                    :error-messages="$v.newQuarter.address.cityWithState.idCity.$error
                      ? $t('modules.quarters.errors.sectionContact.items.address.cityWithState')
                      : ''"
                    :disable-buttons="true"
                  />
                </v-col>
                <v-col sm="3" class="pt-0 pb-0">
                  <v-text-field
                    :label="$t('modules.quarters.create.sectionContact.items.zipCode')"
                    v-model="newQuarter.address.zipCode"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <TelephoneInput
                    :placeholder="$t('modules.quarters.create.sectionContact.items.landline')"
                    :value.sync="newQuarter.phoneNumber"
                    :country.sync="newQuarter.phoneCountry"
                  ></TelephoneInput>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <TelephoneInput
                    :placeholder="`${$t('modules.quarters.create.sectionContact.items.mobilePhone')}`"
                    :value.sync="$v.newQuarter.celNumber.$model"
                    :country.sync="newQuarter.celCountry"
                    :error="$v.newQuarter.celNumber.$error"
                    :error-messages="$v.newQuarter.celNumber.$error
                      ? $t('modules.quarters.errors.sectionContact.items.celNumber')
                      : ''"
                    @blur="$v.newQuarter.celNumber.$touch()"
                  ></TelephoneInput>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col sm="6" class="pt-0 pb-0">
                  <v-text-field
                    type="email"
                    :label="`${$t('modules.quarters.create.sectionContact.items.email')} *`"
                    v-model="$v.newQuarter.email.$model"
                    :error="$v.newQuarter.email.$error"
                    :error-messages="$v.newQuarter.email.$error && !$v.newQuarter.email.required
                      ? $t('modules.quarters.errors.sectionContact.items.email.required')
                      : $v.newQuarter.email.$error && !$v.newQuarter.email.email
                        ? $t('modules.quarters.errors.sectionContact.items.email.email')
                        : ''"
                    outlined
                  />
                </v-col>
              </v-row>
            </OutlinedCard>
            <OutlinedCard
              :title="$t('modules.quarters.update.sectionSchedule.title')"
              :subtitle="$t('modules.quarters.update.sectionSchedule.subtitle')"
              class="mb-6"
            >
              <MultipleFormGroup
                :items="days"
                @addGroup="()=>{this.days.push({days: [], start: null, end: null, menu:{ref:null,end:false, start: false}})}"
                @removeGroup="(i)=>{removeGroup(i)}"
                mandatory
              >
                <template v-slot:default="{item, index}">
                  <v-row>
                    <template v-for="(day,i) in weekdays">
                      <v-col sm="1" :key="i" class="py-0 d-flex justify-center align-center">
                        <template
                          v-if="!(day && day.value == null ? !!day.value : !(day.value == index))"
                        >
                          <RoundCheckbox
                            :checkbox-key="`${i}+${index}`"
                            :text="day.name"
                            :value="!!item.days[i]"
                            @change="() => { checkItem(item, index, i); buildSchedule(); $v.newQuarter.schedules.$touch(); }"
                            :error="$v.newQuarter.schedules.$error"
                          />
                        </template>
                      </v-col>
                    </template>
                    <v-col class="py-0 d-flex align-center">
                      <v-menu
                        :ref="`menu-start-${index}`"
                        v-model="item.menu.start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.start"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="`${$t('modules.quarters.create.sectionSchedule.items.from')} *`"
                            :error="$v.newQuarter.schedules.$error"
                            :error-messages="$v.newQuarter.schedules.$error
                              ? $t('modules.quarters.errors.sectionSchedule.items.schedule')
                              : ''"
                            append-icon="mdi-calendar-clock"
                            class="no-message mt-2"
                            @blur="$v.newQuarter.schedules.$touch()"
                            v-model="item.start"
                            v-on="on"
                            readonly
                            outlined
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.menu.start"
                          v-model="item.start"
                          :max="item.end"
                          ampm-in-title
                          @click:minute="$refs[`menu-start-${index}`].save(item.start); buildSchedule()"
                          class="no-message"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="py-0 d-flex align-center">
                      <v-menu
                        :ref="`menu-end-${index}`"
                        v-model="item.menu.end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="item.end"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="`${$t('modules.quarters.create.sectionSchedule.items.to')} *`"
                            :error="$v.newQuarter.schedules.$error"
                            :error-messages="$v.newQuarter.schedules.$error
                              ? $t('modules.quarters.errors.sectionSchedule.items.schedule')
                              : ''"
                            append-icon="mdi-calendar-clock"
                            class="no-message mt-2"
                            @blur="$v.newQuarter.schedules.$touch()"
                            v-model="item.end"
                            v-on="on"
                            readonly
                            outlined
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="item.menu.end"
                          v-model="item.end"
                          :min="item.start"
                          ampm-in-title
                          @click:minute="$refs[`menu-end-${index}`].save(item.end); buildSchedule()"
                          class="no-message"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </template>
              </MultipleFormGroup>
            </OutlinedCard>
            <OutlinedCard
              :title="$t('modules.quarters.update.sectionBilling.title')"
              :subtitle="$t('modules.quarters.update.sectionBilling.subtitle')"
            >
              <v-text-field
                outlined
                v-model="newQuarter.cbu"
                :label="$t('modules.quarters.create.sectionBilling.items.bankAccountNumber')"
              />
              <v-text-field
                outlined
                v-model="newQuarter.accountName"
                :label="$t('modules.quarters.create.sectionBilling.items.bankAccountName')"
              />
              <v-text-field
                outlined
                v-model="newQuarter.bankCode"
                :label="$t('modules.quarters.create.sectionBilling.items.bankCode')"
              />
              <v-text-field
                outlined
                v-model="newQuarter.bank"
                :label="$t('modules.quarters.create.sectionBilling.items.bankName')"
              />
              <v-text-field
                outlined
                v-model="newQuarter.branchCode"
                :label="$t('modules.quarters.create.sectionBilling.items.subsidiaryCode')"
              />
              <v-text-field
                outlined
                v-model="newQuarter.branch"
                :label="$t('modules.quarters.create.sectionBilling.items.subsidiaryName')"
              />
            </OutlinedCard>
            <OutlinedCard
              :title="$t('modules.quarters.create.sectionCareers.title')"
              :subtitle="$t('modules.quarters.create.sectionCareers.subtitle')"
              class="mb-6"
            >
              <TransferList
                :avatar="false"
                :loading="loadingSearchCareers"
                :availableItems.sync="careers"
                :added-items.sync="careersAdded"
                areEquals="id"
              ></TransferList>
            </OutlinedCard>
            <OutlinedCard
              :title="$t('modules.quarters.create.sectionUsers.title')"
              :subtitle="$t('modules.quarters.create.sectionUsers.subtitle')"
              class="mb-6"
            >
              <TransferList
                :avatar="true"
                :loading="loadingSearchUsers"
                item-text="description"
                :availableItems.sync="users"
                :added-items.sync="usersAdded"
                areEquals="id"
              ></TransferList>
            </OutlinedCard>
            <div class="d-flex justify-end">
              <Button
                white
                :text="$t('actions.cancel')"
                @clicked="$router.replace('/quarters')"
                @end="resetButtonValues"
                class="mr-4"
              ></Button>

              <Button
                :loading="saveButton.loading"
                :success="saveButton.success"
                :error="saveButton.error"
                :text="$t('actions.save')"
                :successText="$t('actions.saved')"
                :errorText="'Error'"
                :disabled="!canUpdate || $v.newQuarter.$invalid"
                @end="resetButtonValues"
                @clicked="checkDisableModule"
              ></Button>
            </div>
          </v-col>
        </v-row>
      </v-container>
      </template>
      <success-message
        v-else
        :title="$t('modules.quarters.success.popup.title', { action: 'actualizado' })"
        :actionPrimary="{
          text: this.$t('modules.quarters.success.popup.btnPrimary'),
          callback: async () => this.$router.replace('/quarters')
        }"
      />
    </v-container>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { almostOneSelected } from '@/helpers/custom-validations';
import { $careers, $ceos, $users } from '../services';
import Constants from '@/plugins/constants'
import { mapGetters } from 'vuex';

export default {
  name: 'QuartersUpdate',
  components: {
    TransferList: () => import('@/components/TransferList/TransferList.vue'),
    RoundCheckbox: () => import('@/components/RoundCheckbox/RoundCheckbox.vue'),
    OutlinedCard: () => import('@/components/OutlinedCard/OutlinedCard.vue'),
    MultipleFormGroup: () => import('@/components/MultipleFormGroup/MultipleFormGroup.vue'),
    Breadcrumbs: () => import('@/components/Breadcrumbs/Breadcrumbs'),
    Button: () => import('@/components/Button/Button.vue'),
    TelephoneInput: () => import('@/components/TelephoneInput/TelephoneInput.vue'),
    AutocompleteInput: () => import('@/components/AutocompleteInput/AutocompleteInput.vue'),
    SuccessMessage: () => import('@/components/SuccessMessage/SuccessMessage')
  },
  data () {
    return {
      loadingSearchUsers: false,
      loadingSearchCareers: false,
      succeed: false,
      paginationLimit: 150,
      days: [
        { days: [], start: null, end: null, menu: { end: false, start: false } }
      ],
      weekdays: [],
      weekdaysSelected: [],
      tmp: '',
      careers: [],
      careersAdded: [],
      users: [],
      usersAdded: [],
      newQuarter: {
        code: null,
        status: true,
        description: null,
        codeSap: null,
        exam: false,
        address: {
          street: null,
          number: null,
          floor: null,
          apartment: null,
          zipCode: null,
          city: null,
          cityWithState: {
            idCity: null,
            idRegion: null,
            cityState: null
          }
        },
        phoneNumber: null,
        celNumber: null,
        phoneCountry: {},
        celCountry: {},
        email: null,
        cbu: null,
        accountName: null,
        bankCode: null,
        bank: null,
        branchCode: null,
        branch: null,
        zones: null,
        schedules: []
      },
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      loadingCode: false,
      errorCode: false,
      originalCode: ''
    };
  },
  validations: {
    newQuarter: {
      description: {
        required
      },
      code: {
        required
      },
      address: {
        street: {
          required
        },
        cityWithState: {
          idCity: {
          }
        }
      },
      celNumber: {
      },
      email: {
        required,
        email
      },
      schedules: {
        required,
        almostOneSelected
      }
    }
  },
  created () {
    this.$t('modules.quarters.update.sectionSchedule.items.weekdays').forEach(
      day => {
        this.weekdays.push({ name: day.slice(0, 1), value: null });
      }
    );
  },
  async mounted() {
    await Promise.all([this.getCareers(), this.getUsers(), this.fetchData()]);
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.quarters.UPDATE
    },
    ...mapGetters({
      userId: 'commons/getUserID',
    }),
  },
  methods: {
    async getCareers (career) {
      this.loadingSearchCareers = true;
      let careers = [];
      if (career) {
        careers = await $careers.find(null, null, {params: { name: career, page: 0, length: 500, orderBy: 'id', orientation: 'desc' }});
      } else {
        careers = await $careers.find(null, null, {params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' }});
      }
      if (careers.data && careers.data.content.length) {
        careers = careers.data.content.map(item => {
          return {
            id: item.id,
            name: item.description
          };
        });

        this.careers = (this.careersAdded.length) ? careers.filter(item => !this.careersAdded.map(item => item.id).includes(item.id)) : careers
      }

      this.loadingSearchCareers = false;
    },
    async getUsers () {
      this.loadingSearchUsers = true;

      const users = await $users.byRolCeo({params: {page: 0, length: 500, userId: this.userId}})
      this.users = users.data.content.map(user => {
        return {
          id: user.id,
          avatar: `${Constants.URL_DATA_SERVER}/users/${user.id}/avatar`,
          name: `${user.name} ${user.lastName}`
        };
      })

      this.loadingSearchUsers = false;
    },
    async fetchData () {
      const { id } = this.$route.params;
      const response = await $ceos.findById(id);
      this.newQuarter = response.data;
      this.originalCode = response.data.code;

      response.data.schedules.sort(function (a, b) {
        return (
          (`${a.start}`).localeCompare(b.start) ||
          (`${a.end}`).localeCompare(b.end)
        );
      });

      const arrayToReduce = response.data.schedules.map(day => {
        const aux = [];
        aux[day.day] = true;
        return {
          days: aux,
          start: day.start.slice(0, 5),
          end: day.end.slice(0, 5),
          menu: { end: false, start: false }
        };
      });

      const newArray = [];
      let lust,
        luen = null;

      for (let i = 0; i < arrayToReduce.length; ++i) {
        if (lust == arrayToReduce[i].start && luen == arrayToReduce[i].end) {
          const obj = {
            start: lust,
            end: luen,
            menu: { end: false, start: false }
          };
          const days = [];
          for (let k = 0; k < 7; ++k) {
            days[k] =
              newArray[newArray.length - 1].days[k] ||
              arrayToReduce[i].days[k];
          }
          obj.days = days;
          newArray[newArray.length - 1] = obj;
        } else {
          lust = arrayToReduce[i].start;
          luen = arrayToReduce[i].end;
          newArray.push({ ...arrayToReduce[i] });
        }
      }

      this.days = newArray;

      if (!this.days.length) {
        this.days = [
          {
            days: [],
            start: null,
            end: null,
            menu: { end: false, start: false }
          }
        ];
      }

      this.days.forEach((day, i) => {
        this.weekdays = this.weekdays.map((weekday, index) => {
          if (day.days[index]) weekday.value = i;
          return weekday;
        });
      });
      this.careersAdded = this.newQuarter.careers
        ? this.newQuarter.careers.map(item => {
          return {
            id: item.id,
            name: item.description
          };
        })
        : [];
      this.careers = this.careers
        .filter(
          item =>
            !this.careersAdded.map(item => item.id).includes(item.id)
        )
        .map(item => ({ name: item.name, id: item.id }));
      this.usersAdded = this.newQuarter.users
        ? this.newQuarter.users.map(item => {
          return {
            id: item.id,
            name: `${item.userData.name} ${item.userData.lastname}`,
            avatar: `${Constants.URL_DATA_SERVER}/users/${item.id}/avatar`
          };
        })
        : [];
      this.users = this.users
        .filter(
          item =>
            !this.usersAdded.map(item => item.id).includes(item.id)
        )
        .map(item => ({
          name: item.name,
          id: item.id,
          avatar: item.avatar
        }));
      this.newQuarter.address.cityWithState.idCity =
        this.newQuarter.address.city && this.newQuarter.address.city.id;
      this.$v.newQuarter.schedules.$touch();

      if (
        this.newQuarter.address.cityWithState.idCity &&
        this.newQuarter.address.cityWithState.cityState
      ) {
        this.$v.newQuarter.address.cityWithState.idCity.$touch();
      }
    },
    removeGroup (i) {
      this.days.splice(i, 1);
      this.days.forEach((day, i) => {
        this.weekdays = this.weekdays.map((weekday, index) => {
          if (day.days[index]) weekday.value = i;
          return weekday;
        });
      });
    },
    checkItem (item, index, weekday) {
      item.days[weekday] = !item.days[weekday];
      if (item.days[weekday]) this.weekdays[weekday].value = index;
      else this.weekdays[weekday].value = null;

      let flag = false;
      this.weekdays.forEach(item => {
        if (item.value == null || item.value == undefined) flag = true;
      });
      if (!flag) this.removeUnusedRows();
    },
    removeUnusedRows () {
      const arr = [];
      this.days.forEach((item, i) => {
        let flag = false;
        item.days.forEach(day => {
          if (day) {
            flag = true;
          }
        });
        if (!flag) arr.push(i);
      });

      this.days = this.days.filter((_, i) => !arr.includes(i));
      this.weekdays = this.weekdays.map(item => {
        item.value = null;
        return item;
      });
      this.days.forEach((day, i) => {
        this.weekdays = this.weekdays.map((weekday, index) => {
          if (day.days[index]) weekday.value = i;
          return weekday;
        });
      });
    },
    buildSchedule () {
      const days = this.days
        .map(day => {
          const arr = [];
          day.days.forEach((wd, i) => {
            if (wd) {
              arr.push({
                day: i,
                start: `${day.start}:00`,
                end: `${day.end}:00`
              });
            }
          });
          return arr;
        })
        .flat();

      this.newQuarter.schedules = days;
      this.$v.newQuarter.schedules.$touch();
    },
    async createQuarter () {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;
      this.newQuarter.address.cityWithState = { idCity : 2 } // TODO 
      this.newQuarter.users = this.usersAdded
        ? this.usersAdded.map(item => {
          return {
            id: item.id
          };
        })
        : [];
      this.newQuarter.careers = this.careersAdded
        ? this.careersAdded.map(item => {
          return {
            id: item.id
          };
        })
        : [];
      try {
        if (!this.$v.newQuarter.$invalid) {
          await $ceos.update(this.$route.params.id, this.newQuarter);
          this.saveButton.success = this.succeed = true;
        }
      } catch (error) {
        this.saveButton.error = true;

        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.quarters.errors.popup.title', {
            action: 'actualizar'
          }),
          content: error.message,
          actionPrimary: {
            text: this.$t('modules.quarters.errors.popup.btnPrimary'),
            callback () { }
          },
          actionSecondary: null,
          icon: { color: 'error', name: 'mdi-cancel' },
          color: 'primary'
        });
      } finally {
        this.saveButton.loading = false;
      }
    },
    removeItems () { },
    addItemsCareers (items) {
      this.careersAdded = items;
    },
    addItemsUsers (items) {
      this.usersAdded = items;
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    async validateCode () {
      this.loadingCode = true;

      try {
        if (
          this.originalCode !== this.newQuarter.code &&
          this.newQuarter.code
        ) {
          const response = await $ceos.find('exists', null, {
            params: {
              code: this.newQuarter.code,
              codeSap: this.newQuarter.codeSap || ''
            }
          });

          this.errorCode = response.data.exists;
        }
      } finally {
        this.loadingCode = false;
      }
    },
    checkDisableModule () {
      const canContinue = this.newQuarter.status;

      if (!canContinue) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.quarters.warnings.moduleDisable.title'),
          content: [
            {
              value: ''
            }
          ],
          actionPrimary: {
            text: this.$t('modules.quarters.warnings.moduleDisable.btnPrimary'),
            callback: () => {
              this.createQuarter();
            }
          },
          actionSecondary: {
            text: this.$t('modules.quarters.warnings.moduleDisable.btnCancel'),
            callback () { }
          },
          icon: { color: 'warning', name: 'mdi-alert' },
          color: 'primary'
        });
      } else {
        this.createQuarter();
      }
    }
  }
};
</script>
