<template>
    <OutlinedCard
        :title="$t('modules.comisiones.dashboard.progress.title')"
        class="mb-6"
    >
        <v-row class="flex-sm-column-reverse flex-md-row">
            <v-col sm="8" class="d-md-none mx-auto">
              <v-btn
                @click="download"
                class="mt-md-8 text-uppercase caption"
                block
                height="44"
                color="primary"
              >
                <v-icon left>mdi-download</v-icon>
                {{ $t('modules.comisiones.dashboard.filters.selects.download') }}
              </v-btn>
            </v-col>
            <v-col md="5" cols="12">
                <v-card class="ma-2 " :elevation="1">
                    <v-row>
                        <v-col md="4" sm="4">
                            <apexchart
                                 class="chart"
                                :options="optionsObjetive"
                                :series="serieObjetive"
                                height="140"
                            ></apexchart>
                        </v-col>
                        <v-col md="8" sm="8" class="ml-0 pl-0" cols="12">
                            <span class="h6 font-weight-medium text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardSales.title')}}</span>
                            <br/>
                            <span class="subtitle-1 grey--text">vs </span>
                            <span class="subtitle-1 grey--text text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardSales.titleTwo')}}</span>
                            <p class="font-weight-black text-center text-md-left mb-0 mt-3"><span>{{`${completeSalesAmount} / ${termObjetiveAmount}`}}</span> {{$t('modules.comisiones.dashboard.progress.cardSales.subtitle')}}</p>
                            <p class="caption grey--text text-center text-md-left pl-1 pl-sm-0 "><span :class=" objetiveAmount > salesAmount ? 'red--text' : 'green--text'">{{(Math.abs(completeAmount)).toFixed(2)}}%<v-icon small :color=" objetiveAmount > salesAmount ? 'red' : 'green'" >{{objetiveAmount > salesAmount ? 'mdi-arrow-down' : objetiveAmount == salesAmount ? '': 'mdi-arrow-up'}}</v-icon></span>{{$t('modules.comisiones.dashboard.progress.cardSales.description')}}</p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-2">
                      <v-row>
                        <v-col md="4" sm="4">
                            <apexchart
                                 class="chart"
                                :options="optionsPreviusObjetive"
                                :series="seriePreviusObjetive"
                                height="140"
                            ></apexchart>
                        </v-col>
                        <v-col md="8" sm="8" class="ml-1 ml-sm-0 pl-0" cols="12">
                            <span class="h6 font-weight-medium text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardSubPeriod.title')}}</span>
                            <br/>
                             <span class="subtitle-1 grey--text">vs </span>
                             <span class="subtitle-1 grey--text text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardSubPeriod.titleTwo')}}</span>  
                             <p class="font-weight-black text-center text-md-left mb-0 mt-3"><span >{{completeSalesAmount}} / {{objetiveAmount}} </span> {{$t('modules.comisiones.dashboard.progress.cardSubPeriod.subtitle')}}</p> 
                             <p class="caption grey--text text-center text-md-left pl-1 pl-sm-0"><span :class=" Math.sign(previousCompleteObjetivePercentage) == -1 ? 'red--text' : 'green--text'">{{(Math.abs(previousCompleteObjetivePercentage)).toFixed(2)}}%<v-icon small :color=" Math.sign(previousCompleteObjetivePercentage) == -1 ? 'red' : 'green'" >{{Math.sign(previousCompleteObjetivePercentage) == -1 ? 'mdi-arrow-down' : Math.sign(previousCompleteObjetivePercentage) == 0 ? ' ' :'mdi-arrow-up'}}</v-icon></span> {{$t('modules.comisiones.dashboard.progress.cardSubPeriod.description')}}</p>
                        </v-col>
                        
                    </v-row>                    
                </v-card>
                <v-card class="ma-2">
                    <v-row>
                        <v-col md="4" sm="4" class="d-flex justify-center align-center mr-0">
                           <span class="grey lighten-2 btnDays text-center">{{dateEndRange}}<br/>{{$t('modules.comisiones.dashboard.progress.cardCommission.titleDay')}}</span>
                        </v-col>
                        <v-col md="8" sm="8" class="ml-0 pl-0" cols="12">
                            <p class="caption grey--text mb-1 text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardCommission.title')}}</p>
                            <p class="text-darken-1 mb-1 text-center text-md-left">{{$t('modules.comisiones.dashboard.progress.cardCommission.subtitle')}}</p>  
                            <p class="font-weight-black text-center text-md-left"><span>{{salesNeedToPrize}}</span> {{$t('modules.comisiones.dashboard.progress.cardCommission.description')}} <span class="pl-1 pl-lg-15 pl-md-1">+{{extraPercentagePayment}}%</span></p> 
                            <div class="pl-10 pl-sm-0 pl-sm-15 pl-md-0"><v-chip :label="true" :x-small="true"  v-for="item in prizesPercentages" :key="item" :color="item == extraPercentagePayment ? 'success': null" :close="false" class="ma-0 mr-1 ">{{item}} %</v-chip> </div>               
                        </v-col>
                        
                    </v-row>  
                </v-card>
            </v-col>
            <v-col md="7"  cols="12" class="d-flex justify-center align-center">
               
                <apexchart :options="areaOptions" :series="series" type="area" :width="500" :heigth="420"></apexchart>
    
            </v-col>
        </v-row>
    </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import apexchart from 'vue-apexcharts';
import ApexCharts from 'apexcharts'
import moment from 'moment'
import { $sellers } from '../Services/index';
import download from 'downloadjs';
import { mapGetters } from 'vuex';

export default {
    name: 'Progress',
    components: {
        OutlinedCard,
        apexchart,
    },
    props:{
        filters : new Object,
        prizes : new Object
    },
    data() {
        return {
             salesAmount: 0,
             objetiveAmount: 0,
             completeAmount: 0,
             dateEndRange:0,
             termObjetiveAmount: 0,
             completeSalesAmount: 0,
             completeObjetivePercentage: 0,
             salesNeedToPrize: 0,
             rangeObjetive: 0,
             rangeSales: 0,
             previousCompleteSalesAmount: 0,
             previousCompleteObjetivePercentage: 0,
             extraPercentagePayment:0,
             prizesPercentages:[],
             rangesObjetives:[],
             areaOptions: {
                chart: {
                    id: 'chartArea',
                    stacked: false,
                    toolbar: {
                    show: false,
                  },
                 },
                 responsive: [
                  {
                    breakpoint: 700,
                    options: {
                        chart:{
                            width:400
                        }
                    }
                  },
                   {
                    breakpoint: 1100,
                    options: {
                        chart:{
                            width:500
                        }
                    }
                  },
                   {
                    breakpoint: 450,
                    options: {
                        chart:{
                            width:350
                        }
                    }
                  },
                  {
                    breakpoint: 380,
                    options: {
                        chart:{
                            width:280
                        }
                    }
                  },
                ],
                tooltip: {
                    enabled: true,
                    shared: true,
                    followCursor: true,
                    fixed: {
                    enabled: true,
                    position: 'topLeft',  
                },
                },
                legend: {
                  position: 'top',
                  horizontalAlign: 'right'
                },
                colors: ['#25E396','#2E93fA'],
                dataLabels: {
                     enabled: false
                },          
                labels:[],                
                 stroke: {
                    curve: 'smooth'
                 },
                 fill: {
                opacity: 0.8,
                type: 'gradient',
                 gradient: {
                      shade: 'light',
                    shadeIntensity: 0.1
                 },
               },
                  markers: {
                size: 5,
                hover: {
                  size: 9
                }
            },    
            },
            series: [
                {
                   name: 'Progreso Actual',  
                    data: []
                },
                 {
                   name: 'Objetivo Arancelados',
                    data: []
                },
            ],
            optionsObjetive: {
                chart: {
                    type: 'radialBar',
                },
                legend: {
                show: false
                    },
                plotOptions: {
                    radialBar: {
                        
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                offsetY: 4,
                                fontSize: '10px',
                                show: true,
                            },
                        },
                        hollow:{
                            size:'28%',
                        },
                        track : {
                            background: '#D7D9E2'
                        }
                    },
                }
            },
            serieObjetive: [0],
            optionsPreviusObjetive: {
                chart: {
                    type: 'radialBar',
                    id: 'vuechart-donut2',
                },
                 colors: ['#25E396'],
                plotOptions: {
                    radialBar: {
                       dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                offsetY: 4,
                                fontSize: '10px',
                                show: true,
                            },
                        },
                        hollow:{
                            size:'28%',
                        },
                        track : {
                            background: '#D7D9E2'
                        }
                    },
                },
            },
            seriePreviusObjetive: [0],
        }
    },
    methods: {
        calculatePercentage(total, value) {
          return ((value/total) * 100).toFixed(2)
        },
       async changeData(newCategories, seriesSales, seriesObjetive) {
          this.areaOptions.labels = newCategories
          this.series[0].data = seriesSales
          this.series[1].data = seriesObjetive
           ApexCharts.exec('chartArea', 'updateOptions', {
            labels: newCategories
           })
          ApexCharts.exec('chartArea', 'updateSeries', [
              { data: seriesSales},
              { data: seriesObjetive}
          ])
        },
         async getData() {
             moment.locale('es-es');
              this.completeObjetivePercentage = !this.prizes.completeObjetivePercentage ? 0 : this.prizes.completeObjetivePercentage
              this.completeSalesAmount = !this.prizes.completeSalesAmount ? 0 : this.prizes.completeSalesAmount
              this.salesNeedToPrize = !this.prizes.salesNeedToPrize ? 0 : this.prizes.salesNeedToPrize 
              this.previousCompleteSalesAmount = !this.prizes.previousCompleteSalesAmount ? 0 : this.prizes.previousCompleteSalesAmount
              this.termObjetiveAmount = !this.prizes.termObjetiveAmount ? 0 : this.prizes.termObjetiveAmount 
              this.previousTargetPercentageComplete = !this.prizes.previousTargetPercentageComplete ? 0 : this.prizes.previousTargetPercentageComplete
              this.rangeObjetive = !this.prizes.rangeObjetive ? 0 : this.prizes.rangeObjetive
              this.extraPercentagePayment = !this.prizes.extraPercentagePayment ? 0 : this.prizes.extraPercentagePayment
              this.rangeSales = !this.prizes.rangeSales ? 0 : this.prizes.rangeSales
              this.serieObjetive = [(this.prizes.completeObjetivePercentage).toFixed(2)]
              this.prizesPercentages = this.prizes.prizesPercentages == null ? [] : this.prizes.prizesPercentages
              this.rangesObjetives = this.prizes.rangesObjetives == null ? [] : this.prizes.rangesObjetives 
              const months= JSON.parse(JSON.stringify(this.rangesObjetives))
               months.sort((a, b) => moment(a.startDate) -moment(b.startDate));
               const totalParams = months.filter(el => moment().format('YYYY-MM') >= moment(el.startDate).format('YYYY-MM'))
               let totalSales = 0
               totalParams.forEach(function(el){totalSales += el.salesAmount;});
               let totalObjetive = 0
                totalParams.forEach(function(el){totalObjetive += el.objectiveAmount;});
                this.salesAmount = totalSales
                this.objetiveAmount= totalObjetive
                this.completeAmount = [this.calculatePercentage(totalObjetive, totalSales)]
              const previusSales = months.find(el => moment(el.startDate).format('MMMM') == moment().subtract(1, 'months').format('MMMM'))
              const lastRange = (months[months.length - 1]).endDate 
               this.dateEndRange = moment(lastRange).diff(moment(), 'days')
               const seriesObjetive = months.map(el => el.objectiveAmount ) 
               const seriesSales = months.map(el => el.salesAmount)
                 const dateFormat =  months.map((el, i) => {
                     return months[i]=moment(el.startDate).format('MMMM')
                 })     
               this.changeData(dateFormat, seriesSales, seriesObjetive)
               this.previousCompleteObjetivePercentage = (isNaN(this.calculatePercentage(this.rangeObjetive, this.rangeSales) - this.calculatePercentage(previusSales.objectiveAmount, previusSales.salesAmount))? 0 : (this.calculatePercentage(this.rangeObjetive, this.rangeSales) - this.calculatePercentage(previusSales.objectiveAmount, previusSales.salesAmount) ))
               this.seriePreviusObjetive = [this.calculatePercentage( this.objetiveAmount, this.completeSalesAmount)]

      },
      async download(){
        const file = await $sellers.create(this.filters.sellers, {params: { term: this.filters.term.id }}, `report/${this.userId}`)
        const content = file.headers.get('content-type');
        download(file.data, `Reporte.xls`, content)
      }

    },
    watch: {
     prizes :{
      handler(){
          this.getData();
        },
        deep: true
    }
    },
    computed: {
      ...mapGetters([
        'commons/getUserID'
      ]),
      userId(){
        return this['commons/getUserID']
      },
    }

}
</script>

<style lang="sass" scoped>
.chart 
     padding: 0px 
     margin-bottom: -30px 
     margin-top: 0px 

.btnDays
    height: 60px
    width: 60px
    border-radius: 50%
    align-items: "center"
    padding: 10px 0
    font-weight: 800
.pl-16 
    padding-left : 90px !important

</style>