
const conciliationTranslations = {
  es: {

    tooltips: {
      enable: 'Habilitar',
      disable: 'Deshabilitar',
      enable_not_allowed: 'El módulo no será visible hasta que se habilite de manera global.',
      help_tooltip: 'Desde aquí podrás editar los permisos para este portal',
      alert_tooltip: 'El módulo se encuentra deshabilitado globalmente.'
    },

    table: {
      breadcrumbs: {
        title: 'Conciliación Mercado Pago',
        description: 'Administración de conciliaciónes.',
      },
      bulk_delete: 'Eliminar conciliación',
      bulk_delete_error: 'No se pudieron eliminar las conciliaciones seleccionadas',
      delete_error: 'No se pudo eliminar la conciliación seleccionada',
      see_conciliation: 'Detalles',
      newButon: 'Nueva Conciliación',
      headers: {
        date: 'Fecha',
        fileName: 'Nombre del archivo',
        updatedBy: 'Subido por'
      },
      messages: {
        delete: '¿Estás seguro de eliminar?',
        deleteNotAllowed: 'No puedes eliminar un conciliación con conciliaciones asignados.'
      }
    },

    create: {
      breadcrumbs: {
        title: 'Conciliación Mercado Pago',
        description: 'Administración de conciliaciónes.',
      },
      title: 'Carga de documentación',
      subtitle: 'Adjunta el documento en formato .xls con el nombre "Plantilla de Excel 97-2003."',
      cardDescription: {
        document: 'Documento cargado',
        rowsProccesed: 'filas procesadas con éxito.',
        rowProccesed: 'fila procesada con éxito.',
        nonRowsProccesed: 'ninguna fila procesada',
        errorsProccesed: 'filas errores.',
        errorProccesed: 'fila error.'
      },
      procesedSection: {
        title: 'Procesados',
      },
      processed: 'Procesado',
      unprocesedSection: {
        title: 'No procesados'
      },
      dataColumns: {
        date: 'Fecha',
        hour: 'Hora',
        id: 'ID',
        referenceNumber: 'N° Referencia',
        description: 'Descripción',
        credit: 'Crédito Neto',
        debit: 'Débito Neto',
        quantity: 'Cantidad bruta',
        tariff: 'Tarifa MP',
        method: 'Método de pago'
      },
      buttonDelete: 'Eliminar',
      buttonAccept: 'Aceptar'
    }
  },
  en: {

    tooltips: {
      enable: 'Enable',
      disable: 'Disable',
      help_group: '',
    },

  }
};


export default conciliationTranslations
