<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.examEditor.breadcrumbs.title')"
          :description="$t('modules.examEditor.breadcrumbs.description')"
        ></Breadcrumbs>
        <div class="px-10 mt-4">
          <SuperTable
            :headers="headers"
            :items="exams"
            :states="availableStatus"
            :permissions="{update: canUpdate}"
            @search="getExams"
            @save="saveExam"
          ></SuperTable>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuperTable from '../Components/SuperTable/SuperTable';
import { $examenesAlumno } from '../Services';
import { mapGetters } from 'vuex'

export default {
  name: 'ExamEditor',
  components: {
    Breadcrumbs,
    SuperTable,
  },
  data() {
    return {
      headers:[
        {text: this.$t('modules.examEditor.table.headers.student'), value: 'student'},
        {text: this.$t('modules.examEditor.table.headers.date'), value: 'date'},
        {text: this.$t('modules.examEditor.table.headers.subject'), value: 'subject'},
        {text: this.$t('modules.examEditor.table.headers.status'), value: 'status'},
        {text: this.$t('modules.examEditor.table.headers.score'), value: 'score'},
        {text: '', value: 'saved', sortable: false},
      ],
      exams: [],
      availableStatus: [
        {text:'Aprobado', value: 1},
        {text:'Desaprobado', value: 2},
        {text:'En revisión', value: 3}
      ]
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.exams.editor.UPDATE
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    })
  },
  methods: {
    // initialize(){
    //   // (TODO) - need call types endpoint for exams states...
    // },
    async getExams(filter=null){
      const exams = await $examenesAlumno.find(`/examenes-alumno?identification=${filter}`);
      this.exams = exams.data.map(exam => {
        return {
          student: exam.alumno,
          career: `${exam.codigoCarrera} - ${exam.carrera}`,
          date: exam.fecha,
          subject: `${exam.codigoMateria} - ${exam.materia}`,
          status: exam.estado,
          score: exam.nota,
          saved: true,
          loading: false,
          id: exam.examenId,
        }
      })
    },
    async saveExam(exam){
      exam.loading = true;
      try{
        await $examenesAlumno.update(`update-examen/${exam.id}`, { estado: exam.status, nota: exam.score, user: this.userId ? this.userId : null })
        exam.saved = true;
      }finally{
        exam.loading = false;
      }
    },
  },
  // mounted() {
  //   this.initialize();
  // },
};
</script>
