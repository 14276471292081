<template>
  <div class="container">

    <h1 class="headline">{{$t('modules.examExceptions.create.stepper.availableBoard.component.title')}}</h1>
   <h2 class="body-2">{{$t('modules.examExceptions.create.stepper.availableBoard.component.subtitle')}}</h2>
   <Calendar
      @selectDate="(data)=>selectDate(data)"
    />
    <div class="d-flex justify-end mt-10">
      <Button
        white
        class="mr-3"
        :text="$t('actions.return')"
        @clicked="()=>goBack()"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button'
import Calendar from '../Calendar/Calendar'

export default {
    name:'AvailableBoard',
    components:{
      Button,
      Calendar
    },
    data(){
      return{
        view: null,
      }
    },
    methods:{
      selectDate(data){
        this.$emit('selectDate', data)
      },
      goBack(){
        this.$emit('goBack')
      }
    }
}
</script>

<style lang="sass" scoped>
  .view-select
    width: 60%
</style>