<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <v-row justify="center">
        <v-col sm="12" class="viewSpaces">
          <OutlinedCard
            :title="$t('modules.users.details.workSection.drawer.form.title')"
            class="mb-6"
          >
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-select
                    ref="selectedWorkingStatusType"
                    v-model="selectedWorkingStatusType"
                    :items="workingStatusTypes"
                    :label="$t('modules.users.details.workSection.drawer.form.workingStatus')"
                    item-text="meaning"
                    return-object
                    outlined
                    :error-messages="($v.selectedWorkingStatusType.$dirty && $v.selectedWorkingStatusType.$invalid) ?
                      $t('modules.users.details.workSection.drawer.form.validations.workingStatusRequired') : ''"
                    @blur="$v.selectedWorkingStatusType.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    ref="selectedWorkingQuantityType"
                    v-model="selectedWorkingQuantityType"
                    :items="workingQuantityTypes"
                    :label="$t('modules.users.details.workSection.drawer.form.workersQuantity')"
                    item-text="meaning"
                    return-object
                    outlined
                    :error-messages="($v.selectedWorkingQuantityType.$dirty && $v.selectedWorkingQuantityType.$invalid) ?
                      $t('modules.users.details.workSection.drawer.form.validations.workersQuantityRequired') : ''"
                    @blur="$v.selectedWorkingQuantityType.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    ref="companyName"
                    v-model="$v.companyName.$model"
                    :label="$t('modules.users.details.workSection.drawer.form.companyName')"
                    outlined
                    :error-messages="($v.companyName.$dirty && $v.companyName.$invalid) ?
                      ((!$v.companyName.required) ? $t('modules.users.details.workSection.drawer.form.validations.companyNameRequired') :
                      (!$v.companyName.alphaNumWithSpaces) ? $t('modules.users.details.workSection.drawer.form.validations.companyNameAlphanumericWithSpaces') :
                      $t('modules.users.details.workSection.drawer.form.validations.companyNameMaxLength')) :
                      ''"
                    @keypress="$validateAlphanumeric($event, $v.companyName.$model,60, true, false)"
                    @blur="$v.companyName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    ref="bossName"
                    v-model="$v.bossName.$model"
                    :label="$t('modules.users.details.workSection.drawer.form.bossName')"
                    outlined
                    :error-messages="($v.bossName.$dirty && $v.bossName.$invalid) ?
                      ((!$v.bossName.alphabeticWithSpaces) ? $t('modules.users.details.workSection.drawer.form.validations.bossNameAlphabeticWithSpaces') :
                      $t('modules.users.details.workSection.drawer.form.validations.bossNameMaxLength')) :
                      ''"
                    @keypress="$validateAlphanumeric($event, $v.bossName.$model,60, true, true)"
                    @blur="$v.bossName.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    ref="bossEmail"
                    v-model="$v.bossEmail.$model"
                    :label="$t('modules.users.details.workSection.drawer.form.bossEmail')"
                    outlined
                    :error-messages="($v.bossEmail.$dirty && $v.bossEmail.$invalid) ?
                      $t('modules.users.details.workSection.drawer.form.validations.bossEmailFormat') : ''"
                    @blur="$v.bossEmail.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <TelephoneInput
                    ref="bossTelephone"
                    :value.sync="$v.bossTelephone.$model"
                    :placeholder="$t('modules.users.details.workSection.drawer.form.bossTelephone')"
                    @validate="isValidPhone"
                    @blur="$v.bossTelephone.$touch()"
                  ></TelephoneInput>
                </v-col>
              </v-row>
            </v-container>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              class="mr-4"
              :text="$t('actions.cancel')"
              @clicked="$emit('closeDrawer')"
            ></Button>
            <Button
              :loading="saveButton.loading"
              :success="saveButton.success"
              :error="saveButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              :errorText="$t('actions.error')"
              :disabled="!canUpdateUser"
              @clicked="validateWorkData"
              @end="resetButtonValues"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput';
import Button from '@/components/Button/Button';
import {$types, $work} from '../../services';
import { required, helpers, maxLength, email } from 'vuelidate/lib/validators';

export default {
  name: 'UsersWorkForm',
  props: {
    studentId: Number,
    workDetails: Object
  },
  components: {
    OutlinedCard,
    TelephoneInput,
    Button
  },
  data() {
    return {
      workingDataId: null,
      loadingWorkingStatus: false,
      workingStatusTypes: [],
      selectedWorkingStatusType: null,
      loadingWorkingQuantity: false,
      workingQuantityTypes: [],
      selectedWorkingQuantityType: null,
      companyName: null,
      bossName: null,
      bossEmail: null,
      bossTelephone: null,
      telephoneValid: true,
      saveButton: {
        loading: false,
        success: false,
        error: false
      }
    }
  },
  validations: {
    selectedWorkingStatusType: { required },
    selectedWorkingQuantityType: { required },
    companyName: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ0-9]*(?:[a-zá-úñ0-9]+\s[a-zá-úñ0-9]*)*$/i)
    },
    bossName: {
      maxLength: maxLength(60),
      alphabeticWithSpaces: helpers.regex('withSpace', /^[a-zá-úñ]*(?:[a-zá-úñ]+\s[a-zá-úñ]*)*$/i),
    },
    bossEmail: {
      email
    },
    bossTelephone: {
      valid() {
        return this.telephoneValid || !this.bossTelephone
      }
    }
  },
  computed: {
    canUpdateUser() {
      return this.$permissions.portaladministrativo.users.UPDATE
    }
  },
  watch: {
    workDetails: {
      handler(value) {
        this.workingDataId = value.id
        this.selectedWorkingStatusType = {
          value: value.workStatus?.value
        }
        this.selectedWorkingQuantityType = {
          value: value.workersQuantity?.value
        }
        this.companyName = value.companyName
        this.bossName = value.bossName
        this.bossEmail = value.bossEmail
        this.bossTelephone = value.bossCelNumber
      },
      deep: true
    }
  },
  mounted() {
    this.getWorkingStatus()
    this.getWorkingQuantity()
  },
  methods: {
    async getWorkingStatus() {
      this.loadingWorkingStatus = true
      try {
        const { data } = await $types.find(null, null, { params: { type: 'WORKING_STATUS' } })
        this.workingStatusTypes = data
      } catch (err) {
        this.workingStatusTypes = []
      } finally {
        this.loadingWorkingStatus = false
      }
    },
    async getWorkingQuantity() {
      this.loadingWorkingQuantity = true
      try {
        const { data } = await $types.find(null, null, { params: { type: 'WORKERS_QUANTITY' } })
        this.workingQuantityTypes = data
      } catch (err) {
        this.workingQuantityTypes = []
      } finally {
        this.loadingWorkingQuantity = false
      }
    },
    async updateWorkData() {
      this.saveButton.loading = true;
      const workData = {
        id: this.workingDataId,
        studentId: this.studentId,
        workStatus: this.selectedWorkingStatusType,
        workersQuantity: this.selectedWorkingQuantityType,
        companyName: this.companyName,
        bossName: this.bossName,
        bossEmail: this.bossEmail,
        bossCelNumber: this.bossTelephone
      }
      try {
        await $work.create(workData)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.details.workSection.drawer.form.messages.success'),
          actionPrimary: {
            text: this.$t('actions.accept'), callback: () => {
              this.$emit('closeDrawer')
            }
          },
          icon: { color: 'success', name: 'mdi-check' },
          color: 'primary',
        });
        this.saveButton.success = true;
        this.saveButton.loading = false;
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.users.details.workSection.drawer.form.messages.error'),
          actionPrimary: {
            text: this.$t('actions.close'), callback() {}
          },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        this.saveButton.loading = false;
        this.saveButton.error = true;
        throw err
      }
    },
    isValidPhone(phoneObject) {
      this.telephoneValid = phoneObject.isValid
      this.$refs.bossTelephone.handleInput(this.bossTelephone, phoneObject)
    },
    async validateWorkData() {
      if (this.$v.$invalid) {
        await this.$v.$touch()
        this.$refs.bossTelephone.handleBlur()
        Object.keys(this.$v).some(input => {
          if (input.includes('$')) return false
          if (this.$v[input].$error) {
            this.$refs[input].$el.scrollIntoView({block: 'nearest', behavior: 'smooth'})
            return true
          }
        })
      } else {
        this.updateWorkData()
      }
    },
    resetButtonValues() {
      this.saveButton.loading = false
      this.saveButton.success = false
      this.saveButton.error = false
    }
  }
};
</script>

<style scoped>

</style>
