<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container class="container-custom px-6">
      <Breadcrumbs
        :title="$route.query.name"
        :description="$t('modules.messaging.template.areaTemplates.breadcrumbs.description')"
      >
       <Button v-if="canCreate" @clicked="newMessage" icon="mdi-plus" :text="$t('modules.messaging.template.areaTemplates.newMessage')" depressed></Button>
      </Breadcrumbs>
      <v-row justify="center">
        <v-col sm="12" class="viewSpaces">
          <AreaMessagesTable
            :periods="periods"
            :headers="headers"
            :data="areaMessagesTemplates"
            :show-select="false"
            :page.sync="pagination.page"
            :pageCount="pagination.pagesAmount"
            :itemsPerPage.sync="itemsPerPage"
            :totalItems="totalItems"
            :permissions="{create: canCreate, update: canUpdate, delete: canDelete, sendMessage: canSendMessage}"
            @send-message="openDrawer"
            @searchAction="searchAction"
            @inputPagination="onInputPagination"
            @lengthPaginationChange="onLengthPaginationChange"
            @updateData="updateTemplate"
            @deleteData="deletePopUp"
            @duplicateData="duplicatePopUp"
          />
        </v-col>
      </v-row>
      <TemporaryRightDrawer
        v-if="canSendMessage"
        @tmpDrawerOpen="drawerOpen = false"
        :open="drawerOpen"
        :title="$t('modules.messaging.template.areaTemplates.sendMessage.title')"
        :description="$t('modules.messaging.template.areaTemplates.sendMessage.description')"
      >
        <FileUpload
          :template="selectedTemplate"
          @close="drawerOpen = false"
        ></FileUpload>
      </TemporaryRightDrawer>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
  import AreaMessagesTable from '../Components/AreaMessagesTable'
  import Button from '@/components/Button/Button';


  import FileUpload from '../Components/FileUpload'
  import { $templates  } from '../Services/index'


  export default {
    name:'AreaTable',
    components: {
      AreaMessagesTable,
      Breadcrumbs,
      FileUpload,
      TemporaryRightDrawer,
      Button
    },
    data() {
      return {
        areaMessagesTemplates: [],
        periods:[],
        drawerOpen: false,
        selectedTemplate: null,
        itemsPerPage: 20,
        totalItems: 0,
        search: null,
        sort: {
          sortBy: 'title',
          sortDesc: false,
        },
        pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
        }, 
        
        headers:[
          { text: this.$t('modules.messaging.template.areaTemplates.templateName'), value: 'title' },
          { text: this.$t('modules.messaging.template.areaTemplates.templateState'), value: 'status' },
          { text: '', value: 'action', sortable: false },
        ]
      }
    },
    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.messaging.templates.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.messaging.templates.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.messaging.templates.DELETE
      },
      canSendMessage() {
        return this.$permissions.portaladministrativo.messaging.templates.SEND
      },
    },
    mounted() {
      this.getAreaMessages();
    },
    methods: {
      openDrawer(template) {
        this.selectedTemplate = template
        this.drawerOpen = true
      },
      async getAreaMessages() {
          const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          areaId : this.$route.params.id,
          search: this.search
        };
        try {
          const areaMessagesTemplates = await $templates.find(null, null, { params })
          this.totalItems = areaMessagesTemplates?.data?.totalItems;
          this.pagination.pagesAmount = areaMessagesTemplates.data.totalPages;
          this.areaMessagesTemplates = areaMessagesTemplates.data.templates
        } catch (error) {
          this.areaMessagesTemplates = []
          this.pagination.page = 1
          this.pagination.pagesAmount = 1
          this.totalItems = 0;
          throw error;
        }finally {
        this.loading = false;
        }
      },
      searchAction(data) {
        this.pagination.page = 1;
        this.search = data;
        this.getAreaMessages();
      },
      onInputPagination(event) {
        this.pagination.page = event;
        this.getAreaMessages();
      },
      onLengthPaginationChange(event) {
         this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
          };
        this.getAreaMessages();
      },
      newMessage () {
        this.$router.push(`${this.$route.params.id}/create`)
      },
      updateTemplate(id) {
        if (this.canUpdate) this.$router.push(`${this.$route.params.id}/edit/${id}`);
      },
      duplicatePopUp(id) {
        const template = this.areaMessagesTemplates.find(templ => templ.id == id);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.messaging.banners.table.messages.duplicatePopUpMessage'),
          content: [{value: template.description}],
          actionPrimary: {text: this.$t('actions.duplicate'), callback: () => this.duplicateTemplate(id)},
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {
            }
          },
          icon: {color: 'info', name: 'mdi-content-duplicate'},
          color: 'primary',
        });
      },
       deletePopUp(id) {
        const templates = this.areaMessagesTemplates.find(template => template.id == id);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('delete_popup_title'),
          content: [{value: templates.description}],
          actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteTemplate(id)},
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        });
      },
      async deleteTemplate(id) {
        try {
          await  $templates.delete(id);
          this.getAreaMessages()
        } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('actions.error'),
            content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.banners.table.messages.deleteError')}],
            actionPrimary: {
              text: this.$t('actions.accept'), callback() {
              }
            },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          });
          throw error;
        }
      },
      async duplicateTemplate(id) {
        try {
          await $templates.create(null, null, `${id}/duplicate`);
          this.getAreaMessages()
        } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('actions.error'),
            content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.banners.table.messages.duplicateError')}],
            actionPrimary: {
              text: this.$t('actions.accept'), callback() {
              }
            },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          });
          throw error;
        }
      },
    }
  }
</script>