<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewPracticeTermsStatus">
      <Breadcrumbs
        :title="$t('modules.academic.practiceTerms.update.breadcrumbs.title')"
        :description="$t('modules.academic.practiceTerms.update.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.academic.practiceTerms.create.codeSection.title')"
            :subtitle="$t('modules.academic.practiceTerms.update.breadcrumbs.subtitleUpdate')"
            class="mb-6"
          >
            <v-row>
              <v-col sm="8">
                <v-autocomplete
                 ref="term"
                 :error-messages="($v.term.$invalid && $v.term.$dirty) ?
                    $t('modules.academic.practiceTerms.create.errors.termDescriptionRequired') : '' "
                 :items="terms"
                 :value-sync="$v.term.$model"
                 outlined
                 v-model="$v.term.$model"
                 :label="`${$t('modules.academic.practiceTerms.create.codeSection.labels.term')} *`"
                 item-text="termDescription"
                 @blur="$v.term.$touch()"
                 return-object
                 ></v-autocomplete>
              </v-col>
            </v-row>

             <v-row>
                  <v-col sm="3">
                    <v-card-text>{{ $t('modules.academic.practiceTerms.create.card.initialDate') }}</v-card-text>
                  </v-col>
                  <v-col sm="5">
                    <v-menu
                      ref="dateFromMenu"
                      v-model="dateFromMenu"
                      :close-on-content-click="false"
                      :return-value.sync="initialDatePP"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent(initialDatePP)"
                          :label="$t('modules.academic.practiceTerms.create.card.from')"
                          persistent-hint
                          append-icon="mdi-calendar"
                          readonly
                          color="primary"
                          outlined
                          v-on="on"
                          :error-messages="($v.initialDatePP.$dirty && !initialDatePP) ? $t('modules.academic.practiceTerms.create.errors.initialDateRequired') : ($v.initialDatePP.$error ? $t('modules.academic.practiceTerms.create.errors.datePair') : '')"
                          @blur="$v.initialDatePP.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="initialDatePP"
                        @change="$refs.dateFromMenu.save(initialDatePP)"
                        color="primary"
                        no-title>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3">
                    <v-card-text>{{ $t('modules.academic.practiceTerms.create.card.finalDate') }}</v-card-text>
                  </v-col>
                  <v-col sm="5">
                    <v-menu
                      ref="dateToMenu"
                      v-model="dateToMenu"
                      :return-value.sync="finalDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent(finalDate)"
                          :label="$t('modules.academic.practiceTerms.create.card.until')"
                          persistent-hint
                          append-icon="mdi-calendar"
                          readonly
                          color="primary"
                          outlined
                          v-on="on"
                          :error-messages="($v.finalDate.$dirty && !finalDate) ? $t('modules.academic.practiceTerms.create.errors.finalDateRequired') : ($v.finalDate.$error ? $t('modules.academic.practiceTerms.create.errors.datePair') : '')"
                          @blur="$v.finalDate.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="finalDate"
                        @change="$refs.dateToMenu.save(finalDate)"
                        color="primary"
                        no-title>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3">
                    <v-card-text>{{ $t('modules.academic.practiceTerms.create.card.lastApprovedExam') }}</v-card-text>
                  </v-col>
                  <v-col sm="5">
                    <v-menu
                      ref="dateFinalMenu"
                      v-model="dateFinalMenu"
                      :return-value.sync="lastApprovedExam"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDateToCurrent(lastApprovedExam)"
                          :label="$t('modules.academic.practiceTerms.create.card.dateLastApprovedExam')"
                          persistent-hint
                          append-icon="mdi-calendar"
                          readonly
                          color="primary"
                          outlined
                          v-on="on"
                          :error-messages="($v.lastApprovedExam.$dirty && !lastApprovedExam) ? $t('modules.academic.practiceTerms.create.errors.lastApprovedExamRequired') : ($v.lastApprovedExam.$error ? $t('modules.academic.practiceTerms.create.errors.datePair') : '')"
                          @blur="$v.lastApprovedExam.$touch()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="lastApprovedExam"
                        @change="$refs.dateFinalMenu.save(lastApprovedExam)"
                        color="primary"
                        no-title>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
          </OutlinedCard>
          
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/academic/practice-terms')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="updateButton.loading"
              :success="updateButton.success"
              :error="updateButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              :errorText="$t('actions.error')"
              :disabled="!canUpdate"
              @end="resetButtonValues"
              @clicked="updatePracticeTerms()"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required, helpers} from 'vuelidate/lib/validators';
import {$professionalizingPractices} from '../Services';
import moment from 'moment'

const datePairValid = (fieldNameStart, fieldNameEnd) => (value, vm) => !helpers.req(value && vm[fieldNameEnd] && vm[fieldNameStart]) || moment(vm[fieldNameStart]).isSameOrBefore(moment(vm[fieldNameEnd]))

export default {
  name: 'PracticeTermsUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
  },
  data() {
    return {
      id: '',
      term: null,
      termId: '',
      initialDatePP: '',
      lastApprovedExam: '',
      finalDate:'',
      enabled: false,
      management: 0,
      status: true,
      dateFromMenu: false,
      dateToMenu: false,
      dateFinalMenu: false,
      terms: [],
      createNewPracticeTermsStatus: false,
      updateButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canUpdate() {
    return this.$permissions.portaladministrativo.academic.professional_practice_terms.UPDATE && this.$v.$anyDirty && !this.$v.$anyError;
    },
  },
   mounted() {
    this.getPracticeTerms()
    this.fetchPracticeTerms()
  },
  methods: {
    onCancelDatePickerClick (model) {
        this[model] = false
      },
      onOkDatePickerClick (ref, date) {
        this.$refs[ref].save(date)
      },
      formatDateToCurrent (date) {
        return date ? moment(date).format('DD/MM/YYYY') : null
      },
      async updatePracticeTerms() {
      this.updateButton.loading = true;
      try {
         if (this.$v.$invalid) {
          this.$v.$touch();
          Object.keys(this.$v).some(input => {
              if (input.includes('$')) return false;
              if (this.$v[input].$error) {
                this.$refs[input].$el.scrollIntoView({block: 'center', behavior: 'smooth'});
                return true
              }
            })
          this.createButton.error = true;
          this.createButton.loading = false;
        } else {
          const newPracticeTerms = {
            termDescription: this.term.termDescription,
            termId: this.term.idTerm,
            id: this.id,
            initialDatePP:this.formatDateToCurrent(this.initialDatePP),
            finalDate: this.formatDateToCurrent(this.finalDate),
            lastApprovedExam: this.formatDateToCurrent(this.lastApprovedExam),
            enabled: this.enabled,
            management: this.management,
          };
          await $professionalizingPractices.update(this.$route.params.id, newPracticeTerms);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.academic.practiceTerms.update.successMessage.title');
          this.successMessage.actionPrimary = {text: this.$t('actions.close'), callback: () => {
              this.$router.push('/academic/practice-terms');
            }
          };
           this.successMessage.actionSecondary = {text: this.$t('modules.academic.practiceTerms.update.successMessage.newChange'), callback: () => {
              this.createNewPracticeTermsStatus = false;
            }
           }
          this.createNewPracticeTermsStatus = true;
          this.updateButton.success = true;
          this.updateButton.loading = false;
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.practiceTerms.update.errorMessage.title');
        this.successMessage.actionSecondary = null;
        this.updateButton.loading = false;
        this.updateButton.error = true;
        throw error;
      }
    },
    async fetchPracticeTerms() {      
      const practiceTerms = await $professionalizingPractices.findById(this.$route.params.id);
      this.id = practiceTerms.data.id;
      this.term = { termDescription: practiceTerms.data.termDescription, idTerm: practiceTerms.data.termId} ;      
      this.initialDatePP = practiceTerms.data.initialDatePP;
      this.finalDate = practiceTerms.data.finalDate;
      this.lastApprovedExam = practiceTerms.data.lastApprovedExam;
      this.management = practiceTerms.data.management;
      this.enabled = practiceTerms.data.enabled;

    },
    resetButtonValues() {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    },
   async getPracticeTerms() {
      try {
        const { data } = await $professionalizingPractices.find('terms', null, {params: {practiceId: this.$route.params.id}});
        this.terms = data;
      } catch (err) {
        this.terms = [];
        throw err;
      }
    },
  },
  validations: {
    term: { required },
    initialDatePP: {
      required,
      datePairValid: datePairValid('initialDatePP', 'finalDate')
    },
    finalDate: {
      required,
      datePairValid: datePairValid('initialDatePP', 'finalDate')
    },
    lastApprovedExam:{ required }
  }
};
</script>