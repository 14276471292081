<template>
	<section>
		<v-card v-if="step == 1" outlined class="elevation-2">
			<PPLocationAndShifts :datosCompany="datosCompany" :dataPP="dataPP" :disponibilidadHoraria="disponibilidadHoraria" :step="step" />
			<v-checkbox
				v-model="datosCompany.validacion"
				:label="$t('modules.academic.professionalizingPractice.update.steppers.manage.validation')"
				:true-value="1"
				:false-value="0"
				:readonly="datosCompany.estadoPPId != 267 || loading"
				@change="updateValidation(datosCompany.validacion)"
				class="ml-10"
			/>
		</v-card>
		<v-card v-if="step == 2" outlined class="elevation-2 pa-8">
			<CheckRequirementsContinue
				:dataPP="dataPP"
				:step="step"
				:datosCompany="datosCompany"
				:updatePP="updatePP"
				@error="error"
				@loading="loadingEmit"
				:loading="loading"
			/>
			<v-divider class="ma-4" />
			<CheckDocsSent :step="step" :dataPP="dataPP" :datosCompany="datosCompany" @error="error" @loading="loadingEmit" :loading="loading" />
		</v-card>
		<v-card v-if="step == 3" outlined class="elevation-2 pa-8">
			<FinishedPP :dataPP="dataPP" :datosCompany="datosCompany" @error="error" @loading="loadingEmit" :loading="loading" />
		</v-card>
		<section class="mt-5 d-flex justify-end align-center">
			<div v-if="step != 3" class="d-flex">
				<Button :text="$t('actions.reject')" white class="mr-3" @clicked="$emit('rejectPP')" :disabled="loading"/>
				<Button
					v-if="step == '1' && !newPracticaEmpresa.flag"
					:text="$t('actions.manage')"
					:disabled="datosCompany.estadoPPId != 267 || loading"
					@clicked="updatePP(268)"
					primary
				/>
				<Button
					v-if="disableNewPracticaEmpresa"
					:text="$t('modules.academic.professionalizingPractice.update.steppers.manage.update')"
					:disabled="datosCompany.estadoPPId == 269 || loading"
					:loading="updateButton.loading"
					:success="updateButton.success"
					:error="updateButton.error"
					:successText="$t('actions.saved')"
					:errorText="$t('actions.error')"
					@end="resetButtonValues"
					primary
					@clicked="cambiarEmpresa"
				/>
				<Button v-if="step == 2" :text="$t('actions.finish')" :disabled="disableFinish" primary @clicked="finishPocedurePP" />
			</div>
			<div v-else class="d-flex justify-end" style="width: 100%">
				<Button :text="$t('actions.close')" primary class="mr-3" @clicked="$router.go(-1)" />
			</div>
		</section>
	</section>
</template>

<script>
import Button from '@/components/Button/Button';
import { email, required, minLength } from 'vuelidate/lib/validators';
import { $updateCompanyPractice, $cerrarConvenio, $actualizarValidacion } from '../Services';
import PPLocationAndShifts from './Steps/PPLocationAndShifts';
import CheckRequirementsContinue from './Steps/CheckRequirementsContinue';
import CheckDocsSent from './Steps/CheckDocsSent';
import FinishedPP from './Steps/FinishedPP';

export default {
	name: 'StartProcedure',
	props: {
		dataStudent: { type: Object },
		datosCompany: { type: Object },
		disponibilidadHoraria: { type: Array },
		dataPP: { type: Object },
		step: { type: String, default: '1' },
		updatePP: { type: Function },
		newPracticaEmpresa: { type: Object },
		loading: { type: Boolean, default: false },
	},
	components: {
		Button,
		PPLocationAndShifts,
		CheckRequirementsContinue,
		CheckDocsSent,
		FinishedPP,
	},
	data() {
		return {
			requirementsComplete: 'Si',
			companyProcess: null,
			updateButton: {
				loading: false,
				error: false,
				success: false,
			},
		};
	},
	validations: {
		newPracticaEmpresa: {
			cargoReferente: { required },
			contacto: { required },
			cuit: { required, minLength: minLength(9) },
			direccion: { required },
			email: { required, email },
			legajo: { required },
			razonSocial: { required },
			telefono: { required, minLength: minLength(10) },
		},
	},
	computed: {
		disableFinish: {
			get() {
				return (
					this.datosCompany.estadoPPId != 279 ||
					!this.dataPP.empresaCumpleRequisitos ||
					!this.dataPP.empresaContinuaProceso ||
					!this.dataPP.documentacionEnviada ||
					!this.dataPP.firmadoPorAlumno ||
					!this.dataPP.firmadoPorEmpresa ||
                    this.loading
				);
			},
			set(value) {
				return value;
			},
		},
		disableNewPracticaEmpresa: {
			get() {
				return this.step == '1' && this.datosCompany.estadoPPId != 269 && this.newPracticaEmpresa.flag && this.datosCompany.validacion;
			},
			set(value) {
				return value;
			},
		},
	},
	methods: {
		resetButtonValues() {
			this.updateButton.loading = false;
			this.updateButton.success = false;
			this.updateButton.error = false;
		},
		cambiarEmpresa() {
			!this.$v.$invalid ? this.updateCompanyPractice() : this.$v.$touch();
		},
		async updateCompanyPractice() {
			this.updateButton.loading = true;
			this.loadingEmit(true);
			try {
				const res = await $updateCompanyPractice.create({ ...this.newPracticaEmpresa });
				this.updateButton.loading = false;
				this.updateButton.success = true;
				this.$emit('fetchData', this.dataStudent.legajo);
				setTimeout(() => {
					this.newPracticaEmpresa.flag = false;
				}, 3000);
				return res;
			} catch (err) {
				this.updateButton.error = true;
				throw err;
			} finally {
				this.updateButton.loading = false;
				this.loadingEmit(false);
			}
		},
		async updateValidation(value) {
			try {
				this.loadingEmit(true);
				this.newPracticaEmpresa.flag = false;
				const res = await $actualizarValidacion.create(null, { params: { convenioId: `${this.dataPP.convenioId}`, validacion: value } });
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.loadingEmit(false);
			}
		},
		async finishPocedurePP() {
			try {
				this.loadingEmit(true);
				const res = await $cerrarConvenio.create(null, { params: { convenioId: `${this.dataPP.convenioId}` } });
				this.$emit('changeStep', '3');
				this.datosCompany.estadoPPId = 269;
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.loadingEmit(false);
			}
		},
		popUpReject() {
			this.$store.dispatch('commons/openPopUp', {
				title: this.$t('modules.academic.professionalizingPractice.update.steppers.reject'),
				actionPrimary: {
					text: this.$t('actions.reject'),
					callback: () => {
						this.updatePP(270);
					},
				},
				actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
				icon: { color: 'warning', name: 'mdi-close' },
				color: 'primary',
			});
		},
		error() {
			this.$emit('error');
		},
		loadingEmit(value) {
			this.$emit('loading', value);
		},
	},
};
</script>
