<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :title="$t('modules.settings.billings.breadcrumbs.title')"
            :description="$t('modules.settings.billings.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template >
            <OutlinedCard :section="$t('modules.settings.billings.sectionSecurity.title')" class="mb-6">
              <OutlinedCardContent
                :label="$t('modules.settings.billings.sectionSecurity.user')"
                sm="4"
              >
                <v-text-field
                  v-model="$v.username.$model"
                  :error-messages="($v.username.$dirty && $v.username.$invalid) ? $t('modules.settings.billings.validations.sectionSecurity.user') : ''"
                  @change="(val) => changeValue(val,'facturanteUser')"
                  placeholder="Nombre de usuario"
                  outlined
                ></v-text-field>
              </OutlinedCardContent>
              <OutlinedCardContent
                :label="$t('modules.settings.billings.sectionSecurity.pass')"
                sm="4"
              >
                <v-text-field
                  v-model="$v.password.$model"
                  :error-messages="($v.password.$dirty && $v.password.$invalid) ? $t('modules.settings.billings.validations.sectionSecurity.pass') : ''"
                  placeholder="*******"
                  :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="hidePass ? 'text' : 'password'"
                  @click:append="hidePass = !hidePass"
                  outlined
                  @change="(val) => changeValue(val,'facturantePass')"
                ></v-text-field>
              </OutlinedCardContent>              
              <OutlinedCardContent
                :label="$t('modules.settings.billings.sectionSecurity.company')"
                sm="4"
              >
                <v-text-field
                  v-model="$v.company.$model"
                  :error-messages="($v.company.$dirty && $v.company.$invalid) ? $t('modules.settings.billings.validations.sectionSecurity.company') : ''"
                  type="text"
                  placeholder="Id de empresa"
                  outlined
                   @change="(val) => changeValue(val,'facturanteIdEmpresa')"
                ></v-text-field>
              </OutlinedCardContent>              
            </OutlinedCard>
            <div class="text-sm-right">
              <Button
                :loading="loading"
                :success="success"
                :error="error"
                :text="$t('actions.save')"
                :successText="$t('actions.saved')"        
                :errorText="$t('actions.error')"
                @clicked="save"
                @end="resetButtonValues"
                :disabled="!canUpdate || $v.$invalid"
              ></Button>
          </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

// Components Imports
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
import Button from '@/components/Button/Button'
import { AES, enc } from 'crypto-js';
import Configuration from '../../../../core/constants/configuration';

// Vuex imports
import { mapGetters } from 'vuex';
import{
 required, 
 } from 'vuelidate/lib/validators'

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    Button
  },
  data () {
    return {
      username: null,
      password:null,
      company:null,
      loading: false,
      success: false,
      error: false,
      modifications:{},
      hidePass: false,
      facturanteAES: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {    
    async fetchData() {  
      this.username = this['settings/getBilling'].facturanteUser.currentValue
      this.password = this['settings/getBilling'].facturantePass.currentValue
      this.password ? this.password = Buffer.from(AES.decrypt(
                                    this['settings/getBilling'].facturantePass.currentValue.split(';')[1],
                                    enc.Utf8.parse(Configuration.value('secret')), 
                                    { iv: enc.Base64.parse(this['settings/getBilling'].facturantePass.currentValue.split(';')[0]) }
                                  ).toString(enc.Utf8), 'base64'    
                                ).toString('ascii') : ''; 
      this.company = this['settings/getBilling'].facturanteIdEmpresa.currentValue
    },
    save () {
      if (!this.canUpdate) return false
      this.loading = true;
      this.$store.dispatch('settings/updateState', this.modifications)
        .then(() => {

          // Modified on Vuex

          this.$store.dispatch('settings/updateAPI')
            .then(() => {

              this.success = true
              this.loading = false

              // Launch snackbar
              this.$store.dispatch('settings/cleanTrash')

            })
            .catch(() => {
              this.error = true
              this.loading = false

            })
        })
        .catch(() => {
          this.error = true
          this.loading = false

        })

    },
    changeValue (value, field) {
      if (!this.canUpdate) return false
      if(field === 'facturantePass'){
        
          const iv = `${(Math.random()+1).toString(16).substring(2, 10)}${(Math.random()+1).toString(16).substring(2, 10)}`;
          const facturanteB64 = Buffer.from(this.password).toString('base64');
          const facturanteAES = AES.encrypt(
            enc.Utf8.parse(facturanteB64), 
            enc.Utf8.parse(Configuration.value('secret')), 
            { iv: enc.Utf8.parse(iv) }
          );
          value = `${facturanteAES.iv.toString(enc.Base64)};${facturanteAES}`
      }
      this.modifications[field] = value;
      this.$store.dispatch('settings/changeValue', { value, field })
    },

    resetButtonValues () {
      this.loading = false;
      this.success = false;
      this.error = false;
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    },
    company: {
      required
    },
  },
  computed: {
    ...mapGetters([
      'settings/getBilling'
    ]),
    canUpdate() {
      return this.$permissions.settings.billing.UPDATE
    },
  },
}

</script>

