
import DashboardRoute from './Dashboard/Routes';
import PaymentsRoute from './Payments/router';
import AppsRoute from './Apps/Routes';
import ProceduresRoute from './Procedures/Routes';
import ReglamentoRoute from './Reglamento/Routes';
import AcademicChargeRoute from './AcademicCharge/router';
import ExamsRoute from './Exams/Routes';
import ProfileRoute from './Profile/Routes';
import CommunicationsRoute from './Communications/Routes';
import ProfessionalizingPracticeRoute from './ProfessionalizingPractice/Routes';
import AdmissionRoutes from './Admission/Routes';

const PortalAlumnoRoute = [
    DashboardRoute,
    PaymentsRoute,
    AppsRoute,
    ReglamentoRoute,
    AcademicChargeRoute,
    ExamsRoute,
    ProceduresRoute,
    ProfileRoute,
    CommunicationsRoute,
    ProfessionalizingPracticeRoute,
    AdmissionRoutes
]

export default PortalAlumnoRoute
