import ObjectivesTable from '../Views/ObjectivesTable'
import ObjectivesCreate from '../Views/ObjectivesCreate'
import ObjectivesUpdate from '../Views/ObjectivesUpdate'
import ObjectivesUpload from '../Views/ObjectivesUpload'

const objectivesRoute = [
    {
        path:'',
        component: ObjectivesTable,
        meta: { name: 'Index', group: 'portaladministrativo.commissions', path: 'objectives', permission: 'READ' }
    },
    {
        path:'create',
        component: ObjectivesCreate,
        meta: { name: 'Create Objective', group: 'portaladministrativo.commissions', path: 'objectives', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: ObjectivesUpdate,
        meta: { name: 'Update Objective', group: 'portaladministrativo.commissions', path: 'objectives', permission: 'READ' }
    },
    {
        path: 'import',
        component: ObjectivesUpload,
        meta: { name: 'Upload Objective', group: 'portaladministrativo.commissions', path: 'objectives', permission: 'CREATE' }
    },
];

export default objectivesRoute
