<template>
    <v-container>
        <v-row>
            <v-col cols="8">
                <v-autocomplete 
                        label="Tipo de comprobante"

                        :items="typesReceipt"
                        dense
                        outlined
                    ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-autocomplete 
                        label="Situación fiscal"

                        :items="fiscalSituations"
                        dense
                        outlined
                    ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <BillingData/>
        </v-row>
        <v-row justify="center">
            <v-col cols="11">
                <v-textarea
                    label="Observación"
                    outlined
                    no-resize
                    ></v-textarea>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BillingData from './BillingData.vue'

    export default {
        name: 'BillingCard',
        components:{
            BillingData
        },
        data() {
            return {
                typesReceipt:[],
                fiscalSituations:[]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>