import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class TitleExam extends Api {
  constructor(http, config = {}) {
    super(http, url, 'products-exam', config);
  }
}
