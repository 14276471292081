<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <FormModal v-if="modal">
      <template slot="form" v-if="studentSelected.modalType === 'disabler'">
        <div class="container">
          <h1 class="headline">{{ $t('modules.exam_enabler.update.modal.disabler.title', {exam_number: studentSelected.ueExamId}) }}</h1>
          <h2 class="body-1 my-2">{{ $t('modules.exam_enabler.update.modal.disabler.subtitle') }} <b>{{ `${studentSelected.student}` }}</b></h2>
          <h2 class="body-2 my-3"><b>{{ $t('modules.exam_enabler.update.modal.disabler.selectReason') }}</b></h2>
          <v-row>
            <v-col sm="8">
              <v-select
                class="my-2"
                :items="disable.reasons"
                item-text="meaning"
                return-object
                v-model="disable.reason"
                outlined
                :label="$t('modules.exam_enabler.update.modal.disabler.reason')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <v-textarea
                class="mb-5"
                auto-grow
                outlined
                v-model="disable.justify"
                :label="$t('modules.exam_enabler.update.modal.disabler.justification')"
              ></v-textarea>
            </v-col>
          </v-row>
          <div class="d-flex justify-end">
            <Button
              white
              class="mr-2"
              :text="$t('actions.cancel')"
              @clicked="closeModal"
            />
            <Button
              :disabled="disablerInactive"
              :text="$t('modules.exam_enabler.update.items.button_actions.disable')"
              @clicked="changeExamState(null)"
            />
          </div>       
        </div>  
      </template>
      <template slot="form" v-else>
        <div class="container">
          <v-row>
            <v-col sm=1>
              <div class="d-flex justify-center align-center icon-circle">
                <v-icon class="error circle white--text">mdi-close</v-icon>
              </div>
            </v-col>
            <v-col sm=11>
              <h1 class="headline pt-2 pb-6">{{ $t('modules.exam_enabler.update.modal.information.title', {exam_number}) }}</h1>
              <p class="body-2 mt-4" v-html="$t('modules.exam_enabler.update.modal.information.text1', {student: studentSelected.student ,date: modalInformation.date, hour: modalInformation.hour})"></p>
              <p class="body-2" v-html="$t('modules.exam_enabler.update.modal.information.text2', {reason: modalInformation.reason})"></p>
              <p class="body-2" v-html="$t('modules.exam_enabler.update.modal.information.text3', {justify: modalInformation.justify})"></p>
              <div class="d-flex justify-start">
                <Button
                  class="mt-6"
                  :text="$t('actions.accept')"
                  @clicked="closeModal"
                />
              </div>       
            </v-col>
          </v-row>
        </div>
      </template>
    </FormModal>
    <v-container class="container-custom px-6">
      <Breadcrumbs
        :title="$t('modules.exam_enabler.update.breadcrumbs.title', {horarios: schedule})"
        :description="$t('modules.exam_enabler.update.breadcrumbs.description')"
      ></Breadcrumbs>
      <div class="px-10">
        <div class="references-card d-flex justify-space-between align-center py-5 px-7">
          <span class="title">{{ $t('modules.exam_enabler.update.references_card.students_enrolled', {n: studentsCount}) }}</span>
          <div class="justify-center d-flex">
            <span class="align-self-center font-weight-bold subtitle-2 mx-3">{{ $t('modules.exam_enabler.update.references_card.references.title') }}</span>
            <div class="d-flex flex-column">
              <div class="d-flex reference mx-3 subtitle-2 align-center">
                <v-icon size="12" color="orange" class="mr-2">mdi-circle</v-icon>
                <span>{{ $t('modules.exam_enabler.update.references_card.references.laboratory_exam') }}</span>
              </div>
              <div class="d-flex reference mx-3 subtitle-2 align-center">
                <v-icon size="12" color="indigo" class="mr-2">mdi-circle</v-icon>
                <span>{{ $t('modules.exam_enabler.update.references_card.references.notebooks_exam') }}</span>
              </div>
            </div>
          </div>
          <span class="title mx-3" v-if="act_number">{{ $t('modules.exam_enabler.update.references_card.act_number', {act_number}) }}</span>
          <v-btn class="mx-2" v-if="act_number" icon color="primary" @click="getBoardData(true)"><v-icon>mdi-refresh</v-icon></v-btn>
          <v-btn class="mx-2" v-if="act_number" icon color="primary" @click="printAct"><v-icon>mdi-printer</v-icon></v-btn>
          <div >
              <Button
                v-if="!act_number"
                @clicked="makeAct"
                :loading="actButton.loading"
                :disabled="!initiated"
                :text="$t('modules.exam_enabler.update.references_card.actions.make_act')"
                @end="resetActButton"
                :success="actButton.success"
                :error="actButton.error"
                :successText="$t('actions.success')"
                :errorText="$t('actions.error')"
              ></Button>
              <Button
                v-else
                @clicked="sumadiRedirect"
                :text="$t('modules.exam_enabler.update.references_card.actions.enable_exams')"
              ></Button>
          </div>
        </div>
        <SuperTable
          class="my-3"
          :headers="headers"
          :items="items"
          @changeState="changeExamState"
          @openModal="openModal"
          @generateExam="generateExam"
          @updateView="initialize"
        />
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button'
import SuperTable from '../Components/SuperTable/SuperTable'
import FormModal from '../Components/FormModal/FormModal'
import {$exams,$types,$launchInstructor} from '../Services'
import {mapGetters} from 'vuex'
import download from 'downloadjs'

export default {
  name: 'ExamEnablerUpdate',
  components: {
    Breadcrumbs,
    Button,
    SuperTable,
    FormModal,
  },
  data(){
    return{
      modal: false,
      initiated: false,
      headers: [
        {text: '', sortable: false, value:'reference'},
        {text: this.$t('modules.exam_enabler.update.headers.student'), sortable: false, value: 'student', class: 'primary--text'},
        {text: this.$t('modules.exam_enabler.update.headers.identification'), sortable: false, value: 'rut', align: 'center', class: 'primary--text'},
        {text: this.$t('modules.exam_enabler.update.headers.career'), sortable: false, value: 'career', class: 'primary--text'},
        {text: this.$t('modules.exam_enabler.update.headers.subject'), sortable: false, value: 'subject', class: 'primary--text'},
        {text: this.$t('modules.exam_enabler.update.headers.proctoring'), value: 'proctoring', align: 'center', class: 'primary--text'},
        {text: this.$t('modules.exam_enabler.update.headers.status'), value: 'status', align: 'center', class: 'primary--text'}, 
        {text: this.$t('modules.exam_enabler.update.headers.exam'), sortable: false, value: 'exam_state', align: 'center', class: 'primary--text'},
      ],
      items: [],
      exam_number: null,
      actButton: {
        loading: false,
        error: false,
        success: false
      },
      act_number: null,
      disabler_loading: false,
      disabler_error: false,
      enabler_loading: false,
      enabler_error: false,
      studentsCount: null,
      schedule: null,
      exams_ids: null,
      
      studentSelected: null,

      disable:{
        reasons: [],
        reason: null, 
        justify: null
      },

      modalInformation: {
        date: null,
        hour: null,
        reason: null,
        justify: null,
      },
    }
  },
  computed:{
    disablerInactive(){
      return ( this.disable.justify === null 
            || this.disable.justify === '' 
            || this.disable.reason === null)
    },
    ...mapGetters({
      userId: 'commons/getUserID'
    }),
    isKlarway(){
      return this.proctoring == 'Klarway' ? false : true
    },
  },
  mounted(){
    this.initialize()
  },
  methods: {
    initialize(){
      this.getBoardData()
    },
    async getBoardData(only_state = false){
      const ids = this.$route.params.id.split('-')
      this.exams_ids = ids
      const query_ids = ids.length > 1 ? `${ids[0]},${ids[1]}` : `${ids[0]}`
      const board = await $exams.find(`periods/details?id=${query_ids}`)
      if(!only_state){
        const reasons = await $types.find('?type=STUDENT_EXAM_REASON_TYPE')
        this.studentsCount = board.data.students.length
        this.act_number = board.data.act
        const start = board.data.turnStart.split(':')
        const end = board.data.turnEnd.split(':')
        this.schedule = this.$t('modules.exam_enabler.table.items.schedule', {start: `${start[0]}:${start[1]}`, end: `${end[0]}:${end[1]}`})
        this.disable.reasons = reasons.data
      }
      
      this.items = board.data.students.map(item => {
        item.student = `${item.name} ${item.lastname}`
        return item
      })

      this.items.sort((a, b) => b.error - a.error);

      this.initiated = true
    },
    openModal(data){
      this.studentSelected = data
      if (this.studentSelected.modalType === 'information') this.getDisableInformation()
      this.modal = true;
    },
    closeModal(){
      this.disable.reason = null,
      this.disable.justify = null,
      this.modal = false
    },
    async changeExamState(data) {
      try {
        let url = ''
        if (data) {
          this.studentSelected = data
          this.enabler_loading = true
          url += `${this.studentSelected.ueExamId}/enable?enabled=true`
        } else {
          this.disabler_loading = true
          url += `${this.studentSelected.ueExamId}/enable?enabled=false&reason=${this.disable.justify}&reasonType=${this.disable.reason.value}`
        }
        await $exams.create(null, null, url)
      } finally {
        if (data) this.enabler_loading = false
        else this.disabler_loading = false
        this.closeModal()
        this.getBoardData(true)
      }
    },
    async generateExam(data) {
      try {
        await $exams.generateExam(data.examRequestId)
        this.$store.dispatch('commons/openPopUp', {
          title: 'El examen se generó correctamente',
          actionPrimary: {text: this.$t('actions.accept'), callback() {}},
          icon: { color: 'success', name: 'mdi-check' },
          color: 'primary',
        })
        data.error = false
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Ocurrió un error al generar el examen',
          actionPrimary: {text: this.$t('actions.accept'), callback() {}},
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        })
        throw error
      } finally {
        data.generating = false
      }
    },
    async printAct() {
      if (this.act_number) {
        // Imprimir acta
        const response = await $exams.find(`periods/acta?periodId=${this.exams_ids}`);
        const filename = `acta-${this.act_number}`;
        const content = response.headers['content-type'];
        download(response.data,filename,content);
      }
    },
    async makeAct(){
      try {
        // Generar acta
        this.actButton.loading = true
        const response = await $exams.update(`periods/act?id=${this.exams_ids}`)
        this.act_number = response.data.act
        this.sumadiRedirect()
        this.actButton.success = true
      } catch {
        this.actButton.error = true
      } finally {
        this.actButton.loading = false
      }
    },
    async sumadiRedirect() {
      const sumadiResponse = await $launchInstructor.create({
        examScheduleId: parseInt(this.exams_ids),
        userId: this.userId
      });
      window.open(sumadiResponse?.data?.urlToRedirect,'_blank')
    },
    resetActButton() {
      this.actButton.loading = false
      this.actButton.success = false
      this.actButton.error = false
    },
    async getDisableInformation(){
      try {
        const data = this.studentSelected
        this.exam_number = data.ueExamId
        const response = await $exams.find(`/periods/student-status?examRequestId=${data.examRequestId}`)
        this.modalInformation.reason = response.data.reasonType
        this.modalInformation.justify = response.data.reason
        this.modalInformation.date = response.data.date
        this.modalInformation.hour = response.data.time
      } catch {
        throw 'error'
      }
    }
  }
};
</script>

<style lang="sass" scoped>

  .references-card
    margin: auto
    border-radius: 5px
    border: 1px solid #ccc

  .icon-circle
    width: 3rem
    height: 3rem
    border-radius: 3rem
    background: #FF000044
    
  .circle
    width: 2rem
    height: 2rem
    border-radius: 2rem

</style>