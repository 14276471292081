import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class StudentsWithoutScholarship extends Api {
  
  constructor(http, config = {}) {
    super(http, url, 'students-without-scholarship', config);
  }

  async findPaginated(apiMethod = null, filter = null, config = {}) {
    config = { ...this._config, ...config };
    let uri = this._uriConstructor(null, apiMethod);

    let i = 0;
    Object.keys(filter).forEach(key => {
      if (i === 0) uri += `?${key}=${filter[key]}`;
      else uri += `&${key}=${filter[key]}`;
      i++;
    });

    return this._http.get(uri, config);
  }

}