<template>
    <div class="asidebar d-flex flex-column">
        <div class="px-6 py-4">
            <v-select
                v-model="readStatus"
                class="mb-4"
                :label="$t('modules.portalalumno.communications.messaginSearch.select.label')"
                :items="filters"
                @change="resetSearch"
                hide-details
                outlined
            ></v-select>
            <v-text-field
                v-model="search"
                :placeholder="$t('modules.portalalumno.communications.messaginSearch.input.placeholder')"
                prepend-inner-icon="mdi-magnify"
                hide-details
                outlined
                rounded
                clearable
                @click:clear="clearSearch"
                @keyup.esc="search = ''"
                @keyup="keyup"
            ></v-text-field>
            <span class="caption d-block text-center font-italic">Ingrese por lo menos 5 caracteres</span>
        </div>

        <v-list class="messages pa-0">
            <v-list-item-group
                v-model="active"
                :mandatory="active !== null"
                active-class="selected"
                v-infinite-scroll="loadMore"
                :infinite-scroll-disabled="loading" infinite-scroll-throttle-delay="1000"
            >
                <template v-for="item in items">
                    <v-divider :key="`divider-${item.id}`"></v-divider>
                    <v-list-item
                        :key="`message-${item.id}`"
                        :class="{'not_readed': !item.read}"
                        @click="selectMessage(item)"
                    >
                        <v-list-item-avatar color="grey lighten-2" class="align-self-center">
                            <v-img :src="`data:image/jpeg;base64,${item.avatar}`"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content class="align-self-center">
                            <v-list-item-title v-html="item.name" class="font-weight-bold"></v-list-item-title>
                            <v-list-item-subtitle class="message-title" v-html="item.title"></v-list-item-subtitle>
                        </v-list-item-content>
                        <span class="time caption grey--text text--darken-2 pt-1 pr-2">{{ item.received_at }}</span>
                    </v-list-item>
                </template>
                <div class="text-center pt-5 pb-8" v-if="moreToLoad">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
                <div v-if="!loading && !items.length" class="d-flex justify-center flex-column align-center text-center mt-4">
                    <img width="80%" :src="require('@/assets/nadaporaca.svg')" />
                    <p class="text-h4 primary--text mt-3 mb-1">{{ $t('modules.portalalumno.communications.messaginSearch.icon.title') }}</p>
                    <p class="body-1">{{ $t('modules.portalalumno.communications.messaginSearch.icon.subtitle') }}</p>
                </div>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
    import { $studentmessage } from '../Services';
    import { mapGetters } from 'vuex'
    import moment from 'moment';

    export default {
        name: 'Asidebar',
        props: {

        },
        data() {
            return {
                active: null,
                search: null,
                readStatus: null,
                filters: [
                    { text: this.$t('modules.portalalumno.communications.messaginSearch.filters.all'), value: null },
                    { text: this.$t('modules.portalalumno.communications.messaginSearch.filters.read'), value: true },
                    { text: this.$t('modules.portalalumno.communications.messaginSearch.filters.noRead'), value: false },
                ],
                items: [],
                nextIndex: 0,
                totalPages: null,
                timeout: null,
                loading: false,
            }
        },
        computed: {
            ...mapGetters({
                studentId: 'commons/getStudentId',
            }),
            moreToLoad() {
                if(this.totalPages === 1) {
                    return this.totalPages && this.nextIndex <= 0
                }
                return this.totalPages && this.nextIndex <= this.totalPages
            },
        },
        async mounted() {
            await this.getMessages()

            const messageId = this.$route.query.message
            if (messageId) {
                const messageIndex = this.items.findIndex(item => item.id == messageId)
                if (messageIndex >= 0) {
                    this.active = messageIndex
                    this.selectMessage(this.items[messageIndex])
                }
            }
        },
        methods: {
            selectMessage(item) {
                if (!this.$isGestor) item.read = true
                this.$emit('selectedMessage', item)
            },
            async getMessages() {
                const { data } = await $studentmessage.findById('student', this.studentId, {params: { read: this.readStatus, page: this.nextIndex, search: this.search }})
                const messages = data.content.map(message => {
                    const days = moment().diff(message.createdDate, 'days')
                    const minutes = moment().diff(message.createdDate, 'minutes')
                    
                    let received_at = ''
                    if (minutes < 60) received_at = this.$t('modules.portalalumno.communications.time.minutes', { minutes })
                    else if (days <= 0) received_at = this.$tc('modules.portalalumno.communications.time.hours', Math.floor(minutes / 60))
                    else if (days == 1) received_at = this.$t('modules.portalalumno.communications.time.yesterday')
                    else received_at = moment(message.createdDate).locale('es').format('d MMM')

                    message.received_at = received_at
                    return message
                })

                this.items = [ ...this.items, ...messages ]
                this.totalPages = data.totalPages
                this.nextIndex++
            },
            clearSearch() {
                this.search = '';
                this.searchAction()
            },
            searchAction() {
                clearTimeout(this.timeout);
                this.resetSearch()
            },
            resetSearch() {
                this.nextIndex = 0;
                this.totalPages = null;
                this.items = []
                this.getMessages()
            },
            keyup(e) { 
                clearTimeout(this.timeout);
                if (e.target.value.length >= 5) {
                    this.timeout = setTimeout(() => {
                        this.searchAction()
                        clearTimeout(this.timeout);
                    }, 2000)
                }
            },
            async loadMore() {
                try {
                    if (!this.moreToLoad) return false
                    this.loading = true
                    await this.getMessages()
                } finally {
                    this.loading = false
                }
            },
        }
    }
</script>

<style lang="sass" scoped>
    .asidebar
        height: calc(100vh - 137px)

        .messages
            flex: 1
            overflow-y: auto

            .not_readed
                background-color: #e5f4f3

            .selected
                border-left: .35rem solid #44b5a6
                padding-left: calc(16px - .35rem)
                background-color: white !important

                &::before
                    opacity: 0

            .time
                font-size: 0.65rem !important
                position: absolute
                top: 0
                right: 0

</style>