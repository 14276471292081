import statementTranslations from './Statements/Translations'

const examsTranslations = {
    es: {
        statements: { ...statementTranslations.es },

    },
    es_CL: {
        statements: { ...statementTranslations.es_CL },

    },
    en: {
        statements: { ...statementTranslations.en },

    }
};

export default examsTranslations