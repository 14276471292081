<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4" class="mt-4 mb-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('modules.procedures.table.search')"
          outlined
          clearable
          @keyup.enter="searchAction"
          @keyup.esc="search = ''"
          class="superTable__search"
        ></v-text-field>
      </v-col>
      <v-col sm="4" class="mt-4 mb-2">
        <Button
          class="ml-4"
          @clicked="searchAction"
          outlined
          depressed
          :text="$t('actions.search')"
        ></Button>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:item.status="{item}">
            <v-select
              :disabled="!permissions.update"
              outlined
              v-model="item.status"
              :items="states"
              @change="(data) => changeData(item, data)"
            ></v-select>
          </template>
          <template v-slot:item.score="{item}">
            <v-text-field
              :readonly="!permissions.update"
              outlined
              v-model="item.score"
              @keyup="(data) => changeData(item, data)"
              @keypress="$validateDecimalInputNumber($event, item.score, $isTeclab ? 10 : 7)"
            ></v-text-field>
          </template>
          <template v-slot:item.saved="{item}">
            <Button
              :text="item.saved ? $t('actions.saved') : $t('actions.save')"
              primary
              size="sm"
              :disabled="!permissions.update || item.saved"
              :loading="item.loading"
              @clicked="saveItem(item)"
            ></Button>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" v-if="items.length > 0">
      <v-col sm="3" class="my-8">
        <span class="caption d-inline-block">{{ firstItemShow + ' - ' + lastItemShow + ' de ' + items.length }}</span>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model="page"
          :max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.procedures.table.goToPage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Button from '@/components/Button/Button'

  export default {
    name: 'SuperTable',
    components: {
      Button,
    },
    props: {
      items: Array,
      headers: Array,
      states: Array,
      permissions: {update: false},
    },
    data(){
      return {
        search: '',
        page: 1,
        itemsPerPage: 10,
      }
    },
    computed: {
      pageCount(){
        return (
          this.items?.length % this.itemsPerPage === 0 ?
          this.items?.length / this.itemsPerPage :
          this.items?.length / this.itemsPerPage + 1
        )
      },
      firstItemShow(){
        return this.itemsPerPage * (this.page - 1) + 1;
      },
      lastItemShow(){
        return this.itemsPerPage * this.page < this.items?.length ? this.itemsPerPage * this.page : this.items?.length;
      }
    },
    methods: {
      searchAction () {
        this.$emit('search', this.search)
      },
      changeData(item){
        if(item.score === '') item.saved = true
        else item.saved = false
      },
      saveItem(item){
        this.$emit('save', item);
      }
    },
  };
</script>
