import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class ComparePrices extends Api {
  constructor(http, config = {}) {
    super(http, url, 'students', config);
  }

  findByStudentRegister (record = null,idCareer = null,config = {}) {
    config = {...this._config, ...config};
    const uri = `${url}/students/${record}/comparePrice?idCareer=${idCareer}`
    
    return this._http.get(uri, config);
  }
}
