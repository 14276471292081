<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.product.table.breadcrumbs.title')"
          :description="$t('modules.product.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newProduct"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="products"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="(products)=>{bulkDeletePopUp(products)}"
              @deleteUser="(id)=>deleteProductPopUp(id)"
              @updateUser="(id)=>{updateProduct(id)}"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  import SuperTable from '../components/SuperTable/SuperTable'
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import Button from '@/components/Button/Button'
  import { $products } from '../Services';
  export default {
    name: 'ProductsTable',
    components: {
      SuperTable,
      Breadcrumbs,
      Button
    },
    data() {
      return {
        clearSelected: '',
        products: [],
        headers: [
          {
            text: this.$t('modules.product.table.headers.code'),
            value: 'billingCodeSap',
          },{
            text: this.$t('modules.product.table.headers.name'),
            value: 'name',
          },{
            text: this.$t('modules.product.table.headers.status'),
            value: 'status',
          },{
            text: '',
            value: 'action'
          }
        ]
      }
    },
    mounted() {
      this.fetchData();
    },
    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.prices.products.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.prices.products.READ || this.$permissions.portaladministrativo.prices.products.UPDATE
      },
      canDelete() {
        return this.$permissions.portaladministrativo.prices.products.DELETE
      },
    },
    methods: {
      changed(parent, filter, value) {
        this.asidebar.items[parent].filters[filter].value = value
      },
      async deleteProductPopUp (idProduct){
        const product = await $products.findById(idProduct);
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.confirmation_delete'),
          content: [{value: product.name}],
          actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deleteProduct(idProduct)},
          actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
          icon: {color: 'error', name: 'mdi-delete'},
          color: 'primary',
        })
      },
      async fetchData() {
        this.clearSelected = new Date().getMilliseconds().toString();
        const products = await $products.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } });
        if (products) {
          this.products = products.data.content
        }
      },
      bulkDeletePopUp(products) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.confirmation_delete'),
          content: products.map(product => ({
            value: product.name
          })),
          actionPrimary: {
            text: this.$t('actions.accept'),
            callback: () => this.bulkDelete(products)
          },
          actionSecondary: {
            text: this.$t('actions.cancel'),
            callback() {}
          },
          icon: {
            color: 'error',
            name: 'mdi-delete'
          },
          color: 'primary',
        })
      },
      async bulkDelete(products) {
        const itemsForDelete = products.map((product) => {
          return {id: product.id}
        })
        try {
          const deleteProd = await $products.bulkDelete(itemsForDelete)
          if (deleteProd) {
            this.fetchData()
          }
        } catch (err) {
          this.$store.dispatch('commons/openPopUp', {
            title: 'No se pueden eliminar los productos seleccionados debido a que tiene datos relacionados.',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary',
          })
        }
      },
      newProduct() {
        if (this.canCreate) this.$router.push('/products/create')
      },
      updateProduct(id) {
        if (this.canUpdate) this.$router.push(`products/update/${id}`)
      },
      async deleteProduct(idProduct) {
        try {
          await $products.delete(idProduct)
          this.fetchData()
        } catch (err) {
          this.$store.dispatch('commons/openPopUp', {
            title: 'No se puede eliminar el producto seleccionado debido a que tiene datos relacionados.',
            actionPrimary: {text: this.$t('actions.accept'), callback() {}},
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
          })
        }
      }
    }
  }
</script>
