
import IntermediateDegreeView from '../Views/IntermediateDegreeView'

const IntermediateDegreeRoute = [
    {
        path: '',
        component: IntermediateDegreeView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'intermediate_degree', permission: 'READ' }
    },
]

export default IntermediateDegreeRoute