<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <template>
        <v-container class="container-custom">
          <Breadcrumbs
            :title="$t('modules.product.update.breadcrumbs.title')"
            :description="$t('modules.product.update.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-container>
      </template>
      <v-container class="container-custom px-8">
        <template v-if="!updateProductStatus">
          <v-row justify="center">
            <v-col class="py-0 pl-7" sm="12">
              <OutlineCard
                :title="$t('modules.product.sections.infoTitle')"
                :subtitle="$t('modules.product.update.section_name.subtitle')"
                :switchLabel="(status) ? $t('modules.discounts.enabled') : $t('modules.discounts.disabled')"
                :switchValue="status"
                @switchChange="(val) => {updateSwitch(val); this.$v.$touch()}"
                switcher
                class="mb-6"
              >
                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.product.update.labels.name') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <v-text-field
                      :error-messages="$v.name.$error ? $t('modules.product.inputErrors.required') : ''"
                      @blur="$v.name.$touch()"
                      v-model="$v.name.$model"
                      :label="`${$t('modules.product.update.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.product.update.labels.code') }}</span>
                  </v-col>
                  <v-col sm="6" class="col-sm-6 col">
                    <span>{{ billingCodeSap }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="3" class="col-sm-3 col">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('modules.product.update.labels.description') }}</span>
                  </v-col>
                  <v-col sm="9" class="col-sm-9 col">
                    <span>{{ description }}</span>
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard :title="$t('modules.product.sections.enabledTitle')" class="mb-6">
                <v-row>
                  <v-col
                    sm="6"
                    class="pt-0 pb-0 d-flex"
                    v-for="(prop, index) in productProps"
                    v-bind:key="prop.id"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        color="primary"
                        class="pa-0 mt-0"
                        v-model="prop.status"
                        :label="prop.description"
                        @change="onCheckHabClick"
                      />
                      <v-text-field
                        class="ml-6 mt-0"
                        placeholder="0"
                        min="0"
                        max="0"
                        outlined
                        :error-messages="prop.status && $v.productProps.$each[index].$error ? $t('modules.product.inputErrors.required') : ''"
                        :disabled="isDisabled(prop)"
                        type="number"
                        @keypress="$validateIntegerInputNumber($event, prop.value, 100)"
                        v-model="prop.value"
                        @blur="onBlur($v.productProps.$each[index])"
                      />
                    </div>
                  </v-col>
                </v-row>
              </OutlineCard>
              <OutlineCard :title="$t('modules.product.sections.relatedProduct')" class="mb-6">
                <v-row>
                  <v-col
                    sm="6"
                    class="pt-0 pb-0 d-flex"
                    v-for="product in productsRelated"
                    :key="product.id"
                  >
                    <v-checkbox
                      v-model="$v.selectedProducts.$model"
                      color="primary"
                      :label="product.name"
                      :value="product.id"
                      class="mr-8 mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </OutlineCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/products')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate"
                  @end="resetButtonValues()"
                  @clicked="validStatus"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
        <SuccessMessage
          v-else
          :type="successMessage.type"
          :title="successMessage.title"
          :actionPrimary="successMessage.actionPrimary"
        />
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import { $products, $billingProps } from '../Services';

export default {
  name: 'ProductsUpdate',
  components: {
    Breadcrumbs,
    OutlineCard,
    Button,
    SuccessMessage
  },
  data() {
    return {
      items: [],
      updateProductStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {},
      status: null,
      name: null,
      description: null,
      billingCodeSap: null,
      selectedProducts: [],
      productProps: [],
      productsRelated: []
    };
  },
  validations: {
    name: { required },
    selectedProducts: {},
    productProps: {
      minLength: minLength(0),
      $each: {
        value: {
          required: requiredIf(function(nestedModel) {
            return nestedModel.status;
          })
        }
      }
    }
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.products.UPDATE && this.$v.$anyDirty && !this.$v.$anyError;
    },
  },
  methods: {
    onBlur(vuelidateLink) {
      vuelidateLink.$touch();
    },
    updateSwitch(val) {
      this.status = val;
    },
    validStatus() {
      if (!this.status) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.product.update.alert_message.title'),
          content: '',
          actionPrimary: {
            text: this.$t(
              'modules.product.update.alert_message.actions.primary_text'
            ),
            callback: () => {
              this.updateProduct();
            }
          },
          actionSecondary: {
            text: this.$t(
              'modules.product.update.alert_message.actions.secondary_text'
            ),
            callback() {}
          },
          icon: {
            color: 'warning',
            name: 'mdi-eye-off-outline'
          },
          color: 'primary'
        });
      } else {
        this.updateProduct();
      }
    },
    isDisabled(prop) {
      let status = false;
      if (!prop.status) {
        prop.value = '';
        status = true;
      } else {
        status = false;
      }
      return status;
    },
    async updateProduct() {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;
      const props = [];
      this.productProps.forEach(item => {
        if (item.status) {
          props.push({
            count: item.value,
            billingProps: { id: item.id }
          });
        }
      });
      const related = [];
      this.selectedProducts.forEach(item => {
        related.push({ parent: item });
      });
      try {
        await $products.update(this.$route.params.id, {
          name: this.name,
          status: this.status,
          description: this.description,
          billingCodeSap: this.billingCodeSap,
          productProps: props,
          productsRelated: related
        });
        this.successMessage.type = 'success';
        this.successMessage.title = `Producto de facturación ${this.name} editado`;
        this.successMessage.actionPrimary = {
          text: this.$t(
            'modules.product.update.success_message.actions.primary_text'
          ),
          callback: () => {
            this.$router.push('/products');
          }
        };
      } catch (err) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t(
          'modules.products.update.error_message.title'
        );
        this.successMessage.actionPrimary = {
          text: this.$t(
            'modules.product.create.error_message.actions.go_back_text'
          ),
          callback: () => {
            this.updateProductStatus = false;
          }
        };
      }
      this.updateProductStatus = true;
      this.saveButton.loading = false;
      this.saveButton.success = true;
    },
    resetButtonValues() {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    onCheckHabClick(event) {
      if (!event) this.$v.productProps.$touch();
    },
    async fetchData() {
      const productBilling = await $billingProps.find();
      this.productProps = productBilling.data.content.map(prop => {
        const tmpProp = {
          description: prop.description,
          id: prop.id,
          propType: prop.propType,
          status: false,
          value: ''
        };
        return tmpProp;
      });
      await this.fetchRelatedProduct();
    },
    async fetchRelatedProduct() {
      const productList = await $products.find(null, null, {
        params: { page: 0, length: 2000 }
      });
      this.productsRelated = productList.data.content;
      await this.fetchProduct();
    },
    async fetchProduct() {
        
        const productInfo = await $products.findById(this.$route.params.id);
        this.name = productInfo.data.name;
        this.description = productInfo.data.description;
        this.billingCodeSap = productInfo.data.billingCodeSap;
        this.status = productInfo.data.status;
        this.selectedProducts = [];
        if (productInfo.data.productsRelated) {
            this.productsRelated.forEach(rel => {
            productInfo.data.productsRelated.forEach(item => {
                if (rel.id == item.parent) {
                this.selectedProducts.push(rel.id);
                }
            });
            });
        }
        if (productInfo.data.productProps) {
            this.productProps.forEach(prop => {
            productInfo.data.productProps.forEach(item => {
                if (prop.id == item.billingProps.id) {
                prop.status = true;
                prop.value = item.count;
                }
            });
            });
        }
    }
  },
  created() {
    this.fetchData();
  }
};
</script>
