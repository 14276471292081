
const liquidationsTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Liquidaciones',
        description: 'Administración de liquidaciones'
      },
      headers: {
        id: '#',
        date: 'Fecha',
        status: 'Estado',
        amount: 'Monto',
        sellerCode: 'Agente',
        user: 'Representante'
      },
      filters: {
        title: 'Refinar búsqueda',
        status: 'Estado',
        sellerCode: 'Agente',
        date: 'Fecha',
        from: 'Desde',
        to: 'Hasta',
        user: 'Representante'
      },
      download: {
        confirmation_title: '¿Desea descargar el reporte?',
        reportName: 'Reporte liquidaciones {date}.xls',
        error: 'Ocurrió un error descargando el reporte',
        primary_text: 'Descargar',
      }
    },
  },
  en: {

  }
};

export default liquidationsTranslations
