<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!updateRangeStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.ranges.update.breadcrumbs.title')"
                :description="$t('modules.commissions.ranges.update.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.ranges.update.sectionData.title')"
                        :subtitle="$t('modules.commissions.ranges.update.sectionData.subtitle')"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.ranges.update.sectionData.labels.term') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span v-html="termFormatted"></span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.ranges.update.sectionData.labels.description') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <v-text-field
                                    v-model="$v.range.description.$model"
                                    :error-messages="($v.range.description.$invalid && $v.range.description.$dirty) ? $t('modules.commissions.ranges.update.sectionData.validations.description') : ''"
                                    :label="`${$t('modules.commissions.ranges.create.data.description')} *`"
                                    @blur="$v.range.description.$touch()"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.ranges.update.sectionData.labels.from') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-menu
                                    ref="menu_from"
                                    v-model="menu_from"
                                    :return-value.sync="$v.range.startDate.$model"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    bottom
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="$v.range.startDate.$model"
                                            :label="`${$t('modules.commissions.ranges.update.sectionData.labels.from')} *`"
                                            :error-messages="$v.range.startDate.$invalid && $v.range.startDate.$dirty ? $t('modules.commissions.ranges.update.sectionData.validations.date') : ''"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="$v.range.startDate.$model"
                                        color="primary"
                                        scrollable
                                        no-title
                                        :min="minDate"
                                        :max="$v.range.endDate.$model ? $v.range.endDate.$model : maxDate"
                                        @input="$refs.menu_from.save($v.range.startDate.$model)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.ranges.update.sectionData.labels.to') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-menu
                                    ref="menu_to"
                                    v-model="menu_to"
                                    :return-value.sync="$v.range.endDate.$model"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    bottom
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="$v.range.endDate.$model"
                                            :label="`${$t('modules.commissions.ranges.update.sectionData.labels.to')} *`"
                                            :error-messages="$v.range.endDate.$invalid && $v.range.endDate.$dirty ? $t('modules.commissions.ranges.update.sectionData.validations.date') : ''"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="$v.range.endDate.$model"
                                        color="primary"
                                        scrollable
                                        no-title
                                        :min="$v.range.startDate.$model ? $v.range.startDate.$model : minDate"
                                        :max="maxDate"
                                        @input="$refs.menu_to.save($v.range.endDate.$model)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/ranges')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="saveButton.loading"
                            :success="saveButton.success"
                            :error="saveButton.error"
                            :text="$t('actions.save')"
                            :successText="$t('actions.saved')"
                            :errorText="$t('actions.error')"
                            :disabled="!canUpdate || $v.$invalid"
                            @end="resetButtonValues"
                            @clicked="updateRange"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import { required } from 'vuelidate/lib/validators';
    import { $range } from '../Services';
    import moment from 'moment';

    export default {
        name: 'RangesUpdate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
        },
        data() {
            return {
                minDate: null,
                maxDate: null,
                range: {
                    term: null,
                    description: null,
                    startDate: null,
                    endDate: null,
                },
                updateRangeStatus: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                menu_from: false,
                menu_to: false,
                successMessage: {
                    type: '',
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.ranges.UPDATE
            },
            termFormatted() {
                if (this.range.term) return `${this.range.term.observations} <small class="grey--text"><i>(${this.range.term.from} - ${this.range.term.to})</i></small>`
                else return '-'
            }
        },
        mounted () {
            this.getRange()
        },
        methods: {
            async getRange() {
                try {
                    const range = (await $range.findById(this.$route.params.id)).data
                    this.minDate = range.term.startDate.substr(0, 10)
                    this.maxDate = range.term.endDate.substr(0, 10)
                    range.term.from = moment(this.minDate).format('DD/MM')
                    range.term.to = moment(this.maxDate).format('DD/MM')
                    this.range = range
                } catch (error) {
                    this.range = {
                        term: null,
                        description: null,
                        startDate: null,
                        endDate: null,
                    }
                    throw error
                }
            },
            resetButtonValues() {
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async updateRange() {
                if(!this.canUpdate) return false;
                try {
                    this.saveButton.loading = true;
                    await $range.update(this.$route.params.id, this.range)
                    this.successMessage.type = 'success',
                    this.successMessage.title = this.$t('modules.commissions.ranges.update.success_message.title'),
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/ranges') } }
                    this.saveButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.update.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.updateRangeStatus = false } }
                    this.saveButton.error = true;
                    throw error;
                } finally {
                    this.saveButton.loading = false;
                    this.updateRangeStatus = true;
                }
            },
        },
        validations: {
            range: {
                description: { required },
                startDate: {
                    required,
                    minDate(value) { return this.minDate ? new Date(value).toISOString() >= new Date(this.minDate).toISOString() : false },
                    maxDate(value) { return this.maxDate ? new Date(value).toISOString() <= new Date(this.maxDate).toISOString() : false }
                },
                endDate: {
                    required,
                    minDate(value) { return this.minDate ? new Date(value).toISOString() >= new Date(this.minDate).toISOString() : false },
                    maxDate(value) { return this.maxDate ? new Date(value).toISOString() <= new Date(this.maxDate).toISOString() : false }
                },
            }
        },
    }
</script>