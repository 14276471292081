import baseUrl from '@/constants/uri';
import Configuration from '../constants/configuration';

export default {
  APP: Configuration.value('app') || 'portaladministrativo',
  DOMAIN: Configuration.value('url') || 'ipp.cl',
  THEME: Configuration.value('theme') || 'ipp',
  HTTP_METHOD_POST: 'POST',
  HTTP_METHOD_PUT: 'PUT',
  HTTP_METHOD_PATCH: 'PATCH',
  HTTP_METHOD_GET: 'GET',
  HTTP_METHOD_DELETE: 'DELETE',
  ADVISOR: !!(Configuration.value('app') != 'portalalumno'),

  URL_BASE: baseUrl,
  URL_IDENTITY_SERVER: `${baseUrl}/oauth/token`,
  URL_PRICING_SERVER: `${baseUrl}/pricing`,
  URL_ACADEMIC_SERVER: `${baseUrl}/academic`,
  URL_STUDENT_SERVER: `${baseUrl}/student`,
  URL_DATA_SERVER: `${baseUrl}/core`,
  URL_PAYMENT_SERVER: `${baseUrl}/payment`,
  URL_LOGOUT_REDIRECT: '/login',
  URL_LOGIN_REDIRECT: '/',
  getFetchOptions(body, method, contentType) {
    const token = localStorage.getItem('access_token')
      ? JSON.parse(localStorage.getItem('access_token'))
      : '';
    if (!contentType) contentType = 'application/json';
    const options = {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${token}`
      }
    };
    if (method) {
      options.method = method;
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  },
  getBasicOptions(body, method, contentType) {
    if (!contentType) contentType = 'application/json';
    const options = {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': contentType,
        Authorization: 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0'
      }
    };
    if (method) {
      options.method = method;
    }
    if (body) {
      options.body = JSON.stringify(body);
    }
    return options;
  }
};
