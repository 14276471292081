const prizesTranslations = {
  es: {
      table: {
          breadcrumbs: {
            title: 'Premios',
            description: 'Administración de Premios.'
          },
            headers: {
                studentType: 'Tipo estudiante',
                percentageFrom: 'Objetivo hasta',
                extraPayment: 'Pago extra',
                date: 'Fecha',
            },
            filters: {
                title: 'Refinar búsqueda',
                studenType: 'Tipo de estudiante',
                date: 'Fecha',
                from: 'Desde',
                to: 'Hasta',
            },
            bulk_delete_title: 'Eliminar premios',
            error_message: {
                delete_message: 'No se pudo eliminar el Premio',
                bulk_delete_message: 'No se pudieron eliminar los premios seleccionadas'
            },
        },


      create: {
        breadcrumbs: {
          title: 'Nuevo premio',
          description: 'Crea un nuevo premio.'
        },
        sectionData: {
          title: 'Tipos de estudiante',
          subtitle: 'Seleccione los tipos de estudiante a los que aplican los premios como la fecha desde la cual son vigentes.',
          items: {
            relation:'Aplica',
            from:'Fecha',
            percentageFrom: 'Objetivo hasta',
            extraPayment: 'Pago extra',
          },
          error: {
            selectedStudentTypes: 'Debes seleccionar al menos un tipo de estudiante',
          }
        },
        sectionMultiple: {
          title: 'Premios',
          subtitle: 'Defina los premios aplicablaes a cada grupo de objetivo',
        },
        validations: {
          percentageRequired: 'El objetivo es requerido',   
          percentageBeGreater: 'Solamente valores entre {beGreater} - {beMax} ',   
          percentageUnique: 'El objetivo no debe repetirse',
        },
        success_message: {
          title: 'Premio creado correctamente',
        },
        error_message: {
            title: 'Se produjo un error al crear el Premio',
        },
      },


      update: {
          breadcrumbs: {
            title: 'Editar Premio',
            description: 'Editar Premio seleccionado'
          },
          success_message: {
            title: 'Premio modificado correctamente',
          },
          sectionData: {
            title: 'Datos del Premio',
            subtitle: 'Edite la información básica del premio.',
            labels: {
              extraPayment: 'Pago extra',
              studentType: 'Tipo de estudiante',
              date: 'Aplica desde',
              percentageFrom: 'Objetivo hasta',
            },
          },
          validations: {
            percentageRequired: 'El objetivo es requerido',
          },
          error_message: {
              title: 'Se produjo un error al modificar el premio',
          },
        },
    },
    en: {
        
    }
};

export default prizesTranslations