<template>
    <div class="calendar d-flex elevation-3">
        <div class="calendar-container">
            <div class="calendar-container__header d-flex align-center justify-center">
                <h4 class="white--text font-weight-medium">{{ $t('modules.portalalumno.dashboard.calendar.title') }}</h4>
            </div>
            <vc-calendar class="calendar-container__calendar" :locale="{id: 'es', firstDayOfWeek: 1}" :attributes="datesToCalendar"/>
        </div>
        <div class="calendar-dates">
            <div class="calendar-dates__arrow"></div>
            <div class="calendar-dates__header d-flex align-center justify-center">
                <h4 class="white--text text-center font-weight-medium">{{ $t('modules.portalalumno.dashboard.calendar.dates') }}</h4>
            </div>
            <div class="calendar-dates__cards d-flex flex-column">
                <v-progress-circular v-if="loading" indeterminate color="#fff" class="my-8 mx-auto"></v-progress-circular>
                <v-card v-else class="my-2" v-for="(label, idx) in datesToLabels.slice(0, datesLabelsLimit)" :key="`calendar-label-${idx}`">
                    <v-card-text class="d-flex pa-1">
                        <v-icon :color="label.type" size="14" class="ml-2 mr-3">mdi-circle</v-icon>
                        <div>
                            <h4 class="primary--text">{{ label.title }}</h4>
                            <p class="caption mb-0">{{ label.date }}</p>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { $calendarDates } from '../Services';
    import { mapGetters } from 'vuex';

    export default {
        name: 'Calendar',
        data() {
            return {
                datesFromAPI: [],
                datesLabelsLimit: 6,
                loading: true,
            }
        },
        computed: {
            datesToCalendar(){
                return this.datesFromAPI.map(date => {
                    return {
                        highlight: this.getDateColor(date.category),
                        popover: { label: date.titulo },
                        dates: moment(date.fechaString , 'DD/MM/YYYY').toISOString(),
                        key: date.titulo
                    }
                });
            },
            datesToLabels(){
                return this.datesFromAPI.map(date => {
                        // Chequeo que la fecha no haya pasado
                        //if(moment(date.fechaString, 'DD/MM/YYYY') < moment()) return null

                        return {
                            title: date.titulo,
                            type: this.getDateColor(date.category),
                            date: date.fechaString
                        }
                    }).filter(date => date !== null) // Quito los eventos que ya pasaron
                    .sort((a, b)=> moment(a.date, 'DD/MM/YYYY') - moment(b.date, 'DD/MM/YYYY')) // Ordeno por fecha
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
            }),
        },
        mounted(){
            this.getData();
        },
        methods: {
            getDateColor(type) {
                if (type == 'FECHA_CURSADO') return 'orange'
                else if (type == 'FECHA_NORMAL') return 'blue'
                else return 'red'
            },
            async getData(){
                try{
                    const datesRaw = await $calendarDates.find(null, null, {params: { leg: this.studentRegister }})
                    this.datesFromAPI = datesRaw.data.fechasCalendarioResponseDTOList ? datesRaw.data.fechasCalendarioResponseDTOList : [];
                } catch (err) {
                    this.datesFromAPI = []
                    throw err
                } finally {
                    this.loading = false;
                }
            },
            launchDatesModal(){
                this.$emit('openModal', this.datesToLabels)
            }
        },
    }
</script>

<style lang="sass" scoped>
    .calendar
        width: 100%
        background: #fff
        border-radius: 5px
        &-container
            width: 58%
            min-height: 290px
            &__header
                height: 35px
                background: #4586de
                border-radius: 5px 0 0 0
            &__calendar
                width: 100%
                border: none
        &-dates
            width: 42%
            position: relative
            min-height: 290px
            background: var(--v-primary-base)
            border-radius: 0 5px 5px 0
            &__header
                height: 35px
            &__arrow
                position: absolute
                width: 20px
                height: 20px
                background: var(--v-primary-base) 
                transform: rotate(45deg)
                top: 50%
                margin-top: -10px
                margin-left: -9px
            &__cards
                max-height: 255px
                overflow-y: auto
                padding: 5px 20px

@media only screen and (max-width: 600px)
    .calendar
        flex-direction: column
        &-container
            width: 100%
        &-dates
            width: 100%
            &__arrow
                top: 0
                margin-top: -7px
                left: 50%
                margin-left: -7.5px
                width: 15px
                height: 14px

</style>