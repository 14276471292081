const profileTranslations = {
  es_PE: {
      sectionContact: {
          'cityWithState': 'Distrito',
          validations: {
              city: 'Ingresa mínimo 3 caracteres para filtrar el distrito',
          }
      },
  },  
  es: {
    tooltips: {
      select_image: 'Selecciona una imagen'
    },

    breadcrumbs: {
      title: 'Mi cuenta',
      description: 'Información básica, como el nombre y la foto, que utilizas en el sistema.'
    },
    changePassword2: {
     label: 'Cambiar contraseña',
    },
    success_message: {
      title: 'Perfil editado correctamente',
      logout_message: 'Si modifica el usuario, deberá volver a iniciar sesión. ¿Desea continuar?',
      actions: {
        primary_text: 'Cerrar',
        back: 'Volver',
      },
    },

    error_message: {
      title: 'Se produjo un error al editar el perfil',
    },

    changePassword: {
      'fields': {
        'currentPass': 'Contraseña actual',
        'newPass': 'Nueva contraseña',
        'repeatPass': 'Repetir contraseña'
      },
      'validations': {
        'required': 'La contraseña es requerida',
        'minLength': 'Mínimo de 8 caracteres',
        'equal': 'Las contraseñas deben ser iguales',
        'securePass': 'Utiliza 8 caracteres como mínimo con una combinación de letras, números y símbolos.',
        'currentPass': 'No coincide con tu contraseña actual',
        repeated: 'Se ingresó la misma contraseña que en el campo anterior'
      },
      securityProgressBar: {
        'label': 'Nivel de Seguridad',
        'category': {
          'down': 'Bajo',
          'middle': 'Medio',
          'high': 'Alto'
        },
      }
    },

    sectionData: {
      'title': 'Información personal',
      'avatar': 'Avatar',
      'avatarDescription': 'Puedes personalizar tu cuenta con una foto',
      'name': 'Nombre',
      'lastname': 'Apellido',
      'birthDate': 'Fecha de nacimiento',
      'gender': 'Sexo',
      'email': 'Usuario',
      'password': 'Contraseña',
      'passwordDescription': 'Cambiar contraseña',
      'identificationType': 'Tipo de identificación',
      'identification': 'Número de identificación',
      validations: {
        'nameRequired': 'Debes ingresar tu nombre',
        'lastnameRequired': 'Ingresa tu/s apellido/s',
        // 'birthDateRequired': 'Selecciona tu fecha de nacimiento',
        // 'genderRequired': 'Selecciona tu género',
        'emailRequired': 'Debes ingresar un email',
        'emailEmail': 'Ingresa un email válido. Ej.: micorreo@ejemplo.com',
        // 'identificationTypeRequired': 'Selecciona un tipo de identificación',
        'identificationRequired': 'Ingresa tu número de identificación',
      }
    },

    sectionContact: {
      'title': 'Información de contacto',
      'email': 'Correo electrónico',
      'street': 'Calle',
      'number': 'Número',
      'floor': 'Piso',
      'apartment': 'Departamento',
      'zipCode': 'Código postal',
      'cityWithState': 'Localidad',
      'phone': 'Teléfono fijo',
      'cellphone': 'Teléfono móvil',
      'phonePlaceholder': 'Ingresa tu número',
      'cellphonePlaceholder': 'Ingresa tu número',
      validations: {
        'emailRequired': 'Debes ingresar un Email',
        'emailEmail': 'Ingresa un Email válido. Ej.: micorreo@ejemplo.com',
        'identificationRequired': 'Ingresa tu número de identificación',
        city: 'Ingresa mínimo 3 caracteres para filtrar la ciudad',
        street: 'Ingrese una dirección',
        number: 'Ingrese el número de la dirección',
      }
    },

    sectionAcademic: {
      'title': 'Información académica',
      'fileNumber': 'Número de legajo',
      'userProfile': 'Perfil de usuario'
    }

  },
  en: {
    tooltips: {
      select_image: 'Selecciona una imagen'
    },
    breadcrumbs: {
      title: 'My account'
    },
  }
};

export default profileTranslations
