<template>
	<v-container fluid class="superTable pa-0">
		<v-row no-gutters>
			<v-col sm="4">
				<v-text-field
					prepend-inner-icon="mdi-magnify"
					:placeholder="$t('actions.search')"
					outlined
					clearable
					class="superTable__search"
					v-model="tableFilter"
					@click:clear="clearAction"
					@keyup.enter="searchAction"
					@keyup.delete="clearAction"
				/>
			</v-col>
			<v-col sm="4">
				<Button class="ml-4" @clicked="searchAction" outlined depressed :text="$t('actions.search')" />
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col sm="12">
				<v-data-table
					:headers="headers"
					:items="items"
					:server-items-length="totalItems"
					:items-per-page="itemsPerPage"
					:page.sync="page"
					:show-select="false"
					class="superTable__table"
					hide-default-footer
					item-key="studentId"
					style="table-layout: fixed"
					:sort-by="sortBy"
					:sort-desc="sortDesc"
					@update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
					@update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
					:expanded.sync="expanded"
					show-expand
					:single-expand="singleExpand"
					:loading="loading_table"
				>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length" class="py-5">
							<StudentInnerTable :student="item" :statusTypes="statusTypes" :docTypes="docTypes" :foundStudentDocs="foundStudentDocs" @update="$emit('update')"/>
						</td>
					</template>
				</v-data-table>
				<v-divider class="mt-4 mb-8" />
			</v-col>
		</v-row>
		<v-row no-gutters align="center">
			<v-col sm="3">
				<span class="caption d-inline-block">
					{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}
				</span>
				<v-select
					:items="itemsPerPageAvailable"
					:placeholder="itemsPerPage.toString()"
					class="superTable__paginationSelect d-inline-block ml-4"
					outlined
					v-model="itemsPerPage"
					@change="$emit('length-pagination-change', itemsPerPage)"
				></v-select>
			</v-col>
			<v-col sm="6" class="text-center">
				<v-pagination
					:value="page"
					:length="pageCount"
					@next="$emit('next-pagination', page)"
					@previous="$emit('previous-pagination', page)"
					@input="$emit('input-pagination', $event)"
					class="superTable__pagination"
				/>
			</v-col>
			<v-col sm="3" align="right">
				<v-text-field
					class="superTable__paginationInput d-inline-block mr-4"
					max="pageCount"
					outlined
					v-model.number="goToPage"
					@change="changePage"
				/>
				<span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
			</v-col>
		</v-row>
	</v-container>
</template>

<style lang="sass" scoped>
.v-data-table ::v-deep .v-data-table__wrapper tbody tr.v-data-table__expanded__content
    box-shadow: none
</style>

<script>
import Button from '@/components/Button/Button';
import StudentInnerTable from './StudentInnerTable.vue';

export default {
	name: 'SuperTable',
	components: {
		Button,
		StudentInnerTable,
	},
	props: {
		items: Array,
		headers: Array,
		sortBy: String,
		sortDesc: Boolean,
		pageCount: { type: Number, default: 1 },
		page: { type: Number, default: 1 },
		totalItems: Number,
		loading_table: Boolean,
		statusTypes: Array,
		docTypes: Array,
		foundStudentDocs: Object,
	},
	data() {
		return {
			selectedItems: [],
			itemsPerPage: 20,
			itemsPerPageAvailable: [20, 50, 100, 200],
			tableFilter: null,
			searchStudent: null,
			singleExpand: true,
			expanded: [],
			availableTypes: [],
			goToPage: 1,
		};
	},
	methods: {
		searchAction() {
			this.$emit('search', this.tableFilter);
		},
		clearAction() {
			this.$emit('search', '');
		},
		changePage() {
			if (this.goToPage > this.pageCount) this.goToPage = this.page;
			else this.$emit('input-pagination', this.goToPage);
		},
	},
	watch: {
		page(val) {
			if (val > this.pageCount) this.page = this.pageCount;
			if (val < 1) this.page = 1;
			if (this.page !== this.goToPage) this.goToPage = this.page;
		},
	},
	computed: {
		itemsCount() {
			const counter = {
				totalItems: this.items.length,
				startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
				endItem: this.page * this.itemsPerPage,
			};
			if (counter.endItem > this.items.length) {
				counter.endItem = this.items.length;
			}
			return counter;
		},
	},
};
</script>
