<template>
	<section>
		<h2 class="mb-4">
			{{ $t('modules.academic.professionalizingPractice.update.documents.title') }}
		</h2>
		<v-row>
			<v-col>
				<v-card
					outlined
					v-if="datosCompany.tipoEmpresaSeleccionId == 264 && $isTeclab"
					class="d-flex flex-row justify-space-around elevation-2"
					@click="getCurriculumVitae"
				>
					<v-card-subtitle class="font-weight-bold text-h6">
						{{ $t('modules.academic.professionalizingPractice.update.documents.cv') }}
					</v-card-subtitle>
					<v-icon>mdi-download</v-icon>
				</v-card>
				<span class="caption error--text" v-if="errorCV">
					{{ $t('modules.academic.professionalizingPractice.update.documents.not_found') }}
				</span>
			</v-col>
			<v-col>
				<v-card outlined class="d-flex flex-row justify-space-around elevation-2" @click="getPresentationLetter">
					<v-card-subtitle class="font-weight-bold text-h6">
						{{ $t('modules.academic.professionalizingPractice.update.documents.presentation_letter') }}
					</v-card-subtitle>
					<v-icon>mdi-download</v-icon>
				</v-card>
			</v-col>
			<v-col>
				<v-card outlined v-if="$isTeclab" class="d-flex flex-row justify-space-around elevation-2" @click="getAcademicRecord">
					<v-card-subtitle class="font-weight-bold text-h6">
						{{ $t('modules.academic.professionalizingPractice.update.documents.academic_record') }}
					</v-card-subtitle>
					<v-icon>mdi-download</v-icon>
				</v-card>
			</v-col>
		</v-row>
	</section>
</template>

<script>
import { $presentationLetter, $academicRecord, $curriculumVitae } from '../../Services';
import download from 'downloadjs';

export default {
	name: 'PPFiles',
	props: ['datosCompany', 'legajo', 'loading'],
	data() {
		return {
			errorCV: false,
		};
	},
	methods: {
		async getPresentationLetter() {
			if (this.loading) return;
			try {
				this.$emit('loading', true);
				const presentation_letter = await $presentationLetter.find(null, null, {
					params: { legajo: `${this.legajo}` },
				});
				download(presentation_letter.data, `${this.datosCompany.razonSocial}.pdf`);
			} catch (err) {
				this.$emit('error');
				throw false;
			} finally {
				this.$emit('loading', false);
			}
		},
		async getAcademicRecord() {
			if (this.loading) return;
			try {
				this.$emit('loading', true);
				const academic_record = await $academicRecord.find(null, null, {
					params: { legajo: `${this.legajo}` },
				});
				download(academic_record.data, `${this.legajo}.pdf`);
			} catch (err) {
				this.$emit('error');
				throw false;
			} finally {
				this.$emit('loading', false);
			}
		},
		async getCurriculumVitae() {
			if (this.loading) return;
			try {
				this.$emit('loading', true);
				const curriculum_vitae = await $curriculumVitae.find(null, null, {
					params: { legajo: `${this.legajo}` },
				});
				download(curriculum_vitae.data, `${this.legajo}.pdf`);
			} catch (err) {
				this.errorCV = true;
				setTimeout(() => {
					this.errorCV = false;
				}, 5000);
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
	},
};
</script>
