<template>
    <v-container fluid class="superTable pa-0">
        <v-row no-gutters>
            <v-col sm="12">
            <v-data-table
                :headers="headers"
                :items="data"
                :totalItems="totalItems"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                item-key="id"
                class="superTable__table"
                style="table-layout: fixed"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :server-items-length="totalItems"
                @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
                @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
            >
            <template v-slot:[`item.name`]="{item}">
                <div class="superTable__table__item__name">
                    <div>{{ item.name }} {{ item.lastname }}</div>
                </div>
            </template>
            <template v-slot:[`item.career`]="{item}">
                <div class="superTable__table__item__name">
                    <div>{{ item.careerCode }} - {{ item.career }}</div>
                </div>
            </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
        </v-col>
        </v-row>
        
        <v-row no-gutters align="center">
        <v-col sm="3">
          <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
          <v-select
            :items="itemsPerPageAvailable"
            :placeholder="itemsPerPage.toString()"
            class="superTable__paginationSelect d-inline-block ml-4"
            outlined
            v-model="itemsPerPage"
            @change="$emit('length-pagination-change', itemsPerPage)"
          ></v-select>
        </v-col>
        <v-col class="text-center" sm="6">
          <v-pagination
            :value="page"
            :length="pageCount"
            @next="$emit('next-pagination', page)"
            @previous="$emit('previous-pagination', page)"
            @input="$emit('input-pagination', $event)"
            class="superTable__pagination"
          ></v-pagination>
        </v-col>
        <v-col align="right" sm="3">
          <v-text-field
            class="superTable__paginationInput d-inline-block mr-4"
            max="pageCount"
            outlined
            v-model.number="goToPage"
            @blur="changePage"
          ></v-text-field>
          <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'SuperTable',
    props: {
      data: Array,
      headers: Array,
      deleteTitle: String,
      sortBy: String,
      sortDesc: Boolean,
      pageCount: { type: Number, default: 1 },
      page: { type: Number, default: 1 },
      totalItems: Number
    },
    data () {
      return {
        timeout: null,
        search: '',
        itemsPerPage: 20,
        itemsPerPageAvailable: [20, 50, 100, 200],
        goToPage: 1,
      }
    },
    methods: {
      changePage() {
        if (this.goToPage > this.pageCount) this.goToPage = this.page;
        else this.$emit('input-pagination', this.goToPage)
      },
    },
    watch: {
      page (val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val < 1) this.page = 1;
        if (this.page !== this.goToPage) this.goToPage = this.page;
      },
    },
    computed: {
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };
  
        if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
        return counter
      }
    },
  };
  </script>