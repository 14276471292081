<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="12">
      <v-row>
        <v-col justify-center align-center cols="4">
          <div class="square-banner">
            <div class="square-container">
              <img class="image-square" src="@/assets/images/portalalumno/teclabCreditsPig.png" alt="">
              <p>{{$t('modules.portalalumno.payments.checkingAccount.credits.control.title')}}</p>
              <p class="accumulated">{{data.accumulated}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="8">
          <CreditsBanner :points="data.redeemed" />
          <CreditsBanner :points="data.due" :date="data.dueDate" :isError="true"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CreditsBanner from '../CreditsBanner';
export default {
  name: 'TeclabCreditsBanner',
  components: {
    CreditsBanner,
  },
  props: {
    data: {
      type: Object,
      required: false,
    }
  }
}
</script>

<style lang="sass" scoped>
.square-banner
  width: 238px
  height: 183px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border-radius: 17px
  margin: auto
  margin-top: 40px
  border: solid 1px #0088cc
.square-container
  display: flex
  flex-direction: column
  align-items: center
  p
    color: rgba(0, 136, 204, 0.85)
    margin-bottom: 0px
.accumulated
  font-size: 26px
  font-weight: bold
  text-align: left
  color: rgba(0, 136, 204, 0.85)
.image-square
  width: 78px
  height: 78px

</style>
