import Conciliation from '../Conciliation';
import ConciliationCreate from '../Views/ConciliationCreate';
import ConciliationDetail from '../Views/ConciliationDetail';
import ConciliationTable from '../Views/ConciliationTable';

const ConciliationRoute =
{
  path: '/conciliations',
  meta: { 
    name: {
      en: 'Conciliation',
      es: 'Conciliación',
      }
    },
  component: Conciliation,
  children: [
    {
      path: '/',
      component: ConciliationTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'conciliations', permission: 'READ' }
    },
    {
      path: 'create',
      component: ConciliationCreate,
      meta: { name: 'Create Conciliation', group: 'portaladministrativo.prices', path: 'conciliations', permission: 'CREATE' }
    },
    {
      path: 'detail/:id',
      component: ConciliationDetail,
      meta: { name: 'Detail Conciliation', group: 'portaladministrativo.prices', path: 'conciliations', permission: 'READ' }
    },
  ]
};

export default ConciliationRoute
