const objectivesTranslations = {
    es: {
        table: {
            breadcrumbs: {
              title: 'Objetivos',
              description: 'Administración de objetivos.'
            },
            headers: {
              'seller': 'Agente',
              'from': 'Fecha desde',
              'to': 'Fecha hasta',
              'studentType': 'Tipo estudiante',
              'amount': 'Objetivo',
              'career': 'Carrera',
            },
            filters: {
              title: 'Refinar búsqueda',
              product: 'Producto',
              studenType: 'Tipo de estudiante',
              date: 'Fecha',
              career: 'Carrera',
            },
            message: {
              delete: '¿Estás seguro que desea eliminar?',
              deleteAction: 'Si, eliminar',
              cancelAction: 'Cancelar',
            },
            search: {
                bulkDelete: 'Eliminar objetivos'
            },
            error_message: {
              delete_message: 'No se puede eliminar la comisión',
              bulk_delete_message: 'No se pueden eliminar las comisiones'
            },
            filters_asidebar: {
              'ingresante': 'Ingresante',
              'reingresante': 'Reingresante',
            }
          },
          create: {
            breadcrumbs: {
              title: 'Nuevo objetivo',
              description: 'Crea un nuevo objetivo.'
            },
            sectionData: {
              title: 'Datos de la comisión',
              subtitle: 'Agrega la información básica de la comisión y su porcentaje.',
              labelEnable: 'Habilitada',
              labelDisable: 'Deshabilitada',
              items: {
                billingProduct: 'Producto de facturación',
                range: 'Rangos',
                seller: 'Agentes',
                objective: 'Objetivo',
              },
              error: {
                'selectedStudentTypes' : 'Debes seleccionar al menos un tipo de estudiante',
              }
            },
            sectionMultiple: {
              title: 'Rangos, agentes y objetivos',
            },
            sectionCareer: {
              subtitle: 'Selecciona en esta sección la carrera.',
            },
            validations: {
              studentType: 'Debe seleccionar al menos un tipo de estudiante',
              selectCareer: 'Debe seleccionar una carrera',
              range: 'Debe seleecionar un rango',
              seller: 'Debe seleecionar un agente',
              objective: 'Debe informar un objectivo mayor a cero',
            },
            success_message: {
              title: 'Comisión creada correctamente',
              description: 'creada',
              actions: {
                  primary_text: 'Cerrar',
                  secondary_text: 'Crear otro',
              },
            },
            error_message: {
                title: 'Se produjo un error al crear el objetivo',
            },
            errors: {
              popup: {
                btnPrimary: 'Volver',
              },
            },
            multilpleFormGroup: {
              percentage: 'Porcentaje :',
            }
          },
          update: {
            breadcrumbs: {
              title: 'Editar objetivo',
              description: 'Edita el objetivo seleccionado.'
            },
            success_message: {
              title: 'Comisión modificada correctamente',
              actions: {
                  primary_text: 'Cerrar',
              },
            },
            sectionData: {
              title: 'Datos del objetivo',
              subtitle: 'Edite la información básica del objetivo.',
              labels: {
                'studentType': 'Tipo estudiante',
                'careers': 'Carrera',
                'range': 'Rango',
                'seller': 'Agente',
                'objective': 'Objetivo',
              },
            },
            error_message: {
                title: 'Se produjo un error al modificar el objetivo',
                actions: {
                    back: 'Volver',
                  },
            },
          },
          upload: {
            breadcrumbs: {
              title: 'Importar archivo excel',
              description: 'Permite subir un archivo de Excel con los datos a agregar.'
            },
            headers: {
              'fileNumber': 'Nro de fila',
              'created': 'Procesado',
              'idRange': '# Rango',
              'sellerCode': 'Código vendedor',
              'objetiveAmount': 'Objetivo',
              'studentTypeValue': 'Tipo de estudiante',
              'careerCode': 'Código de carrera',
            },
          }
    },
    en: {
        
    }
};

export default objectivesTranslations
