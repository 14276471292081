<template>
  <v-container class="superTable pa-0" fluid>
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          :placeholder="$t('actions.search')"
          class="superTable__search"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :totalItems="totalItems"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          show-select
          class="superTable__table"
          hide-default-footer
          item-key="id"
          style="table-layout: fixed"
          v-model="selectedItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)" 
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)" 
        >
          <template v-if="permissions.delete" v-slot:top>
            <div :class="['superTable__bulkActions', 'py-2',selectedItems.length ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                <span
                  @click="$emit('bulk-delete', selectedItems)"
                  class="caption mx-4 cursor-pointer hover-7-inverted"
                  v-if="permissions.delete"
                ><v-icon class="mr-2" size="22">mdi-delete</v-icon>{{ deleteTitle }}</span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props"
                               v-on="on"></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              :value="isSelected"
              @input="select($event)"
              class="superTable__table__item__checkbox"
              color="primary"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.studentType`]="{item}">
            {{ item.studentType.meaning }}
          </template>

          <template v-slot:[`item.billingProduct`]="{item}">
            {{ item.billingProduct.name }}
          </template>

          <template v-slot:[`item.dateFrom`]="{item}">
            {{ item.dateFrom.substr(0, 10) }}
          </template>

          <template v-slot:[`item.percentageCommission`]="{item}">
            {{ `${item.percentageCommission} %` }}
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-menu bottom left>
              <template v-if="permissions.update || permissions.delete" v-slot:activator="{ on }">
                <v-icon class="superTable__table__item__action" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="editItem(item.id)" v-if="permissions.update">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item.id)" v-if="permissions.delete">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>
                    {{ $t('actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" no-gutters>
      <v-col sm="3">
        <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
        <v-select
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          class="superTable__paginationSelect d-inline-block ml-4"
          outlined
          v-model="itemsPerPage"
          @change="$emit('length-pagination-change', itemsPerPage)"
        ></v-select>
      </v-col>
      <v-col class="text-center" sm="6">
        <v-pagination 
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)"
          @input="$emit('input-pagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col align="right" sm="3">
        <v-text-field
          class="superTable__paginationInput d-inline-block mr-4"
          max="pageCount"
          outlined
          v-model.number="goToPage"
          @blur="changePage"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SuperTable',
    props: {
      data: Array,
      headers: Array,
      clearSelected: String,
      deleteTitle: String,
      permissions: { delete: false, update: false },
      sortBy: String,
      sortDesc: Boolean,
      pageCount: { type: Number, default: 1 },
      page: { type: Number, default: 1 },
      totalItems: Number
    },
    data() {
      return {
        selectedItems: [],
        search: '',
        timeout: null,
        itemsPerPage: 20,
        itemsPerPageAvailable: [20, 50, 100, 200],
        goToPage: 1,
      }
    },
    methods: {
      keyup () {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
              this.searchAction()
              clearTimeout(this.timeout);
          },1000)
      },
      searchAction() {
        this.$emit('searchAction', this.search)
      },
      editItem(id) {
        this.$emit('updateCommission', id);
      },
      deleteItem(id) {
        this.$emit('deleteCommission', id);
      },
      changePage() {
        if (this.goToPage > this.pageCount) this.goToPage = this.page;
        else this.$emit('input-pagination', this.goToPage)
      }
    },
    watch: {
      page (val) {
        if (val > this.pageCount) this.page = this.pageCount;
        if (val < 1) this.page = 1;
        if (this.page !== this.goToPage) this.goToPage = this.page;
      },
      clearSelected() {
        this.selectedItems = []
      }
    },
    computed: {
      itemsCount() {
        const counter = {
          totalItems: this.totalItems,
          startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
          endItem: this.page * this.itemsPerPage
        };

        if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
        return counter
      }
    },
  };
</script>
