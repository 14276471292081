export default {

  // module state in the core

  status: true,

  // MUTATIONS DICTIONARY

  /*<--------------------------------------------------------------
     Set the relationship between the mutation, the database field
     and the vuex field (the last two must have the same name).
    -------------------------------------------------------------->*/

  mutationsDictionary: {
    index: {
      table: {
        start: 0,
        end: 1,
      },
    },
    content: [


      //DISCOUNTS LIST
      { type: 'Array', setter: 'SET_DISCOUNTS', section: '', field: 'discounts' },

    ]
  },

  // TRASH

  /*<--------------------------------------------------------------
     Save state's changes, relating old value, field an setter for
     undo action
    -------------------------------------------------------------->*/

  undo: false,
  trash: [],

  // data
  discounts: []


}
