<template>
  <div class="container">
    <h1 class="title font-weight-bold">{{ $t('modules.examExceptions.create.stepper.justify.component.text',{student}) }}</h1>
    <div class="table-container mt-5">
      <v-row class="table-row">
        <v-col sm="2" class="primary--text teal lighten-5 d-flex justify-center">{{ $t('modules.examExceptions.create.stepper.justify.component.headers.day') }}</v-col>
        <v-col sm="2" class="primary--text teal lighten-5 d-flex justify-center">{{ $t('modules.examExceptions.create.stepper.justify.component.headers.hour') }}</v-col>
        <v-col sm="3" class="primary--text teal lighten-5 d-flex justify-center">{{ $t('modules.examExceptions.create.stepper.justify.component.headers.ceo') }}</v-col>
        <v-col sm="5" class="primary--text teal lighten-5 d-flex justify-center">{{ $t('modules.examExceptions.create.stepper.justify.component.headers.subject') }}</v-col>
      </v-row>
      <v-row class="table-row">
        <v-col sm="2" class="d-flex justify-center align-center text-center">{{ day }}</v-col>
        <v-col sm="2" class="d-flex justify-center align-center text-center">{{ hour }}</v-col>
        <v-col sm="3" class="d-flex justify-center align-center text-center">{{ ceoName }}</v-col>
        <v-col sm="5" class="d-flex justify-center align-center text-center">{{ subject }}</v-col>
      </v-row>
    </div>
    <h3 class="body-2 mt-5">{{ $t('modules.examExceptions.create.stepper.justify.component.selectBoardType') }}</h3>
    <v-radio-group v-model="boardType">
      <v-radio color="primary" :label="$t('modules.examExceptions.create.stepper.justify.component.types.notebook',{cupos:this.noteQuantity})" :disabled="this.noteQuantity < 1" :value="notebookType"></v-radio>
      <v-radio color="primary" :label="$t('modules.examExceptions.create.stepper.justify.component.types.laboratory',{cupos:this.labQuantity})" :disabled="this.labQuantity < 1" :value="laboratoryType"></v-radio>
    </v-radio-group>

    <h3 class="body-2 mt-5">{{ $t('modules.examExceptions.create.stepper.justify.component.selectReason') }}</h3>
    
    <v-select
      :label="$t('modules.examExceptions.create.stepper.justify.component.reason')"
      outlined
      :items="reasons"
      item-text="meaning"
      item-value=""
      return-object
      v-model="reason"
      class="mt-5 justify-select"
    />

    <v-textarea
      outlined
      class="mt-5"
      v-model="justify"
      :label="$t('modules.examExceptions.create.stepper.justify.title')"
    />
    
    <div class="d-flex justify-end mt-10">
      <Button
        white
        class="mr-3"
        :text="$t('actions.return')"
        @clicked="goBack"
      />
      <Button
        :disabled="!canFinish || !reason || !justify || !boardType"
        :text="$t('actions.inscribe')"
        @clicked="inscribe"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button'
import moment from 'moment'
import { $exams } from '../../Services'

export default {
    name:'Justify',
    components:{
      Button
    },
    props:{
      reasons: Array,
      canFinish: Boolean,
      date: Object,
      ceo: Object,
      student: String,
      subject: String,
    },
    data(){
      return{
        reason: null,
        justify: null,
        boardType:null,
        labQuantity: null,
        noteQuantity: null,
        notebookType: this.$sisConstants('modules.examBoards.notebook'),
        laboratoryType: this.$sisConstants('modules.examBoards.laboratory'),
      }
    },
    methods:{
      inscribe(){
        this.$emit('inscribe', {reasonType: this.reason, reason: this.justify, boardType: this.boardType})
      },
      goBack(){
        this.$emit('goBack')
      },
      clearData() {
        this.reason = null
        this.justify = null
        this.boardType = null
        this.labQuantity = null
        this.noteQuantity = null
      }
    },
    computed:{
      day(){
        return this.date ? moment(this.date.start).format('DD-MM-YYYY'): '-'
      },
      hour(){
        return this.date ? this.date.title : '-'
      },
      ceoName(){
        return this.ceo ? this.ceo.text : '-'
      }
    },
    watch: {
      async date(val) {
        this.clearData()
        const ids = val.id[1] ? `${val.id[0]},${val.id[1]}` : `${val.id[0]}`
        const boardsData = (await $exams.find(`periods/details?id=${ids}`)).data
        this.labQuantity = (boardsData.labQuantity !== null && boardsData.labStudents !== null) ? boardsData.labQuantity - boardsData.labStudents : 0
        this.noteQuantity = (boardsData.noteQuantity !== null && boardsData.noteStudents !== null) ? boardsData.noteQuantity - boardsData.noteStudents : 0 
        if(this.noteQuantity > 0) this.boardType = this.notebookType
        else if(this.labQuantity > 0) this.boardType = this.laboratoryType
      }
    }
}
</script>

<style lang="sass" scoped>
    .table
      &-container
        width: 100%
        border: 1px solid #ccc
        border-radius: 5px
      &-row
        width: 100%
        margin: 0

    .justify-select
      width: 70%

</style>