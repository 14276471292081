<template>
  <div>
    <v-container fluid class="container-custom fullHeight">
        <Breadcrumbs
          :title="$t('modules.exams_group.statements.modules.breadcrumbs.title')"
          :description="`${this.$route.query.code} - ${this.$route.query.description}`"
        >
        </Breadcrumbs>
    <v-container class="container-custom px-6" v-if="!newContentSuccess">
      <div class="px-10 mb-12">
        <v-row justify="space-between">
          <v-col lg="6" cols="12" class="viewSpaces px-0 pl-lg-0 pr-lg-4" style="padding-top:76px !important">
            <Expandible
              :permissions="{
                read: canRead,
                update: canUpdate,
                delete: canDelete,
              }"
              :modulos="data"
              @sendIdTema="fetchIdTema"
              @enviarTrue="() => modificarTrue = true"
              @openBulkUpload="openBulkUpload"
            />
          </v-col>
          <v-col lg="6" cols="12" class="viewSpaces px-0 pl-lg-0 pr-lg-4" v-if="TableStatementList ">
            <v-row>
              <v-col cols="12" class="py-0 d-flex justify-end">
                <Button
                class="ml-4"
                @clicked=" $router.push(`/exams/statements/statement/${idTema}/create`) "
                depressed
                :text="$t('modules.exams_group.statements.modules.createModule')"
                ></Button>
              </v-col>
              <v-col cols="12" class="py-0 d-flex align-center">
                <v-checkbox
                  v-for="n in exam_types"
                  :key="n.value"
                  :label="`${n.meaning}`"
                  :value="n.value"
                  v-model="selectedType"
                  @change="fetchStatements"
                  multiple
                  :disabled="isDisabled(n)"
                  class="checkbox"
                ></v-checkbox>
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('actions.search')"
                  outlined
                  clearable
                  @keyup="keyup"
                  @click:clear="clean"
                  class="superTable_search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" >
                <TableStatements
                  :headers="TableStatementHeader"
                  :data="TableStatementList "
                  :clear-selected="clearSelected"
                  :permissions="{ update: canUpdate, delete: canDelete }"
                  :deleteTitle="
                    $t('modules.commissions.prizes.table.bulk_delete_title')
                  "
                  :totalItems="totalItems"
              />
              </v-col>
            </v-row>
          </v-col>          
        </v-row>

        <v-row justify="end" class="my-8" v-if="modificarTrue">
          <Button
            class="ml-4"
            @clicked="$router.go(-1)"
            outlined
            depressed
            :text="$t('actions.cancel')"
          ></Button>
          <Button
            class="ml-4"
            @clicked="updateContentPopUp"
            :text="$t('actions.save')"
            depressed

            :loading="saveButton.loading"
            :success="saveButton.success"
            :error="saveButton.error"
            @end="resetButtonValues"

            :successText="$t('actions.saved')"
            :errorText="$t('actions.error')"
          ></Button>
        </v-row>
      </div>
    </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
      <TemporaryRightDrawer
        @tmpDrawerOpen="closeRightDrawer"
        :open="drawerOpen"
        :title="$t('modules.exams_group.statements.bulkUpload.bulk.title')"
        :description="$t('modules.exams_group.statements.bulkUpload.bulk.subtitle')"
      >
      <UploadTemplate
        @update="fetchModules"
        @close="closeRightDrawer"
        :id="bulkUploadId"
        ref="drawer"
      ></UploadTemplate>
      </TemporaryRightDrawer>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
import Expandible from '../Components/Expandible';
import TableStatements from '../Components/TableStatements';
import Button from '@/components/Button/Button';
import { $statements,$types } from '../Services/index';
import { mapGetters } from 'vuex';
import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer';
import UploadTemplate from '../Components/UploadTemplate.vue';

export default {
  name: 'Contents',
  components: { Breadcrumbs, Expandible, Button, TableStatements, SuccessMessage, TemporaryRightDrawer,UploadTemplate  },
  data() {
    return {
      drawerOpen: false,
      saveButton: {
        loading: false,
        success: false,
        error: false,
      },
      bulkUploadId: null,
      counter: -1,
      newContentSuccess: false,
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
      search:'',
      exam_types:[],
      selectedType:[],
      modificarTrue:false,
      totalItems: 0,
      TableStatementHeader: [
        {
          text: 'Nombre',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Dificultad',
          value: 'difficulty',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Estado',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      TableStatementList : null,
      
      clearSelected: null,
      data: [],
      idTema: null,
      newVar: [
        {
          id: '',
          description: 'Modulo 1',
          order: 1,
          childen: [
            {
              id: '',
              description: 'Unidad 1',
              order: 1,
              childen: [
                {
                  id: '',
                  description: 'Tema 1',
                  order: ''
                }
              ]
            }
          ]
        }
      ],
      progamaR:''
    };
  },
  methods: {
    clean(){
      setTimeout(()=>{
        this.search == null ? this.search = '':''
        this.fetchStatements()
      },100)
    },
    keyup(){
      clearTimeout(this.timeout)
      this.timeout = setTimeout(()=>{
        this.fetchStatements()
        clearTimeout(this.timeout)
      },300)
    },
    async getExamTypes(){
      const types = await $types.find(null, null, {params: {type:'STATEMENT_EXAM_TYPE'}})
      this.exam_types = types.data
      for (let index = 0; index < types.data.length; index++) {
        const element = types.data[index]
        this.selectedType.push(element.value)      
      }
    },
    isDisabled(type){
      return this.selectedType.length === 1 && this.selectedType[0] === type.value
    },
    resetButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    newOrder(data){
      data.forEach((e)=>{
        e.order = ++this.counter
        if(e.children?.length){
          this.newOrder(e.children)
        }
      })
    },
    async saveStatements(){
      this.newOrder(this.data)
      const data = {
        program:{
          id: this.$route.params.program_id,
        },
        modules:this.data
      }
      try {
        this.saveButton.loading = true;
        await $statements.create(data, '','contents');
        this.successMessage.type = 'success',
        this.successMessage.title = this.$t('modules.exams_group.statements.modules.success_message.title'),
        this.successMessage.actionPrimary = { text: this.$t('modules.exams_group.statements.programsCreate.finish'), callback: () => { this.$router.go(-1) } };
        this.successMessage.actionSecondary = {text: this.$t('Hacer otro cambio'), callback: () => { this.newContentSuccess = false; this.fetchModules(); this.modificarTrue = false} };
        this.saveButton.success = true;
      } catch (error) {
        this.successMessage.type = this.$t('actions.error');
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.exams_group.statements.modules.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.newContentSuccess = false } }
        this.saveButton.error = true;
        throw error
      } finally {
          this.saveButton.loading = false;
          this.newContentSuccess = true;
      }
    },
    updateContentPopUp() {
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de modificar?',
        actionPrimary: {text: 'Aceptar', callback: () => this.saveStatements()},
        actionSecondary: {text: 'Cancelar', callback() {}},
        icon: { color: 'warning', name: 'mdi-alert' },
        color: 'primary'
      });
    },
    fetchIdTema(itemId) {
      this.idTema = itemId;
    },
    estructureModules(contents, parentId){
      const children = []
      for (let index = 0; index < contents.length; index++) {
        if(contents[index].parent && contents[index].parent.id == parentId){
          const child = contents[index]
          child.editable = false
          child.enabled = true
          child.order = children.length + 1
          child.children = this.estructureModules(contents, child.id)
          children.push(child)
        }
      }
      return children
    },
    async fetchModules() {
      try {
        const res = await $statements.find(`contents/${this.$route.params.program_id}`);
        const { data } = res;
        data.sort((a, b) => (a.order && b.order ? a.order - b.order : a.id - b.id));
        this.data = []
        for (let index = 0; index < data.length; index++) {
          if(data[index].parent == null){
            const module = data[index]
            module.editable = false
            module.enabled = true
            module.order = this.data.length + 1
            module.children = this.estructureModules(data, module.id)
            this.data.push(module)
          }
        }
      } catch (error) {
        this.data = [];
        throw error;
      }
    },
    async fetchStatements() {
      const res = await $statements.find(
        `getStatementsByContent/${this.idTema}`, null,{params:{search:`${this.search}`,statementExamTypeIds:`${this.selectedType}`}}
      );
      this.TableStatementList  = res.data.map(({ id, description, difficulty, status }) => {
        return { id, description, difficulty, status };
      })
      this.totalItems = res.data.length;
    },
    openBulkUpload(item) {
      this.drawerOpen= true;
      this.bulkUploadId= item.id;
    },
    closeRightDrawer(){
      this.drawerOpen = false;
      this.$refs.drawer.massImportStatus = false;
    }
  },


  computed: {
    canRead() {
      return this.$permissions.portaladministrativo.exams.statements.READ;
    },
    canCreate() {
      return this.$permissions.portaladministrativo.exams.statements.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.exams.statements.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.exams.statements.DELETE;
    },
    ...mapGetters({
      configurationValues: 'settings/getAllCurrentValues',
      configurationOptions: 'settings/getAllOptions'
    })
  },


  watch: {
    idTema() {
      this.fetchStatements();
    }
  },


  mounted () {
    this.fetchModules()
    this.getExamTypes()
  },


};
</script>

<style lang="sass" scoped>
.checkbox
  margin-right: 5px
</style>