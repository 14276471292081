<template>
  <v-card :elevation="n - 1" class="mx-auto mb-2" width=100%>
    <v-list>
        <v-list-item>
            <v-list-item-action class="mr-0">
                <v-btn text icon color="grey">
                    <v-icon>mdi-drag-vertical</v-icon>
                </v-btn>
            </v-list-item-action>
            <v-list-item-avatar size="64" color="grey" class="mx-8">
                <!--v-if="avatar"-->
                <!-- <v-img :src="data.avatar"></v-img> -->
            </v-list-item-avatar>
            <div class="flex-column align-content-start d-flex flex-grow-1">
                <v-list-item-title class="subtitle-1 primary--text font-weight-medium align-self-start">
                    {{ data.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="subtitle-2 font-weight-regular">{{ data.texto }}
                </v-list-item-subtitle>
                <div class="mr-auto px-3">
                    <v-chip :class="dynamicClass" x-small :color="dynamicColor">
                        <v-avatar left class="mr-0 pr-0">
                            <template v-if="data.status">
                                <v-icon size="10">mdi-check</v-icon>
                            </template>
                            <template v-else>
                                <v-icon size="10">mdi-close</v-icon>
                            </template>
                        </v-avatar>
                        {{data.status ? 'Activa' : 'Inactiva'}}
                    </v-chip>
                </div>
            </div>
            <v-list-item-action class="mr-2">
                <v-switch v-model="data.status" color="indigo darken-3"></v-switch>
            </v-list-item-action>
            <v-list-item-action class="mr-0">
                <v-btn text icon color="grey" :to="{path: 'applicationedit'}">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'AppItem',
  props: {
    data: Object,
  },
  data () {    return {
    }  },
  computed: {
    dynamicClass () {
      let tmpClass = ''
      this.data.status ? tmpClass = 'success--text' : tmpClass = 'error--text'
      return tmpClass
    },
    dynamicColor () {
      let tmpColor = ''
      this.data.status ? tmpColor = 'success lighten-5' : tmpColor = 'error lighten-5'
      return tmpColor
    }
  }
};
</script>
