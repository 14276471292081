<template>
  <div class="px-4">
    <v-row>
      <v-col>
        <strong class="ContractPreviewStudentDetailsCard-title">
          {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.title') }}
        </strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.fullName') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ studentName }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $countryConstants.identificationType }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ studentIdentification }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.email') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ studentEmail }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.cellphone') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ studentCellphone }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.address') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ studentAddress }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <strong class="ContractPreviewStudentDetailsCard-title">
          {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.title2') }}
        </strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.fullName') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ agentName }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $countryConstants.identificationType }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ agentIdentification }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.email') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ agentEmail }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.cellphone') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ agentCellphone }}
          </span>
        </div>
      </v-col>
      <v-col cols="3">
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--title">
            {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.address') }}
          </span>
        </div>
        <div>
          <span class="ContractPreviewStudentDetailsCard-detail--description">
            {{ agentAddress }}
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: [
    'studentAddress',
    'studentCellphone',
    'studentEmail',
    'studentIdentification',
    'studentName',
    'agentAddress',
    'agentCellphone',
    'agentEmail',
    'agentIdentification',
    'agentName'
  ]
}
</script>

<style lang="sass">
.ContractPreviewStudentDetailsCard
  &-title
    color: #011856
  &-detail--title
    color: #2a3d71
    font-size: 13px
  &-detail--description
    font-size: 13px
</style>