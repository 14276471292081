
import ProfessionalizingPracticeView from '../Views/ProfessionalizingPracticeView'
import ProfessionalizingPracticeUpdate from '../Views/ProfessionalizingPracticeUpdate';


const ProfessionalizingPracticeRoute = [
    {
        path: '/',
        component: ProfessionalizingPracticeView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'professionalizing_practice', permission: 'READ' },
    },
    {
        path: 'update/:id',
        component: ProfessionalizingPracticeUpdate,
        meta: { name: 'Update', group: 'portaladministrativo.academic', path: 'professionalizing_practice', permission: 'UPDATE' }
    },
]

export default ProfessionalizingPracticeRoute