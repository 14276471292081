<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!updateDepartmentStatus">
        <Breadcrumbs
          :title="$t('modules.departments.update.breadcrumbs.title')"
          :description="$t('modules.departments.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.departments.create.title')"
                :subtitle="$t('modules.departments.create.subtitle')"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6">
                    <v-text-field
                      counter="75"
                      :error-messages="($v.name.$anyDirty && $v.name.$anyError) ? ((!$v.name.required) ? $t('modules.departments.create.validation_errors.name') : $t('modules.departments.create.validation_errors.name_length')) : ''"
                      @blur="$v.name.$touch()"
                      v-model="$v.name.$model"
                      :label="`${$t('modules.departments.create.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3">
                    <v-text-field
                      :error-messages="($v.code.$anyDirty && $v.code.$anyError) ? ((!$v.code.required) ? $t('modules.departments.create.validation_errors.code') : $t('modules.departments.create.validation_errors.code_unique')) : ''"
                      :loading="loadingCode"
                      v-model.trim="$v.code.$model"
                      @blur="validateCode"
                      :label="`${$t('modules.departments.create.labels.code')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12">
                    <v-textarea
                      outlined
                      :label="`${$t('modules.departments.create.labels.description')} *`"
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.departments.create.validation_errors.description') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.departments.create.section_subjects.title')"
                :subtitle="$t('modules.departments.create.section_subjects.subtitle')"
                class="mb-6"
              >
                <TransferList
                  alwaysSearchToBackend
                  :avatar="false"
                  :loading="loadingSearchSubjects"
                  :firstListTitle="$t('modules.departments.create.labels.available')"
                  :secondListTitle="$t('modules.departments.create.labels.selected')"
                  :availableItems.sync="subjects"
                  :added-items.sync="subjectsAdded"
                  areEquals="id"
                  @searchItemLeftOnBackend="(val) => getSubjects(val)"
                  @searchItemRightOnBackend="(val) => searchSubjectsAdded(val)"
                ></TransferList>
                <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="subjectsAdded.length===0"
                >{{ $t('modules.departments.create.validation_errors.subjects') }}</v-alert>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/departments')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdate || $v.formValid.$anyError"
                  @end="resetButtonValues()"
                  @clicked="updateDepartment"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import TransferList from '@/components/TransferList/TransferList';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $departments, $subjects } from '../Services';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'DepartmentsUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    TransferList,
    SuccessMessage
  },
  data() {
    return {
      paginationLimit: 100,
      loadingSearchSubjects: false,
      loadingCode: false,
      codeUnique: null,
      updateDepartmentStatus: false,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.departments.update.success_message.title'),
        actionPrimary: {
          text: this.$t(
            'modules.departments.update.success_message.actions.primary_text'
          ),
          callback: () => {
            this.$router.push('/departments');
          }
        }
      },

      name: null,
      code: null,
      actualCode: null,
      description: null,
      selectedSubjects: [],
      availableSubjects: [],
      filteredSubjects: [],
      subjects: [],
      subjectsAdded: []
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(75)
    },
    code: {
      required,
      unique() {
        return this.codeUnique !== false;
      }
    },
    description: { required },
    formValid: ['name', 'code', 'description']
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.departments.UPDATE && this.subjectsAdded.length !== 0;
    },
  },
  methods: {
    searchSubjects(event) {
      this.getSubjects(event);
    },
    searchSubjectsAdded(event) {
      this.subjectsAdded = event;
    },
    async getSubjects(subjectStr) {
      const subject = subjectStr ? subjectStr.toUpperCase() : undefined;
      try {
        this.loadingSearchSubjects = true;
        let subjects = [];
        if (subject) {
          subjects = await $subjects.find(null, null, {
            params: { name: subject, code: subject, length: this.paginationLimit }
          });
        } else {
          subjects = await $subjects.find(null, null, {
            params: { length: this.paginationLimit }
          });
        }
        if (subjects.data && subjects.data.content.length) {
          subjects = subjects.data.content.map(item => {
            return {
              id: item.id,
              name: `${item.code  } ${  item.description}`
            };
          });
          this.subjects = subjects;
        }
      } finally {
        this.loadingSearchSubjects = false;
      }
    },
    async validateCode() {
      this.$v.code.$touch();
      if (this.$v.code.$model) {

        if(this.actualCode === this.$v.code.$model) {
          this.codeUnique = null;
        }
        else {
          this.loadingCode = true;
          try {
            const response = await $departments.find('exists', null, {
              params: { code: this.$v.code.$model }
            });
            this.codeUnique = !response.data.exists;
            this.loadingCode = false;
          } catch (error) {
            this.codeUnique = null;
            throw error;
          } finally {
            this.$v.code.$touch();
          }
        }
      }
    },
    async updateDepartment() {
      if (!this.canUpdate) return false
      this.saveButton.loading = true;

      const subjects = [];
      this.subjectsAdded.forEach(subject => {
        subjects.push({ id: subject.id });
      });

      const updatedDepartment = {
        name: this.name,
        code: this.code,
        description: this.description,
        subjects
      };

      try {
        await $departments.update(this.$route.params.id, updatedDepartment);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.departments.update.success_message.title');
        this.successMessage.actionPrimary = {text: this.$t('modules.departments.update.success_message.actions.primary_text'), callback: () => {this.$router.push('/departments')}};
        this.saveButton.success = true;
      } catch {
        this.saveButton.error = true;
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.departments.update.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.departments.update.success_message.actions.back'), callback: () => {this.updateDepartmentStatus = false}};
      } finally {
        this.updateDepartmentStatus = true;
        this.saveButton.loading = false;
      }
    },
    resetButtonValues() {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
    async fetchData() {
      const allData = Promise.all([
        $departments.findById(this.$route.params.id)
      ]);
      const [departments] = await allData;

      // Department data
      this.name = departments.data.name;
      this.code = departments.data.code;
      this.actualCode = departments.data.code;
      this.description = departments.data.description;

      this.subjectsAdded = departments.data.subjects
        ? departments.data.subjects.map(item => {
            return {
              id: item.id,
              name: `${item.code  } ${  item.description}`
            };
          })
        : [];
      this.subjects = this.subjects
        .filter(
          item => !this.subjectsAdded.map(item => item.id).includes(item.id)
        )
        .map(item => ({ name: item.name, id: item.id }));
    }
  },
  async mounted() {
    await this.getSubjects();
    await this.fetchData();
  }
};
</script>
