<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!updatePrizesStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.prizes.update.breadcrumbs.title')"
                :description="$t('modules.commissions.prizes.update.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.prizes.update.sectionData.title')"
                        :subtitle="$t('modules.commissions.prizes.update.sectionData.subtitle')"
                        class="mb-6"
                    >
    
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.prizes.update.sectionData.labels.studentType') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ commission.studentType ? commission.studentType.meaning : '-' }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.prizes.update.sectionData.labels.date') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="commission.dateFrom"
                                            :label="`${$t('modules.commissions.prizes.update.sectionData.labels.date')} *`"
                                            append-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="commission.dateFrom"
                                        no-title
                                        color="primary"
                                        scrollable
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.prizes.update.sectionData.labels.percentageFrom') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-text-field
                                    v-model="$v.commission.percentageFrom.$model"
                                    :label="`${$t('modules.commissions.prizes.update.sectionData.labels.percentageFrom')} *`"
                                    @keypress="$validateIntegerInputNumber($event, $v.commission.percentageFrom.$model, 200, null, 1)"
                                    append-icon="mdi-percent"
                                    outlined
                                    :error-messages="($v.commission.percentageFrom.$error && $v.commission.percentageFrom.$dirty) ?
                                    ( (!$v.commission.percentageFrom.required) ?
                                    $t('modules.commissions.prizes.create.validations.percentageRequired')
                                    :(!$v.commission.percentageFrom.minValue) &&
                                    $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '101', beMax:'200'})
                                    ) : ''"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.prizes.update.sectionData.labels.extraPayment') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-text-field
                                    v-model="$v.commission.extraPayment.$model "
                                    :label="`${$t('modules.commissions.prizes.update.sectionData.labels.extraPayment')} *`"
                                    @keypress="$validateIntegerInputNumber($event, $v.commission.extraPayment.$model, 100, null, 1)"
                                    append-icon="mdi-percent"
                                    outlined
                                    :error-messages="($v.commission.extraPayment.$error && $v.commission.extraPayment.$dirty) ?
                                    ( (!$v.commission.extraPayment.required) ?
                                    $t('modules.commissions.prizes.create.validations.percentageRequired')
                                    :(!$v.commission.extraPayment.minValue) &&
                                    $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '1', beMax:'100'})
                                    ) : ''"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/prizes')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="saveButton.loading"
                            :success="saveButton.success"
                            :error="saveButton.error"
                            :text="$t('actions.save')"
                            :successText="$t('actions.saved')"
                            :errorText="'Error'"
                            :disabled="!canUpdate || $v.$invalid"
                            @end="resetButtonValues"
                            @clicked="updateCommission"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import { required } from 'vuelidate/lib/validators';    
    import { $commissionPrizes } from '../Services';

    export default {
        name: 'PrizesUpdate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
        },
        data() {
            return {
                commission: {
                    dateFrom: null,
                    percentageFrom: null
                },
                updatePrizesStatus: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                menu: false,
                successMessage: {
                    type: '',
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.prizes.UPDATE
            },
        },
        mounted () {
            this.getCommission()
        },
        methods: {
            async getCommission() {
                try {
                    this.commission = (await $commissionPrizes.findById(this.$route.params.id)).data
                    this.commission.dateFrom = this.commission.dateFrom.substr(0, 10)                    
                } catch (error) {
                    this.commission = {percentageFrom: null}
                    throw error
                }
            },
            resetButtonValues () {
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async updateCommission () {
                if(!this.canUpdate) return false;
                try {
                    this.saveButton.loading = true;
                    await $commissionPrizes.update(this.$route.params.id, this.commission)
                    this.successMessage.type = 'success',
                    this.successMessage.title = this.$t('modules.commissions.prizes.update.success_message.title'),
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/prizes') } }
                    this.saveButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.prizes.update.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.updatePrizesStatus = false } }
                    this.saveButton.error = true;
                    throw error;
                } finally {
                    this.saveButton.loading = false;
                    this.updatePrizesStatus = true;
                }
            },
        },
        validations: {
            commission: {
                percentageFrom: {
                        required,
                        minValue(value) {
                            return value > 100 && value <= 200
                        }            
                    },
                extraPayment:{ 
                        required,
                        minValue(value) {
                            return value > 0 && value <= 100
                        }
                    },
            }
        },
    }
</script>