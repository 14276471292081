<template>
<!-- expansion conteiner-->
  <v-expansion-panels id="expansion">

    <!-- expansion 4 -->
    <v-expansion-panel
      v-for="(modulo, indexModulo) in modulos"
      :key="modulo.id"
      class="mb-5"
    >

    <!-- expansion hijo -->
      <v-expansion-panel-header class="module-bg" >

        <!-- fila expansion -->
        <v-row @click="pressTema( modulo.id, `${indexModulo + 1}`) ">

          <!-- Modulos -->
          <v-col cols="10" >
            <h3 v-if="!modulo.editable">
              {{ `${modulo.moduleType.meaning} : ${ modulo.description } ` }}
            </h3>
            <v-text-field
                :placeholder="modulo.description"
                v-model="modulo.description"
                label="Solo"
                v-else
                solo
            ></v-text-field>
          </v-col>
          
          <!-- menu modulo -->
          <v-col cols="2" class="d-flex justify-end">
            <v-menu bottom left>
              <template v-slot:activator="{ on: menu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{ ...tooltip, ...menu }" class=""
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <span>{{
                    $t("modules.scholarships.table.message.actions")
                  }}</span>
                </v-tooltip>
              </template>

              <v-list class="pa-0">
                <v-list-item
                  v-if="permissions.read"
                  @click="addChill(modulo)"
                >
                  <v-list-item-title>
                    
                    {{ "+ Agregar unidad" }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="permissions.update"
                  @click="enabledEdit(modulo)"
                >
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{ $t("modules.exams_group.statements.actions.edit") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="permissions.update"
                  @click="openBulkUpload(modulo)"
                >
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>
                    {{ $t("modules.exams_group.statements.actions.bulkUpload") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

        </v-row>        
      </v-expansion-panel-header>

  <!-- UNIDAD -->
      <v-expansion-panel-content class="expansion-pa-0 module-bg" >
        <v-expansion-panels focusable class="module-bg" >
          <draggable v-model="modulo.children" class="width-full">
            <v-expansion-panel
              v-for="(unidad, indexUnidad) in modulo.children"
              :key="unidad.id"
              class="module-bg my-0 pl-0"
            >
              <v-expansion-panel-header class="module-tema-bg pl-12" :class="{'disenble': !unidad.enabled}"
                @click="pressTema(unidad.id, `${indexModulo + 1}.${indexUnidad + 1}`)"
              >
                <v-row >
                  <v-col cols="10">
                    <p class="mb-1">
                      Unidad {{ indexModulo + 1 }}.{{ indexUnidad + 1 }}
                    </p>
                    <h3 v-if="!unidad.editable">{{ unidad.description }}</h3>
                    <v-text-field
                      :placeholder="unidad.description ? unidad.description : 'Descripción'"
                      v-model="unidad.description"
                      v-else
                      solo
                    ></v-text-field>
                  </v-col>

                  <!-- Menu Unidad -->
                  <v-col cols="2" class="d-flex justify-end">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on: menu }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-icon v-on="{ ...tooltip, ...menu }" class=""
                              >mdi-dots-vertical</v-icon
                            >
                          </template>
                          <span>
                            {{ $t("modules.scholarships.table.message.actions") }}
                          </span>
                        </v-tooltip>
                      </template>

                      <v-list class="pa-0">
                        <v-list-item
                          v-if="permissions.read"
                        >
                          <v-list-item-title
                          style="cursor:pointer"
                          class="row-pointer"
                          @click="addChill(unidad)">
                            {{ $t("modules.exams_group.statements.components.expanded.addTema") }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="permissions.update"
                          @click="enabledEdit(unidad)"
                        >
                          <v-list-item-title>
                            <v-icon>mdi-pencil</v-icon>
                            {{ $t("modules.exams_group.statements.actions.edit") }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="permissions.delete"
                          @click="desablePopUp(unidad, indexModulo)"
                        >
                          <v-list-item-title>
                            <v-icon>{{ unidad.enabled ? 'mdi-delete':'mdi-check' }}</v-icon>
                            {{unidad.enabled ? $t("modules.exams_group.statements.components.expanded.disable") : $t("modules.exams_group.statements.components.expanded.enable")}}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="permissions.update"
                            @click="openBulkUpload(unidad)"
                          >
                            <v-list-item-title>
                              <v-icon>mdi-pencil</v-icon>
                              {{ $t("modules.exams_group.statements.actions.bulkUpload") }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <!-- Tema -->
              <v-expansion-panel-content class="module-bg">
                <draggable v-model="unidad.children">
                  <div
                    class="px-0 py-0"
                    v-for="(tema, indexTema) in unidad.children"
                    :key="tema.id"
                  >
                    <div
                      :class="{ 'module-tema-select':`${indexModulo + 1}.${indexUnidad + 1}.${indexTema + 1}` == selectedContent, 'disenble': !tema.enabled }"
                      class="d-flex pl-16 pr-12 py-4 align-center AsignatureSearch__card"
                      @click="
                        pressTema(
                          tema.id,
                          `${indexModulo + 1}.${indexUnidad + 1}.${
                            indexTema + 1
                          }`
                        )
                      "
                    >
                      <v-icon class="mr-2">mdi-drag-vertical</v-icon>
                      <v-chip class="primary px-2 mr-2" x-small>
                        {{
                          `${indexModulo + 1}.${indexUnidad + 1}.${
                            indexTema + 1
                          }`
                        }}
                      </v-chip>
                      <span style="flex: 1" v-if="!tema.editable">
                        {{ tema.description }}
                      </span>
                      <v-text-field
                        :placeholder="tema.description? tema.description : 'Descripción'"
                        v-model="tema.description"
                        v-else
                        solo
                      ></v-text-field>
                      
                      <!-- Menu Temas -->
                      <v-menu bottom left>
                        <template v-slot:activator="{ on: menu }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-icon v-on="{ ...tooltip, ...menu }" class=""
                                >mdi-dots-vertical</v-icon
                              >
                            </template>
                            <span>{{
                              $t("modules.scholarships.table.message.actions")
                            }}</span>
                          </v-tooltip>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item
                            v-if="permissions.update"
                            @click="enabledEdit(tema)"
                          >
                            <v-list-item-title>
                              <v-icon>mdi-pencil</v-icon>
                              {{ $t("modules.exams_group.statements.actions.edit") }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            :disabled="!tema.parent.enabled"
                            v-if="permissions.delete"
                            @click="toggleEnableTema(tema, indexTema)"
                          >
                            <v-list-item-title>
                              <v-icon>{{tema.enabled?'mdi-delete':'mdi-check'}}</v-icon>
                              {{ tema.enabled ? $t("modules.exams_group.statements.components.expanded.disable") : $t("modules.exams_group.statements.components.expanded.enable")}}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="permissions.update"
                            @click="openBulkUpload(tema)"
                          >
                            <v-list-item-title>
                              <v-icon>mdi-pencil</v-icon>
                              {{ $t("modules.exams_group.statements.actions.bulkUpload") }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <v-divider width="78%" class="mx-auto"></v-divider>
                  </div>
                </draggable>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  name: 'Expandible',
  components: { draggable },
  props: {
    permissions: { read: false, update: false, delete: false },
    modulos: { type: Array }
  },
  data() {
    return {
      selectedContent: ''
      
    };
  },
  methods: {
    pressTema(temaId, orderContent) {
      this.selectedContent = orderContent;
      this.$emit('sendIdTema', temaId);
      // this.$emit('enableSave', 'item')
    },
    enabledEdit(item){
      item.editable = !item.editable
      this.$emit('enviarTrue')
    },
    async addChill(parent){
      const newChill = {
        children: [],
        childrenCount: '',
        description: '',
        editable: true,
        enabled: true,
        id: null,
        moduleType: parent.moduleType,
        contentType: parent?.contentType ? ( parent.contentType == 227 ? 228 : 229  ) : 227, 
        order: null,
        parent:{
          editable: true,
          enabled: true,
          },
        program: null,
      }
      this.$emit('enviarTrue')
      parent.children.push(newChill)
    },
    toggleEnableUnidad(item){
      item.enabled = !item.enabled
      item.children.forEach(chil => {
        chil.parent.enabled = item.enabled        
        if(!item.enabled) chil.enabled = false
      })
    },
    toggleEnableTema(item){
      if(item.parent.enabled){ 
        item.enabled = !item.enabled
        }
    },
    desablePopUp(unidad, indexModulo) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.exams_group.statements.warning_message.disableUnidad'),
        actionPrimary: {text: 'Aceptar', callback: () => this.toggleEnableUnidad(unidad, indexModulo)},
        actionSecondary: {text: 'Cancelar', callback() {}},
        icon: { color: 'warning', name: 'mdi-alert' },
        color: 'primary'
      });
    },
    openBulkUpload(item) {
      this.$emit('openBulkUpload', item)
},
  },
};
</script>

<style lang="sass">
.expansion-pa-0 .v-expansion-panel-content__wrap
  padding: 0px !important  
  &:hover
    cursor: pointer
.expansion-pa-0 .v-expansion-panel-header--active
  background-color: rgba(236,236,236,.10 ) !important

</style>

<style lang="sass" scoped>
.module-bg
  background-color: rgba(247,247,247,.45)  
.module-tema-bg
  background-color: rgba(236,236,236,.20)
  font-family: Roboto
  border-radius:0%    
  color:#6e6e6e
  
.module-tema-select
  background-color: rgba(236,236,236,.45)
.AsignatureSearch
  background: #ebebeb
  width: 25%
  height: auto  
  &__card    
    transition: all 300ms ease
    &:hover, &:active
      z-index: 2
      box-shadow: 0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
.width-full
  width: 100%
</style>