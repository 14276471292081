<template>
  <v-menu content-class="HelpMenu elevation-12" v-model="showMenu">
    <v-list class="md-6 px-0 pt-1 pb-2">
      <v-list-item-group class="pa-0">
        <v-subheader class="py-0 px-4">{{$t('components.helpMenu.helpSection.title')}}</v-subheader>
        <v-list-item class="py-3 px-4" :href="_self['settings/getUrls']['documentation']['currentValue']" target="_blank"
          replace>
          <v-list-item-title>{{$t('components.helpMenu.helpSection.items.documentation')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
        <v-list-item class="py-3 px-4" :href="_self['settings/getUrls']['news']['currentValue']" target="_blank" replace>
          <v-list-item-title>{{$t('components.helpMenu.helpSection.items.news')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
        <v-list-item class="py-3 px-4" target="_blank" replace>
          <v-list-item-title>{{$t('components.helpMenu.helpSection.items.about')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
        <v-list-item class="py-3 px-4" target="_blank" replace>
          <v-list-item-title>{{$t('components.helpMenu.helpSection.items.shortcuts')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
      </v-list-item-group>
      <v-list-item-group class="pa-0">
        <v-subheader class="py-0 px-4">{{$t('components.helpMenu.legalSection.title')}}</v-subheader>
        <v-list-item class="py-3 px-4" :href="_self['settings/getUrls']['privacyPolicy']['currentValue']" target="_blank"
          replace>
          <v-list-item-title>{{$t('components.helpMenu.legalSection.items.privacyPolicy')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
        <v-list-item class="py-3 px-4" :href="_self['settings/getUrls']['termsConditions']['currentValue']"
          target="_blank" replace>
          <v-list-item-title>{{$t('components.helpMenu.legalSection.items.termsConditions')}}</v-list-item-title>
          <v-icon small class="px-0">mdi-open-in-new</v-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<style scoped lang="sass">
  .HelpMenu
    position: absolute
    left: 45px !important
    bottom: 85px
    top: unset !important
    .v-list-item
      min-height: 0
      .v-item-group
        :nth-child(2)
          padding-top: 6px !important
      .v-menu__content
        border-radius: 5px
        margin-left: 50px

      .theme--light.v-icon
        padding: 0 !important
        margin: 0 0 0 12px
</style>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    showMenu: Boolean,
  },
  methods: {
    getComputed (prop) {
      return this[prop]
    },
    close () {
      this.$emit('close');
    }
  },
  computed: {
    ...mapGetters([
      'settings/getUrls'
    ])
  }
}
</script>
