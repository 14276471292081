export default {

  // Setting general getters
  getDiscounts (state) {
    return state.discounts;
  }


  // Settings Terms and Privacy

}
