<template>
	<section v-if="step == 2">
		<h3 class="mb-2">
			{{ $t('modules.academic.professionalizingPractice.update.steppers.manage.documentation') }}
		</h3>
		<div class="d-flex justify-space-start align-center">
			<v-checkbox
				v-model="dataPP.documentacionEnviada"
				label="Enviada"
				:readonly="!(datosCompany.validacion || !dataPP.automaticSend)"
				:disabled="disableCheckboxEnviada"
				@change="updateCheckEnvioConvenio(dataPP.documentacionEnviada)"
				class="mr-6"
			/>
			<v-checkbox
				v-model="dataPP.firmadoPorAlumno"
				label="Firmado por el Alumno"
				:readonly="!(datosCompany.validacion || !dataPP.automaticSend)"
				:disabled="disableCheckboxFirmadoPorAlumno"
				@change="getSignStudent(dataPP.firmadoPorAlumno)"
				class="mr-6"
			/>
			<v-checkbox
				v-model="dataPP.firmadoPorEmpresa"
				label="Firmado por la empresa"
				:readonly="!(datosCompany.validacion || !dataPP.automaticSend)"
				:disabled="disableCheckboxFirmadoPorEmpresa"
				@change="getSignCompany(dataPP.firmadoPorEmpresa)"
				class="mr-6"
			/>
		</div>
	</section>
</template>

<script>
import { $updateCheckEnvioConvenio, $updateCheckFirmadoPorAlumno, $updateCheckFirmadoPorEmpresa } from '../../Services';

export default {
	name: 'CheckDocsSent',
	props: ['dataPP', 'step', 'datosCompany', 'loading'],
	computed: {
		disableCheckboxEnviada: {
			get() {
				return (
					!this.dataPP.empresaCumpleRequisitos ||
					!this.dataPP.empresaContinuaProceso ||
					this.datosCompany.estadoPPId != 279 ||
					this.loading
				);
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxFirmadoPorAlumno: {
			get() {
				return (
					!this.dataPP.empresaCumpleRequisitos ||
					!this.dataPP.empresaContinuaProceso ||
					this.datosCompany.estadoPPId != 279 ||
					!this.dataPP.documentacionEnviada ||
					this.loading
				);
			},
			set(value) {
				return value;
			},
		},
		disableCheckboxFirmadoPorEmpresa: {
			get() {
				return (
					!this.dataPP.empresaCumpleRequisitos ||
					!this.dataPP.empresaContinuaProceso ||
					this.datosCompany.estadoPPId != 279 ||
					!this.dataPP.documentacionEnviada ||
					!this.dataPP.firmadoPorAlumno ||
					this.loading
				);
			},
			set(value) {
				return value;
			},
		},
	},
	methods: {
		async updateCheckEnvioConvenio(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckEnvioConvenio.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value, studentCareer: `${this.datosCompany.legajo}` },
				});
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
		async getSignStudent(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckFirmadoPorAlumno.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value },
				});
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
		async getSignCompany(value) {
			try {
				this.$emit('loading', true);
				const res = await $updateCheckFirmadoPorEmpresa.create(null, {
					params: { convenioId: `${this.dataPP.convenioId}`, status: value },
				});
				return res;
			} catch (err) {
				this.$emit('error');
				throw err;
			} finally {
				this.$emit('loading', false);
			}
		},
	},
};
</script>
