<template>
    <v-container class="d-flex flex-row pa-0 fullHeight scrollableContent" fluid>
        <v-container class="container container-custom px-6">
            <Breadcrumbs :title="$t('modules.examExceptions.create.breadcrumbs.title')" :description="$t('modules.examExceptions.create.breadcrumbs.description',{student, career})"></Breadcrumbs>
            <v-row justify="center">
                <v-col sm="12">
                    <Stepper
                        non-linear
                        :stepper="stepper"
                        :currentStep="currentStep"
                        :canFinish="canFinish"
                        :finishButton="finishButton"
                        :canGoToNextStep="canGoToNextStep"
                        @changeStep="changeStep"
                        @confirm="createExam"
                        @resetFinishButtonValues="resetFinishButtonValues"
                        class="stepper"
                    >
                        <v-container class="container-custom">
                            <v-row justify="center" no-gutters>
                                <v-col sm="10">
                                    <v-stepper-content step="1" class="px-0">
                                        <SubjectSelection
                                            :subjects="availableSubjects"
                                            @selectSubject="selectSubject"
                                        />
                                    </v-stepper-content>
                                    <v-stepper-content step="2" class="px-0">
                                        <AvailableBoard
                                            @selectDate="selectDate"
                                            @goBack="changeStep(1)"
                                        />
                                    </v-stepper-content>
                                    <v-stepper-content step="3" class="px-0">
                                        <Justify    
                                            :reasons="reasons"
                                            :canFinish="canFinish"
                                            :date="selectedDate"
                                            :ceo="selectedCeo"
                                            :student="student"
                                            :subject="selectedSubject?selectedSubject.subject:''"
                                            @goBack="changeStep(2)"
                                            @inscribe="createExam"
                                        />
                                    </v-stepper-content>
                                    <v-stepper-content step="4" class="px-0">
                                        <Confirmation />
                                    </v-stepper-content>
                                </v-col>
                            </v-row>
                        </v-container>
                    </Stepper>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Stepper from '@/components/Stepper/Stepper';
    import AvailableBoard from '../Components/CreateSteps/AvailableBoard';
    import Justify from '../Components/CreateSteps/Justify';
    import Confirmation from '../Components/CreateSteps/Confirmation';
    import SubjectSelection from '../Components/CreateSteps/SubjectSelection';
    import { mapGetters } from 'vuex';
    import { $materias, $types, $students, $exams, $examsException } from '../Services';

    export default {
        name: 'ExamExceptionCreate',
        components:{
            Breadcrumbs,
            Stepper,
            Justify,
            AvailableBoard,
            Confirmation,
            SubjectSelection,
        },
        data() {
            return {
                student: null,
                career: null,
                totalItems: 0,
                pagination: {
                    limit: 20,
                    page: 1,
                    currentPage: 1,
                    pagesAmount: 1,
                },
                history: [],
                currentStep: 1,
                canGoToNextStep: false,
                finishButton: {
                    loading: false,
                    success: false,
                    error: false,
                    text: this.$t('actions.create'),
                    successText: this.$t('actions.created'),
                    errorText: 'error',
                },
                breadcrumbs: {
                    title: this.$t('modules.preRegistration.table.breadcrumbs.title'),
                    description: this.$t('modules.preRegistration.table.breadcrumbs.subtitle'),
                    button: {
                        name: this.$t('actions.new'),
                        icon: 'mdi-plus',
                        color: 'primary'
                    }
                },
                stepper: [
                    {
                        title: this.$t('modules.examExceptions.create.stepper.subjectSelection.title'),
                        button: this.$t('modules.examExceptions.create.stepper.subjectSelection.button'),
                        available: false,
                    },{
                        title: this.$t('modules.examExceptions.create.stepper.availableBoard.title'),
                        button: this.$t('modules.examExceptions.create.stepper.availableBoard.button'),
                        available: false,
                    },{
                        title: this.$t('modules.examExceptions.create.stepper.justify.title'),
                        button: this.$t('modules.examExceptions.create.stepper.justify.button'),
                        available: false,
                    },{
                        title: this.$t('modules.examExceptions.create.stepper.confirmation.title'),
                        button: this.$t('modules.examExceptions.create.stepper.confirmation.button'),
                        available: false,
                    }
                ],
                availableSubjects: [],
                reasons: [],
                selectedSubject: null,
                selectedCeo: null,
                selectedDate: null,
            }
        },
        computed: {
            canFinish () {
                return (this.selectedCeo !== null 
                    && this.selectSubject !== null
                    && this.selectedDate !== null)
            },
            ...mapGetters([
                'commons/getLoadingState',
                'commons/getUserID'
            ]),
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            selectSubject(data){
                this.selectedSubject = data
                this.changeStep(this.currentStep+1)
            },
            async fetchData(){
                const register = this.$route.params.id
                const subjects = (await $materias.find('inscripcion-excepcion', null, {params: {page: 0, length: 500, leg: register} })).data
                
                this.availableSubjects = subjects.regularesDTO.map(subject => {
                    const validateNull = subject.teacher.name && subject.teacher.lastname;
                    return {
                        id: subject.id,
                        subject: subject.descripcion,
                        condition: subject.statusSubject.meaning,
                        term: subject.term.code,
                        examsCount: subject.examsCount,
                        validity: `${subject.vigenciaDesde} al ${subject.vigenciaHasta}`,
                        professor: subject.teacher && validateNull ? `${subject.teacher.name} ${subject.teacher.lastname}`: '-',
                        status: subject.statusExamRequest ? this.$t('modules.examExceptions.create.stepper.subjectSelection.component.states.enabled') : this.$t('modules.examExceptions.create.stepper.subjectSelection.component.states.disabled'),
                    }
                })

                this.reasons = (await $types.find(`?type=EXAM_REASON_TYPE`)).data
                const studentData = (await $students.find(`${register}`)).data
                this.student = `${studentData.name} ${studentData.lastname}`
                this.career = studentData.career.description  
            },
            changeStep (step) {
                this.currentStep = step;
                this.canGoToNextStep = false;
            },
            resetFinishButtonValues () {
                this.finishButton.loading = false
                this.finishButton.success = false
                this.finishButton.error = false
            },
            selectDate(data){
                this.selectedCeo = data.ceo
                this.selectedDate = data.date
                this.changeStep(this.currentStep + 1)
            },
            async createExam(data) {
                
                const ids = this.selectedDate.id && this.selectedDate.id[1] ? `${this.selectedDate.id[0]},${this.selectedDate.id[1]}` : `${this.selectedDate.id[0]}`
                const typesBoards = (await $exams.find(`period-type?periodId=${ids}`)).data
                const boardToSave = typesBoards.find(board =>{
                    return board.type.value === data.boardType
                })
                const register = this.$route.params.id
                const loggedUser = this['commons/getUserID']

                try{
                    await $examsException.create({reason: data.reason, reasonType: data.reasonType},{},`inscripcion?idCeoAgenda=${boardToSave.id}&idCeo=${this.selectedCeo.value}&leg=${register}&idAlumnoMateria=${this.selectedSubject.id}&idUsuario=${loggedUser}&exception=true`)
                    this.changeStep(this.currentStep + 1)
                } catch(error) {    
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{ value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.examExceptions.create.stepper.subjectSelection.component') }],
                        actionPrimary: { text: this.$t('actions.accept'), callback () { } },
                        icon: { color: 'error', name: 'mdi-close' },
                        color: 'primary',
                    });
                    throw error;
                }
            },
        }
    }
</script>

<style lang="sass" scoped>
    .stepper
        border: 1px solid #ccc
</style>