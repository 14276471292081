import TemplateCreate from '../Views/TemplateCreate.vue'
import TemplateEdit from '../Views/TemplateEdit.vue'
import TemplateTable from '../Views/TemplateTable.vue'
import AreaTable from '../Views/AreaTable.vue'

const templateRoute = [
        {
            path: '',
            component: TemplateTable,
            meta: { name: 'Index', group: 'portaladministrativo.messaging', path: 'templates', permission: 'READ' }
        },  
        {
            path: 'area/:id',
            component: AreaTable,
            meta: { name: 'area', group: 'portaladministrativo.messaging', path: 'templates', permission: 'READ' }
        },  
        {
            path: 'area/:id/create',
            component: TemplateCreate,
            meta: { name: 'create', group: 'portaladministrativo.messaging', path: 'templates', permission: 'CREATE' }
        },
        {
            path: 'area/:id/edit/:id',
            component: TemplateEdit,
            meta: { name: 'edit', group: 'portaladministrativo.messaging', path: 'templates', permission: 'CREATE' }
        },
];

export default templateRoute
