<template>
  <div>
    <v-container class="d-flex pa-0 fullHeight" fluid>
      <v-container fluid class="px-6">
        <Breadcrumbs
          :description="$t('modules.sistemaQ.create.calendarbreadcrumbs.description')"
          :title="$t('modules.sistemaQ.create.calendarbreadcrumbs.title')"
        >
          <Button
            v-if="canCreate"
            :icon="$t('modules.sistemaQ.create.calendarbreadcrumbs.button.icon')"
            :text="$t('modules.sistemaQ.create.calendarbreadcrumbs.button.name')"
            @clicked="calendarDrawerOpen"
            depressed
          ></Button>
        </Breadcrumbs>
        <template v-if="!loading">
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <v-row no-gutters class="mb-4">
                <v-col sm="4">
                  <v-autocomplete
                    :items="formattedCeos"
                    :label="$t('modules.sistemaQ.create.ceoSelectLabel')"
                    @change="onCeoSelectedChange"
                    outlined
                    return-object
                    v-if="!isOnlyCeo"
                    v-model="selectedCeo">
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row justify="space-between" no-gutters>
                <v-card outlined class="d-flex px-6 pt-3" max-height="44">
                  <span class="subtitle-2">
                    {{$t('modules.sistemaQ.create.calendar.references')}}
                  </span>
                  <span class="body-2 ml-6">
                    <v-icon class="mr-1" color="#ED8B00" size="11">
                      mdi-circle
                    </v-icon>
                    {{$t('modules.sistemaQ.create.calendar.lab')}}
                  </span>
                  <span class="body-2 ml-6">
                    <v-icon class="mr-1" color="#717EFF" size="11">
                      mdi-circle
                    </v-icon>
                    {{$t('modules.sistemaQ.create.calendar.notebook')}}
                  </span>
                  <span class="body-2 ml-6">
                    <v-icon class="mr-1 square-icon" color="#e9f0ff" size="17">
                      mdi-rectangle
                    </v-icon>
                    {{$t('modules.sistemaQ.create.calendar.disabled')}}
                  </span>
                </v-card>

                <v-select
                  :items="calendarView"
                  v-model="type"
                  :label="$t('modules.sistemaQ.create.calendar.selectView')"
                  outlined
                  class="flex-grow-0"
                  style="width: 150px"
                >
                </v-select>
              </v-row>
              <v-divider class="mt-6"></v-divider>
              <div class="examCalendar">
                <v-sheet>
                  <v-toolbar color="white" flat>
                    <v-btn @click="prev" fab small text>
                      <v-icon small>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn @click="next" fab small text>
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-toolbar-title class="ml-4">
                      <span class="title font-weight-bold">{{ title }}</span>
                      <template>
                        <v-menu
                          :close-on-content-click="false"
                          max-width="290px"
                          min-width="290px"
                          offset-y
                          ref="menu1"
                          transition="scale-transition"
                          v-model="menu1"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn tag="button" color="gray" icon small tile v-on="on">
                              <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>
                          <v-date-picker
                            @input="menu1 = false"
                            color="primary"
                            no-title
                            v-model="focus"
                            @change="(type !== 'day') ? type = 'day' : ''"
                          ></v-date-picker>
                        </v-menu>
                      </template>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </v-sheet>

                <v-sheet height="600">
                  <v-calendar
                    :events="events"
                    :key="calendarKey"
                    :now="today"
                    :type="type"
                    :value="today"
                    :show-month-on-first="false"
                    @change="updateRange"
                    @click="v-on"
                    @click:date="viewDate"
                    @click:day="viewDay"
                    @click:more="onClickMore"
                    class="examCalendar__calendar"
                    color="primary"
                    ref="calendar"
                    v-model="focus"
                  >
                    <template v-slot:event="{ event }">
                      <v-sheet
                        :class="['examCalendar__calendar__event-card', {'disabled-event' : !event.status}]"
                        @click.stop="showEvent(event)"
                      >
                        <div class="examCalendar__calendar__event-card__icons">
                          <v-icon :color="dot.color" :key="dot.color" size="9" v-for="dot in event.dots">mdi-circle</v-icon>
                        </div>
                        <div class="examCalendar__calendar__event-card__text">
                          <span class="examCalendar__calendar__event-card__time">{{ event.title }}</span>
                          <span class="examCalendar__calendar__event-card__seat">{{ event.rightSideText }}</span>
                        </div>
                      </v-sheet>
                    </template>
                  </v-calendar>
                  <v-menu
                    :activator="selectedElement"
                    offset-x
                    v-model="selectedOpen"
                  >
                  </v-menu>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <TemporaryRightDrawer
        v-if="canCreate"
        :description="$t('modules.sistemaQ.create.aside.subtitle')"
        :open="calendarDrawer.open"
        :title="$t('modules.sistemaQ.create.aside.title')"
        @tmpDrawerOpen="toggleTemporaryDrawer"
      >
        <SistemaQExamCreate
          v-if="calendarDrawer.open"
          :clearDrawer="clearDrawer"
          :insideDrawer="true"
          :selectedDate="selectedDate"
          :availableCeos="availableCeos"
          :selectedCeo="selectedCeo"
          @successfulCreation="onSuccessfulCreation"
          @tmpDrawerOpen="calendarDrawer.open = false"
          ref="examCreate"
        ></SistemaQExamCreate>
      </TemporaryRightDrawer>
      <div
        :style="{ top: ypos + 'px', left: xpos + 'px' }"
        class="examCalendar__calendar__more-events"
        v-if="isMenuOpen"
        @click.stop=""
      >
        <span class="examCalendar__calendar__more-events__title caption primary--text font-weight-bold"> Mesa del {{ currentSelectedTableDetails }}</span>
        <v-icon @click="hideMoreEvents" size="20" class="examCalendar__calendar__more-events__close ml-4">mdi-close</v-icon>
        <div class="examCalendar__calendar__more-events__content">
          <template v-for="(event,index) in showMoreEvents">
            <v-sheet
              :class="['examCalendar__calendar__event-card', {'disabled-event' : !event.status}]"
              @click.stop="showEvent(event)"
              :key="`event-more-${event.title}-${index}`"
            >
              <div class="examCalendar__calendar__event-card__icons">
                <v-icon :color="dot.color" :key="dot.color" size="9" v-for="dot in event.dots">mdi-circle</v-icon>
              </div>
              <div class="examCalendar__calendar__event-card__text">
                <span class="examCalendar__calendar__event-card__time">{{ event.title }}</span>
                <span class="examCalendar__calendar__event-card__seat">{{ event.rightSideText }}</span>
              </div>
            </v-sheet>
          </template>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
  import {$exams, $ceos} from '../services';
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import Button from '@/components/Button/Button';
  import TemporaryRightDrawer from '@/components/TemporaryRightDrawer/TemporaryRightDrawer'
  import SistemaQExamCreate from './SistemaQExamCreate'
  import moment from 'moment'
  import { mapGetters } from 'vuex';

  export default {
    name: 'SistemaQExamCalendar',
    components: {
      Breadcrumbs,
      Button,
      TemporaryRightDrawer,
      SistemaQExamCreate
    },
    data() {
      return {
        lastClick: {},
        isMenuOpen: false,
        clearDrawer: false,
        splittedIdParams: [],
        xpos: 0,
        ypos: 0,
        menu1: false,
        tracked: {
          '2019-01-09': [23, 45],
          '2019-01-08': [10],
          '2019-01-07': [0, 78],
          '2019-01-06': [0, 0],
          '2019-01-05': [0, 10],
          '2019-01-04': [2, 90],
          '2019-01-03': [10, 32],
          '2019-01-02': [80, 10],
          '2019-01-01': [20, 25],
        },
        colors: ['#FFFFFF'],
        category: ['Development', 'Meetings', 'Slacking'],
        selectedDate: '',
        availableCeos: [],
        breadcrumbs: {
          title: 'Generación de exámenes',
          description: 'Administración de exámenes',
          button: {
            name: 'Nuevo examen',
            icon: 'mdi-plus',
            color: 'primary'
          }
        },
        calendarDrawer: {
          title: 'Generación de exámenes',
          description: 'Administración de exámenes',
          open: false,

        },
        loadingUsers: false,
        loading: true,
        today: moment().format('YYYY-MM-DD'),
        focus: moment().format('YYYY-MM-DD'),
        type: 'month',
        calendarView: [
          { value: 'month', text: this.$t('modules.sistemaQ.create.calendar.month') },
          { value: 'week', text: this.$t('modules.sistemaQ.create.calendar.week') },
          { value: 'day', text: this.$t('modules.sistemaQ.create.calendar.day') },
        ],
        start: null,
        end: null,
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        selectedCeo: null,
        events: [],
        rawExams: {},
        formattedExamsEvents: {},
        showMoreEvents: []
      }
    },
    computed: {
      canCreate() {
        return this.$permissions.portaladministrativo.exams.creation.CREATE
      },
      canUpdate() {
        return this.$permissions.portaladministrativo.exams.creation.READ || this.$permissions.portaladministrativo.exams.creation.UPDATE
      },
      calendarKey() {
        return this.events.length
      },
      currentSelectedTableDetails() {
        return moment(this.showMoreEvents[0].start).format('DD/MM/YY')
      },
      title() {
        const {start, end} = this;
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start);
        const endMonth = this.monthFormatter(end);
        const suffixMonth = startMonth === endMonth ? '' : endMonth;

        const startYear = start.year;
        const endYear = end.year;
        const suffixYear = startYear === endYear ? '' : endYear;

        const startDay = start.day + this.nth(start.day);
        const endDay = end.day + this.nth(end.day);

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`;
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
          case 'day':
            return ` ${startDay} , ${startMonth} ${startYear}`
        }
        return ''
      },
      isOnlyCeo() {
        return this.availableCeos.length <= 1
      },
      formattedCeos() {
        return this.availableCeos?.map(ceo => ({value: ceo.id, text: ceo.description}))
      },
      ...mapGetters([
        'commons/getUserCeos',
        'commons/getUserRoles',
      ])
    },
    mounted () {
      document.addEventListener('click', this.printMousePos);
      this.getCeos()
    },
    
    methods: {
      async getCeos(){
        try {
          const ceos = await $ceos.find(null, null, {
            params: {
              length: 500,
              orderBy: 'description',
              orientation: 'asc'
            }
          });
          this.availableCeos = ceos.data.content
          this.selectedCeo = this.formattedCeos
          const month = moment(this.today, 'YYYY-MM-DD').format('M');
          const year = moment(this.today, 'YYYY-MM-DD').format('YYYY');
          const fetchedExams = await $exams.find('periods', null, {params: {ceoId: this.selectedCeo[0].value, month, year}});
          this.addYearMonthStructure(this.rawExams, fetchedExams.data, month, year, false);
          this.addYearMonthStructure(this.events, fetchedExams.data, month, year, true)
          this.loading = false
        } catch(error){
          return error
        }
      },
      async onSuccessfulCreation() {
        this.events = [];
        this.rawExams = {}
      },
      printMousePos({screenX, screenY, target}) {
        const firstClass = target.className.split(' ')
        if(firstClass[0] === 'v-event-more'){        
          this.xpos = screenX - 80;
          this.ypos = screenY - 160;
        }
      },
      addYearMonthStructure(list, data, month, year, event) {
        if (!event) {
          if (!list[year]) {
            list[year] = {}
          }
          if (!list[year][month]) {
            const formattedExamsDays = {};
            for (const key in data) {
              formattedExamsDays[moment(key).format('DD')] = data[key]
            }
            list[year][month] = formattedExamsDays
          }
        } else {
          for (const key in data) {
            this.events = [...this.events, ...this.formatSingleDayBoards(data[key], key)]
          }
        }
      },
      formatSingleDayBoards(boards, date) {
        return boards?.map(board => {
          const startFormatted = board.turnStart.slice(0, 5);
          const endFormatted = board.turnEnd.slice(0, 5);
          const generatedCalendarEvent = {
            id: board.id,
            start: `${moment(date).format('YYYY-MM-DD')} ${board.turnStart}`,
            end: `${moment(date).format('YYYY-MM-DD')} ${board.turnEnd}`,
            lab: board.laboratory,
            note: board.note,
            status: board.status,
            title: `${startFormatted} - ${endFormatted}`,
            rightSideText: `${board.studentCount}/${board.studentCapacity}`,
            dots: []
          };
          if (board.laboratory) generatedCalendarEvent.dots.push({color: '#ED8B00'});
          if (board.notebook) generatedCalendarEvent.dots.push({color: '#717EFF'});
          if (generatedCalendarEvent.dots.length === 1) generatedCalendarEvent.dots.push({color: board.status ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0)'});
          return generatedCalendarEvent
        })
      },
      async onCeoSelectedChange() {
        this.events = [];
        this.rawExams = {}
        const month = moment(this.start.date, 'YYYY-MM-DD').format('M')
        const year = moment(this.start.date, 'YYYY-MM-DD').format('YYYY')
        const fetchedExams = await $exams.find('periods', null, {params: {ceoId: this.$isTeclab ? this.selectedCeo[0].value : this.selectedCeo.value, month, year}})
        this.addYearMonthStructure(this.rawExams, fetchedExams.data, month, year, false)
        this.addYearMonthStructure(this.events, fetchedExams.data, month, year, true)
      },
      monthFormatter(date) {
        return this.$t(`modules.sistemaQ.create.months.${date.month}`)
      },
      hideMoreEvents() {
        this.isMenuOpen = false
      },
      onClickMore(selectedEvent) {
        //event
        this.showMoreEvents = this.events.filter(event => moment(event.start, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD') === selectedEvent.date);
        this.isMenuOpen = true
      },
      viewDate({date}) {
        this.focus = date;
        this.type = 'day'
      },
      viewDay() { // { date }
        // this.selectedDate = date
        // this.calendarDrawer.open = true
      },
      getEventColor(event) {
        return event.color
      },
      setToday() {
        this.focus = this.today
      },
      prev() {
        this.$refs.calendar.prev()
      },
      next() {
        this.$refs.calendar.next()
      },
      showEvent(event) {
        if (!this.canUpdate) return false
        const [id1, id2] = event.id;
        let idQuery = id1;
        idQuery += id2 ? `-${id2}` : '';
        this.$router.push({name: `detailExamBoard`, params: {id: idQuery}})
      },
      async updateRange({start, end}) {
        // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
        this.start = start;
        this.end = end;
        if (this.selectedCeo) {
          if (!this.rawExams[moment(this.start.year, 'YYYY-MM-DD').format('YYYY')] || !this.rawExams[moment(this.start.year, 'YYYY-MM-DD').format('YYYY')][this.start.month]) {
            const fetchedExams = await $exams.find('periods', null, {
              params: {
                ceoId: this.selectedCeo[0].value,
                month: this.start.month,
                year: moment(this.start.year, 'YYYY-MM-DD').format('YYYY')
              }
            });
            this.addYearMonthStructure(this.rawExams, fetchedExams.data, this.start.month, moment(this.start.year, 'YYYY-MM-DD').format('YYYY'), false);
            this.addYearMonthStructure(this.events, fetchedExams.data, this.start.month, moment(this.start.year, 'YYYY-MM-DD').format('YYYY'), true)
          }
        }
      },
      nth(d) {
        return d > 3 && d < 21
          ? ''
          : ['', '', '', '', '', '', '', '', '', ''][d % 10]
      },
      toggleTemporaryDrawer() {
        this.calendarDrawer.open = false;
      },
      calendarDrawerOpen() {
        this.calendarDrawer.open = true;
        this.selectedDate = null;
      }
    },
  }
</script>

<style lang="sass">
  .examCalendar
    &__calendar
      .v-calendar, &.v-calendar
        &-weekly, &-daily
          &_head
            &-day
              &-label
                .v-btn--fab.v-size--default
                  height: 40px
                  width: 40px
                  margin: 0.25rem
            &-weekday
              padding-top: 0.375rem
          &__head
            &-weekday
              padding: 0.375rem 0
              font-size: 14px
              font-weight: bold
              text-transform: capitalize
              margin-right: 0 !important
          &__day
            margin-right: 0 !important
            padding: 0 0.5rem 0.5rem 0.5rem
            transition: box-shadow 300ms ease
            position: relative
            &:hover
              z-index: 2
              box-shadow: 0 9px 12px rgba(0, 0, 0, 0.16)
            &-label
              margin: 0.325rem 0
              .v-btn
                font-size: 14px
                font-weight: bold
                color: var(--v-primary-base)
                text-transform: capitalize
                &.v-btn--fab
                  &.v-size--small
                    width: 34px
                    height: 34px
            &.v-present
              .v-btn__content
                color: white
            &.v-outside
              .v-btn__content
                color: rgba(black, 0.5)
                font-weight: normal
            .v-event
              height: unset !important
              width: 100% !important
              background: unset !important
              &-timed
                background-color: transparent !important
                &-container
                  margin: 0 5px
        &-daily
          border: #e0e0e0 1px solid
          &__day
            &:hover
              box-shadow: unset
            .v-event-timed
              overflow: visible
              &-container
                .examCalendar__calendar__event-card
                  flex-direction: column
                  justify-content: center
                  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08)
                  &:hover
                    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.12)
                  &__icons
                    justify-content: center
                    display: flex
                    padding: 0.5rem 0
                    i
                      padding: 0 0.25rem
                  &__text
                    text-align: center
                    min-width: 100%
                  &__time, &__seat
                    padding: 0 0.5rem
          &__head
            position: relative
            z-index: 1
            box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.06)
            margin: 0 !important
          &_head
            &-day
              &:last-child
                border-right-color: white !important
                padding-right: 8px
      &__event-card
        display: flex
        flex-wrap: wrap
        font-size: 0.785rem
        height: 100%
        width: 100%
        padding: 0.325rem 0.425rem
        margin-bottom: 0.325rem
        border: #e0e0e0 1px solid !important
        transition: all 300ms ease
        cursor: pointer
        &:hover
          border: var(--v-primary-base) 1px solid !important
        &.disabled-event
          background-color: var(--v-primary-lighten1)
        &__time
          padding-left: 0.425rem
          align-self: center
          font-size: 11px
        &__seat
          padding-left: 0.425rem
          margin-left: auto
          align-self: center
          font-size: 11px
        &__icons
          align-content: center
          display: flex
      &__more-events
        position: absolute
        z-index: 3
        background: white
        padding: 1rem
        border: 1px solid #ebebeb
        border-radius: 5px
        box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.2), 0 6px 12px -6px rgba(0, 0, 0, 0.1)
        &__content
          margin-top: 0.5rem
</style>
