import Apps from '../Apps';
import AppsView from '../Views/AppsView'

const AppsRoute =
{
  path: '/apps',
  meta: {
    name: {
      en: 'Aplications',
      es: 'Aplicaciones'
    }
  },
  component: Apps,
  children: [
    {
      path: '',
      component: AppsView,
      meta: { name: 'Index', group: 'portalalumno', path: 'apps', permission: 'READ' }
    },
  ]
}

export default AppsRoute