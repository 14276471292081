<template>
  <OutlinedCard :title="$t('modules.users.update.sectionPreviousStudies.title')" class="mb-6">
    <v-container fluid>
      <v-row no-gutters>
        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
          <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPreviousStudies.fields.level') }}</span>
        </v-col>
        <v-col sm="4" class="align-start pt-1 pb-3">
          <v-select
            :label="$t('modules.users.update.sectionPreviousStudies.fields.level')"
            outlined
            :items="studentLevels"
            item-text="meaning"
            v-model="userData.level"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
          <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPreviousStudies.fields.city') }}</span>
        </v-col>
        <v-col sm="4" class="align-start pt-1 pb-3">
          <AutocompleteInput
            :value.sync="localInstitutionCity"
            :label="$t('modules.users.update.sectionPreviousStudies.fields.city')"
            disable-buttons
          >
          </AutocompleteInput>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col sm="4" class="d-flex align-start pb-0 pt-4">
          <span class="caption text-uppercase">{{ $t('modules.users.update.sectionPreviousStudies.fields.institution') }}</span>
        </v-col>
        <v-col sm="4" class="align-start pt-1 pb-3">
          <v-select
            :label="$t('modules.users.update.sectionPreviousStudies.fields.institution')"
            outlined
            :items="institutions"
            :loading="loadingInstitutions"
            item-text="name"
            item-value="id"
            v-model="userData.institution"
          />
        </v-col>
      </v-row>
    </v-container>
  </OutlinedCard>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import { $institutions } from '../../services';

export default {
  name: 'UserEditPreviousStudies',
  props: {
    userData: Object,
    studentLevels: Array,
    institutionCity: Object
  },
  components: {
    OutlinedCard,
    AutocompleteInput
  },
  data () {
    return {
      institutions: [],
      localInstitutionCity: null,
      loadingInstitutions: false
    }
  },
  watch: {
    institutionCity(value) {
      this.localInstitutionCity = value
    },
    async localInstitutionCity() {
      this.loadingInstitutions = true
      try {
        const params = {
          city: this.localInstitutionCity?.idCity || null,
          region: this.localInstitutionCity?.idRegion || null,
          orientation: 'asc',
          orderBy: 'name',
          length: 500
        }
        const {data} = await $institutions.find(null, null, { params })
        this.institutions = data.content
      } catch (err) {
        this.institutions = []
        throw err
      } finally {
        this.loadingInstitutions = false
      }
    }
  }
}
</script>
