import PreregistrationTable from '../Views/PreregistrationTable.vue';
import PreregistrationCreate from '../Views/PreregistrationCreate.vue';
import PreregistrationUpdate from '../Views/PreregistrationUpdate.vue';

const PreregistrationRoute = [
  {
    path:'',
    component: PreregistrationTable,
    meta: { name: 'Index', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'READ' }
  },
  {
    path:'create',
    component: PreregistrationCreate,
    meta: { name: 'PreregistrationCreate', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'CREATE' }
  },
  {
    path: 'update/:id',
    component: PreregistrationUpdate,
    meta: { name: 'PreregistrationUpdate', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'READ' }
  },
]

export default PreregistrationRoute
