const paymentsTranslations = {
    es: {
        inputErrors: {
            required: 'Este campo es obligatorio',
        },
    },
    en: {
        inputErrors: {
            required: 'This field is required',
        },
    }
}

export default paymentsTranslations