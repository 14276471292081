const MultipleProductsCostTranslations = {
    es: {
        creditNote: 'Se generará una nota de crédito por {difference}',
        noDifference: 'El estudiante no deberá abonar una diferencia, ya que las carreras tienen el mismo precio.',
        difference: 'El estudiante deberá abonar una diferencia de {difference}',
        alumno_noDifference: 'No deberas abonar ninguna diferencia',
        alumno_difference: 'Deberas abonar una diferencia de {difference}',
        concepto: 'Concepto',
        carrera_nueva: 'Carrera anterior',
        nueva_carrera: 'Nueva carrera',
        diferencia: 'Diferencia',
        diferencia_costos: 'Diferencia de costos',
    },
    en: {

    }
}

export default MultipleProductsCostTranslations