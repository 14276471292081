<template>
  <div class="container">
    <SuccessMessage
      type="success"
      :title="$t('modules.examExceptions.create.stepper.confirmation.component.title')"
      :subtitle="$t('modules.examExceptions.create.stepper.confirmation.component.subtitle')"
      :actionPrimary="actionPrimary"
    />
  </div>
</template>

<script>
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
export default {
    name:'Confirmation',
    components:{
      SuccessMessage
    },
    data(){
      return{
        actionPrimary:{
          text:this.$t('actions.close'),
          callback:()=>{
            this.$router.push('/exam-exception')
          }
        }
      }
    }
}
</script>

<style>
    /*No style */
</style>