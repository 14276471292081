<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.messaging.banners.table.breadcrumbs.title')"
          :description="$t('modules.messaging.banners.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newBanner"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="banners"
              :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :deleteTitle="$t('modules.messaging.banners.table.actions.bulkDelete')"
              :permissions="{create: canCreate, update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @searchAction="searchAction"
              @bulk-delete="bulkDeletePopUp"
              @deleteData="deletePopUp"
              @updateData="updateBanner"
              @duplicateData="duplicatePopUp"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $banners } from '../Services';
import moment from 'moment';

export default {
  name: 'BannersTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      banners: [],
      sort: {
        sortBy: 'dateTo',
        sortDesc: true,
      },
      headers: [
        {
          text: this.$t('modules.messaging.banners.table.headers.code'),
          value: 'code'
        }, {
          text: this.$t('modules.messaging.banners.table.headers.description'),
          value: 'description'
        }, {
          text: this.$t('modules.messaging.banners.table.headers.dateFrom'),
          value: 'dateFrom'
        }, {
          text: this.$t('modules.messaging.banners.table.headers.dateTo'),
          value: 'dateTo'
        }, {
          text: this.$t('modules.messaging.banners.table.headers.order'),
          value: 'order'
        },{
          text: this.$t('modules.messaging.banners.table.headers.status'),
          value: 'visible'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.messaging.banners.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.messaging.banners.READ || this.$permissions.portaladministrativo.messaging.banners.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.messaging.banners.DELETE;
    },
  },
  methods: {
    async fetchData() {
      if (this.loading) return false;
      this.clearSelected = new Date().getMilliseconds().toString();
      this.loading = true;
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc'
        };
        const filterParams = {
          search: ''
        }

        filterParams.search = this.search;

        const banners = await $banners.filter(filterParams, { params });
        this.banners = banners.data.content.map(item => {
          item.dateFrom = moment(item.dateFrom).format('DD-MM-YYYY');
          item.dateTo = item.dateTo ? moment(item.dateTo).format('DD-MM-YYYY') : '-';
          return item
        });
        this.totalItems = banners.data.totalElements;
        this.pagination.pagesAmount = banners.data.totalPages;
      } catch (err) {
        this.banners = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    deletePopUp(id) {
      const banner = this.banners.find(banner => banner.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{value: banner.description}],
        actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteBanner(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    bulkDeletePopUp(banners) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: banners.map(banner => ({value: banner.description})),
        actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(banners)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async bulkDelete(banners) {
      const itemsForDelete = {
        ids: banners.map((banner) => {
          return banner.id;
        })
      };
      try {
        await $banners.bulkDelete(itemsForDelete);
        this.fetchData();
      } catch (err) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: this.$t('modules.messaging.banners.table.messages.bulkDeleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw err;
      }
    },
    newBanner() {
      if (this.canCreate) this.$router.push('/messaging/banners/create');
    },
    updateBanner(id) {
      if (this.canUpdate) this.$router.push(`/messaging/banners/update/${id}`);
    },
    async deleteBanner(id) {
      try {
        await $banners.delete(id);
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.banners.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    duplicatePopUp(id) {
      const banner = this.banners.find(banner => banner.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.messaging.banners.table.messages.duplicatePopUpMessage'),
        content: [{value: banner.description}],
        actionPrimary: {text: this.$t('actions.duplicate'), callback: () => this.duplicateBanner(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'info', name: 'mdi-content-duplicate'},
        color: 'primary',
      });
    },
    async duplicateBanner(id) {
      try {
        await $banners.findById(id, 'duplicate');
        this.fetchData()
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.banners.table.messages.duplicateError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>

