import Discounts from '../Discounts.vue'
import DiscountsTable from '../Views/DiscountsTable.vue';
import DiscountsCreate from '../Views/DiscountsCreate.vue';
import DiscountsUpdate from '../Views/DiscountsUpdate.vue';
import DiscountsDetail from '../Views/DiscountsDetail.vue';


const DiscountsRoute =
{
  path: '/discounts',
  meta: {
    name: {
      en: 'Discounts',
      es: 'Descuentos'
    }
  },
  component: Discounts,
  children: [
    {
      path: '/',
      component: DiscountsTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'discounts', permission: 'READ' }
    },
    {
      path: 'create',
      component: DiscountsCreate,
      meta: { name: 'Discounts Create', group: 'portaladministrativo.prices', path: 'discounts', permission: 'CREATE' }
    },
    {
      path: 'update/:id',
      component: DiscountsUpdate,
      meta: { name: 'Discounts Update', group: 'portaladministrativo.prices', path: 'discounts', permission: 'READ' }
    },
    {
      path: 'detail',
      component: DiscountsDetail,
      meta: { name: 'Discounts Detail', group: 'portaladministrativo.prices', path: 'discounts', permission: 'READ' }
    },
  ]
};

export default DiscountsRoute
