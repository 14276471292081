<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.portalalumno.procedures.procedureExamCharge.title')"></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces d-flex flex-column align-center" cols="12">
          
          <v-progress-circular v-if="loading" class="mx-auto pt-16 mt-16" indeterminate color="primary"></v-progress-circular>

          <template v-else-if="!procedureStatus">
            <p class="body-2">{{ $t('modules.portalalumno.procedures.procedureExamCharge.description') }}</p>

            <v-row style="width: 100%;">
              <v-col sm="10" class="mx-auto">
                <v-row>
                  <v-col sm="7">
                    <v-autocomplete
                      class="mt-12 mb-4"
                      v-model="selectedCareer"
                      @change="getExamPrice"
                      :items="careers"
                      :label="$t('modules.portalalumno.procedures.procedureExamCharge.autocompleteSubject.label')"
                      item-text="career.degree"
                      return-object
                      hide-details
                      outlined
                    ></v-autocomplete>
                  </v-col>

                  <v-col sm="12">
                    <v-card outlined class="py-3 px-3 payment-item">
                      <v-row justify="space-between" no-gutters>
                        <v-col class="d-flex flex-column align-start justify-center">
                          <span class="black--text body-1 font-weight-bold pl-3">{{ name }}</span>
                          <span class="caption pl-3" v-if="scholarship && needToPay">{{ scholarship.name }}</span>
                          <span class="caption pl-3" v-if="discount && needToPay">{{ discount.name }}</span>
                        </v-col>
                        <v-col class="text-right">
                          <div class="mx-2 d-flex flex-column align-end justify-center fill-height" :class="(discount > 0 && needToPay) ? 'py-1' : 'py-4'">
                            <span v-if="totalDiscount > 0 && needToPay" class="font-weight-bold grey--text caption strikethrough">{{ total | $currency }}</span>
                            <span class="primary--text body-1 font-weight-bold">{{ needToPay ? $options.filters.$currency(charge) : $options.filters.$currency(0) }}</span>
                            <span v-if="totalDiscount > 0 && needToPay" class="font-weight-bold success--text caption">{{ totalDiscount }}% Off</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="12" class="py-5 mt-10 d-flex justify-end">
                    <Button
                      :text="(needToPay && charge) ? $t('actions.pay') : $t('actions.apply')"
                      :loading="createButton.loading"
                      :success="createButton.success"
                      :error="createButton.error"
                      :successText="$t('actions.created')"
                      :errorText="'Error'"
                      @end="resetButtonValues"
                      @clicked="confirm"
                      :disabled="!name"
                    ></Button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.primaryAction"
            :actionSecondary="successMessage.secondaryAction"
          ></SuccessMessage>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import Button from '@/components/Button/Button';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
  // eslint-disable-next-line no-unused-vars
  import { $titleExam, $finalExam, $students } from '../services'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProcedureExamCharge',
    components: {
      Breadcrumbs,
      Button,
      SuccessMessage
    },
    data () {
      return {
        careers: [],
        selectedCareer: null,
        successMessage: {
          title: null,
          type: null,
          primaryAction: null,
          secondaryAction: null,
        },
        procedureStatus: false,
        needToPay: (this.$route.params.needToPay !== undefined) ? this.$route.params.needToPay : true,
        loading: false,
        charge: null,
        totalDiscount: 0,
        discount: null,
        scholarship: null,
        total: null,
        name: null,
        createButton: {
          loading: false,
          success: false,
          error: false
        },
      }
    },
    computed: {
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        studentId: 'commons/getStudentId',
      }),
    },
    mounted () {
      this.getCareers()
    },
    methods: {
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      async getCareers() {
        try {
          this.loading = true
          const careers = await $students.create([55], {params: {finalExam: true}}, `careers/${this.studentId}`)
          this.careers = careers.data
          if (careers.data.length) this.selectedCareer = careers.data[0]  // eslint-disable-line prefer-destructuring
          this.getExamPrice()
        } catch (error) {
          this.selectedCareer = null
          this.careers = []
          this.loading = false
        }
      },
      roundNumber(number) {
        return Math.round((number + Number.EPSILON) * 100) / 100
      },
      async getExamPrice() {
        try {
          this.loading = true
          const params = {
            careerId: this.selectedCareer.career.id,
            ceoId: this.selectedCareer.ceo,
            cityId: null,
            student: this.studentId,
            legajo: this.selectedCareer.idStudentCareer
          }
          const priceData = await $titleExam.find(null, null, { params })
          this.id = priceData.data[0]?.id;
          this.name = priceData.data[0]?.name;
          this.total = priceData.data[0]?.amount;
          this.charge = priceData.data[0]?.amount;
          this.totalDiscount = 0
          this.discount = null
          this.scholarship = null

          if (priceData.data[0].scholarship) {
            const { scholarship } = priceData.data[0]  // eslint-disable-line
            let finalDiscount = 0
            let amount = 0
            if (scholarship.amount) {
              if (scholarship.amount >= this.charge) {
                this.charge = 0
                this.totalDiscount = 100
                finalDiscount = 100
                amount = this.charge
              } else {
                finalDiscount = scholarship.amount / this.charge * 100
                amount = scholarship.amount // eslint-disable-line
                this.totalDiscount += finalDiscount
                this.charge -= scholarship.amount
              }
            } else if (scholarship.percentage) {
              finalDiscount = scholarship.percentage
              amount = this.charge * scholarship.percentage / 100
              this.totalDiscount += (100 - this.totalDiscount) * scholarship.percentage / 100
              this.charge -= this.charge * scholarship.percentage / 100
            }
            this.scholarship = {
              id: scholarship.id,
              name: `${scholarship.description} - ${this.roundNumber(finalDiscount)}% Off`,
              amount,
            }
          }

          if (priceData.data[0].discount) {
            const { discount } = priceData.data[0]  // eslint-disable-line
            let finalDiscount = 0
            let amount = 0
            if (discount.amount) {
              if (discount.amount >= this.charge) {
                this.charge = 0
                this.totalDiscount = 100
                finalDiscount = 100
                amount = this.charge
              } else {
                finalDiscount = discount.amount / this.charge * 100
                amount = discount.amount // eslint-disable-line
                this.totalDiscount += finalDiscount
                this.charge -= discount.amount
              }
            } else if (discount.percentage) {
              finalDiscount = discount.percentage
              amount = this.charge * discount.percentage / 100
              this.totalDiscount += (100 - this.totalDiscount) * discount.percentage / 100
              this.charge -= this.charge * discount.percentage / 100
            }
            this.discount = {
              id: discount.id,
              name: `${discount.description} - ${this.roundNumber(finalDiscount)}% Off`,
              amount,
            }
          }

          if (this.totalDiscount) this.totalDiscount = this.roundNumber(this.totalDiscount)

        } catch (error) {
          this.id = null
          this.charge = null
          this.total = null
          this.totalDiscount = 0
          this.discount = null
          this.scholarship = null
          this.name = null
        } finally {
          this.loading = false
        }
      },
      async confirm () {
        try {
          this.createButton.loading = true
          const examObject = {
            total: this.needToPay ? this.total : 0,
            amount: this.charge,
            billingProductId: this.id,
          }

          if (this.scholarship) examObject.scholarship = this.scholarship
          if (this.discount) examObject.discount = this.discount


          await $finalExam.create(examObject, { params: { degreeId: this.selectedCareer.degreeId } }, `${this.selectedCareer.idStudentCareer}`)
          if (this.needToPay && this.charge) this.$router.push('/payments')
          this.successMessage.title = 'Trámite solicitado correctamente'
          this.successMessage.type = 'success'
          this.successMessage.primaryAction = { text: 'Ver mis pagos', callback() { this.$router.push('/payments/history') } }
          this.successMessage.secondaryAction = { text: 'Volver', callback: () => { this.$router.push('/procedures') } }
          this.procedureStatus = true
          this.createButton.success = true
        } catch {
          this.createButton.error = true
        } finally {
          this.createButton.loading = false
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  .strikethrough
    text-decoration: line-through

  .payment-item 
    width: 100%
    border: 1px solid #6087e1 !important
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
    background: #d9e2fa
</style>