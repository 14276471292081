<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!createNewPrizes">
            <Breadcrumbs
                :title="$t('modules.commissions.prizes.create.breadcrumbs.title')"
                :description="$t('modules.commissions.prizes.create.breadcrumbs.description')"
            ></Breadcrumbs>

            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard :title="$t('modules.commissions.prizes.create.sectionData.title')" :subtitle="$t('modules.commissions.prizes.create.sectionData.subtitle')" class="mb-6">
                        <v-row>
                            <v-col sm="6">
                                <v-select
                                    outlined
                                    multiple
                                    :label="`${$t('modules.commissions.prizes.create.sectionData.items.relation')} *`"
                                    v-model="$v.selectedStudentTypes.$model"
                                    :items="studentTypes"
                                    item-text="meaning"
                                    return-object
                                    @blur="$v.selectedStudentTypes.$touch()"
                                    :error-messages="$v.selectedStudentTypes.$dirty && $v.selectedStudentTypes.$invalid ? $t('modules.commissions.prizes.create.sectionData.error.selectedStudentTypes') : ''"
                                ></v-select>
                            </v-col>
                            <v-col sm="3">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="from_date"
                                            :label="$t('modules.commissions.prizes.create.sectionData.items.from')"
                                            append-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="from_date"
                                        no-title
                                        color="primary"
                                        scrollable 
                                        :min="minDate"
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <OutlinedCard
                        :title="$t('modules.commissions.prizes.create.sectionMultiple.title')"
                        :subtitle="$t('modules.commissions.prizes.create.sectionMultiple.subtitle')"
                        class="mb-6"
                    >
                        <v-row no-gutters>
                            <v-col sm="11">
                                <MultipleFormGroup
                                    :items="$v.groups.$each.$iter"
                                    :length="groups.length"
                                    @addGroup="addGroup"
                                    @removeGroup="removeGroup"
                                >
                                    
                                    <template v-slot:default="{item}">
                                        <v-container fluid class="pa-0">
                                            <v-row>
                                                <v-col class="pb-0" sm="4">
                                                    <v-text-field
                                                        @keypress="$validateIntegerInputNumber($event, item.percentageFrom.$model, 200, null, 1)"
                                                        :label="` ${$t('modules.commissions.prizes.create.sectionData.items.percentageFrom')} *`"
                                                        v-model="item.percentageFrom.$model"
                                                        outlined
                                                        @blur="item.percentageFrom.$touch()"
                                                        :error-messages="(item.percentageFrom.$dirty && item.percentageFrom.$invalid) ? 
                                                            ( (!item.percentageFrom.required) ? $t('modules.commissions.prizes.create.validations.percentageRequired') :
                                                                (!item.percentageFrom.minValue) ? $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '101', beMax:'200'}) : $t('modules.commissions.prizes.create.validations.percentageUnique'))
                                                            : '' "
                                                        append-icon="mdi-percent"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col class="pb-0" sm="4">
                                                    <v-text-field
                                                        @keypress="$validateIntegerInputNumber($event,item.extraPayment.$model, 100, null, 1)"
                                                        :label="`${$t('modules.commissions.prizes.create.sectionData.items.extraPayment')} *`"
                                                        v-model="item.extraPayment.$model"
                                                        outlined
                                                        @blur="item.extraPayment.$touch()"
                                                        :error-messages="(item.extraPayment.$error && item.extraPayment.$dirty)?
                                                        (!item.extraPayment.required && item.extraPayment.minValue? $t('modules.commissions.prizes.create.validations.percentageRequired') 
                                                        : $t('modules.commissions.prizes.create.validations.percentageBeGreater', {beGreater: '1', beMax:'100'}) )
                                                        : ''"
                                                        append-icon="mdi-percent"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>                                                

                                </MultipleFormGroup>
                            </v-col>
                        </v-row>
                    </OutlinedCard>

                    <div class="d-flex justify-end">
                            <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/prizes')"
                            @end="resetButtonValues()"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="createButton.loading"
                            :success="createButton.success"
                            :error="createButton.error"
                            :text="$t('actions.create')"
                            :successText="$t('actions.created')"
                            :errorText="'Error'"
                            :disabled="!canCreate "
                            @end="resetButtonValues"
                            @clicked="createPrize"
                        ></Button>
                    </div>

                </v-col>
            </v-row>

        </v-container>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
    import Button from '@/components/Button/Button';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { required} from 'vuelidate/lib/validators';
    import { $types, $commissionPrizes } from '../Services';


    export default {
        name:'PrizesCreate',
        components: {
            Breadcrumbs,
            OutlinedCard,
            MultipleFormGroup,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                createNewPrizes: false,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null
                },

                from_date: new Date().toISOString().substr(0, 10),
                minDate: new Date().toISOString().substr(0, 10),
                menu: false,
                studentTypes: [],
                selectedStudentTypes: [], 
                groups: [
                    {
                        percentageFrom:null,
                        extraPayment:null,
                    }                
                ],
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.prizes.CREATE
            },
        },
        mounted () {
            this.getStudentType();
        },
        methods: {
            async getStudentType() {
                try {
                    const studentTypes = await $types.find(null, null, { params: { type: 'STUDENT_TYPE'}})
                    this.studentTypes = studentTypes.data
                } catch (error) {
                    this.studentTypes = []
                    throw error
                }
            },
            addGroup () {
                const mockup = {
                    percentageFrom:null,
                    extraPayment:null,
                };
                this.groups.push({
                    ...mockup
                })
            },
            removeGroup (index) {
                this.groups.splice(index, 1)
            },
            async createPrize(){
                if( this.$v.$invalid || !this.canCreate){ 
                    this.$v.$touch()
                }
                else{
                    try {
                    await $commissionPrizes.create({
                        studentTypes: this.selectedStudentTypes,
                        dateFrom: this.from_date,
                        prizesList: this.groups,
                    })
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.commissions.prizes.create.success_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/prizes') } };
                    this.successMessage.actionSecondary = { text: this.$t('actions.create_another'), callback: () => { this.createNewPrizes = false } };

                    this.createNewPrizes = true;
                    this.createButton.success = true;
                    this.clearData();
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.prizes.create.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createNewPrizes = false; }}
                    this.successMessage.actionSecondary = null;

                    this.createNewPrizes = true;
                    this.createButton.loading = false;
                    this.createButton.error = true;
                }}
            },
            clearData () {
                this.resetButtonValues();
                this.groups = []
                this.selectedStudentTypes = []
                this.addGroup();
                this.$v.$reset();
            },
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
        },          
        validations: {
            selectedStudentTypes: { required },
            groups: {
                $each:{
                    percentageFrom: {
                        required,
                        isUnique(value){
                            return this.groups.filter(e => e.percentageFrom == value).length == 1
                        },
                        minValue(value) {
                            return value > 100 && value <= 200
                        }            
                    },
                    extraPayment:{ 
                        required,
                        minValue(value) {
                            return value > 0 && value <= 100
                        }      
                    },
                }
            }
        },
    }
</script>