<template>
    <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.certificateProgram.breadcrumbs.title')"
        :description="$t('modules.portalalumno.procedures.certificateProgram.breadcrumbs.subtitle')"
        :analytics="{category: 'Tramites', label: gaLabel}"
      >
      </Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">
          <div class="d-flex align-center justify-center">
            <v-row justify="center">
              <v-col cols="10">
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        class="mb-4"
                        v-model="selectedCareer"
                        @change="getDocument"
                        :items="careers"
                        :label="$t('modules.portalalumno.procedures.certificateProgram.autocompleteCareer.label')"
                        item-text="career.degree"
                        return-object
                        hide-details
                        outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="10" sm="10">
            <v-alert icon="mdi-bell" color="#f1f1f1"  elevation=2 >
                   {{$t('modules.portalalumno.procedures.certificateProgram.alertInfo.title')}}
            </v-alert>
        </v-col>
        <v-col cols="11">
          <div class="d-flex justify-start">
                    <Button
                      :loading="createButton.loading"
                      :success="createButton.success"
                      :error="createButton.error"
                      :text="$t('modules.portalalumno.procedures.certificateProgram.button.text')"
                      :successText="$t('actions.created')"
                      :errorText="'Error'"
                    
                    ></Button>
                  </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import { $studentProcessing, $students } from '../services';
    import { mapGetters } from 'vuex'

    export default {
        name:'CertificateProgram',
        components: {
            Breadcrumbs,
            Button
        },
        data() {
            return {
                createButton: {
                  loading: false,
                  success: false,
                  error: false
                },
                fileSource: '',
                title: '',
                description: '',
                careers: [],
                selectedCareer: null,
                loading: true,
                certificateStatus: {
                'REGISTERED_STUDENT_CERTIFICATE': [54], //Alumno Regular
                'GRADUATION_CERTIFICATE': [55,145], //Egreso
                'ACADEMIC_TRANSCRIPT': [145], //Horas
                'I_AM_TECLAB': [54], //Matriculta
                'SYLLABUS_AND_WORKLOAD_CERTIFICATE': [54], //Plan de Estudio
                'FINAL_EXAM_PROOF': [55,145,54], //Concentración de Notas
                'PARTIAL_TITLE_DETAILS': [145] //Titulo Digital
                }
            }
            },
            computed: {
            gaLabel() {
                const type = this.$route.params.certificateKey
                if (type === 'GRADUATION_CERTIFICATE' || type === 'REGISTERED_STUDENT_CERTIFICATE') return this.$t('modules.portalalmuno.procedures.certificateProgram.labelsCertificates.alumnoRegular.title')
                else if (type === 'I_AM_TECLAB') return this.$t('modules.portalalmuno.procedures.certificateProgram.labelsCertificates.matricula.title')
                else if (type === 'SYLLABUS_AND_WORKLOAD_CERTIFICATE') return this.$t('modules.portalalmuno.procedures.certificateProgram.labelsCertificates.planStudio.title')
                else if (type === 'FINAL_EXAM_PROOF') return this.$t('modules.portalalmuno.procedures.certificateProgram.labelsCertificates.notas.title')
                else return this.$t('modules.portalalmuno.procedures.certificateProgram.labelsCertificates.default.title')
            },
            finalUrl() {
                return `${this.fileSource}#toolbar=0&statusbar=0&messages=0&navpanes=0`
            },
            ...mapGetters({
                studentRegister: 'commons/getStudentActualRegister',
                studentUserId: 'commons/getStudentUserID',
                studentId: 'commons/getStudentId',
            }),
            },
            methods: {
            onDownaloadClick () {
                this.$trackEvent('Tramites', 'Descargar', this.gaLabel)
                this.$refs.downloadButton.click();
            },
            async getCareers() {
                try {
                this.loading = true
                const careers = await $students.create(this.certificateStatus[this.$route.params.certificateKey], { params: {
                    titleCertificate: this.$route.params.certificateKey === 'PARTIAL_TITLE_DETAILS',
                    graduationCertificate: this.$route.params.certificateKey === 'GRADUATION_CERTIFICATE'
                    }}, `careers/${this.studentId}`)
                this.careers = careers.data.map(career => {
                    career.value = `${career.idStudentCareer}-${career.degreeId}`;
                    return career;
                });
                if (careers.data.length) {
                    this.selectedCareer = careers.data[0]  // eslint-disable-line prefer-destructuring
                    this.getDocument();
                }
                } catch (error) {
                this.selectedCareer = null
                this.careers = []
                }
            },
            async getDocument() {
                try {
                this.loading = true
                const params = { idStudentCareer: this.selectedCareer.idStudentCareer, user: this.studentUserId ? this.studentUserId : null}
                if (this.selectedCareer?.degreeId) params.degree = this.selectedCareer.degreeId
                const fetchedPDF = await $studentProcessing.find(`certified/${this.$route.params.certificateKey}`, null, { params })
                this.fileSource = fetchedPDF.data
                } catch (error) {
                this.fileSource = '';
                } finally {
                this.loading = false
                }
            }
            },
            mounted () {
            this.getCareers()
            }
    }
</script>

<style lang="scss" scoped>

</style>