<template>
  <div>
  <v-btn
    :class="[!isIcon?'px-6':'', (light || white) ? 'grey--text text--darken-2' : '', cancelActive ? 'skeleton': '']"
    @click="click()"
    rounded
    @mouseleave="hover=false"
    @mouseover="hover=true"
    :elevation="hover ? 8 : 0"
    :outlined="outlined"
    :disabled="disabled"
    :loading="loading"
    :color="cancelActive ? '' : buttonState ? buttonState : color"
    :x-small="size === 'x-sm'"
    :small="size === 'sm'"
    :large="size === 'lg'"
    :x-large="size === 'x-lg'"
    :icon="isIcon"
    :block="block"
  >
    <template v-if="cancelActive">
      <v-icon left>{{icons.cancelable}}</v-icon>
      {{ $t('actions.cancel')}}
    </template>
    <template v-else>
      <v-icon :left="!!buttonState || !!icon && !isIcon">{{  buttonState ? icons[buttonState] : icon }}</v-icon>
      {{ isIcon ? '' : buttonState ? this[texts[buttonState]] : text}}
    </template>
  </v-btn>
  </div>
</template>
<script>

export default {
  props: {
    isIcon: Boolean,
    success: Boolean,
    loading: { type: Boolean, default: false },
    error: Boolean,
    outlined: Boolean,
    secondary: Boolean,
    colorProp: String,
    white: Boolean,
    grey: Boolean,
    light: Boolean,
    depressed: { type: Boolean, default: false },
    disabled: Boolean,
    size: { type: String, default: 'lg' },
    text: String,
    icon: String,
    successText: String,
    errorText: String,
    cancelable: Boolean,
    progressText: String,
    block: { type: Boolean, default: false}
  },
  data () {
    return {
      buttonState: null,
      buttonLoading: null,
      hover: false,
      icons: {
        error: 'mdi-alert-circle',
        success: 'mdi-check',
        cancelable: 'mdi-close'
      },
      texts: {
        error: 'errorText',
        success: 'successText',
      },
      localColor: '',
      cancelActive: false,
      eventCancellable: null,
    }
  },
  methods: {
    async click () {
      if(this.cancelable){
        if(!this.cancelActive){
          this.cancelActive = true
          this.eventCancellable = setTimeout(()=>{
            this.cancelActive = false
            this.$emit('clicked');
          },5000)
        }else{
          this.cancelActive = false
          clearTimeout(this.eventCancellable)
        }
      }else{
        this.$emit('clicked');
      }
    },
  },
  watch: {
    success (val) {
      if (val) {
        setTimeout(() => {
          this.buttonLoading = false;
          this.buttonState = 'success';
          setTimeout(() => {
            this.buttonState = null;
            this.$emit('end');
          }, 1500)
        }, 1000)
      }
    },
    error (val) {
      if (val) {
        setTimeout(() => {
          this.buttonLoading = false;
          this.buttonState = 'error';
          setTimeout(() => {
            this.buttonState = null;
            this.$emit('end');
          }, 3000)
        }, 1000)
      }
    },
  },
  computed: {
    color () {
      if (this.white) {
        return 'white';
      } else if (this.grey) {
        return 'grey lighten-2';
      } else if (this.secondary) {
        return 'secondary';
      } else if (this.light) {
        return 'primary lighten-1';
      } else {
        return this.colorProp ? this.colorProp : 'primary';
      }
    },
    depress () {
      if (this.color === 'grey lighten-2' || this.color === 'white') {
        return true;
      } else {
        return this.depressed;
      }
    }
  },
  created () {
    this.buttonLoading = this.loading
  }
}
</script>

<style scoped lang="sass">
.v-btn
  transition: all 300ms ease 
  &.skeleton
    animation-duration: 1.25s
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-timing-function: linear
    animation-name: placeholderAnimate
    background: #f6f7f8 // Fallback
    background: linear-gradient(to right, #ddd 0%, #f0f0f0 10%, #ddd 20%)
    background-size: 1000px
    
@keyframes placeholderAnimate 
  0% 
    background-position: -500px 0
  100% 
    background-position: 500px 0
    
</style>
