export default {

  // module state in the core

  status: true,

  // MUTATIONS DICTIONARY

  /*<--------------------------------------------------------------
     Set the relationship between the mutation, the database field
     and the vuex field (the last two must have the same name).
    -------------------------------------------------------------->*/

  mutationsDictionary: {
    index: {
      general: {
        start: 0,
        end: 6,
      },
      email: {
        start: null,
        end: null,
      },
      sms: {
        start: null,
        end: null,
      },
      privacyPolicy: {
        start: null,
        end: null,
      },
      termsConditions: {
        start: null,
        end: null,
      },
      billingConfigs:{
        start: null,
        end: null
      }

    },
    content: [

      // General mutations [0-6]

      { type: 'Select', setter: 'SET_LANGUAGE', section: 'general', field: 'language' },
      { type: 'Select', setter: 'SET_DATEFORMAT', section: 'general', field: 'dateFormat' },
      { type: 'Select', setter: 'SET_TIMEFORMAT', section: 'general', field: 'timeFormat' },
      { type: 'Select', setter: 'SET_TIMEZONE', section: 'general', field: 'timeZone' },
      { type: 'Number', setter: 'SET_MAXLOGINATTEMPTS', section: 'general', field: 'maxLoginAttempts' },
      { type: 'Number', setter: 'SET_MAXATTEMPTSHOURS', section: 'general', field: 'maxAttemptsHours' },

      // Aparience [6-]

      // Email []

      { type: 'Text', setter: 'SET_MAILFROM', section: 'mail', field: 'smtpFrom' },
      { type: 'Text', setter: 'SET_MAILNAME', section: 'mail', field: 'smtpName' },
      { type: 'Text', setter: 'SET_MAILHOST', section: 'mail', field: 'smtpHost' },
      { type: 'Number', setter: 'SET_MAILPORT', section: 'mail', field: 'smtpPort' },
      { type: 'Text', setter: 'SET_MAILUSERNAME', section: 'mail', field: 'smtpUserName' },
      { type: 'Text', setter: 'SET_MAILPASSWORD', section: 'mail', field: 'smtpPassword' },
      { type: 'Bool', setter: 'SET_MAILAUTH', section: 'mail', field: 'smtpAuth' },
      { type: 'Bool', setter: 'SET_MAILTLS', section: 'mail', field: 'smtpTLSEnable' },
      { type: 'Bool', setter: 'SET_MAILSSL', section: 'mail', field: 'smtpSSLEnable' },

      // SMS []

      { type: 'Text', setter: 'SET_SMSTEMPLATE', section: 'sms', field: 'smsTemplate' },
      { type: 'Text', setter: 'SET_SMSCONFIGS', section: 'sms', field: 'smsConfigs' },

      // Terms Conditions []

      { type: 'Text', setter: 'SET_TERMSCONDITIONS', section: 'urls', field: 'termsConditions' },

      // Privacy []

      { type: 'Text', setter: 'SET_PRIVACYPOLICY', section: 'urls', field: 'privacyPolicy' },

      //Billing []

      { type: 'Text', setter: 'SET_BILLINGCONFIGS_USERNAME', section: 'billing', field: 'facturanteUser' },
      { type: 'Text', setter: 'SET_BILLINGCONFIGS_PASSWORD', section: 'billing', field: 'facturantePass' },
      { type: 'Text', setter: 'SET_BILLINGCONFIGS_IDCOMPANY', section: 'billing', field: 'facturanteIdEmpresa' },

    ]
  },

  // TRASH

  /*<--------------------------------------------------------------
     Save state's changes, relating old value, field an setter for
     undo action
    -------------------------------------------------------------->*/

  undo: false,
  trash: [],

  // data

  general: {

    language: {
      currentValue: null,
      options: [
        {
          value: 1,
          text: 'Español',
          locate: 'es',
        },
        {
          value: 2,
          text: 'English',
          locate: 'en',
        },
      ]
    },
    dateFormat: {
      currentValue: null,
      options: [
        {
          value: 1,
          text: 'dd/mm/yyyy',
        },
        {
          value: 2,
          text: 'yyyy/dd/mm',
        },
      ]
    },
    timeFormat: {
      currentValue: null,
      options: [
        {
          value: 1,
          text: 'AM/PM',
        },
        {
          value: 2,
          text: '24 hs',
        },
      ]
    },
    timeZone: {
      currentValue: null,
      options: [
        {
          value: 1,
          text: 'UTC-03:00 Ciudad de Buenos Aires',
        },
        {
          value: 2,
          text: 'UTC-05:00 Santiago de Chile',
        },
      ]
    },
    maxLoginAttempts: {
      currentValue: null,
    },
    maxAttemptsHours: {
      currentValue: null,
    }
  },

  mail: {
    smtpFrom: {
      currentValue: null
    },
    smtpName: {
      currentValue: null
    },
    smtpHost: {
      currentValue: null
    },
    smtpPort: {
      currentValue: null
    },
    smtpUserName: {
      currentValue: null
    },
    smtpPassword: {
      currentValue: null
    },
    smtpAuth: {
      currentValue: null
    },
    smtpTLSEnable: {
      currentValue: null
    },
    smtpSSLEnable: {
      currentValue: null
    },
  },

  sms: {
    smsTemplate: {
      currentValue: null,
    },
    smsConfigs: {
      currentValue: [],
    },
  },

  urls: {
    news: {
      currentValue: 'https://www.google.com/'
    },
    documentation: {
      currentValue: 'https://vuejs.org/'
    },
    termsConditions: {
      currentValue: null,
    },
    privacyPolicy: {
      currentValue: null,
    }
  },
  billing: {
    facturanteUser:{
      currentValue: null
    },
    facturantePass:{
      currentValue: null
    },
    facturanteIdEmpresa:{
      currentValue: null
    }
  }

}
