import Departments from '../Departments'
import DepartmentsCreate from '../Views/DepartmentsCreate';
import DepartmentsTable from '../Views/DepartmentsTable';
import DepartmentsUpdate from '../Views/DepartmentsUpdate';

const DepartmentsRoute =
    {
        path: '/departments',
        meta: {
            name: {
                en: 'Departments',
                es: 'Departamentos'
            }
        },
        component: Departments,
        children: [
            {
                path: '/',
                component: DepartmentsTable,
                meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'departments', permission: 'READ' }
            },
            {
                path: 'create',
                component: DepartmentsCreate,
                meta: { name: 'Departments Create', group: 'portaladministrativo.academic', path: 'departments', permission: 'CREATE' }
            },
            {
                path: 'update/:id',
                component: DepartmentsUpdate,
                meta: { name: 'Departments Update', group: 'portaladministrativo.academic', path: 'departments', permission: 'READ' }
            },

        ]
    };

export default DepartmentsRoute
