<template>
  <v-container fluid class="superTable">
    <v-row no-gutters></v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="items"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :single-select="true"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:[`item.status`]="{item}">
            <v-switch class="mt-0" color="primary" v-model="item.status" @change="changeStudyPlanState"></v-switch>
          </template>

          <template v-slot:[`item.studyPlan.duplicateCarousel`]="{item}">
            <v-icon :color="(item.studyPlan.duplicateCarousel) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.action`]="{item}" v-if="permissions.read || permissions.update">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.read || permissions.update" @click="editItem(item)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon>{{$t('modules.careers.table.search.edit')}}
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item v-if="permissions.delete" @click="deleteItem(item)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon>{{$t('modules.careers.table.search.delete')}}
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
          <v-pagination
          v-model="page"
          :length="pageCount"
          circle
          />
        <v-alert
          text
          type="warning"
          class="mt-4"
          v-if="items.length===0"
        >{{ $t('modules.careers.warning.no_selected_warning') }}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'StudyPlansTable',
  props: {
    items: Array,
    headers: Array,
    clearSelected: String,
    permissions: {read: false, update: false},
  },
  data() {
    return {
      selectedItems: [],
      search: '',
      page: 1,
      itemsPerPage: 10,
      itemsPerPageAvailable: [1, 10, 20, 50],
      markedUsers: []
    };
  },
  methods: {
    editItem(item) {
      const index = this.items.indexOf(item);
      this.$emit('update', item, index);
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      this.$emit('delete', index);
    },
    changeStudyPlanState(){
      this.$emit('changeStudyPlanState');
    },
    changeValue(item) {
      item.status = !item.status;
      return item;
    }
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val <= 1) this.page = 1;
    },
    clearSelected() {
      this.selectedItems = [];
    }
  },
  computed: {
    areItemsSelected() {
      return !!this.selectedItems.length;
    },
    pageCount() {
      let counter = this.items.length / this.itemsPerPage;

      if (counter < 1) {
        counter = 1;
      }

      return Math.ceil(counter);
    },
    itemsCount() {
      const counter = {
        totalItems: this.items.length,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.items.length) {
        counter.endItem = this.items.length;
      }

      return counter;
    }
  }
};
</script>
