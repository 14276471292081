import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('report');

export class CheckStatus extends Api {
   constructor(http, config = {}) {
    super(http, url, 'check-status', config);
   }
}
