export default {

  // TRASH

  SAVE_IN_TRASH (state, value) {
    state.trash.push(value)
  },
  CLEAN_TRASH (state) {
    state.trash = []
  },

  // Settings general mutations

  SET_LANGUAGE (state, value) {
    state.general.language.currentValue = value
  },
  SET_DATEFORMAT (state, value) {
    state.general.dateFormat.currentValue = value
  },
  SET_TIMEFORMAT (state, value) {
    state.general.timeFormat.currentValue = value
  },
  SET_TIMEZONE (state, value) {
    state.general.timeZone.currentValue = value
  },
  SET_MAXLOGINATTEMPTS (state, value) {
    state.general.maxLoginAttempts.currentValue = value
  },
  SET_MAXATTEMPTSHOURS (state, value) {
    state.general.maxAttemptsHours.currentValue = value
  },


  // Settings Email

  SET_MAILFROM (state, value) {
    state.mail.smtpFrom.currentValue = value
  },
  SET_MAILNAME (state, value) {
    state.mail.smtpName.currentValue = value
  },
  SET_MAILHOST (state, value) {
    state.mail.smtpHost.currentValue = value
  },
  SET_MAILPORT (state, value) {
    state.mail.smtpPort.currentValue = value
  },
  SET_MAILUSERNAME (state, value) {
    state.mail.smtpUserName.currentValue = value
  },
  SET_MAILPASSWORD (state, value) {
    state.mail.smtpPassword.currentValue = value
  },
  SET_MAILAUTH (state, value) {
    state.mail.smtpAuth.currentValue = value
  },
  SET_MAILTLS (state, value) {
    state.mail.smtpTLSEnable.currentValue = value
  },
  SET_MAILSSL (state, value) {
    state.mail.smtpSSLEnable.currentValue = value
  },

  // Settings SMS

  SET_SMSTEMPLATE (state, value) {
    state.sms.smsTemplate.currentValue = value
  },
  SET_SMSCONFIGS (state, value) {
    state.sms.smsConfigs.currentValue = value
  },
  SET_SMSCONFIGFIELD (state, { id, field, value }) {

    // modify individual fields of sms config

    state.sms.smsConfigs.currentValue.filter((config) => {
      return config.id == id;
    })[0][field] = value;
  },

  // Settings TermsConditions

  SET_TERMSCONDITIONS (state, value) {
    state.urls.termsConditions.currentValue = value;
  },

  // Settings Privacy

  SET_PRIVACYPOLICY (state, value) {
    state.urls.privacyPolicy.currentValue = value
  },

  // Billing

  SET_BILLINGCONFIGS_USERNAME (state, value) {

    state.billing.facturanteUser.currentValue = value
  },
  SET_BILLINGCONFIGS_PASSWORD (state, value) {
    state.billing.facturantePass.currentValue = value
  },
  SET_BILLINGCONFIGS_IDCOMPANY (state, value) {
    state.billing.facturanteIdEmpresa.currentValue = value
  },
  

}
