<template>
  <div :class="['payment-item px-4', selected ? 'item-active' : '']" @click="$emit('selectMe')">
    <v-row>
      <v-col :class="['d-flex align-center py-2', !autoHeight ? 'description': '']" cols="12" sm="9">
        <v-checkbox
          :value="selected"
          color="primary"
          class="px-6"
        ></v-checkbox>
        <div class="payment-item__content">
          <h5 class="body-1 font-weight-bold ma-0">{{ payment.title }}</h5>
          <h6 class="caption grey--text ma-0" v-if="payment.description">{{ payment.description }}</h6>
        </div>
      </v-col>
      <v-col cols="12" sm="3" class="d-flex justify-end align-center py-2">
        <div class="payment-item__card__price primary--text mr-6">{{ payment.amount | $currency }}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    payment: Object,
    amount: Number,
    title: String,
    description: String,
    selected: Boolean,
    autoHeight: Boolean,
  },
};
</script>

<style lang="sass" scoped>
.payment-item
  background: white
  border: 1px solid #d9d9d9
  border-radius: 5px
  transition: all 300ms ease
  &:hover
    cursor: pointer
    border-color: #6087e1
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
    .v-input--checkbox
      opacity: 1
  &.item-active
    border-color: #6087e1
    background-color: #d9e2fa
    box-shadow: 0 2px 4px 0 rgba(0, 44, 142, 0.15)
    .payment-item__card
      &.card-first
        background-color: white
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 0.5rem 0
  &__card
    text-align: right
    display: flex
    flex-direction: column
    border-radius: 5px
    padding: 0.5rem 0.75rem
    margin: 0 0.25rem
    &__price
      font-size: 18px
      line-height: 1.2
      font-weight: bold
  .v-input--checkbox
    opacity: 0.5
    transition: opacity 300ms ease
    &.v-input--is-label-active
      opacity: 1
  
  .description
    min-height: 100px

</style>
