<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!updateCommissionStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.objectives.update.breadcrumbs.title')"
                :description="$t('modules.commissions.objectives.update.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.objectives.update.sectionData.title')"
                        :subtitle="$t('modules.commissions.objectives.update.sectionData.subtitle')"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.objectives.update.sectionData.labels.studentType') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ objectiveData.studentTypeDTO ? objectiveData.studentTypeDTO.meaning : '-' }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.objectives.update.sectionData.labels.careers') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ objectiveData.career ? `${objectiveData.career.code} - ${objectiveData.career.description}` : '-'}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.objectives.update.sectionData.labels.range') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ objectiveData.range ? objectiveData.range.description : '-'}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.objectives.update.sectionData.labels.seller') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ objectiveData.seller ? objectiveData.seller.sellerName : '-'}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.objectives.update.sectionData.labels.objective') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-text-field
                                    v-model="$v.objectiveData.objectiveAmount.$model"
                                    :error-messages="$v.objectiveData.objectiveAmount.$error && $v.objectiveData.objectiveAmount.$dirty ? 
                                                    `${$t('modules.commissions.objectives.create.validations.objective')} *` : ''"
                                    :label="`${$t('modules.commissions.objectives.update.sectionData.labels.objective')} *`"
                                    @keypress="$validateDecimalInputNumber($event, $v.objectiveData.objectiveAmount.$model, null, 4, 1)"
                                    @blur="$v.objectiveData.objectiveAmount.$touch()"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/objectives')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="saveButton.loading"
                            :success="saveButton.success"
                            :error="saveButton.error"
                            :text="$t('actions.save')"
                            :successText="$t('actions.saved')"
                            :errorText="'Error'"
                            :disabled="$v.$invalid || !canUpdate"
                            @end="resetButtonValues"
                            @clicked="updateCommission"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import { required} from 'vuelidate/lib/validators';
    import { $rangeObjective } from '../Services';

    export default {
        name: 'ObjectiveUpdate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
        },
        data () {
            return {
                objectiveData: {
                    objective: null
                },
                updateCommissionStatus: null,
                date: '',
                errorMesagesSelected: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                item: {
                    menu: false,
                },
                successMessage: {
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.objectives.UPDATE
            },
        },
        async mounted () {
            try {
                const resp = await $rangeObjective.findById(this.$route.params.id);
                this.objectiveData = resp.data;
            } catch (error) {
                this.objectiveData = {objective: null}
                throw error
            }
        },
        methods: {
            resetButtonValues () {
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async updateCommission () {
                if(!this.canUpdate) return false;
                try {
                    this.saveButton.loading = true;
                    await $rangeObjective.update(this.$route.params.id, this.objectiveData)
                    this.successMessage.type = 'success',
                    this.successMessage.title = this.$t('modules.commissions.objectives.update.success_message.title'),
                    this.successMessage.actionPrimary = { text: this.$t('modules.commissions.objectives.update.success_message.actions.primary_text'), callback: () => { this.$router.push('/commissions/objectives') } }
                    this.saveButton.success = true;
                    this.updateCommissionStatus = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = this.$t('modules.commissions.objectives.update.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('modules.commissions.objectives.update.error_message.actions.back'), callback: () => { this.resetButtonValues(); this.updateCommissionStatus = false } }
                    this.saveButton.loading = false;
                    this.saveButton.error = true;
                    this.updateCommissionStatus = true;
                    throw error;
                }
                finally {
                    this.saveButton.loading = false;
                }
            },
        },
        validations: {
            objectiveData: {
                objectiveAmount: {
                    required
                }
            }
        },
    }
</script>