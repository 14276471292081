let vue = null;
export function setVueInstance(vue_instance) {
    vue = vue_instance;
}

export function getVueInstance() {
    return vue;
}

export default [
    setVueInstance,
    getVueInstance
];