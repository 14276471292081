import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('academic');

export class Documentations extends Api {
  constructor(http, config = {}) {
    super(http, url, 'documentations', config);
  }
}
