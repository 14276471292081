// import services


export default {

  // Commons actions

  initData ({ commit, state }) {
    const value = [
      {
        code: 463221,
        name: 'Fase 1',
        productType: 'Matricula',
        state: false,
        startDate: new Date().toLocaleDateString(),
        discount: '20 %'
      },
      {
        code: 359674,
        name: 'Fase 3',
        productType: 'Matricula',
        state: false,
        startDate: new Date().toLocaleDateString(),
        discount: '20 %'
      },
      {
        code: 506387,
        name: 'Fase 4',
        productType: 'Promo reinscripción',
        state: true,
        startDate: new Date().toLocaleDateString(),
        discount: '20 %'
      },
      {
        code: 564983,
        name: 'Fase 2',
        productType: 'Derecho de examen',
        state: true,
        startDate: new Date().toLocaleDateString(),
        discount: '$1.250'
      },
    ];
    state.mutationsDictionary.content.forEach(mutation => {
      if (!value) {
        throw new Error(`Error: value got null`)
        // return;
      }
      if (mutation.type === 'Array' && Array.isArray(value)) {
        commit(mutation.setter, value);
        return;
      }
      if (mutation.type === typeof value) {
        commit(mutation.setter, value);
        return;
      }
      throw new Error(`
                ${mutation.setter} couldn't be performed: missmatched typeof value.
                    - Expected ${mutation.type}
                    - Got ${!Array.isArray(value) ? typeof value : 'Array'}
            `)
    })
    // commit(mutation.setter, dataObject)
    // settingsService.getAllData()
    //     .then((response)=>{
    //
    //         var value
    //
    //         state.mutationsDictionary.content.forEach(mutation => {
    //
    //             if(mutation.type === "Select"){
    //
    //                 // Transfer string option value to int option value for save in Vuex
    //
    //                 value = state[mutation.section][mutation.field]['options'].filter(option => {
    //                     return option.text === response[mutation.field]
    //                 })[0].value;
    //
    //             }else{
    //                 value = response[mutation.field]
    //             }
    //             commit(mutation.setter, value)
    //         })
    //
    //         return response
    //     })
    //     .catch((err)=>{
    //         return err
    //     })

  },

  // changeValue ({commit,state, getters}, change) {

  // state.mutationsDictionary.content.forEach(mutation => {
  //     if(mutation.field === change.field){
  //
  //         // Record the change in trash
  //
  //         var firstChange = true;
  //
  //         // Search change in trash
  //         for (let element of state.trash) {
  //             if (element.setter == mutation.setter) {
  //                 firstChange = false
  //                 break
  //             }
  //         }
  //
  //         // commit trash mutation
  //         if(firstChange){
  //             const oldValue = getters.getAllCurrentValues[mutation.field]
  //             commit('SAVE_IN_TRASH', { value: oldValue , section: mutation.section, field: mutation.field , setter: mutation.setter})
  //         }
  //     }
  // })
  // },

  undo ({ commit, state }) {
    state.trash.forEach((mutation) => {
      commit(mutation.setter, mutation.value);
    });
    commit('CLEAN_TRASH');
  },

  cleanTrash ({ commit }) {
    commit('CLEAN_TRASH')
  },

  // modify individual fields of sms config

  // disabledAllSMSSettings ({commit,state}){
  // state.sms.smsConfigs.currentValue.forEach((config) => {
  //     commit('SET_SMSCONFIGFIELD',{
  //         id: config.id,
  //         field: 'status',
  //         value: false,
  //     })
  // })
  // },
  //
  // updateIndividualSMSConfig ({commit}, change) {
  //
  //     // commit('SET_SMSCONFIGFIELD',change)
  //
  // },
  //
  // updateState ({commit,state}, changes) {
  //
  //     // state.trash.forEach((mutation) => {
  //     //     commit(mutation.setter, changes[mutation.field])
  //     // })
  //
  // },
  //
  // updateAPI ({getters,state}) {
  //
  // if (getters.getChanges.length > 0 ) {
  //
  //     var changes = {}
  //
  //     getters.getChanges.forEach((change) => {
  //
  //         if (state[change.section][change.field]['options']) {
  //
  //             // Transfer int option value to string option value for save in DB
  //
  //             changes[change.field] = state[change.section][change.field]['options'].filter(option => {
  //                 return option.value === getters.getAllCurrentValues[change.field]
  //             })[0].text
  //
  //         } else {
  //
  //             changes[change.field] = getters.getAllCurrentValues[change.field]
  //
  //         }
  //
  //     })
  //
  //     // API Service
  //
  //     settingsService.changeData(1,changes)
  //         .then((res) => {
  //             return res
  //
  //         })
  //         .catch((err) => {
  //             return err
  //         })
  // } else {
  //     throw new Error("No updates!");
  // }
  //
  // }

}
