<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewEquivalencesStatus">
      <Breadcrumbs
        :title="$t('modules.academic.equivalences.create.breadcrumbs.title')"
        :description="$t('modules.academic.equivalences.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.academic.equivalences.create.codeSection.title')"
            :subtitle="$t('modules.academic.equivalences.create.codeSection.subtitle')"
            class="mb-6">
          <br>
            <OutlinedCardContent
                :label="$t('modules.academic.equivalences.create.labels.OldSubjects')"
              ><v-row>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :label="`${$t('modules.academic.equivalences.create.labels.department')} *`"
                      :items="departmentsOld"
                      item-text="name"
                      @change="filterCleanSubjectsOld"
                      outlined
                      return-object
                      class="no-message"
                      v-model="selectedDepartmentOld"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-autocomplete
                      :error-messages="($v.selectedSubjectOld.$dirty && $v.selectedSubjectOld.$invalid) ? $t('modules.academic.equivalences.validation_errors.subject') : ''"
                       :label="`${$t('modules.academic.equivalences.create.labels.subject')} *`"
                      :items="subjectsOld"
                      item-text="description"
                      item-value="id"
                      outlined
                      return-object
                      @blur="$v.selectedSubjectOld.$touch()"
                      v-model="$v.selectedSubjectOld.$model"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
            </OutlinedCardContent>
            <OutlinedCardContent
                :label="$t('modules.academic.equivalences.create.labels.NewSubjects')"
              ><v-row>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :label="`${$t('modules.academic.equivalences.create.labels.department')} *`"
                      :items="departmentsNew"
                      item-text="name"
                      @change="filterCleanSubjectsNew"
                      outlined
                      return-object
                      class="no-message"
                      v-model="selectedDepartmentNew"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-autocomplete
                      :error-messages="
                      ($v.selectedSubjectNew.$dirty && $v.selectedSubjectNew.$invalid) ?
                      (!$v.selectedSubjectNew.sameAsSelectedSubjectOld && $v.selectedSubjectNew.$model) ? 
                      $t('modules.academic.equivalences.validation_errors.differentSubjects') : $t('modules.academic.equivalences.validation_errors.subject') : ''"
                      :label="`${$t('modules.academic.equivalences.create.labels.subject')} *`"
                      :items="subjectsNew"
                      item-text="description"
                      item-value="id"
                      outlined
                      return-object
                      @blur="$v.selectedSubjectNew.$touch()"
                      v-model="$v.selectedSubjectNew.$model"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                </OutlinedCardContent>
          </OutlinedCard> 
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/academic/equivalences')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="$t('actions.error')"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="createEquivalences"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required } from 'vuelidate/lib/validators';
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
import {  $departments,  $subjects, $equivalences } from '../Services';

export default {
  name: 'EquivalencesCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    Button,
    SuccessMessage,
  },
  data() {
    return {
      departmentsOld: [],
      departmentsNew: [],
      selectedDepartmentOld: [],
      selectedDepartmentNew: [],
      subjectsOld: [],
      subjectsNew: [],
      selectedSubjectOld: '',
      selectedSubjectNew: '',
      createNewEquivalencesStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.equivalences.CREATE;
    },
  },
  mounted() {
    this.fetchDepartament();
  },
  methods: {
    subjects() {
      
    },
    onCancelDatePickerClick (model) {
        this[model] = false
      },
      onOkDatePickerClick (ref, date) {
        this.$refs[ref].save(date)
      },
    async createEquivalences() {
      this.createButton.loading = true;
      try {
        if (this.$v.$invalid && !this.$v.selectedSubjectNew.sameAsSelectedSubjectOld) {
          
          this.$v.$touch();
          
          this.createNewEquivalencesStatus = false;
          this.createButton.error = true;
          this.createButton.loading = false;
        } else {
          const newEquivalences = {
            
            oldSubjectId: {id: this.selectedSubjectOld.id},
            newSubjectId: {id: this.selectedSubjectNew.id},

          };
          await $equivalences.create(newEquivalences);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.academic.equivalences.create.success_message.title');
          this.successMessage.actionPrimary = {
            text: this.$t('actions.close'), callback: () => {
              this.$router.push('/academic/equivalences');
            }
          };
          this.successMessage.actionSecondary = {
            text: this.$t('actions.create_another'), callback: () => {
              this.createNewEquivalencesStatus = false;
            }
          };
          this.createNewEquivalencesStatus = true;
          this.createButton.success = true;
          this.createButton.loading = false;
          this.clearData();
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.equivalences.create.error_message.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewEquivalencesStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewEquivalencesStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        throw error;
      }
    },
    clearData() {
      this.resetButtonValues();
      this.$v.$reset();
      this.selectedDepartmentOld = [];
      this.selectedDepartmentNew = [];
      this.selectedSubjectOld = '';
      this.selectedSubjectNew = '';
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
    filterCleanSubjectsOld(departmentOld) {
      this.filterSubjectOld(departmentOld)
      this.selectedSubjectOld = '';

    },
    filterCleanSubjectsNew(departmentNew) {
      this.filterSubjectNew(departmentNew)
      this.selectedSubjectNew = '';
    },
    async filterSubjectOld (departmentOld) {
      const subjectsOld = await $subjects.filterByDepartment(departmentOld.id);
      this.subjectsOld= subjectsOld.data.map( item => { 
          item.description = `${item.code} - ${item.description}`
          return item
      });
    },
    async filterSubjectNew (departmentNew) {
      const subjectsNew = await $subjects.filterByDepartment(departmentNew.id);
      this.subjectsNew = subjectsNew.data.map( item => { 
          item.description = `${item.code} - ${item.description}`
          return item
      });
    },
    async fetchDepartament () {
      const departments = await $departments.find();
      this.departmentsOld = JSON.parse(JSON.stringify(departments.data.content));
      this.departmentsNew = JSON.parse(JSON.stringify(departments.data.content));
    },
  },
  validations: {
   selectedSubjectOld: { required, 
   },
   selectedSubjectNew: { 
      required,                       
      sameAsSelectedSubjectOld(value){
        return this.selectedSubjectOld.id != value.id
      }
    }
   }
};
</script>