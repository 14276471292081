<template>
    <v-col cols="12">
        <v-card
            class="mx-auto"
            outlined>   
                <v-container class="mx-2">
                    <v-row class="mr-2">
                        <v-col cols="6">
                            <v-text-field
                                label="Nombre y Apellido"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-select
                                label="Tipo documento"
                                
                                outlined
                                
                                class="no-message"
                            ></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Número de documento"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mr-2">
                        <v-col cols="8">
                            <v-text-field
                                label="Ciudad"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mr-2">
                        <v-col cols="5">
                            <v-text-field
                                label="Calle"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field
                                label="Número"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Piso"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                label="Depto"
                                
                                type="text"
                                outlined
                                
                                class="no-message"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
        </v-card>
    </v-col>
</template>

<script>
    export default {
        name: 'BillingData',
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>

    
</style>