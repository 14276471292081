<template>
  <div fluid class="pl-3 pr-3 mt-10" v-if="data.length" >
      <v-row class='mb-3'>
        <v-col cols='4'>{{$t('modules.portalalumno.payments.checkingAccount.credits.table.date')}}</v-col>
        <v-col cols='5'>{{$t('modules.portalalumno.payments.checkingAccount.credits.table.detail')}}</v-col>
        <v-col cols='2'>{{$t('modules.portalalumno.payments.checkingAccount.credits.table.points')}}</v-col>
      </v-row>
    <v-row v-for="row in data" :key="row.description"  class="payment-single mb-2">
      <v-col cols="4" class="details">
        <h3>{{row.date}}</h3>
      </v-col>

      <v-col cols="5" class="description justify-start">
        <p class="mb-0">{{row.description}}</p>
      </v-col>
      <v-col cols="2" class="amount">
        <p class="mb-0">{{row.amount}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CreditTeclabTable',
  props: {
    data: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      loading: false,
    };
  }
}
</script>

<style scoped lang="sass">
.payment-single
    font-family: 'Roboto'
    font-size: 12px
    font-weight: bold
    height: 50px
    cursor: pointer
    border-radius: 5px
    box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
    background-color: #f8f8f8
    align-items: center
    padding: 0 0 51px 10px
    margin-bottom: 20px
.details
  display: flex
  flex-direction: row
.description
  display: flex
  align-content: center
  color: rgba(0, 0, 0, 0.5)
.amount
  color: #687268
.amount-red
  color: #fc0000
  display: flex
  flex-direction: row
  justify-content: flex-end

</style>
