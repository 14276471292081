import StatusConnectionIndex from '../Views/StatusConnectionIndex'
import StatusConnection from '../StatusConnection'

const StatusConnectionRoute =
    {
        path: '/status-connection',
        meta: {
            name: {
                en: 'Status of services',
                es: 'Estado de los servicios'
            }
        },
        component: StatusConnection,
        children: [
                {
                    path: '',
                    component: StatusConnectionIndex,
                    meta: { name: 'Index'}
                }
            ]
        };
    export default StatusConnectionRoute
        


