import { render, staticRenderFns } from "./ReportsInfo.vue?vue&type=template&id=1a8566f8&scoped=true&"
import script from "./ReportsInfo.vue?vue&type=script&lang=js&"
export * from "./ReportsInfo.vue?vue&type=script&lang=js&"
import style0 from "./ReportsInfo.vue?vue&type=style&index=0&id=1a8566f8&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a8566f8",
  null
  
)

export default component.exports