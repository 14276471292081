<template>
    <v-container fluid class="pa-0 fullHeight">
        <v-container fluid class="mt-0 scrollableContent">
            <div v-if="!overlay">
                <v-card outlined class="px-3">
                    <v-row align="center">
                        <v-col cols="2" class="py-1">
                            <img height="30" :src="logoPath" />
                        </v-col>
                        <v-col cols="10" align="end" class="py-1">
                            <strong class="mr-2">{{ $t('modules.exams.review.subject') }}:</strong>
                            <span class="mr-10">{{ subject }}</span>
                            <strong class="mr-2 ml-10">{{ $t('modules.exams.review.student') }}:</strong>
                            <span class="mr-5">{{ student.fullName }}</span>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row>
                    <v-col cols="2">
                        <h2>{{ $t('modules.exams.review.brief.title') }}</h2>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.status') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 :class="exam.status ? 'success--text' : 'error--text'">
                                        {{exam.status ? $t('modules.exams.review.brief.approved') : $t('modules.exams.review.brief.failed') }}
                                    </h1>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.calification') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 class="primary--text">{{ exam.calification }}</h1>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.totalQuestions') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 class="primary--text">{{ exam.totalQuestions }}</h1>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.totalAnswers') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 class="primary--text">{{ exam.totalAnswered }}</h1>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.correctAnswers') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 class="primary--text">{{ exam.correctNumber }}</h1>
                                </v-col>
                            </v-row>
                        </v-card>

                        <v-card outlined class="ExamRevision-card-rounded">
                            <v-row align="center" justify="center" class="py-4">
                                <v-col cols="12" align="center" class="py-0">
                                    <span class="primary--text">{{ $t('modules.exams.review.brief.incorrectAnswers') }}</span>
                                </v-col>
                                <v-col cols="12" align="center" class="py-0">
                                    <h1 class="primary--text">{{ exam.incorrectNumber }}</h1>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="10">
                        <h2>{{ $t('modules.exams.review.answers.title') }}</h2>
                        <br />
                        <v-expansion-panels accordion>
                            <v-expansion-panel v-for="(item, i) in exam.answers" :key="i" class="ExamRevision-expansion-panel">
                                <v-expansion-panel-header class="py-2">
                                    <span class="d-flex flex-row align-center">
                                        <v-icon large :color="item.isCorrect ? 'success' : 'error'" class="ml-2 mr-5 d-block">
                                            {{ item.isCorrect ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                        </v-icon>
                                        {{ i + 1 }}. {{ item.description }}
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="ExamRevision-expansion-panel-content">
                                    <v-row>
                                        <v-col cols="6">
                                            <span class="status-dot" :class="item.isCorrect ? 'success' : 'error'"></span>
                                            <strong class="mr-2">{{ $t('modules.exams.review.answers.chosenAnswer') }}:</strong>
                                            <span>{{ item.selectedAnswer }}</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="status-dot success"></span>
                                            <strong class="mr-2">{{ $t('modules.exams.review.answers.correctAnswer') }}:</strong>
                                            <span>{{ item.correctAnswer }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <h3 class="mb-2">{{ $t('modules.exams.review.answers.justification') }}</h3>
                                            <span class="grey--text">{{ item.justification }}</span>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </div>
            <v-overlay :value="overlay">
                <h2 class="loading-message">{{ $t('modules.exams.assestment.loading.message') }}</h2>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <AlertPopup
                :active="alert.active"
                :title="alert.title"
                :content="alert.content"
                :actionPrimary="alert.actionPrimary"
                :actionSecondary="alert.actionSecondary"
                :icon="alert.icon"
                :color="alert.color"
            ></AlertPopup>
        </v-container>
    </v-container>
</template>

<script>
    import AlertPopup from '../Components/AlertPopup'
    import Constants from '@/plugins/constants'
    import { mapGetters } from 'vuex';

    export default {
        name: 'ExamReview',
        props: {
            student: Object,
            subject: String,
        },
        components: {
            AlertPopup
        },
        data () {
            return {
                overlay: true,
                excelFile: null,
                exam: {
                    student: null,
                },
                fields: {
                    'Nro. pregunta': 'questionNumber',
                    'Justificacion del sistema': 'system',
                    'Argumento alumno': 'studentDischarge',
                    'Correcta': 'correctAnswer',
                    'Hay error': 'hasError',
                    'Justificacion profesor': 'teacherJustification'
                },
                json_data: [],
                alert: {
                    active: false,
                    title: null,
                    content: null,
                    actionPrimary: null,
                    actionSecondary: null,
                    icon: { color: 'warning', name: 'mdi-eye-off-outline' },
                    color: 'primary',
                }
            }
        },
        computed: {
            ...mapGetters({
                logoPath: 'commons/getLogoImageUrl',
            }),
        },
        async mounted () {
            try {
                const response = await fetch(`${Constants.URL_BASE}/q-system/exams/${this.$route.params.examId}/justify`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
                        'Content-Type': 'application/json'
                    },
                })

                this.exam = await response.json()
            } catch (error) {
                this.alert.title = error.codeMeaning
                this.alert.content = ''
                this.alert.actionPrimary = null
                this.alert.actionSecondary = {text: this.$t('modules.exams.assestment.popUp.errorFinishingExam.buttonSecondary'), callback() {window.close()} }
                this.icon = { color: 'warning', name: 'mdi-eye-off-outline' }
                this.alert.active = true
            } finally {
                this.overlay = false;
            }
        }
    }
</script>

<style lang="sass" scoped>
    .scrollableContent
        height: 100vh

    .loading-message
        position: relative
        left: -4rem

    .ExamRevision-card-rounded
        border-radius: 8px !important
        margin-top: 16px
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08)

    .ExamRevision-expansion-panel
        border-radius: 8px !important
        box-shadow: none
        border: 1px solid rgba(0, 0, 0, 0.12)

        &::after
            opacity: 0 !important

    .ExamRevision-expansion-panel-content
        border-top: 1px solid rgba(0, 0, 0, 0.12)
        padding-top: 10px
        padding-left: 90px
        padding-right: 90px

        .status-dot
            display: inline-block
            margin-right: 10px
            width: 12px
            height: 12px
            border-radius: 50%

        .ExamRevision-checkbox label.v-label
            color: black
</style>
