import Admission from '../Admission';
import AdmissionStatus from '../Views/AdmissionStatus'
import DocumentationUpload from '../Views/DocumentationUpload';
import ContractSign from '../Views/ContractSign';
import Ecert from '../Views/Ecert';

const AdmissionRoutes =
{
  path: '/admission',
  meta: {
    name: {
      en: 'Admission',
      es: 'Admisión'
    }
  },
  component: Admission,
  children: [
    {
      path: '/',
      component: AdmissionStatus,
      name: 'AdmissionStatus',
      meta: {
        name: 'Index'
      },
      props: true
    },
    {
      path: 'documentation',
      component: DocumentationUpload,
      name:'DocumentationUpload',
      meta: {
        name: 'DocumentationUpload'
      },
      props: true
    },
    {
      path: 'contract',
      component: ContractSign,
      name:'ContractSign',
      meta: {
        name: 'ContractSign'
      }
    },
    {
      path: 'ecert',
      component: Ecert,
      name:'Ecert',
      meta: {
        name: 'Ecert'
      }
    },
  ]
}

export default AdmissionRoutes