<template>
  <v-container fluid class="d-flex flex-row pa-0 fullHeight">
    <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="asideChanged"></AsideBar>
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.procedures.initTable.breadcrumbs.title')"
          :description="$t('modules.procedures.initTable.breadcrumbs.description')"
        ></Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12">
            <SuperTableInit
              :headers="headers"
              :items="procedures"
              :totalItems="asidebar.totalItems"
              :query="asidebar.search"
              @click-row="openProcedure"
              @on-search="searchAction"
              @on-page-change="(val) => pageChange(val - 1)"
              @on-items-length-change="itemsLengthChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import AsideBar from '@/components/AsideBar/AsideBar'
import SuperTableInit from '../Components/SuperTable/SuperTableInit'
import { $procedures, $types, $processings } from '../Services';

export default {
  name: 'ProceduresInitTable',
  components: {
    Breadcrumbs,
    AsideBar,
    SuperTableInit
  },
  data() {return {
    clearSelected: '',
    asidebar: {
        title: 'Refinar búsqueda',
        totalItems: 0,
        page:0,
        length:20,
        search: null,
        items: [
          {
            name: this.$t('modules.procedures.initTable.headers.category'),
            filters: [{
              type: 'autocomplete',
              object: true,
              items: [],
              value: 0,
              dataFiltered: null
            }]
          },
          {
            name: this.$t('modules.procedures.initTable.headers.type'),
            filters: [{
              type: 'autocomplete',
              object: true,
              items: [],
              value: 0,
              dataFiltered: null
            }]
          },
          {
            name: this.$t('modules.procedures.initTable.headers.status'),
            filters: [{
              type: 'select',
              object: true,
              items: [],
              value: 91,
              dataFiltered: null
            }]
          },
        ]
      },
    headers: [
      { text: this.$t('modules.procedures.initTable.headers.number'), value: 'id' },
      { text: '', value: 'identification' },
      { text: this.$t('modules.procedures.initTable.headers.student'), value: 'name' },
      { text: this.$t('modules.procedures.initTable.headers.category'), value: 'category' },
      { text: this.$t('modules.procedures.initTable.headers.type'), value: 'description' },
      { text: this.$t('modules.procedures.initTable.headers.date'), value: 'created' },
      { text: this.$t('modules.procedures.initTable.headers.paymentDate'), value: 'paymentDate' },
      { text: this.$t('modules.procedures.initTable.headers.status'), value: 'processingStatus' }
    ],
    procedures: [],
  }},
  mounted() {
    this.headers.find(header => header.value == 'identification').text = this.$isTeclab ? this.$t('modules.procedures.initUpdate.identificationTeclab') : this.$t('modules.procedures.initUpdate.identificationIpp')
    this.fetchData()
  },
  computed: {
    canUpdate() {
      return this.$permissions.portaladministrativo.procedures.READ || this.$permissions.portaladministrativo.procedures.UPDATE
    }
  },
  methods: {

    itemsLengthChange(val){
      const searchObject = {
        category: this.asidebar.items[0].filters[0].value ? [this.asidebar.items[0].filters[0].value] : [], 
        processing: this.asidebar.items[1].filters[0].value ? [this.asidebar.items[1].filters[0].value] : [], 
        status: this.asidebar.items[2].filters[0].value ? [this.asidebar.items[2].filters[0].value] : [],
        search: this.asidebar.search
      }
      this.asidebar.length=val
      this.fetchFilteredData(searchObject,this.asidebar.page,val)
    },

    pageChange(val){
      const searchObject = {
        category: this.asidebar.items[0].filters[0].value ? [this.asidebar.items[0].filters[0].value] : [], 
        processing: this.asidebar.items[1].filters[0].value ? [this.asidebar.items[1].filters[0].value] : [], 
        status: this.asidebar.items[2].filters[0].value ? [this.asidebar.items[2].filters[0].value] : [],
        search: this.asidebar.search
      }
      this.asidebar.page=val
      this.fetchFilteredData(searchObject,val,this.asidebar.length)
    },  

    searchAction(val){
      this.asidebar.search = val;
      const searchObject = {
        category: this.asidebar.items[0].filters[0].value ? [this.asidebar.items[0].filters[0].value] : [], 
        processing: this.asidebar.items[1].filters[0].value ? [this.asidebar.items[1].filters[0].value] : [], 
        status: this.asidebar.items[2].filters[0].value ? [this.asidebar.items[2].filters[0].value] : [],
        search: val
      }
      this.fetchFilteredData(searchObject,this.asidebar.page,this.asidebar.length)
    },

    async fetchFilteredData(params, page = 0, length = 1000){
      this.clearSelected = new Date().getMilliseconds().toString();
      const filteredProcedures =  await $procedures.create(params, {params: { page, length }}, 'filter')
      if (filteredProcedures) {
        this.asidebar.totalItems = filteredProcedures.data.totalElements
        this.procedures = filteredProcedures.data.content.map(item => {
          item.created = new Date(item.created).toISOString().substr(0, 10)
          item.processingStatus = item.processingStatus.meaning
          item.category = item.processingCategory.name
          return item
        })
      }
    },

    asideChanged(){
      const searchObject = {
        category: this.asidebar.items[0].filters[0].value ? [this.asidebar.items[0].filters[0].value] : [], 
        processing: this.asidebar.items[1].filters[0].value ? [this.asidebar.items[1].filters[0].value] : [], 
        status: this.asidebar.items[2].filters[0].value ? [this.asidebar.items[2].filters[0].value] : [],
        search: this.asidebar.search
      }
      
      this.fetchFilteredData(searchObject)

    },
    async fetchData() {
      const params = {category:[],processing:[],status:[91]}
      if (this.$route.query.rut) {
        this.asidebar.search = this.$route.query.rut
        params.search = this.$route.query.rut
      }

      this.fetchFilteredData(params,0,20);
      this.fetchFilters();
    },
    openProcedure(id) {
      if (this.canUpdate) this.$router.push(`/procedures/${id}`);
    },
    async fetchFilters() {
      this.clearSelected = new Date().getMilliseconds().toString();
      const [categories, types, states] = await Promise.all([
         $processings.find('/categories', null, { params: { page: 0, length: 500 } }),
         $processings.find('processings'),
         $types.find(null, null, { params: { type: 'PROCESSING_STATUS' } }),
      ])

      if(categories){
        categories.data.forEach(category => {
          this.asidebar.items[0].filters[0].items.push({text:category.name, value: category.id})
        })
        this.asidebar.items[0].filters[0].items.sort((a, b) => (a.text > b.text) ? 1 : -1)
        this.asidebar.items[0].filters[0].items.unshift({text: 'Todos', value: 0})
      }
      if(types){
        types.data.forEach(type => {
          this.asidebar.items[1].filters[0].items.push({text: type.name, value: type.key})
        })
        this.asidebar.items[1].filters[0].items.sort((a, b) => (a.text > b.text) ? 1 : -1)
        this.asidebar.items[1].filters[0].items.unshift({text: 'Todos', value: 0})
      }
      if(states){
        states.data.forEach(state => {
          this.asidebar.items[2].filters[0].items.push({text:state.meaning, value: state.value})
        })
        this.asidebar.items[2].filters[0].items.sort((a, b) => (a.text > b.text) ? 1 : -1)
        this.asidebar.items[2].filters[0].items.unshift({text: 'Todos', value: 0})
      }

    },
  }
}
</script>