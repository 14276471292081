<template>
  <div>
    <v-card outlined :class="isMandatory ? 'ContractPreviewPeriodsDetailsCard is-mandatory' : ''">
      <v-card-text class="justify-center align-center pa-2">
        <div><strong class="ContractPreviewPeriodsDetailsCard-period-card--due">{{ due }}</strong></div>
        <div>
          <strong class="ContractPreviewPeriodsDetailsCard-period-card--old-price" style="text-decoration:line-through">{{ `${$countryConstants.currency} ${price}` }}</strong>
          <strong class="ContractPreviewPeriodsDetailsCard-period-card--price pl-2">{{ `${$countryConstants.currency} ${savingAmount}` }}</strong>
        </div>
        <div v-if="!payment.isMandatory"><span class="ContractPreviewPeriodsDetailsCard-period-card--expiration-date">{{ formattedExpirationDate }}</span></div>
        <div v-else><span class="ContractPreviewPeriodsDetailsCard-period-card--expiration-date">*Item obligatorio</span></div>
      </v-card-text>
    </v-card>
    <span v-if="isMandatory" class="ContractPreviewPeriodsDetailsCard-period-card--expiration-date ml-1">
      Obligatoria de pago
    </span>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'AccountCard',
  props: {
    due: {
      default: '',
      type: String
    },
    expirationDate: {
      default: '',
      type: String
    },
    isMandatory: {
      default: false,
      type: Boolean
    },
    period: {
      default: '',
      type: String
    },
    price: {
      default: null,
      type: Number
    },
    payment: Object
  },
  computed: {
    savingAmount() {
      return this.$isTeclab ? this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100) < 0 ? 0 : this.payment.options.amount - ((this.payment.options.off + this.payment.options.addedExtraOff) * this.payment.options.amount / 100)
          : this.payment.options.amount - ((this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)) * this.payment.options.amount / 100)
    },
    savingOff() {
      return this.$isTeclab ? (this.payment.options.off + this.payment.options.addedExtraOff) > 100 ? 100 : this.payment.options.off + this.payment.options.addedExtraOff
          : this.payment.options.off + (this.payment.options.addedExtraOff * (100 - this.payment.options.off) / 100)
    },
    formattedExpirationDate () {
      return this.expirationDate ?
           // moment().format(this.configurationOptions.dateFormat.find(config => config.value === this.configurationValues.dateFormat).text.toUpperCase()) 
          moment(this.expirationDate).format( 'DD/MM/YYYY' )
          : null
    },
  },

}
</script>

<style lang="sass" scoped>
.ContractPreviewPeriodsDetailsCard
  &.is-mandatory
    background-color: #dae0fa
  &-period-card--period
    color: #011856
    font-size: 12px
  &-period-card--due
    font-size: 12px
  &-period-card--old-price
    font-family: 'Roboto'
    color: rgba(33, 33, 33, 0.5)
    font-size: 12px
  &-period-card--price
    font-family: 'Roboto'
    font-size: 18px
    color: #106CA7
  &-period-card--expiration-date
    font-size: 11px
    margin: 0
</style>
