import PaymentIntents from '../PaymentIntents.vue';
import PaymentIntentsView from '../Views/PaymentIntentsView';

const PaymentIntentsRoute =
{
  path: '/paymentIntents',
  meta: { 
    name: {
      en: 'Payment intents',
      es: 'Precios por mesa',
    }
  },
  component: PaymentIntents,
  children: [
    {
      path: '/',
      component: PaymentIntentsView,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'paymentIntents', permission: 'READ'}
    },
  ]
};

export default PaymentIntentsRoute