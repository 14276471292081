import NotFoundState from '@/components/NotFoundState/NotFoundState'
import PricingView from './Pricing';
import SubscriptionRules from './SubscriptionRules/SubscriptionRules';
import SubscriptionRulesRoute from './SubscriptionRules/Routes';
import ReferredCompanies from './ReferredCompanies/ReferredCompanies';
import ReferredCompaniesRoute from './ReferredCompanies/Routes';
import Billing from './Billing/Billing';
import BillingRoute from './Billing/Routes';
import CreditCardInterest from './CreditCardInterest/CreditCardInterest';
import CreditCardInterestRoute from './CreditCardInterest/Routes';

const PricingRoute = {

  path: '/pricing',
  meta: {
    name: {
      en: 'Pricing Administrator',
      es: 'Administrador de Precios'
    },
  },
  component: PricingView,
  children:[
    {
      path: '/',
      component: NotFoundState,
    },{
      path:'subscription-rules',
      meta: { name: { es: 'Reglas de Suscripción', en: 'Subscription Rules' } },
      component: SubscriptionRules,
      children: SubscriptionRulesRoute
    },{
      path:'referred-companies',
      meta: { name: { es: 'Empresas Referidas', en: 'Referred Companies' } },
      component: ReferredCompanies,
      children: ReferredCompaniesRoute
    },{
      path:'billing',
      meta: { name: { es: 'Comprobantes', en: 'Billing' } },
      component: Billing,
      children: BillingRoute
    },{
      path:'referred-companies',
      meta: { name: { es: 'Empresas Referidas', en: 'Referred Companies' } },
      component: ReferredCompanies,
      children: ReferredCompaniesRoute
    },{
      path:'interest',
      meta: { name: { es: 'Interés tarjeta de crédito', en: 'Credit card interest' } },
      component: CreditCardInterest,
      children: CreditCardInterestRoute
    }
  ]
};

export default PricingRoute
