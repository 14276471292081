<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs
                :title="$t('modules.portalalumno.payments.paymentsStudents.view.history.title')"
                :description="$t('modules.portalalumno.payments.paymentsStudents.view.history.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces mb-16" cols="12">
                    <HistorySuperTable
                        :headers="headers"
                        :items="payments"
                        :sortBy.sync="sort.sortBy"
                        :sortDesc.sync="sort.sortDesc"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import HistorySuperTable from '../Components/HistorySuperTable/HistorySuperTable'
    import { $studentAccount } from '../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'PaymentsHistory',
        components: {
            Breadcrumbs,
            HistorySuperTable,
        },
        data () {
            return {
                payments: [],
                sort: {
                    sortBy: 'paymentDate',
                    sortDesc: true,
                },
                search: null,
                headers: [
                    { value: 'id', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.number'), align: 'center' },
                    { value: 'accountDescription', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.description') },
                    { value: 'paymentDate', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.date'), align: 'center' },
                    { value: 'paymentAmount', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.amount'), align: 'end', sortable: false, },
                    { value: 'paymentStatus', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.status'), align: 'center', sortable: false },
                    { value: 'urlPdf', text: this.$t('modules.portalalumno.payments.paymentsStudents.view.history.headers.bills'), align: 'center' }
                ],
            }
        },
        computed: {
            ...mapGetters({
                studentId: 'commons/getStudentId',
            })
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            async fetchData() {
                const response = await $studentAccount.find(`${this.studentId}/paid`)
                response.data.accounts.sort((a, b) => new Date(b.paymentDate) - new Date(a.paymentDate));
                this.payments = response.data.accounts
            },
            goBack () {
                if (window.history.length > 1) {
                    const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1]
                    if (getCurrentRoute.meta.name !== 'Index') this.$router.go(-1)
                    else this.$router.push('/')
                }
            },
        },
    }
</script>
