<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        :title="$t('modules.portalalumno.procedures.procedureTNE.title')"
        :description="$t('modules.portalalumno.procedures.procedureTNE.description')"
        isDescriptionFullLength
        :analytics="{category: 'Tramites', label: 'Gestión de TNE'}"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="10">
          <v-sheet class="payment">
            <v-row>
              <v-col cols="12" class="ml-7 justify-start text-left mt-2">
                <div class="primary--text">{{ $t('modules.portalalumno.procedures.procedureTNE.cardTitle') }}</div>
              </v-col>
            
              <v-col cols="12" class="d-flex justify-center">
                <div class="alert-message px-md-10 px-sm-10 py-6">
                  <v-row>
                    <v-col md="1" sm="12" class="d-flex align-center justify-center">
                      <v-icon color="orange darken-2">mdi-bell</v-icon>
                    </v-col>
                    <v-col md="11" sm="12" class="d-flex align-center justify-center py-0">
                      <div class="d-flex flex-column">
                        <p class="mb-2 ml-4">{{ $t('modules.portalalumno.procedures.procedureTNE.text') }}<strong><u><a @click="clickAction('Ext 1')" href="https://sistema.tne.cl/reposiciones/estado_tarjeta_alumno/" target="_blank">{{ $t('modules.portalalumno.procedures.procedureTNE.click') }}</a></u></strong></p>
                        <p class="mb-0 ml-4">{{ $t('modules.portalalumno.procedures.procedureTNE.text_2') }}<strong><u><a @click="clickAction('Ext 2')" href="https://oficinavirtual.tne.cl/OficinaVirtual/tramites/tramitesDisponibles.do/" target="_blank">{{ $t('modules.portalalumno.procedures.procedureTNE.aqui') }}</a></u></strong></p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

              <v-col sm=12 class="d-flex justify-center" v-for="payment in paymentItems" :key="payment.id">
                <PaymentListItem
                  class="payment-list"
                  :payment="payment"
                  @selectMe="select(payment.id, payment.amount)"
                  :selected="paymentSelected && paymentSelected === payment.id"
                ></PaymentListItem>
              </v-col>

              <v-col sm=12 class="mt-5">
                <div class="button-container mx-auto d-flex justify-end">
                  <Button
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :error="createButton.error"
                    :disabled="!canCreate"
                    :text="$t('actions.pay')"
                    :successText="$t('actions.created')"
                    :errorText="'Error'"
                    @end="resetButtonValues"
                    @clicked="create"
                  ></Button>
                </div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import { required } from 'vuelidate/lib/validators';
  import Button from '@/components/Button/Button';
  import { $tneProducts, $studentProcessing } from '../services'
  import PaymentListItem from '../Components/PaymentListItem/PaymentListItem'
  import { mapGetters } from 'vuex'

  export default {
    name: 'procedureExamCharge',
    components: {
      Breadcrumbs,
      Button,
      PaymentListItem
    },
    computed: {
      ...mapGetters({
        studentRegister: 'commons/getStudentActualRegister',
        userName: 'commons/getUserName',
        studentUserId: 'commons/getStudentUserID',
      }),
      canCreate() {
        return this.$permissions.portalalumno.procedures.TNE && this.paymentSelected
      }
    },
    data () {
      return {
        createButton: {
          loading: false,
          success: false,
          error: false
        },
        paymentItems: [],
        paymentSelected: null,
        paymentAmount: null,
      }
    },
    mounted () {
      this.getTneProducts();
    },
    methods: {
      clickAction(action) {
        this.$trackEvent('Tramites', action, 'Gestión de TNE')
      },
      select(id, amount){
        if (this.paymentSelected && this.paymentSelected === id) {
          this.paymentSelected = null;
          this.paymentAmount = null;
        } else {
          this.paymentSelected = id;
          this.paymentAmount = amount
        }
      },   
      resetButtonValues () {
        this.createButton.loading = false;
        this.createButton.success = false;
        this.createButton.error = false;
      },
      async getTneProducts(){
        try {
          const products = await $tneProducts.find();
          this.paymentItems = products.data.map((product) =>{
            return {
              id: product.id,
              title: product.name,
              description: product.description,
              amount: product.amount,
            }
          });

          this.paymentItems.sort((a, b) => (a.title > b.title) ? 1 : -1)
        } catch (error) {
          this.paymentItems = []
          throw error
        }
      },
      async create(){
        try{
          this.createButton.loading = true;
          this.$trackEvent('Tramites', 'Pagar', 'Gestión de TNE')
          await $studentProcessing.create({
            product: { id : this.paymentSelected },
            amount : this.paymentAmount,
            studentCareer : this.studentRegister,
            userName: this.userName,
            userUpdateId: this.studentUserId ? this.studentUserId : null
          }, {}, 'generate-tne');
          this.createButton.success = true;
          this.$router.push('/payments')
        } catch {
          this.createButton.error = true;
        } finally {
          this.createButton.loading = false
        }
      }
    },
    validations: {
      procedureExamCharge: {
        reason: { required },
        careers: { value: { required }, }
      }
    },
  }
</script>

<style lang="sass" scoped>
  .alert-message
    width: 90%
    min-height: 72px
    background-color: rgba(#ed8b00, 10%)
    border-radius: 10px

    p
      flex: 1
      font-size: 12px
      color: rgba(0, 0, 0, 0.75)

  .payment
    border-radius: 5px
    padding: 20px
    border: solid 1px #cecece
    height: 100%
    font-size: 14px

    &-list
      width: 90%
    
  .button-container
    width: 90%
    
</style>