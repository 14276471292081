const AutocompleteInputTranslations = {
    es_PE: {
        isRequired: 'Ingresa un distrito',
    },
    es: {
        isRequired: 'Ingresa una ciudad',
    },
    en: {

    }
};

export default AutocompleteInputTranslations
