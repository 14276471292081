<template>
  <div class="scrollableContent">
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs :description="breadcrumbs.description" :title="breadcrumbs.title">
          <Button
            v-if="canCreate"
            @clicked="newDepartment"
            :icon="breadcrumbs.button.icon"
            :text="breadcrumbs.button.name"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="departments"
              :clear-selected="clearSelected"
              :deleteTitle="$t('modules.departments.table.bulk_delete')"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="(departments)=>{bulkDeletePopUp(departments)}"
              @deleteUser="(id)=>{deleteDepartmentPopUp(id)}"
              @updateUser="(id)=>{updateDepartment(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import SuperTable from '../Components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $departments } from '../Services';

export default {
  name: 'DepartmentsTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      clearSelected: '',
      breadcrumbs: {
        title: this.$t('modules.departments.table.breadcrumbs.title'),
        description: this.$t('modules.departments.table.breadcrumbs.description'),
        button: {
          name: 'Nuevo',
          icon: 'mdi-plus',
          color: 'primary'
        }
      },
      departments: [],
      headers: [
        {
          text: this.$t('modules.departments.table.headers.code'),
          value: 'code',
        },
        {
          text: this.$t('modules.departments.table.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.departments.table.headers.subjects'),
          value: 'subjects_count',
        },
        {
          text: '',
          value: 'action'
        }
      ],
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.departments.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.departments.READ || this.$permissions.portaladministrativo.academic.departments.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.departments.DELETE
    },
  },
  mounted () {
    this.fetchData();
  },
  methods: {
    async fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();
      try {
        const departments = await $departments.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } });
        this.departments = departments.data.content.map(department => {
          if (department.subjects) department.subjects_count = department.subjects.length;
          else department.subjects_count = 0;
          return department;
        });
      } catch {
        this.departments = []
      }
    },
    newDepartment () {
      if (this.canCreate) this.$router.push('/departments/create')
    },
    updateDepartment (id) {
      if (this.canUpdate) this.$router.push(`/departments/update/${id}`)
    },
    bulkDeletePopUp (departments) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('actions.confirmation_delete'),
        content: departments.map(department => ({
          value: department.name
        })),
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(departments) },
        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      })
    },
    deleteDepartmentPopUp (id) {
      const department = this.departments.find(department => department.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('actions.confirmation_delete'),
        content: [{ value: department.name }],
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteDepartment(id) },
        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    async bulkDelete (departments) {
      const itemsForDelete = departments.map((department) => {
        return { id: department.id }
      });

      try {
        await $departments.bulkDelete(itemsForDelete)
        itemsForDelete.forEach(item => {
          this.departments = this.departments.filter(department => department.id !== item.id);
        });
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.departments.table.bulk_delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error;
      }
    },
    async deleteDepartment (id) {
      try {
        $departments.delete(id);
        this.departments = this.departments.filter(department => department.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.departments.table.delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback () { } },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary',
        });
        throw error
      }
    },
  }
}
</script>
