<template>
    <div>
        <v-container fluid class="d-flex pa-0 fullHeight">
            <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
            <v-container fluid class="scrollableContent pa-0">
                <v-container class="container-custom px-6">
                    <Breadcrumbs
                        :title="$t('modules.commissions.objectives.table.breadcrumbs.title')"
                        :description="$t('modules.commissions.objectives.table.breadcrumbs.description')"
                    >
                        <Button v-if="canCreate" @clicked="importFile" icon="mdi-upload" :text="$t('actions.import_file')" class="mr-2" depressed outlined></Button>
                        <Button v-if="canCreate" @clicked="newCommission" icon="mdi-plus" :text="$t('actions.new')" depressed></Button>
                    </Breadcrumbs>
                    <v-row justify="center">
                        <v-col sm="12" class="viewSpaces">
                            <SuperTable
                                :headers="headers"
                                :data="commissions"
                                :clear-selected="clearSelected"
                                :pageCount="asidebar.pagination.pagesAmount"
                                :page="asidebar.pagination.currentPage"
                                :permissions="{update: canUpdate, delete: canDelete}"
                                @updateUser="updateCommission"
                                @deleteUser="deleteCommissionsPopUp"
                                @bulk-delete="bulkDeletePopUp"
                                :deleteTitle="$t('modules.commissions.objectives.table.search.bulkDelete')"
                                @searchAction="searchAction"
                                :sortBy.sync="sort.sortBy"
                                :sortDesc.sync="sort.sortDesc"
                                @inputPagination="onInputPagination"
                                @lengthPaginationChange="onLengthPaginationChange"
                                :totalItems="totalItems"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-container>
        </v-container>
    </div>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable';
    import AsideBar from '@/components/AsideBar/AsideBar';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import Button from '@/components/Button/Button';
    import { $types, $weekObjetive, $careers, $rangeObjective } from '../Services';

    export default {
        name: 'ConfigTable',
        components: {
            AsideBar,
            Breadcrumbs,
            SuperTable,
            Button,
        },
        data () {
            return {
                totalItems: 0,
                clearSelected: '',
                search: null,
                commissions: [],
                rawCommission: [],
                sort: {
                    sortBy: 'period.startDate',
                    sortDesc: true,
                },
                headers: [
                    {
                        text: this.$t('modules.commissions.objectives.table.headers.studentType'),
                        value: 'studentType'
                    },
                    {
                        text: this.$t('modules.commissions.objectives.table.headers.from'),
                        value: 'period.startDate'
                    },
                     {
                        text: this.$t('modules.commissions.objectives.table.headers.to'),
                        value: 'period.endDate'
                    },
                    {
                        text: this.$t('modules.commissions.objectives.table.headers.seller'),
                        value: 'seller'
                    },
                    {
                        text: this.$t('modules.commissions.objectives.table.headers.amount'),
                        value: 'objectiveAmount'
                    },
                    {
                        text: this.$t('modules.commissions.objectives.table.headers.career'),
                        value: 'career.description'
                    },
                    {
                        text: '',
                        value: 'action'
                    }
                ],
                asidebar: {
                    title: this.$t('modules.commissions.objectives.table.filters.title'),
                    pagination: {
                        limit: 20,
                        page: 1,
                        currentPage: 1,
                        pagesAmount: 1
                    },
                    headers: [
                        {
                            text: this.$t('modules.commissions.objectives.table.headers.billingProduct'),
                            value: 'billingProduct'
                        },
                        {
                            text: this.$t('modules.commissions.config.table.headers.commission_percentage'),
                            value: 'percentageCommission'
                        },
                        {
                            text: this.$t('modules.commissions.config.table.headers.studentType'),
                            value: 'studentType'
                        },
                    ],
                    items: [
                    {
                        name: this.$t('modules.commissions.objectives.table.filters.studenType'),
                        filters: null
                    },
                    {
                        name: this.$t('modules.commissions.objectives.table.filters.career'),
                        filters: [
                                {
                                    name: this.$t('modules.commissions.objectives.table.filters.career'),
                                    type: 'autocomplete',
                                    value: null,
                                    multiple: true,
                                    clearable: true,
                                    items: []
                                }
                            ]
                    },
                    {
                        name: this.$t('modules.commissions.objectives.table.filters.date'),
                        filters: [
                            {
                                name: this.$t('modules.commissions.config.table.filters.from'),
                                type: 'date',
                                value: null,
                                isCalendarOpen: false,
                            },
                            {
                                name: this.$t('modules.commissions.config.table.filters.to'),
                                type: 'date',
                                value: null,
                                isCalendarOpen: false,
                            },
                        ]
                    }
                    ]
                },
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.objectives.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.objectives.READ || this.$permissions.portaladministrativo.commissions.objectives.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.commissions.objectives.DELETE
            },
        },
        async mounted () {
            await this.getStudentType();
            await this.getCareer();

            this.fetchData();
        },
        methods: {
            deleteCommissionsPopUp(id) {
                const commissions = this.commissions.find(commissions => commissions.id == id);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.commissions.objectives.table.message.delete'),
                    content: [{ value: commissions.name }],
                    actionPrimary: { text: 'Aceptar', callback: () => this.deleteCommissions(id) },
                    actionSecondary: { text: 'Cancelar', callback() {} },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            bulkDeletePopUp(commissions) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('modules.commissions.objectives.table.message.delete'),
                    content: commissions.map(commission => ({ value: commission.name })),
                    actionPrimary: {
                        text: this.$t('modules.commissions.objectives.table.message.deleteAction'),
                        callback: () => {
                            try {
                                this.bulkDelete(commissions);
                            } catch {
                                this.$store.dispatch('commons/openPopUp', {
                                    title: this.$t('modules.commissions.objectives.table.error_message.bulk_delete_message'),
                                    content: commissions.map(commission => ({ value: commission.name })),
                                    actionPrimary: { text: 'Aceptar', callback() {} },
                                    icon: { color: 'error', name: 'mdi-close' },
                                    color: 'primary'
                                });
                            }
                        }
                    },
                    actionSecondary: { text: this.$t('modules.commissions.objectives.table.message.cancelAction'), callback() {} },
                    icon: { color: 'error', name: 'mdi-delete' },
                    color: 'primary'
                });
            },
            async bulkDelete(commissions) {
                const itemsForDelete = commissions.map(commission => {
                    return { id: commission.id };
                });

                try {
                    await $rangeObjective.bulkDelete(itemsForDelete);
                } finally {
                    this.fetchData();
                }
            },
            async deleteCommissions(id) {
                try {
                    await $rangeObjective.delete(id);
                } finally {
                    this.fetchData();
                }
            },
            async getStudentType() {
                const fetchedStudentTypes = await $types.find(null, null, { params: { type: 'STUDENT_TYPE'}})
                this.asidebar.items[0].filters = fetchedStudentTypes.data.map(item => {
                    return {
                        id: item.value,
                        name: item.meaning,
                        type: 'check',
                        items: [],
                        value: true, 
                        dataFiltered: ''
                    }
                });
            },
            async getCareer () {
                const careers = await $careers.find('list', null, {params: {page: 0, length: 500, status: true} })
                careers.data.content.sort((a, b) => (a.name > b.name) ? 1 : -1)
                
                this.asidebar.items[1].filters[0].items = careers.data.content.map(item => {
                    return {
                        value: item.id,
                        text: item.name,                        
                        dataFiltered: 0,                        
                    }
                });
            },
            
            async fetchData() {
                try {
                    let sortCol = this.sort.sortBy
                    if (sortCol == 'seller') sortCol = 'seller.sellerName'
                    else if (sortCol == 'studentType') sortCol = 'studentType.meaning'
                    else if (sortCol == 'period.startDate') sortCol = 'range.startDate'
                    else if (sortCol == 'period.endDate') sortCol = 'range.endDate'
                    
                    const params = {
                        page: this.asidebar.pagination.page - 1,
                        length: this.asidebar.pagination.limit,
                        orderBy: sortCol,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                    }

                    const filterParams = {
                        termId : [], 
                        studentType: [],
                        career: [],
                        from : null,
                        to : null,
                        search : null,
                    }

                    this.asidebar.items[0].filters.forEach(studentType => {
                        if(studentType.value) filterParams.studentType.push(studentType.id);
                    })
        
                    filterParams.career = this.asidebar.items[1].filters[0].value
                        ? this.asidebar.items[1].filters[0].value : [],                    

                    filterParams.from = this.asidebar.items[2].filters[0].value ? 
                        new Date(this.asidebar.items[2].filters[0].value).toISOString().substr(0, 10) : null;

                    filterParams.to = this.asidebar.items[2].filters[1].value ? 
                        new Date(this.asidebar.items[2].filters[1].value).toISOString().substr(0, 10) : null;

                    filterParams.search = this.search;

                    const resp = await $weekObjetive.create(filterParams, { params }, 'filter');
                    this.totalItems = resp.data.totalElements;
                    this.asidebar.pagination.pagesAmount = resp.data.totalPages;

                    this.commissions = resp.data.content;

                } catch (error) {
                    this.commissions = []
                    throw error;
                }
            },
            
            importFile() {
                if (this.canCreate) this.$router.push('/commissions/objectives/import');
            },
            newCommission() {
                if (this.canCreate) this.$router.push('/commissions/objectives/create');
            },
            updateCommission(id) {
                if (this.canUpdate) this.$router.push(`/commissions/objectives/update/${id}`);
            },
            async searchAction(data) {
                this.search = data;
                this.fetchData();
            },
            async onInputPagination(event) {
                this.asidebar.pagination.page = event;
                this.asidebar.pagination.currentPage = event;
                this.fetchData();
            },
            onLengthPaginationChange(event) {
                this.asidebar.pagination = {
                    page: 1,
                    limit: event,
                    currentPage: 1,
                    pagesAmount: 1
                };
                this.fetchData();
            },
            changed(index, i, value) {
                this.asidebar.items[index].filters[i].value = value
                this.asidebar.items[2].filters[0].maxDate = this.asidebar.items[2].filters[1].value;
                this.asidebar.items[2].filters[1].minDate = this.asidebar.items[2].filters[0].value;
                this.fetchData();
            },
        },
        watch: {
            sort: {
                handler(){
                    this.fetchData();
                },
                deep: true
            }
        }
    }
</script>