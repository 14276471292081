<template>
  <div class="telephoneInput__container">
   <vue-tel-input v-model="phone"
                  v-bind="bindProps"
                  :disabled="disabled"
                  @input="handleInput"
                  mode="international"
                  autocomplete="phone"
                  :validCharactersOnly="true"
                  @blur="handleBlur"
                  @validate="isValidPhone"
                  ref="v_tel_input"
                  :wrapperClasses="['telephoneInput', {'is-invalid': (!isValid && phone) || error || errorAction, 'action_buttons': action_buttons}]">
   </vue-tel-input>
    <div class="telephoneInput__details">
      <div class="v-messages error--text" v-if="(!isValid && phone) || error || errorAction">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="telephoneInput__actions" v-if="action_buttons">
      <v-btn small class="px-0" @click="saveInput">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
      <v-btn small class="px-0" @click="cancelInput">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="sass">
.telephoneInput
    min-height: 44px !important
    margin-bottom: 8px
    outline: none
    &.is-invalid
        border-color: #ff5252 !important
        border-width: 2px !important
        .vti__input, .vti__input::placeholder
            color: #ff5252 !important
    &:hover
        border-color: rgba(0, 0, 0, 0.87)
    &:focus-within
        box-shadow: none !important
        border-color: var(--v-primary-base) !important
        border-width: 2px !important
        & ~ .telephoneInput__actions
            height: auto
            opacity: 1
            top: 46px
        &.is-invalid
            border-color: #ff5252 !important
            border-width: 2px !important
            .vti__input, .vti__input::placeholder
                color: #ff5252 !important


    &.action_buttons
        border-color: transparent
        &:hover
            border-color: rgba(0, 0, 0, 0.87)
            background-color: rgba(62, 80, 255, 0.1) !important

    &__details
        margin-bottom: 8px
        padding: 0 12px

    &__container
        position: relative
    &__actions
        z-index: 2
        display: flex
        justify-content: flex-end
        position: absolute
        top: 0
        right: 0
        height: 0
        opacity: 0
        overflow: hidden
        .v-btn
            min-width: 40px
            margin: 4px
        &:hover
            height: auto
            opacity: 1
            top: 46px
</style>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'TelephoneInput',
  props: {
    disabled: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
    },
    action_buttons: {
      type: Boolean,
      default: false
    },
    country: {
      type: Object,
    }
  },
  components: {
    VueTelInput
  },
  data () {
    return {
      phone: this.value,
      isValid: true,
      errorAction: false,
      errorMessage: '',
      bindProps: {
        mode: 'international',
        disabledFetchingCountry: false,
        disabled: this.disabled,
        disabledFormatting: false,
        placeholder: this.placeholder,
        required: this.required,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'phone',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    };
  },
  methods: {
    /*validate() {
      console.log(this.$refs.v_tel_input)
      this.isValid = this.$refs.v_tel_input.phoneObject.isValid
      this.$emit('validate', this.isValid)
      /!*this.$refs.v_tel_input.emitInput(this.value)*!/
    },*/
    isValidPhone(phoneObject) {
      this.$emit('validate', phoneObject)
    },
    handleInput (input, { number, isValid, country }) {
      this.isValid = isValid;
      this.errorAction = false;

      if ( this.required ) {
        if (input) {
          if(!isValid ) {
            this.errorAction = true;
            this.errorMessage = this.$t('components.telephoneInput.formatInvalid');
          }
        } else {
          this.errorAction = true;
          this.errorMessage = this.$t('components.telephoneInput.isRequired');
        }
      } else {
        if (input && !isValid) {
          this.errorAction = true;
          this.errorMessage = this.$t('components.telephoneInput.formatInvalid');
        }
      }

      if (input && isValid) {
        this.phone = number.international;
        this.$emit('update:country', country);
        this.$emit('update:value', number.international);
      }

      this.$emit('input', this.phone);
    },
    handleBlur () {
      if ( this.required && !this.phone ) {
        this.errorAction = true;
        this.errorMessage = this.$t('components.telephoneInput.isRequired');
      }
      if( this.phone && !this.isValid ) {
        this.errorAction = true;
        this.errorMessage = this.$t('components.telephoneInput.formatInvalid');
      }

      this.$emit('blur');
    },
    cancelInput () {
      this.isValid = true;
      this.errorAction = false;
      this.phone = this.value;
    },
    saveInput () {
      if (this.required ) {
        if (this.isValid) {
          if (this.phone) {
            this.errorAction = false;
            this.$emit('save', this.phone);
          }
        } else {
          this.errorAction = true;
        }
      } else {
        this.errorAction = false;
        this.$emit('save', this.phone);
        }
    },
  },
  watch: {
    value(val) {
      this.$refs.v_tel_input.cursorPosition = val.length
      if (val && this.action_buttons) this.isValid = true;
      this.phone = val || '';

      if(this.required && !this.phone && this.action_buttons) {
        this.errorAction = true;
      }
    }
  }
};
</script>
