import { render, staticRenderFns } from "./UpdateDetailsTable.vue?vue&type=template&id=73022c5b&scoped=true&"
import script from "./UpdateDetailsTable.vue?vue&type=script&lang=js&"
export * from "./UpdateDetailsTable.vue?vue&type=script&lang=js&"
import style0 from "./UpdateDetailsTable.vue?vue&type=style&index=0&id=73022c5b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73022c5b",
  null
  
)

export default component.exports