const sellersTranslations = {
    es_PE: {
        create: {
            sectionLocality:{
                title: 'Distrito',
                subtitle: 'En esta sección puedes asignar los distritos a gestionar.',
                province: 'Departamento *',
                firstListTitle: 'Distritos Disponibles',
                secondListTitle: 'Distritos a asignar: ',
                label: 'distritos',
                error: {
                    errorLocality: 'Debes seleccionar al menos una distrito',
                },
            },
        },
        update: {
            sectionLocality:{
                title: 'Distritos',
                subtitle: 'En esta sección puedes editar los distritos a gestionar.',
                province: 'Departamento *',
                firstListTitle: 'Distritos Disponibles',
                secondListTitle: 'Distritos a asignar: ',
                label: 'distritos'
            },
        },
        validations: {
            cityRequired: 'Ingrese un distrito',
        },
    },
    es: {
        table: {
            breadcrumbs: {
                title: 'Agentes Comerciales',
                description:'Administración de diferentes agentes comerciales',
            },
            headers: {
                code: 'Codigo',
                name: 'Nombre',
                careers: 'Carreras',
                students: 'Estudiantes',
                status: 'Estado',
            },
            actions: {
                errorDelete: 'Error al eliminar',
                bulk_delete: 'Eliminar agentes comerciales',
            },
            messages: {
                bulk_delete_error: 'No se pudieron eliminar los agentes seleccionados',
                delete_error: 'No se pudo eliminar el agente seleccionado',
            }
        },
        create: {
            breadcrumbs: {
                title: 'Nuevo Agente Comercial',
                description:'Crea un nuevo agente comercial',
            },
            sectionData: {
                title: 'Datos del agente',
                subtitle: 'Ingresa la informacion básica del nuevo agente comercial que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                labelEnable: 'Habilitado',
                labelDisable: 'Deshabilitado',
                items: {
                    'sellerName': 'Nombre *',
                    'sellerCode': 'Código *',
                    'commission': 'Recibe comisión',
                    'sapCode': 'Código Sap ',
                    'email': 'Email *',
                    'phone': 'Telefono *',
                  },
                  error: {
                    errorUser: 'Debes seleccionar al menos un tipo de estudiante',
                },
            },
            sectionLocality:{
                title: 'Localidades',
                subtitle: 'En esta sección podrás asignar las localidades a gestionar.',
                province: 'Provincia *',  
                firstListTitle: 'Localidales Disponibles',
                secondListTitle: 'Localidades a asignar: ',
                label: 'localidades',
                error: {
                    errorLocality: 'Debes seleccionar al menos una localidad',
                },
            },
            sectionCareers: {
                title: 'Carreras',
                subtitle: 'En esta sección podrás asignar las carreras que estarán disponibles para el nuevo agente comercial.',
                'no-selected-warning': '¡Precaución! Debes seleccionar al menos una carrera.',
                error: {
                    errorCareer: 'Debes seleccionar al menos una Carrera',
                },
            },
            sectionUsers: {
                title: 'Usuarios',
                subtitle: 'Selecciona los usuarios para el nuevo agente comercial. Ten en cuenta que si el usuario que buscas no está disponible en esta lista, es posible que ya se encuentre asignado a otro agente comercial.',
                error: {
                    errorUser: 'Debes seleccionar al menos un estudiante',
                },
            },
            success_message: {
                title:'Agente comercial creado correctamente',
               actions: {
                primary_text: 'Cerrar',
                secondary_text: 'Crear otro',
                back: 'Volver',
            },
           },
            error_message: {
                title: 'Ocurrió un error al crear el agente comercial',
            },
            disabledMessage : 'Estás cargando el agente comercial de manera deshabilitada. ¿Deseas continuar? '
        },
        update: {
            breadcrumbs: {
                title: 'Editar Agente Comercial',
                description:'Edita el agente seleccionado',
            },   
            sectionData: {
                title: 'Datos del agente',
                subtitle: 'Edite la informacion básica del nuevo agente comercial que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                labelEnable: 'Habilitado',
                labelDisable: 'Deshabilitado',
                items: {
                    'sellerName': 'Nombre *',
                    'sellerCode': 'Código *',
                    'commission': 'Recibe comisión',
                    'sapCode': 'Código Sap *',
                    'email': 'Email *',
                    'phone': 'Telefono *',
                  },
                  error: {
                    selectDesc: 'Debes seleccionar al menos un tipo de estudiante',
                },
            },
            sectionCareers: {
                title: 'Carreras',
                subtitle: 'En esta sección podrás editar las carreras que estarán disponibles para el nuevo agente comercial.',
                'no-selected-warning': '¡Precaución! Debes seleccionar al menos una carrera.',
            },
            sectionLocality:{
                title: 'Localidades',
                subtitle: 'En esta sección podrás editar las localidades a gestionar.',
                province: 'Provincia *',  
                firstListTitle: 'Localidales Disponibles',
                secondListTitle: 'Localidades a asignar: ',
                label: 'localidades'
            },
            sectionUsers: {
                title: 'Usuarios',
                subtitle: 'Selecciona los usuarios a editar en el agente comercial. Ten en cuenta que si el usuario que buscas no está disponible en esta lista, es posible que ya se encuentre asignado a otro agente comercial.'
            },
            success_message: {
                title: 'Agente comercial actualizado correctamente',
            },
            error_message: {
                title: 'Ocurrió un error al actualizar el agente comercial',
            }
        },
        validations: {
          nameRequired: 'Ingrese un nombre',
          nameAlphanumericWithSpaces: 'Solo alfanuméricos',
          nameMaxLength: 'Máximo 60 caracteres',
          codeRequired: 'Ingrese un código',
          codeExist: 'Ese código ya existe',
          codeAlphanumeric: 'Solo alfanuméricos',
          codeMaxLength: 'Máximo 10 caracteres',
          emailRequired: 'Ingrese un email',
          cityRequired: 'Ingrese una ciudad',
        },
    },
    en: {
        
    }
};


export default sellersTranslations