const pricesTranslations = {
  es:{

    table: {
      breadcrumbs: {
        title: 'Precios',
        description: 'Administración de precios.'
      },
      headers: {
        'product': 'Producto',
        'status': 'Estado',
        'details': 'Detalles',
        'from': 'Desde',
        'careers': 'Carreras',
      },
      filters: {
        product: 'Producto',
        year: 'Año'
      },
      message: {
        delete: '¿Estás seguro de eliminar?',
        title: 'Estás a punto de eliminar los siguientes precios',
        deleteAction: 'Si, eliminar',
        cancelAction: 'Cancelar',
        actions: 'Acciones'
      },
      search: {
        search: 'Buscar',
        gotopage: 'ir a página',
        edit: 'Editar',
        delete: 'Eliminar',
        bulkDelete: 'Eliminar becas',
        of: 'de',
      },
      reindex: 'Reindexar',
      create: 'Nuevo',
      edit: 'Editar',
    },
    create: {
      breadcrumbs: {
        title: 'Nuevo precio',
        subtitle: 'Crea una nueva regla de precios.'
      },
      section_product: {
        title: 'Producto y fecha',
        subtitle: 'Selecciona un producto e ingresa una fecha desde la cual se aplicará el precio.',
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        product: 'Producto',
        from: 'Desde',
        new_product: 'Nuevo producto',
        new_product_description: 'Crea un nuevo producto.',
        product_error: 'Debes seleccionar un producto'
      },
      section_career: {
        title: 'Carreras',
        subtitle: 'Selecciona el tipo de estudio y las carreras a las que se les asignará el nuevo precio.',
        study_type: 'Tipo de estudio',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        },
        'no-selected-warning': '¡Precaución! Debes seleccionar al menos una carrera.',
      },
      section_student: {
        title: 'Tipo de estudiantes',
        error: '¡Precaución! Debes seleccionar al menos un tipo de estudiante.',
      },
      section_modality: {
        title: 'Modalidades',
        error: '¡Precaución! Debes seleccionar al menos una modalidad.',
      },
      section_zone: {
        title: 'Precios y regiones',
        items: {
          zone: 'Región',
          zone_error: 'Debes seleccionar una región',
          price: 'Precio',
          price_error: 'Debes ingresar un número',
        },
        new_zone: 'Nueva región',
        new_zone_description: 'Crea una nueva región.'
      },
      buttons: {
        error: 'Error',
        create: 'Crear',
        created: 'Creado',
        cancel: 'Cancelar'
      },
      warning_popup: {
        title: '¿Deseas crear un precio en estado deshabilitado?',
      },
      success_title: 'El precio ha sido creado con éxito',
      error_title: 'Ya existe un precio relacionado al producto y fecha seleccionados',
      accept: 'Aceptar',
      go_to_back:'Volver',
    },
    update: {
      breadcrumbs:{
        title: 'Editar precio',
        description: 'Modifica datos del precio seleccionado.'
      },
      section_product: {
        title: 'Producto y Fecha',
        subtitle: 'Selecciona un producto e ingresa una fecha desde la cual se aplicará el precio',
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        product: 'Producto',
        from: 'Desde',
        new_product: 'Nuevo producto'
      },
      section_career: {
        title: 'Carreras',
        subtitle: 'Selecciona el tipo de estudio y las carreras a las que se les asignará el nuevo precio.',
        study_type: 'Tipo de estudio',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionadas'
        },
        'no-selected-warning': '¡Precaución! Debes seleccionar al menos una carrera.',
      },
      section_student: {
        title: 'Tipo de estudiantes',
        error: '¡Precaución! Debes seleccionar al menos un tipo de estudiante.',
      },
      section_modality: {
        title: 'Modalidades',
        error: '¡Precaución! Debes seleccionar al menos una modalidad.',
      },
      section_zone: {
        title: 'Precios y regiones',
        items: {
          zone: 'Región',
          zone_error: 'Debes seleccionar una región',
          price: 'Precio',
          price_error: 'Debes ingresar un número'
        },
        new_zone: 'Nueva región'
      },
      warning_popup: {
        title: '¿Deseas guardar un precio en estado deshabilitado?',
        update: 'Si, guardar.',
        cancel: 'Cancelar.'
      },
      section_details: {
        title: 'Precios generados',
        table: {
          headers:{
            modality_type: 'Tipo de modalidad',
            student_type: 'Tipo de estudiante',
            zone: 'Región',
            price: 'Precio',
          }
        }
      },
      success_title: 'El precio ha sido actualizado con éxito',
      error_title: 'Ya existe un precio relacionado al producto y fecha seleccionados',
      accept: 'Aceptar',
      go_to_back: 'Volver'

    },
    reindex: {
      breadcrumbs:{
        title: 'Reindexar precio',
        description: 'Busca el o los precios que deseas modificar',
      },
      success: 'La reindexación ha sido realizada de manera satisfactoria',
      error: 'Ha ocurrido un error al reindexar',
      getError: 'No hemos encontrado precios para esta combinación',
      retry: 'Reintentar',
      filters:{
        product: 'Producto',
        student_type: 'Tipo de estudiante',
        modality_type: 'Tipo de modalidad',
        from: 'Desde',
        search: 'Buscar',
        newSearch: 'Nueva búsqueda',
        newSearchAlert:{
          title: '¿Deseas realizar una nueva búsqueda?',
          description: 'Perderás todos los cambios realizados',
          cancel: 'Cancelar',
          search: 'Sí, buscar'
        }
      },
      quantity:{
        percent: 'Porcentaje',
        amount: 'Monto',
        round: 'Redondear',
        calculate: 'Calcular'
      },
      table:{
        headers:{
          state: 'Estado',
          product: 'Producto',
          student_type: 'Tipo de estudiante',
          modality_type: 'Tipo de modalidad',
          zone: 'Región',
          from: 'Desde',
          price: 'Precio',
          index: 'Indexado',
          final: 'Final',
        },
        goToPage: 'Ir a la página',
        of: 'de'
      },
      validations:{
        search: {
          productRequired: 'Debes ingresar un producto',
          dateRequired: 'Debes ingresar una fecha',
          studentTypeRequired: 'Debes ingresar un tipo de estudiante',
          modalityRequired: 'Debes ingresar una modalidad'
        }
      },
      button:{
        error: 'Error',
        getSuccess: 'Listo',
        save:'Guardar',
        cancel: 'Cancelar',
      },
      cancelAlert:{
        title: '¿Estás seguro que deseas cancelar?',
        description: 'Perderás todos los cambios realizados',
        goBack: 'No, volver atras',
        cancel: 'Cancelar'
      },

      reindexAlert:{
        title: 'Estás a punto de reindexar',
        prices: 'precios',
        description: 'Perderás todos los cambios realizados',
        cancel: 'Cancelar',
        reindex: 'Sí, reindexar'
      }
    }
  },
  en:{

  }
}

export { pricesTranslations }
export default pricesTranslations
