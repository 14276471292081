<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters class="mb-4">
      <v-col sm="10">
        <v-row>
          <v-col sm="3" class="py-0">
            <v-select
              v-model="$v.search.product.$model"
              :items="products"
              :label="$t('modules.prices.reindex.filters.product')"
              :disabled="items.length > 0"
              return-object
              item-text="meaning"
              outlined
              required
              @blur="$v.search.product.$touch"
              :error-messages="validate('search.product')"
            ></v-select>
          </v-col>
          <v-col sm="3" class="py-0">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="$v.search.date.$model"
                  @blur="$v.search.date.$touch"
                  :disabled="items.length > 0"
                  readonly
                  :label="$t('modules.prices.reindex.filters.from')"
                  append-icon="mdi-calendar"
                  outlined
                  v-on="on"
                  :error-messages="validate('search.date')"
                ></v-text-field>
              </template>
              <v-date-picker ref="picker" v-model="search.date" min="1950-01-01"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="3" class="py-0">
            <v-select
              v-model="$v.search.studentType.$model"
              :items="studentTypes"
              :label="$t('modules.prices.reindex.filters.student_type')"
              :disabled="items.length > 0"
              return-object
              item-text="meaning"
              outlined
              required
              @blur="$v.search.studentType.$touch"
              :error-messages="validate('search.studentType')"
            ></v-select>
          </v-col>
          <v-col sm="3" class="py-0">
            <v-select
              v-model="$v.search.modality.$model"
              :disabled="items.length > 0"
              :items="modalities"
              :label="$t('modules.prices.reindex.filters.modality_type')"
              return-object
              item-text="meaning"
              outlined
              required
              @blur="$v.search.modality.$touch"
              :error-messages="validate('search.modality')"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="2" align="left">
        <Button
          class="ml-6"
          :disabled="$v.search.$invalid"
          :text="items.length > 0 && !getLoading? $t('modules.prices.reindex.filters.newSearch') : $t('modules.prices.reindex.filters.search') "
          :loading="getLoading"
          :error="getError"
          :errorText="$t('modules.prices.reindex.button.error')"
          :successText="$t('modules.prices.reindex.button.getSuccess')"
          :success="getSuccess"
          @end="resetButtonValues()"
          @clicked="items.length && !getLoading> 0 ? newSearch() : searchPrices()"
        ></Button>
      </v-col>
    </v-row>
    <v-fade-transition>
      <v-row no-gutters class="mb-8" v-show="items.length > 0">
        <v-col sm="10">
          <v-row>
            <v-col class="py-0" sm="3">
              <v-text-field
                :label="$t('modules.prices.reindex.quantity.percent')"
                v-model="reindexQuantity.percent"
                @keypress="$validateDecimalInputNumber($event, reindexQuantity.percent, 100)"
                @change="calculationChange('percent')"
                :error-messages="validate('reindexQuantity.percent')"
                prepend-inner-icon="mdi-percent"
                type="number"
                min="0"
                max="100"
                outlined
                class="no-message"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="3">
              <v-text-field
                :label="$t('modules.prices.reindex.quantity.amount')"
                v-model="reindexQuantity.amount"
                @change="calculationChange('amount')"
                @keypress="$validateDecimalInputNumber($event, reindexQuantity.amount)"
                :error-messages="validate('reindexQuantity.amount')"
                type="number"
                outlined
                min="0"
                class="no-message"
              >
                <template v-slot:prepend>
                  <span>{{ $countryConstants.currency }}</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col class="py-0 d-flex align-center" sm="6">
              <v-checkbox
                v-model="reindexQuantity.roundPrice"
                :label="$t('modules.prices.reindex.quantity.round')"
                color="primary"
                class="mt-2 mr-6 no-message"
                style="width: auto;"
              ></v-checkbox>
              <Button
                :text="$t('modules.prices.reindex.quantity.calculate')"
                :disabled="$v.reindexQuantity.$invalid"
                outlined
                @clicked="calculatePrice"
              ></Button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition>
    <v-row no-gutters v-show="items.length > 0">
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:item.status="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

            <template v-slot:item.price="{item}">{{ item.price }}</template>
            <template v-slot:item.amount="{item}">{{ item.amount | $currency }}</template>
            <template v-slot:item.indexedPrice="{item}">
              <span class="font-weight-bold">{{ item.indexedPrice | $currency }}</span>
            </template>

            <template v-slot:item.finalPrice="{item}">
              <IndependentInput
                :v="['required',]"
                :input="item.finalPrice"
                size="small"
                class="my-3 font-weight-bold"
                icon="mdi-currency-usd"
                @save="changeFinalPrice(item.finalPrice)"
              ></IndependentInput>
            </template>
          </v-data-table>
          <v-divider class="mt-4 mb-8"></v-divider>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition>
      <v-row no-gutters align="center" v-if="items.length > 0">
        <v-col sm="3">
          <span
            class="caption d-inline-block"
          >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' ' + $t('modules.prices.reindex.table.of') + ' ' + itemsCount.totalItems }}</span>
          <v-select
            v-model="itemsPerPage"
            :items="itemsPerPageAvailable"
            :placeholder="itemsPerPage.toString()"
            outlined
            class="superTable__paginationSelect d-inline-block ml-4"
          ></v-select>
        </v-col>
        <v-col sm="6" class="text-center">
          <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
        </v-col>
        <v-col sm="3" align="right">
          <v-text-field
            v-model="page"
            min="1"
            max="pageCount"
            outlined
            class="superTable__paginationInput d-inline-block mr-4"
          ></v-text-field>
          <span class="caption d-inline-block">{{$t('modules.prices.reindex.table.goToPage')}}</span>
        </v-col>
      </v-row>
    </v-fade-transition>
    <v-fade-transition>
    <v-row justify="end" class="buttons" v-if="items.length > 0">
      <v-col class="py-0 mt-8 d-flex justify-end pr-4" sm="9">
        <v-btn text rounded large class="cancelbutton mr-3" @click="cancel()">{{$t('modules.prices.reindex.button.cancel')}}</v-btn>
        <Button
          @clicked="reindexAlert()"
          class="crearbutton"
          large
          rounded
          :errorText="$t('modules.prices.reindex.button.error')"
          :text="$t('modules.prices.reindex.button.save')"
          :success="saveSuccess"
          :loading="saveLoading"
          :error="saveError"
          primary
        ></Button>
      </v-col>
    </v-row>
    </v-fade-transition>
  </v-container>
</template>

<style lang="sass" scoped>
    .v-expansion-panels
        .v-expansion-panel-content
            padding-left: 10px
            padding-bottom: 0px
            padding-right: 16px
</style>

<script>
import Button from '@/components/Button/Button'
import IndependentInput from '@/components/IndependentInput/IndependentInput'
import { $products, $types } from '../../Services'
import { required, requiredUnless, minValue } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex';

export default {
  name: 'SuperTable',
  props: {
    items: Array,
    headers: Array,
    pageCount: Number,
    calculation: Object,
    saveSuccess: Boolean,
    saveError: Boolean,
    saveLoading: Boolean,
    getSuccess: Boolean,
    getError: Boolean,
    getLoading: Boolean,
  },
  components: {
    Button,
    IndependentInput
  },
  data () {
    return {
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
      products: [],
      studentTypes: [],
      modalities: [],
      dateMenu: false,
      search: {
        'product': '',
        'date': '',
        'studentType': '',
        'modality': ''
      },
      reindexQuantity: {
        'percent': null,
        'amount': null,
        'roundPrice': true
      }
    }
  },
  provide() {
    return {
        $validationParams: { required }
    };
  },
  methods: {
    
    cancel () {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.prices.reindex.cancelAlert.title'),
        actionPrimary: {
          text: this.$t('modules.prices.reindex.cancelAlert.cancel'),
          callback: () => {
            this.goBack()
          }
        },
        actionSecondary: {
          text: this.$t('modules.prices.reindex.cancelAlert.goBack'),
          callback () { }
        },
        icon: { color: 'warning', name: 'mdi-alert-outline' },
        color: 'primary',
      })
    },

    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1];
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },

    formatDetailsToReindex(details){
      
      const data = [];
      
      details.forEach(detail => {
        const tmpDetail = {
          detailId : detail.detailId,
          priceId : detail.priceId,
          priceStatus : detail.status,
          billingProduct : detail.billingProduct,
          amount : detail.finalPrice,
          zone : detail.zone,
          modalityType : detail.modalityType,
          studentType : detail.studentType,
          from: detail.from
        }

        data.push(tmpDetail);
      })

      return data
    },

    reindexAlert () {
      this.$store.dispatch('commons/openPopUp', {
        title: `${this.$t('modules.prices.reindex.reindexAlert.title')  } ${  this.items.length  } ${  this.$t('modules.prices.reindex.reindexAlert.prices')}`,
        actionPrimary: {
          text: this.$t('modules.prices.reindex.reindexAlert.reindex'),
          callback: () => {

            const data = {
              details: this.formatDetailsToReindex(this.items),
              newDate: this.search.date,
              productId: this.search.product.value
            }

            this.$emit('reindex', data)
          }
        },
        actionSecondary: {
          text: this.$t('modules.prices.reindex.reindexAlert.cancel'),
          callback () { }
        },
        icon: { color: 'warning', name: 'mdi-alert-outline' },
        color: 'primary',
      })
    },

    newSearch () {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.prices.reindex.filters.newSearchAlert.title'),
        actionPrimary: {
          text: this.$t('modules.prices.reindex.filters.newSearchAlert.search'),
          callback: () => {
            this.items = []
          }
        },
        actionSecondary: {
          text: this.$t('modules.prices.reindex.filters.newSearchAlert.cancel'),
          callback () { }
        },
        icon: { color: 'warning', name: 'mdi-alert-outline' },
        color: 'primary',
      })
    },


    calculationChange (field) {
      if (field === 'percent') {
        this.reindexQuantity.amount = null;
      } else if (field === 'amount') {
        this.reindexQuantity.percent = null;
      }
    },

    formatProductFilter (products) {
      return products.map((product) => {
        const tmpProduct = {
          meaning: product.name,
          type: 'PRODUCT',
          value: product.id,
        }
        return tmpProduct
      })
    },

    formatSearchQuery (filters) {
      return {
        studentType:filters.studentType.value,
        modalityType:filters.modality.value,
        productId:filters.product.value,
        from: filters.date,
      }
    },

    async getFiltersData () {
      const [products, studentType, modalityType] = await Promise.all([
        $products.find(null, null, { params: { page: 0, length: 500 } }),
        $types.find(null, null, { params: { type: 'STUDENT_TYPE' } }),
        $types.find(null, null, { params: { type: 'MODALITY_TYPE' } }),
      ]);

      this.products = this.formatProductFilter(products.data.content)
      this.studentTypes = studentType.data
      this.modalities = modalityType.data
    },
    searchPrices () {
      this.$emit('search', this.formatSearchQuery(this.search));
    },
    calculatePrice () {
      this.$emit('calculate', this.reindexQuantity);
    },

    validate (inputValue) {

      let error = '';
      const that = this;

      const validator = this['commons/getProp'](inputValue, this.$v)

      String.prototype.toPascalCase = function () {
        return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
      };

      if (validator.$invalid && validator.$dirty) {
        const validationParams = validator.$params;

        // First validate required
        if (validationParams.required) {
          if (!validator.required) {
            error = that.$t(`modules.prices.reindex.validations.${inputValue}Required`);
          }
        }

        const paramsToArray = Object.values(validationParams);

        paramsToArray.forEach(param => {
          if (param.type !== 'required') {
            if (validator[param.type]) {
              error = that.$t(`modules.prices.reindex.validations.${inputValue + (param.type).toPascalCase()}`)
            }
          }
        })
      } else {
        error = '';
      }

      return error;
    },

    resetButtonValues () {
      this.$emit('update:getLoading',false);
      this.$emit('update:getSuccess',false);
      this.$emit('update:getError',false);
      this.$emit('update:saveLoading',false);
      this.$emit('update:saveSuccess',false);
      this.$emit('update:saveError',false);
    },
  },
  watch: {
    'page': function (val) {
      this.$emit('page', val)
    },
    'itemsPerPage': function (val) {
      this.$emit('itemsPerPage', val)
    }
  },
  computed: {
    itemsCount () {
      const counter = {
        totalItems: this.items.length,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.items.length) {
        counter.endItem = this.items.length
      }

      return counter
    },

    ...mapGetters([
      'commons/getProp'
    ])
  },
  validations: {
    search: {
      product: { required },
      date: { required },
      studentType: { required },
      modality: { required }
    },
    reindexQuantity: {
      percent: {
        required: requiredUnless('amount'),
        minValue: minValue(0)
      },
      amount: {
        required: requiredUnless('percent'),
        minValue: minValue(0)
      },
    }
  },
  mounted () {
    this.getFiltersData()
  }
};
</script>
