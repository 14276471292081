<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container fluid class="px-0">
        <div class="px-md-40">
          <div class="pl-3 pr-3">

            <v-row align="center">
              <v-col class="d-flex" cols="12">

                <div class="outside-container">
                  <v-row>
                    <v-col class cols="12">
                      <div class="icon-wrapper" :class="status">
                        <v-icon v-if="status === 'approved'" color="success" size="100px">mdi-check-circle</v-icon>                        
                        <v-icon v-else-if="status === 'rejected'" color="red" size="100px">mdi-close-circle</v-icon>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class cols="12">
                      <h4 class="text-center" :class="(status === 'approved') ? 'success--text' : ((status === 'in_process') ? 'yellow--text' : 'red--text')">{{ title }}</h4>
                      <p class="text-center message_description">{{ description }}</p>
                      <div class="text-center">
                        <v-btn rounded color="primary" class="px-10" dark @click="goToHome">Ir a inicio</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>

              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>

    </v-container>
  </div>
</template>
<script>
import { $students } from '../services';

export default {
  name: 'Ecert',
  data () {
    return {
      headerTitle: this.$t('modules.admission.careerChangeStep3.dataSection.header'),
      token: '',
      code: '',
      title: null,
      description: null,
      status: '',
    }
  },
  async mounted () {
      this.token = this.$route.query.T;
      this.code =  this.$route.query.C;
      if (this.code === '0') {
        try {
          await $students.validateEcert(this.token);
          this.status = 'approved';
        } catch (e) {
          this.status = 'rejected';
        }
      } else {
        this.status = 'rejected';
      }
  },
  methods: {
    setMessage(){
      this.title = this.$t(`modules.admission.ecert.${this.status}.title`);
      this.description = this.$t(`modules.admission.ecert.${this.status}.description`);          
    },
    goToHome() {
      window.location.href = '#/home';
    }
  },
  watch: {
    status() {
      this.setMessage()
    },
  },
}
</script>

<style scoped lang="sass">
  .icon-wrapper
    padding: 25px
    border-radius: 50%
    height: 148px!important
    width: 148px!important
    margin: 0 auto
    margin-top: 30px
    &.approved
      background-color: rgba(59, 192, 0, 0.1) !important
    &.in_process
      background-color: rgba(59, 192, 0, 0.1) !important
    &.rejected
      background-color: rgba(244, 67, 54, 0.1) !important

  h4 
    font-family: "DM Sans", sans-serif
    font-size: 24px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 1.29
    letter-spacing: -0.72px
    text-align: center

  .message_description
    font-family: "Roboto", sans-serif
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.43
    letter-spacing: 0.25px
    text-align: left
    color: rgba(0, 0, 0, 0.87)
    width: 290px
    margin: 0 auto
    padding-bottom: 20px
    padding-top: 5px

  .outside-container 
    width: 534px
    margin: 0 auto

</style>