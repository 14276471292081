<template>
  <OutlinedCard class="usersCreateContactForm">
    <form class="usersCreateContactForm__content">
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-model="userData.email.$model"
            :error-messages="(userData.email.$dirty && userData.email.$invalid) ? $t('modules.users.create.validation_errors.contactForm.email') : ''"
            :label="$t('modules.users.create.contactForm.email') + ' *'"
            outlined
            type="email"
            @blur="userData.email.$touch">
          </v-text-field>
          <p class="body-2 ma-0">
            {{ $t('modules.users.create.contactForm.description') }}
          </p>
        </v-col>
        <v-col sm="6">
          <TelephoneInput
            :value.sync="userData.cellphone.$model"
            :country.sync="userData.celCountry"
            :required="true"
            :placeholder="$t('modules.users.create.contactForm.cellphone') + ' *'"
            :error="userData.cellphone.$dirty && userData.cellphone.$invalid"
            @blur="userData.cellphone.$touch()"
          ></TelephoneInput>
        </v-col>
        <v-col sm="6">
          <TelephoneInput
            :value.sync="userData.phone.$model"
            :country.sync="userData.celCountry"
            :placeholder="$t('modules.users.create.contactForm.phone')"
          >
          </TelephoneInput>
        </v-col>
        <v-col sm="12">
          <v-text-field
            v-model="userData.address.street.$model"
            :error-messages="(userData.address.street.$dirty && userData.address.street.$invalid) ? $t('modules.users.create.validation_errors.contactForm.address') : ''"
            :label="$t('modules.users.create.contactForm.addressName') + ' *'"
            outlined
            type="text"
            @blur="userData.address.street.$touch">
          </v-text-field>
        </v-col>
        <v-col sm="3">
          <v-text-field
            v-model.number="userData.address.number.$model"
            :label="$t('modules.users.create.contactForm.addressNumber')"
            min="0"
            max="999999"
            @keypress="$validateIntegerInputNumber($event, userData.address.number.$model, null, 6)"
            outlined
            type="number">
          </v-text-field>
        </v-col>
        <v-col sm="3">
          <v-text-field
            v-model.number="userData.address.floor.$model"
            min="0"
            max="200"
            @keypress="$validateIntegerInputNumber($event, userData.address.floor.$model, 200)"
            :label="$t('modules.users.create.contactForm.addressFloor')"
            outlined
            type="number">
          </v-text-field>
        </v-col>
        <v-col sm="3">
          <v-text-field
            v-model="userData.address.apartment.$model"
            @keydown="limitInput($event, 3)"
            :label="$t('modules.users.create.contactForm.addressApartment')"
            outlined
            type="text">
          </v-text-field>
        </v-col>
        <v-col sm="3">
          <v-text-field
            v-model="userData.address.zipCode.$model"
            @keydown="limitInput($event, 10)"
            :label="$t('modules.users.create.contactForm.addressZipCode')"
            outlined
            type="text"></v-text-field>
        </v-col>
        <v-col sm="12">
          <AutocompleteInput
            :value.sync="userData.address.cityWithState.$model"
            :errorMessages="$t('modules.users.create.validation_errors.contactForm.city')"
            :label="$t('modules.users.create.contactForm.city') + ' *'"
            :disable-buttons="true"
            @save="(val)=>{this.userData.address.cityWithState.$model = val}">
          </AutocompleteInput>
        </v-col>
      </v-row>
    </form>
  </OutlinedCard>
</template>

<style lang="sass">
    .usersCreateContactForm
        &__content
            .birthdayDatePicker__input
                .v-input__slot
                    fieldset
                        border-color: rgba(0, 0, 0, 0.24) !important
</style>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import AutocompleteInput from '@/components/AutocompleteInput/AutocompleteInput';
import TelephoneInput from '@/components/TelephoneInput/TelephoneInput'

export default {
  name: 'UsersCreateContactForm',
  components: {
    OutlinedCard,
    AutocompleteInput,
    TelephoneInput
  },
  props: {
    userData: Object,
    nextStep: Function,
    activeStep: Boolean,
  },
  data () {
    return {
      cityName: null,
      regionName: null,
      cityState: null
    }
  },
  methods: {
    limitInput(event, length) {
      if ((event.keyCode != 8) && (event.keyCode != 9) && (event.target.value.length >= length)) event.preventDefault();
    },
    validateStep(value) {
      if (this.activeStep) {
        if (!value.email.$anyDirty || value.email.$anyError ||
          !value.cellphone.$anyDirty || value.cellphone.$anyError ||
          !value.address.street.$anyDirty || value.address.street.$anyError ||
          !value.address.cityWithState.$anyDirty || value.address.cityWithState.$anyError) {
          this.$emit('disableNextStep');
        } else {
          this.$emit('nextStep');
        }
      }
    },
  },
  watch: {
    activeStep() {
      this.validateStep(this.userData);
    },
    userData: {
        handler(value){
          this.validateStep(value);
        },
        deep: true
    },
  },
}
</script>
