import Account from '../Views/Account';
import Details from '../Views/PaymentDetails';
import TeclabCredits from '../Views/TeclabCredits';
const checkingAccount = [
  {
    path: '/',
    component: Account,
    name: 'Account',
    meta: { name: 'Index', group: 'portalalumno.payments', path: 'checking_account', permission: 'READ' }
    },
  {
    path: 'details/:id',
    component: Details,
    name: 'Details',
    meta: { name: 'Index', group: 'portalalumno.payments', path: 'checking_account', permission: 'READ' }
    },
  {
    path: 'teclab-credits',
    component: TeclabCredits,
    name: 'TeclabCredits',
    meta: { name: 'Index', group: 'portalalumno.payments', path: 'checking_account', permission: 'READ' }
    }
];
export default checkingAccount
