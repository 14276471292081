<template>
  <v-container class="container-custom" style="height: 100%;">
    <v-row>
      <Breadcrumbs :title="breadcrumbs.title"></Breadcrumbs>
    </v-row>
    <v-row justify="center">
      <ApplicationEdit :myData="myData"></ApplicationEdit>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import ApplicationEdit from '../Components/ApplicationEdit/ApplicationEdit'

export default {
  components: {
    Breadcrumbs,
    ApplicationEdit
  },
  data () {
    return {
      myData:
      {
        status: true,
        items: [
          {
            name: 'Cambiar contraseña de campus Virtual',
            status: false
          },
          {
            name: 'Cambiar contraseña de campus Virtual',
            status: false
          },
          {
            name: 'Cambiar contraseña de campus Virtual',
            status: false
          },
          {
            name: 'Cambiar contraseña de campus Virtual',
            status: false
          }
        ]
      },
      breadcrumbs: {
        title: 'Opciones Personales'
      }
    }
  }
}
</script>
