<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs :title="$t('modules.portalalumno.payments.paymentsStudents.checkingAccount.details.breadcrumb.title')"></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" cols="12">

          <v-row>
            <v-col cols="12">
              <PaymentDetail :details="data" />
            </v-col>

          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<script>
import PaymentDetail from '../Components/PaymentDetail';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
export default {
    name: 'account',
    components: {
      PaymentDetail,
      Breadcrumbs
    },
    data() {
      return{
        data: {
            receiptId: 68646,
            receiptType: 'FACTURA B',
            receiptNumber: 'Conceptos:  Arancel',
            concept: 'Arancel',
            amount: 1050,
            paymentMethod: 'Tarjeta de debito',
            description: 'Pago mensualidad',
            datePayment: '22/06/2021'
        },
      }
    },
    created(){
      this.getInitialData()
    },
    methods: {
      async getInitialData() {
        try{
         this.data = this.$route.query.body;
        }catch(err){
          //console.log(err)
        }
      }
    },
}
</script>
