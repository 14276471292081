<template>
  <v-container fluid class="superTable">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          @input="helperSearcher()"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('actions.search')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :loading="loading"
          show-select
          :headers="headers"
          :items="Object.values($v.users.$each.$iter)"
          :page="page"
          :items-per-page="itemsPerPage"
          :multi-sort="false"
          :totalItems="totalItems"
          :sort-by="sortBy"
          :single-select="singleSelect"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
          @click:row="(val) => $emit('click:row', val.id)"
          hide-default-footer
          item-key="$model.id"
          class="superTable__table Benefits-supertable"
          style="table-layout: fixed"
        >
          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`header.token`]="{ header }">
             {{header.text }}
          </template>

          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox
              v-show="(canSelect || isSelected) && showSelect(item)"
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              v-model="item.isSelected.$model"
              @input="select($event)"
              :disabled="item.token.$invalid"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.identification`]="{item}">
            <div class="superTable__table__item__name">
              <div>{{ item.$model.identification }}</div>
            </div>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <div class="superTable__table__item__name">
              <v-avatar size="32" class="mr-4">
                <img :src="item.$model.avatar" />
              </v-avatar>
              <div>{{ item.$model.name }} {{ item.$model.lastname }}</div>
            </div>
          </template>

          <template v-slot:[`item.token`]="{ item }">
            <v-text-field
              v-show="canSelect && showSelect(item)"
              class="superTable__table__item__name mt-2 pt-0"
              color="primary"
              outlined
              v-model="item.token.$model"
              :error-messages="(item.token.$invalid && item.token.$dirty) ? ((!item.token.required) ? 'Este campo es obligatorio' : 'El token es invalido') :''"
              @blur="item.token.$touch()"
            ></v-text-field>
          </template>

        </v-data-table>
        <v-divider class="mt-0 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          @change="$emit('lengthPaginationChange', itemsPerPage)"
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination
          @next="$emit('nextPagination', page)"
          @previous="$emit('previousPagination', page)"
          @input="$emit('inputPagination', $event)"
          v-model="page"
          :length="pageCount"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          :value="page"
          ref="directPage"
          :max="pageCount"
          outlined
          @change="directPageInput($event)"
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.benefits.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from '@/plugins/constants'
import { $studentToken } from '../../Services'
import { required } from 'vuelidate/lib/validators';
// const touchMap = new WeakMap()
// import _ from 'lodash'

export default {
  name: 'SuperTableAssign',
  props: {
    users: Array,
    headers: Array,
    loading: Boolean,
    clearSelected: String,
    canSelect: Boolean,
    totalItems: {
      type: Number,
      default: 0
    },
    pageCount: Number,
    sortBy: String,
    sortDesc: Boolean,
    allowItemDeletion: {
      type: Boolean,
      default: true
    },
    withAverage: {
      type: Boolean,
      default: () => false,
    },
    minAverage: {
      type: Number,
      default: () => false,
    },
  },
  data() {
    return {
      timeOutToken: null,
      selectedItems: [],
      errorRepeatToken: false,
      search: '',
      items: [],
      singleSelect: false,
      userToken: true,
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
      timeout:null,
      // touchMap: new WeakMap(),
      index: 0
    };
  },
  methods: {
    helperSearcher(){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
    },
    searchAction(){
      this.page = 1
      this.$emit('searchName', this.search)
      this.$emit('inputPagination', this.page);
    },
    showSelect(item) {
      if (this.withAverage) return item.average != null && item.average >= 0 && item.average <= this.$countryConstants.maxAverage
      else return true
    },
    getAvatar(id) {
      return `${Constants.URL_DATA_SERVER}/users/${id}/avatar`;
    },
    directPageInput(event) {
      let newPage = +event;

      if(newPage > +this.pageCount) newPage = +this.pageCount;
      else if(newPage < 1 || +this.pageCount == 1) newPage = 1;

      this.page = newPage;
      this.$emit('inputPagination', this.page);
    },
  },
  watch: {
    page(val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val <= 1) this.page = 1;
    },
    clearSelected() {
      this.selectedItems = [];
    },
    selectedItems(val){
      this.$emit('toggleItem', val);
    },
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: this.page * this.itemsPerPage - this.itemsPerPage + 1,
        endItem: this.page * this.itemsPerPage
      };
      if (counter.endItem > this.totalItems) {
        counter.endItem = this.totalItems;
      }
      return counter;
    }
  },
  validations: {
    users: {
      $each: {
        identification: {required},
        token: {
          required,
              async getTokenValidate(val){
              if(val){
                try {
                  const data = await $studentToken.find(`?token=${val}&validateUse=true`)
                  if(data.status == 200) {
                    return true
                  }
                } catch(error) {
                  const itemsArray = Object.values(this.$v.users.$each.$iter)
                  const itemFilterArray = itemsArray.filter((e) => e.token.$invalid == true)
                  for (let index = 0; index < itemFilterArray.length; index++) {
                    const element = itemFilterArray[index];
                    element.isSelected.$model = false
                  }
                  const itemsSelected = this.selectedItems.filter((e) => e.isSelected.$model != false)
                  this.selectedItems = itemsSelected
                  return false
                }
              }
            },
        },
        isSelected:{Boolean},
        avatar: {required},
        name: {required},
        id: {String}
      }
    }
  }
};
</script>

<style lang="sass">
  .Benefits-supertable
    table tr
      // cursor: pointer !important
</style>
