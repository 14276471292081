export default {
    es: {
        origen: 'De',
        destino: 'A',
    },
    en: {
        origen: 'From',
        destino: 'To',
    }
};
