<template>
  <v-container class="d-flex fill-height align-center justify-center">
    <v-row>
      <v-col sm="6" class="mx-auto d-flex justify-center flex-column">
        <v-icon color="warning" size="154px">mdi-shield-alert</v-icon>
        <h6 class="display-1 font-wight-bold text-center mt-4 mb-1">{{$t('components.accessBlocked.title')}}</h6>
        <h6 class="headline text-center mt-4 mb-8">{{$t('components.accessBlocked.subtitle')}}</h6>
        <p class="text-center mb-10" v-html="$t('components.accessBlocked.description', { theme: $theme })"></p>
        <div class="d-flex justify-center">
          <v-btn
            large
            rounded
            text
            color="primary"
            class="my-2 px-8 text-uppercase"
            @click="toHome"
          >{{$t('actions.goToHome')}}</v-btn>
          <v-btn
            large
            rounded
            color="primary"
            class="my-2 px-8 text-uppercase"
            @click="logout"
          >{{$t('actions.logout')}}</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'AccessBlocked',
    methods: {
        logout () {
        this.$auth.logout();
        },
        toHome () {
        this.$router.replace('/')
        }
    }
};
</script>
