import Constants from '@/plugins/constants'

const url = `${Constants.URL_STUDENT_SERVER}/examenes/pefByCourseAndUser`

const pefByCourseAndUser = async ({ courseId, userId }) => {
    const response = await fetch(`${url}/${courseId}/${userId}`, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0' },
    })
    const data = await response.json()
    if(!response.ok) throw data
    return { data }
}
export default pefByCourseAndUser
