import MessagingView from './Messaging';
import Banners from './Banners/Banners'; 
import BannersRoute from './Banners/Routes/index';
import Template from './Template/Template'; 
import TemplateRoute from './Template/Routes/index';
import Whatsapp from './Whatsapp/Whatsapp'; 
import WhatsappRoute from './Whatsapp/Routes/index';
import NotFoundState from '@/components/NotFoundState/NotFoundState'

const MessagingRoute = {
    
    path: '/messaging',
    meta: {
        name: {
            en: 'Messaging',
            es: 'Mensajeria'
        },
    },
    component: MessagingView,
    children:[
        {
            path: '/',
            component: NotFoundState,
        },{
            path: 'banners',
            meta: { name: { es: 'Banners', en: 'Banners' } },
            component: Banners,
            children: BannersRoute
        },{
            path: 'templates',
            meta: { name: { es: 'Comunicaciones', en: 'communications' } },
            component: Template,
            children: TemplateRoute
        },{
            path: 'whatsapp',
            meta: { name: { es: 'Whatsapp', en: 'Whatsapp' } },
            component: Whatsapp,
            children: WhatsappRoute
        }
    ]
};

export default MessagingRoute

