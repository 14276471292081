<template>
  <v-row>
    <v-list>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.cuit'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.dni }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.carrera'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.carrera }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.celular'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.celular }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.localidad'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.localidad }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.telefono'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.telefonofijo }}
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.legajo'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.legajo }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.materia'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.materia }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.email'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.email }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_student.form.provincia'
            )
          }}: &nbsp;
        </span>
        {{ dataStudent.provincia }}
      </v-list-item>
    </v-list>
  </v-row>
</template>

<script>
export default {
  name: 'DatosStudentListGroup',
  props: ['datosCompany', 'dataStudent'],
};
</script>
