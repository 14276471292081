import ValidatorIndex from '../Views/ValidatorIndex'
const ValidatorRoute =
  [
    {
      path: '/validator',
      name: 'validator',
      component: ValidatorIndex,
    }
  ]

export default ValidatorRoute
