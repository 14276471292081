import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('billing');

export class Provincias extends Api {
  constructor(http, config = {}) {
    super(http, url, 'provincias', config);
  }
}
