import SubjectsSync from '../SubjectsSync.vue'
import SubjectsSyncTable from '../Views/SubjectsSyncTable.vue';

const SubjectsSyncRoute =
  {
    path: '/subjects-sync',
    meta: {
      name: {
        es: 'Sincronización de Materias',
        en: 'Subjects Synchronization',
      }
    },
    component: SubjectsSync,
    children: [
      {
        path: '',
        component: SubjectsSyncTable,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'subjects_sync', permission: 'READ' }
      }
    ]
  };

export default SubjectsSyncRoute
