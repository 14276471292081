import PrizesTable from '../Views/PrizesTable'
import PrizesCreate from '../Views/PrizesCreate'
import PrizesUpdate from '../Views/PrizesUpdate'

const configRoute = [
    {
        path:'',
        component: PrizesTable,
        meta: { name: 'Index', group: 'portaladministrativo.commissions', path: 'prizes', permission: 'READ' }
    },
    {
        path:'create',
        component: PrizesCreate,
        meta: { name: 'Create Commission', group: 'portaladministrativo.commissions', path: 'prizes', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: PrizesUpdate,
        meta: { name: 'Update Commission', group: 'portaladministrativo.commissions', path: 'prizes', permission: 'READ' }
    },
];

export default configRoute
