<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.academic.equivalences.table.breadcrumbs.title')"
          :description="$t('modules.academic.equivalences.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newEquivalences"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="equivalences"
              :clear-selected="clearSelected"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @searchAction="searchAction"
              @deleteData="(id)=>deleteEquivalencesPopUp(id)"
              @updateData="(id)=>{updateEquivalences(id)}"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$equivalences} from '../Services';


export default {
  name: 'EquivalencesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      clearSelected: null,
      createNewEquivalencesStatus: false,
      equivalences: [],
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      },
      sort: {
        sortBy: 'id',
        sortDesc: false,
      },
      headers: [  
        {
          text: this.$t('modules.academic.equivalences.table.headers.idAsignaturaOld'),
          value: 'oldSubjectId'
        },
        {
          text: this.$t('modules.academic.equivalences.table.headers.idAsignaturaNew'),
          value: 'newSubjectId'
        },{
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.equivalences.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.equivalences.READ || this.$permissions.portaladministrativo.academic.equivalences.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.academic.equivalences.DELETE;
    },
  },
  methods: {
      newEquivalences() {
      if (this.canCreate) this.$router.push('/academic/equivalences/create');
    },
  updateEquivalences(id) {
      if (this.canUpdate) this.$router.push(`/academic/equivalences/update/${id}`);
    },
    async fetchData() {
      if (this.loading) return false;
      this.clearSelected = new Date().getMilliseconds().toString();
      this.loading = true;
      try {
        const {data} = await $equivalences.filter(null, { params: { 
            page: this.pagination.page - 1, 
            length: this.pagination.limit,
            orderBy: this.sort.sortBy,
            orientation: this.sort.sortDesc ? 'desc' : 'asc',
            search: this.search
                        } 
                    });
        this.totalItems = data.totalElements;
        this.equivalences = data.content
        this.pagination.pagesAmount = data.totalPages;
      } catch (err) {
        this.equivalences = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    async deleteEquivalences (id) {
      try {
          await $equivalences.delete(id);
          this.$store.dispatch('commons/openPopUp', {
              title: this.$t('modules.academic.equivalences.table.success'),
              content: [{value: this.$t('modules.academic.equivalences.table.success_message')}],
              actionPrimary: {text: this.$t('actions.accept'), callback() {}},
              icon: {color: 'success', name: 'mdi-check'},
              color: 'primary',
          });
          this.equivalences = this.equivalences.filter(equivalences => equivalences.id !== id);
          
      } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
              title: 'Error',
              content: [{value: this.$t('modules.academic.equivalences.table.delete_error')}],
              actionPrimary: {text: this.$t('actions.accept'), callback() {}},
              icon: {color: 'error', name: 'mdi-close'},
              color: 'primary',
          });
          throw error
      }
      },
      deleteEquivalencesPopUp(id){
        const equivalences = this.equivalences.find(equivalences => equivalences.id == id);
        this.$store.dispatch('commons/openPopUp', {
            title: this.$t('delete_popup_title'),
            content: [{value: equivalences.newSubjectId.code}],
            actionPrimary: {text: this.$t('actions.accept'), callback: () => this.deleteEquivalences(id)},
            actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
            icon: {color: 'error', name: 'mdi-delete'},
            color: 'primary',
        })
      },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  }
};
</script>