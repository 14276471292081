<template>
  <v-card class="px-4" outlined style="background-color: #f9f9f9" :loading="loading">
    <template v-slot:progress="">
      <v-row >
        <v-col cols="12" class="pa-6 text-center">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="hasPeriodsAvailable">
      <v-col class="pb-0" >
        <h3>{{$t('modules.portalalumno.procedures.reEnrollmentStatus.contractPeriodSelection.period')}}</h3>
        <span class="caption">{{$t('modules.portalalumno.procedures.reEnrollmentStatus.contractPeriodSelection.subtitle')}}</span>
      </v-col>
    </v-row>
    <v-row >
      <v-col v-if="!loading && !hasPeriodsAvailable" cols="12" class="pa-6 text-center">
        <span>{{$t('modules.portalalumno.procedures.reEnrollmentStatus.contractPeriodSelection.hasNotPeriodsAvailable')}}</span>
      </v-col>
      <v-col v-else cols="12" md="6" v-for="(period, index) in periods" :key="index">
        <PeriodCard :period="period" :index="index" @input="onPeriodUpdate"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PeriodCard from './PeriodsSelectionSectionPeriodCard.vue';

export default {
  components: { PeriodCard },
  props: {
    loading: Boolean,
    periods: Array
  },
  data() {
    return {
      selectedPeriods: []
    }
  },
  computed: {
    hasPeriodsAvailable() {
      return this.periods.length > 0
    },
  },
  methods: {
    emitUpdatePeriods() {
      const periods = [...this.periods]
      const selectedPeriods = periods.filter(period => period.selected === true).map(period => { return {
        id: period.id,
        code: period.code,
        description: period.description,
        classStartDate: period.classStartDate,
        classEndDate: period.classEndDate
      } })
      this.$emit('input', selectedPeriods)
    },
    onPeriodUpdate(payload) {
      if (payload.action === 'select') {
        this.periods.forEach((period, index) => {
          if (index <= payload.index) {
            this.$set(this.periods, index, {...this.periods[index], ...{selected: true}})
          }
        })
        this.emitUpdatePeriods()
      } else {
        const lastSelectedPeriodIndex = this.periods.findLastIndex(period => period.selected === true)
        const arr = payload.index >= lastSelectedPeriodIndex
        if(arr) {
          this.$set(this.periods, payload.index, {...this.periods[payload.index], ...{selected: false}})
          this.emitUpdatePeriods()
        }
      }
      
    } 
  },
}
</script>

<style></style>