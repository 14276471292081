import Sections from '../Sections.vue'
import SectionsTable from '../Views/SectionsTable.vue';
import SectionsCreate from '../Views/SectionsCreate.vue';
import SectionsUpdate from '../Views/SectionsUpdate.vue';


const SectionsRoute =
    {
        path: '/sections',
        meta: {
            name: {
                en: 'Sections',
                es: 'Secciones'
            }
        },
        component: Sections,
        children: [
            {
                path: '',
                component: SectionsTable,
                meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'sections', permission: 'READ' }
            },
            {
                path: 'create',
                component: SectionsCreate,
                meta: { name: 'Sections Create', group: 'portaladministrativo.academic', path: 'sections', permission: 'CREATE' }
            },
            {
                path: 'update/:id',
                component: SectionsUpdate,
                meta: { name: 'Sections Update', group: 'portaladministrativo.academic', path: 'sections', permission: 'READ' }
            },
        ]
    };

export default SectionsRoute
