const practiceTermsTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Configuración de prácticas profesionales',
        description:'Administración de las prácticas profesionales',
      },
      headers: {
        initialDatePP: 'Fecha inicial',
        finalDate: 'Fecha final',
        lastApprovedExam: 'Último examen aprobado',
        enabled: 'Inscripción',
        management: 'Aplicativo',
        termDescription: 'Periodo'
      },
      actions: {
        errorDelete: 'Error al eliminar',
        enabledTrue: 'Activar inscripción',
        managementTrue: 'Activar aplicativo',
        enabledFalse: 'Desactivar inscripción',
        managementFalse: 'Desactivar aplicativo',
      },
      messages: {
        deleteError: 'No se pudo eliminar la práctica profesional seleccionada',
      }
    },
    create: {
      card: {
        initialDate: 'Fecha de inicio:',
        finalDate: 'Fecha de finalización:',
        examDate: 'Fecha del último examen aprobado',
        from: 'Desde *',
        until: 'Hasta *',
        lastApprovedExam: 'Fecha del último examen aprobado',
        dateLastApprovedExam: 'Fecha *'
      },
      breadcrumbs: {
        title: 'Nueva práctica profesional',
        description:'Crea una nueva práctica profesional',
      },
      codeSection: {
        title: 'Datos de la práctica profesional',
        subtitle: 'Asigna la información básica de la práctica profesional que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
        labels: {
          term: 'Periodo',
        },
      },
      conditionSection: {
        title: 'Aplica por',
        labels: {
          scholarship: 'Beca',
          average: 'Promedio'
        }
      },
      termSection: {
        title: 'Periodos',
        subtitle: 'Selecciona en esta sección los periodos donde este práctica profesional puede ser aplicado',
        transferList: {
          firstTitle: 'Disponibles',
          secondTitle: 'Seleccionados'
        }
      },
      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentage: 'Porcentaje',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      errors: {
        initialDateRequired: 'Ingresa una fecha de inicio',
        finalDateRequired: 'Ingresa una fecha de finalización',
        lastApprovedExamRequired: 'Ingresa la fecha del último examen aprobado',
        datePair: 'Fecha inválida',
        termDescriptionRequired: 'Ingresa el periodo de la práctica profesional',
      },
      successMessage: {
        title: 'Práctica profesional creada correctamente',
      },
      errorMessage: {
        title: 'Ocurrió un error al crear la práctica profesional',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar práctica profesional',
        description: 'Modifica la práctica profesional seleccionada',
        subtitleUpdate: 'Asigna la información básica de la práctica profesional que estás editando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda' ,
      },
      conditionSection: {
        title: 'Aplica por',
        labels: {
          scholarship: 'Beca',
          average: 'Promedio'
        }
      },
      dayRangeSection: {
        title: 'Días y porcentajes',
        labels: {
          percentage: 'Porcentaje',
          dayFrom: 'Día Inicio',
          dayTo: 'Día Fin'
        }
      },
      successMessage: {
        title: 'Práctica profesional modificada correctamente',
        newChange: 'Hacer otro cambio',
      },
      errorMessage: {
        title: 'Ocurrió un error al modificar la práctica profesional',
      }
    },
  },
  en: {

  }
};


export default practiceTermsTranslations
