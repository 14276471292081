import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class BillingProps extends Api {
  constructor(http, config = {}) {
    super(http, url, 'billing-props', config);
  }

  async findPriceByCarrerAndCity(apiMethod = null, config = {}) {
    config = { ...this._config, ...config };

    return this._http.get(`${url}${apiMethod}`, config);
  }
}
