<template>
  <div class="calendar-container mx-auto">
    <vc-date-picker
      class="calendar-container__calendar capitalize"
      :locale="{id: 'es', firstDayOfWeek: 1}"
      :attributes=attributes
      v-model="selectedDate"
      :available-dates="dates"
      :select-attribute="selectAttribute"
      @update:from-page="getPage"
      @transition-start="dateButton.visible = false"
      @transition-end="$emit('getDates')"
      @dayclick="$emit('dateSelection', selectedDate)"
    >
      <template v-slot:footer>
        <div class="pb-5 calendar-caption rounded-b-xl d-flex decapitalize">
          <div class="text-caption caption-font">
            <v-icon color="#EBEFF3">mdi-checkbox-blank</v-icon>
            {{ $t('modules.portalalumno.exams.dateStep.calendarCaption.notAvailableDate') }}
          </div>
          <div class="text-caption caption-font">
            <v-icon color="primary">mdi-checkbox-blank</v-icon>
            {{ $t('modules.portalalumno.exams.dateStep.calendarCaption.selectedDate') }}
          </div>
        </div>
      </template>
    </vc-date-picker>
  </div>
</template>

<script>
  export default {
    name: 'ExamsCalendar',
    props: {
      dates: Array,
      dateButton: Object
    },
    data() {
      return {
        selectedDate: null,
        selectAttribute: {
          highlight: {
            fillMode: 'solid',
            style: {
              backgroundColor: '#012169',
              borderRadius: '4px',
            },
          },
          order: 3
        },
        attrs: [
          {
            key: 'available',
            highlight: {
              style: {
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
              },
              contentStyle: {
                color: '#707070',
              },
            },
            dates: [],
            order: 2
          }, {
            key: 'disabled',
            highlight: {
              fillMode: 'solid',
              style: {
                backgroundColor: '#EBEFF3',
                borderRadius: '4px',
              },
              contentStyle: {
                color: '#CCD6E0',
              },
            },
            dates: {days: [...Array(32).keys()]},
            order: 1
          }, {
            key: 'today',
            highlight: {
              fillMode: 'solid',
              style: {
                backgroundColor: this.$vuetify.theme.themes.light.secondary.base, //'#0097FF',
                borderRadius: '4px',
              },
            },
            dates: new Date(),
            order: 2
          }
        ],
      };
    },
    methods: {
      getPage(page) {
        this.$emit('getMonthAndYear', page.month, page.year);
      },
    },
    computed: {
      attributes() {
        const { attrs } = this;
        attrs[0].dates = this.dates;
        return attrs;
      }
    },
    watch: {
      dateButton: {
        handler(val) {
          if (!val.visible) this.selectedDate = null;
        },
        deep: true
      }
    }
  };
</script>

<style lang="sass" scoped>

.capitalize
  text-transform: capitalize

.decapitalize
  text-transform: none

.calendar-caption
  background-color: white
  justify-content: space-evenly
  border: none

.caption-font
  color: #707070
  font-size: 0.7rem !important

.calendar-container
  width: 300px

  &__calendar
    width: 100%

@media only screen and (max-width: 600px)
  .calendar-container
    flex-direction: column
    width: 100%

</style>