import BannersTable from '../Views/BannersTable.vue';
import BannersCreate from '../Views/BannersCreate.vue';
import BannersUpdate from '../Views/BannersUpdate.vue';

const BannersRoute = [
  {
    path:'',
    component: BannersTable,
    meta: { name: 'Index', group: 'portaladministrativo.messaging', path: 'banners', permission: 'READ' }
  },
  {
    path:'create',
    component: BannersCreate,
    meta: { name: 'BannersCreate', group: 'portaladministrativo.messaging', path: 'banners', permission: 'CREATE' }
  },
  {
    path: 'update/:id',
    component: BannersUpdate,
    meta: { name: 'BannersUpdate', group: 'portaladministrativo.messaging', path: 'banners', permission: 'READ' }
  },
]

export default BannersRoute
