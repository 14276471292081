import NotFoundState from '@/components/NotFoundState/NotFoundState'
import AcademicChargeView from './AcademicCharge';
import Subjects from './Subjects/Subjects';
import SubjectsRoute from './Subjects/Routes';
import StudyPlan from './StudyPlan/StudyPlan';
import StudyPlanRoute from './StudyPlan/Routes';

const AcademicChargeRoute = {

    path: '/academic-charge',
    meta: {
        name: {
            en: 'Academic charge',
            es: 'Carga académica'
        },
    },
    component: AcademicChargeView,
    children:[
        {
            path: '/',
            component: NotFoundState,
        }, {
            path:'subjects',
            meta: { name: { es: 'Asignaturas', en: 'Subjects' } },
            component: Subjects,
            children: SubjectsRoute
        }, {
            path:'studyPlan',
            meta: { name: { es: 'Plan de estudio', en: 'Subjects' } },
            component: StudyPlan,
            children: StudyPlanRoute
        }
    ]
};

export default AcademicChargeRoute
