import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class SystemValidation extends Api {
   constructor(http, config = {}) {
    super(http, url, 'system-validation', config);
   }
}
