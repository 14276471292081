import SistemaQExamBoard from '../SistemaQExamBoard'
import SistemaQExamCalendar from '../Views/SistemaQExamCalendar';
import SistemaQExamCreate from '../Views/SistemaQExamCreate'
import SistemaQExamDetail from '../Views/SistemaQExamDetail'

const SistemaQExamBoardRoute =
{
  path: '/exam-board',
  meta: {
    name: {
      en: 'exam-board',
      es: 'Mesas de examen',
      }
    },
  component: SistemaQExamBoard,
  children: [
    {
      path: '/',
      component: SistemaQExamCalendar,
      meta: { name: 'Index', group: 'portaladministrativo.exams', path: 'creation', permission: 'READ' }
    },
    {
      path: 'create',
      component: SistemaQExamCreate,
      meta: { name: 'SistemaQExamCreate', group: 'portaladministrativo.exams', path: 'creation', permission: 'CREATE' }
    },
    {
      path: 'detail/:id',
      name: 'detailExamBoard',
      component: SistemaQExamDetail,
      meta: { name: 'SistemaQExamDetail', group: 'portaladministrativo.exams', path: 'creation', permission: 'READ' }
    },

  ]
};

export default SistemaQExamBoardRoute
