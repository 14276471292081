export default {

  SET_ERRORLOGIN (state, message) {
    state.errorLogin = message
  },
  CLEAR_ERRORLOGIN (state) {
    state.errorLogin = ''
  },

  SET_RECOVERYINFO (state, recoveryData) {
    state.recoveryData = { ...recoveryData };
  },
  SET_VALIDATIONCODE (state, validationCode) {
    state.recoveryData = { ...state.recoveryData, validationCode };
  },
}
