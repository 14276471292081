<template>
    <v-dialog v-model="modalOpen" max-width="600px" @click:outside="closeDialog" content-class="modal-content">
        <v-card class="px-5 py-6">
            <v-card-title class="headline">{{ `${selectedSubject.subject.code} - ${selectedSubject.subject.descripcion}` }}</v-card-title>
            <!-- <v-divider class="my-0"></v-divider> -->
            <v-card-text class="pb-0 pt-3">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="selectedSubject.electiveSelected"
                            :items="selectedSubject.electives"
                            :item-text="(item) => `${item.code} - ${item.description}`"
                            item-value="id"
                            label="Asignatura seleccionada"
                            return-object
                            outlined
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="d-flex justify-end ma-0 mt-6 pa-0">
                <v-spacer></v-spacer>
                <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="closeDialog"
                    class="mr-4"
                ></Button>
                <Button
                    :text="$t('actions.save')"
                    @clicked="save"
                ></Button> 
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Button from '@/components/Button/Button';

    export default {
        components:{
            Button,
        },
        name:'ElectiveSubjectPopUp',
        props:{
            selectedSubject: Object,
            modalOpen: Boolean,
        },
        methods:{
            closeDialog() {
                this.$emit('cancel')
            },
            save() {
                this.$emit('save', this.selectedSubject.electiveSelected)
            },
        },
    }
</script>