import PaymentStudents from './PaymentsStudents/Payments.vue';
import PaymentsStudentsRoutes from './PaymentsStudents/Routes'
import CheckingAccount from './CheckingAccount/CheckingAccount.vue'
import CheckingAccountRoute from './CheckingAccount/Routes';
import PaymentsView from './Payments.vue';

const paymentRoutes = {
  path: 'payments',
  meta: {
    name: {
      en: 'Payments',
      es: 'Pagos'
    },
  },
  component: PaymentsView,
  children: [
        {
        path: '/',
        meta: {name: {es: 'Pagos', en: 'Payments'}},
        component: PaymentStudents,
        children: PaymentsStudentsRoutes
      }, {
        path: 'checking-account',
        meta: {name: {es: 'Cuenta corriente', en: 'Checking account'}},
        component: CheckingAccount,
        children: CheckingAccountRoute,
      }
  ]
};

export default paymentRoutes
