<template>
  <v-container>
  <v-card class="mx-auto mb-0 px-6 d-flex flex-row align-center justify-space-between" width=100% outlined>
    <div class="d-flex flex-row align-center">
      <span class="caption">DOMINIO</span>
      <v-text-field v-model="application.domain" outlined width="387" height="40" class="ml-6 mt-6 inputwidth">
      </v-text-field>
    </div>
    <v-switch v-model="application.status" color="primary darken-3" class="mr-9"></v-switch>
  </v-card>
  <v-container>
    <draggable :myData="myData">
      <template v-for="(data, i) in myData">
        <v-row :key="i">
          <AppComp :data="data"></AppComp>
        </v-row>
      </template>
    </draggable>
  </v-container>
  <div class="text-right">
    <v-btn rounded color="primary darken-3" class="body-2 font-weight-bold" height="47" width="103">Guardar</v-btn>
  </div>
</v-container>
</template>

<script>
import AppComp from '@/components/AppComp/AppComp'
import draggable from 'vuedraggable'

export default {
  name: 'Application',
  display: 'Simple',
  order: 0,
  components: {
    AppComp,
    draggable
  },
  props: {
    myData: Array,
  },
  data () {    return {
      application: {
        domain: '',
        status: true
      }
    }  }
}
</script>


<style lang="sass">
    .inputwidth
        min-width: 380px
</style>
