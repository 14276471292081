<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="">
        <Breadcrumbs
          :title="$t('modules.commissions.objectives.upload.breadcrumbs.title')"
          :description="
            $t('modules.commissions.objectives.upload.breadcrumbs.description')
          "
        ></Breadcrumbs>
        
        <template v-if="!isProcessed">
          <v-col cols="5">
            <v-card outlined v-if="hasIdReviewedItems">
              <v-list class="files-list">
                <v-list-item
                  v-for="(item, i) in identificationDocsGiven"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.filename }}</v-list-item-title>
                  </v-list-item-content>

                  <div class="d-flex align-center">
                    <v-icon class="mr-2" :class="item.statusClass">{{
                      item.statusIcon
                    }}</v-icon>
                    {{ item.statusText }}
                  </div>
                </v-list-item>
              </v-list>
            </v-card>
            <a href="/templates/Template-ObjtivesSellers.xlsx" download>
              <Button icon="mdi-download" :text="$t('actions.download_template')" class="mr-2" depressed outlined></Button>
            </a>
            <FileUploader
              formatsAccepted=".xlsx, .xls, .xlt"
              :title="$t('modules.conciliation.create.title')"
              :subtitle="$t('modules.conciliation.create.subtitle')"
              @action="process"
              :files.sync="identificationDocs"
              v-if="showIdentificationDocs"
            />
          </v-col>
        </template>
        <template v-else-if="loading">
          <div class="text-center">
            <v-progress-circular
              :size="90"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-else>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <v-card outlined class="pa-5" max-width="400px">
                <h4 class="">
                  {{
                    $t("modules.conciliation.create.cardDescription.document")
                  }}
                </h4>
                <div class="d-flex justify-space-between mt-3 mb-3">
                  <div class="subtitle font-weight-bold">
                    {{
                      identificationDocs[0] ? identificationDocs[0].name : "0"
                    }}
                  </div>
                  <div>
                    <v-icon class="mr-2" color="green">mdi-check-circle</v-icon
                    >{{ $t("modules.conciliation.create.processed") }}
                  </div>
                </div>
              </v-card>
              <v-data-table 
                :headers="headers"
                :items="statusFile"
                class="mt-5 elevation-1"
                :items-per-page="50"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{row.item.fileNumber}}</td>
                    <td>
                      <v-icon v-if="row.item.created" class="mr-2" color="green">mdi-check-circle</v-icon>
                      <v-icon v-else class="mr-2" size="19" color="red">mdi-alert-circle-outline</v-icon>
                    </td>
                    <td>{{row.item.idRange}}</td>
                    <td>{{row.item.sellerCode}}</td>
                    <td>{{row.item.objetiveAmount}}</td>
                    <td>{{row.item.studentTypeValue}}</td>
                    <td>{{row.item.careerCode}}</td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <div class="d-flex justify-end">

                <Button
                  :text="$t('modules.conciliation.create.buttonAccept')"
                  :errorText="'Error'"
                  @clicked="redirect"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import FileUploader from '@/components/FileUploader/FileUploader';
import constants from '@/plugins/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'ObjectivesUpload',
  components: {
    Breadcrumbs,
    Button,
    FileUploader
  },
  data() {
    return {
      hasIdReviewedItems: null,
      identificationDocs: [],
      showIdentificationDocs: [],
      isProcessed: false,
      conciliationItem: [],
      processedItems: [],
      noProcessedItems: [],
      loading: false,
      statusFile: [],
      headers: [
        { text: this.$t('modules.commissions.objectives.upload.headers.fileNumber'), class: 'primary--text', value: 'fileNumber' },
        { text: this.$t('modules.commissions.objectives.upload.headers.created'), class: 'primary--text', value: 'created' },
        { text: this.$t('modules.commissions.objectives.upload.headers.idRange'), class: 'primary--text', value: 'idRange' },
        { text: this.$t('modules.commissions.objectives.upload.headers.sellerCode'), class: 'primary--text', value: 'sellerCode' },
        { text: this.$t('modules.commissions.objectives.upload.headers.objetiveAmount'), class: 'primary--text', value: 'objetiveAmount' },
        { text: this.$t('modules.commissions.objectives.upload.headers.studentTypeValue'), class: 'primary--text', value: 'studentTypeValue' },
        { text: this.$t('modules.commissions.objectives.upload.headers.careerCode'), class: 'primary--text', value: 'careerCode' },
      ],
      items: []
    };
  },
  computed: {
    ...mapGetters(['commons/getUserData', 'commons/getUserID'])
  },
  methods: {
    process(event) {
      this.isProcessed = true;
      this.identificationDocs = event;
      this.sendDocumentation();
    },
    async sendDocumentation() {
      this.loading = true;
      const user = this['commons/getUserData'].userId;
      const formData = new FormData();
      formData.append('userId', user);
      formData.append('file', this.identificationDocs[0]);

      const token = localStorage.getItem('access_token')
        ? JSON.parse(localStorage.getItem('access_token'))
        : '';
      const options = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: formData
      };

      try {
        let repsonse = null;
        repsonse = await fetch(
          `${constants.URL_BASE}/commission/rangeObjetive/file`,
          options
        );
        this.statusFile = await repsonse.json();
      } catch (error) {
        this.loading = false;
        throw error
      } finally {
        this.loading = false;
      }
    },
    getColor(value) {
      if (value < 0) return 'red--text';
      else return value;
    },
    getText(value) {
      if (value < 0) return `( ${value} )`;
      else return value;
    },
    redirect() {
      this.$router.push('/commissions/objectives');
    }
  },
  mounted() {}
};
</script>

<style lang='sass'>

.header-line
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)

.text--muted
  color: rgba(0, 0, 0, 0.35)

.check-icon
  background: #dae2f4

</style>