<template>
  <div v-if="disableButtons">
    <v-combobox
      v-model="$v.select.$model"
      :error-messages="($v.select.$anyDirty && $v.select.$invalid) ? errorMessages : null"
      item-text="name"
      return-object
      :loading="loading"
      :items="items"
      :label="label"
      :search-input.sync="search"
      :error="error"
      flat
      outlined
      @blur="saveInput"
      required
    ></v-combobox>
  </div>
  <div v-else class="independentSelect">
    <v-combobox @click="hide = false" @blur.native="cancelInput"
      v-model="select"
      item-text="name"
      return-object
      :loading="loading"
      :items="items"
      :label="label"
      :search-input.sync="search"
      :error="error"
      :error-messages="errorMessages || error_message"
      class="independentSelect__input"
      flat
      outlined
      :disabled="disabled"
      required
    ></v-combobox>
    <div class="independentSelect__actions">
      <v-btn small class="px-0" v-show="!hide" @click="saveInput">
        <v-icon small>mdi-check</v-icon>
      </v-btn>
      <v-btn small class="px-0" v-show="!hide" @click="cancelInput">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import api from '@/api';
const { $core } = api;
const { $academic } = api;
export const { $citiesWithState } = $core;
export const { $teachers } = $academic;
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'AutocompleteTeacher',
  props: {
    disableButtons: Boolean,
    label: String,
    error: Boolean,
    errorMessages: String,
    list: [],
    value: [Object, String],
    searchTeacher: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: { name: '' },
      originalData: {},
      text: '',
      state: [],
      hide: true,
      error_message: this.$t('components.autocompleteInput.isRequired'),
    }
  },
  validations: {
    select: {
      name: { required }
    }
  },
  watch: {
    search (val) {
      if(val !== null && val !== undefined) {
        val && val !== this.select && this.querySelections(val)
      }
    },
    value(val) {
        this.select =  val; 
        this.$emit('update:value', this.select);
        this.text = val?.name || ''
    }
  },
  mounted () {
    if (this.value) {
        this.originalData = Object.keys(this.value).reduce(
          (accumulator, current) => {
            accumulator[current] = null; 
            return accumulator
          }, {});
      if(this.value !== null) {
        if(this.value.idCity !== null){
          this.select = this.value
        } else {
          this.select = null
        }
      } else {
        this.select = this.value
      }
    }
    if (this.list) {
      this.items = this.list
    }
  },
  methods: {
    async querySelections (v) {
      if(!v.match('[0-9]+')) {
        if(this.searchTeacher) {
          if (v.length < 2) { this.items = []; return; }
          if (v === this.select) return;
        } else {
          if (v.length < 3) { this.items = []; return; }
          if (v === this.select) return;
        }
      }
      this.loading = true;
      this.items.length = 0
      try {
        if(this.searchTeacher) {
          let teachers = []
          if (v.match('[0-9]+')) {
            teachers = await $teachers.find(null, null);
            this.items = teachers.data.content.length ? teachers.data.content.map(teacher => {
              teacher.name = `${teacher.user.userData.name} ${teacher.user.userData.lastname}`
              return teacher;
            }) : []
            this.$emit('update:value', v)
          } else if(v !== null && v!==undefined) {
            teachers = await $teachers.find(null, null, {params: {rolName: 'Docente', name: `${v}`}});
            this.items = teachers.data.content.length ? teachers.data.content.map(teacher => {
              teacher.name = `${teacher.user.userData.name} ${teacher.user.userData.lastname}`
              return teacher;
            }) : []
          }
        } else {
          const cities = await $citiesWithState.find(null, null, { params: { city: v } });
          this.items = cities.data;
        }   
      } finally {
        this.loading = false;
      }
    },
    cancelInput () {
      this.select = this.value ? this.value.name : this.search
      this.hide = true;
    },
    saveInput () {
      if(this.search === '') {
        this.select = null
        this.$emit('update:value', this.select)
        this.$emit('save', this.select);
        this.$v.$reset()
        this.$v.$touch()
        this.hide = true;
      } else if(this.search === null ){
        this.select = null
        this.$emit('update:value', this.select)
        this.$v.$reset()
        this.$v.$touch()
        this.hide = true;
      } else if (this.search) {
        if(this.searchTeacher) {
          this.$emit('update:value', this.select)
        } else {
          this.$emit('update:value', this.select)
        } 
        this.hide = true;
      } else {
        this.cancelInput();
      }
    }
  },
}
</script>