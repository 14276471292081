export default {
  es: {
    noFiles: 'No hay archivos seleccionados',
    addFiles: 'Agregar archivo',
    process: 'Procesar'
  },
  en: {
    noFiles: 'No files selected',
    addFiles: 'Add files',
    process: 'Process'
  }
}
