<template>
    <v-container v-if="user" fluid class="pa-0 pb-8 dashboard-bg fullHeight d-flex align-center" :style="`background-image: url(${require('@/assets/dashboard-background.svg')})`">
        <v-container class="container-custom viewSpaces d-flex align-center">
            <v-row>
                <v-col sm="5">
                    <h1 class="display-2 primary--text my-4 text-capitalize">
                        {{ $t('modules.portalalumno.dashboard.home.no_student.title', {name: user.name}) }}
                    </h1>
                    <h2 class="body-1">
                        {{ $isGestor ? $t('modules.portalalumno.dashboard.home.no_student.subtitle') : $t('modules.portalalumno.dashboard.home.message') }}
                    </h2>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'DashboardView',
        computed: {
            ...mapGetters({
                user: 'commons/getUserData',
                student: 'commons/getStudentData',
            }),
        },
        created() {
            if (this.student) this.$router.push({ name: 'DashboardStudent' })
        }
    }
</script>

<style scoped lang="sass">
    .dashboard-bg
        background-color: unset !important
        background-size: contain
        background-repeat: no-repeat
        background-position: right top
</style>
