<template>
  <v-row v-if="step != '1' || datosCompany.estadoPPId != 267 || !datosCompany.validacion">
    <v-list>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.reason'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.razonSocial }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.address'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.direccion }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_position'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.cargoReferente }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_name'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.contacto }}
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.cuit'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.cuit }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.tel'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.telefono }}
      </v-list-item>
      <v-list-item>
        <span class="font-weight-bold">
          {{
            $t(
              'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.email'
            )
          }}: &nbsp;
        </span>
        {{ datosCompany.email }}
      </v-list-item>
    </v-list>
  </v-row>
  <v-row v-else class="py-1">
    <DatosCompanyForm
      :newPracticaEmpresa="newPracticaEmpresa"
      :newPracticaEmpresaFlagChild.sync="newPracticaEmpresaFlagChild"
      :datosCompany="datosCompany"
    />
  </v-row>
</template>

<script>
import DatosCompanyForm from './DatosCompanyForm';

export default {
  name: 'DatosCompanyListGroup',
  props: [
    'datosCompany',
    'newPracticaEmpresa',
    'newPracticaEmpresaFlag',
    'step',
  ],
  components: {
    DatosCompanyForm,
  },
  computed: {
    newPracticaEmpresaFlagChild: {
      get() {
        return this.$emit('update:newPracticaEmpresaFlag', true);
      },
      set(value) {
        return value;
      },
    },
  },
};
</script>
