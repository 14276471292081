const reservations = {
    title: 'Pedido de examen',
    description: 'Este reporte muestra todos los pedidos de examen de todas las asignaturas correspondientes al período seleccionado con su respectivo proctoring.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateReservationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Se muestran sólo los exámenes en estado de "pedido".' },
        { label: 'Criterios', value: 'El período seleccionado corresponde al de cursada de la asignatura. Se muestra el proctoring original y si hubo algún cambio.' },
    ],
    example: {
        header: [
            'Legajo',
            'DNI',
            'Estudiante',
            'Email',
            'Carrera',
            'Asignatura',
            'Período',
            'Fecha reserva',
            'Bloque reserva',
            'Hora inicio',
            'Hora fin',
            'Estado',
            'Nota',
            'Aprobado',
            'Cant. Intentos',
            'Tipo excepción',
            'Motivo excepción',
            'Proctoring pedido',
            'Proctoring mesa',
        ],
        data: [
            [
                '20212AGHOT0003',
                '27417590',
                'Silvana Sguera',
                'silvanalsguera@gmail.com',
                'GHOT - Gestión Hotelera',
                'ESP68 - COMERCIALIZACIÓN  Y REVENUE MANAGEMENT',
                '2B/2022',
                '2022-12-30 21:02:32.506-03',
                '2023-01-23',
                '20:00:00',
                '21:00:00',
                '61 - Pedido',
                'null',
                '',
                '1',
                'null',
                'null',
                'Klarway',
                'Klarway',
            ],
            [
                '20212APNET0012',
                '35864012',
                'Andrés Ignacio Florio',
                'andres_florio@hotmail.com',
                'PNET - Programación',
                'ESP41 - PROGRAMACIÓN MOBILE',
                '2B/2022',
                '2022-12-05 12:50:10.041-03',
                '2022-12-16',
                '12:00:00',
                '18:00:00',
                '61 - Pedido',
                '7.0',
                'SI',
                '1',
                'null',
                'null',
                'Klarway',
                'Klarway',
            ],
        ],
    },
}

const nonreservations = {
    title: 'Sin pedido de examen',
    description: 'Este reporte muestra todos las materias de los estudiantes que no tienen pedido de examen activo.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateNonReservationsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Para la asignatura mostrada no deben existir pedidos de examen con fecha posterior a la actual. El tipo de examen puede ser normal de 22 o 30 preguntas o complejo de 9. Si la modalidad del estudiante es actual, la asignatura debe estar en estado "regular", pero si la modalidad es vieja puede ser "regular", "en curso" o "desaprobada". El estudiante no debe estar en nunguno de los estados: "baja", "anulado", "eliminado por no matricula", "eliminado por no carga académica".' },
        { label: 'Criterios', value: 'El período seleccionado corresponde al de cursada de la asignatura. ' },
    ],
    example: {
        header: [
            'Estudiante',
            'DNI',
            'Email',
            'Legajo',
            'Estado',
            'Codigo Asignatura',
            'Asignatura',
            'STUDENT_SUBJECT_ID',
            'Período',
            'Intentos',
            'Telefono',
        ],
        data: [
            [
                'Carlos GonzaloGimenez',
                '33880160',
                'gimenezberbesg@gmail.com',
                '20221APNET0235',
                'Regular',
                'ESP27',
                'PROGRAMACIÓN WEB',
                '4846822',
                '2B/2022',
                '0',
                '+54 11 3345-4017',
            ],
            [
                'Celeste Haedo',
                '33305582',
                'miequiponuevo8717@gmail.com',
                '20222ARLAB0019',
                'Regular',
                'FUN19',
                'GESTIÓN DE PERSONAS',
                '4818630',
                '2B/2022',
                '1',
                '+54 2221 41-0607',
            ],
        ],
    },
}

const examenesInfo = {
    title: 'Datos de mesas de examen / Exámenes',
    description: 'Este reporte muestra todos los exámenes rendidos o por rendir con fecha no mayor a 1 mes antes o después de la fecha actual.',
    detail: [
        { label: 'Archivo', value: 'Reporte examenesinfo.xlsx' },
        { label: 'Países', value: '<span class="country-chip argentina">Argentina</span> <span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Los exámenes rendidos deben tener hora de inicio y los exámenes no rendidos no deben tener nota.' },
        { label: 'Criterios', value: 'Este reporte muestra los exámenes con fecha dentro del período 1 mes antes y 1 mes después de la fecha actual.' },
    ],
    example: {
        header: [
            'Nombre',
            'Apellido',
            'Nro Doc',
            'Carrera',
            'Inicio carrera',
            'Materia',
            'Periodo Materia',
            'Fecha pedido exámen',
            'Fecha mesa',
            'Mesa hora inicio',
            'Fecha exámen',
            'Estado',
            'Nota',
            'Examen Hora inicio',
            'Examen Hora Fin',
            'Proctoring actual',
            'Proctoring original',
            'Legajo',
            'Email',
            'Celular',
            'Teléfono',
            'Localidad',
            'Razón anulación',
            'Tipo de anulación',
            'Usuario anuló',
            'Fecha anulación',
            'Último periodo abonado',
        ],
        data: [
            [
                'AARON DIEGO',
                'VALDERRAMA FIGUEROA',
                '16115680',
                'ANALISTA DE SISTEMAS',
                'PRIMER BIMESTRE ACADÉMICO 2021',
                'ÉTICA PROFESIONAL',
                'CUARTO BIMESTRE ACADÉMICO 2022',
                '6/1/2023 19:28',
                '11/1/2023',
                '20:00:00',
                '11/1/2023',
                'Aprobado',
                '7.0',
                '11/1/2023 20:21',
                '11/1/2023 20:28',
                'Klarway',
                'null',
                '20211A0421P0030',
                'aaronvalderrama@gmail.com',
                '56948882063',
                'null',
                'PUDAHUEL',
                'null',
                'null',
                'null',
                'null',
                'PRIMER BIMESTRE ACADÉMICO 2022',
            ],
            [
                'ABIGAIL AÍDA',
                'FUENTEALBA ORELLANA',
                '16537849',
                'SERVICIO SOCIAL (PLAN ESPECIAL)',
                'PRIMER BIMESTRE ACADÉMICO 2022',
                'ENFOQUE DE DERECHOS APLICADO AL SERVICIO SOCIAL',
                'CUARTO BIMESTRE ACADÉMICO 2022',
                '27/12/2022 02:24',
                '2023-01-10',
                '20:00:00',
                '2023-01-10',
                'Anulado',
                'null',
                'null',
                'null',
                'Klarway',
                'null',
                '20221A0610PE0053',
                'abigail.fuentetealba24@gmail.com',
                '+56973673279',
                '',
                'CONCHALI',
                'Estoy lejos de mi casa',
                'Se me presentó un inconveniente personal',
                '16537849',
                '2023-01-10 17:11:45.948',
                'TERCER BIMESTRE ACADÉMICO 2022',
            ],
        ],
    },
}

const consolidatedExams = {
    title: 'Consolidado Calificaciones',
    description: 'Este reporte muestra las notas de examen de cada materia de cada estudiante con las notas de módulos y actividades correspondientes.',
    detail: [
        { label: 'Archivo', value: 'Reporte generateConsolidatedExamsReport.xlsx' },
        { label: 'Países', value: '<span class="country-chip chile">Chile</span>' },
        { label: 'Filtros', value: 'Muestra asignaturas con y sin examen.' },
        { label: 'Criterios', value: 'Muestra a los estudiantes con modalidad 19. Y las asignaturas convalidadas u homologadas.' },
    ],
    example: {
        header: [
            'RUT',
            'Estudiante',
            'Carrera',
            'Asignatura',
            'Período asignatura',
            'Nota final',
            'Nota examen',
            'M1',
            'M2',
            'M3',
            'M4 - E1',
            'M4 - E2',
            'M4 - E3',
            'R19',
            'Aprobado',
            'Concepto',  
        ],
        data: [
            [
                '10002871-9',
                'SANDRA PATRICIA RUIZ VARGAS',
                '0320P - CONTADOR AUDITOR',
                '0320P85 - PRÁCTICA PROFESIONAL',
                '2A/2020',
                '7.0',
                '',
                '7',
                '7',
                '7',
                '7',
                '7',
                '6.7',
                'NO',
                'SI',
                'APROBADO',
            ],
            [
                '10001813-6',
                'DANIEL FAUNDEZ PARDO',
                '0380P - INGENIERÍA INDUSTRIAL',
                'TRA009 - HABILIDADES PARA LA COMUNICACIÓN ORAL Y ESCRITA',
                '1B/2022',
                '3.5',
                '',
                '5.56',
                '1',
                '6.28',
                '1',
                '-',
                '-',
                'NO',
                'NO',
                'DESAPROBADO',
            ],
        ],
    },
}


// documentations[this.reports.service][this.reports.url]

export default {reservations, nonreservations, examenesInfo, consolidatedExams  };
