// To use the store of an application you must do the following --> "import *app* from '../modules/*app*/store'"
//                                                              -->  add *app* in the object 'modules'


// Import modules stores

import commons from './commons'
import settings from '../modules/portaladministrativo/Settings/store'
import login from '../modules/LoginApplication/store'
import discounts from '../modules/portaladministrativo/Discounts/store'

// Define object

const modules = {
  commons,
  settings,
  login,
  discounts,
};

export default modules
