<template>
	<v-container>
		<v-tooltip top max-width="200">
			<template v-slot:activator="{ on, attrs }">
				<v-card 
					class="d-flex justify-space-between align-center pa-4 primary--text rounded-lg" 
					style="max-width:200px"
					v-bind="attrs" v-on="on"
				>
					<div class="d-flex flex-column" style="white-space: nowrap">
						<h5 class="pt-0">{{ data.description ? data.description : '' }}</h5>
						<h3 class="pb-0 font-weight-bold pl-0 black--text">
							{{ data.total ? data.total : 0 }}
						</h3>
						<h6 class="pt-0 text--secondary">
							de
							{{ data.totalPerStudent ? data.totalPerStudent : 0 }}
							Alumnos
						</h6>
					</div>
					<v-progress-circular
						:rotate="360"
						:size="70"
						:width="5"
						:value="data.averageByDoc"
						:color="'primary'"
					>
						{{ data.averageByDoc ? data.averageByDoc : 0 }} %
					</v-progress-circular>
				</v-card>
			</template>
			<span>{{$t(`modules.academic.documentation.panels.tooltip.${data.status}`)}}</span>
		</v-tooltip>
	</v-container>
</template>

<script>
export default {
	name: 'PanelDataTable',
	props: {
		data: { type: Object },
	},

	computed: {},
};
</script>

<style></style>
