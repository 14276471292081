import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class ExamsException extends Api {
    constructor(http, config = {}) {
        super(http, url, 'examenes', config);
    }

    async getAvailableStudents(config = {}) {
        config = { ...this._config, ...config };
        const uri = `${url}/students`
        return this._http.get(uri, config);
    }
}
