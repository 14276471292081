<template>
  <v-container fluid class="superTable">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('modules.prices.table.search.search')"
          outlined
          clearable
          class="superTable__search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :show-select="true"
          :headers="headers"
          :items="items"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
        >
          <template v-slot:header.data-table-select="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.status="{item}">
            <v-icon size="12" :color="(item.status) ? 'success' : 'grey lighten-2'">mdi-circle</v-icon>
          </template>

          <template v-slot:item.amount="{item}">
            <v-text-field
              class="no-message my-2"
              :error="item.invalid"
              outlined
              type="number"
              :min="0"
              v-model="item.model.amount"
              @keypress="$validateDecimalInputNumber($event, item.model.amount)"
              @change="val => changeDetail(item.id, val)"
            >
              <template v-slot:prepend>
                <span>{{ $countryConstants.currency }}</span>
              </template>
            </v-text-field>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span
          class="caption d-inline-block"
        >{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' '+ $t('modules.prices.table.search.of')+ ' ' + itemsCount.totalItems }}</span>
        <v-select
          v-model="itemsPerPage"
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          outlined
          class="superTable__paginationSelect d-inline-block ml-4"
        ></v-select>
      </v-col>
      <v-col sm="6" class="text-center">
        <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
      </v-col>
      <v-col sm="3" align="right">
        <v-text-field
          v-model="page"
          min="1"
          max="pageCount"
          outlined
          class="superTable__paginationInput d-inline-block mr-4"
        ></v-text-field>
        <span class="caption d-inline-block">{{$t('modules.prices.table.search.gotopage')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'SuperTable',
  props: {
    details: Object,
    headers: Array,
    clearSelected: String,
  },
  data () {    
    return {
      items: [],
      selectedItems: [],
      search: '',
      page: 1,
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      markedUsers: [],
    }  
  },
  methods: {
    editItem (id) {
      this.$emit('update', id);
    },
    detailsToArray () {
      for (const detail in this.details) {
        this.items.push({model: this.details[detail].$model,invalid: this.details[detail].$invalid});
      }
    },
    changeDetail (id, val) {
      for (const detail in this.details) {
        const data = this.details[detail].$model;
        if (data.id == id) {
          data.amount = val !== '' ? parseInt(val) : null;
          return 0
        }
      }
    }
  },
  mounted () {
    this.detailsToArray()
  },
  watch: {
    'page': function (val) {
      this.$emit('page', val)
    },
    'clearSelected': function () {
      this.selectedItems = []
    },
    'details': function () {
      this.detailsToArray()
    }
  },
  computed: {
    areItemsSelected () {
      return !!this.selectedItems.length
    },
    pageCount () {
      let counter = this.items.length / this.itemsPerPage;

      if (counter < 1) {
        counter = 1
      }

      return Math.ceil(counter)
    },
    itemsCount () {
      const counter = {
        totalItems: this.items.length,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.items.length) {
        counter.endItem = this.items.length
      }

      return counter
    }
  },
};
</script>

<style lang="sass" scoped>
.v-expansion-panels
    .v-expansion-panel-content
        padding-left: 10px
        padding-bottom: 0px
        padding-right: 16px
</style>
