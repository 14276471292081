<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom">
            <Breadcrumbs
                isDescriptionFullLength
                :title="$t('modules.portalalumno.profile.breadcrumbs.title')"
            />
            <outlinedCard
                :title="$t('modules.portalalumno.profile.perfilAlumno.title')"
            >
            <StudentCard 
                :id="$route.params.id"
                :avatar="alumno[0].avatar"
                :name="alumno[0].name"
                :career="alumno[0].career"
                :from="alumno[0].from" 
                :horizontal="true"
                v-if="alumno"
            />

            <v-card class="my-4">
                <v-card-title primary-title>
                    {{ $t('modules.portalalumno.profile.perfilAlumno.about') }}
                </v-card-title>
                <v-card-text>
                    {{socialMedia.comments}}
                </v-card-text>
                <v-card-actions class="d-flex row wrapper pa-4">

                    <a :href="socialMedia.facebook" target="_blank" class="mx-4 d-flex mt-2 mt-lg-0 align-center text-decoration-none" v-if="socialMedia.facebook">
                        <v-icon class="pa-0 mr-1  rounded-circle" color="#3d5a96" size="2.5rem" >mdi-facebook</v-icon> 
                        <div class="text-body-2" >{{ cutstr(socialMedia.facebook) }}</div>
                    </a>

                    <a :href="socualMedia.twitter" target="_blank" class="mx-4 d-flex mt-2 mt-lg-0 align-center text-decoration-none" v-if="socialMedia.twitter">
                        <v-icon class="pa-2 mr-1  twitter-bg rounded-circle" color="#FFF" size="20px" >mdi-twitter</v-icon> 
                        <div class="text-body-2">{{socialMedia.twitter}}</div>  
                    </a>

                    <a :href="socualMedia.linkedin" target="_blank" class="mx-4 d-flex mt-2 mt-lg-0 align-center text-decoration-none" v-if="socialMedia.linkedin">
                        <v-icon class="pa-2 mr-1  linkedin-bg rounded-circle" color="#FFF" size="20px" >mdi-linkedin</v-icon> 
                        <div class="text-body-2">{{ cutstr(socialMedia.linkedin) }}</div>
                    </a>

                    <a :href="socualMedia.instagram" target="_blank" class="mx-4 d-flex mt-2 mt-lg-0 align-center text-decoration-none" v-if="socialMedia.instagram">
                        <v-icon class="pa-2 mr-1  instagram-bg rounded-circle" color="#FFF" size="20px" >mdi-instagram</v-icon> 
                        <div class="text-body-2">{{ cutstr(socialMedia.instagram) }}</div>
                    </a>

                </v-card-actions>
            </v-card>
            </outlinedCard> 
            <v-row class="ma-0">
                <Button
                    :text="$t('actions.back')"
                    @clicked="$router.go(-1)"
                    white
                    outlined
                />
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import Button from '@/components/Button/Button';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import { $studentsData } from '../Services/index'
import constants from '@/plugins/constants';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard.vue';
import StudentCard from '../Components/StudentCard'
    export default {
        components: { StudentCard, Button, OutlinedCard, Breadcrumbs },
        name:'BuscadorAlumnoView',

        data() {
            return {
                alumno:null,
                socialMedia:{},
                idStudent:''
            }
        },
        methods: {
            async getSocial() {
                const params =  {}
                const {data} = await $studentsData.find(`${this.idStudent}/detail` , null, { params });
                this.socialMedia = {
                    comments: data.comments,
                    facebook: data.facebook,
                    id: data.id,
                    instagram: data.instagram,
                    linkedin: data.linkedin,
                    twitter: data.twitter,
                }
            },
            async getStudentsdata() {
                const params =  {userId:this.$route.params.id}
                const {data} = await $studentsData.find(null, null, { params });
                this.alumno = data.students.map( ({careerStudyPlan,student}) => {
                    this.idStudent = student.id
                    return {
                        id: String(student.user.id),
                        avatar: `${constants.URL_BASE}/core/users/${student.user.id}/avatar`,
                        name: `${student.user.userData?.name} ${student.user.userData?.lastname}`,
                        career:careerStudyPlan.career.description,
                        from:`${student.user.userData?.address.city || ''} ${student.user.userData?.address.region?.regionName || ''}`,
                    }
                })
            },
            cutstr(str){
                const pattern = new RegExp('((http|https)://)?(www.)?(facebook|twitter|instagram|linkedin).(com)','i');
                const posicionCut = []
                let withoutUrl = ''

                if(pattern.test(str)) withoutUrl = str.replace(pattern,' ')
                for (let index = 3; index < withoutUrl.length; index++) {
                    const element = withoutUrl[index];
                    if(element == '/' || element == '?' && pattern.test(str)){
                        posicionCut.push(index)
                    }
                }
                return withoutUrl.substr(0, posicionCut[0] ) || str
            }
        },
        async mounted () {
            await this.getStudentsdata()
            this.getSocial()
        },
    }
</script>

<style lang="sass" scoped>
    .container 
        max-width: 1185px !important
    .email-bg
        background-color:#2f75ec
    .facebook-bg
        background-color:#3d5a96
    .twitter-bg
        background-color:#2aa3ef
    .linkedin-bg
        background-color:#007ab7
    .instagram-bg
        background: #d6249f
        background: radial-gradient(circle at 18% 150%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)
</style>