<template>
    <div>
        <v-row class="px-3" v-if="!sendDocumentationStatus">
            <v-col cols="12">
                <div class="mx-5 my-3">
                    <h4 class="subtitle-1 font-weight-medium">{{ $t('modules.messaging.template.areaTemplates.sendMessage.drop_title') }}</h4>
                    <a href="/templates/envio-mensajeria.xlsx" download>
                        <Button
                            outlined
                            class="mt-4 mb-8"
                            icon="mdi-download"
                            :text="$t('modules.messaging.template.areaTemplates.sendMessage.download_template')"
                        ></Button>
                    </a>
                </div>
                <DropFiles
                    :key="dropFilesKey"
                    v-model="file"
                    class="mx-5 mt-5"
                    extension=".xls,.xlsx"
                    limitSizeBytes="2000000"
                    type-file="excel"
                ></DropFiles>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
                <Button
                    white
                    class="my-6"
                    :text="$t('actions.close')"
                    :disabled="sendButton.loading"
                    @clicked="closeConfirmation"
                ></Button>
                <Button
                    class="mx-5 my-6"
                    :loading="sendButton.loading"
                    :success="sendButton.success"
                    :error="sendButton.error"
                    :text="$t('actions.send')"
                    :successText="$t('actions.sent')"
                    :errorText="'Error'"
                    :disabled="!canSend"
                    @end="resetButtonValues"
                    @clicked="confirmationPopup"
                ></Button>
            </v-col>
        </v-row>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
        />
    </div>
</template>

<script>
    import DropFiles from '@/components/DropFiles/DropFiles';
    import Button from '@/components/Button/Button';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { $templates } from '../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'FileUpload',
        props: {
            template: { type: Object, default: null }
        },
        components: {
            DropFiles,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                sendDocumentationStatus: false,
                file: null,
                dropFilesKey: 0,
                sendButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: 'success',
                    title: '',
                    actionPrimary: { text: '', callback() {} },
                },
            }
        },
        computed: {
            ...mapGetters({
                userId: 'commons/getUserID',
            }),
            canSend() {
                return this.$permissions.portaladministrativo.messaging.templates.SEND
            },
        },
        methods: {
            confirmationPopup() {
                if (!this.file) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.messaging.template.areaTemplates.sendMessage.no_files'),
                        actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.messaging.template.areaTemplates.sendMessage.send_confirmation'),
                        content: [{ value: this.file.name }],
                        actionPrimary: { text: this.$t('actions.send'), callback: () => this.sendFile() },
                        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                }
            },
            async sendFile() {
                try {
                    this.sendButton.loading = true;

                    const formData = new FormData()
                    formData.append('file', this.file);
                    formData.append('userId', this.userId);

                    const response = await $templates.formData(formData, 'send', this.template.id)
                    const data = JSON.parse(await response.text())
                    
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.messaging.template.areaTemplates.sendMessage.success_message', { quantity: data.errorDetail });
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.close() } }
                    this.sendButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning && error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.messaging.template.areaTemplates.sendMessage.error_message');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.sendDocumentationStatus = false; }}
                    this.sendButton.error = true;

                    throw error
                } finally {
                    this.sendDocumentationStatus = true;
                    this.sendButton.loading = false;
                }
            },
            resetButtonValues() {
                this.sendButton.loading = false;
                this.sendButton.success = false;
                this.sendButton.error = false;
            },
            closeConfirmation() {
                if (this.file) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.messaging.template.areaTemplates.sendMessage.pending_files'),
                        actionPrimary: { text: this.$t('actions.yes'), callback: () => this.close() },
                        actionSecondary: { text: this.$t('actions.no'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-alert' },
                        color: 'primary',
                    });
                } else {
                    this.close()
                }
            },
            close() {
                this.$emit('close')
                setTimeout(() => {
                    this.file = null
                    this.dropFilesKey++
                    this.sendDocumentationStatus = false
                    this.resetButtonValues()
                }, 2000)
            },
        },
    }
</script>