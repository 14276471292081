<template>
  <v-container fluid class="d-flex pa-0 fullHeight">
    <v-container fluid class="scrollableContent pa-0">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :title="$t('modules.admission.preregistration.table.breadcrumbs.title')"
          :description="$t('modules.admission.preregistration.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newPreregistration"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :data="preregistrations"
              :page-count="pagination.pagesAmount"
              :page="pagination.page"
              :total-items="totalItems"
              :permissions="{update: canUpdate, delete: canDelete}"
              :sortBy.sync="sort.sortBy"
              :sortDesc.sync="sort.sortDesc"
              @searchAction="searchAction"
              @deleteData="deletePopUp"
              @updateData="updatePreregistration"
              @input-pagination="onInputPagination"
              @length-pagination-change="onLengthPaginationChange"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $preRegister } from '../Services';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'PreregistrationTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data() {
    return {
      loading: false,
      search: null,
      page: 1,
      totalItems: null,
      preregistrations: [],
      sort: {
        sortBy: '',
        sortDesc: false,
      },
      headers: [
        {
          text: this.$t('modules.admission.preregistration.table.headers.name'),
          value: 'name'
        }, {
          text: this.$t('modules.admission.preregistration.table.headers.identification'),
          value: 'identificacion'
        }, {
          text: this.$t('modules.admission.preregistration.table.headers.career'),
          value: 'career',
        }, {
          text: this.$t('modules.admission.preregistration.table.headers.startDate'),
          value: 'startDate',
          width: 100
        }, {
          text: this.$t('modules.admission.preregistration.table.headers.status'),
          value: 'status',
          align: 'center'
        }, {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      pagination: {
        limit: 20,
        page: 1,
        pagesAmount: 1
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.CREATE;
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.admission.pre_registration.READ || this.$permissions.portaladministrativo.admission.pre_registration.UPDATE;
    },
    canDelete() {
      return this.$permissions.portaladministrativo.admission.pre_registration.DELETE;
    },
    ...mapGetters({
      userId: 'commons/getUserID',
    })
  },
  methods: {
    deletePopUp(id) {
      const preregistration = this.preregistrations.find(preregistration => preregistration.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('modules.admission.preregistration.table.messages.deletePopup'),
        content: [{value: preregistration.name}],
        actionPrimary: {text: this.$t('actions.drop'), callback: () => this.deletePreregistration(id)},
        actionSecondary: {
          text: this.$t('actions.cancel'), callback() {
          }
        },
        icon: {color: 'error', name: 'mdi-delete'},
        color: 'primary',
      });
    },
    async fetchData() {
      if (this.loading) return false;
      this.loading = true;
      try {
        const params = {
          page: this.pagination.page - 1,
          length: this.pagination.limit,
          orderBy: this.sort.sortBy,
          orientation: this.sort.sortDesc ? 'desc' : 'asc',
          identification: this.search
        };
        const {data} = await $preRegister.find(null, null, {params});
        this.preregistrations = data.content.map(item => {
          item.startDate = moment(item.startDate).format('DD-MM-YYYY');
          item.id = item.academicRegister
          item.status = item.studentCareerStatus.meaning
          return item
        });
        this.totalItems = data.totalElements;
        this.pagination.pagesAmount = data.totalPages;
      } catch (err) {
        this.preregistrations = [];
        this.pagination.page = 1
        this.pagination.pagesAmount = 1
        this.totalItems = 0;
        throw err;
      } finally {
        this.loading = false;
      }
    },
    newPreregistration() {
      if (this.canCreate) this.$router.push('/admission/preregistration/create');
    },
    updatePreregistration(id) {
      if (this.canUpdate) this.$router.push(`/admission/preregistration/update/${id}`);
    },
    async deletePreregistration(id) {
      try {
        await $preRegister.update(id, {value: 56, user: this.userId});
        this.fetchData()
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.admission.preregistration.table.messages.deleteSuccess'),
          content: '',
          actionPrimary: {text: this.$t('actions.accept'), callback() {} },
          actionSecondary: null,
          icon: {color: 'success', name: 'mdi-check'},
          color: 'primary',
        });
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('actions.error'),
          content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.admission.preregistration.table.messages.deleteError')}],
          actionPrimary: {
            text: this.$t('actions.accept'), callback() {
            }
          },
          icon: {color: 'error', name: 'mdi-close'},
          color: 'primary',
        });
        throw error;
      }
    },
    searchAction(data) {
      this.pagination.page = 1;
      this.search = data;
      this.fetchData();
    },
    onInputPagination(event) {
      this.pagination.page = event;
      this.fetchData();
    },
    onLengthPaginationChange(event) {
      this.pagination = {
        page: 1,
        limit: event,
        pagesAmount: 1
      };
      this.fetchData();
    },
  },
  watch: {
    sort: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  }
};
</script>
