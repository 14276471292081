import ProfessionalPracticeTermsTable from '../Views/ProfessionalPracticeTermsTable';
import ProfessionalPracticeTermsCreate from '../Views/ProfessionalPracticeTermsCreate';
import ProfessionalPracticeTermsUpdate from '../Views/ProfessionalPracticeTermsUpdate'

const ProfessionalPracticeTermsRoute = [
  {
    path: '/',
    component: ProfessionalPracticeTermsTable,
    meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'professional_practice_terms', permission: 'READ' }
  }, {
    path: 'create',
    component: ProfessionalPracticeTermsCreate,
    meta: { name: 'ProfessionalPracticeTerms Create', group: 'portaladministrativo.academic', path: 'professional_practice_terms', permission: 'CREATE' }
  }, {
    path: 'update/:id',
    component: ProfessionalPracticeTermsUpdate,
    meta: { name: 'ProfessionalPracticeTerms Update', group: 'portaladministrativo.academic', path: 'professional_practice_terms', permission: 'READ' }
  },
];

export default ProfessionalPracticeTermsRoute