<template>
  <v-form
    width="100%"
    style="width:100%"
    :disabled="datosCompany.estadoPPId == 270 || datosCompany.estadoPPId == 269"
  >
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          type="text"
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.razonSocial.required
              ? $t('modules.academic.periods.create.errors.required')
              : ''
          "
          @blur="$v.newPracticaEmpresa.razonSocial.$touch()"
          v-model="newPracticaEmpresa.razonSocial"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.reason'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
        <v-text-field
          type="text"
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.direccion.required
              ? $t('modules.academic.periods.create.errors.required')
              : ''
          "
          @blur="$v.newPracticaEmpresa.direccion.$touch()"
          v-model="newPracticaEmpresa.direccion"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.address'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
        <v-text-field
          type="text"
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.cargoReferente.required
              ? $t('modules.academic.periods.create.errors.required')
              : ''
          "
          @blur="$v.newPracticaEmpresa.cargoReferente.$touch()"
          v-model="newPracticaEmpresa.cargoReferente"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_position'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
        <v-text-field
          type="text"
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.contacto.required
              ? $t('modules.academic.periods.create.errors.required')
              : ''
          "
          @blur="$v.newPracticaEmpresa.contacto.$touch()"
          v-model="newPracticaEmpresa.contacto"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_name'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.cuit.required ||
            !$v.newPracticaEmpresa.cuit.minLength
              ? `${$t(
                  'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.minLength'
                )}*`
              : ''
          "
          @blur="$v.newPracticaEmpresa.cuit.$touch()"
          v-model="newPracticaEmpresa.cuit"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.cuit'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
        <v-text-field
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.telefono.minLength ||
            !$v.newPracticaEmpresa.telefono.required
              ? `${$t(
                  'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.minLengthTel'
                )}*`
              : ''
          "
          @blur="$v.newPracticaEmpresa.telefono.$touch()"
          v-model="newPracticaEmpresa.telefono"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.tel'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
        <v-text-field
          type="email"
          class="pb-3"
          :error-messages="
            !$v.newPracticaEmpresa.email.required ||
            !$v.newPracticaEmpresa.email.email
              ? `${$t(
                  'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.emailRequired'
                )}*`
              : ''
          "
          @blur="$v.newPracticaEmpresa.email.$touch()"
          v-model="newPracticaEmpresa.email"
          :label="`${$t(
            'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.email'
          )}*`"
          outlined
          @input="() => (newPracticaEmpresa.flag = true)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators';

export default {
  props: ['newPracticaEmpresa', 'datosCompany'],
  validations: {
    newPracticaEmpresa: {
      cargoReferente: { required },
      contacto: { required },
      cuit: { required, minLength: minLength(9) },
      direccion: { required },
      email: { required, email },
      legajo: { required },
      razonSocial: { required },
      telefono: { required, minLength: minLength(10) },
    },
  },
};
</script>
