<template>
  <div class="summary">
    <div class="d-flex justify-center">
      <span class="primary--text">Resumen</span>
      <v-spacer></v-spacer>
      <img :src="require('@/assets/images/portalalumno/summaryImage.png')" class="summary-image" />
    </div>
    <v-divider class="mt-5"></v-divider>
    <template v-if="subtotalItems.length > 0">
      <div class="summary-values">
        <v-row>
          <v-col sm=6 class="d-flex pl-4 align-center justify-start">
            <span class="primary--text body-2">Subtotal</span>
          </v-col>
          <v-col sm=6 class="d-flex pr-4 align-center justify-end">
            <span class="primary--text body-2">{{ subtotal | $currency }}</span>
          </v-col>
        </v-row>

        <!-- discounts iterator -->
        <v-row v-for="subtotalItem in subtotalItems" :key="subtotalItem.id">
          <v-col sm=6 class="d-flex pl-4 align-center justify-start discount-detail">
            <v-tooltip nudge-top="0" nudge-left="0" bottom max-width="300px" color="rgba(0, 0, 0, 0.8)">
              <template v-slot:activator="{ on }">
                <span class="primary--text caption" v-on="on">{{ itemsNames(subtotalItem.title,20) }}</span>
              </template>
              <span class="caption">{{ itemsNames(subtotalItem.title, 100) }}</span>
            </v-tooltip>
          </v-col>
          <v-col sm=6 class="d-flex pr-4 align-center justify-end discount-detail">
            <span class="primary--text caption">{{ subtotalItem.amount | $currency }}</span>
          </v-col>
        </v-row>
        <!-- !discounts iterator -->

        <v-row v-if="discountsItems.length > 0">
          <v-col sm=6 class="d-flex pl-4 align-center justify-start">
            <span class="primary--text body-2">Descuentos</span>
          </v-col>
          <v-col sm=6 class="d-flex pr-4 align-center justify-end">
            <span class="primary--text body-2">- {{ discounts | $currency }}</span>
          </v-col>
        </v-row>

        <!-- discounts iterator -->
        <v-row v-for="discount in discountsItems" :key="discount.id">
          <v-col sm=6 class="d-flex pl-4 align-center justify-start discount-detail">
            <v-tooltip nudge-top="0" nudge-left="0" bottom max-width="300px" color="rgba(0, 0, 0, 0.8)">
              <template v-slot:activator="{ on }">
                <span class="success--text caption" v-on="on">{{ itemsNames(discount.name, 20) }}</span>
              </template>
              <span class="caption">{{ itemsNames(discount.name, 100) }}</span>
            </v-tooltip>
          </v-col>
          <v-col sm=6 class="d-flex pr-4 align-center justify-end discount-detail">
            <span class="success--text caption">{{ discount.percentage }} %</span>
          </v-col>
        </v-row>
        <!-- !discounts iterator -->
      </div>
      <v-divider class="mt-8"></v-divider>
      <v-row class="mt-4">
        <v-col sm=6 class="d-flex pl-4 align-center justify-start">
          <span class="primary--text font-weight-bold">Total a pagar</span>
        </v-col>
        <v-col sm=6 class="d-flex pr-4 align-center justify-end">
          <span class="primary--text font-weight-bold">{{ total | $currency }}</span>
        </v-col>
      </v-row>
    </template>
    <div v-else class="d-flex py-4 px-8 mt-5 justify-center align-center caption">Por favor, selecciona un item para pagar.</div>
  </div>
</template>

<script>
  export default {
    name: 'Summary',
    props: {
      subtotalItems: Array,
      discountsItems: Array
    },
    data() {
      return {}
    },
    methods:{
      itemsNames(str, max_length) {
        str = `${str.slice(0,1).toUpperCase()}${str.slice(1,str.length).toLowerCase()}`
        if(str.length > max_length) return `${str.slice(0, max_length - 1)}...`
        return str
      },
    },
    computed: {
      subtotal(){
        let subtotal = 0
        this.subtotalItems.forEach(item =>{ subtotal += item.amount })
        return subtotal
      },
      discounts(){
        let discounts = 0
        this.discountsItems.forEach(item =>{ discounts += this.subtotal * item.percentage / 100 })
        return discounts
      },
      total(){
        return this.subtotal - this.discounts
      }
    },
  }
</script>

<style lang="sass" scoped>
  .summary 
    position: fixed
    top: 20%
    z-index: 0
    width: 30%
    box-shadow: 0px 2px 5px #ccc
    padding: 30px 40px

    &-image 
      max-height: 40px

  .discount-detail
    height: 20px

</style>