<template>
  <div>
    <v-card class="px-4" outlined style="background-color: #f9f9f9" :loading="loading">
      <template v-slot:progress="">
        <v-row>
          <v-col cols="12" class="pa-6 text-center">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </template>
      <v-row  v-if="!loading">
        <v-col class="pb-0 col-3" >
          <h3>{{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.title') }}</h3>
        </v-col>
        <v-col class="pb-0" >
          <h3>Monto a pagar</h3>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col>
        <ContractPreviewPeriodsDetails
            :selectedPeriods="selectedPeriods"
            :payments="payments"
        />
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 py-0">
                <strong class="subtitle-2">
                  {{ $t('modules.portalalumno.procedures.reEnrollmentStatus.contractPreview.studentDetails.mainTitle') }}
                </strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ContractPreviewStudentDetails
                    :studentName="contractDetails?.name"
                    :studentIdentification="contractDetails?.identification"
                    :studentEmail="contractDetails?.email"
                    :studentCellphone="contractDetails?.cellphone"
                    :studentAddress="contractDetails?.address"
                    :agentName="contractDetails?.nameApoderado"
                    :agentIdentification="contractDetails?.identificationApoderado"
                    :agentEmail="contractDetails?.emailApoderado"
                    :agentCellphone="contractDetails?.cellphoneApoderado"
                    :agentAddress="contractDetails?.addressApoderado"
                />
                <ContractPreviewAcademicDetails
                  :arancel="contractDetails?.arancel"
                  :careerName="contractDetails?.careerName"
                  :city="contractDetails?.city"
                  :cuotas="selectedPeriods?.length*contractDetails?.installments"
                  :finalArancel="priceList"
                  :formaPago="contractDetails?.formaPago"
                  :jornada="contractDetails?.jornada"
                  :modality="contractDetails?.modality"
                  :semesters="contractDetails?.semesters"
                  :valorCuota="installmentValueCalc"
                  :valorMatricula="contractDetails?.matricula"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ContractPreviewPeriodsDetails from './ContractPreviewPeriodsDetails.vue'
import ContractPreviewStudentDetails from './ContractPreviewStudentDetails.vue'
import ContractPreviewAcademicDetails from './ContractPreviewAcademicDetails.vue'
import {$students} from '../../services'
import {mapGetters} from 'vuex';
export default {
  name: 'ContractPreview',
  props: {
    accounts: {
      default: () => [],
      type: Array
    },
    payments: {
      default: () => [],
      type: Array
    },
    selectedPeriods: {
      default: () => [],
      type: Array
    }
  },
  components: { ContractPreviewAcademicDetails ,ContractPreviewPeriodsDetails, ContractPreviewStudentDetails },
  data() {
    return {
      loading: false,
      contractDetails: null
    }
  },
  computed: {
    ...mapGetters({
      userData: 'commons/getStudentData'
    }),
    academicSemesterBeginningOfValidity() {
      if(this.selectedPeriods.length > 0) {
        return this.selectedPeriods[0].classStartDate
      }
      return ''
    },
    installmentData(){
      const installment = this.payments.filter(paymentInstallment => paymentInstallment.idProduct == this.$sisConstants('modules.products.arancel'))
      return installment[0]
    },
    installmentValueCalc(){
      const installmentValue = this.$isTeclab ? + this.installmentData.options.amount - ((this.installmentData.options.off + this.installmentData.options.addedExtraOff) * this.installmentData.options.amount / 100) < 0 ? 0 : this.installmentData.options.amount - ((this.installmentData.options.off + this.installmentData.options.addedExtraOff) * this.installmentData.options.amount / 100)
          : this.installmentData.options.amount - ((this.installmentData.options.off + (this.installmentData.options.addedExtraOff * (100 - this.installmentData.options.off) / 100)) * this.installmentData.options.amount / 100)
    return installmentValue 
    },
    priceList(){
      const priceList = this.installmentValueCalc ? this.installmentValueCalc * this.installmentData.options.paymentInstallments : ''
      return priceList
    }
  },
  async created() {
    await this.getContractDetails()
  },
  watch: {
    selectedPeriods () {
      this.updateSelectedAccount(this.contractDetails)
    }
  },
  methods: {
    updateSelectedAccount(contractDetails) {
      if(this.selectedPeriods.length > 0) {
        const accounts = [...this.accounts]
        const selectedPeriodsIds = this.selectedPeriods.map(period => period.id )
        const selectedAccounts  = accounts.filter(account => selectedPeriodsIds.includes(account.term.id))
        this.contractDetails = {...contractDetails, ...{ accounts: selectedAccounts }}
      } else {
        this.contractDetails = {...contractDetails, ...{ accounts: [] }}
      }
    },
    async getContractDetails() {
      this.loading = true;
      try {
        const {data} = await $students.find(`contract-details/${this.userData.academicRegister}`,null)
        this.updateSelectedAccount(data)
        // eslint-disable-next-line no-useless-catch
      } catch (err) {
        throw err
      } finally {
        this.loading = false
      }
      
    }
  }
}
</script>

<style lang="sass">
.ContractPreview
  &-card-subtitle
    font-size: 13px
    color: #9a9a9a
  &.card-message
    font-size: 11px
  &.card-title
    color: black
  &.is-selected
    background-color: #dae0fa

</style>