<template>
    <div>
        <OutlinedCard
            :title="$t('modules.benefits.create.academic_performance.rules_title')"
            :subtitle="$t('modules.benefits.create.academic_performance.rules_subtitle')"
            class="mb-6"
        >
            <MultipleFormGroup
                :items="rules.groups.$each.$iter"
                :length="rules.groups.$model.length"
                @removeGroup="removeGroup"
                @addGroup="addGroup"
            >
                <template v-slot:default="{item, index}">
                    <v-container fluid class="py-0">
                        <v-row>
                            <v-col sm="6" class="py-0">{{ $t('modules.benefits.create.academic_performance.rules.from') }}</v-col>
                            <v-col sm="6" class="py-0">{{ $t('modules.benefits.create.academic_performance.rules.to') }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="2">
                                <v-select
                                    :error-messages="(item.dayFrom.$dirty && item.dayFrom.$invalid) ? 'Error' : ''"
                                    :label="`${$t('modules.benefits.create.academic_performance.rules.day')} *`"
                                    :items="item.$model.dayListFrom"
                                    outlined
                                    class="no-message"
                                    @blur="item.dayFrom.$touch()"
                                    v-model="item.dayFrom.$model"
                                ></v-select>
                            </v-col>
                            <v-col sm="3">
                                <v-select
                                    :error-messages="(item.monthFrom.$dirty && item.monthFrom.$invalid) ? 'Error' : ''"
                                    :label="`${$t('modules.benefits.create.academic_performance.rules.month')} *`"
                                    :items="$t('modules.benefits.create.academic_performance.rules.monthList')"
                                    item-text="value"
                                    outlined
                                    return-object
                                    class="no-message"
                                    @change="setRulesFromDay(index, item.monthFrom.$model)"
                                    @blur="item.monthFrom.$touch()"
                                    v-model="item.monthFrom.$model"
                                ></v-select>
                            </v-col>
                            <v-col sm="1"></v-col>
                            <v-col sm="2">
                                <v-select
                                    :error-messages="(item.dayTo.$dirty && item.dayTo.$invalid) ? 'Error' : ''"
                                    :label="`${$t('modules.benefits.create.academic_performance.rules.day')} *`"
                                    :items="item.$model.dayListTo"
                                    outlined
                                    class="no-message"
                                    @blur="item.dayTo.$touch()"
                                    v-model="item.dayTo.$model"
                                ></v-select>
                            </v-col>
                            <v-col sm="3">
                                <v-select
                                    :error-messages="(item.monthTo.$dirty && item.monthTo.$invalid) ? $t('modules.benefits.create.validation_errors.errorDate') : ''"
                                    :label="`${$t('modules.benefits.create.academic_performance.rules.month')} *`"
                                    :items="$t('modules.benefits.create.academic_performance.rules.monthList')"
                                    item-text="value"
                                    outlined
                                    return-object                                  
                                    @change="setRulesToDay(index, item.monthTo.$model)"
                                    @blur="item.monthTo.$touch()"
                                    v-model="item.monthTo.$model"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </MultipleFormGroup>
        </OutlinedCard>
    </div>
</template>

<script>
    import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';

    export default {
        name: 'AcademicPerformanceRules',
        props: {
            rules: Object
        },
        components: {
            MultipleFormGroup,
            OutlinedCard,
        },
        methods: {
            removeGroup(index) {
                this.$emit('removeGroup', index)
            },
            addGroup() {
                const list = this.$t('modules.benefits.create.academic_performance.rules.dayList.31')
                const mockup = {
                    dayListFrom: list,
                    dayListTo: list,
                    dayFrom: null,
                    monthFrom: {id: null},
                    dayTo: null,
                    monthTo: {id: null},
                };
                this.$emit('addGroup', mockup);
            },
            setRulesFromDay(index, month) {
                this.rules.groups.$model[index].dayListFrom = this.$t(`modules.benefits.create.academic_performance.rules.dayList.${month.days}`)
            },
            setRulesToDay(index, month) {
                this.rules.groups.$model[index].dayListTo = this.$t(`modules.benefits.create.academic_performance.rules.dayList.${month.days}`)
            },
        }
    }
</script>