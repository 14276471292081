import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('academic');

export class Terms extends Api {
  constructor(http, config = {}) {
    super(http, url, 'terms', config);
  }

  findWithFilter(filter, config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, 'filter', null);

    return this._http.post(uri, filter, config);
  }
  
  currentPeriod(config = {}) {
    config = {...this._config, ...config};
    const uri = this._uriConstructor(null, 'current-sales');
    return this._http.get(uri, config);
  }
}
