<template>
  <v-card outlined class="files-card mt-5 py-5 px-5 text-center">
    <h3 v-if="title" class="black--text">{{ title }}</h3>
    <div v-if="subtitle"  class="mb-2 text--muted">{{ subtitle }}</div>
    <v-list class="files-list" v-if="fileList.length !== 0">
      <v-list-item v-for="(doc, i) in fileList" :key="i">
        <v-list-item-icon>
          <v-icon color="primary">mdi-image</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-left">{{doc.name}}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn @click="deleteFile(doc)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-btn v-if="!fileList.length || isMultiple"
      outlined
      rounded
      small
      :disabled="!isEnabled"
      color="primary"
      class="btn-add-files mt-1"
      @click="openFilesDialog"
    >{{$t('components.fileUploader.addFiles')}}</v-btn>

    <v-btn v-else-if="process"
      outlined
      rounded
      small
      color="primary"
      class="btn-add-files mt-1"
      @click="action()"
    >{{$t('components.fileUploader.process')}}</v-btn>
    <h5 class="caption text-center mt-3 mb-0" :class="{ 'red--text': sizeError }">Tamaño maximo de archivo: {{ limitSize }}</h5>
    <v-file-input :accept="formatsAccepted" ref="fileInput" chips @change="onChange" v-show="false" :id="uniqueId"></v-file-input>
  </v-card>
</template>
<script>
export default {
  name: 'FileUploader',
  props: {
    files: Array,
    title: String,
    subtitle: String,
    formatsAccepted: { type: String, default: '' },
    isEnabled:{type : Boolean, default: true},
    limitSizeBytes: { type: String, default: '2000000' },
    process:{type : Boolean, default: true},
    isMultiple: { type: Boolean, default: false },
  },
  data() {return {
    fileList: [],
    uniqueId: '',
    item: [],
    sizeError: false,
  }},
  computed: {
    limitSize() {
      if (this.limitSizeBytes.length > 6) return `${this.limitSizeBytes.slice(0, -6)} MB`
      else return `${this.limitSizeBytes.slice(0, -3)} KB`
    },
  },
  watch: {
    // files (val) {
    //   this.fileList = val;
    // },
    fileList (newVal, oldVal) {
      if(newVal != oldVal)
        this.$emit('update:files', newVal);
    }
  },
  methods: {
    onChange (ev) {
      if (ev && this.limitSizeBytes >= ev.size) {
        this.fileList.push(ev)
        this.sizeError = false
      } else this.sizeError = true
    },
    deleteFile(doc) {
      this.$refs.fileInput.reset()
      this.$refs.fileInput.resetValidation()
      this.$refs.fileInput.clearableCallback()
      const index = this.fileList.indexOf(doc);
      this.fileList.splice(index, 1);
    },
    openFilesDialog() {
      const el = document.querySelector(`#${this.uniqueId}`);
      el.click();
    },
    action() {
      this.$emit('action', this.fileList);
    }
  },
  created() {
    this.uniqueId = `componentFilesInput-${Date.now()}${Math.round(Math.random())}`;
  },
  mounted() {
    this.fileList = this.files;
  }
}
</script>

<style lang="sass" scoped>

  .text--muted
    color: rgba(0, 0, 0, 0.7)

  .files-card

    .files-list
      background: transparent

      .v-list-item:not(:last-child)
        border-bottom: 1px solid lightgrey
      
    .btn-add-files
      background: white !important

    .no-files-message
      font-weight: 600
      font-size: .9em
      display: flex
      flex-flow: row nowrap
      justify-content: center
      align-items: center
      color: #a1a1a1

      .v-icon
        font-size: 1.5em
        color: inherit
</style>