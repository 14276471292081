<template>
    <div class="formModal">
        <v-card class="px-10 py-5 d-flex justify-start align-center formModal__content">
            <slot name="form"></slot>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'FormModal',
    data(){
        return{}
    }
}
</script>

<style lang="sass" scoped>

.formModal
    position: absolute
    z-index: 99
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.2)

    &__content
        min-width: 60vw
        max-width: 80vw
        position: fixed
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 50px rgba(0, 0, 0, 0.2)

</style>