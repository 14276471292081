<template>
    <v-container fluid class="d-flex pa-0 pb-8 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom" v-if="!showSuccesMessage">
    <v-row>
      <v-col>
        <Breadcrumbs
          :title="$t('modules.academic.sanctions.sanctionAssign.create.title')"
          :description="$t('modules.academic.sanctions.sanctionAssign.create.description')"
        ></Breadcrumbs>
        </v-col>
    </v-row>
    <v-row justify="center">
       <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">

       <OutlinedCard
          :title="$t('modules.academic.sanctions.sanctionAssign.create.cardUser.title')"
          :subtitle="$t('modules.academic.sanctions.sanctionAssign.create.cardUser.subtitle')"
          class="mb-6"
        >
        <v-row>
            <v-col sm="4">
               <v-text-field
                :label="$t('modules.academic.sanctions.sanctionAssign.create.cardUser.document')"
                v-model="dniUser"
                :rules="validations.selectRules"
                :error="!documentCorrect"
                hint="Documento inexistente"
                outlined
                @blur="getStudentData"
                @click:append="getStudentData"
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col sm="11">
            <OutlinedCard
            removeShadow
            v-if="userData"
            >
              <v-container fluid class="d-flex pa-0 pb-8">
                <v-container class="container-custom">
                  <v-row class="mb-4" justify="space-between">
                    <v-col sm="4">
                      <p class="mb-2">{{$t('modules.academic.sanctions.sanctionAssign.create.cardUser.name')}}</p>
                      <h5>{{userData.fullname}}</h5>
                    </v-col>
                    <v-col sm="3">
                      <p class="mb-2">{{$t('modules.academic.sanctions.sanctionAssign.create.cardUser.doc_type')}}</p>
                      <h5>{{userData.doc_type}}</h5>
                    </v-col>
                    <v-col sm="4">
                      <p class="mb-2">{{$t('modules.academic.sanctions.sanctionAssign.create.cardUser.doc_number')}}</p>
                      <h5>{{userData.doc_number}}</h5>
                    </v-col>
                  </v-row>
                  <v-row justify="space-between">
                    <v-col sm="4">
                      <p class="mb-2">{{$t('modules.academic.sanctions.sanctionAssign.create.cardUser.career')}}</p>
                      <h5>{{userData.career}}</h5>
                    </v-col>
                    <v-col sm="4">
                      <p class="mb-2">{{$t('modules.academic.sanctions.sanctionAssign.create.cardUser.legacy')}}</p>
                      <h5>{{userData.legacy}}</h5>
                    </v-col>
                  </v-row>
                </v-container>
              </v-container>
            </OutlinedCard>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="11">
              <Banner
              v-if="false"
              type="info"
              title="Este alumno registra sanciones anteriores.">
                <template v-slot:action>
                  <v-btn text color="primary">
                    Ver historial
                  </v-btn>
                </template>
              </Banner>
          </v-col>
        </v-row>
       </OutlinedCard>
       </v-col>
    </v-row>
    <v-row justify="center">
       <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">

       <OutlinedCard
          :title="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.title')"
          :subtitle="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.subtitle')"
          class="mb-6"
        >
        <v-form v-model="validForm">
          <v-row>
              <v-col sm="4">
                <v-select
                  :items="sanctionType"
                  v-model="selectedSanction"
                  :rules="validations.selectRules"
                  :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.area')"
                  item-text="meaning"
                  outlined
                  required
                  @change="getUpdateDate"
                />
              </v-col>
          </v-row>
          <v-row>
            <v-col sm="4">
                <v-select
                  :items="sanctionLevelType"
                  v-model="selectedLevelType"
                  :rules="validations.selectRules"
                  :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.type')"
                  item-text="meaning"
                  outlined
                  required
                  @change="getUpdateDate"
                />
            </v-col>
            <v-col v-show="subjects.length>0" sm="5">
              <v-combobox
              v-model="selectedSubject"
              :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.reason')"
              outlined
              :items="subjects"
              item-text="title"
              />
            </v-col>
            <v-col v-if="selectedSubject && subjects.length>0" sm="3">
              <h5>{{$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.dateEnd')}}</h5>
              <p v-if="selectedSubject.dateEndSanction">{{selectedSubject.dateEndSanction}}</p>
              <p v-else>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.permanent')}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="10">

            <p v-if="selectedSubject">{{selectedSubject.description}}</p>
            </v-col>
          </v-row>
          <v-row v-if="selectedSanction === 302">
            <v-col sm="6">
              <v-combobox
              v-model="selectedDepartment"
              :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.subject')"
              outlined
              :items="departments"
              item-text="descriptionSubject"
              @change="getDateExams"
              />
            </v-col>
            <v-col sm="4">
              <v-select
                v-model="selectedExamen"
                :items="examenes"
                :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.examens')"
                item-text="examDate"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="11">
              <v-textarea
                v-model="observation"
                outlined
                no-resize
                :label="$t('modules.academic.sanctions.sanctionAssign.create.cardSanction.observation')">
              </v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="false" class="d-flex justify-end">
            <v-col sm="4" >
              <Button
              text="Previsualizar mensaje"
              outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="false" sm="8">
              <FileUploader
                title="Información complementaria"
                subtitle="Podés adjuntar información de respaldo o documentación necesaria."
                :files="filesToUpload"
              />
            </v-col>
          </v-row>
        </v-form>
       </OutlinedCard>
       <div class="d-flex justify-end mb-4">
            <Button
            white
            :text="$t('actions.cancel')"
            @clicked="$router.replace('/academic/sanctions/assigned')"
            class="mr-4"
             ></Button>

            <Button
            :loading="false"
            :success="false"
            :error="false"
            :text="$t('actions.create')"
            :successText="$t('actions.created')"
            :errorText="'Error'"
            :disabled="!userData || !canCreate || !validForm"
            @clicked="createSanctionAssigned"
            ></Button>
          </div>
       </v-col>
    </v-row>
  </v-container>
  <SuccessMessage
    v-if="showSuccesMessage"
    :type="successMessage.type"
    :title="successMessage.title"
    :actionPrimary="successMessage.actionPrimary"
    :actionSecondary="successMessage.actionSecondary"
  />
</v-container>
</v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button.vue';
import Banner from '@/components/Banner/Banner.vue';
import FileUploader from '@/components/FileUploader/FileUploader.vue';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {$types, $sanctionStudents, $sanction, $materias, $examenes} from '../Services';
import { mapGetters } from 'vuex';
export default {
  name: 'SanctionAssign',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    Banner,
    FileUploader,
    SuccessMessage
  },
  data() {
    return {
      loading: false,
      dniUser: null,
      sanctionLevelType: [],
      selectedLevelType: null,
      sanctionType: [],
      selectedSanction: null,
      subjects: [],
      selectedSubject: null,
      departments: [],
      examenes: [],
      selectedExamen: null,
      selectedDepartment: null,
      userData: null,
      endDate: null,
      filesToUpload: [],
      observation: '',
      btnSucces: false,
      btnError: false,
      btnLoading: false,
      documentCorrect: true,
      validForm: false,
      showSuccesMessage: false,
      successMessage: {
        type: 'success',
        title: this.$t('modules.academic.sanctions.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/sanctions/assigned') } },
        actionSecondary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.secondary_text'), callback: () => { this.createdNewSanction = false } }
      },
      validations: {
        selectRules: [
          v => !!v || 'Verificá este campo'
        ],
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
     loadData() {
       this.getSanctionTypes();
       this.getSanctionLevel();
    },

    async getSanctionTypes() {
       try{
        const { data } = await  $types.find('', null, {params:{type:'SANCTION_TYPE'}})
        this.sanctionType = data;
       }catch{
         this.sanctionType = [];
       }
    },
    async getSanctionLevel() {
      try{
        const { data } = await $types.find('', null, {params:{type:'SANCTION_LEVEL_TYPE'}})
        this.sanctionLevelType = data;

      }catch{
        this.sanctionLevelType = [];
      }
    },
    async getDepartments() {
      try{
        const param = {
          leg: this.userData.legacy,
        }

        const { data } = await $materias.find('examSubjects', null, {params: param})
        //here
        this.departments = data;
      }catch{
        this.departments = [];
      }
    },
    async getDateExams() {

      try{
        const { data } = await $examenes.find('getExamsBySubject', null, {params:{subjectCode: this.selectedDepartment.codeSubject, studentCareerId:this.userData.legacy}})
        this.examenes = data;
      }catch {
        this.examenes = [];
      }
    },
    async getStudentData() {
      try{
      const { data } = await $sanctionStudents.find('student',null,{params: {
        dni: this.dniUser
      }});
      const  [student] = data.content;
        const userData = {
          fullname: `${student.name} ${student.lastname}`,
          doc_type: student.type.meaning,
          doc_number: student.identification,
          career: student.career.description,
          legacy: student.studentCareerId,
          id: student.id,
          career_exames: student.examRequests
        };
        this.userData = userData;
        this.getDepartments();
        this.documentCorrect = true;
      }
      catch{
        this.userData = null;
        this.documentCorrect = false
      }
    },
    async getUpdateDate() {
      try{
      if(this.selectedLevelType && this.selectedSanction){
        this.subjects = [];
        this.selectedSubject = null;
        const dataToPass = {
          sanctionLevel: this.selectedLevelType,
          sanctionType: this.selectedSanction,
        }
        const { data } = await $sanction.find('', null, {params: dataToPass})
        if(data) {
          this.subjects = data.content.filter((el) => el.status === true);
        }else{
          this.subjects = [];
        }
      }
      }catch{
        this.subjects = [];
      }
    },
    /* async postDocument() {
      const dataToSend = {
        file: this.filesToUpload[0],
        documentation: {
          id: this.userData.id,
          studentSanction: this.selectedSanction,
        }
      };
       //const response = await $sanction.create(dataToSend, null, 'documentation');
    }, */
    async createSanctionAssigned() {
      if(this.canCreate && this.dniUser){
        try{
            const userCreateId = this.userId;
            const subjectSelected = this.selectedSubject?.id || null;
            const dataToSend = {
              student: {
                id: this.userData.id
              },
              studentCareerId: this.userData.legacy,
              examInformation: {
                examRequestId: this.selectedExamen,
              },
              observations: this.observation,
              status: true,
              sanction:{
                  id: subjectSelected
              },
              userCreate: {
                id: userCreateId
              }

            };
            await $sanctionStudents.create(dataToSend,{},null);
            this.showSuccesMessage = true;
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.academic.sanctions.sanctionAssign.create.message_succes');
            this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/academic/sanctions/assigned') } };
            this.successMessage.actionSecondary = null
        }catch(error){
          this.showSuccesMessage = true;
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.sanctions.sanctionAssign.create.message_error');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.$router.go(-1) }}
          this.successMessage.actionSecondary = null;
        }
      }
    }
  },
  computed: {
    ...mapGetters(['commons/getUserID']),
    userId() {
      return this['commons/getUserID']
    },
    canCreate() {
      return this.$permissions.portaladministrativo.academic.sanctions.ASSIGNED_CREATE
    },
  }
}
</script>
