<template>
    <v-container class="bg fullHeight d-flex " fluid>
        <OutlinedCard
            :title="$t('modules.portalalumno.reglamento.reglamentoConfirm.breadcrumbs.title')"
            :subtitle="$t('modules.portalalumno.reglamento.reglamentoConfirm.breadcrumbs.description')"
            style="height: max-content"
        >
            <v-row>
                <v-col>
                    <v-list style="max-height: 50vh" class="overflow-y-auto">
                        <v-list-group v-for="regulation in regulations" :key="regulation._id" :value="true">
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title v-text="regulation.Nombre"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list-item v-for="subcapitulos in regulation.Subcapitulos" :href="`#${subcapitulos._id}`" :key="subcapitulos._id">
                                <v-list-item-content>
                                    <v-list-item-title v-text="subcapitulos.display"></v-list-item-title>
                                    <div v-html="subcapitulos.content.Contenido"></div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-col>
            </v-row>
            <v-row class="m-0 mb-16 d-flex flex-column flex-sm-row align-center"> 
                <v-col cols="12" sm="6" md="4" class="d-flex">
                    <v-alert class="mb-0 mt-4" outlined type="info">
                        {{$t('modules.portalalumno.reglamento.reglamentoConfirm.title')}}
                        <a class="ms-2 ps-4" :href="require('@/assets/documents/Reglamento_Institucional_Teclab_2021.pdf')" target="_blank">
                            <v-icon medium color="blue" class="me-3">mdi-cloud-download-outline</v-icon>
                            {{$t('modules.portalalumno.reglamento.reglamentoConfirm.pdf')}}
                        </a>
                    </v-alert>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="d-flex mt-4">
                    <v-checkbox
                        v-model="accept"
                        color="primary"
                        class="ma-0"
                    ></v-checkbox>
                    <div class="">
                        <div class="checkbox-custom checkbox-default">
                            <label for="chkAccept"><strong>{{$t('modules.portalalumno.reglamento.reglamentoConfirm.condiciones.title')}}</strong></label>
                        </div>
                        <p>{{$t('modules.portalalumno.reglamento.reglamentoConfirm.condiciones.subtitle')}}</p>
                    </div>
                </v-col> 
                <v-col cols="12" sm="12" md="4" class="d-flex justify-start justify-sm-end ps-0 ps-sm-12 ps-md-0 mb-8">
                    <Button
                        white
                        :text="$t('actions.notAccept')"
                        :errorText="$t('actions.error')"
                        class="mr-4"
                        @clicked="logout"
                    ></Button>
                    <Button
                        @clicked="confirm"
                        :disabled="!accept"
                        :text="$t('actions.accept')"
                        :successText="$t('actions.created')"
                        :errorText="$t('actions.error')"
                    ></Button>
                </v-col>
            </v-row>
        </OutlinedCard>
        <FirmNewPP v-if="$isTeclab" :open="!!$route.query.pp || showModalFirmNewPP" @close="showModalFirmNewPP = false" />
    </v-container>
</template>


<script>
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import FirmNewPP from '../../Dashboard/Components/FirmNewPP.vue'
    import AuthService from '@/plugins/auth.service';
    import ListStudents from '@/constants/listStudents';
    import { mapGetters } from 'vuex';
    import { $student } from  '../Services';

    import Configuration from '../../../../core/constants/configuration';

    export default {
        components: {
            OutlinedCard,
            Button,
            FirmNewPP,
        },
        name: 'ReglamentoConfirm',
        data() {
            return {
                regulations:[],
                accept: false,
                showModalFirmNewPP: false,
            }
        },
        computed:{
            cockpit() {
                if (this.$isTeclab) return { url: 'https://www.teclab.edu.ar/cockpit', token: '9e705928b960e3c338102ab3233e1e' }
                else return { url: 'https://cockpit.ipp.cl', token: '9b840d8b42fa7c01c06df4e95913d0' }
            },
            ...mapGetters({
                studentId: 'commons/getStudentId',
            }),
        },
        methods: {
            async getFetchRegulations() { 
                try {
                    const res = await fetch( `${this.cockpit.url}/api/collections/completeRuleBook/?token=${this.cockpit.token}&col=CapituloNew` )
                    const resJson = await res.json()
                    this.regulations = await resJson.entries
                } catch (error) {
                    this.regulations = []
                    throw error
                }
            },
            logout () {
                this.$auth.logout();
            },
            async confirm(){
                await $student.update(null, null, { params: { studentId: this.studentId, type: 'GENERAL' } }, 'signPpTermCondition' )
                await this.$store.dispatch('commons/initData')
                this.$router.push('/dashboard')
            }
        },
        async mounted () {
            this.getFetchRegulations()
            const alumnoSeleccionado = JSON.parse(localStorage.getItem('alumnoSeleccionado'));
            const dniAlumnoBloqueado = Configuration.value('app') == 'portalalumno' && AuthService.isStudent() && alumnoSeleccionado ? ListStudents.find(
                dni => dni == alumnoSeleccionado.userData.username
            ) : null;

            this.showModalFirmNewPP = !!dniAlumnoBloqueado;
        },
    }
</script>