
const equivalencesTranslations = {
    es: {
        validation_errors: {
            subject: 'Selecciona un departamento para elegir la asignatura',
            differentSubjects: 'La asignatura nueva debe ser distinta a la anterior'
        },
        table: {
            breadcrumbs: {
                title: 'Equivalencias',
                description: 'Administración de equivalencias'
            },
            new_section: 'Nueva',
            bulk_delete: 'Eliminar secciones',
            delete_error: 'No se pudo eliminar la equivalencia',
            success_message: 'Se elimino correctamente la equivalencia',
            success: 'Éxito',
            bulk_delete_error: 'No se pudieron eliminar las secciones',
            headers: {
                idAsignaturaOld: 'Asignatura anterior',
                idAsignaturaNew: 'Asignatura nueva',
                percentage: 'Porcentaje',
                codeAsignaturaOld:'Codigo'
            }
        },
        create: {
            title: 'Datos principales',
            subtitle: 'Define los datos básicos necesarios para la creación de una nueva equivalencia. Podrás asignarle el tipo de LMS que utilizará, como así también el cupo de estudiantes y la asignatura que incluirá.',
            labels: {
                department: 'Departamento',
                subject: 'Asignatura',
                OldSubjects: 'Asignatura anterior',
                NewSubjects: 'Asignatura nueva'
            },
            breadcrumbs: {
                title: 'Nueva equivalencia',
                description: 'Crea una nueva equivalencia'
            },
            warning_popup: {
                title: 'Se está creando la equivalencia deshabilitada',
                content: '',
                create: 'Crear',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Nueva equivalencia creada correctamente',
                actions: {
                    primary_text: 'Cerrar',
                    secondary_text: 'Crear otro',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al crear la equivalencia',
            },
            codeSection:{
                    title: 'Datos de la equivalencia',
                    subtitle: 'Agrega la información de la nueva equivalencia. Recuerda que estos datos deben ser lo más descriptivos posible, ya que serán utilizados para futuros filtros de búsqueda.'
            },
            
        },
        update: {
            title: 'Datos principales',
            subtitle: '',
            studenttitle: 'Listados de estudiantes',
            studentsubtitle: '',
            studentrut: 'RUT',
            labels: {
                name: 'Nombre',
                modality: 'Modalidad',
                term: 'Período',
                lms_type: 'Tipo LMS',
                department: 'Departamento',
                subject: 'Asignatura',
                equivalences: 'Secciones',
                size: 'Cupo',
                teacher: 'Docente',
                enabled: 'Habilitado',
                disabled: 'Deshabilitado',
            },
            breadcrumbs: {
                title: 'Editar equivalencia',
                description: 'Modifica la equivalencia'
            },
            warning_popup: {
                title: 'Se está guardando la equivalencia deshabilitada',
                content: '',
                update: 'Guardar',
                cancel: 'Cancelar'
            },
            success_message: {
                title: 'Equivalencia modificada correctamente',
                newChange: 'Hacer otra cambio',
                actions: {
                    primary_text: 'Cerrar',
                    back: 'Volver',
                },
            },
            error_message: {
                title: 'Se produjo un error al modificar la equivalencia',
            },
        },
    },
    en: {
    }
};
export default equivalencesTranslations
