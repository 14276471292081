<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewSellerStatus">
      <Breadcrumbs
        :title="$t('modules.commissions.sellers.create.breadcrumbs.title')"
        :description="$t('modules.commissions.sellers.create.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.commissions.sellers.create.sectionData.title')"
            :subtitle="$t('modules.commissions.sellers.create.sectionData.subtitle')"
            :switch-label="changeStatuslabel"
            switcher
            @switchChange="changeStatus"
            :switchValue="status"
            class="mb-6"
          >
            <v-row>
              <v-col sm="8">
                <v-text-field
                  :error-messages="($v.sellerName.$dirty && $v.sellerName.$invalid) ?
                  ((!$v.sellerName.required) ? $t('modules.commissions.sellers.validations.nameRequired') :
                  (!$v.sellerName.alphaNumWithSpaces) ? $t('modules.commissions.sellers.validations.nameAlphanumericWithSpaces') :
                  $t('modules.commissions.sellers.validations.nameMaxLength')) :
                  ''"
                  :label="$t('modules.commissions.sellers.create.sectionData.items.sellerName')"
                  outlined
                  v-model="$v.sellerName.$model"
                  @blur="$v.sellerName.$touch()"
                  @keypress="$validateAlphanumeric($event, $v.sellerName.$model,60, true)"
                ></v-text-field>
              </v-col>
              <v-col sm="4">

                <v-text-field
                      outlined
                      :error-messages="($v.sellerCode.$dirty && $v.sellerCode.$invalid) ?
                      ((!$v.sellerCode.required) ? $t('modules.commissions.sellers.validations.codeRequired') :
                      (!$v.sellerCode.alphaNum) ? $t('modules.commissions.sellers.validations.codeAlphanumeric') :
                      (!$v.sellerCode.maxLength) ? $t('modules.commissions.sellers.validations.codeMaxLength') :
                      $t('modules.commissions.sellers.validations.codeExist')) :
                      ''"
                      :loading="loadingCode"
                      :label="`${$t('modules.commissions.sellers.create.sectionData.items.sellerCode')}`"
                      :value="$v.sellerCode.$model"
                      @input="$v.sellerCode.$model = $event.toUpperCase()"
                      @blur="validateCode"
                      @keypress="$validateAlphanumeric($event, $v.sellerCode.$model,10)"
                    ></v-text-field>
          
              </v-col>
              <v-col cols="8" >
                <v-text-field
                  :label="$t('modules.commissions.sellers.create.sectionData.items.email')"
                  v-model="$v.email.$model"
                  outlined
                  :error-messages="$v.email.$dirty && $v.email.$invalid ? $t('modules.commissions.sellers.validations.emailRequired') : ''"
                  v-model.trim="$v.email.$model"
                  @input="$v.email.$model = $event.toLowerCase()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="4" >
                <v-text-field
                  :label="$t('modules.commissions.sellers.create.sectionData.items.sapCode')"
                  v-model="sapCode"
                  @input="sapCode = $event.toUpperCase()"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" >
                <v-checkbox
                  v-model="paying"
                  :label="$t('modules.commissions.sellers.create.sectionData.items.commission')"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </OutlinedCard>
         
          <OutlinedCard
            :title="$t('modules.commissions.sellers.create.sectionLocality.title')"
            :subtitle="$t('modules.commissions.sellers.create.sectionLocality.subtitle')"
            class="mb-6"
          >
            <v-col class="d-flex px-0" cols="4" sm="3">
              <v-autocomplete
                :items="statesList"
                item-text="name"
                :label="$t('modules.commissions.sellers.create.sectionLocality.province')"
                outlined
                return-object
                v-model.trim="$v.selectState.$model"
                @blur="$v.selectState.$touch()"
              ></v-autocomplete>
            </v-col>
            <TransferList
              :avatar="false"
              :chips="true"
              valueDisable="seller"
              showInChip="message"
              :loading="loadingSearchLocalities"
              item-text="description"
              :availableItems.sync="filteredLocalities"
              :added-items.sync="localitiesAdded"
              areEquals="id"
              :firstListTitle="$t('modules.commissions.sellers.create.sectionLocality.firstListTitle')"
              :secondListTitle="`${$t('modules.commissions.sellers.create.sectionLocality.secondListTitle')} ${localitiesAdded.length === 0 ? '' : localitiesAdded.length } `"
            ></TransferList>
              <v-alert
                  text
                  type="warning"
                  class="mt-4"
                  v-if="localitiesAdded.length===0"
                >{{ $t('modules.commissions.sellers.create.sectionLocality.error.errorLocality') }}</v-alert>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.commissions.sellers.create.sectionCareers.title')"
            :subtitle="$t('modules.commissions.sellers.create.sectionCareers.subtitle')"
            class="mb-6"
          >
            <TransferList
              :avatar="false"
              :loading="loadingSearchCareers"
              :availableItems.sync="filteredCareers"
              :added-items.sync="careersAdded"             
              areEquals="id"
              @searchItemLeftOnBackend="searchCareers"
              @searchItemRightOnBackend="searchCareersAdded"
            ></TransferList>
          </OutlinedCard>
          <OutlinedCard
            :title="$t('modules.commissions.sellers.create.sectionUsers.title')"
            :subtitle="$t('modules.commissions.sellers.create.sectionUsers.subtitle')"
            class="mb-6"
          >
            <TransferList
              :avatar="true"
              :loading="loadingSearchUsers"
              item-text="description"
              :availableItems.sync="users"
              :added-items.sync="usersAdded"
              areEquals="id"
            ></TransferList>
          </OutlinedCard>
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/commissions/sellers')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>

            <Button
              :loading="createButton.loading"
              :success="createButton.success"
              :error="createButton.error"
              :text="$t('actions.create')"
              :successText="$t('actions.created')"
              :errorText="'Error'"
              :disabled="!canCreate"
              @end="resetButtonValues"
              @clicked="createSellerPopUp"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required, email, maxLength, helpers, alphaNum} from 'vuelidate/lib/validators';
import { $careers, $users, $countries , $sellers, $citiesByState } from '../Services';
import TransferList from '@/components/TransferList/TransferList.vue';
import { mapGetters } from 'vuex';
import Constants from '@/plugins/constants';
import Configuration from '../../../../../core/constants/configuration';


export default {
  name: 'SellersCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    TransferList,
  },
  data() {
    return {
      createNewSellerStatus: false,
      createButton: {
        loading: false,
        success: false,
        error: false,
      },
        localitiesAdded: [],
        filteredLocalities:[],
        selectState: null,
        statesList: [],
        sellerName: '',
        status: true,
        phone: null,
        sapCode: null,
        description: null,
        sellerCode: null,
        email: null,
        paying: true,
        careerList: [],
        userList: [],
        citiesList: [],

      loadingCode: false,
      codeUnique: null,

      loadingSearchLocalities: false,
      loadingSearchUsers: false,
      loadingSearchCareers: false,
      users: [],
      usersAdded: [],
      paginationLimit: 150,
      filteredCareers: [],
      careers: {},
      careersAdded: [],
      careersFilters: [],
      availableCareers: [],
    
      successMessage: {
        type: 'success',
        title: this.$t('modules.commissions.sellers.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.commissions.sellers.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/commissions/sellers') } },
        actionSecondary: { text: this.$t('modules.commissions.sellers.create.success_message.actions.secondary_text'), callback: () => { this.createNewSellerStatus = false } }
      },
    };
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.commissions.sellers.CREATE;
    },
    selectStateErrors () {
      const errors = [];
      if (!this.$v.selectState.$anyError) return errors;
      if (!this.$v.selectState.required)
        errors.push(
          this.$t('modules.commissions.sellers.validations.cityRequired')
        );
      return errors;
    },
    changeStatuslabel() {
      return this.status
        ? this.$t('modules.commissions.sellers.create.sectionData.labelEnable')
        : this.$t('modules.commissions.sellers.create.sectionData.labelDisable');
    },
     ...mapGetters({
      userId: 'commons/getUserID',
    }),

  },
  watch: {
   
    'careersAdded': function () {

      this.selectedCareerFilter = {};
    },
    'selectState': async function () {
        this.loadingSearchLocalities = true
        const cities = await $citiesByState.find(null, null, { params: { stateId: this.selectState.id, sellers:true } });
      
        const newCities = (cities.data.sort((a, b) => a.name < b.name ? -1 : 1)).map(el => {
           if (el.seller) {
             const newseller = {status: el.seller, message : 'No disponible'}
             const newElem = {...el, seller:newseller}
             return newElem
           }else {
              return { ...el, seller:undefined }
           }
         })
        this.filteredLocalities =  newCities
        this.loadingSearchLocalities = false
     },
  },
  mounted() {
    this.getCareers();
    this.getUsers();
    this.getStates();
  },
  methods: {
    async validateCode() {
      this.$v.sellerCode.$touch();
      if (this.$v.sellerCode.$model) {
        this.codeUnique = null;
        this.loadingCode = true;
        try {
          const response = await $sellers.find('exists', null, {params: {code: this.$v.sellerCode.$model}});
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error;
        } finally {
          this.$v.sellerCode.$touch();
        }
      }
    },
    searchCareers(event) {
      this.getCareers(event);
    },
    searchCareersAdded(event) {
      this.careersAdded = event;
    },
    async getCareers(career) {
      this.loadingSearchCareers = true;
      let careers = [];
      if (career) {
        careers = await $careers.find(null, null, {
          params: { name: career, length: this.paginationLimit },
        });
      } else {
        careers = await $careers.find(null, null, {
          params: { length: this.paginationLimit },
        });
      }
      if (careers.data && careers.data.content.length) {
        careers = careers.data.content.map((item) => {
          return {
            id: item.id,
            name: item.legalDescription,
            careerType: item.careerType,
          };
        });

        // CAREERS CATEGORIES

        this.careersFilters.forEach((filter) => {
          /* eslint-disable */
          if (filter.meaning === "Todos") {
            this.careers[filter.meaning] = careers;
          } else {
            this.careers[filter.meaning] = careers.filter(
              (item) => item.careerType.value == filter.value
            );
          }
        });

        this.filteredCareers = careers;
        this.availableCareers = careers;
      }
      this.loadingSearchCareers = false;
    },
    changeStatus() {
      this.status = !this.status;
    },
    async getUsers() {
      this.loadingSearchUsers = true;

              const users = await $users.find(`list`, null,{params: {page: 0, length: 500, userId: this.userId, orderBy: 'name', orientation: 'asc'}})
        this.users = users.data.content.map(user => {
        return {
          id: user.id,
          avatar: `${Constants.URL_DATA_SERVER}/users/${user.id}/avatar`,
          name: `${user.name} ${user.lastName}`
        };
      })

      this.loadingSearchUsers = false;
    },
    async getStates() {
     
      try {
      let country =  Configuration.value('country') || 'argentina'
                 const statesLocal = await $countries.find(null, null, {params: { name: country }})     
                  this.statesList = (statesLocal.data[0].states).sort((a, b) => a.name < b.name ? -1 : 1)
      } catch (error) {
       throw error
      }                           
      
    },
       createSellerPopUp () {
       if ( this.$v.$invalid || !this.canCreate || this.localitiesAdded == 0) {
              this.$v.$touch()
      }else {  
        if (!this.status) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('alert'),
            content: [{ value: this.$t('modules.commissions.sellers.create.disabledMessage') }],
            actionPrimary: { text: this.$t('actions.accept'), callback: () => this.createSeller() },
            actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
            icon: { color: 'warning', name: 'mdi-alert' },
            color: 'primary',
          });
        } else {
          this.createSeller();
        }
      }
    },
    async createSeller(){
      if (!this.canCreate) return false;
       this.createButton.loading = true;
      let newSeller = {
        sellerName: this.sellerName.trim(),
        sapCode: this.sapCode,
        status: this.status,
        email: this.email,
        sellerCode: this.sellerCode,
        paying: this.paying,
        careerList: [],
        usersList: [],
        citiesList: []
      }
      this.careersAdded.map(career => {
       const addcareer = { ...newSeller, careerList: newSeller.careerList.push({id:career.id})} 
      })
      this.usersAdded.map(user => {
       const addUser = { ...newSeller, usersList: newSeller.usersList.push({id:user.id})} 
      })
      this.localitiesAdded.map(city => {
       const addCities = { ...newSeller, citiesList: newSeller.citiesList.push({id:city.id})} 
      })
  
      try {
          await $sellers.create(newSeller);
           this.successMessage.type = 'success';
          this.successMessage.title = this.$t("modules.commissions.sellers.create.success_message.title");
          this.successMessage.actionPrimary = {
            text: this.$t('modules.commissions.sellers.create.success_message.actions.primary_text'),
            callback: () => {
              this.$router.push("/commissions/sellers");
            },
          };
          this.successMessage.actionSecondary = {
            text: this.$t('modules.commissions.sellers.create.success_message.actions.secondary_text'),
            callback: () => {
              this.createNewSellerStatus = false;
            },
          };
  
          this.createNewSellerStatus = true;
          this.createButton.success = true; 
           this.clearData();
      } catch (error) {
         this.successMessage.type = 'error';
         if (error.code ) {
          this.successMessage.title = this.$t('modules.commissions.sellers.create.error_message.title');
        }
        this.successMessage.actionSecondary = null;
        this.successMessage.actionPrimary = { text: this.$t('modules.commissions.sellers.create.success_message.actions.back'), callback: () => { this.createNewSellerStatus = false } }
        
        
        this.createNewSellerStatus = true;
        this.createButton.loading = false;
        this.createButton.error = true;
        this.resetButtonValues();
    
      } 
    },
    clearData() {
      this.resetButtonValues();
      this.sellerName = null;
      this.sellerName = null,
      this.status = true,
      this.phone = null,
      this.sapCode = null,
      this.description = null,
      this.sellerCode = null,
      this.email = null,
      this.paying = false,
      this.localitiesAdded = [],
      this.careersAdded = [],
      this.usersAdded = [],
      this.$v.$reset();
    },
    resetButtonValues() {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    },
  },
  validations: {
    sellerName: {
      required,
      maxLength: maxLength(60),
      alphaNumWithSpaces: helpers.regex('withSpace',/^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
    },
    sellerCode: {
      required,
      alphaNum,
      maxLength: maxLength(10),
      unique() {
        return this.codeUnique !== false;
      },
    },
    selectState: { required },
    email: {
      required,
      email
    },
    seller: ['sellerName', 'sellerCode', 'selectState', 'email', ],
    
  },
};
</script>