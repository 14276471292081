import EquivalencesTable from '../Views/EquivalencesTable';
import EquivalencesCreate from '../Views/EquivalencesCreate';
import EquivalencesUpdate from '../Views/EquivalencesUpdate'

const EquivalencesRoute = [
  {
    path: '/',
    component: EquivalencesTable,
    meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'equivalences', permission: 'READ' }
  }, {
    path: 'create',
    component: EquivalencesCreate,
    meta: { name: 'Equivalences Create', group: 'portaladministrativo.academic', path: 'equivalences', permission: 'CREATE' }
  }, {
    path: 'update/:id',
    component: EquivalencesUpdate,
    meta: { name: 'Equivalences Update', group: 'portaladministrativo.academic', path: 'equivalences', permission: 'READ' }
  },
];
export default EquivalencesRoute