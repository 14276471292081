<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :title="$t('modules.settings.liquidations.breadcrumbs.title')"
            :description="$t('modules.settings.liquidations.breadcrumbs.description')"
          ></Breadcrumbs>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template >
            <OutlinedCard :section="$t('modules.settings.liquidations.sectionSecurity.title')" class="mb-6">
              <OutlinedCardContent
                :label="$t('modules.settings.liquidations.sectionSecurity.startDay')"
                sm="4"
              >
                <v-text-field
                  v-model="$v.startDay.$model"
                  :error-messages="($v.startDay.$dirty && $v.startDay.$invalid) ? $t('modules.settings.liquidations.validations.sectionSecurity.startDayRequired') : ''"
                  @keypress="$validateIntegerInputNumber($event, $v.startDay.$model, 28, null, 1)"
                  placeholder="0"
                  outlined
                ></v-text-field>
              </OutlinedCardContent>
              <OutlinedCardContent
                :label="$t('modules.settings.liquidations.sectionSecurity.finishDay')"
                sm="4"
              >
                <v-text-field
                  v-model="$v.finishDay.$model"
                  :error-messages="($v.finishDay.$dirty && $v.finishDay.$invalid) ? $t('modules.settings.liquidations.validations.sectionSecurity.finishDayRequired') : ''"
                  @keypress="$validateIntegerInputNumber($event, $v.finishDay.$model, 28, null, 1)"
                  placeholder="0"
                  outlined
                ></v-text-field>
              </OutlinedCardContent>              
            </OutlinedCard>
            <div class="text-sm-right">
              <Button
                :loading="loading"
                :success="success"
                :error="error"
                :text="$t('actions.save')"
                :successText="$t('actions.saved')"        
                :errorText="$t('actions.error')"
                @clicked="fetchDataUpdate"
                @end="resetButtonValues"
                :disabled="!canUpdate || $v.$invalid"
              ></Button>
          </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

// Components Imports
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
import Button from '@/components/Button/Button'
import { $payings } from '../services'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    Button
  },
  data () {
    return {
      startDay: null,
      finishDay:null,
      loading: false,
      success: false,
      error: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {    
    async fetchData() {            
      try {
        const liqui = await $payings.find(`configure`, null, null );
        liqui.data.forEach(liquidationObject => liquidationObject.key === 'Commission_Paying_From'
              ? this.startDay = liquidationObject.value
              : this.finishDay = liquidationObject.value)
      } catch (error) {
        this.startDay = null
        this.finishDay = null
        throw error
      }                
    },
    async fetchDataUpdate() {
      try {
        this.loading = true;
        const data = [
          {
            'key': 'Commission_Paying_From',
            'value': this.startDay
          },{
            'key': 'Commission_Paying_To',
            'value': this.finishDay
          }
        ]

        await $payings.update(this.$route.params.id, data, null ,`configure`)
        this.success = true
      } catch (error) {          
        this.error = true
        throw error
      } finally {
        this.loading = false
      }
    },
    resetButtonValues () {
      this.loading = false;
      this.success = false;
      this.error = false;
    }
  },
  validations: {
    startDay: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(28),
    },
    finishDay: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(28),
    },
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.billing.UPDATE
    },
  },
}

</script>

