<template>
    <v-container>
        <v-row >
            <span class="mt-13 mr-4">Filtrar por </span>
            <v-col
            cols="12"
            sm="12"
            md="6"           
            lg="4"
            >            
                <p class="text-uppercase caption ">
                    {{ $t('modules.comisiones.dashboard.aranceles.selects.over.careers') }}
                </p>
                <v-select
                    :items="careers"
                    item-text="name"
                    item-value="id"
                    v-model="selectCareer"
                    :label=" $t('modules.comisiones.dashboard.aranceles.selects.all') "
                    prepend-inner-icon="mdi-book"
                    outlined
                    full-width
                    hide-details
                    multiple
                    clearable
                >
                </v-select>            
            </v-col>
            <v-col align-self="end" class="d-flex justify-end">
                <v-btn @click="goAranceles()" height="44" elevation="4">{{topFive ? $t('modules.comisiones.dashboard.aranceles.moreInfo.more') : $t('modules.comisiones.dashboard.aranceles.moreInfo.back') }}</v-btn>                        
            </v-col>

        </v-row >
        <v-row>

            <v-col lg="8" cols="12" class="">
                <v-card elevation="2" height="431" max-width="">
                        <v-simple-table height="431" >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-start subtitle-2 text-uppercase" style="background: #F5F6FA 0% 0% no-repeat padding-box;">
                                            {{ $t('modules.comisiones.dashboard.aranceles.cardLeft.head.arancelados') }}
                                        </th>
                                        <th class="text-start subtitle-2 text-uppercase" style="background: #F5F6FA 0% 0% no-repeat padding-box;">
                                            {{ $t('modules.comisiones.dashboard.aranceles.cardLeft.head.career') }}
                                        </th>
                                        <th class="text-end subtitle-2 text-uppercase" style="background: #F5F6FA 0% 0% no-repeat padding-box;">
                                            {{ $t('modules.comisiones.dashboard.aranceles.cardLeft.head.commision') }} ($)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item,i) in dataTable"
                                    :key="i"
                                    >
                                        <td class="text-center">{{ item.studentAmount }}</td>
                                        <td class="">{{ item.description }}</td>
                                        <td class="text-end">{{ item.totalAmount | $currency }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                </v-card>
            </v-col>

            <v-col lg="4" cols="12">
                <v-card elevation="2" height="431">
                    <v-card-title class="subtitle-2 text-uppercase" style="background: #F5F6FA 0% 0% no-repeat padding-box;">{{ $t('modules.comisiones.dashboard.aranceles.cardRigth.top5') }} </v-card-title>
                    <v-card-text class='d-flex justify-center pb-0'>
                        <apexchart ref="donutGraphic" type="donut" :options="configDonut" :series="percentageDonut" width="143"> </apexchart> 
                    </v-card-text>
                    <div style="top:-12px;position: relative;">
                    <v-card-text class="d-flex py-1" v-for="(serie,i) in percentageDonut" :key="i">
                        <span class="d-flex align-center" style="flex: 1">
                            <v-icon class="mr-2" :color="configDonut.colors[i]" size="12px">mdi-circle</v-icon>
                            {{ configDonut.labels[i] }}
                        </span>
                        <span>{{ `${serie} %` }}</span>
                    </v-card-text>
                    </div>
                    <v-card-title class="subtitle-2 py-0 text-uppercase">
                        Medio de pago
                    </v-card-title>
                    <v-card-text >
                        <v-row class="pt-0">
                            <v-col class="px-1" v-for="method in paymentMethods" :key="method.options.labels[0]">
                                <apexchart ref="radialBarGraphic" type="radialBar" :options="method.options" :series="method.serie" height="100"></apexchart>
                            </v-col>
                        </v-row>
                        </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>    
</template>

<script>
import { $careers, $sellers } from '../Services'
import { mapGetters } from 'vuex';
    export default {
        name:'Aranceles',
        props:{
            filters:Object,
            },
        data() {
            return {              
                topFive:true,
                originalDataTable: [],
                selectCareer:[],
                careersIds:[],

                careers:[],
                salesByCareers:[],

                paymentMethod:[
                    {label: 'Credito', value: 0},
                    {label: 'Debito', value: 0},
                    {label: 'Efectivo', value: 0},
                    {label: 'Suscripcion', value: 0},
                ],
                benefit:[],
                
                graficTable:[],

                percentageDonut: [],
                configDonut: {
                    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                    labels: [],
                    legend: {
                        horizontalAlign:'left',
                        position: 'bottom',
                        show:false,
                    },
                    
                    dataLabels:{
                        enabled:false,
                    },
                    plotOptions:{
                        pie:{
                            donut: {
                                size: '66,5px',
                            }
                        }
                    }
                },

                paymentMethods: [],
                percentageRadio:[],
                configRadio: {
                    labels: [],
                    chart: {
                        type:'radialBar',
                        width: '100%'
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {size: '28%'},
                            dataLabels: {
                                value: {
                                    fontSize: '10px',
                                    offsetY: -12,
                                    offsetX: 0,
                                },
                                name: {
                                    fontSize: '12px',
                                    color: 'black',
                                    offsetY: 38,
                                    offsetX: 0,
                                }
                            }
                        }
                    }
                }


            }
        },
        async mounted() {
            await this.getCareers()
        },

        methods: {            
            async getCareers() {
                try {
                    const careers = await $careers.find('list', null, {params: {page: 0, length: 500, status: true} })
                    this.careers = careers.data.content.sort((a, b) => (a.name > b.name) ? 1 : -1)
                    this.careersIds = this.careers.map(career => career.id)
                } catch (error) {
                    this.careers = []
                }
            },

            async getTable(){
                try {
                    const body = {
                        'careersIds': this.selectCareer.length ? this.selectCareer : this.careersIds ,
                        'sellersIds':this.filters && this.filters.sellers? this.filters.sellers : []
                    }
                    const params = {term : this.filters.term.id}
                    const res = await $sellers.create(body, {params}, `salesByCareers/${this.userId}`)
                    const data = res.data.map(item => {
                        return ({
                            'id':item.career.id,
                            'studentAmount':item.studentAmount,
                            'description':item.career.description,
                            'totalAmount':Math.trunc(item.comissionAmount)
                            })
                        })
                    data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)
                    this.originalDataTable = data
                    this.desserts = res.data.map(item => {
                        return ({
                            'arancelados':item.studentAmount,
                            'career':item.career.description,
                            'commision':Math.trunc(item.comissionAmount)
                        })
                    })
                } catch (error) {
                    this.originalDataTable = []
                    throw error
                }
            },
            async dataGrafic(){
                try {
                    const body = {
                        'careersIds': this.selectCareer.length ? this.selectCareer : this.careersIds,
                        'sellersIds':this.filters && this.filters.sellers? this.filters.sellers : []
                    }
                    const params = {term : this.filters.term.id}
                    const res = await $sellers.create(body, {params}, `salesByCity/${this.userId}`)
                    const { data } = res
                    this.configDonut.labels = []
                    for (let index = 0; index < res.data.listCitySales.length; index++) {
                        const element = res.data.listCitySales[index];
                        this.configDonut.labels.push(element.city.name)
                    }
                    this.percentageDonut = res.data.listCitySales.map(item => item.percentage)
                    this.paymentMethod = data.listPayments.map(item  => {
                            return {'labels': item.paymentType.meaning, 'value': item.percentage.toFixed(1)}
                        })
                    this.paymentMethods = []
                    this.paymentMethod.forEach(method => {
                        const options = JSON.parse(JSON.stringify(this.configRadio))
                        options.labels.push(method.labels)
                        this.paymentMethods.push({serie: [method.value], options })
                    });
                } catch (error) {
                    this.originalDataTable = []
                    throw error
                }
            },

            goAranceles(){
                this.topFive = !this.topFive
                this.$emit('toggle-career', this.topFive)
            }
        },
        computed: {
            ...mapGetters([
                'commons/getUserID',
                'commons/getUserRoles'
            ]),
            userId(){
                return this['commons/getUserID']
            },
            carrersIdForServices(){
                return this.selectCareer ? this.selectCareer : this.careersIds
            },
            dataTable(){
                return this.topFive ? this.originalDataTable.slice(0,5) : this.originalDataTable
            }            
        },
        watch: {
            filters() {
                this.getTable()
                this.dataGrafic()
            },
            selectCareer(){
                this.getTable()
            }
        },
    }
</script>

<style lang="scss" scoped>
    
</style>
