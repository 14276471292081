<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!createNewCommission">
            <Breadcrumbs
                :title="$t('modules.commissions.config.create.breadcrumbs.title')"
                :description="$t('modules.commissions.config.create.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.config.create.sectionData.title')"
                        :subtitle="$t('modules.commissions.config.create.sectionData.subtitle')"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="6">
                                <v-select
                                    outlined
                                    multiple
                                    :label="`${$t('modules.commissions.config.create.sectionData.items.relation')} *`"
                                    v-model="$v.selectedStudentTypes.$model"
                                    @blur="selectedStudentTypeError = !selectedStudentTypes.length"
                                    :items="studentTypes"
                                    :error-messages="selectedStudentTypeError ? $t('modules.commissions.config.create.sectionData.error.selectedStudentTypes') : ''"
                                    item-text="name"
                                    return-object
                                ></v-select>
                            </v-col>
                            <v-col sm="3">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="from"
                                            :label="$t('modules.commissions.config.create.sectionData.items.from')"
                                            append-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="from"
                                        no-title
                                        color="primary"
                                        scrollable 
                                        :min="minDate"
                                        @input="menu = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <OutlinedCard class="mb-6 pt-0" v-for="studentTypeItem in $v.selectedStudentTypes.$each.$iter" :key="studentTypeItem.id">
                            <v-card-title class="headline ma-0 px-0 mb-4 pt-2">{{ studentTypeItem.$model.name }}</v-card-title>
                            <v-row>
                                <v-col cols="6" class="px-0 py-0">
                                    <v-select
                                        v-model="studentTypeItem.billingProduct.$model"
                                        @blur="studentTypeItem.$model.dirty = true"
                                        :error-messages="!studentTypeItem.billingProduct.required && studentTypeItem.$model.dirty ? $t('modules.commissions.config.create.validations.billingProductRequired') : ''"
                                        :label="`${$t('modules.commissions.config.create.sectionData.items.billingProduct')} *`"
                                        :items="studentTypeItem.$model.billingProductList"
                                        item-text="name"
                                        return-object
                                        outlined
                                        multiple
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" class="px-0 pb-0" v-for="billingProductItem in studentTypeItem.billingProduct.$each.$iter" :key="billingProductItem.id">
                                    <OutlinedCard class="pt-0">
                                        <v-card-title class="headline font-weight-bold ma-0 px-0 mb-4 pt-2">{{ billingProductItem.$model.name }}</v-card-title>
                                        <v-row>
                                            <v-col cols="5" class="py-0">
                                                <v-text-field
                                                    v-model.number="billingProductItem.percentage.$model"
                                                    :error-messages="billingProductItem.$invalid && billingProductItem.$model.dirty ? $t('modules.commissions.config.create.validations.percentageRequired') : ''"
                                                    @blur="billingProductItem.$model.dirty = true"
                                                    @keypress="$validateDecimalInputNumber($event, billingProductItem.percentage.$model, 100)"
                                                    outlined
                                                    :label="`${$t('modules.commissions.config.create.sectionData.items.percentage')} *`"
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </OutlinedCard>
                                </v-col>
                            </v-row>
                        </OutlinedCard>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/config')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="createButton.loading"
                            :success="createButton.success"
                            :error="createButton.error"
                            :text="$t('actions.create')"
                            :successText="$t('actions.created')"
                            :errorText="'Error'"
                            :disabled="!canCreate || $v.$invalid"
                            @end="resetButtonValues"
                            @clicked="createCommission"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import { required } from 'vuelidate/lib/validators';
    import { $types, $products, $commissions } from '../Services';
    import { mapGetters } from 'vuex';

    export default {
        name: 'ConfigCreate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
        },
        data() {
            return {
                createNewCommission: null,
                studentTypes: [],
                selectedStudentTypes: [],
                selectedStudentTypeError: false,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                minDate: null,
                menu: false,
                from: new Date().toISOString().substr(0, 10),
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null
                },
            }
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.config.CREATE
            },
            ...mapGetters({userId: 'commons/getUserID',})
        },
        async mounted () {
            const date = new Date()
            date.setMonth(date.getMonth() - 2)
            this.minDate = date.toISOString().substr(0, 10)
            this.getStudentType();
        },
        methods: {
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
            async getStudentType() {
                try {
                    const studentTypes = await $types.find(null, null, { params: { type: 'STUDENT_TYPE'}})

                    this.studentTypes = studentTypes.data.map(type => {
                        return {
                            id: type.value,
                            name: type.meaning,
                            billingProduct: [],
                            dirty: false,
                            billingProductList: [],
                        };
                    });
                    
                    this.getBillingProducts();
                } catch (error) {
                    this.studentTypes = []
                    throw error
                }
            },
            async getBillingProducts () {
                const billingProduct = await $products.find();
                const items = billingProduct.data.content.map(product => {
                    return {
                        id: product.id,
                        name: product.name,
                        percentage: null,
                        dirty: false,
                    };
                });

                this.studentTypes.forEach(element => {
                    element.billingProductList = JSON.parse(JSON.stringify(items));
                });
            },
            async createCommission () {
                if(!this.canCreate) return false;
                const data = this.selectedStudentTypes.map(itemStudentType => {
                    const billingProductReturn = itemStudentType.billingProduct.map(itemBillingProduct => {
                        return {
                            id: itemBillingProduct.id,
                            percentage: itemBillingProduct.percentage
                        }
                    });

                    return {
                        studentTypeId: itemStudentType.id,
                        dateFrom: this.from,
                        productList: billingProductReturn,
                        userId: this.userId,
                    };
                });

                try {
                    this.createButton.loading = true;
                    await $commissions.create(data);
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.commissions.config.create.success_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => {this.$router.push('/commissions/config')} };
                    this.successMessage.actionSecondary = { text: this.$t('actions.create_another'), callback: () => {this.createNewCommission = false} };
                    this.createButton.success = true;
                    this.clearData()
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.commissions.config.create.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => {this.createNewCommission = false} };
                    this.successMessage.actionSecondary = null
                    this.createButton.error = true;
                } finally {
                    this.createButton.loading = false;
                    this.createNewCommission = true;
                }
            },
            clearData() {
                this.selectedStudentTypes = [];
                this.from = new Date().toISOString().substr(0, 10),
                this.selectedStudentTypeError = false
                this.studentTypes.forEach(element => {
                    element.billingProduct = [];
                    
                    element.dirty = false;
                    element.billingProductList.forEach(item => {
                        item.percentage = null;
                        item.dirty = false;
                    });
                });
                this.resetButtonValues();
                this.$v.$reset();
            }
        },
        validations: {
            selectedStudentTypes: {
                required,
                $each: {
                    billingProduct: {
                        required,
                        $each: {
                            percentage: { 
                                required,
                                minValue(value) {
                                    return value > 0
                                }
                            }
                        }
                    }
                },
            },
        },
    }
</script>