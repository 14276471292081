<template>
  <v-container>
    <v-row class="container-custom fullHeight d-flex align-center justify-center">
      <v-col sm="10" class="d-flex flex-column mx-auto mb-16">
        <v-progress-circular v-if="!procedureDetail" class="mx-auto mt-16" indeterminate color="primary"></v-progress-circular>
        <OutlinedCard containerClass="mt-0" v-else>
          <v-row>
            <v-col sm="10" class="align-start mt-0">
              <TitleComponent 
                :title="`${procedureDetail.description} - Nº ${procedureDetail.id}`" 
                :description="`${$t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.requested')}: ${procedureDetail.requested}`" >
              </TitleComponent>
            </v-col>
            <v-col sm="2" class="d-flex align-center">
              <v-chip class="px-4" rounded :color="procedureDetail.chipColor" dark>{{ procedureDetail.status }}</v-chip>
            </v-col>  
          </v-row>
          
          <v-divider></v-divider>
            
          <TitleComponent
            :title="$t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.reason')"
            :description="(procedureDetail.processingsReasonType && procedureDetail.processingsReasonType.meaning) || $t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.emptyReason')"
          ></TitleComponent>
          <TitleComponent
            :title="$t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.documentation')"
            :description="!procedureDetail.documentations || !procedureDetail.documentations.length ? $t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.emptyDocumentations') : ''"
          ></TitleComponent>
            
          <v-row v-if="procedureDetail && procedureDetail.documentations && procedureDetail.documentations.length">
            <v-col sm="10" class="mx-auto d-flex align-center pt-0" v-for="(item, index) in procedureDetail.documentations" :key="index">
              <v-icon v-if="item.documentation.status" color="green">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              <h6 class="caption ml-3">{{ item.documentation.name }}</h6>
            </v-col>
          </v-row>
          
          <v-divider></v-divider>
          
          <TitleComponent
            :title="$t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.comments')"
            :description="procedureDetail.reason || $t('modules.portalalumno.procedures.procedureHistoryDetails.dataSection.emptyComments')"
          ></TitleComponent>
        </OutlinedCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import TitleComponent from '@/components/TitleComponent/TitleComponent';
import { $studentProcessing } from '../services';
import moment from 'moment'

export default {
  name: 'procedureHistoryDetails',
  props:{
    procedureId: Number,
  },
  components: {
    OutlinedCard,
    TitleComponent,
  },
  data() {
    return {
      procedureDetail: null,
    }
  },
  methods: {
    async fetchData (id) {
      const response = await $studentProcessing.findById(id);
      this.procedureDetail = response.data
      this.procedureDetail.requested = moment(response.data.created, 'YYYY-MM-DD').format('DD/MM/YYYY');

      const status = response.data.processingStatus.meaning
      this.procedureDetail.status =  status

      if (status == 'Pendiente') {
        this.procedureDetail.status =  this.$t('modules.portalalumno.procedures.procedureHistoryDetails.status.iniciado')
        this.procedureDetail.chipColor = '#419bf9'
      } else if (status == 'Rechazado') this.procedureDetail.chipColor = 'error'
      else if (status == 'Aprobado') this.procedureDetail.chipColor = 'success'
      else if (status == 'Observado') this.procedureDetail.chipColor = 'orange'
    },
  },
  watch: {
    procedureId(val) {
      if (val) this.fetchData(val);
      else this.procedureDetail = null
    }
  },
}
</script>