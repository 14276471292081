var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticStyle:{"width":"100%"},attrs:{"width":"100%","disabled":_vm.datosCompany.estadoPPId == 270 || _vm.datosCompany.estadoPPId == 269}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{staticClass:"pb-3",attrs:{"type":"text","error-messages":!_vm.$v.newPracticaEmpresa.razonSocial.required
            ? _vm.$t('modules.academic.periods.create.errors.required')
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.reason'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.razonSocial.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.razonSocial),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "razonSocial", $$v)},expression:"newPracticaEmpresa.razonSocial"}}),_c('v-text-field',{staticClass:"pb-3",attrs:{"type":"text","error-messages":!_vm.$v.newPracticaEmpresa.direccion.required
            ? _vm.$t('modules.academic.periods.create.errors.required')
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.address'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.direccion.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.direccion),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "direccion", $$v)},expression:"newPracticaEmpresa.direccion"}}),_c('v-text-field',{staticClass:"pb-3",attrs:{"type":"text","error-messages":!_vm.$v.newPracticaEmpresa.cargoReferente.required
            ? _vm.$t('modules.academic.periods.create.errors.required')
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_position'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.cargoReferente.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.cargoReferente),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "cargoReferente", $$v)},expression:"newPracticaEmpresa.cargoReferente"}}),_c('v-text-field',{staticClass:"pb-3",attrs:{"type":"text","error-messages":!_vm.$v.newPracticaEmpresa.contacto.required
            ? _vm.$t('modules.academic.periods.create.errors.required')
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.referrer_name'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.contacto.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.contacto),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "contacto", $$v)},expression:"newPracticaEmpresa.contacto"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"pb-3",attrs:{"error-messages":!_vm.$v.newPracticaEmpresa.cuit.required ||
          !_vm.$v.newPracticaEmpresa.cuit.minLength
            ? `${_vm.$t(
                'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.minLength'
              )}*`
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.cuit'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.cuit.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.cuit),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "cuit", $$v)},expression:"newPracticaEmpresa.cuit"}}),_c('v-text-field',{staticClass:"pb-3",attrs:{"error-messages":!_vm.$v.newPracticaEmpresa.telefono.minLength ||
          !_vm.$v.newPracticaEmpresa.telefono.required
            ? `${_vm.$t(
                'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.minLengthTel'
              )}*`
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.tel'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.telefono.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.telefono),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "telefono", $$v)},expression:"newPracticaEmpresa.telefono"}}),_c('v-text-field',{staticClass:"pb-3",attrs:{"type":"email","error-messages":!_vm.$v.newPracticaEmpresa.email.required ||
          !_vm.$v.newPracticaEmpresa.email.email
            ? `${_vm.$t(
                'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.emailRequired'
              )}*`
            : '',"label":`${_vm.$t(
          'modules.academic.professionalizingPractice.update.steppers.header.start.drop_company.form.email'
        )}*`,"outlined":""},on:{"blur":function($event){return _vm.$v.newPracticaEmpresa.email.$touch()},"input":() => (_vm.newPracticaEmpresa.flag = true)},model:{value:(_vm.newPracticaEmpresa.email),callback:function ($$v) {_vm.$set(_vm.newPracticaEmpresa, "email", $$v)},expression:"newPracticaEmpresa.email"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }