import Subjects from '../Subjects';
import SubjectsTable from '../views/SubjectsTable';
import SubjectsCreate from '../views/SubjectsCreate';
import SubjectsUpdate from '../views/SubjectsUpdate';

const SubjectsRoute =
    {
        path: '/subjects',
        meta: { 
            name: {
              en: 'Subjects',
              es: 'Asignaturas',
              }
            },
        component: Subjects,
        children:[
            {
                path: '/',
                component: SubjectsTable,
                meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'subjects', permission: 'READ' }
            },{
                path:'create',
                component: SubjectsCreate,
                meta: { name: 'SubjectsCreate', group: 'portaladministrativo.academic', path: 'subjects', permission: 'CREATE' }
            },{
                path: 'update/:id',
                component: SubjectsUpdate,
                meta: { name: 'SubjectsUpdate', group: 'portaladministrativo.academic', path: 'subjects', permission: 'READ' }
            },
        ]
    };

export default SubjectsRoute
