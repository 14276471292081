import SubscriptionRulesTable from '../Views/SubscriptionRulesTable';
import SubscriptionRulesCreate from '../Views/SubscriptionRulesCreate.vue';
import SubscriptionRulesUpdate from '../Views/SubscriptionRulesUpdate.vue';

const SubscriptionRulesRoute = [
  {
    path:'',
    component: SubscriptionRulesTable,
    meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'subscription_rules', permission: 'READ' }
  },
  {
    path:'create',
    component: SubscriptionRulesCreate,
    meta: { name: 'SubscriptionRulesCreate', group: 'portaladministrativo.prices', path: 'subscription_rules', permission: 'CREATE' }
  },
  {
    path: 'update/:id',
    component: SubscriptionRulesUpdate,
    meta: { name: 'SubscriptionRulesUpdate', group: 'portaladministrativo.prices', path: 'subscription_rules', permission: 'READ' }
  },
]

export default SubscriptionRulesRoute
