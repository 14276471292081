const reglamentoTranslations = {
    es: {
        breadcrumbs: {
            title: 'Reglamento',
            description: 'Reglamento institucional'
        },
        label: {
            indices: 'Índice',
            shortCut: 'Accesos rápidos',
            search: 'Búsqueda',
        },
        reglamentoConfirm:{
            breadcrumbs: {
                title: 'Reglamento Institucional',
                description: 'Condiciones de uso'
            },
            title:'Reglamento',
            pdf:'Descarga PDF',
            condiciones:{
                title:'Acepto terminos y condiciones',
                subtitle:'Debe deslizar hasta el final para aceptar.'
            }
        }
    },
    en: {
    }
};  

export default reglamentoTranslations
