<template>
  <v-container class="container-custom" style="height: 100%;">
    <v-row>
      <Breadcrumbs :title="breadcrumbs.title"></Breadcrumbs>
    </v-row>
    <v-row justify="center">
      <Application :myData="myData"></Application>
    </v-row>
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Application from '../Components/Application/Application'

export default {
  components: {
    Breadcrumbs,
    Application,
  },
  data () {
    return {
      myData: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Nombre de la app 1',
          texto: 'Descripción de funciones de la aplicación. La cantidad de caracteres es limitada.',
          status: true,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Nombre de la app 2',
          texto: 'Descripción de funciones de la aplicación. La cantidad de caracteres es limitada.',
          status: true,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Nombre de la app 3',
          texto: 'Descripción de funciones de la aplicación. La cantidad de caracteres es limitada.',
          status: true,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Nombre de la app',
          texto: 'Descripción de funciones de la aplicación. La cantidad de caracteres es limitada.',
          status: true,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Nombre de la app',
          texto: 'Descripción de funciones de la aplicación. La cantidad de caracteres es limitada.',
          status: true,
        },

      ],
      breadcrumbs: {
        title: 'Campus virtual'
      }

    }
  }
}
</script>
