import NotFoundState from '@/components/NotFoundState/NotFoundState'
import CommissionsView from './Commissions';
import Config from './Config/Config';
import ConfigRoute from './Config/Routes';
import Ranges from './Ranges/Ranges';
import RangesRoute from './Ranges/Routes';
import Objectives from './Objectives/Objectives';
import ObjectivesRoute from './Objectives/Routes';
import Prizes from './Prizes/Prizes';
import PrizesRoute from './Prizes/Routes';
import Sellers from './Sellers/Sellers';
import SellersRoute from './Sellers/Routes';

const CommissionsRoute = {
    
    path: '/commissions',
    meta: {
        name: {
            en: 'Commissions',
            es: 'Comisiones'
        },
    },
    component: CommissionsView,
    children:[
        {
            path: '/',
            component: NotFoundState,
        },{
            path:'config',
            meta: { name: { es: 'Porcentaje', en: 'Percentage' } },
            component: Config,
            children: ConfigRoute
        },{
            path:'ranges',
            meta: { name: { es: 'Rangos de fecha', en: 'Date ranges' } },
            component: Ranges,
            children: RangesRoute
        },{
            path:'objectives',
            meta: { name: { es: 'Objetivos por periodo', en: 'Periodic objectives' } },
            component: Objectives,
            children: ObjectivesRoute
        },{
            path:'prizes',
            meta: { name: { es: 'Premios', en: 'Prizes' } },
            component: Prizes,
            children: PrizesRoute
        },{
            path:'sellers',
            meta: { name: { es: 'Agentes Comerciales', en: 'Commercial Agents' } },
            component: Sellers,
            children: SellersRoute
        }
    ]
};

export default CommissionsRoute