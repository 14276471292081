import NotFoundState from '@/components/NotFoundState/NotFoundState'
import StatementsRoute from './Statements/Routes';
import Statements from './Statements/Statements';
import ExamsView from './Exams';


const ExamsRoute = {
    
    path: '/exams',
    meta: {
        name: {
            en: 'Exams',
            es: 'Examenes'
        },
    },
    component: ExamsView,
    children:[
        {
            path: '/',
            component: NotFoundState,
        },{
            path:'statements',
            meta: { name: { es: 'Proyecto Q', en: 'Project Q' } },
            component: Statements,
            children: StatementsRoute
        }
    ]
};

export default ExamsRoute