import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('academic');

export class Sections extends Api {
  constructor(http, config = {}) {
    super(http, url, 'sections', config);
  }

  async getTeachers(nameRol = 'docente', config = {}) {
    config = {...this._config, ...config};
    return this._http.get(`${this._baseUrl}/teachers?page=0&length=1000&rolName=${nameRol}`, {}, config);
  }
  
}
