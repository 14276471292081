import Scholarships from '../Scholarships'
import ScholarshipsCreate from '../Views/ScholarshipsCreate'
import ScholarshipsTable from '../Views/ScholarshipsTable'
import ScholarshipsUpdate from '../Views/ScholarshipsUpdate'
import ScholarshipsDetails from '../Views/ScholarshipsDetails'

const ScholarshipsRoute =
{
  path: '/scholarships',
  meta: { 
    name: {
      en: 'Scholarships',
      es: 'Becas',
      }
    },
  component: Scholarships,
  children: [
    {
      path: '/',
      component: ScholarshipsTable,
      meta: { name: 'Index', group: 'portaladministrativo.prices', path: 'scholarships', permission: 'READ' }
    }, {
      path: 'create',
      component: ScholarshipsCreate,
      meta: { name: 'ScholarshipsCreate', group: 'portaladministrativo.prices', path: 'scholarships', permission: 'CREATE' }
    }, {
      path: 'update/:id',
      component: ScholarshipsUpdate,
      meta: { name: 'ScholarshipsUpdate', group: 'portaladministrativo.prices', path: 'scholarships', permission: 'UPDATE' }
    }, {
      path: 'details/:id',
      component: ScholarshipsDetails,
      meta: { name: 'ScholarshipsDetails', group: 'portaladministrativo.prices', path: 'scholarships', permission: 'READ' }
    },
  ]
};

export default ScholarshipsRoute;
