<template>
  <v-container fluid class="pt-0">
    <v-container fluid class="container-custom">
      <v-row no-gutters justify="center">
        <v-col sm="10">
          <Breadcrumbs
            :description="$t('modules.settings.email.breadcrumbs.description')"
            :title="$t('modules.settings.email.breadcrumbs.title')"
          ></Breadcrumbs>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col sm="10" class="viewSpaces">
          <template>
            <v-tabs hide-slider height="68px" class="settingsTabs">
            <v-tab class="text-capitalize">
              <div class="tab-container">
                <span class="tab-title">{{$t('modules.settings.email.tabs.advanced.title')}}</span>
              </div>
            </v-tab>
            <v-tab class="text-capitalize">
              <div class="tab-container">
                <span class="tab-title">{{$t('modules.settings.email.tabs.test.title')}}</span>
              </div>
            </v-tab>
            <!--
            <v-tab class="text-capitalize body-2 ">
              {{$t('modules.settings.email.tabs.templates.title')}}
            </v-tab>
            -->
            <v-tab-item class="mt-0">
              <OutlinedCard :removeBorderTop="true" :title="$t('modules.settings.email.tabs.advanced.title')" class="mb-6">
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.mail.label')">
                  <v-text-field
                    :error-messages="validate('smtpFrom')"
                    @change="(val)=>changeValue(val,'smtpFrom')"
                    outlined
                    v-model="$v.smtpFrom.$model"
                  ></v-text-field>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.name.label')">
                  <v-text-field
                    :error-messages="validate('smtpName')"
                    @change="(val)=>changeValue(val,'smtpName')"
                    outlined
                    v-model="$v.smtpName.$model"
                  ></v-text-field>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.host.label')">
                  <v-text-field
                    :error-messages="validate('smtpHost')"
                    @change="(val)=>changeValue(val,'smtpHost')"
                    outlined
                    v-model="$v.smtpHost.$model"
                  ></v-text-field>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.port.label')">
                  <v-text-field
                    :error-messages="validate('smtpPort')"
                    @change="(val)=>changeValue(val,'smtpPort')"
                    outlined
                    v-model="$v.smtpPort.$model"
                    @keypress="$validateIntegerInputNumber($event, $v.smtpPort.$model, null, 4)"
                  ></v-text-field>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.encriptationType.label')">
                  <v-radio-group :value="_self['encriptation']" @change="(val) => changeEncriptation(val)">
                    <v-radio :label="$t('modules.settings.email.tabs.advanced.items.encriptationType.options.noEncrypt')"
                             class="mb-2 mt-2"
                             color="primary" value="1"></v-radio>
                    <v-radio :label="$t('modules.settings.email.tabs.advanced.items.encriptationType.options.ssl')"
                             class="mb-2"
                             color="primary" value="2"></v-radio>
                    <v-radio :label="$t('modules.settings.email.tabs.advanced.items.encriptationType.options.tls')"
                             class="mb-6"
                             color="primary" value="3"></v-radio>
                  </v-radio-group>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.authentication.label')">
                  <v-radio-group :value="_self['settings/getAllCurrentValues']['smtpAuth']"
                                 @change="(val) => changeValue(val,'smtpAuth')">
                    <v-radio :label="$t('modules.settings.email.tabs.advanced.items.authentication.options.no')"
                             :value="false"
                             class="mb-2 mt-2" color="primary"></v-radio>
                    <v-radio :label="$t('modules.settings.email.tabs.advanced.items.authentication.options.yes')"
                             :value="true"
                             class="mb-6" color="primary"></v-radio>
                  </v-radio-group>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.userName.label')">
                  <v-text-field
                    :error-messages="validate('smtpUserName')"
                    @change="(val)=>changeValue(val,'smtpUserName')"
                    outlined
                    v-model="$v.smtpUserName.$model"
                  ></v-text-field>
                </OutlinedCardContent>
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.advanced.items.password.label')">
                  <v-text-field
                    :error-messages="validate('smtpPassword')"
                    @change="(val)=>changeValue(val,'smtpPassword')"
                    outlined
                    v-model="$v.smtpPassword.$model"
                    placeholder="*******"
                    :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="hidePass ? 'text' : 'password'"
                    @click:append="hidePass = !hidePass"
                  ></v-text-field>
                </OutlinedCardContent>
              </OutlinedCard>
              <div class="text-sm-right mt-6">
                <Button
                  :disabled="!canUpdate || $v.advancedOptions.$invalid"
                  :error-messages="error"
                  :loading="loading"
                  :success="success"
                  :successText="$t('actions.saved')"
                  :text="$t('actions.save')"
                  @clicked="save()"
                  @end="resetButtonValues()"
                  errorText="Error"
                >
                </Button>
              </div>
            </v-tab-item>
            <v-tab-item class="mt-0">
              <OutlinedCard :removeBorderTop="true" :title="$t('modules.settings.email.tabs.test.items.title')" class="mb-6">
                <OutlinedCardContent :label="$t('modules.settings.email.tabs.test.items.email')">
                  <v-text-field
                    outlined
                    v-model="$v.emailTest.$model"
                    :placeholder="$t('modules.settings.email.tabs.test.items.email')"
                    :error-messages="validate('emailTest')"
                  ></v-text-field>
                </OutlinedCardContent>
              </OutlinedCard>
              <div class="text-sm-right mt-6">
                <!--¡¡¡Add Events!!!-->
                <Button
                  :error-messages="error"
                  :loading="loading"
                  :success="success"
                  :successText="$t('actions.sent')"
                  :text="$t('actions.send')"
                  errorText="Error"
                  :disabled="!canUpdate || $v.emailTest.$invalid"
                  @clicked="sendEmail()"
                  @end="resetEmail"
                >
                </Button>
              </div>
            </v-tab-item>
            <!--(Hasta el 20/01/2020)
            <v-tab-item class="mt-4">
              <OutlinedCard :title="$t('modules.settings.email.tabs.templates.items.title')">
                <TextEditor></TextEditor>
              </OutlinedCard>
              <div class="text-sm-right mt-6">

                <Button
                  :error-messages="error"
                  :loading="loading"
                  :success="success"
                  :successText="$t('actions.accepted')"
                  :text="$t('actions.accept')"
                  errorText="Error"
                >
                </Button>
              </div>
            </v-tab-item>
            -->
          </v-tabs>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style lang="sass">
.settingsTabs
  .v-expansion-panels
    margin-left: 2px

    .v-expansion-panel
      border-radius: 5px !important

  .container .container--fluid
    padding: 0px !important

  .container
    padding-top: 0px

  .v-tabs-bar
    top: 4px

  .v-card__text
    padding-left: 0px

  .v-tabs-slider-wrapper
    color: #FFFFFF
    border-radius: 10px 10px 0 0

  .v-tab
    &:first-child
      .tab-container
        border-left: none
        border-right: none

    &:last-child
      .tab-container
        border-left: none
        border-right: none

    border-radius: 5px 5px 0 0
    margin-right: 0px
    color: #7e7e7e !important
    background-color: #f5f5f5
    border-bottom: solid 4px #c2c2c2
    width: 50%
    height: 68px
    max-width: initial
    padding: 0px
    font-weight: initial

    .tab-container
      min-height: 20px
      min-width: 100% !important
      border-left: 0.5px solid grey
      border-right: 0.5px solid grey

    .v-slide-group__wrapper
      margin-left: 12px

  .v-tab--active
    background-color: #FFFFFF
    opacity: 1.0
    font-weight: bold
    border-top: solid 1px #c2c2c2
    border-right: solid 1px #c2c2c2
    border-left: solid 1px #c2c2c2
    border-bottom: solid 1px #FFFFFF
    color: #7e7e7e !important
    border-radius: 5px 5px 0 0

    .tab-container
      border-left: none
      border-right: none
      .tab-title
        padding: 20px 0px
        border-bottom: 3px solid #0b22ff

</style>

<script>
import {mapGetters} from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'
// (Hasta el 20/01/2020) import TextEditor from '@/components/TextEditor/TextEditor'
import Button from '@/components/Button/Button'
import {email, numeric, required} from 'vuelidate/lib/validators'
import settingsServices from '@/plugins/settings.service'
import { AES, enc } from 'crypto-js';
import Configuration from '../../../../core/constants/configuration';

export default {
  components: {
    Breadcrumbs,
    OutlinedCard,
    OutlinedCardContent,
    // (Hasta el 20/01/2020) TextEditor,
    Button
  },
  data() {
    return {

      loading: false,
      success: false,
      error: false,
      modifications: {},

      // validations fields
      smtpFrom: null,
      smtpName: null,
      smtpHost: null,
      smtpPort: null,
      smtpUserName: null,
      smtpPassword: null,
      emailTest: null,
      hidePass: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.smtpFrom = this['settings/getAllCurrentValues'].smtpFrom;
      this.smtpName = this['settings/getAllCurrentValues'].smtpName;
      this.smtpHost = this['settings/getAllCurrentValues'].smtpHost;
      this.smtpPort = this['settings/getAllCurrentValues'].smtpPort;
      this.smtpUserName = this['settings/getAllCurrentValues'].smtpUserName;
      this.smtpPassword = this['settings/getAllCurrentValues'].smtpPassword ?  Buffer.from(AES.decrypt(
                                    this['settings/getAllCurrentValues']?.smtpPassword.split(';')[1],
                                    enc.Utf8.parse(Configuration.value('secret')), 
                                    { iv: enc.Base64.parse(this['settings/getAllCurrentValues'].smtpPassword.split(';')[0]) }
                                  ).toString(enc.Utf8), 'base64'    
                                ).toString('ascii') : '';
    },
    sendEmail() {
      if (!this.canUpdate) return false
      const data = { 'email' : this.emailTest }
      this.loading = true;
      settingsServices.sendEmailTest(data)
        .then(()=>{
          this.success = true;
        })
        .catch(()=>{
          this.error = true;
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    resetEmail() {
      this.emailTest = null
      this.$v.$reset()
    },
    save() {
      if (!this.canUpdate) return false
      this.loading = true;

      this.$store.dispatch('settings/updateState', this.modifications)
        .then(() => {
          // Modified on Vuex
          this.$store.dispatch('settings/updateAPI')
            .then(() => {
              this.success = true;
              // Launch snackbar
              this.$store.dispatch('settings/cleanTrash');
            })
            .catch(() => {
              this.error = true;
            })
        })
        .catch(() => {
          this.error = true;
        })
    },
    changeValue(value, field) {
      if (!this.canUpdate) return false
      if(field === 'smtpPassword'){
        const iv = `${(Math.random()+1).toString(16).substring(2, 10)}${(Math.random()+1).toString(16).substring(2, 10)}`;
        const emailB64 = Buffer.from(this.smtpPassword).toString('base64');
        const emailAES = AES.encrypt(
          enc.Utf8.parse(emailB64), 
          enc.Utf8.parse(Configuration.value('secret')), 
          { iv: enc.Utf8.parse(iv) }
        );
        value = `${emailAES.iv.toString(enc.Base64)};${emailAES}`
    }
      this.modifications[field] = value;
      this.$store.dispatch('settings/changeValue', {value, field})
        .then(() => {
          //
        })
        .catch(() => {
          //
        })
    },
    changeEncriptation(value) {
      if (!this.canUpdate) return false
      if (value == '1') {
        this.modifications.smtpTLSEnable = false;
        this.modifications.smtpSSLEnable = false;
      } else if (value == '2') {
        this.modifications.smtpTLSEnable = false;
        this.modifications.smtpSSLEnable = true;
      } else {
        this.modifications.smtpTLSEnable = true;
        this.modifications.smtpSSLEnable = true;
      }

      this.$store.dispatch('settings/changeValue', {
        value: this.modifications.smtpTLSEnable,
        field: 'smtpTLSEnable'
      });
      this.$store.dispatch('settings/changeValue', {
        value: this.modifications.smtpSSLEnable,
        field: 'smtpSSLEnable'
      });
    },
    resetButtonValues() {
      this.loading = false;
      this.success = false;
      this.error = false;
    },
    validate(inputValue) {
      let error = '';
      const that = this;

      String.prototype.toPascalCase = function () {
        return this.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
      };

      if (this.$v[inputValue].$invalid && this.$v[inputValue].$dirty) {
        const validationParams = this.$v[inputValue].$params;

        // First validate required
        if (validationParams.required) {
          if (!that.$v[inputValue].required) {
            error = that.$t(`modules.settings.email.tabs.advanced.validations.${inputValue}Required`);
          }
        }

        const paramsToArray = Object.values(validationParams);

        paramsToArray.forEach(param => {
          if (param.type !== 'required') {
            if (!that.$v[inputValue][param.type]) {
              error = that.$t(`modules.settings.email.tabs.advanced.validations.${inputValue + (param.type).toPascalCase()}`)
            }
          }
        })
      } else {
        error = '';
      }
      return error;
    },
  },
  computed: {
    canUpdate() {
      return this.$permissions.settings.communication.UPDATE
    },
    ...mapGetters([
      'settings/getAllCurrentValues'
    ])
  },
  validations: {
    smtpFrom: {
      required,
      email
    },
    smtpName: {
      required
    },
    smtpHost: {
      required
    },
    smtpPort: {
      required,
      numeric
    },
    smtpUserName: {
      required,
      email
    },
    smtpPassword: {
      required
    },
    emailTest:{
      required,
      email
    },

    //Validations Groups

    advancedOptions: ['smtpFrom','smtpName','smtpHost','smtpPort','smtpUserName','smtpPassword'],

  },
}
</script>

