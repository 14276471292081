import StatementsUpdate from '../Views/StatementsUpdate.vue'
import StatementsCreate from '../Views/StatementsCreate.vue'
import ProgramsTable from '../Views/Programs.vue'
import SubjectsTable from '../Views/SubjectsView.vue'
import Contents from '../Views/Contents.vue'
import ProgramsCreate from '../Views/ProgramsCreate.vue'
import ProgramsUpdate from '../Views/ProgramsUpdate.vue'
import StatementImport from '../Views/StatementImport.vue'

const statementsRoute = [
    {
        path:'/',
        component: SubjectsTable,
        meta: { name: 'Index', group: 'portaladministrativo.exams', path: 'statements', permission: 'READ' }
    },  
    {
        path:'programs/:subject_id',
        component: ProgramsTable,
        meta: { name: 'details', group: 'portaladministrativo.exams', path: 'statements', permission: 'READ' }
    }, 
    {
        path:'programs/:subject_id/create',
        component: ProgramsCreate,
        meta: { name: 'edit', group: 'portaladministrativo.exams', path: 'statements', permission: 'CREATE' }
    },
    {
        path:'programs/update/:program_id',
        component: ProgramsUpdate,
        meta: { name: 'edit', group: 'portaladministrativo.exams', path: 'statements', permission: 'CREATE' }
    },
    {
        path:'contents/:program_id',
        component: Contents,
        meta: { name: 'contents', group: 'portaladministrativo.exams', path: 'statements', permission: 'READ' }
    },   
    {
        path:'statement/:content_id/create',
        component: StatementsCreate,
        meta: { name: 'create', group: 'portaladministrativo.exams', path: 'statements', permission: 'CREATE' }
    },   
    {
        path:'statement/:content_id/update',
        component: StatementsUpdate,
        meta: { name: 'update', group: 'portaladministrativo.exams', path: 'statements', permission: 'UPDATE' }
    },    
    {
        path:'statement/:program_id/import',
        component: StatementImport,
        meta: { name: 'update', group: 'portaladministrativo.exams', path: 'statements', permission: 'UPDATE' }
    },    
];

export default statementsRoute
