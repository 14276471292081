<template>
  <div>
    <v-card
        v-bind="$attrs"
        outlined
        :class="cardStyle"
        @click="onPeriodClick"
    >
      <v-card-text class="d-flex justify-center align-center" style="height:100%">
        <div class="px-2 py-3">

          <v-row align="center">
            <v-checkbox
                :input-value="period.selected"
                :true-value="true"
                hide-details
                class="shrink mr-0 mt-0 pt-0"
                @input="onPeriodClick"
            ></v-checkbox>
            <strong class="PeriodCard card-title">{{periodDescription}}</strong>
          </v-row>
          <span class="PeriodCard card-message">{{$t('modules.portalalumno.procedures.reEnrollmentStatus.contractPeriodSelection.periodDescription', {startDate: period.classStartDate, endDate: period.classEndDate})}}</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PeriodCard',
  props: {
    index: {
      type: Number
    },
    period: {
      default() {},
      type: Object
    }
  },
  computed: {
    cardStyle() {
      if(this.period.selected) {
        return 'PeriodCard is-selected'
      }
      return 'PeriodCard'
    },
    periodDescription() {
      return `Período ${this.period?.code}`
    }
  },
  methods: {
    onPeriodClick() {
      if(this.index==0 && this.period.selected==true && this.period.longitud!==1){
        this.$emit('input', { index: this.index+1, action: 'unselect'})
      }
      this.$emit('input', { index: this.index, action: this.period?.selected ? 'unselect' : 'select'})
    }
  }
}
</script>

<style lang="sass">
.PeriodCard
  height: 100%
  cursor: pointer
  border-color: #9cabca
  &.card-message
    font-size: 11px
  &.card-title
    color: black
  &.is-selected
    background-color: #dae0fa

</style>