<template>
  <div fluid class=" pl-3 pr-3 mt-10" >
      <v-row class='mb-3 '>
        <v-col cols='5' class="caption">{{$t('modules.portalalumno.payments.checkingAccount.account.table.check')}}</v-col>
        <v-col cols='4' sm="5" class="caption">{{$t('modules.portalalumno.payments.checkingAccount.account.table.concept')}}</v-col>
        <v-col cols='2' class="caption">{{$t('modules.portalalumno.payments.checkingAccount.account.table.amount')}}</v-col>
      </v-row>
    <v-row v-for="payment in receipts" :key="payment.receiptId" class="payment-single mb-7 " @click="handleClick(payment)">
          <v-col cols="4" class="details p-0 m-0">
            <h3>{{payment.receiptType}}</h3>
            <p class="mb-0 p-0">Nro. {{payment.receiptNumber}}</p>
            <p class="text-caption m-0 p-0">{{$t('modules.portalalumno.payments.checkingAccount.account.table.receiptDate', {date: payment.receiptDetail && payment.receiptDetail.date})}}</p>
          </v-col>

          <v-col cols="4" class="description justify-start p-0 m-0">
            <p>{{payment.concept}}</p>
          </v-col>
          <v-col cols="2" sm="3" class="amount text-right p-0 m-0">
            <p v-if="payment.amount > 0" class="m-0">{{ payment.amount | $currency }}</p>
            <p v-else class="amount-red m-0">({{ payment.amount | $currency }})</p>
          </v-col>
          <v-col cols="1" class="p-0 m-0" >
            <v-icon>mdi-chevron-right</v-icon>
          </v-col>
     
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PaymentInfo',
  props: {
    receipts: {
      type: Array
    }
  },
  data() {
    return {
      showPrepaid: false,
      prepaidValid: false
    }
  },
  methods: {
    handleClick(data) {
      this.$emit('click', data);
    }
  },
}
</script>

<style scoped lang="sass">
.payment-single
    font-family: 'Roboto'
    font-size: 12px
    font-weight: bold
    height: 90px
    // width: 100%
    cursor: pointer
    border-radius: 5px
    box-shadow: 0 2px 2px 0 rgba(1, 33, 105, 0)
    border: solid 1px #d9d9d9
    background-color: white
    align-items: center
    // padding: 16px
    margin-bottom: 20px
.details
  display: flex
  flex-direction: column
.description
  display: flex
  color: rgba(0, 0, 0, 0.5)
.amount
  color: #687268
.amount-red
  color: #fc0000
@media only screen and (max-width: 600px) 
      .payment-single
          font-size: 10px
          
</style>
