<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!updateCommissionStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.config.update.breadcrumbs.title')"
                :description="$t('modules.commissions.config.update.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.config.update.sectionData.title')"
                        :subtitle="$t('modules.commissions.config.update.sectionData.subtitle')"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.config.update.sectionData.labels.billingProduct') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ commission.billingProduct ? commission.billingProduct.name : '-'}}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.config.update.sectionData.labels.studentType') }}</span>
                            </v-col>
                            <v-col sm="6" class="py-4">
                                <span>{{ commission.studentType ? commission.studentType.meaning : '-' }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.config.update.sectionData.labels.date') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="commission.dateFrom"
                                            :label="`${$t('modules.commissions.config.update.sectionData.labels.date')} *`"
                                            append-icon="mdi-calendar"
                                            readonly
                                            hide-details
                                            outlined
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="commission.dateFrom"
                                        no-title
                                        color="primary"
                                        scrollable
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="3" class="py-4">
                                <span class="caption text-uppercase">{{ $t('modules.commissions.config.update.sectionData.labels.percentege') }}</span>
                            </v-col>
                            <v-col sm="3" class="py-4">
                                <v-text-field
                                    v-model="$v.commission.percentageCommission.$model"
                                    :error-messages="$v.commission.percentageCommission.$error && $v.commission.percentageCommission.$dirty ? $t('modules.commissions.config.update.validations.percentageRequired') : ''"
                                    :label="`${$t('modules.commissions.config.update.sectionData.labels.percentege')} *`"
                                    @keypress="$validateDecimalInputNumber($event, $v.commission.percentageCommission.$model, 100)"
                                    append-icon="mdi-percent"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/config')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="saveButton.loading"
                            :success="saveButton.success"
                            :error="saveButton.error"
                            :text="$t('actions.save')"
                            :successText="$t('actions.saved')"
                            :errorText="'Error'"
                            :disabled="!canUpdate || $v.$invalid"
                            @end="resetButtonValues"
                            @clicked="updateCommission"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :title="successMessage.title"
            :type="successMessage.type"
            :actionPrimary="successMessage.actionPrimary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import Button from '@/components/Button/Button';
    import { required } from 'vuelidate/lib/validators';
    import { $commissions } from '../Services';
import { mapGetters } from 'vuex';

    export default {
        name: 'ConfigUpdate',
        components: {
            Breadcrumbs,
            SuccessMessage,
            OutlinedCard,
            Button,
        },
        data() {
            return {
                commission: {
                    dateFrom: null,
                    percentageCommission: null
                },
                updateCommissionStatus: false,
                saveButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                menu: false,
                successMessage: {
                    type: '',
                    title: null,
                    actionPrimary: null
                },
            }
        },
        computed: {
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.config.UPDATE
            },
            ...mapGetters({userId: 'commons/getUserID',})
        },
        mounted () {
            this.getCommission()
        },
        methods: {
            async getCommission() {
                try {
                    this.commission = (await $commissions.findById(this.$route.params.id)).data
                    this.commission.dateFrom = new Date(this.commission.dateFrom).toISOString().substr(0, 10)
                    this.commission.userId = this.userId
                } catch (error) {
                    this.commission = {percentageCommission: null}
                    throw error
                }
            },
            resetButtonValues () {
                this.saveButton.loading = false;
                this.saveButton.success = false;
                this.saveButton.error = false;
            },
            async updateCommission () {
                if(!this.canUpdate) return false;
                try {
                    this.saveButton.loading = true;
                    await $commissions.update(this.$route.params.id, this.commission)
                    this.successMessage.type = 'success',
                    this.successMessage.title = this.$t('modules.commissions.config.update.success_message.title'),
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/config') } }
                    this.saveButton.success = true;
                } catch (error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.config.update.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.resetButtonValues(); this.updateCommissionStatus = false } }
                    this.saveButton.error = true;
                    throw error;
                } finally {
                    this.saveButton.loading = false;
                    this.updateCommissionStatus = true;
                }
            },
        },
        validations: {
            commission: {
                percentageCommission: { required }
            }
        },
    }
</script>