import PeriodsTable from '../Views/PeriodsTable';
import PeriodsCreate from '../Views/PeriodsCreate';
import PeriodsUpdate from '../Views/PeriodsUpdate'

const PeriodsRoute = [
  {
    path: '/',
    component: PeriodsTable,
    meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'periods', permission: 'READ' }
  }, {
    path: 'create',
    component: PeriodsCreate,
    meta: { name: 'Periods Create', group: 'portaladministrativo.academic', path: 'periods', permission: 'CREATE' }
  }, {
    path: 'update/:id',
    component: PeriodsUpdate,
    meta: { name: 'Periods Update', group: 'portaladministrativo.academic', path: 'periods', permission: 'READ' }
  },
];

export default PeriodsRoute
