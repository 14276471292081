import Profile from '../Profile'
import ProfileUpdate from '../Views/ProfileUpdate'

const ProfileRoute =
{
  path: '/profile',
  meta: { 
    name: {
      en: 'Profile',
      es: 'Perfil',
      }
    },
  component: Profile,
  children: [
    {
      path: '/',
      component: ProfileUpdate,
      meta: { name: 'Index' }
    }
  ]

}

export default ProfileRoute
