
import ProfessionalizingPractice from '../ProfessionalizingPractice';
import ProfessionalizingPracticeView from '../Views/ProfessionalizingPracticeView'

const ProfessionalizingPracticeRoute =
{
    path: '/professionalizing-practice',
    meta: {
        name: {
            en: 'Practica profesionalizante',
            es: 'Practica profesionalizante'
        }
    },
    component: ProfessionalizingPractice,
    children: [
        {
            path: '',
            component: ProfessionalizingPracticeView,
            meta: { name: 'Index', group: 'portalalumno', path: 'professionalizing_practice', permission: 'READ' }
        },
    ]
}

export default ProfessionalizingPracticeRoute