
import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('student');

export class CalendarDates extends Api {
  
  constructor(http, config = {}) {
    super(http, url, 'alumnos/fechasCalendarioDashboards', config);
  }

}