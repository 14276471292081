<template>
    <v-container fluid class="d-flex pa-0 pb-8">
        <v-container class="container-custom" v-if="!createdNewSanction">
            <v-row>
                <v-col>
                    <Breadcrumbs
                        :title="$t('modules.academic.sanctions.update.breadcrumbs.title')"
                        :description="$t('modules.academic.sanctions.update.breadcrumbs.description')"
                    ></Breadcrumbs>
                </v-col>
            </v-row>
            <v-row justify="center"  >

                    <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
                        <OutlinedCard
                            :title="$t('modules.academic.sanctions.create.title')"
                            :subtitle="$t('modules.academic.sanctions.create.subtitle')"
                            class="mb-6"
                        >
                          <v-row class="mt-5">
                              <v-col sm="8">
                                  <v-textarea
                                      v-model="$v.description.$model"
                                      @blur="$v.description.$touch()"
                                      outlined
                                      no-resize
                                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? !$v.description.required ? $t('modules.academic.sanctions.create.errors_validations.name.required') : !$v.description.maxLength ?$t('modules.academic.sanctions.create.errors_validations.name.maxLength') :!$v.description.minLength ? $t('modules.academic.sanctions.create.errors_validations.name.minLength') : !$v.description.alphaNumWithSpaces ? $t('modules.academic.sanctions.create.errors_validations.name.alphaNumWithSapces'): null : ''"
                                      :label="`${$t('modules.academic.sanctions.create.labels.description')} *`"
                                  ></v-textarea>
                              </v-col>
                          </v-row>

                    <v-divider class="mt-6 mb-8"></v-divider>
                </OutlinedCard>
                <div class="d-flex justify-end mb-4">
                    <Button
                    white
                    :text="$t('actions.cancel')"
                    @clicked="$router.replace('/academic/sanctions')"

                    class="mr-4"
                    ></Button>

                    <Button
                    :loading="createButton.loading"
                    :success="createButton.success"
                    :error="createButton.error"
                    :text="$t('modules.academic.sanctions.update.btn.update')"
                    :disabled="!canUpdate"
                    :successText="$t('actions.created')"
                    @clicked="updateSanction"
                    :errorText="'Error'"

                    ></Button>
                </div>
                </v-col>
            </v-row>
        </v-container>
              <SuccessMessage
                v-else
                :type="successMessage.type"
                :title="successMessage.title"
                :actionPrimary="successMessage.actionPrimary"
                :actionSecondary="successMessage.actionSecondary"
                />
    </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import Button from '@/components/Button/Button';
import { $sanction } from '../Services';
import { required, maxLength, minLength, helpers } from 'vuelidate/lib/validators';
    export default {
       name:'SanctionsUpdate',
        components:{
            Breadcrumbs,
            OutlinedCard,
            Button,
            SuccessMessage,


        },
        data() {
            return {
              breadcrumbs: {
                title: 'Nueva Sancion',
                description: 'Crea una nueva sancion',
              },
              sanction: {},
              description: null,
              createdNewSanction: false,
              status: false,
              successMessage: {
                type: 'success',
                title: this.$t('modules.benefits.create.success_message.title'),
                actionPrimary: { text: this.$t('modules.benefits.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/sanctions') } },
                actionSecondary: null,
              },
               createButton: {
                loading: false,
                success: false,
                error: false
              },
            }
        },
        mounted() {
            this.getSanction()
        },
        methods: {
            async getSanction() {
                try {
                    const sanction = await $sanction.findById(this.$route.params.id)
                    this.sanction = sanction.data;
                    this.description = sanction.data.description;
                } catch (error) {
                    this.sanction = {}
                    throw error
                }
            },

            async updateSanction() {
              if(this.canUpdate){
                try{
                  const {id} = this.$route.params;
                  const newSanction = {
                    discharge: this.sanction.discharge,
                    code:  this.sanction.code,
                    description: this.description,
                    status : this.sanction.status,
                    daysDuration: this.sanction.daysDuration,
                    sanctionType: {
                      value : this.sanction.sanctionType.value
                    },
                    sanctionLevelType:{
                      value: this.sanction.sanctionLevelType.value
                    },
                    moduleList: this.sanction.moduleList,
                    userLogged: this.sanction.userLogged
                  };
                  await $sanction.update(id, newSanction);
                  this.successMessage.type = 'success';
                  this.successMessage.title = this.$t('modules.academic.sanctions.update.succesMessage');
                  this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/academic/sanctions') } };
                  this.createButton.success = true;
                  this.createdNewSanction = true;
                }catch(error){
                  this.successMessage.type = 'error';
                  this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.sanctions.update.error_message.title');
                  this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/academic/sanctions') } };
                  this.successMessage.actionSecondary = null;
                  this.createButton.loading = true;
                  this.createButton.error = true;
                }
              }
            }
        },
        computed:{
          canUpdate() {
            return this.$permissions.portaladministrativo.academic.sanctions.UPDATE
          }
        },
        validations:{
          description: {
            required,
            alphaNumWithSpaces: helpers.regex('withSpace', /^[a-z0-9]+(?:[a-z0-9]+\s[a-z0-9]*)*$/i),
            maxLength: maxLength(50),
            minLength: minLength(10)
          },
        }
    }
</script>
