<template>
   <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom">
      <Breadcrumbs
        isDescriptionFullLength
        :callback="goBack"
      ></Breadcrumbs>
      <v-row v-if="true">
        <v-col class="viewSpaces" cols="12" >
            <OutlinedCard
            :title="$t(`modules.portalalumno.procedures.procedureDocument.OutlineCard.title`)"
            :subtitle="$t(`modules.portalalumno.procedures.procedureDocument.OutlineCard.subtitle`)"
            class="mb-6">
            <v-row>
                <v-col cols="6">
                      <DropFiles
                        :title="$t(`modules.portalalumno.procedures.procedureDocument.dropfileDni.title`)"
                        :subtitle="$t(`modules.portalalumno.procedures.procedureDocument.dropfileDni.subtitle`)"
                        extension=".jpg"
                        typeFile="image/jpeg"
                        limitSizeBytes="4000000"
                        :multiple="true"
                        :required="false"
                        @update:fileInput="fileInput"
                        />
                </v-col>
                <v-col cols="6">
                    <DropFiles
                        :title="$t(`modules.portalalumno.procedures.procedureDocument.dropfileAnalitico.title`)"
                        :subtitle="$t(`modules.portalalumno.procedures.procedureDocument.dropfileAnalitico.subtitle`)"
                        extension=".jpg"
                        typeFile="image/jpeg"
                        limitSizeBytes="4000000"
                        :required="false"
                        :multiple="true"
                        @update:fileInput="fileInput"
                        />
                </v-col>
            </v-row>
            </OutlinedCard>
        </v-col>
        <v-col cols="11">
          <div class="d-flex justify-end">
                    <Button
                      white
                      :text="$t('actions.cancel')"
                      @clicked="$router.replace('/procedures')"
                      class="mr-4"
                    ></Button>

                    <Button
                      :loading="createButton.loading"
                      :success="createButton.success"
                      :error="createButton.error"
                      :text="$t('actions.create')"
                      :successText="$t('actions.created')"
                      :errorText="$t('actions.error')"
                    
                    ></Button>
                  </div>
        </v-col>
        <v-col cols="6">
          <div class="">
            <h5>{{$t(`modules.portalalumno.procedures.procedureDocument.information.title`)}}</h5>
            <Button
            class="mt-4 "
            :text="$t(`modules.portalalumno.procedures.procedureDocument.information.buttonText`)"
            @clicked="$router.push('/reglamento')"
            outlined
            size="sm"
          ></Button>
          </div>
        </v-col>
      </v-row>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
  import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
  import DropFiles from '@/components/DropFiles/DropFiles';
  import Button from '@/components/Button/Button';
  import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';

    export default {
        name:'ProcedureDocumentation',
        components:{
            Breadcrumbs,
            OutlinedCard,
            DropFiles,
            Button,
            SuccessMessage
        },
        data() {
            return {
                 createButton: {
                  loading: false,
                  success: false,
                  error: false
                },
                 successMessage: {
                  type: 'success',
                  title: this.$t(`modules.portalalumno.procedures.procedureDocument.successMessage.title`),
                  subtitle:this.$t(`modules.portalalumno.procedures.procedureDocument.successMessage.subtitle`),
                  actionSecondary: { text: this.$t('modules.benefits.create.success_message.actions.secondary_text'), callback: () => { this.createNewBenefitStatus = false } }
                },
            }
        },
        methods: {
                goBack () {
                    this.$trackEvent('Tramites', 'Cancelar', 'Retiro')
                    this.$router.push('/procedures')
                },
                fileInput(){
                    
                }
        },
    }
</script>

<style lang="scss" scoped>

</style>