<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewDepartmentStatus">
        <Breadcrumbs
          :title="$t('modules.departments.create.breadcrumbs.title')"
          :description="$t('modules.departments.create.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <OutlinedCard
                :title="$t('modules.departments.create.title')"
                :subtitle="$t('modules.departments.create.subtitle')"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="6" class="py-2">
                    <v-text-field
                      counter="75"
                      :error-messages="($v.name.$anyDirty && $v.name.$anyError) ? ((!$v.name.required) ? $t('modules.departments.create.validation_errors.name') : $t('modules.departments.create.validation_errors.name_length')) : ''"
                      @blur="$v.name.$touch()"
                      v-model="$v.name.$model"
                      :label="`${$t('modules.departments.create.labels.name')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col sm="3" class="py-2">
                    <v-text-field
                      :error-messages="($v.code.$anyDirty && $v.code.$anyError) ? ((!$v.code.required) ? $t('modules.departments.create.validation_errors.code') : $t('modules.departments.create.validation_errors.code_unique')) : ''"
                      :loading="loadingCode"
                      v-model.trim="$v.code.$model"
                      @blur="validateCode"
                      :label="`${$t('modules.departments.create.labels.code')} *`"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" class="py-2">
                    <v-textarea
                      outlined
                      :label="`${$t('modules.departments.create.labels.description')} *`"
                      :error-messages="($v.description.$dirty && $v.description.$invalid) ? $t('modules.departments.create.validation_errors.description') : ''"
                      @blur="$v.description.$touch()"
                      v-model="$v.description.$model"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <OutlinedCard
                :title="$t('modules.departments.create.section_subjects.title')"
                :subtitle="$t('modules.departments.create.section_subjects.subtitle')"
                class="mb-6"
              >
                <v-row>
                  <v-col sm="12" class="py-2">
                   <TransferList
                      alwaysSearchToBackend
                      :avatar="false"
                      :loading="loadingSearchSubjects"
                      :firstListTitle="$t('modules.scholarships.create.sectionDocumentation.transferList.firstTitle')"
                      :secondListTitle="$t('modules.scholarships.create.sectionDocumentation.transferList.secondTitle')"
                      :availableItems.sync="subjects"
                      :added-items.sync="subjectsAdded"
                      @searchItemLeftOnBackend="(val) => getSubjects(val)"
                      areEquals="id"
                    ></TransferList>
                    <v-alert
                      text
                      type="warning"
                      class="mt-4"
                      v-if="subjectsAdded.length === 0"
                      >{{ $t('modules.departments.create.validation_errors.subjects')  }}</v-alert>
                  </v-col>
                </v-row>
              </OutlinedCard>
              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/departments')"
                  @end="resetButtonValues()"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="$t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="'Error'"
                  :disabled="!canCreate || !$v.formValid.$anyDirty || $v.formValid.$anyError"
                  @end="resetButtonValues()"
                  @clicked="createDepartment"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :actionSecondary="successMessage.actionSecondary"
      />
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import TransferList from '@/components/TransferList/TransferList';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import { $departments, $subjects } from '../Services';
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'DepartmentsCreate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    TransferList,
    SuccessMessage,
  },
  data () {
    return {
      paginationLimit : 100,
      loadingSearchSubjects: false,
      createNewDepartmentStatus: false,
      loadingCode: false,
      codeUnique: null,
      createButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: 'success',
        title: this.$t('modules.departments.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.departments.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/departments') } },
        actionSecondary: { text: this.$t('modules.departments.create.success_message.actions.secondary_text'), callback: () => { this.createNewDepartmentStatus = false } }
      },

      availableSubjects: [],
      filteredSubjects: [],

      name: null,
      code: null,
      description: null,
      selectedSubjects: [],

      subjects: [],
      subjectsAdded: [],
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(75),
    },
    code: {
      required,
      unique () {
        return this.codeUnique !== false;
      },
    },
    description: { required },
    formValid: ['name', 'code', 'description']
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.academic.departments.CREATE && this.subjectsAdded.length !== 0
    },
  },
  methods: {
    async getSubjects(subjectStr) {
      
      const subject = subjectStr ? subjectStr.toUpperCase() : undefined;
      
      try {
        this.loadingSearchSubjects = true
        let subjects = [];
        if (subject) {
        subjects = await $subjects.find(null, null, {
            params: { name: subject, code: subject, length: this.paginationLimit, orderBy: 'id', orientation: 'desc' }
        });
        } else {
        subjects = await $subjects.find(null, null, {
            params: { length: this.paginationLimit, orderBy: 'id', orientation: 'desc' }
        });
        }
        if (subjects.data && subjects.data.content.length) {
        subjects = subjects.data.content.map(item => {
            return {
            id: item.id,
            name: `${item.code  } ${  item.description}`
            };
        });
        this.subjects = subjects;
        }
      } finally {
        this.loadingSearchSubjects = false
      }
    },
    async validateCode () {
      this.$v.code.$touch();
      if (this.$v.code.$model) {
        this.loadingCode = true;
        try {
          const response = await $departments.find('exists', null, { params: { code: this.$v.code.$model } });
          this.codeUnique = !response.data.exists;
          this.loadingCode = false;
        } catch (error) {
          this.codeUnique = null;
          throw error
        } finally {
          this.$v.code.$touch();
        }
      }
    },
    async createDepartment () {
      if (!this.canCreate) return false
      this.createButton.loading = true;

      const subjects = [];
      this.subjectsAdded.forEach(subject => {
        subjects.push({ id: subject.id });
      });

      const newDepartment = {
        name: this.name,
        code: this.code.toString(),
        description: this.description,
        subjects,
      };

      try {
        await $departments.create(newDepartment);
        this.successMessage.type = 'success';
        this.successMessage.title = this.$t('modules.departments.create.success_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.departments.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/departments') } };
        this.successMessage.actionSecondary = { text: this.$t('modules.departments.create.success_message.actions.secondary_text'), callback: () => { this.createNewDepartmentStatus = false } };
        this.createButton.success = true;
        this.clearData();
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = this.$t('modules.departments.create.error_message.title');
        this.successMessage.actionPrimary = { text: this.$t('modules.departments.create.success_message.actions.back'), callback: () => { this.createNewDepartmentStatus = false } }
        this.successMessage.actionSecondary = null;
        this.createButton.error = true;
      } finally {
        this.createNewDepartmentStatus = true;
        this.createButton.loading = false;
      }
    },
    clearData () {
      this.resetButtonValues();
      this.$v.$reset();
      this.loadingCode = false;
      this.codeUnique = null;
      this.name = null;
      this.code = null;
      this.description = null;
      this.selectedSubjects = [];
      this.subjectsAdded = [];
    },
    resetButtonValues () {
      this.createButton.loading = false;
      this.createButton.success = false;
      this.createButton.error = false;
    }
  },
  mounted () {
    this.getSubjects()
  }
}
</script>
