const Breadcrumbs = {
    es: {
        home: 'Inicio',
    },
    en: {
        home: 'Home',
    }
};

export default Breadcrumbs
