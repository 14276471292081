<template>
  <v-container fluid class="pa-0 pb-8 notfound-bg fullHeight d-flex align-center" :style="`background-image: url(${require('@/assets/notfound-background.svg')})`">
    <v-container class="container-custom viewSpaces d-flex align-center">
      <v-row>
        <v-col sm="5" class="d-flex flex-column">
          <h1 class="display-2 font-weight-bold mt-4 mb-2">
            {{ $t('components.notFoundState.title') }}
          </h1>
          <h5 class="headline mb-6">
            {{ $t('components.notFoundState.subtitle') }}
          </h5>
          <p>
            {{ $t('components.notFoundState.description') }}
          </p>
          <div class="d-flex mt-8">
            <Button
              :text="$t('actions.goToHome')"
              @clicked="goHome"
              :outlined="hasPreviousRoute"
            >
            </Button>
            <Button
              class="ml-6"
              v-if="hasPreviousRoute"
              :text="$t('actions.return')"
              @clicked="goBack"
            >
            </Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Button from '@/components/Button/Button';

export default {
  components: {
    Button
  },

  data() {
    return {
      currentRoute: {},
      previousRoute: {},
      hasPreviousRoute: null
    }
  },

  methods: {
    goBack () {
      this.$router.go(-1);
    },
    goHome () {
      this.$router.push('/');
    }
  },

  created () {
    if (window.history.length > 1) {
      this.hasPreviousRoute = true;
    }
  }
}
</script>

<style scoped lang="sass">
  .notfound-bg
    background-color: unset !important
    background-size: contain
    background-repeat: no-repeat
    background-position: right top
</style>
