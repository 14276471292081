/* eslint-disable no-useless-catch */
<template>
    <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot>
          <v-card>
            <v-container class="container-custom ">
              <v-row class="justify-end">
                <v-btn
                  icon
                  light
                  @click="$emit('hideArea')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
              <v-row class="px-12">
                <v-col cols="12">
                  <div class="text-h5 ">{{data ? $t('modules.messaging.template.newArea.editar') : $t('modules.messaging.template.newArea.create')}} {{$t('modules.messaging.template.newArea.title')}}</div>
                </v-col>
                <v-col cols="8" >
                <v-text-field
                  v-model="$v.name.$model"
                  :error-messages="($v.name.$dirty && $v.name.$invalid) ?
                  ((!$v.name.required) ? $t('modules.messaging.template.newArea.error.name.requerido') :

                  (!$v.name.maxLength) ? $t('modules.messaging.template.newArea.error.name.maxLength') :
                  '') :
                  ''"
                  @blur="$v.name.$touch()"
                  outlined
                  :label="$t('modules.messaging.template.newArea.labelName')"
                ></v-text-field>
              </v-col>
                <v-col cols="4" >
                <v-text-field
                  v-model="code"
                  :error-messages="($v.code.$dirty && $v.code.$invalid) ?
                  ((!$v.code.required) ? $t('modules.messaging.template.newArea.error.code.requerido') :

                  (!$v.code.maxLength) ? $t('modules.messaging.template.newArea.error.code.maxLength') :
                  '') :
                  ''"
                  @blur="$v.code.$touch()"
                  outlined
                  :label="$t('modules.messaging.template.newArea.labelCode')"
                ></v-text-field>
              </v-col>
              </v-row>
              <v-row class="d-flex justify-end px-12 mb-4">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$emit('hideArea')"
                  class="mr-4"
                ></Button>
                <Button
                  :loading="createButton.loading"
                  :success="createButton.success"
                  :error="createButton.error"
                  :text="data ? $t('actions.edit') :  $t('actions.create')"
                  :successText="$t('actions.created')"
                  :errorText="$t('actions.error')"
                  @clicked="submit"
                  :disabled="!data ? !canCreate : !canUpdate"
                ></Button>
              </v-row>
            </v-container>
          </v-card>
        </template>
      </v-dialog>

</template>

<script>
 import Button from '@/components/Button/Button';
 import { $workflowAreas } from '../Services/index'
 import {
  required,
  // alphaNum,
  maxLength,
  } from 'vuelidate/lib/validators';
// import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';


    export default {
        name: 'NewArea',
        components:{
          Button,
          // SuccessMessage
        },
        props:{
          dialog:{type : Boolean, default: false},
          canCreate:{type:Boolean, default:false},
          data:{type: Object, default:null },
          canUpdate:{type: Boolean, default:false }
        },
        data() {
          return {
            name:'',
            code:'',
            createButton: {
              loading: false,
              success: false,
              error: false
            },
            successMessage: {
              type: null,
              title: null,
              actionPrimary: null,
              actionSecondary: null
            },
          }
        },
        methods: {
          submit() {
           if (this.$v.$invalid ) {
              this.$v.$touch();
           }else {
              this.createArea()
            }
        },
       async createArea(){
         this.createButton.loading = true;
           if(this.data){
                 try {
                  await $workflowAreas.update(this.data.id, {name:this.name, areaCode : this.code});
                  this.createButton.success = true;
                  this.createButton.loading = false;
                  this.$emit('getMessages')
                  this.$emit('hideArea')
              } catch (error) {
                this.createButton.loading = false;
                this.createButton.error = true;
                throw error
              }
            }else{
              // eslint-disable-next-line no-useless-catch
              try {
                   await $workflowAreas.create({name:this.name, areaCode : this.code})
                  this.createButton.success = true;
                  this.createButton.loading = false;
                  this.$emit('getMessages')
                  this.$emit('hideArea')
              } catch (error) {
                this.createButton.loading = false;
                this.createButton.error = true;
                throw error
              }
            }
        }
      },
      validations: {
        
          name:{
            required,
            maxLength: maxLength(30),
          },
          code:{
            required,
            maxLength: maxLength(10),
          },
        },
        watch: {
          data() { 
            if(this.data){
              this.name = this.data.name
              this.code = this.data.areaCode
            } else{
              this.name = ''
              this.code = ''
            }
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>