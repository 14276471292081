<template>
  <v-card 
    v-bind="$attrs" 
    outlined 
    style="height:100%" 
    class="InfoCard" 
    :class="config.class" 
    @click="item.action.callback"
  >
    <v-card-text style="height:100%">
      <div class="main-col px-2 py-2">
        
        <div class="mb-2"><strong class="card-title">{{item.title}}</strong></div>
        
        <div class="mb-2">
          <span v-for="(doc, i) in item.documentation" :key="i">{{doc}}<br></span>
        </div>
        
        <div class="actions-div">

          <div v-if="showStatus" class="mb-4">
            <div class="icon-container mr-2"><v-icon>{{config.icon}}</v-icon></div> <span class="black--text">{{config.text}}</span>
          </div>
          <v-btn 
            v-if="item.action.text" 
            :disabled="item.action.disabled" 
            outlined 
            rounded 
            color="primary" 
            class="mb-2"
            small
            @click.stop="item.action.callback"
          >
            {{item.action.text}}
          </v-btn>

          <span class="primary--text">{{item.observation}}</span>

        </div>
       
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    item: Object,
  },
  computed: {
    config() {
      const config = {
        class: '',
      };

      switch (this.item.status) {
        case 'P':
        default:
          config.class = '';
          config.icon = '';
          config.text = '';
          break;
        case 'B':
          config.class = 'status-blocked';
          config.icon = '';
          config.text = '';
          break;
        case 'R':
          config.class = 'status-revision';
          config.icon = 'mdi-progress-clock';
          config.text = 'En revisión';
          break;
        case 'W':
          config.class = 'status-warning';
          config.icon = 'mdi-alert-outline';
          config.text = 'Observada';
          break;
        case 'S':
          config.class = 'status-success';
          config.icon = 'mdi-check';
          config.text = 'Aprobada';
          break;
        case 'D':
          config.class = 'status-alert';
          config.icon = 'mdi-close';
          config.text = 'Desaprobada';
          break;
      }

      return config;
    },
    showStatus() {
      return !(['P', 'B'].includes(this.item.status));
    }
  }
}
</script>

<style lang="sass">
  .InfoCard
    min-height: 200px
    box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)

    .main-col
      display: flex
      flex-flow: column nowrap
      height: 100%

      .card-title
        color: #000

      div:not(.actions-div)
        flex-grow: 0
      
      .actions-div
        align-self: flex-end
        flex-grow: 1
        display: flex
        flex-flow: column nowrap
        width: 100%
        align-items: flex-start
        justify-content: flex-end

        > div
          display: flex
          justify-content: center
          align-items: center

        .icon-container
          border-radius: 50%
          padding: 5px
          float: left
          display: flex
          justify-content: center
          align-items: center
          background: grey
          
          .v-icon
            color: white
            font-size: 18px

    

    &.status-success
      box-shadow: 0 8px 14px 0 rgba(24, 154, 43, 0.29)
      border-color: #008614 !important
      
      .actions-div .icon-container
        background: #008614

    &.status-revision
      .actions-div .icon-container
        background: #3d6cd9

    &.status-warning
      box-shadow: 0 8px 14px 0 rgba(#f58c05, 0.29)
      border-color: #f58c05 !important
      
      .actions-div .icon-container
        background: #f58c05

    &.status-alert
      box-shadow: 0 8px 14px 0 rgba(red, 0.29)
      border-color: red !important
      
      .actions-div .icon-container
        background: red

    &.status-blocked
      box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15)
      background: #f1f1f1 !important
      
      .card-title
        color: #274280

    &:hover
      cursor: pointer
      box-shadow: 0 8px 14px 0 rgba(0, 44, 142, 0.15) !important
      border: solid 1px #0036b1 !important
</style>