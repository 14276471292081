import Reports from '../Reports.vue'
import ReportsTable from '../Views/ReportsTable.vue'

const ReportsRoute = {
    path: '/reports',
    meta: {
        name: {
            en: 'Reports',
            es: 'Reportes'
        }
    },
    component: Reports,
    children: [
        {
            path: '/',
            component: ReportsTable,
            name: 'reports_table',
            meta: { name: 'Index', group: 'portaladministrativo', path: 'reports', permission: 'READ' }
        }
    ]
};

export default ReportsRoute