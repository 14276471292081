<template>
  <v-navigation-drawer
    v-model="open"
    fixed
    temporary
    right
    :width="`${String(width)}vw`"
    class="TemporaryRightDrawer no-scroll"
    stateless
  >
    <div class="TemporaryRightDrawer__header d-flex py-4 px-6 align-center">
      <v-icon class="mr-8" @click="$emit('tmpDrawerOpen')">mdi-close</v-icon>
      <div class="d-flex flex-column">
        <h1 class="title primary--text">{{ title }}</h1>
        <h6 class="caption">{{ description }}</h6>
      </div>
    </div>
    <v-divider></v-divider>
    <v-progress-linear absolute color="primary" indeterminate v-if="loading.status && loading.drawer"></v-progress-linear>
    <div :class="['TemporaryRightDrawer__content', scroll ? 'scrollableContent' : '']">
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'TemporaryRightDrawer',
    props: {
      open: Boolean,
      title: String,
      description: String,
      width: { type: Number,  default: 60 },
      scroll: { type: Boolean, default: true },
    },
    computed: {
      ...mapGetters({
        loading: 'commons/getLoadingState',
      })
    },
    watch: {
      open() {
        this.$store.dispatch('commons/setLoadingDrawer', this.open)
      }
    }
  }
</script>

<style lang="sass">
  .TemporaryRightDrawer
    transition-duration: 0.5s
    &.no-scroll, &.no-scroll > div:first-child
      overflow: hidden
</style>
