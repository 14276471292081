<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6" fluid>
        <Breadcrumbs
          :title="$t('modules.roles.table.breadcrumbs.title')"
          :description="$t('modules.roles.table.breadcrumbs.description')"
        >
          <Button
            v-if="canCreate"
            @clicked="newRol"
            :icon="'mdi-plus'"
            :text="$t('actions.new')"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="roles"
              :clear-selected="clearSelected"
              :permissions="{users: {read: canReadUsers}, roles: {update: canUpdate, delete: canDelete}}"
              @bulk-delete="(roles)=>{bulkDeletePopUp(roles)}"
              @deleteUser="(id)=>deleteRolPopUp(id)"
              @updateUser="(id)=>{updateRol(id)}"
              @seeUsers="(id)=>{seeUsers(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>
import SuperTable from '../Components/SuperTable/SuperTable'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
import Button from '@/components/Button/Button'

export default {
  name: 'RolesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button,
  },
  data () {
    return {
      clearSelected: '',
      headers: [
        {
          text: this.$t('modules.roles.table.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('modules.roles.table.headers.description'),
          value: 'description',
        },
        {
          text: this.$t('modules.roles.table.headers.status'),
          value: 'status',
        },
        {
          text: this.$t('modules.roles.table.headers.modules'),
          value: 'modules',
        },
        {
          text: this.$t('modules.roles.table.headers.users'),
          value: 'users',
        },
        {
          text: '',
          value: 'action'
        }
      ],
      roles: []
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    canReadUsers() {
      return this.$permissions.portaladministrativo.users.READ
    },
    canCreate() {
      return this.$permissions.portaladministrativo.roles.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.roles.READ || this.$permissions.portaladministrativo.roles.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.roles.DELETE
    },
  },
  methods: {

    deleteRolPopUp (id) {
      const rol = this.roles.find(rol => rol.id == id);
      if (rol.users)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.roles.table.messages.deleteNotAllowed'),
          content: [{
            value: rol.name
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback() { } },
          actionSecondary: null,
          icon: { color: 'warning', name: 'mdi-cancel' },
          color: 'primary',
        });
      else
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.roles.table.messages.delete'),
          content: [{
            value: rol.name
          }],
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.deleteRol(id) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {

            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
    },
    fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();
      this.$users.getRoles().then(data => {
        data = data.content.map(rol => {
          rol.status = true;
          rol.avatar = rol.name.toUpperCase().slice(0, 2);
          rol.selected = false;
          return rol;
        });
        this.roles = data;
      })
    },
    bulkDeletePopUp (roles) {
      const rolesWithUsers = roles.filter(rol => !!rol.users);
      if (rolesWithUsers.length)
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.roles.table.messages.deleteNotAllowed'),
          content: rolesWithUsers.map(rol => ({
            value: rol.name
          })),
          actionPrimary: { text: this.$t('actions.accept'), callback() { } },
          actionSecondary: null,
          icon: { color: 'warning', name: 'mdi-cancel' },
          color: 'primary',
        });
      else
        this.$store.dispatch('commons/openPopUp', {
          title: this.$t('modules.roles.table.messages.delete'),
          content: roles.map(user => ({
            value: user.name
          })),
          actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(roles) },
          actionSecondary: {
            text: this.$t('actions.cancel'), callback() {

            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        })
    },
    bulkDelete (roles) {
      const itemsForDelete = roles.map((rol) => {
        return { id: rol.id }
      });
      this.$users.bulkDeleteRoles(itemsForDelete)
        .then(() => {
          this.fetchData()
        })
        .catch(() => {
          this.fetchData()
        })
    },
    newRol () {
      if (this.canCreate) this.$router.push('/roles/create')
    },
    updateRol (id) {
      if (this.canUpdate) this.$router.push(`roles/update/${id}`)
    },
    seeUsers (id) {
      if (this.canReadUsers) this.$router.push(`users?rol=${id}`)
    },
    deleteRol (id) {
      this.$users.deleteRol(id)
        .then(() => {
          this.roles = this.roles.filter(rol => rol.id !== id);
        })
    }
  }
}
</script>
