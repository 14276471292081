import Communications from '../Communications.vue';
import CommunicationsView from '../Views/CommunicationsView.vue';

const CommunicationsRoute =
{
    path: '/communications',
    meta: {
        name: {
            es: 'Comunicaciones',
            en: 'Communications',
        }
    },
    component: Communications,
    children: [
        {
            path: '/',
            component: CommunicationsView,
            meta: { name: 'Index' }
        }
    ]
};

export default CommunicationsRoute;
