<template>
  <div
    :class="rounded ? 'avatarUpload rounded' : 'avatarUpload squared'"
    :style="[setWidth, setHeight]"
  >
    <img class="avatarUpload__preview" :src="image" />
    <div class="avatarUpload__button-upload" @click="uploadImage">
      <span class="text-center px-2" :style="[fontSize]">
        <v-icon color="primary">mdi-pencil-outline</v-icon>
      </span>
    </div>
    <input
      class="avatarUpload__input-file"
      type="file"
      accept="image/*"
      ref="fileInput"
      @change="createImage"
    />
  </div>
</template>

<style lang="sass">
.avatarUpload
  position: relative
  overflow: hidden
  transition: all .3s ease
  border: 1px solid #f0f0f0
  &.rounded
    border-radius: 50% !important
  &.squared
    border-radius: 0
  &:hover
    cursor: pointer
  &:hover &__button-upload
    opacity: 0.75
  &__preview
    height: 100%
    width: 100%
    transition: all .3s ease
    background-color: #F5F5F5
  &__button-upload
    position: absolute
    bottom: 0
    left: 0
    height: 50%
    width: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    span
      color: black
      font-weight: 300
      font-size: 9px
      line-height: 1
      letter-spacing: 0.25px
      margin-bottom: 4px
</style>

<script>
export default {
  name: 'AvatarUpload',
  props: ['image', 'rounded', 'label', 'labelSize', 'width', 'height', 'size'],
  data () {
    return {
      file: null,
      imagePreview: null,
      setWidth: { 'width': '60px' },
      setHeight: { 'height': '60px' },
      fontSize: null
    }
  },
  methods: {
    uploadImage () {
      this.$refs.fileInput.click();
    },
    createImage (e) {
      if (e && e.target && e.target.files[0])
        this.$emit('save', e.target.files[0])
    }
  },
  created () {
    const customWidth = this.width;
    const customHeight = this.height;
    const customSize = this.size;
    const {labelSize} = this;

    if (customSize) {
      this.setWidth = { width: `${customSize  }px` };
      this.setHeight = { height: `${customSize  }px` };
    } else {
      if (customWidth) {
        this.setWidth = { width: `${customWidth  }px` };
      }
      if (customHeight) {
        this.setHeight = { height: `${customHeight  }px` };
      }
    }

    if (labelSize) {
      this.fontSize = { 'font-size': `${this.labelSize  }px` }
    }
  },
}
</script>
