const accessBlockedTranslations = {
    es: {
        title:'Estas sancionado.',
        subtitle:'No tienes permisos para realizar esta acción',
        description: 'Ponte en contacto con el equipo de <span class="text-uppercase">{theme}</span> para más información.'
    },
  
    en: {
     
    }
  };
  
  export default accessBlockedTranslations