import api from '@/api'

const { $qSystem } = api;
const { $statements } = $qSystem

const _$statements = Object.create($statements)
_$statements.resource = 'statements/getStatement'

const getExamPreview = async ({ id }) => {
  const {data} = await _$statements.findById(id)
  return {exam:data}
}

export default getExamPreview
