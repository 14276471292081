<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="">
        <Breadcrumbs
          :title="$t('modules.conciliation.create.breadcrumbs.title')"
          :description="
            $t('modules.conciliation.create.breadcrumbs.description')
          "
        ></Breadcrumbs>
        <template v-if="!loading">
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <v-card outlined class="pa-5" max-width="400px">
                <h4 class="">
                  {{
                    $t("modules.conciliation.create.cardDescription.document")
                  }}
                </h4>
                <div class="d-flex justify-space-between mt-3 mb-3">
                  <div class="subtitle font-weight-bold">
                    {{ filename ? filename : "" }}
                  </div>
                  <div>
                    <v-icon class="mr-2" color="green">mdi-check-circle</v-icon
                    >{{$t(
                            "modules.conciliation.create.processed"
                          ) }}
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon class="mr-2" size="19" color="green"
                    >mdi-check-circle-outline</v-icon
                  >
                  <div class="caption text--muted mt-1">
                    {{ processedItems.length ? processedItems.length : "0" }}
                    {{
                      processedItems.length > 0
                        ? $t(
                            "modules.conciliation.create.cardDescription.rowsProccesed"
                          )
                        : $t(
                            "modules.conciliation.create.cardDescription.rowProccesed"
                          )
                    }}
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon class="mr-2" size="19" color="red"
                    >mdi-alert-circle-outline</v-icon
                  >
                  <div class="caption text--muted mt-1">
                    {{
                      noProcessedItems.length ? noProcessedItems.length : "0"
                    }}
                    {{
                      noProcessedItems.length
                        ? $t(
                            "modules.conciliation.create.cardDescription.errorsProccesed"
                          )
                        : $t(
                            "modules.conciliation.create.cardDescription.errorProccesed"
                          )
                    }}
                  </div>
                </div>
              </v-card>
              <br />
              <h4 class="mb-2">
                {{ $t("modules.conciliation.create.procesedSection.title") }}
              </h4>
              <v-card outlined class="pa-1">
                <v-data-table
                  :headers="headers"
                  :items="processedItems"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
                  class="elevation-0"
                >
                  <template v-slot:item.hour="{ item }">
                    <div dark>
                      {{ item.hour }} hs
                    </div> </template
                  >
                  <template v-slot:item.credit="{ item }">
                    <div :class="getColor(item.credit)" dark>
                      {{ getText(item.credit) }}
                    </div> </template
                  >'
                  <template v-slot:item.debit="{ item }">
                    <div :class="getColor(item.debit)" dark>
                      {{ getText(item.debit) }}
                    </div>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <div :class="getColor(item.quantity)" dark>
                      {{ getText(item.quantity) }}
                    </div>
                  </template>
                  <template v-slot:item.tariff="{ item }">
                    <div :class="getColor(item.tariff)" dark>
                      {{ getText(item.tariff) }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
              <br />
              <h4 class="mb-2">
                {{ $t("modules.conciliation.create.unprocesedSection.title") }}
              </h4>
              <v-card outlined class="pa-1">
                <v-data-table
                  :headers="headers"
                  :items="noProcessedItems"
                  calculate-widths
                  hide-default-footer
                  disable-pagination
                  class="elevation-0"
                >
                  <template v-slot:item.date="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.date) }}
                    </div> </template
                  >
                  <template v-slot:item.hour="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.hour) }} hs
                    </div> </template
                  >
                  <template v-slot:item.id="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.id) }}
                    </div> </template
                  >
                  <template v-slot:item.referenceNumber="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.referenceNumber) }}
                    </div>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.description) }}
                    </div>
                  </template>
                  <template v-slot:item.credit="{ item }">
                    <div class="red--text" dark>{{ getText(item.credit) }}</div>
                  </template>
                  <template v-slot:item.debit="{ item }">
                    <div class="red--text" dark>{{ getText(item.debit) }}</div>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <div class="red--text" dark>
                      {{ getText(item.quantity) }}
                    </div>
                  </template>
                  <template v-slot:item.tariff="{ item }">
                    <div class="red--text" dark>{{ getText(item.tariff) }}</div>
                  </template>
                  <template v-slot:item.method="{ item }">
                    <div class="red--text" dark>{{ getText(item.method) }}</div>
                  </template>
                </v-data-table>
              </v-card>
              <br />
              <div class="d-flex justify-end">
                <Button
                  outlined
                  white
                  :text="$t('modules.conciliation.create.buttonDelete')"
                  class="mr-4 primary--text"
                  @clicked="deleteDocument"
                ></Button>

                <Button
                  :text="$t('modules.conciliation.create.buttonAccept')"
                  :errorText="'Error'"
                  @clicked="redirect"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $conciliations } from '../Services';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'ConciliationDetail',
  components: {
    Breadcrumbs,
    Button
  },
  data() {
    return {
      filename: '',
      processedItems: [],
      noProcessedItems: [],
      loading: false,
      headers: [
        {
          text: 'Fecha',
          class: 'primary--text ',
          width: '125px',
          value: 'date'
        },
        { text: 'Hora', class: 'primary--text', value: 'hour' },
        { text: 'ID', class: 'primary--text', value: 'id' },
        {
          text: 'N° Referencia',
          class: 'primary--text',
          value: 'referenceNumber'
        },
        { text: 'Descripción', class: 'primary--text', value: 'description' },
        { text: 'Crédito Neto', class: 'primary--text', value: 'credit' },
        {
          text: 'Débito Neto',
          class: 'primary--text',
          width: '125px',
          value: 'debit'
        },
        { text: 'Cantidad bruta', class: 'primary--text', value: 'quantity' },
        { text: 'Tarifa MP', class: 'primary--text', value: 'tariff' },
        { text: 'Método de pago', class: 'primary--text', value: 'method' }
      ],
      items: []
    };
  },
  computed: {
    ...mapGetters(['commons/getUserData', 'commons/getUserID'])
  },
  methods: {
    deleteDocument() {
      const filename = this.processedItems.length
        ? this.processedItems[0].filename
        : this.noProcessedItems[0].filename;
      this.$store.dispatch('commons/openPopUp', {
        title: '¿Esta seguro de eliminar?',
        content: [{ value: filename }],
        actionPrimary: {
          text: 'Aceptar',
          callback: () => this.deleteConciliation(filename)
        },
        actionSecondary: { text: 'Cancelar', callback() {} },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary'
      });
    },
    async deleteConciliation(filename) {
      try {
        await $conciliations.delete(`?filename=${filename}`);
        this.redirect();
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{ value: this.$t('modules.discounts.table.delete_error') }],
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-close' },
          color: 'primary'
        });
        throw error;
      }
    },
    getColor(value) {
      if (value < 0) return 'red--text';
      else return value;
    },
    getText(value) {
      if (value < 0) return `( ${value} )`;
      else return value;
    },
    async getConciliation() {
      this.loading = true
      const { id } = this.$route.params;
      let aux = null
      try {
        aux = await $conciliations.find(`?filename=${id}&`, null, {
          params: { page: 0, length: 500 }
        })
        
      } catch(error) {
          this.filename = '',
          this.processedItems = []
          this.noProcessedItems = []
          this.loading =  false
          throw error
      }

      this.processedItems = aux.data.processed;
      this.noProcessedItems = aux.data.notProcessed;

      this.processedItems = this.processedItems
        ? this.processedItems.map(conciliation => {
            return {
              date: conciliation.date
                ? moment(conciliation.date).format('YYYY-MM-DD')
                : ' ',
              hour: conciliation.date
                ? moment(conciliation.date).format('HH:MM')
                : ' ',
              filename: conciliation.filename,
              id: conciliation.id ? conciliation.id : ' ',
              referenceNumber: conciliation.externalReference
                ? conciliation.externalReference
                : ' ',
              description: conciliation.description
                ? conciliation.description
                : ' ',
              credit: conciliation.netCreditAmount
                ? conciliation.netCreditAmount
                : ' ',
              debit: conciliation.netDebitAmount
                ? conciliation.netDebitAmount
                : ' ',
              quantity: conciliation.grossAmount
                ? conciliation.grossAmount
                : ' ',
              tariff: conciliation.mpFeeAmount ? conciliation.mpFeeAmount : ' ',
              method: conciliation.paymentMethod
                ? conciliation.paymentMethod
                : ' '
            };
          })
        : [];
      this.noProcessedItems = this.noProcessedItems
        ? this.noProcessedItems.map(conciliation => {
            return {
              date: conciliation.date
                ? moment(conciliation.date).format('YYYY-MM-DD')
                : ' ',
              hour: conciliation.date
                ? moment(conciliation.date).format('HH:MM')
                : ' ',
              filename: conciliation.filename,
              id: conciliation.id ? conciliation.id : ' ',
              referenceNumber: conciliation.externalReference
                ? conciliation.externalReference
                : ' ',
              description: conciliation.description
                ? conciliation.description
                : ' ',
              credit: conciliation.netCreditAmount
                ? conciliation.netCreditAmount
                : ' ',
              debit: conciliation.netDebitAmount
                ? conciliation.netDebitAmount
                : ' ',
              quantity: conciliation.grossAmount
                ? conciliation.grossAmount
                : ' ',
              tariff: conciliation.mpFeeAmount ? conciliation.mpFeeAmount : ' ',
              method: conciliation.paymentMethod
                ? conciliation.paymentMethod
                : ' '
            };
          })
        : [];
        this.loading = false
    },
    redirect() {
      this.$router.push('/conciliations');
    }
  },
  mounted() {
    this.getConciliation();
  }
};
</script>

<style lang='sass' scoped>

.header-line
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)

.text--muted
  color: rgba(0, 0, 0, 0.35)

.check-icon
  background: #dae2f4
</style>

