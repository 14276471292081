<template>
  <v-stepper class="common-stepper" v-model="changeStep" style="box-shadow: unset;" :non-linear="nonLinear">
    <v-stepper-header style="box-shadow: unset;" class="pa-0 d-flex">
      <template v-for="(step, i) in stepper">
        <v-stepper-step
          :key="'step' + i"
          color="primary lighten-3"
          :editable="step.available"
          :disabled="step.available"
          :step="(i + 1)"
          :complete="currentStep > (i + 1)"
          class="py-3 d-flex flex-grow-1 justify-center"
        >{{ step.title }}</v-stepper-step>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <slot></slot>
    </v-stepper-items>
    <v-container class="container-custom pa-0" v-if="navButtons">
      <v-row justify="center">
        <v-col sm="10" class="py-0 mb-4 d-flex justify-end">
          <Button
            white
            :text="(currentStep !== 1) ? $t('actions.back') : $t('actions.cancel')"
            @clicked="(currentStep !== 1) ? backStep() : ((insideDrawer) ? $emit('cancelAction') : $router.go(-1))"
            @end="resetButtonValues()"
            class="mr-4"
          ></Button>
          <!-- Show in last step -->
          <Button
            v-if="_self['isLastStep']"
            :loading="finishButton.loading"
            :success="finishButton.success"
            :error="finishButton.error"
            :text="finishButton.text"
            :successText="finishButton.successText"
            :errorText="finishButton.errorText"
            @clicked="$emit('confirm')"
            @end="resetButtonValues()"
            :disabled="!canFinish"
          ></Button>

          <Button
            v-if="!(_self['isLastStep'])"
            :text="stepper[currentStep-1].button"
            errorText="Error"
            @clicked="nextStep"
            @end="resetButtonValues()"
            :disabled="!canGoToNextStep"
          ></Button>
        </v-col>
      </v-row>
    </v-container>
  </v-stepper>
</template>

<style lang="sass">

    .common-stepper
      .v-stepper
        &__header
            border-top: 1px solid #E5E5E5
            border-bottom: 1px solid #E5E5E5
        &__step
            background: #f5f5f5
            &--editable
                border-left: 1px solid #eeeeee
                border-right: 1px solid #eeeeee
            &--active
                background: white
                border-bottom: 4px solid var(--v-primary-lighten3)
                border-left: 0
                border-right: 0
            &--complete
                background: white

        &__content
            opacity: 1
            transition: opacity 300ms ease
            &.tab-transition-leave-active
                opacity: 0
        &__label
            font-size: 14px
</style>

<script>
import Button from '../Button/Button'

export default {
  name: 'Stepper',
  components: {
    Button
  },
  props: {
    stepper: Array,
    navButtons: Boolean,
    canFinish: { type: Boolean, default: true },
    currentStep: { type: Number, default: 1 },
    loading: Boolean,
    finishButton: Object,
    canGoToNextStep: Boolean,
    insideDrawer: { type: Boolean, default: false },
    nonLinear: {type: Boolean, default: false }
  },
  data () {
    return {
    }
  },
  methods: {
    backStep () {
      if (this.currentStep !== 1) {
        this.$emit('changeStep', this.currentStep - 1)
      }
    },
    nextStep () {

      if (this.canGoToNextStep) {
        if (!this.isLastStep) {
          this.$emit('changeStep', this.currentStep + 1);
          this.$emit('next')
        } else {
          this.$emit('confirm')
        }
      }
    },
    resetButtonValues () {
      this.$emit('resetFinishButtonValues')
    }
  },
  computed: {
    isLastStep () {
      return this.currentStep === this.stepper.length
    },
    changeStep:{
      set(value){
        this.$emit('changeStep', value)
      },
      get(){
        return this.currentStep
      }

    }
  }
}
</script>
