<template>
  <OutlinedCard>
    <v-row no-gutters>
      <v-col sm="6" class="pr-4">
        <v-text-field
          :error="$v.formData.name.$anyError"
          :label="$t('modules.roles.create.stepper.details.fields.name') + ' *'"
          :error-messages="nameErrors"
          @blur="$v.formData.name.$touch()"
          outlined
          :counter="75"
          v-model="$v.formData.name.$model" class="mb-4"></v-text-field>
      </v-col>
      <v-col sm="4">
        <v-select
          v-model="$v.formData.rolType.$model"
          :items="rolTypeOptions"
          :error-messages="($v.formData.rolType.$dirty && $v.formData.rolType.$invalid) ? $t('modules.roles.create.stepper.details.validations.typeRequired') : ''"
          :label="$t('modules.roles.create.stepper.details.fields.type') + ' *'"
          @blur="$v.formData.rolType.$touch()"
          outlined
          return-object
          item-text="meaning">
        </v-select>
      </v-col>
    </v-row>
    <v-textarea :error="$v.formData.description.$anyError" outlined :error-messages="descriptionErrors"
      @blur="$v.formData.description.$touch()" v-model="$v.formData.description.$model"
      :label="$t('modules.roles.create.stepper.details.fields.description') + ' *'" :counter="200"></v-textarea>
    <slot></slot>
  </OutlinedCard>
</template>


<script>
import { required } from 'vuelidate/lib/validators'
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard'

export default {
  name: 'roles-stepper-form',
  props: ['formData', 'rolTypeOptions'],
  components: {
    OutlinedCard
  },
  validations: {
    formData: {
      name: {required},
      description: {required},
      rolType: {required}
    },
  },
  computed: {
    nameErrors () {
      const errors = [];
      if (!this.$v.formData.name.$anyError) return errors;

      if (!this.$v.formData.name.required) errors.push(this.$t('modules.roles.create.stepper.details.validations.nameRequired'));
      return errors;
    },
    descriptionErrors () {
      const errors = [];
      if (!this.$v.formData.description.$anyError) return errors;

      if (!this.$v.formData.description.required) errors.push(this.$t('modules.roles.create.stepper.details.validations.descriptionRequired'));
      return errors;
    }
  },
  methods: {
    resetValidations() {
      this.$v.$reset()
    }
  }
}
</script>
