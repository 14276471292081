<template>
    <v-card>
        <v-card-title class="primary--text">{{ selectedSubject ? selectedSubject.name : ''}}</v-card-title>
        <v-card-text class="d-flex flex-column">
            <div class="d-flex flex-column flex-sm-row justify-space-between mt-7 mb-5">
                <div class="d-flex flex-column mx-2 pa-2" :class="{'final-score': idx == (selectedSubjectParts.length - 1) }" v-for="(part,idx) in selectedSubjectParts" :key="`part-${idx}`">
                    <h3 :class="[{'grey--text text--darken-4': idx != (selectedSubjectParts.length - 1)}, 'font-weight-medium text-center']">{{ part.title }}</h3>
                    <p class="text-center mt-1 mt-sm-3 mb-0">{{ part.content }}</p>
                </div>
            </div>
            <v-divider />
            <div class="d-flex flex-column flex-sm-row mt-3">
                <span class="caption">{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.modal.formula') }}</span>
                <v-spacer></v-spacer>
                <span class="caption"><v-icon size="12" color="#4586de" class="mr-2">mdi-circle</v-icon>{{ $t('modules.portalalumno.dashboard.subjectsTab.approved.modal.recoveredScores') }}</span>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end mx-4 mb-4">
            <Button color="primary" :text="$t('actions.close')" @clicked="$emit('close')"></Button>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Button from '@/components/Button/Button'

    export default {
        name: 'SubjectModal',
        props: {
            selectedSubject: Object,
            selectedSubjectParts: Object,
        },
        components: {
            Button
        },
    }
</script>

<style lang="sass" scoped>
    .final-score
        background: #d9e2fa
        color: var(--v-primary-base)
        padding: 9px
        border: 1px solid var(--v-primary-base)
        border-radius: 5px
</style>