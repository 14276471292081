<template>
   <!-- <v-dialog v-model="dialog" persistent retain-focus max-width="900px">
      <v-card>
      </v-card>
    </v-dialog> -->


    <div v-if="item" class="alertPopup">

      <v-card v-show="item" class="pa-10 d-flex flex-column justify-center alertPopup__content">
       
        <div class="title mb-4">Asignar prerrequisito</div>
        
        <MultipleFormGroup
          :items="correlatives"
          :canAddGroup="availableSubjects.length > correlatives.length" 
          styleless
          @addGroup="addGroup"
          @removeGroup="removeGroup"
          class="correlativeSubjectsMultipleForm"
        >
        <template v-slot="{item}">
          <v-row>
            <v-col sm="5">
              <v-select
                :items="options"
                v-model="item.correlativeWay"
                outlined
              >
              </v-select>
            </v-col>
            <v-col sm="7">
              <v-autocomplete
                :items="getItems(item)"
                :item-text="(item)=>item.code +' - '+item.description"
                v-model="item.subject"
                return-object
                outlined
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </template>
        </MultipleFormGroup>

        <v-card-actions class="d-flex justify-end ma-0 mt-6 pa-0">
          <v-spacer></v-spacer>
          <Button
            white
            :text="$t('actions.cancel')"
            @clicked="cancel()"
            @end="resetButtonValues()"
            class="mr-4"
          ></Button>
          <Button
            :text="'Vincular'"
            @clicked="save"
            :disabled="!canSave"
          ></Button> 
        </v-card-actions>
        
      </v-card>
    </div>

</template>

<script>
import Button from '@/components/Button/Button';
import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';

const BELONGS_TO_BIMESTER_A = 'ba';
const BELONGS_TO_BIMESTER_B = 'bb';
const BELONGS_TO_SEMESTRAL = 'se'; //eslint-disable-line no-unused-vars

export default {
  components:{
    Button,//eslint-disable-line vue/no-unused-components
    MultipleFormGroup //eslint-disable-line vue/no-unused-components
  },
  name:'CorrelativeSubjectsPopUp',
  props:{
    availableSubjects: Array,
    selectedSubjects: Array,
    item: Object
  },
  data(){return {
    value: null,
    correlatives:[
      {
        correlativeWay: 0,
        subject: {},
      }
    ],
    options:[
      {
        text: 'Es prerrequisito de',
        value: 0,
      },
      {
        text: 'Tiene como prerrequisito a',
        value: 1,
      }
    ]
  }},
  mounted () {
    this.value = this.item;
    if(this.value && this.value.correlatives && this.value.correlatives.length){
        this.correlatives = this.value.correlatives.map(correlative => {
          if(correlative.correlativeWay === undefined){
            return {
              correlativeWay:0,
              subject: {
                id: correlative.preRequisite.id,
                code: correlative.preRequisite.code,
                description: correlative.preRequisite.description
              }
            }
          }else{
            return correlative
          }
          
       })
    }else{ this.correlatives = [
        {
          correlativeWay: 0,
          subject: {},
        }
      ]
    }
    if(!this.value)
      this.correlatives = [
        {
          correlativeWay: 0,
          subject: {},
        }
      ]
  },
  methods:{
    getItems(item){
      if(!this.value) return [];
      if(item.correlativeWay){
        const canBeCorrelativeOf = this.availableSubjects
                .filter( s => s.semester <= this.value.semester)
                .filter( s => {
                  if(s.semester === this.value.semester){
                    if(s.belongsTo === BELONGS_TO_BIMESTER_A && this.value.belongsTo === BELONGS_TO_BIMESTER_B)
                      return true;
                    else
                      return false;
                  } else {
                    return true;
                  }
                })
                .filter( s => {
                  if(s.id === item.subject?.id)
                    return true;
                  else
                    return !this.correlatives.find( ({subject}) => subject?.id === s.id)
                })
                .map(s => {
                  return {
                    id: s.id,
                    description: s.description,
                    code: s.code,
                  }
                });
                
        return canBeCorrelativeOf;
      } else {
        const canHaveAsCorrelatives = this.availableSubjects
                .filter( s => s.semester >= this.value.semester)
                .filter( s => {
                  if(s.semester === this.value.semester){
                    if(s.belongsTo === BELONGS_TO_BIMESTER_B && this.value.belongsTo === BELONGS_TO_BIMESTER_A)
                      return true;
                    else
                      return false;
                  } else {
                    return true;
                  }
                })
                .filter( s => {
                  if(s.id === item.subject?.id)
                    return true;
                  else
                    return !this.correlatives.find( ({subject}) => subject?.id === s.id)
                })
                .map(s => {
                  return {
                    id: s.id,
                    description: s.description,
                    code: s.code,
                  }
                });
        return canHaveAsCorrelatives;
      }
    },
    removeGroup (index) {
      this.correlatives.splice(index, 1)
    },
    addGroup () {
      const mockup =
      {
        correlativeWay: 0,
        subject: {},
      };
      this.correlatives.push({
        ...mockup
      })
    },
    cancel(){
      this.$emit('cancel')
    },
    save () {
      this.$emit('save', this.correlatives);
    },
  },
  computed:{
    canSave(){
      let canSave = true;
      this.correlatives.forEach(item =>{
        if(!item.subject?.id)
          canSave = false;
      })
      return canSave;
    }
  }
}
</script>

<style lang="sass" scoped>
.correlativeSubjectsMultipleForm
  max-height: 450px
  overflow: auto
  padding: 1rem

.alertPopup
  position: absolute
  z-index: 9999
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.2)

  &__content
    min-width: 70vw
    max-width: 100vw
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 20px 50px rgba(0, 0, 0, 0.2)


</style>