<template>
  <div>
    <v-row v-if="items.length">
      <v-col sm=6 class="d-flex justify-start pl-5">
          <h5 class="primary--text">{{ $t('components.multipleStatusGroups.asignatura') }}</h5>
      </v-col>
      <v-col sm=2 class="d-flex justify-center">
        <h5 class="primary--text text-center">{{ $t('components.multipleStatusGroups.periodo_aprobacion') }}</h5>
      </v-col>
      <v-col sm=2 class="d-flex justify-center">
        <h5 class="primary--text text-center">{{ $t('components.multipleStatusGroups.calificacion') }}</h5>
      </v-col>
      <v-col sm=2 class="d-flex justify-center">
        <h5 class="primary--text text-center">{{ $t('components.multipleStatusGroups.homologada') }}</h5>
      </v-col>
    </v-row>
    <div v-for="(item, i) in items" :key="i" class="mb-1">
      <v-card class="mx-auto cards-outlined" outlined :color="item.status ? '#ffffff' : '#f8f8f8'">
      <v-row>
        <v-col sm=6 class="d-flex">
          <v-list-item-subtitle class="d-flex align-center text-muted pl-5">{{ `${item.subject.code} - ${item.subject.description}` }}</v-list-item-subtitle>
        </v-col>
        <v-col sm=2 class="d-flex"> 
          <v-list-item-subtitle class="d-flex align-center justify-center text-muted">{{ item.subject.date }}</v-list-item-subtitle>
        </v-col>
        <v-col sm=2 class="d-flex">
          <v-list-item-subtitle class="d-flex align-center justify-center text-muted">{{ item.subject.score }}</v-list-item-subtitle>
        </v-col>
        <v-col sm=2>
          <div v-if="item.status" class="d-flex align-center justify-center">
            <span class="pa-1 shadow-icon-green">
              <v-icon color="green">mdi-check-circle</v-icon>
            </span>
          </div>
          <div v-else class="d-flex justify-center">
            <span class="pa-1 shadow-icon-red">
              <v-icon color="red">mdi-close-circle</v-icon>
            </span>
          </div>
        </v-col>
      </v-row>
      </v-card>
    </div>
    <p v-if="!items.length">{{ noItemsMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'MultipleStatusGroup',
  props: {
    items: [Array, Object],
    noItemsMessage: String
  },
}
</script>

<style scoped lang="sass">
  .shadow-icon-red
    display: flex
    justify-content: center
    align-items: center
    width:32px
    border-radius: 50%
    background-color: #ffeded

  .shadow-icon-green
    display: flex
    justify-content: center
    align-items: center
    width:32px
    border-radius: 50%
    background-color: #F0F8FF

  .text-muted
    font-size: 14px
    color: #707070

  .cards-outlined
    border: thin solid rgba(0, 0, 0, 0.12) !important
</style>
