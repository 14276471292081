import api from '@/api';

const $st = api.$practica
const {$pricing} = api
export const {$types} = $pricing
export const { 
    $summary, 
    $status, 
    $agreements, 
    $presentationLetter, 
    $identification, 
    $practiceAgreement, 
    $practica, 
    $filter,
    $professionalizingPractices,
    $academicRecord, 
    $updateCheckEmpresaCumpleRequisitos, 
    $updateCheckEmpresaContinuaProceso,
    $actualizarValidacion,
    $cerrarConvenio,
    $updateCheckFirmadoPorAlumno,
    $updateCheckFirmadoPorEmpresa,
    $updateEstadoPracticaEmpresa,
    $updateObservacion,
    $curriculumVitae,
    $updateCheckEnvioConvenio,
    $updateCheckAprobo,
    $rechazarConvenio,
    $updateCompanyPractice,
} = $st;
