import StudentPreRegistration from '../StudentPreRegistration.vue'
import StudentPreRegistrationTable from '../Views/StudentPreRegistrationTable.vue';
import StudentPreRegistrationCreate from '../Views/StudentPreRegistrationCreate.vue';
import StudentPreRegistrationUpdate from '../Views/StudentPreRegistrationUpdate.vue';
import StudentPreRegistrationDetail from '../Views/StudentPreRegistrationDetail.vue';

const studentPreRegistrationRoute =
    {
        path: '/preregistration',
        meta: { name: { es: 'Preinscripción', en: 'Preregistration' } },
        component:  StudentPreRegistration,
        children: [
            {
                path: '',
                component: StudentPreRegistrationTable,
                meta: { name: 'Index', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'READ' }
            },
            {
                path: 'create',
                component: StudentPreRegistrationCreate,
                meta: { name: 'StudentPreRegistrationCreate', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'CREATE' }
            },
            {
                path: 'update/:id',
                component: StudentPreRegistrationUpdate,
                meta: { name: 'StudentPreRegistrationUpdate', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'READ' }
            },
            {
                path: 'detail/:id',
                component: StudentPreRegistrationDetail,
                meta: { name: 'StudentPreRegistrationDetail', group: 'portaladministrativo.admission', path: 'pre_registration', permission: 'READ' }
            },
        ]
    };

export default studentPreRegistrationRoute