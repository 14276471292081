<template>
    <v-card
        class="StudyPlan__card cursor-grab text-left py-2 px-3 mt-2"
        :class="status"
        @click="select"
        outlined
    >
        <div class="caption StudyPlan__card__title">{{ item.subject.code }} - {{ item.subject.descripcion }}</div>
        <div class="caption StudyPlan__card__title pt-3" v-if="item.electiveSelected || electiveSelected">Seleccionada</div>
        <div class="caption primary--text font-weight-bold StudyPlan__card__title pt-1" v-if="item.electiveSelected">{{ `${item.electiveSelected.code} - ${item.electiveSelected.description}` }}</div>
        <div class="caption font-weight-bold StudyPlan__card__title pt-1" v-else-if="electiveSelected">{{ `${electiveSelected.code} - ${electiveSelected.description}` }}</div>
        <v-checkbox
            v-if="canSelect"
            class="StudyPlan__card__button pt-0 mt-0"
            :color="item.canUnenroll ? 'white' : 'primary'"
            :value="item.selected"
            hide-details
            readonly
        ></v-checkbox>

        <ElectiveSubjectPopUp
            v-if="selectedElectiveSubject"
            :modalOpen="electiveSubjectPopupOpen"
            :selectedSubject="selectedElectiveSubject"
            @cancel="clearPopup"
            @save="saveCorrelativesSelectedSubject"
        ></ElectiveSubjectPopUp>

    </v-card>
</template>

<script>
    import ElectiveSubjectPopUp from '../ElectiveSubjectPopUp/ElectiveSubjectPopUp';

    export default {
        name: 'SubjectItem',
        props:{
            item: Object,
        },
        components: {
            ElectiveSubjectPopUp
        },
        data() {
            return {
                electiveSubjectPopupOpen: false,
                selectedElectiveSubject: null,
            }
        },
        methods:{
            openCorrelativesPopUp(){
                this.$emit('open')
            },
            select() {
                if (this.item.electives && !this.electiveSelected) {
                    this.selectedElectiveSubject = JSON.parse(JSON.stringify(this.item))
                    this.electiveSubjectPopupOpen = true
                } else if (this.canSelect) this.item.selected = !this.item.selected
            },
            clearPopup() {
                this.selectedElectiveSubject = null
                this.electiveSubjectPopupOpen = false
            },
            saveCorrelativesSelectedSubject(electiveSelected) {
                this.item.electiveSelected = electiveSelected
                this.item.selected = electiveSelected ? true : false
                this.clearPopup()
            },
        },
        computed:{
            canSelect() {
                return this.item.canEnroll || this.item.canUnenroll
            },
            electiveSelected() {
                if (!this.item.electives) return false
                return this.item.electives.find(elective => elective.selected == true)
            },
            status() {
                if (!this.item.subjectStatus) return ''
                else if (this.item.subjectStatus.value == 84) return 'approved'
                else if (this.item.subjectStatus.value == 85) return 'enabled'
                else if (this.item.subjectStatus.value == 86) return 'in-curse'
                else if (this.item.subjectStatus.value == 87) return 'reproved'
                else return ''
            },
        }
    }
</script>

<style lang="sass" scoped>
.StudyPlan
  &__card
    box-shadow:  0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06) !important
    &.approved
        background-color: rgb(214, 255, 214)
        border-color: rgb(214, 255, 214)
        color: #616161
    &.enabled
        background-color: rgb(82, 216, 126)
        border-color: rgb(82, 216, 126)
        color: white
    &.in-curse
        background-color: rgb(113, 126, 255)
        border-color: rgb(113, 126, 255)
        color: white
    &.reproved
        background-color: rgb(244, 186, 218)
        border-color: rgb(244, 186, 218)
        color: #880e4f
    &:hover, &:active
      z-index: 2
      box-shadow: 0px 0px 0 1px #E1E4ED, 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
    &__title
      padding-right: 2rem
    &__button
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
</style>