<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
      <v-container fluid class="scrollableContent pa-0">
        <v-container class="container-custom px-6">
          <Breadcrumbs :description="$t('modules.comisiones.liquidations.table.breadcrumbs.description')"
                       :title="$t('modules.comisiones.liquidations.table.breadcrumbs.title')"></Breadcrumbs>
          <v-row justify="center">
            <v-col sm="12" class="viewSpaces">
              <SuperTable
                :headers="headers"
                :items="liquidations"
                :pageCount="asidebar.pagination.pagesAmount"
                :page="asidebar.pagination.page"
                :sortBy.sync="sort.sortBy"
                :sortDesc.sync="sort.sortDesc"
                :totalItems="totalItems"
                @searchAction="searchAction"
                @input-pagination="onInputPagination"
                @length-pagination-change="onLengthPaginationChange"
                @download-report="downloadConfirmation"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
</template>

<script>
  import SuperTable from '../Components/SuperTable/SuperTable'
  import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
  import AsideBar from '@/components/AsideBar/AsideBar'
  import { $payings, $types, $sellers, $users } from '../Services';
  import moment from 'moment';
  import {mapGetters} from 'vuex';
  import download from 'downloadjs';

  export default {
    name: 'LiquidationsTable',
    components: {
      SuperTable,
      Breadcrumbs,
      AsideBar
    },
    data () {
      return {
        loading: false,
        totalItems: 0,
        search: null,
        sort: {
          sortBy: 'date',
          sortDesc: true,
        },
        liquidations: [],
        asidebar: {
          title: this.$t('modules.comisiones.liquidations.table.filters.title'),
          pagination: {
            limit: 20,
            page: 1,
            pagesAmount: 1
          },
          items: [
            {
              name: this.$t('modules.comisiones.liquidations.table.filters.status'),
              filters: null
            }, {
              name: this.$t('modules.comisiones.liquidations.table.filters.user'),
              filters: [{
                type: 'autocomplete',
                name: this.$t('modules.comisiones.liquidations.table.filters.user'),
                items: [],
                value: null,
                clearable: true
              }]
            },{
              name: this.$t('modules.comisiones.liquidations.table.filters.sellerCode'),
              filters: [{
                type: 'autocomplete',
                name: this.$t('modules.comisiones.liquidations.table.filters.sellerCode'),
                items: [],
                value: null,
                clearable: true
              }]
            },{
              name: this.$t('modules.comisiones.liquidations.table.filters.date'),
              filters: [
                {
                  name: this.$t('modules.comisiones.liquidations.table.filters.from'),
                  type: 'date',
                  value: null,
                  isCalendarOpen: false,
                },{
                  name: this.$t('modules.comisiones.liquidations.table.filters.to'),
                  type: 'date',
                  value: null,
                  isCalendarOpen: false,
                },
              ]
            }
          ]
        },
        headers: [
          { text: this.$t('modules.comisiones.liquidations.table.headers.id'), value: 'id' },
          { text: this.$t('modules.comisiones.liquidations.table.headers.user'), value: 'user' },
          { text: this.$t('modules.comisiones.liquidations.table.headers.sellerCode'), value: 'sellerCode' },
          { text: this.$t('modules.comisiones.liquidations.table.headers.date'), value: 'date' },
          { text: this.$t('modules.comisiones.liquidations.table.headers.amount'), value: 'amount' },
          { text: this.$t('modules.comisiones.liquidations.table.headers.status'), value: 'status' },
          { text: '', value: 'download' }
        ],
      }
    },
    async mounted () {
      await this.getPayingTypes();
      await this.getSellersCode();
      await this.getSellersName();

      this.fetchData();
    },
    methods: {
      changed(index, i, value) {
        this.asidebar.items[index].filters[i].value = value
        this.fetchData();
      },
      async getPayingTypes() {
        const fetchedPayingType = await $types.find(null, null, { params: { type: 'PAYING_TYPE' } })
        this.asidebar.items[0].filters = fetchedPayingType.data.map(item => {
          return {
            id: item.value,
            name: item.meaning,
            type: 'check',
            items: [],
            value: true,
            dataFiltered: '',
            mandatory: true
          }
        });
      },
      async getSellersName() {
        try {
          const sellers = await $users.find(`list`, null, { params: { page:0, length: 500, orderBy: 'name', orientation: 'asc'} });

          const currentSeller = []
          sellers.data.content.forEach(seller => {
            if (this.isAdmin) currentSeller.push({text: `${seller.name} ${seller.lastName}`, value: seller.id})
            else if (this.loggedUser == seller.id) currentSeller.push({text: `${seller.name} ${seller.lastName}`, value: seller.id})
          })
          currentSeller.sort((a, b) => (a.text > b.text) ? 1 : -1)
          this.asidebar.items[1].filters[0].items = currentSeller

        } catch (error) {
          this.asidebar.items[1].filters[0].items = [];
          throw error;
        }
      },
      async getSellersCode() {
        try {
          const response = await $sellers.filter(null, { params: { page:0, length: 500, orderBy: 'sellerCode', orientation: 'asc'} });
          const sellers = response.data.content.filter(seller => this.isAdmin || seller.userId == this.loggedUser)
                                          .map(seller => { return {text: `${seller.sellerCode} - ${seller.sellerName}`, value: seller.id} })
          
          this.asidebar.items[2].filters[0].items = sellers
        } catch (error) {
          this.asidebar.items[2].filters[0].items = [];
          throw error;
        }
      },
      async fetchData() {
        if(this.loading) return; //won't fetch if it's already fetching
        this.loading = true;

        try {
          let sortCol;
          switch (this.sort.sortBy)
          {
            case 'user': sortCol = 'seller.user.userData.name'; break;
            case 'sellerCode': sortCol = 'seller.sellerCode'; break;
            case 'status': sortCol = 'payingType.meaning'; break;
            case 'date': sortCol = 'createDate'; break;
            case 'amount': sortCol = 'amount'; break;
            default: sortCol = this.sort.sortBy;
          }

          const params = {
            page: this.asidebar.pagination.page - 1,
            length: this.asidebar.pagination.limit,
            orderBy: sortCol,
            orientation: this.sort.sortDesc ? 'desc' : 'asc',
          }

          const filterParams = {
            search: '',
            payingType: [],
            sellers: [],
            from: null,
            to: null,
            user: []
          }

          this.asidebar.items[0].filters.forEach(payingType => {
            if (payingType.value) filterParams.payingType.push(payingType.id);
          })

          filterParams.user = this.asidebar.items[1].filters[0].value ?
            [this.asidebar.items[1].filters[0].value] : this.isAdmin ?
              [] : [this.loggedUser];

          filterParams.sellers = this.asidebar.items[2].filters[0].value ?
            [this.asidebar.items[2].filters[0].value] : [];

          filterParams.from = this.asidebar.items[3].filters[0].value;

          filterParams.to = this.asidebar.items[3].filters[1].value;

          filterParams.search = this.search;

          const resp = await $payings.filter(filterParams, { params });
          this.totalItems = resp.data.totalElements;
          this.asidebar.pagination.pagesAmount = resp.data.totalPages;

          this.liquidations = resp.data.content.map(item => {
            item.date = moment(item.date).format('DD-MM-YYYY');
            item.loading = false
            return item
          });

        } catch (error) {
          this.liquidations = []
          this.asidebar.pagination.page = 1
          this.asidebar.pagination.pagesAmount = 1
          this.totalItems = 0
          throw error;
        } finally {
          this.loading = false;
        }
      },
      searchAction(data) {
        this.asidebar.pagination.page = 1
        this.search = data;
        this.fetchData();
      },
      onInputPagination(event) {
        this.asidebar.pagination.page = event;
        this.fetchData();
      },
      onLengthPaginationChange(event) {
        this.asidebar.pagination = {
          page: 1,
          limit: event,
          pagesAmount: 1
        };
        this.fetchData();
      },
      downloadConfirmation(liquidation) {
        if (!liquidation.loading) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.comisiones.liquidations.table.download.confirmation_title'),
            content: null,
            actionPrimary: { text: this.$t('modules.comisiones.liquidations.table.download.primary_text'), callback: () => { this.downloadReport(liquidation); }},
            actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
            icon: { color: 'warning', name: 'mdi-eye-off-outline' },
            color: 'primary'
          });
        }
      },
      async downloadReport(liquidation) {
        try {
          liquidation.loading = true

          const response = await $payings.find('report', null, {responseType: 'blob', params: { id: liquidation.id } });
          const content = response.headers.get('content-type');
          download(response.data, this.$t('modules.comisiones.liquidations.table.download.reportName', { date: moment().format('DD-MM-YYYY') }), content)
        } catch (error) {
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('actions.error'),
            content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.comisiones.liquidations.table.download.error')}],
            actionPrimary: { text: this.$t('actions.accept'), callback() { }},
            icon: { color: 'error', name: 'mdi-close' },
            color: 'primary'
          });
          throw error
        } finally {
          liquidation.loading = false
        }
      }
    },
    computed: {
      loggedUser(){
        return this['commons/getUserID'];
      },
      isAdmin(){
        return this['commons/getUserRoles'].find(rol => {return rol.rolType.meaning == 'Administrador'});
      },
      ...mapGetters([
        'commons/getUserID',
        'commons/getUserRoles'
      ]),
    },
    watch: {
      sort: {
        handler(){
          this.fetchData();
        },
        deep: true
      }
    }
  }
</script>
