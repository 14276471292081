<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'Procedures',
    data () {
      return {}
    },
  };
</script>
