<template>
  <v-container fluid class="superTable pa-0">
    <v-row no-gutters>
      <v-col sm="4">
        <v-text-field
          :placeholder="$t('actions.search')"
          class="superTable__search"
          clearable
          outlined
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          @click:clear="search = '';keyup()"
          @keyup="keyup"
          @keyup.esc="search = ''"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="12">
        <v-data-table
          v-model="selectedItems"
          :headers="headers"
          :items="data"
          :totalItems="totalItems"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          show-select
          item-key="id"
          class="superTable__table"
          style="table-layout: fixed"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @update:sort-by="$emit('update:sortBy', Array.isArray($event) ? $event[0] : $event)"
          @update:sort-desc="$emit('update:sortDesc', Array.isArray($event) ? $event[0] : $event)"
        >
          <template v-slot:[`item.status`]="{item}">
            <v-icon :color="(item.status) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.detail`]="{item}">
            <div v-for="(e, index) in item.detail" :key="index">
              {{ `${e.dayFrom} - ${e.dayTo}` }}
            </div>
          </template>

          <template v-slot:[`item.percentage`]="{item}">
            <div v-for="(e, index) in item.percentage" :key="index">
              {{ `${e.percentage} %` }}
            </div>
          </template>

          <template v-slot:[`item.studentType`]="{item}">
            {{ item.studentType.meaning }}
          </template>

          <template v-slot:[`item.scholarship`]="{item}">
            <v-icon :color="(item.scholarship) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:[`item.average`]="{item}">
            <v-icon :color="(item.average) ? 'success' : 'grey lighten-2'" size="12">mdi-circle</v-icon>
          </template>

          <template v-slot:top v-if="permissions.delete">
            <div :class="['superTable__bulkActions', 'py-2',selectedItems.length ? 'show' : 'hide']">
              <v-divider></v-divider>
              <div class="py-3">
                                <span
                                  v-if="permissions.delete"
                                  class="caption mx-4 cursor-pointer hover-7-inverted"
                                  @click="$emit('bulk-delete', selectedItems)"
                                >
                                    <v-icon size="22" class="mr-2">mdi-delete</v-icon>{{ deleteTitle }}
                                </span>
              </div>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-slot:[`header.data-table-select`]="{ on, props }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              v-bind="props"
              v-on="on"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox
              class="superTable__table__item__checkbox"
              color="primary"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.action`]="{ item }" v-if="permissions.update || permissions.delete">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
              </template>
              <v-list class="pa-0">
                <v-list-item v-if="permissions.update" @click="editItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-pencil</v-icon> {{ $t('actions.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="permissions.delete" @click="deleteItem(item.id)">
                  <v-list-item-title>
                    <v-icon>mdi-delete</v-icon> {{ $t('actions.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-divider class="mt-4 mb-8"></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col sm="3">
        <span class="caption d-inline-block">{{ `${itemsCount.startItem} - ${itemsCount.endItem} de ${itemsCount.totalItems}` }}</span>
        <v-select
          :items="itemsPerPageAvailable"
          :placeholder="itemsPerPage.toString()"
          class="superTable__paginationSelect d-inline-block ml-4"
          outlined
          v-model="itemsPerPage"
          @change="$emit('length-pagination-change', itemsPerPage)"
        ></v-select>
      </v-col>
      <v-col class="text-center" sm="6">
        <v-pagination
          :value="page"
          :length="pageCount"
          @next="$emit('next-pagination', page)"
          @previous="$emit('previous-pagination', page)"
          @input="$emit('input-pagination', $event)"
          class="superTable__pagination"
        ></v-pagination>
      </v-col>
      <v-col align="right" sm="3">
        <v-text-field
          class="superTable__paginationInput d-inline-block mr-4"
          max="pageCount"
          outlined
          v-model.number="goToPage"
          @blur="changePage"
        ></v-text-field>
        <span class="caption d-inline-block">{{ $t('actions.goToPage') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SuperTable',
  props: {
    data: Array,
    headers: Array,
    clearSelected: String,
    deleteTitle: String,
    permissions: {update: false, delete: false},
    sortBy: String,
    sortDesc: Boolean,
    pageCount: { type: Number, default: 1 },
    page: { type: Number, default: 1 },
    totalItems: Number
  },
  data () {
    return {
      selectedItems: [],
      timeout: null,
      search: '',
      itemsPerPage: 20,
      itemsPerPageAvailable: [20, 50, 100, 200],
      goToPage: 1,
    }
  },
  methods: {
    keyup () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchAction()
        clearTimeout(this.timeout);
      },1000)
    },
    searchAction() {
      this.$emit('searchAction', this.search)
    },
    changePage() {
      if (this.goToPage > this.pageCount) this.goToPage = this.page;
      else this.$emit('input-pagination', this.goToPage)
    },
    editItem (id) {
      this.$emit('updateData', id);
    },
    deleteItem (id) {
      this.$emit('deleteData', id);
    },
  },
  watch: {
    page (val) {
      if (val > this.pageCount) this.page = this.pageCount;
      if (val < 1) this.page = 1;
      if (this.page !== this.goToPage) this.goToPage = this.page;
    },
    clearSelected() {
      this.selectedItems = []
    },
  },
  computed: {
    itemsCount() {
      const counter = {
        totalItems: this.totalItems,
        startItem: ((this.page * this.itemsPerPage) - this.itemsPerPage) + 1,
        endItem: this.page * this.itemsPerPage
      };

      if (counter.endItem > this.totalItems) counter.endItem = this.totalItems
      return counter
    }
  },
};
</script>
