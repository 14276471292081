import Constants from '@/plugins/constants'

const url = `${Constants.URL_BASE}/q-system/exams`

const getExam = async ({ examId, studentId }) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ examId, studentId }),
    headers: {
      'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0',
      'Content-Type': 'application/json'
    },
  })
  const data = await response.json()
  if(!response.ok) throw data
  return {exam:data}
}
export default getExam
