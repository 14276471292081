<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
            <Breadcrumbs
                :title="$t('modules.pricing.interest.table.breadcrumbs.title')"
                :description="$t('modules.pricing.interest.table.breadcrumbs.subtitle')"
            >
                <Button
                    v-if="canCreate"
                    @clicked="newInterest"
                    :icon="'mdi-plus'"
                    :text="$t('actions.new')"
                    depressed
                ></Button>
            </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                        :headers="headers"
                        :data="installments"
                        :clear-selected="clearSelected"
                        :total-items="totalItems"
                        :sortBy.sync="sort.sortBy"
                        :sortDesc.sync="sort.sortDesc"
                        @searchAction="searchAction"
                        @updateData="updateInterest"
                        :permissions="{update: canUpdate,  delete: canDelete}"
                        :page-count="pagination.pagesAmount"
                        :page="pagination.page"

                        :deleteTitle="$t('modules.pricing.interest.table.message.bulkDelete')"
                        @bulk-delete="bulkDeletePopUp"
                        @deleteData="deletePopUp"
                        @input-pagination="onInputPagination"
                        @length-pagination-change="onLengthPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
import SuperTable from '../Components/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import {$installments} from '../Services';

export default {
    name: 'CreditCardInterestTable',
    components: {
        SuperTable,
        Breadcrumbs,
        Button,
    },
    data() {
        return {
            loading: false,
            search: null,
            page: 1,
            totalItems: null,
            clearSelected: null,
            installments: [],
            termsAdded: [],
            sort: {
                sortBy: 'code',
                sortDesc: false,
            },
            headers: [
                {
                text: this.$t('modules.pricing.interest.table.headers.code'),
                value: 'code',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.installments'),
                value: 'installment',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.percentage'),
                value: 'percentage',
                sortable: false,
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.from'),
                value: 'from',
                align: 'center'
                },
                {
                text: this.$t('modules.pricing.interest.table.headers.to'),
                value: 'to',
                align: 'center'
                }, {
                text: this.$t('modules.pricing.interest.table.headers.status'),
                value: 'status',
                align: 'center'
                }, {
                text: '',
                value: 'action',
                sortable: false,
                align: 'center'
                },
            ],
            pagination: {
                limit: 20,
                page: 1,
                pagesAmount: 1
            },
        };
    },
    watch: {
        sort: {
            handler() {
                this.fetchData();
            },
            deep: true
        }
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        canCreate() {
            return this.$permissions.portaladministrativo.prices.interest.CREATE;
        },
        canUpdate() {
            return this.$permissions.portaladministrativo.prices.interest.READ || this.$permissions.portaladministrativo.prices.interest.UPDATE;
            },
        canDelete() {
            return this.$permissions.portaladministrativo.prices.interest.DELETE;
        },
    },
    methods: {
        bulkDeletePopUp(installments) {
            this.$store.dispatch('commons/openPopUp', {
                title: this.$t('delete_popup_title'),
                content: installments.map(installment => ({value: installment.code})),
                actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(installments)},
                actionSecondary: {
                text: this.$t('actions.cancel'), callback() {
                }
                },
                icon: {color: 'error', name: 'mdi-delete'},
                color: 'primary',
            });
        },
    async bulkDelete(installments) {
        const itemsForDelete = {
            ids: installments.map((installment) => {
            return installment.id;
            })
        };
        try {
            await $installments.bulkDelete(itemsForDelete);
            this.fetchData();
        } catch (err) {
            this.$store.dispatch('commons/openPopUp', {
            title: this.$t('actions.error'),
            content: [{value: this.$t('modules.pricing.interest.table.errorMessage.bulkDeleteError')}],
            actionPrimary: {
                text: this.$t('actions.accept'), callback() {
                }
            },
            icon: {color: 'error', name: 'mdi-close'},
            color: 'primary',
            });
            throw err;
        }
        },
        deletePopUp(id) {
                const installment = this.installments.find(installment => installment.id == id);
                this.$store.dispatch('commons/openPopUp', {
                title: this.$t('delete_popup_title'),
                content: [{value: installment.code}],
                actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteInstallment(id)},
                actionSecondary: {
                text: this.$t('actions.cancel'), callback() {
                }
                },
                icon: {color: 'error', name: 'mdi-delete'},
                color: 'primary',
            });
        },
        async deleteInstallment(id) {
            try {
                await $installments.delete(id);
                this.installments = this.installments.filter(installment => installment.id !== id);
            } catch (error) {
                this.$store.dispatch('commons/openPopUp', {
                title: this.$t('actions.error'),
                content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.pricing.interest.table.errorMessage.deleteError')}],
                actionPrimary: {
                    text: this.$t('actions.accept'), callback() {
                    }
                },
                icon: {color: 'error', name: 'mdi-close'},
                color: 'primary',
                });
                throw error;
            }
        },
        searchAction(data) {
            this.pagination.page = 1;
            this.search = data;
            this.fetchData();
        },
        newInterest() {
            if (this.canCreate) this.$router.push('/pricing/interest/create');
        },
        updateInterest(id) {
            if (this.canUpdate) this.$router.push(`/pricing/interest/update/${id}`);
        },
        async fetchData() {
            if (this.loading) return false;
            this.clearSelected = new Date().getMilliseconds().toString();
            this.loading = true;
            try {
                const params = {
                    page: this.pagination.page - 1,
                    length: this.pagination.limit,
                    orderBy: this.sort.sortBy,
                    orientation: this.sort.sortDesc ? 'desc' : 'asc',
                    search: this.search
                };
                const installments = await $installments.find(null, null, {params});
                this.installments = installments.data.content;
                this.totalItems = installments.data.totalElements;
                this.pagination.pagesAmount = installments.data.totalPages;
            } catch (err) {
                this.installments = [];
                this.pagination.page = 1
                this.pagination.pagesAmount = 1
                this.totalItems = 0;
                throw err;
            } finally {
                this.loading = false;
            }
        },
        onInputPagination(event) {
            this.pagination.page = event;
            this.fetchData();
        },
        onLengthPaginationChange(event) {
        this.pagination = {
            page: 1,
            limit: event,
            pagesAmount: 1
        };
        this.fetchData();
    },
    },
}

</script>
