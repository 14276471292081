<template>
    <v-container fluid class="superTable">
        <v-row no-gutters>
            <v-col sm="4">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    :placeholder="$t('actions.search')"
                    outlined
                    clearable
                    class="superTable__search"
                    @click:clear="clearAction"
                    @keyup.enter="searchAction"
                    @keyup.esc="clearAction"
                    @keyup.delete="clearValidation"
                ></v-text-field>
            </v-col>
            <v-col sm="4">
                <Button
                class="ml-4"
                @clicked="searchAction"
                outlined
                depressed
                :text="$t('actions.search')"
                ></Button>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col sm="12">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    @click:row="(row) => $emit('click-row', row.id)"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    item-key="id"
                    class="superTable__table proceduresInitTable"
                    style="table-layout: fixed"
                >
                    <template v-slot:[`header.data-table-select`]="{ on, props }">
                        <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
                        <v-simple-checkbox class="superTable__table__item__checkbox" color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                    </template>

                    <template v-slot:[`item.name`]="{item}">
                        <div class="superTable__table__item__name">
                            {{ item.name }}
                        </div>
                    </template>

                    <template v-slot:[`item.paymentDate`]="{item}">
                        {{ item.paymentDate ? item.paymentDate : '-' }}
                    </template>


                    <template v-slot:[`item.action`]="{item}">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="superTable__table__item__action">mdi-dots-vertical</v-icon>
                            </template>
                            <v-list class="pa-0">
                                <v-list-item @click="approveProcedure(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-checkbox-marked-outline</v-icon> {{$t('modules.procedures.initTable.approve')}}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="observeProcedure(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-minus-circle-outline</v-icon> {{$t('modules.procedures.initTable.observe')}}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="rejectProcedure(item.id)">
                                    <v-list-item-title>
                                        <v-icon>mdi-close-box-outline</v-icon> {{$t('modules.procedures.initTable.reject')}}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <v-divider class="mt-4 mb-8"></v-divider>
            </v-col>
        </v-row>
        <v-row no-gutters align="center">
            <v-col sm="3">
                <span class="caption d-inline-block">{{ itemsCount.startItem + ' - ' + itemsCount.endItem + ' de ' + itemsCount.totalItems }}</span>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageAvailable"
                    :placeholder="''+itemsPerPage"
                    outlined
                    class="superTable__paginationSelect d-inline-block ml-4"
                ></v-select>
            </v-col>
            <v-col sm="6" class="text-center">
                <v-pagination v-model="page" :length="pageCount" class="superTable__pagination"></v-pagination>
            </v-col>
            <v-col sm="3" align="right">
                <v-text-field
                        v-model="page"
                        max="pageCount"
                        outlined
                        class="superTable__paginationInput d-inline-block mr-4"
                ></v-text-field>
                <span class="caption d-inline-block">{{$t('modules.procedures.initTable.goToPage')}}</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Button from '@/components/Button/Button'

    export default {
        name: 'SuperTableInit',
        components: {
            Button
        },
        props: {
            items: Array,
            totalItems:Number,
            headers: Array,
            clearSelected: String,
            query: {type: String, default: null},
            // selectedItems: {type: Array, default: ()=> []}
        },
        data() { return {
            selectedItems: [],
            search: '',
            page: 1,
            itemsPerPage: 20,
            itemsPerPageAvailable: [20, 50, 100, 200],
        }},
        mounted() {
            if (this.query) this.search = this.query
        },
        methods:{
            searchAction(){
                this.$emit('on-search', this.search);
            },
            clearAction(){
                this.search = '';
                this.$emit('on-search', '');
            },
            clearValidation(){
                if(this.search === ''){
                    this.$emit('on-search', '')
                }
            },
            getAvatar(id){
                return id;
            },
            approveProcedure(id) {
                this.$emit('approve-procedure', id);
            },
            observeProcedure(id) {
                this.$emit('observe-procedure', id);
            },
            rejectProcedure(id) {
                this.$emit('reject-procedure', id);
            }
        },
        watch:{
            'page': function (val) {
                if(val > this.pageCount)
                    this.page = this.pageCount;
                if(val <= 1)
                    this.page = 1;
                
                this.$emit('on-page-change',this.page)
            },
            'items': function () {
                this.selectedItems = []
            },
            'itemsPerPage': function (val) {
                this.$emit('on-items-length-change',val)
            },
        },
        computed: {
            areItemsSelected(){
                return !!this.selectedItems.length
            },
            pageCount() {
                if(this.totalItems){
                    let counter = this.totalItems / this.itemsPerPage;

                    if ( counter < 1 ) {
                        counter = 1
                    }

                    return Math.ceil(counter)
                }
                return 0
            },
            itemsCount() {
                if(this.totalItems){
                    const counter = {
                        totalItems: this.totalItems,
                        startItem: ( ( this.page * this.itemsPerPage ) - this.itemsPerPage ) +  1,
                        endItem: this.page * this.itemsPerPage
                    };

                    if ( counter.endItem > this.totalItems ) {
                        counter.endItem = this.totalItems
                    }

                    return counter;
                }
                return 0
            }
        },
    };
</script>
<style lang="sass">
    .proceduresInitTable table tbody tr
        cursor: pointer
</style>
