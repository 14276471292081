import SellersCreate from '../Views/SellersCreate';
import SellersUpdate from '../Views/SellersUpdate';
import SellersTable from '../Views/SellersTable';

const SellersRoute = [
    {
        path:'',
        component: SellersTable,
        meta: { name: 'Index', group: 'portaladministrativo.commissions', path: 'sellers', permission: 'READ' }
    },
    {
        path:'create',
        component: SellersCreate,
        meta: { name: 'Create Sellers', group: 'portaladministrativo.commissions', path: 'sellers', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: SellersUpdate,
        meta: { name: 'Update Sellers', group: 'portaladministrativo.commissions', path: 'sellers', permission: 'READ' }
    },
];

export default SellersRoute