
const zonesTranslations = {
  es_CL: {
    detail: {
      zone_data: {
        title: 'Datos de la región',
      },
    },
    create: {
      breadcrumbs: {
        title: 'Crea una nueva región',
        subtitle: 'Creación de las regiones en el sistema',
      },
      title: 'Datos de la región',
      description: 'Aquí podrás asignar la información básica de la región que estás creando. Ten en cuenta que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
      // quarters_description: 'En esta sección podrás asignarle a la nueva región los CSEs que incluirá. Ten en cuenta que los CSEs pueden estar disponibles para asignar o ya estar asignadas a una región.',    // :TODO - Fix zonas, borrar linea al subir cambios
      validation_errors: {
        name: 'El nombre de la región es requerido',
      },
      success_message: {
        title: '¡Región creada con éxito!',
      },
      error_message: {
        title: 'La región no pudo crearse',
      },
      alert_message: {
        title: 'Estás creando una región deshabilitada',
      }
    },
    table: {
      breadcrumbs: {
        title: 'Regiones',
        description: 'Información de las regiones del sistema.'
      },
      bulk_delete_error: 'No se pudieron eliminar las regiones seleccionadas',
      delete_error: 'No se pudo eliminar la región seleccionada',
      search: {
        bulkDelete: 'Eliminar regiones'
      }
    },
    update: {
      breadcrumbs: {
        description: 'Modifica la región seleccionada',
      },
      success_message: {
        title: 'Región modificada correctamente',
      },
      error_message: {
        title: 'La región no pudo guardarse',
      },
      alert_message: {
        title: 'Estás guardando una región deshabilitada',
      }
    }
  },
  es_PE: {
      create: {
          sectionLocality:{
              title: 'Distritos',
              subtitle: 'En esta sección podrás asignar los distritos a gestionar.',
              province: 'Departamento *',
              provinceRequired: 'Ingrese una departamento',
              firstListTitle: 'Distritos Disponibles',
              secondListTitle: 'Distritos a asignar: ',
              label: 'distritos',
              error: {
                  errorLocality: 'Debes seleccionar al menos un distrito',
              },
          },
          validation_errors: {
              cities: 'Debes seleccionar al menos un distrito',
          },
      },
      table: {
          headers: {
              cities: 'Distritos',
          },
      },
      update: {
          sectionLocality:{
              title: 'Localidades',
              subtitle: 'En esta sección podrás editar las distritos a gestionar.',
              province: 'Departamento *',
              firstListTitle: 'Distritos Disponibles',
              secondListTitle: 'Distritos a asignar: ',
              label: 'distritos'
          },
      }
  },
  es: {
    enabled: 'Habilitada',
    disabled: 'Deshabilitada',
    // available: 'Disponibles',   // :TODO - Fix zonas, borrar linea al subir cambios
    // selected: 'Seleccionados',   // :TODO - Fix zonas, borrar linea al subir cambios
    detail: {
      breadcrumbs: {
        title: 'ZonaA',
        button: {
          icon: 'mdi-pencil',
          name: 'Editar',
          color: 'primary'
        }
      },
      zone_data: {
        title: 'Datos de la zona',
        items: {
          'name': 'Nombre',
        },
      },
      zone_site: {
        title: 'Quarters incluídas',
        items: {
          'site': 'Quarters',
        },
      },
    },
    create: {
      breadcrumbs: {
        title: 'Crea una nueva zona',
        subtitle: 'Creación de las zonas en el sistema',
      },
      title: 'Datos de la zona',
      description: 'Aquí podrás asignar la información básica de la zona que estás creando. Ten en cuenta que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
      // quarters_title: 'Centros de servicio',    // :TODO - Fix zonas, borrar linea al subir cambios
      // quarters_description: 'En esta sección podrás asignarle a la nueva zona los CSEs que incluirá. Ten en cuenta que los CSEs pueden estar disponibles para asignar o ya estar asignadas a una zona.',    // :TODO - Fix zonas, borrar linea al subir cambios
      labels: {
        name: 'Nombre',
      },
      sectionLocality:{
        title: 'Localidades',
        subtitle: 'En esta sección podrás asignar las localidades a gestionar.',
        province: 'Provincia *',
        provinceRequired: 'Ingrese una provincia',
        firstListTitle: 'Localidales Disponibles',
        secondListTitle: 'Localidades a asignar: ',
        label: 'localidades',
        error: {
          errorLocality: 'Debes seleccionar al menos una localidad',
        },
      },
      validation_errors: {
        name: 'El nombre de la zona es requerido',
        // ceos: 'Debes seleccionar al menos un CSE',    // :TODO - Fix zonas, borrar linea al subir cambios
        cities: 'Debes seleccionar al menos una ciudad',
      },
      success_message: {
        title: '¡Zona creada con éxito!',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
          back: 'Volver'
        }
      },
      error_message: {
        title: 'La zona no pudo crearse',
      },
      alert_message: {
        title: 'Estás creando una zona deshabilitada',
        actions: {
          primary_text: 'Aceptar',
          secondary_text: 'Cancelar'
        }
      }
    },
    table: {
      breadcrumbs: {
        title: 'Zonas',
        description: 'Información de las zonas del sistema.'
      },
      bulk_delete_error: 'No se pudieron eliminar las zonas seleccionadas',
      delete_error: 'No se pudo eliminar la zona seleccionada',
      headers: {
        name: 'Nombre',
        // ceos: 'CSE',    // :TODO - Fix zonas, borrar linea al subir cambios
        cities: 'Ciudades',
        status: 'Estado',
      },
      message: {
        delete: '¿Estás seguro de eliminar?'
      },
      search: {
        search: 'Buscar',
        gotopage: 'ir a página',
        edit: 'Editar',
        delete: 'Eliminar',
        bulkDelete: 'Eliminar zonas'
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar zona',
        description: 'Modifica la zona seleccionada',
      },
      labels: {
        name: 'Nombre',
      },
      sectionLocality:{
        title: 'Localidades',
        subtitle: 'En esta sección podrás editar las localidades a gestionar.',
        province: 'Provincia *',
        firstListTitle: 'Localidales Disponibles',
        secondListTitle: 'Localidades a asignar: ',
        label: 'localidades'
      },
      quarters_title: 'Centros de servicio',
      success_message: {
        title: 'Zona modificada correctamente',
        actions: {
          primary_text: 'Cerrar',
          back: 'Volver'
        }
      },
      error_message: {
        title: 'La zona no pudo guardarse',
      },
      alert_message: {
        title: 'Estás guardando una zona deshabilitada',
        actions: {
          primary_text: 'Aceptar',
          secondary_text: 'Cancelar'
        }
      }
    }
  },
  en: {

    detail: {
      breadcrumbs: {
        title: 'Zone A',
        button: {
          icon: 'mdi-pencil',
          name: 'Edit',
          color: 'primary'
        }
      },
      zone_data: {
        title: 'Zone Data',
        items: {
          'name': 'Name',
        },
      },
      zone_site: {
        title: 'Included quarters',
        items: {
          'site': 'Quarters',
        },
      },
    },
  },
};


export default zonesTranslations
