<template>
  <div>
    <div class="mb-5">
      <h4 class="primary--text"> {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirementsList') }}</h4>
      <div v-if="requirements" class="d-flex">
         <v-icon large :class="'mr-2 ' + (requirements.materiasOK ? 'teal--text' : '') ">mdi-check-circle-outline</v-icon>
         <div class="text--secondary">
            <p class="font-weight-bold mb-0 mt-2">
              {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.title') }}
            </p>
            <p class="mt-0 body-2">
              Antes del {{requirements.fechaLimiteParaMateriasAprobadas}}
            </p>
         </div>
      </div>
        <div v-if="!$isTeclab">
          <p class="mb-0 font-weight-bold body-1 text--secondary">
            {{`${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subtitle')}`}}
          </p>
          <v-list class="pl-5">
            <v-list-item-group>
                <v-list-item-subtitle class="font-weight-bold body-2 text--secondary text-wrap">
                    {{`1. ${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subitem_one')}`}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold body-2 text--secondary text-wrap">
                    {{`2. ${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subitem_two')}`}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold body-2 text--secondary text-wrap">
                    {{`3. ${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subitem_three')}`}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold body-2 text--secondary text-wrap">
                    {{`4. ${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subitem_four')}`}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-weight-bold body-2 text--secondary text-wrap">
                    {{`5. ${ $t('modules.portalalumno.practice.stepper.dateStep.requirements.requirements.subitem_five')}`}}
                </v-list-item-subtitle>
            </v-list-item-group>
          </v-list>
        </div>
    </div>
    <div class="mb-5">
      <h4 class="primary--text"> {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.startDate') }}</h4>
      <div v-if="requirements" class=" d-flex align-center">
        <v-icon class="mr-2 primary--text pa-1" :style="'border:2px solid '+($isTeclab?'#106ca7':'#012169')+';border-radius:50%'">mdi-calendar</v-icon>
        <div class="d-flex flex-column justify-center text--secondary ">
          <p class="mt-4 mb-0 font-weight-bold">{{requirements.fechaProximoComienzo}} - {{requirements.fechaFinPP}}</p>
          <p v-if="requirements" class="body-2"> {{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.time') }} {{requirements.fechaLimiteParaMateriasAprobadas}}</p>
        </div>
      </div>
    </div>
    <div class="mb-5 primary--text font-weight-bold">
      <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.next_step') }} <a v-if="!$isTeclab" href="https://6058217.fs1.hubspotusercontent-na1.net/hubfs/6058217/Direcci%C3%B3n%20de%20Egresados%20y%20Empleabilidad/Inscripci%C3%B3n%20pr%C3%A1cticas/HORAS%20DE%20PR%C3%81CTICA%20IPP2021%20(%C3%BAltimo).pdf" target="_blank">(ver detalles)</a></p>
    </div>
    <div class="mb-5 font-weight-bold text--secondary body-2">
      <h4 class="primary--text" v-if="!$isTeclab">{{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.search_practice_center') }}</h4>
      <p>{{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.text') }}</p>
    </div>
    <div>
      <p class="body-2 font-weight-bold text--secondary" v-if="!$isTeclab">{{ $t('modules.portalalumno.practice.stepper.dateStep.requirements.letter') }}</p>
       <v-text-field
          :label="$t('modules.portalalumno.practice.stepper.dateStep.requirements.referrer_name')"
          placeholder="Arcos Dorados SA"
          outlined
          v-model="name_business"
          @keyup="keyup"
          @keyup.enter="$emit('getConditionsPP')"
        ></v-text-field>
        <v-btn
          text
          :disabled='!name_business'
          @click="validateBusiness"
          class="primary--text font-weight-bold"
        >
        <v-icon small class="mr-1">mdi-download</v-icon>
        {{
          $t('modules.portalalumno.practice.stepper.dateStep.requirements.download')
          }}
          </v-btn>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RequirementsPP',
    props: {
      requirements: {type: Object}
    },
    data(){
      return {
        name_business: null
      }
    },
    methods: {
      keyup () {
        this.$emit('setNameBusiness', this.name_business)
      },
      validateBusiness(){
        if(!this.name_business){
          this.$store.dispatch('commons/openPopUp', {
            title: this.$t('modules.portalalumno.practice.stepper.dateStep.warning_title'),
            content: [{value: this.$t('modules.portalalumno.practice.stepper.dateStep.warning_text')}],
            actionPrimary: {text: this.$t('actions.close'), callback() {}},
            actionSecondary: null,
            icon: { color: 'warning', name:'mdi-alert-outline' },
            color: 'primary',
          })
        } else {
          this.$emit('getNameCompany', this.name_business)
        }
      }
    },
    computed: {
  },
}
</script>

<style lang="sass" scoped>

</style>
