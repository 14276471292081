
import DocumentationView from '../Views/DocumentationView'
import DocTypesView from '../Views/DocTypesView'

const DocumentationRoute = [
    {
        path: '',
        component: DocumentationView,
        meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'documentation', permission: 'READ' }
    },
    {
        path: 'types',
        component: DocTypesView,
        meta: { name: 'DocTypesView', group: 'portaladministrativo.academic', path: 'documentation', permission: 'READ' }
    },
]

export default DocumentationRoute