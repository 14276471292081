<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.commissions.sellers.table.breadcrumbs.title')"
                    :description="$t('modules.commissions.sellers.table.breadcrumbs.description')"
                >
                    <Button
                        v-if="canCreate"
                        @clicked="newSeller"
                        :icon="'mdi-plus'"
                        :text="$t('actions.new')"
                        depressed
                    ></Button>
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="sellers"
                            :clear-selected="clearSelected"
                            :page="pagination.currentPage"
                            :pageCount="pageCount"
                            :permissions="{update: canUpdate, delete: canDelete}"                            
                            @updateUser="updateSeller"
                            @deleteUser="deletePopUp"
                            @bulk-delete="bulkDeletePopUp"
                            :deleteTitle="$t('modules.commissions.sellers.table.actions.bulk_delete')"
                            @searchAction="searchAction"
                            :sortBy.sync="sort.sortBy"
                            :sortDesc.sync="sort.sortDesc"
                            @inputPagination="onInputPagination"
                            @lengthPaginationChange="onLengthPaginationChange"
                            :totalItems="totalItems"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import Button from '@/components/Button/Button';
    import {  $sellers } from '../Services';

    export default {
        name: 'SellersTable',
        components: {
            SuperTable,
            Breadcrumbs,
            Button,
        },
        data() {
            return {
                pageCount:1,
                loading: false,
                totalItems: 0,
                clearSelected: null,
                search: null,
                sellers: [],
                pagination : {
                    page: 1,
                    limit: 20,
                    currentPage: 1,
                    pagesAmount: 1
                },
                sort: {
                    sortBy: 'sellerName',
                    sortDesc: true,
                },
                headers: [
                    {
                        text: this.$t('modules.commissions.sellers.table.headers.code'),
                        value: 'sellerCode',
                        sort: () => 0
                       
                    },{
                        text: this.$t('modules.commissions.sellers.table.headers.name'),
                        value: 'sellerName',
                        align: 'start',
                        sort: () => 0
                       
                    },{
                        text: this.$t('modules.commissions.sellers.table.headers.careers'),
                        value: 'careersAmount',
                        align: 'center',
                        sortable: false,
                    },{
                        text: this.$t('modules.commissions.sellers.table.headers.students'),
                        value: 'studentsAmount',
                        align: 'center',
                        sortable: false,
                    },{
                        text: this.$t('modules.commissions.sellers.table.headers.status'),
                        value: 'status',
                        align: 'center',
                         sort: () => 0
                    },{
                        text: '',
                        value: 'action',
                        sortable: false,
                    }
                ],
            }
        },
        mounted() {
            this.fetchData();
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.sellers.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.sellers.READ || this.$permissions.portaladministrativo.commissions.sellers.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.commissions.sellers.DELETE
            },
            
        },
        methods: {
            deletePopUp(id){
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteSeller(id)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async fetchData() {
                if (this.loading) return false
                this.clearSelected = new Date().getMilliseconds().toString();
                this.loading = true
                try {

                    const params = {
                        page: this.pagination.currentPage - 1,
                        length: this.pagination.limit,
                        orderBy: this.sort.sortBy === undefined ? 'sellerName' : this.sort.sortBy,
                        orientation: this.sort.sortDesc ? 'asc' : 'desc',
                        param: this.search,
                    }
                    const sellers = await $sellers.filter(null , {params})   
                   
                    this.sellers = sellers.data.content
                    this.totalItems = sellers?.data?.totalElements;
                    this.pageCount = sellers.data.totalPages;
                } catch (error) {
                    this.sellers = []
                    this.totalItems = 0
                    throw error
                } finally {
                    this.loading = false
                }
            },
            bulkDeletePopUp(sellers) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: sellers.map(seller => ({ value: `${seller.sellerCode} - ${seller.sellerName}` })),
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(sellers)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async bulkDelete (sellers) {
                
                const itemsForDelete = sellers.map((seller) => {
                    return { id: seller.id }
                });
                try {
                    await $sellers.bulkDelete(itemsForDelete)
                    
                    this.fetchData()

                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{value: this.$t('modules.commissions.sellers.table.messages.bulk_delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error;
                }
            },
             async onInputPagination(event) {
                this.pagination.page = event;
                this.pagination.currentPage = event;
                this.fetchData();
            },
            onLengthPaginationChange(event) {

                this.pagination = {
                    page: 1,
                    limit: event,
                    currentPage: 1,
                    pagesAmount: 1
                };
                this.fetchData();
            },

            newSeller() {
                if (this.canCreate) this.$router.push('/commissions/sellers/create')
            },
            updateSeller(id) {
                if (this.canUpdate) this.$router.push(`/commissions/sellers/update/${id}`)
            },
            async deleteSeller (id) {
                
                try {
                    await $sellers.delete(id);
                    this.fetchData();
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('actions.error'),
                        content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.sellers.table.messages.delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error
                }
            },
         async  searchAction(data) {
                this.pagination.currentPage = 1
                this.search = data;
                this.fetchData()
            },
        },
        watch:{
            sort: {
                handler(){
                    this.fetchData();
                },
                deep: true
            }
        }
    }
</script>