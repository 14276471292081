import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('pricing');

export class Scholarships extends Api {
  constructor(http, config = {}) {
    super(http, url, 'scholarships', config);
  }

  assignStudents(scholarship, students, config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, `assign-student/${scholarship}`);

    return this._http.post(uri, students, config);
  }

  removeStudent(config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, `assign-student/delete`);

    return this._http.delete(uri, config);
  }

  removeStudents(students, config = {}) {
    config = { ...this._config, ...config };
    const uri = this._uriConstructor(null, `assign-student/bulk`);

    return this._http.post(uri, students, config);
  }

  async findPaginated(apiMethod = null, filter = null, config = {}) {
    config = { ...this._config, ...config };
    let uri = this._uriConstructor(null, apiMethod);
    
    let i = 0;
    Object.keys(filter).forEach(key => {
      if (i === 0) uri += `?${key}=${filter[key]}`;
      else uri += `&${key}=${filter[key]}`;
      i++;
    });

    return this._http.get(uri, config);
  }
}
