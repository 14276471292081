<template>
  <v-container fluid class="d-flex pa-0 pb-8">
    <v-container class="container-custom" v-if="!showSuccesMessage">
      <v-row>
        <v-col>
          <Breadcrumbs
            :title="$t('modules.academic.sanctions.sanctionAssign.update.title')"
            description=""
          >
          <v-menu v-if="!editAvaliable" offset-y >
                        <template v-slot:activator="{ on: menu }">
                            <v-btn text color="primary" v-on="{ ...menu }" >
                                <v-icon>mdi-dots-vertical</v-icon>
                                {{ $t('modules.academic.sanctions.details.breadcrumbs.actions') }}
                            </v-btn>
                        </template>
                        <v-list class="pa-0">
                            <v-list-item @click="editSanctionDetails">
                                <v-list-item-title>
                                    <v-icon>mdi-pencil</v-icon>
                                    {{ $t('actions.edit') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
          </Breadcrumbs>
          </v-col>
      </v-row>
      <v-row justify="center">
       <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
         <OutlinedCard
         title="Datos del alumno"
         subtitle="Datos del alumno con una sancion asignada"
         class="mb-6"
         >
          <v-row class="mt-8">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardUser.name')}}</p>
            </v-col>
            <v-col sm="5">
              <h5>{{userData.fullname}}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardUser.doc_type')}}</p>
            </v-col>
            <v-col sm="5">
              <h5>{{userData.doc_type}}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardUser.doc_number')}}</p>
            </v-col>
            <v-col sm="5">
              <h5>{{userData.doc_number}}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardUser.career')}}</p>
            </v-col>
            <v-col sm="5">
              <h5>{{userData.career}}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardUser.legacy')}}</p>
            </v-col>
            <v-col sm="5">
              <h5>{{userData.legacy}}</h5>
            </v-col>
          </v-row>
         </OutlinedCard>
       </v-col>
      </v-row>
      <v-row justify="center">
       <v-col class="py-0" sm="12" style="padding-left: 32px; padding-right: 32px;">
         <OutlinedCard
         :title="$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.title')"
         :subtitle="$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.subtitle')"
         class="mb-6"
         >
          <v-row class="mt-8">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.area')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.area}}</h5>
              <v-select
                v-else
                :items="sanctionType"
                v-model="selectedSanction"
                item-text="meaning"
                item-value="value"
                outlined
                @change="getUpdateDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.type')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.type}}</h5>
              <v-select
                v-else
                :items="sanctionLevelType"
                v-model="selectedLevelType"
                item-text="meaning"
                item-value="value"
                outlined
                @change="getUpdateDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.reason')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.reason}}</h5>
              <v-combobox
                v-else
                v-model="selectedSubject"
                outlined
                :items="subjects"
                item-text="description"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedSanction === 302">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.subject')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.assignature}}</h5>
              <v-combobox
                v-else
                v-model="selectedDepartment"
                outlined
                :items="departments"
                item-text="descriptionSubject"
                @change="getDateExams"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedSanction === 302">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.examens')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.examen}}</h5>
              <v-select
                v-else
                v-model="selectedExamen"
                :items="examenes"
                item-text="examDate"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>
          <v-row v-if="subjects.length && selectedSubject">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.dateEnd')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="selectedSubject.dateEndSanction">{{selectedSubject.dateEndSanction}}</h5>
              <h5 v-else>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.permanent')}}</h5>
            </v-col>
          </v-row>
          <v-row v-if="!editAvaliable">
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.dateEnd')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="sanctionDetails.date_end">{{sanctionDetails.date_end}}</h5>
              <h5 v-else>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.permanent')}}</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="5">
              <p>{{$t('modules.academic.sanctions.sanctionAssign.update.cardSanction.observation')}}</p>
            </v-col>
            <v-col sm="5">
              <h5 v-if="!editAvaliable">{{sanctionDetails.observations}}</h5>
              <v-textarea
                v-else
                v-model="sanctionDetails.observations"
                outlined
                no-resize
              />
            </v-col>
          </v-row>
         </OutlinedCard>
         <div v-if="editAvaliable" class="d-flex justify-end mb-4">
            <Button
            white
            :text="$t('actions.cancel')"
            @clicked="$router.replace('/academic/sanctions/assigned')"
            class="mr-4"
             ></Button>

            <Button
            :loading="btnLoading"
            :success="btnSucces"
            :error="btnError"
            :text="$t('modules.academic.sanctions.update.btn.update')"
            :successText="$t('actions.created')"
            :disabled="!canUpdate"
            @clicked="updateSanctionAssigned"
            :errorText="'Error'"
            ></Button>
          </div>
       </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
    v-if="showSuccesMessage"
    :type="successMessage.type"
    :title="successMessage.title"
    :actionPrimary="successMessage.actionPrimary"
    :actionSecondary="successMessage.actionSecondary"
  />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import Button from '@/components/Button/Button';
import { $sanctionStudents, $types, $sanction, $materias, $examenes } from '../Services';
import { mapGetters } from 'vuex'
export default {
  name: 'SanctionAssignedUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage
  },
  data() {
    return {
      loading: false,
      editAvaliable: false,
      idSanction: this.$route.params.id,
      sanctionDetails: {},
      sanctionLevelType: [],
      selectedLevelType: null,
      sanctionType: [],
      selectedSanction: null,
      subjects: [],
      selectedSubject: null,
      departments: [],
      selectedDepartment: null,
      userData: {},
      examenes: [],
      selectedExamen: null,
      btnSucces: false,
      btnError: false,
      btnLoading: false,
      showSuccesMessage: false,
      successMessage: {
        type: 'success',
        title: this.$t('modules.academic.sanctions.create.success_message.title'),
        actionPrimary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.primary_text'), callback: () => { this.$router.push('/academic/sanctions/assigned') } },
        actionSecondary: { text: this.$t('modules.academic.sanctions.create.success_message.actions.secondary_text'), callback: () => { this.createdNewSanction = false } }
      },
    }
  },
  mounted(){
    this.getInitialData();
  },
  methods: {
    async getInitialData() {
      this.getSanctionDetail();
      this.getSanctionTypes();
      this.getSanctionLevel();
    },
    async getSanctionDetail() {
      try{
        const { data }  = await $sanctionStudents.findById(this.idSanction);
        await this.getFullDataStudent(data.student.identification);
        const details = {
          area: data.sanction?.sanctionType.meaning,
          type: data.sanction?.sanctionLevelType.meaning,
          reason: data.sanction.description,
          date_end: data.dateEnd,
          assignature: data.sanction.title,
          examen: data.sanction.code,
          observations: data.observations
        };
        this.selectedSanction = data.sanction.sanctionType.value;
        this.selectedLevelType = data.sanction.sanctionLevelType.value;
        this.sanctionDetails = details;

      }catch(error){
        this.sanctionDetails = {}
        throw error
      }
    },
    async getFullDataStudent(document) {
       try{
      const { data } = await $sanctionStudents.find('student',null,{params: {
        dni: document
      }});
      const  [student] = data.content;
        const userData = {
          fullname: `${student.name} ${student.lastname}`,
          doc_type: student.type.meaning,
          doc_number: student.identification,
          career: student.career.description,
          legacy: student.studentCareerId,
          id: student.id,
          career_exames: student.examRequests
        };
        this.userData = userData;
        this.getDepartments();
      }
      catch{
        this.userData = {};
      }
    },
     async getSanctionTypes() {
       try{
        const { data } = await  $types.find('', null, {params:{type:'SANCTION_TYPE'}})
        this.sanctionType = data;
       }catch{
         this.sanctionType = [];
       }
    },
    async getSanctionLevel() {
      try{
        const { data } = await $types.find('', null, {params:{type:'SANCTION_LEVEL_TYPE'}})
        this.sanctionLevelType = data;

      }catch{
        this.sanctionLevelType = [];
      }
    },
    async getDepartments() {
      try{
        const param = {
          leg: this.userData.legacy,
        }

        const { data } = await $materias.find('examSubjects', null, {params: param})
        //here
        this.departments = data;
      }catch{
        this.departments = [];
      }
    },
    async getDateExams() {

      try{
        const { data } = await $examenes.find('getExamsBySubject', null, {params:{subjectCode: this.selectedDepartment.codeSubject, studentCareerId:this.userData.legacy}})
        this.examenes = data;
      }catch {
        this.examenes = [];
      }
    },
    async getUpdateDate() {
      try{
      if(this.selectedLevelType && this.selectedSanction){
        this.subjects = [];
        this.selectedSubject = null;
        const dataToPass = {
          sanctionLevel: this.selectedLevelType,
          sanctionType: this.selectedSanction,
        }
        const { data } = await $sanction.find('', null, {params: dataToPass})
        this.subjects = data.content;
      }
      }catch{
        this.subjects = [];
      }
    },
    editSanctionDetails() {
      this.editAvaliable = true;
    },
    async updateSanctionAssigned() {
      if(this.canUpdate){
        try{
            const userCreateId = this.userId;
            const subjectSelected = this.selectedSubject?.id || null;
            const dataToSend = {
              student: {
                id: this.userData.id
              },
              studentCareerId: this.userData.legacy,
              examInformation: {
                examRequestId: this.selectedExamen,
              },
              observations: this.sanctionDetails.observation,
              status: true,
              sanction:{
                  id: subjectSelected
              },
              userCreate: {
                id: userCreateId
              }

            };
            await $sanctionStudents.update(`${this.idSanction}`,dataToSend);
            this.showSuccesMessage = true;
            this.successMessage.type = 'success';
            this.successMessage.title = this.$t('modules.academic.sanctions.create.success_message.title');
            this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/academic/sanctions/assigned') } };
            this.successMessage.actionSecondary = null
        }catch(error){
          this.showSuccesMessage = true;
          this.successMessage.type = 'error';
          this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.sanctions.create.error_message.title');
          this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.$router.go(-1) }}
          this.successMessage.actionSecondary = null;
        }
      }
    }
  },
  computed: {
    ...mapGetters(['commons/getUserID']),
    userId() {
      return this['commons/getUserID']
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.academic.sanctions.ASSIGNED_UPDATE
    }
  }
}
</script>
