import Profile from '../Profile';
import StudentFinder from '../views/StudentFinder';
import ProfileLayout from '../views/ProfileLayout';
import StudentProfile from '../views/StudentProfile';

const PerfilRoute = {
  path: '/profile',
  meta: {
    name: {
      en: 'Profile',
      es: 'Perfil',
    },
  },
  component: Profile,
  children: [
    {
      path: '/',
      component: ProfileLayout,
      meta: { name: 'Index' }
    }, {
      path: 'studentFinder',
      component: StudentFinder,
      meta: { name: 'StudentFinder' }
    }, {
      path: 'studentFinder/:id',
      component: StudentProfile,
      meta: { name: 'StudentProfile' }
    },
  ],
};
export default PerfilRoute;
