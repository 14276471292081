<template>
  <div class="container">
    
      <h1 class="headline">{{$t('modules.examExceptions.create.stepper.subjectSelection.component.title')}}</h1>
      <h2 class="body-1 mt-2">{{$t('modules.examExceptions.create.stepper.subjectSelection.component.subtitle')}}</h2>
      <SuperTable
        class="mt-6"
        :headers="headers"
        :items="subjects"
        @subjectSelection="selectSubject"
      />
  </div>
</template>

<script>
import SuperTable from '../SuperTable/SuperTableCreate'

export default {
    name:'SubjectSelection',
    components: {
      SuperTable,
    },
    props:{
      subjects: Array,
    },
    data(){
      return{
        headers: [
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.subject'), sortable: false, value: 'subject', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.condition'), sortable: false, value: 'condition', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.term'), sortable: false, value: 'term', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.validity'), sortable: false, value: 'validity', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.professor'), sortable: false, value: 'professor', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.count'), sortable: false, value: 'examsCount', class:'primary--text'},
          {text:this.$t('modules.examExceptions.create.stepper.subjectSelection.component.headers.state'), sortable: false, value: 'status', class:'primary--text'}, 
        ],
      }
    },
    methods:{
      selectSubject(data){
        this.$emit('selectSubject', data)
      }
    },
}
</script>