<template>
    <v-container fluid class="fullHeight scrollableContent">
        <v-container>
            <v-row>
                <v-col cols="12" sm="10" lg="8" class="offset-sm-1 offset-lg-2">
            
                    <div class="py-6 d-flex flex-column mb-8">
                        <div class="d-flex justify-start">
                            <v-img
                                max-width="100px" height="auto" contain
                                :src="logoPath"
                            ></v-img>
                        </div>
                    </div>
            
                    <SuccessMessage
                        v-if="successMessage.type != null"
                        :type="successMessage.type"
                        :title="successMessage.title"
                        :subtitle="successMessage.subtitle"
                        :actionPrimary="successMessage.actionPrimary"
                    />

                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import Constants from '@/plugins/constants'
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage'
    import { mapGetters } from 'vuex';

    export default {
        name: 'EcertIndex',
        components:{
            SuccessMessage
        },
        data () {
            return {
                url_data_server: Constants.URL_DATA_SERVER,
                token: null,
                code: null,
                successMessage: {
                    type: null,
                    title: null,
                    subtitle: null,
                    actionPrimary: {text: 'Ir al Campus', callback() { window.location.href = 'https://campus.ipp.cl/' }}
                },
            }
        },
        async mounted() {
            if (this.$route.query.T) this.token = this.$route.query.T
            if (this.$route.query.C) this.code = this.$route.query.C

            const response = await fetch(`https://api.ipp.cl/gw/student/ecert/notification?token=${this.token}&code=${this.code}`, {
                method: 'POST',
                mode: 'cors',
                headers: {'Authorization': 'Basic cG9ydGFsLXRlY2xhYjpUaDEkSXNNeVN1cGVyU2VjcjN0'},
            })

            if (this.code === '0') {
                if (response.status > 300) {
                    this.successMessage.type = 'warning'
                    this.successMessage.title = 'Firma validada correctamente'
                    this.successMessage.subtitle = 'Si bien la firma fue validada, no pudimos actualizar el estado del estudiante.'
                } else {
                    this.successMessage.type = 'success'
                    this.successMessage.title = 'Firma validada correctamente'
                    this.successMessage.subtitle = null
                }
            } else {
                this.successMessage.type = 'error'
                this.successMessage.title = 'No pudimos validar la firma'
                this.successMessage.subtitle = null
            }
        },
        computed: {
            ...mapGetters({
                logoPath: 'commons/getLogoImageUrl',
            }),
        },
    }
</script>