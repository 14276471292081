import RangesCreate from '../Views/RangesCreate';
import RangesUpdate from '../Views/RangesUpdate';
import RangesTable from '../Views/RangesTable';

const RangesRoute = [
    {
        path:'',
        component: RangesTable,
        meta: { name: 'Index', group: 'portaladministrativo.commissions', path: 'ranges', permission: 'READ' }
    },
    {
        path:'create',
        component: RangesCreate,
        meta: { name: 'Create Ranges', group: 'portaladministrativo.commissions', path: 'ranges', permission: 'CREATE' }
    },
    {
        path: 'update/:id',
        component: RangesUpdate,
        meta: { name: 'Update Ranges', group: 'portaladministrativo.commissions', path: 'ranges', permission: 'READ' }
    },
];

export default RangesRoute