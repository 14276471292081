import ExamTitlesNotes from '../ExamTitlesNotes.vue'
import ExamTitlesNotesTable from '../Views/ExamTitlesNotesTable.vue'

const ExamTitlesNotesRoute =
{
  path: '/exam-titles-notes',
  meta: {
    name: {
      en: 'ExamTitlesNotes',
      es: 'Exámen de Título'
    }
  },
  component: ExamTitlesNotes,
  children: [
    {
      path: '/',
      component: ExamTitlesNotesTable,
      meta: { name: 'Index', group: 'portaladministrativo.students', path: 'exam_title_notes', permission: 'READ' }
    }
  ]
};

export default ExamTitlesNotesRoute
