<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom">
        <Breadcrumbs :breadcrumbs="$t('discounts.detail.breadcrumbs')" :topAction="editDiscount"></Breadcrumbs>
      </v-container>
      <v-container class="container-custom px-8">
        <template>
          <v-row justify="center">
            <v-col class="py-0" sm="9">
              <OutlineCard>
                <span
                  class="title font-weight-bold ma-0 px-0 pt-2"
                >{{ $t('discounts.detail.section_data.title') }}</span>
                <v-row class="mt-4">
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.name') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.code') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.status') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.studentType') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.modality') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_data.items.careers') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                </v-row>
                <v-divider class="mt-6 mb-8"></v-divider>
                <span
                  class="title font-weight-bold ma-0 px-0 pt-2"
                >{{ $t('discounts.detail.section_zone.title') }}</span>
                <v-row class="mt-4">
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_zone.items.product') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_zone.items.zone') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_zone.items.from') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_zone.items.semester') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_zone.items.bimester') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                </v-row>
                <v-divider class="mt-6 mb-8"></v-divider>
                <span
                  class="title font-weight-bold ma-0 px-0 pt-2"
                >{{ $t('discounts.detail.section_restriction.title') }}</span>
                <v-row class="mt-4">
                  <v-col sm="4" class="d-flex align-center">
                    <span
                      class="caption text-uppercase"
                    >{{ $t('discounts.detail.section_restriction.items.limit') }}</span>
                  </v-col>
                  <v-col sm="8" class="align-center">
                    <span class="body-2">Sarasaa</span>
                  </v-col>
                </v-row>
              </OutlineCard>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlineCard from '@/components/OutlinedCard/OutlinedCard';

export default {
  components: {
    Breadcrumbs,
    OutlineCard,
  },
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      if (window.history.length > 1) {
        const getCurrentRoute = this.$route.matched[this.$route.matched.length - 1]
        if (getCurrentRoute.meta.name !== 'Index') {
          this.$router.go(-1)
        } else {
          this.$router.push('/')
        }
      }
    },
    editDiscount () {
      this.$router.push('/discounts/update')
    },
  },
}
</script>
