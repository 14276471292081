import ExamTest from '../ExamTest.vue'
import ExamTestAssestment from '../Views/Assestment.vue';
import ExamTestAssestmentReview from '../Views/AssestmentReview.vue';

const ExamTestRoute = [{
    path: '/practicadeexamen',
    meta: {
        name: {
            es: 'Exámenes',
            en: 'Exams',
        }
    },
    component: ExamTest,
    children: [
        {
            path: '/',
            redirect: '/login',
        },
        {
          path:'preview',
          name:'preview',
          component:()=>import(/* webpackChunkName:"exam-preview" */ '../Views/Assestment.vue'),
          meta: { name: 'contents', group: 'portaladministrativo.exams', path: 'statements', permission: 'READ' },
          props: ({query})=>({examPreviewId: query.id ,isExamPreview:true})
        },
        {
            path: ':courseId/:userId',
            component: ExamTestAssestment,
            meta: { name: 'Assestment' }
        },{
            path: ':courseId/:userId/:examId/review',
            name: 'practica-review',
            component: ExamTestAssestmentReview,
            meta: { name: 'Review' },
            props: true
        },
    ]
}];

export default ExamTestRoute
