<template>
  <div class="wrapper colors mb-4">
    <div class="left box">
      <div class="py-2 pl-5 pr-3 box">
        <h5 class="primary--text">{{$t('components.fromToCard.origen')}}</h5>
        <div class="career-headline flex-column align-start">
          <h1 class="primary--text">{{ from.title }}</h1>
          <span v-if="from.subtitle" class="caption">{{ from.subtitle }}</span>
        </div>
      </div>
    </div>
    <div class="right box">
      <div class="py-2 pl-5 pr-3 box">
        <h5 class="ml-8 pl-5 primary--text">{{$t('components.fromToCard.destino')}}</h5>
        <div class="ml-8 pl-5 career-headline flex-column align-start">
          <h1 class="primary--text">{{ to.title }}</h1>
          <span v-if="to.subtitle" class="caption">{{ to.subtitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FromToCard',
  props: {
    from: Object,
    to: Object,
  }
}
</script>

<style lang="sass" scoped>

.career-headline
  flex: 1
  display: flex
  align-items: center

  h1
    font-size: 1.2rem !important
    font-weight: 700

.wrapper 
  display: flex

  .box 
    border: 1px solid #ebebeb
    height: 7rem
    display: flex
    flex: 1 0 0
    flex-direction: column

.wrapper .box span 
  display: inline-flex

/* Arrow ---------------- */
.left 
  position: relative

.left:before 
  content: ''
  width: 0 
  height: 0
  position: absolute
  right: 0
  top: 0
  transform: translateX(3.5rem)
  border-top: 3.5rem solid transparent
  border-bottom: 3.5rem solid transparent
  border-left: 3.5rem solid green


/* Colorized ---------------- */
.wrapper.colors div 
  border: 0  

.wrapper.colors .left:before 
  border-left-color: #e1e4ed

.wrapper.colors .left 
  background-color: #e1e4ed

.wrapper.colors .right 
  background-color: #F6F7FB

</style>