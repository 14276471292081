<template>
  <v-card class="px-8 py-6 mb-3 OutlinedCard" :class="[{'remove-border-top': removeBorderTop, 'remove-border': removeBorder, 'remove-shadow': removeShadow }]" :disabled="disabled">
    <template v-if="(title || subtitle)">
      <div class="d-flex justify-space-between">
        <v-card-title v-if="title" class="headline font-weight-bold ma-0 px-0 pt-2">{{ title }}</v-card-title>
        <div class="d-flex align-center">
          <v-switch color="primary" class="ma-0 pa-0 mt-0 mr-2" :label="switchLabel" :input-value="switchValue" @change="$emit('update:switchValue', $event); $emit('switchChange', $event)"
            v-if="switcher"></v-switch>
          <v-menu bottom left v-if="actions">
            <template v-slot:activator="{ on }">
              <v-icon color="grey" size="24" v-on="on">mdi-dots-horizontal</v-icon>
            </template>
            <slot name="actions"></slot>
          </v-menu>
        </div>
      </div>
      <v-card-text class="body-2 pa-0 ma-0" v-if="subtitle" v-html="subtitle"></v-card-text>
    </template>
    <div>
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'OutlinedCard',
  props: {
    containerClass: { type: String, default: '' },
    title: { type: String, default: null },
    subtitle: String,
    switcher: Boolean,
    switchValue: Boolean,
    removeShadow: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    removeBorderTop: { type: Boolean, default: false },
    removeBorder: { type: Boolean, default: false },
    switchLabel: String,
    actions: { type: Boolean, default: false },
    shadow:{type : Boolean, default: true}
  },
}
</script>

<style lang="sass">
  .OutlinedCard
    width: 100%
    border: 1px solid #BDBDBD !important
    border-radius: 10px !important
    &.custom-shadow
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13)
    .v-input--switch
      .v-input__slot
        margin: 0
      .v-messages
        display: none
    &.remove-shadow
      box-shadow: none !important
    &.remove-border-top
      border-top: 0 !important
      border-radius: 0 0 10px 10px !important
    &.remove-border
      border: 0 !important
</style>
