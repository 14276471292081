import Careers from '../Careers';
import CareersCreate from '../Views/CareersCreate';
import CareersUpdate from '../Views/CareersUpdate';
import CareersTable from '../Views/CareersTable';

const CareersRoute =
    {
        path: '/careers',
        meta: {
            name: {
                en: 'Careers',
                es: 'Carreras'
            }
        },
        component: Careers,
        children:[
            {
                path:'',
                component: CareersTable,
                meta: { name: 'Index', group: 'portaladministrativo.academic', path: 'careers', permission: 'READ' }
            },
            {
                path:'create',
                component: CareersCreate,
                meta: { name: 'Create Career', group: 'portaladministrativo.academic', path: 'careers', permission: 'CREATE' }
            },
            {
                path: 'update/:id',
                component: CareersUpdate,
                meta: { name: 'Update Career', group: 'portaladministrativo.academic', path: 'careers', permission: 'READ' }
            },
        ]
    };

export default CareersRoute
