const applicationsTranslations = {
  es: {
    breadcrumbs: {
      title: 'Aplicaciones'
    },
  },
  en: {
    breadcrumbs: {
      title: 'Aplications'
    },
  }
};

export default applicationsTranslations
