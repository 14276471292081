<template>
  <div>
    <v-container fluid class="pa-0 pb-8">
      <v-container class="container-custom" v-if="!createNewRolStatus">
        <Breadcrumbs
          :title="$t('modules.roles.update.breadcrumbs.title')"
          :description="$t('modules.roles.update.breadcrumbs.description')"
        ></Breadcrumbs>
        <template>
          <v-row justify="center">
            <v-col class="viewSpaces" sm="12">
              <RolesStepperForm :form-data="rol" :rolTypeOptions="rolTypeOptions" class="mb-6"></RolesStepperForm>
              <RolesStepperPermissions
                :applications="applications"
                class="mb-6"
                @fetchModules="fetchData"
                :forceRerender="forceRerender"
              ></RolesStepperPermissions>

              <div class="d-flex justify-end">
                <Button
                  white
                  :text="$t('actions.cancel')"
                  @clicked="$router.replace('/roles')"
                  class="mr-4"
                ></Button>

                <Button
                  :loading="saveButton.loading"
                  :success="saveButton.success"
                  :error="saveButton.error"
                  :text="$t('actions.save')"
                  :successText="$t('actions.saved')"
                  :errorText="'Error'"
                  :disabled="!canUpdateRol || loading"
                  @clicked="updateRol"
                  @end="resetButtonValues()"
                ></Button>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
      <SuccessMessage
        v-else
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
      />
    </v-container>
  </div>
</template>

<script>
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import RolesStepperPermissions from '../Components/RolesStepperPermissions/RolesStepperPermissions';
import RolesStepperForm from '../Components/RolesStepperForm/RolesStepperForm';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button'
import { $types, $roles } from '../Services';

export default {
  components: {
    RolesStepperForm,
    RolesStepperPermissions,
    Breadcrumbs,
    SuccessMessage,
    Button
  },
  data () {
    return {
      rol: {
        name: '',
        description: '',
        applications: [],
        rolType: {}
      },
      rolTypeOptions: [],
      applications: [],
      createNewRolStatus: null,
      saveButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        title: this.$t('modules.roles.update.messages.updateSuccess'),
        actionPrimary: { text: 'Aceptar', callback: () => { this.$router.push('/roles') } }
      },
      forceRerender: 0,
      loading: false,
    }
  },
  computed: {
    canUpdateRol () {
      return this.canUpdate && this.rol.name && this.rol.name.length;
    },
    canUpdate () {
      return this.$permissions.portaladministrativo.roles.UPDATE
    },
  },
  methods: {
    filterChildren (child) {
      if (child.status) {
        if (child.children) child.children = child.children.filter(this.filterChildren)
        if (child.permissions) child.permissions = child.permissions.filter(perm => perm.name == 'READ' ? true : perm.status)
      }
      return child.status;
    },
    cleanUselessData (child) {
      if (child.children) {
        child.children = child.children.map(this.cleanUselessData)
      }
      child.permissions = child.permissions.map(perm => perm.name === 'READ' ? ({ id: perm.id, status: true }) : ({ id: perm.id, status: perm.status }))
      return { id: child.id, children: child.children, permissions: child.permissions };
    },
    updateRol () {
      if (!this.canUpdate) return false;
      let applications = JSON.parse(JSON.stringify(this.applications)); // eslint-disable-line no-unused-vars

      //FILTRA APLICACIONES DESHABILITADAS
      if (applications) applications = applications.filter((app) => app.status);

      //FILTRA MODULOS DESHABILITADOS
      applications = applications.map(app => {
        if (app.modules) app.modules = app.modules.filter(module => module.status);
        return app;
      });

      //RECURSIVA SOBRE CHILDS
      applications = applications.map(app => {
        if (app.modules) app.modules = app.modules.filter(this.filterChildren)
        return app;
      })

      //CLEAN USELESS DATA
      applications = applications.map(app => {
        app.modules = app.modules.map(this.cleanUselessData)
        return { id: app.id, modules: app.modules }
      })

      this.saveButton.loading = true;
      const {id} = this.$route.params;
      this.$users.updateRole({ id, ...this.rol, applications })
        .then(() => {
          this.$store.dispatch('commons/initData')
            .then(() => {
              this.saveButton.loading = false;
              this.saveButton.success = true;
              this.createNewRolStatus = true;
            })
            .catch(() => {
              this.saveButton.error = false;
              this.createNewRolStatus = false;
            })
        })
        .catch(() => {
          this.saveButton.error = false;
          this.createNewRolStatus = false;
        })
    },
    async fetchData () {
      try {
        this.loading = true
        const role = await $roles.findById(this.$route.params.id);
        const rolesTypes = await $types.find(null, null, { params: { type: 'ROLS_TYPE' } })
        this.rolTypeOptions = rolesTypes.data
        this.rol = { name: role.data.name, description: role.data.description, rolType: role.data.rolType };
        let res = await $roles.findById(this.$route.params.id, 'applications-permissions')
        res = res.data
        const applications = []
        for(let i = 0; i < res.length; ++i){
            const application = res[i];
            const modules =  await $roles.find('module-permissions', null, { params: {
                                    idRol: this.$route.params.id,
                                    idApp: application.id
                                  }})
            application.modules = modules.data
            applications.push(application)
        }
        // THE GAME
        this.applications = applications;
      } catch {
        this.applications = []
      } finally {
        this.loading = false
      }
    },
    resetSaveButtonValues () {
      this.saveButton.loading = false;
      this.saveButton.success = false;
      this.saveButton.error = false;
    },
  },
  mounted () {
    this.fetchData()
  }
}
</script>
