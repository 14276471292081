<template>
  <div>
    <v-row>
      <v-col sm=6 class="d-flex justify-start">
        <h1 class="headline primary--text">{{ $t('components.multipleProductsCosts.diferencia_costos')  }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm=3 class="d-flex justify-start pl-10">
        <h6 class="table-header">{{ $t('components.multipleProductsCosts.concepto') }}</h6>
      </v-col>
      <v-col sm=3 class="d-flex justify-center">
        <h6 class="table-header">{{ $t('components.multipleProductsCosts.carrera_nueva') }}</h6>
      </v-col>
      <v-col sm=3 class="d-flex justify-center">
        <h6 class="table-header">{{ $t('components.multipleProductsCosts.nueva_carrera') }}</h6>
      </v-col>
      <v-col sm=3 class="d-flex justify-center">
        <h6 class="table-header">{{ $t('components.multipleProductsCosts.diferencia') }}</h6>
      </v-col>
    </v-row>
    <div v-for="(item, i) in items" :key="i" class="mb-3 table-data-row">
      <v-card class="mx-auto cards-outlined" outlined>
        <v-row>
          <v-col class="d-flex justify-start" sm=3>
            <div class="mx-auto ml-6">
              <div class="text-cell">{{ item.name }}</div>
            </div>
          </v-col>
          <v-col class="d-flex justify-center" sm=3>
            <div class="mx-auto">
              <div class="text-cell">{{ item.oldAmount | $currency }}</div>
            </div>
          </v-col>
          <v-col class="d-flex justify-center" sm=3>
            <div class="mx-auto">
              <div class="text-cell">{{ item.newAmount | $currency }}</div>
            </div>
          </v-col>
          <v-col sm=3 class="d-flex justify-center">
            <div class="mx-auto">
              <div class="text-cell" :style="differences[i] >= 0 ? 'color: #274280' : 'color: #fe5858'">{{ differences[i] | $currency }}</div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-row class="ml-2s">
      <v-col sm=6 class="d-flex justify-start">
        <h4 v-if="finalCost < 0">{{ $t('components.multipleProductsCosts.creditNote', {difference: $options.filters.$currency(Math.abs(finalCost))}) }}</h4>
        <h4 v-else-if="finalCost > 0">{{ $t(`components.multipleProductsCosts.${$isPortalAdministrativo ? 'difference' : 'alumno_difference'}`, {difference: $options.filters.$currency(finalCost)}) }}</h4>
        <h4 v-else>{{ $t(`components.multipleProductsCosts.${$isPortalAdministrativo ? 'noDifference' : 'alumno_noDifference'}`) }}</h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MultipleProductsCosts',
  props: {
    items: [Array, Object],
  },
  data () {
    return {
      finalCost: 0,
    }
  },
  mounted(){
    this.calculateFinalCost()
  },
  computed: {
    differences() {
      return this.items ? this.items.map(value => value.newAmount - value.oldAmount) : []
    }
  },
  methods: {
    calculateFinalCost() {
      let total = 0
      this.items.forEach(item => {
        total += item.newAmount - item.oldAmount
      })
      this.finalCost = total
    }
  }
}
</script>

<style scoped lang="sass">
  .table-header
    font-size: 14px
    text-transform: uppercase

  .table-data-row
    border-radius: 5px

  .cards-outlined
    background-color: #f4f3f7
    height: 3rem

  .text-cell
    font-size: .9rem
    font-weight: 600
    color: #000b3b
</style>
