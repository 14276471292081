import { render, staticRenderFns } from "./LiquidationsTable.vue?vue&type=template&id=03f016d0&"
import script from "./LiquidationsTable.vue?vue&type=script&lang=js&"
export * from "./LiquidationsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports