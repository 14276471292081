
const degreesTranslations = {
  es: {
    table: {
      breadcrumbs: {
        title: 'Gestión de títulos',
        description: 'Administración de títulos'
      },
      headers: {
        name: 'Nombre',
        type: 'Tipo',
      },
      delete_error: 'No se pudo eliminar el título seleccionado',
    },
    create: {
      breadcrumbs: {
        title: 'Nuevo título',
        description: 'Crea nuevo título'
      },
      title: 'Título académico',
      success_message: {
        title: 'Título creado correctamente',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
        },
      },
      error_message: {
        title: 'Se produjo un error al crear el título',
      },
      labels: {
        name: 'Nombre',
        female_switch: 'Lleva nombre femenino',
        female_name: 'Nombre femenino',
        type: 'Tipo de título',
      },
      validation_errors: {
        name: 'Nombre del título es requerido',
        female_name: 'Nombre femenino es requerido',
        type: 'Tipo de título es requerido',
      }
    },
    update: {
      breadcrumbs: {
        title: 'Edición de título',
        description: 'Modifica las características del título'
      },
      title: 'Título académico',
      success_message: {
        title: 'Título modificado correctamente',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
        },
      },
      error_message: {
        title: 'Se produjo un error al modificar el título',
        actions: {
          primary_text: 'Volver a intentar',
          secondary_text: 'Cerrar',
        },
      },
      labels: {
        name: 'Nombre',
        female_name: 'Nombre femenino',
        type: 'Tipo de título',
      },
      validation_errors: {
        name: 'Nombre del título es requerido',
        type: 'Tipo de título requerido',
      }
    },
  },
  en: {

  }
};

export default degreesTranslations
