
const creditCardInterestTranslations = {
    es: {
        table:{
            breadcrumbs:{
                title:'Gestión de intereses',
                subtitle:'Administración de intereses por cuotas',
            },
            headers:{
                code:'Código',
                installments:'Cuotas',
                percentage:'Porcentaje',
                to: 'Hasta',
                status: 'Estado',
                from: 'Desde'
            },
            errorMessage:{
                bulkDeleteError:'No se pudieron eliminar los intereses por cuotas seleccionados',
                deleteError:'Hubo un error al eliminar el interés', 
                
            },
            message: {
                bulkDelete: 'Eliminar interés por cuota',
            }
        },
        create:{
            validations:{
                percentageRequired: 'El porcentaje es requerido',
                teaRequired: 'El TEA es requerido',
                cftRequired: 'El CFT es requerido',
                installments:{
                    required:'Las cantidades de cuotas son obligatorias y debe ser mayor a 0'
                },
                noSelectedCareers: 'Debes seleccionar al menos una carrera',
                noSelectedTerms: 'Debes seleccionar al menos un período',
                studentType: 'Debe seleccionar al menos un tipo de estudiante',
                codeRequired: 'El código es un campo requerido',
                codeAlphanumeric: 'Solo alfanuméricos',
                codeMaxLength: 'Máximo 10 caracteres',
                codeUnique: 'Código ya existente',
                datePair: 'Fecha inválida',
                startDateRequired: 'Ingresa una fecha de inicio',
                maxInstallments: 'El máximo de cuotas son 100',
                maxValue: 'El valor máximo es de 1000 ',
                decimal: 'Solo números enteros o decimales hasta con dos cifras',
                numeric: 'Solo números enteros',
                minValue: 'Debe ingresar un valor mayor a 0'
            },
            breadcrumbs:{
                title:'Nuevo interés',
                subtitle:'Crea un nuevo interés',
            },
            installmentsSection:{
                title:'Datos de los intereses por cuotas',
                subtitle:'Asigna la información básica del interés por cantidad de cuotas que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                installments: 'Cantidad de cuotas',
                percentage: 'Porcentaje',
                code: 'Código',
                tea: 'TEA',
                cft: 'CFT',
                isBank: 'Aplica a cuotas con interés',
                preRegistered:'Preinscrito',
                reEntrant: 'Reingresante',
                endDate: 'Hasta',
                startDate: 'Desde *'
            },
            careersSection:{
                title: 'Carreras',
                subtitle:'Selecciona las carreras a las que quiere asignarles el interés por cuota',
            },
            transferList:{
                firstTitle:'Disponibles',
                secondTitle:'Seleccionados',
            },
            studentType: 'Tipo de estudiante',
            sucessMessage: {
                title:'El interés por cuota creada correctamente'
            },
            errorMessage: {
                title:'El interés por cuota no fue creada correctamente',
                entityExists:'El registro ya existe'
            },
        },
        update:{
            validations:{
                percentageRequired: 'El porcentaje es requerido',
                teaRequired: 'El TEA es requerido',
                cftRequired: 'El CFT es requerido',
                installments:{
                    required:'Las cantidades de cuotas son obligatorias y debe ser mayor a 0'
                },
                noSelectedCareers: 'Debes seleccionar al menos una carrera',
                noSelectedTerms: 'Debes seleccionar al menos un período',
                studentType: 'Debe seleccionar al menos un tipo de estudiante',
                codeRequired: 'El código es un campo requerido',
                codeAlphanumeric: 'Solo alfanuméricos',
                codeMaxLength: 'Máximo 10 caracteres',
                codeUnique: 'Código ya existente',
                startDateRequired: 'Ingresa una fecha de inicio',
                datePair: 'Fecha inválida',
                maxInstallments: 'El máximo de cuotas es de 100',
                maxValue: 'El valor máximo es de 1000',
                decimal: 'Solo números enteros o decimales hasta con dos cifras',
                numeric: 'Solo números enteros',
            },
            installmentsSection:{
                title:'Datos de los intereses por cuotas',
                subtitle:'Asigna la información básica del interés por cantidad de cuotas que estás creando. Recuerda que estos datos deben ser lo más descriptivos posible ya que serán utilizados para futuros filtros de búsqueda.',
                installments: 'Cantidad de cuotas',
                percentage: 'Porcentaje',
                code: 'Código',
                tea: 'TEA',
                cft: 'CFT',
                isBank: 'Aplica a cuotas con interés',
            },
            breadcrumbs:{
                title:'Editar interés',
                subtitle:'Modifica el interés por cuota seleccionado',
            },
            careersSection:{
                title: 'Carreras',
                subtitle:'Selecciona las carreras a las que quiere asignarles el interés por cuota',
            },
            transferList:{
                firstTitle:'Disponibles',
                secondTitle:'Seleccionados',
            },
            studentType: 'Tipo de estudiante',
            sucessMessage: {
                title:'El interés por cuota fue editada correctamente'
            },
            errorMessage: {
                title:'El interés por cuota no fue editada correctamente'
            },
        }
    },
    en: {

    }
};

export default creditCardInterestTranslations