<template>
    <v-navigation-drawer
        v-model="drawer"
        :absolute="$isMobile"
        :temporary="$isMobile"
        :permanent="!$isMobile"
        :expand-on-hover="!$isMobile"
        color="primary"
        dark
        :width="$isMobile ? '100%' : '28%'"
        :style="$isMobile ? 'height: calc(100vh - 64px) !important; top: 64px !important;' : ''"
        :class="['NavigationDrawer', 'pointerIconMenu']"
        height="auto"
        :hide-overlay="$isMobile"

    >
        <div :class="[openSubmenu.value ? 'smoothSlideIn'  : 'smoothSlideOut', 'fullHeight', 'scrollSubMenu']">
            <v-list-item
                class="px-6 menuHamburguer" :disabled="!openSubmenu.value" inactive
                @click="openSubmenu.value ? openSubmenu.value = !openSubmenu.value : ''"
                :class="[openSubmenu.value ? 'cursor-pointer' : '']">
                <v-icon icon>{{ (!openSubmenu.value) ? 'mdi-menu' : 'mdi-chevron-left' }}</v-icon>
                <v-list-item-title class="pl-8 font-weight-bold body-2">{{ (!openSubmenu.value) ? 'Menú principal' : 'Volver' }}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>
            <v-list dense class="pa-0 fullHeight">
                <div v-if="!openSubmenu.value" class="fullHeight" style="height: calc(100vh - 64px);">
                    <v-list-item-group :mandatory="$route.path !== '/profile'" max="1" v-model="selected" class="fullHeight d-flex flex-column">
                        <v-list-item v-for="(item, i) in activeModules" :key="i" :value="i" @click="linkClicked(item)"
                            :inactive="(item.path === 'help') || item.spacer" :disabled="$route.path === item.path || item.spacer"
                            :input-value="selected === i" :class="[item.spacer ? 'flex-grow-1' : 'v-list-item--link']">
                            <v-list-item-icon>
                                <v-icon v-if="item.imagePath">{{ item.imagePath }}</v-icon>
                                <v-icon v-else>mdi-heart</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title :class="[selected !== i ? 'font-weight-light' : '', 'body-2 d-flex justify-space-between align-center']">
                                    {{ item.name }}
                                    <v-icon v-if="(item.children && item.children.length > 0)" @click.prevent="">mdi-chevron-right</v-icon>
                                </v-list-item-title>
                            </v-list-item-content>
                            <HelpMenu v-if="item.path === 'help'" :showMenu="showMenu"></HelpMenu>
                        </v-list-item>
                    </v-list-item-group>
                </div>
                <div v-else style="height: calc(100vh - 64px);">
                    <v-list-item-group  max="1" :value="subSelectedValue(modules.indexOf(modules[openSubmenu.parentIndex]), subSelected.parentIndex, subSelected.value)" class="d-flex flex-column justify-space-between">
                        <div class="d-flex px-6" style="opacity: 0.5;">
                            <v-list-item-icon>
                                <v-icon v-if="modules[openSubmenu.parentIndex].imagePath">{{ modules[openSubmenu.parentIndex].imagePath }}</v-icon>
                                <v-icon v-else>mdi-heart</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="align-self-center">
                                <v-list-item-title class="font-weight-light body-1">
                                    {{ modules[openSubmenu.parentIndex].name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </div>
                        <v-divider></v-divider>
                        <v-list-item v-for="(subitem, i) in activeSubModules" :key="i" @click="subLinkClicked(subitem, i)" :disabled="$route.path === subitem.path">
                            <v-list-item-icon class="justify-center">
                                <v-icon v-if="subitem.imagePath">{{ subitem.imagePath }}</v-icon>
                                <v-icon v-else>mdi-heart</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content class="py-3">
                                <v-list-item-title :class="[subSelected !== i ? 'font-weight-light' : '', 'body-2 d-flex justify-space-between align-center']">
                                    {{ subitem.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>


<script>
    import HelpMenu from '../HelpMenu/HelpMenu'
    import { mapGetters } from 'vuex'

    export default {
        name: 'NavigationDrawer',
        props: {
            drawerOpen: {type: Boolean, default: false},
        },
        components: {
            HelpMenu,
        },
        data () {
            return {
                drawer: true,
                openSubmenu: {
                    value: false,
                    parentIndex: null,
                },
                modules: [],
                selected: 0,
                prevSelected: null,
                subSelected: {
                    value: null,
                    parentIndex: null
                },
                showMenu: false,
            }
        },
        methods: {
            async linkClicked (item) {
                const index = this.modules.indexOf(item);
                this.showMenu = false;

                if (item.children && item.children.length) {
                    setTimeout(() => {
                        this.selected = this.prevSelected;
                    }, 1);

                    this.openSubmenu.parentIndex = index;
                    this.openSubmenu.value = true;
                } else if (item.path === 'help') {
                    this.showMenu = true;
                    setTimeout(() => {
                        this.selected = this.prevSelected;
                    }, 500);
                } else if (item.code == 'portalalumno-canvas' || item.code == 'portalalumno-edusoft' || item.code == 'portalalumno-elibro') {
                    this.redirect(item.code)
                } else {
                    if (this.$route.path !== item.path) {
                        this.subSelected.value = this.subSelected.parentIndex = null;
                        this.selected = this.prevSelected = index;
                        this.$router.push(item.path)
                    }
                }
            },
            async subLinkClicked (subitem, i) {
                this.showMenu = false;

                if (subitem.code == 'portalalumno-canvas' || subitem.code == 'portalalumno-edusoft' || subitem.code == 'portalalumno-elibro') {
                    this.redirect(subitem.code)
                } else if (this.$route.path !== subitem.path) {
                    this.subSelected.value = i;
                    this.subSelected.parentIndex = this.openSubmenu.parentIndex;
                    this.selected = this.openSubmenu.parentIndex;
                    this.$router.push(subitem.path);
                }
            },
            async redirect(code) {
                // EduSoft ---> https://ed.engdis.com/ipp
                // E-Libro ---> https://elibro.net/es/lc/ipp/inicio
                // Canvas sin ID ---> https://ipp.instructure.com/courses/4506
                // Canvas con ID ---> clickToCanvas()
                if (!this.studentData.student) return false

                let uri = null
                if (code == 'portalalumno-edusoft') uri = 'https://ed.engdis.com/ipp'
                else if (code == 'portalalumno-elibro') uri = 'https://elibro.net/es/lc/ipp/inicio'
                else if (code == 'portalalumno-canvas') {
                  this.$redirectToCanvas(this.canvasId, this.studentData.student.user.name)
                }

                if (uri) {
                    const win = window.open(uri, '_blank')
                    if (!win) {
                        // Mostrar mensaje de bloqueo
                    }
                }
            },
            setCurrentSelected (status) {
                let someSelected = false;
                this.modules.forEach((item, i) => {
                    if (item.children && item.children.length > 0) {
                        const subitemSelected = item.children.find(children => {
                            return (children.path === this.$route.path || this.$route.matched.some(route => route.path === children.path));
                        });
                        if (subitemSelected) {
                            const index = this.modules.indexOf(item);
                            const subIndex = this.modules[index].children.indexOf(subitemSelected);

                            this.openSubmenu.parentIndex = this.selected = this.prevSelected = index;
                            this.subSelected.value = subIndex;
                            this.subSelected.parentIndex = this.openSubmenu.parentIndex;
                            someSelected = true;
                            if (status === 'init') this.openSubmenu.value = true;
                        }
                    } else {
                        if (item.path === this.$route.path || this.$route.matched.some(route => route.path === item.path)) {
                            this.selected = this.prevSelected = i;
                            this.subSelected.value = this.subSelected.parentIndex = null;
                            someSelected = true;
                            if (status === 'init') this.openSubmenu.value = false;
                        }
                    }
                    if(!someSelected) {
                        this.openSubmenu.value = this.openSubmenu.parentIndex = false;
                        this.selected = this.prevSelected = this.subSelected.value = this.subSelected.parentIndex = null;
                    }
                });
            },
            subSelectedValue (index, parentIndex, subSelected) {
                if(index === parentIndex) return subSelected
                else return undefined;
            },
            orderModules() {
                const orderSubModules = (item) => {
                    if (item.children) item.children = orderSubModules(item.children)
                    item.sort((itemA, itemB) => itemA.order - itemB.order)
                    return item
                }
                if (this.apiModules && this.apiModules.length > 0) {
                    // SORT modules and subModules order
                    this.modules = this.apiModules.sort((itemA, itemB) => itemA.order - itemB.order);
                    this.modules.forEach(item => {
                        if (item.children) item.children = orderSubModules(item.children)
                    });
                }
                
                if (this.$isPortalAdministrativo) this.modules.unshift({ name: 'Inicio', imagePath: 'mdi-home', path: '/', status: true });
                this.modules.push({ spacer: true, imagePath: ' ', status: true });
                if (this.$isPortalAdministrativo || this.$isPortalComisiones) this.modules.push({ name: 'Ayuda', imagePath: 'mdi-help-circle', path: 'help', status: true });
                if (this.$permissions.settings.READ) this.modules.push({ name: 'Configuración', imagePath: 'mdi-settings', path: '/settings/', status: true });
            },
        },
        watch:{
            drawerOpen() {
                if (this.$isMobile) this.drawer = !this.drawer
            },
            $isMobile(val) {
                if (val) this.drawer = false
            },
            $route() {
                this.setCurrentSelected('init');
            },
            permissions() {
                this.orderModules()
                this.setCurrentSelected('init');
            }
        },
        computed: {
            activeModules() {
                return this.modules.filter(module => module.status == true)
            },
            activeSubModules() {
                return this.modules[this.openSubmenu.parentIndex].children.filter(module => module.status == true)
            },
            ...mapGetters({
                apiModules: 'commons/getApplicationModules',
                permissions: 'commons/getPermissions',
                canvasId: 'commons/getCanvasId',
                studentData: 'commons/getStudentData',
            }),
        },
        created () {
            if (this.$isMobile) this.drawer = false
            if(this.permissions && this.permissions.length) {
                this.setCurrentSelected('init');
            }
        },
    }
</script>

<style lang="sass">
    .scrollSubMenu
        overflow-y: auto
        overflow-x: hidden
        height: 100%

    .pointerIconMenu
        .v-main__wrap
            height: inherit
            & > div, & > div > div
              height: inherit

    .smoothSlideIn
        animation-fill-mode: forwards
        animation-name: smoothSlideIn
        animation-duration: 0.4s
        animation-timing-function: ease-in-out
    .smoothSlideOut
        animation-fill-mode: forwards
        animation-name: smoothSlideOut
        animation-duration: 0.4s
        animation-timing-function: ease-in-out


    @keyframes smoothSlideIn
        0%
            opacity: 0
            transform: translateX(100%)
        15%
            opacity: 0
        50%
            transform: translateX(0)
            opacity: 1
        100%
            transform: translateX(0)
            opacity: 1

    @keyframes smoothSlideOut
        0%
            opacity: 0
            transform: translateX(-100%)
        15%
            opacity: 0
        50%
            transform: translateX(0)
            opacity: 1
        100%
            transform: translateX(0)
            opacity: 1

    .NavigationDrawer
        min-width: 72px
        
        // mini-variant
        &.v-navigation-drawer--mini-variant
            transition-duration: 0.3s !important
            transition-timing-function: ease-out !important
            .v-item, .v-list-item--link
                padding: 3px 24px
                flex: 0
                justify-content: start
                &--active
                    transition: opacity 500ms ease
                    &:hover, &:active, &:focus
                        transition: opacity 500ms ease
                        &:before
                            transition: opacity 500ms ease
                            opacity: 1 !important
                    &:before
                        transition: opacity 500ms ease
                        opacity: 1
                    .v-list-item__icon .v-icon
                        color: var(--v-primary-base)
                &:before
                    z-index: -1
                    transition: opacity 0s !important
                    width: 40px
                    height: 40px
                    left: 16px
                    top: 3px
                    bottom: 3px
                    border-radius: 50%

        /*&:hover, &:active, &:focus
            &:before
                width: 40px
                height: 40px
                opacity: 0.4
                left: 50%
                top: 50%
                transform: translate(-50%, -50%)
                border-radius: 50%
                transition: opacity 500ms ease*/
        // open
        &:not(.v-navigation-drawer--mini-variant)
            transition-duration: 0.3s !important
            transition-timing-function: ease-out !important
            .v-list-item, .v-list-item--link
                padding: 3px 24px
                flex: 0
                &--active
                    background: #FFFFFF
                    color: var(--v-primary-base)
                    border-right: 1px solid #ddd
                    &:hover, &:active, &:focus
                        &:before
                            opacity: 1 !important
                    &:before
                        opacity: 1
                    .v-list-item__icon .v-icon
                        color: var(--v-primary-base)
                &:before
                    z-index: -1
                    transition: opacity 500ms ease
                &:hover, &:active, &:focus
                    &:before
                        opacity: 0.5
        .menuHamburguer
            height: 63px
        .v-list-item__content
            .v-list-item__title
                .v-icon
                    position: absolute
                    right: 6px
        .filter-white
            filter: invert(1) brightness(2) saturate(0)
</style>
