<template>
    <v-container class="communications d-flex flex-column pa-0" fluid>
        <Breadcrumbs
            class="ma-2"
            style="flex: 0"
            :title="$isMobile && messageSelected ? $t('actions.return') : $t('modules.portalalumno.communications.breadcrumbs.title')"
            :callback="$isMobile && messageSelected ? goBack : null"
            hide-breadcrumb
        />
        <v-divider></v-divider>
        <v-row class="communications__page mx-0" style="flex: 1">
            <v-col v-show="!$isMobile || !messageSelected" cols="12" sm="5" md="4" lg="3" class="pa-0">
                <Asidebar @selectedMessage="selectedMessage"></Asidebar>
            </v-col>
            <v-col v-if="!$isMobile || messageSelected" cols="12" sm="7" md="8" lg="9" class="communications__detail pa-0">
                <Detail :messageSelected="messageSelected"></Detail>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Asidebar from '../Components/Asidebar'
    import Detail from '../Components/Detail'
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';

    export default {
        name: 'CommunicationsView',
        components:{
            Asidebar,
            Detail,
            Breadcrumbs,
        },
        data() {
            return {
                messages: [],
                messageSelected: null,
                isEmpty: true
            }
        },
        methods: {
            selectedMessage(message) {
                this.messageSelected = message
                this.$store.dispatch('commons/refreshNotifications')
            },
            goBack() {
                this.messageSelected = null
            },
        },
    }
</script>

<style lang="sass" scoped>

    .communications
        height: calc(100vh - 64px)

        &__page
            height: calc(100% - 73px) !important

        &__detail
            height: 100%
            background: #f5f5f5

</style>