<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <AsideBar :items="asidebar.items" :title="asidebar.title" @changed="changed"></AsideBar>
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.commissions.ranges.table.breadcrumbs.title')"
                    :description="$t('modules.commissions.ranges.table.breadcrumbs.description')"
                >
                    <Button
                        v-if="canCreate"
                        @clicked="newRange"
                        :icon="'mdi-plus'"
                        :text="$t('actions.new')"
                        depressed
                    ></Button>
                </Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="ranges"
                            :clear-selected="clearSelected"
                            :page.sync="page"
                            :itemsPerPage.sync="itemsPerPage"
                            :totalElements="totalItems"
                            :deleteTitle="$t('modules.commissions.ranges.table.actions.bulk_delete')"
                            :permissions="{update: canUpdate, delete: canDelete}"
                            :sortBy.sync="sort.sortBy"
                            :sortDesc.sync="sort.sortDesc"
                            @bulk-delete="bulkDeletePopUp"
                            @deleteData="deletePopUp"
                            @updateData="updateRange"
                            @searchAction="searchAction"
                            @input-pagination="onInputPagination"
                            @length-pagination-change="onLengthPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable'
    import AsideBar from '@/components/AsideBar/AsideBar';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs'
    import Button from '@/components/Button/Button';
    import { $range, $terms } from '../Services';
    import moment from 'moment';

    export default {
        name: 'RangesTable',
        components: {
            SuperTable,
            Breadcrumbs,
            Button,
            AsideBar,
        },
        data() {
            return {
                loading: false,
                page: 1,
                itemsPerPage: 20,
                totalItems: null,
                clearSelected: null,
                search: '',
                ranges: [],
                sort: {
                    sortBy: 'startDate',
                    sortDesc: true,
                },
                headers: [
                    {
                        text: this.$t('modules.commissions.ranges.table.headers.id'),
                        value: 'id',
                        sortable: false,
                    },{
                        text: this.$t('modules.commissions.ranges.table.headers.term'),
                        value: 'termCode',
                        align: 'center',
                        sortable: false,
                    },{
                        text: this.$t('modules.commissions.ranges.table.headers.description'),
                        value: 'description',
                    },{
                        text: this.$t('modules.commissions.ranges.table.headers.range'),
                        value: 'rangeNumber',
                        align: 'center',
                        sortable: false,
                    },{
                        text: this.$t('modules.commissions.ranges.table.headers.start_date'),
                        value: 'startDate',
                        align: 'center',
                    },{
                        text: this.$t('modules.commissions.ranges.table.headers.end_date'),
                        value: 'endDate',
                        align: 'center',
                    },{
                        text: '',
                        value: 'action',
                        sortable: false,
                    }
                ],
                asidebar: {
                    title: this.$t('modules.commissions.ranges.table.filters.title'),
                    pagination: {
                        limit: 20,
                        page: 1,
                        pagesAmount: 1
                    },
                    items: [
                        {
                            name: this.$t('modules.commissions.ranges.table.filters.term'),
                            filters: [
                                {
                                    name: this.$t('modules.commissions.ranges.table.filters.term'),
                                    type: 'autocomplete',
                                    value: null,
                                    multiple: true,
                                    clearable: true,
                                    items: [],
                                }
                            ]
                        },{
                            name: this.$t('modules.commissions.ranges.table.filters.date'),
                            filters: [
                                {
                                    name: this.$t('modules.commissions.ranges.table.filters.from'),
                                    type: 'date',
                                    value: null,
                                    isCalendarOpen: false,
                                },{
                                    name: this.$t('modules.commissions.ranges.table.filters.to'),
                                    type: 'date',
                                    value: null,
                                    isCalendarOpen: false,
                                },
                            ]
                        }
                    ]
                },
            }
        },
        mounted() {
            this.getTerms()
            this.fetchData();
        },
        computed: {
            canCreate() {
                return this.$permissions.portaladministrativo.commissions.ranges.CREATE
            },
            canUpdate() {
                return this.$permissions.portaladministrativo.commissions.ranges.READ || this.$permissions.portaladministrativo.commissions.ranges.UPDATE
            },
            canDelete() {
                return this.$permissions.portaladministrativo.commissions.ranges.DELETE
            },
        },
        methods: {
            deletePopUp(id){
                const range = this.ranges.find(range => range.id == id);
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: [{value: range.description}],
                    actionPrimary: {text: this.$t('actions.delete'), callback: () => this.deleteRange(id)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async fetchData() {
                if (this.loading) return false
                this.clearSelected = new Date().getMilliseconds().toString();
                this.loading = true
                try {

                    const params = {
                        page: this.page - 1,
                        length: this.itemsPerPage,
                        orderBy: this.sort.sortBy,
                        orientation: this.sort.sortDesc ? 'desc' : 'asc',
                    }

                    const filterParams = {
                        term: this.asidebar.items[0].filters[0].value 
                        ? this.asidebar.items[0].filters[0].value : [],
                        from: this.asidebar.items[1].filters[0].value,
                        to: this.asidebar.items[1].filters[1].value,
                        search: this.search,
                    }
                    
                    const ranges = await $range.filter(filterParams, { params })

                    // console.log(ranges.data)

                    this.ranges = ranges.data.content.map(range => {
                        range.termCode = range.term?.observations
                        range.startDate = range.startDate ? moment(range.startDate).format('DD/MM/YYYY') : '-'
                        range.endDate = range.endDate ? moment(range.endDate).format('DD/MM/YYYY') : '-'
                        return range;
                    });
                    this.totalItems = ranges?.data?.totalElements;
                } catch (error) {
                    this.ranges = []
                    this.totalItems = 0
                    throw error
                } finally {
                    this.loading = false
                }
            },
            async getTerms() {
                const terms = await $terms.find(null, null, {params: {page: 0, length: 20, orderBy: 'saleDateFrom', orientation: 'desc'}});
                this.asidebar.items[0].filters[0].items = terms.data.content.map(item => {
                    item.startDate = moment(item.saleDateFrom).format('DD/MM')
                    item.endDate = moment(item.saleDateTo).format('DD/MM')
                    return {
                        value: item.id,
                        text: item.code,
                        description: `${this.$t('modules.commissions.ranges.table.from')} ${item.startDate} ${this.$t('modules.commissions.ranges.table.to')} ${item.endDate}`
                    }
                });
            },
            bulkDeletePopUp(ranges) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('delete_popup_title'),
                    content: ranges.map(range => ({ value: range.description })),
                    actionPrimary: {text: this.$t('actions.accept'), callback: () => this.bulkDelete(ranges)},
                    actionSecondary: {text: this.$t('actions.cancel'), callback() {}},
                    icon: {color: 'error', name: 'mdi-delete'},
                    color: 'primary',
                })
            },
            async bulkDelete (ranges) {
                const itemsForDelete = ranges.map((range) => {
                    return { id: range.id }
                });

                try {
                    await $range.bulkDelete(itemsForDelete)
                    // itemsForDelete.forEach(item => {
                    //     this.ranges = this.ranges.filter(range => range.id !== item.id);
                    // });
                    this.fetchData()

                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: 'Error',
                        content: [{value: this.$t('modules.commissions.ranges.table.messages.bulk_delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error;
                }
            },
            onInputPagination(event) {
                this.asidebar.pagination.page = event;
                this.fetchData();
            },
            onLengthPaginationChange(event) {
                this.asidebar.pagination = {
                    page: 1,
                    limit: event,
                    pagesAmount: 1
                };
                this.fetchData();
            },
            changed(index, i, value) {
                this.asidebar.items[index].filters[i].value = value
                this.asidebar.items[1].filters[0].maxDate = this.asidebar.items[1].filters[1].value
                this.asidebar.items[1].filters[1].minDate = this.asidebar.items[1].filters[0].value
                this.fetchData();
            },
            newRange() {
                if (this.canCreate) this.$router.push('/commissions/ranges/create')
            },
            updateRange(id) {
                if (this.canUpdate) this.$router.push(`/commissions/ranges/update/${id}`)
            },
            async deleteRange (id) {
                try {
                    await $range.delete(id);
                    this.ranges = this.ranges.filter(range => range.id !== id);
                } catch (error) {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('actions.error'),
                        content: [{value: (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.table.messages.delete_error')}],
                        actionPrimary: {text: this.$t('actions.accept'), callback() {}},
                        icon: {color: 'error', name: 'mdi-close'},
                        color: 'primary',
                    });
                    throw error
                }
            },
            searchAction(data) {
                this.search = data;
                this.fetchData()
            },
        },
        watch:{
            page() {
                this.fetchData()
            },
            itemsPerPage() {
                this.fetchData()
            },
            sort: {
                handler(){
                    this.fetchData();
                },
                deep: true
            }
        }
    }
</script>