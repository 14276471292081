/**
 * @param  {any} param
 */
export function almostOneSelected(value) {
  if (!value.length) {
    return false;
  }

  const [{ceo, ...others}] = value;
  const values = Object.values(others);

  if (!values.length) {
    return false;
  }

  return values.reduce((acc, value) => {
    if (!value && value !== 0) {
      return false;
    }

    if (typeof value === 'string' && /null/.test(value)) {
      return false;
    }

    return acc;
  }, true);
}
