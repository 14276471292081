<template>
  <div class="">
    <v-card >
      <v-list-item three-line>
        
        <v-list-item-avatar
          size="65"
          color="grey"
          rounded="1"
        >
            <img :src="avatar" alt="">
        </v-list-item-avatar>

        <v-list-item-content class="ml-1" v-if="!horizontal">
            <v-list-item-title class="mb-1 text-primary text-button">
                {{ name }}
            </v-list-item-title>
            <v-list-item-subtitle class="" style="">
              
                <div class="v-list-item__title d-flex">
                  <v-icon color="#0088cc" size="14" class="mr-1">mdi-school</v-icon>
                  <div class="truncated text-caption">{{ career }}</div>
                </div>

               
                <div v-if="from.length > 3" class="v-list-item__title d-flex">
                   <v-icon color="#0088cc" size="14">mdi-map-marker</v-icon>
                   <div class="text-caption"> {{ from }} </div>
                </div>
            </v-list-item-subtitle>
        </v-list-item-content>

        <div class="ml-1 d-flex flex-column flex-md-row " v-else>
          
          <v-list-item-title class="mb-1 mr-4 truncated" style="flex: 2 1 33%;">
              <span class=" text-caption text-sm-body-2 text-md-h6 ">{{ name }}</span>
          </v-list-item-title>
          <div class="mb-0 d-flex align-center mr-5" >
              <v-icon color="#0088cc" size="16" class="" >mdi-school</v-icon>
              <div class="text-caption truncated ml-2">{{ career }}</div>
          </div>
          <div class="mb-0  d-flex align-center" v-if="from.length > 3">
              <v-icon color="#0088cc" size="16" class="mr-1">mdi-map-marker</v-icon> 
              <div class="text-caption">{{ from }}</div>
          </div>

        </div>

      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CardAlumnos',
  props: {
    id:String,
    avatar: String,
    name: String,
    career: String,
    from: String,
    horizontal:{type:Boolean,default:false},
  },
};

</script>

<style lang="scss" scoped>
</style>