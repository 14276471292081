
import { Api } from '../api';
import { createUrl } from '../../helpers/uri-factory';

const url = createUrl('payment');

export class PaymentMethod extends Api {
  
  constructor(http, config = {}) {
    super(http, url, 'payment_methods', config);
  }

}