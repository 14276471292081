<template>
  <v-dialog :value="open" @input="closeDialog" max-width="600px" class="dialog">
    <v-card>
      <a href="https://bit.ly/37SY31Q" target="_blank">
        <v-img height="250" src="@/assets/firmNewPP.png"></v-img>
      </a>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FirmNewPP',
  props: {
    open: { type: Boolean, default: false },
    token: { type: String, default: null },
  },
  methods: {
    closeDialog () {
      if (this.open) {
        this.$emit('close');
        if(this.$route.path == '/reglamento/confirm'){
          this.$router.push({path: '/reglamento/confirm'})
        } else if(this.$route.path == '/dashboard'){
          this.$router.push({path: '/dashboard'});
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
  .dialog
    width: 400px
    height: 250px
</style>
