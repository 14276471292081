import api from '@/api';

const { $academic } = api;

export const { $departments, $subjects } = $academic;


// export default function ({academicDataService}, Vue) {

//     return {
//         install() {
//             Vue.prototype.$departments = this;
//         },
//         createDepartment(body) {
//             return academicDataService.post('/departments', body)
//                 .then(res => {
//                     if (res.status === 201)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getDepartment(id) {
//             return academicDataService.get(`/departments/${id}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         updateDepartment(id, body) {
//             return academicDataService.put(`/departments/${id}`, body)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getDepartments(page, length) {
//             return academicDataService.get(`/departments?page=${page}&length=${length}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         getSubjects() {
//             return academicDataService.get('/subjects')
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         deleteDepartment(id) {
//             return academicDataService.delete(`/departments/${id}`)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//         bulkDeleteDepartments(body) {
//             return academicDataService.post('/departments/bulk', body)
//                 .then(res => {
//                     if (res.status === 200)
//                         return res.data;
//                     else
//                         throw res
//                 })
//                 .then(data => {
//                     return data
//                 })
//         },
//     }
// }