<template>
  <v-container fluid class="pa-0 pb-8">
    <v-container class="container-custom" v-if="!createNewEquivalencesStatus">
      <Breadcrumbs
        :title="$t('modules.academic.equivalences.update.breadcrumbs.title')"
        :description="$t('modules.academic.equivalences.update.breadcrumbs.description')"
      ></Breadcrumbs>
      <v-row justify="center">
        <v-col class="viewSpaces" sm="12">
          <OutlinedCard
            :title="$t('modules.academic.equivalences.create.codeSection.title')"
            class="mb-6"
          >
          <br>
            <OutlinedCardContent
                :label="$t('modules.academic.equivalences.create.labels.OldSubjects')"
              ><v-row>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :label="`${$t('modules.academic.equivalences.create.labels.department')} *`"
                      :items="departmentsOld"
                      item-text="name"
                      @change="filterCleanSubjectsOld"
                      outlined
                      return-object
                      class="no-message"
                      v-model="oldDepartments"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-autocomplete
                      :error-messages="($v.selectedSubjectOld.$dirty && $v.selectedSubjectOld.$invalid) ? $t('modules.academic.equivalences.validation_errors.subject') : ''"
                      :label="`${$t('modules.academic.equivalences.create.labels.subject')} *`"
                      :items="subjectsOld"
                      item-text="description"
                      item-value="id"
                      outlined
                      return-object
                      @blur="$v.selectedSubjectOld.$touch()"
                      @change="$v.selectedSubjectOld.$touch()"
                      v-model="selectedSubjectOld"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
            </OutlinedCardContent>
            <OutlinedCardContent
                :label="$t('modules.academic.equivalences.create.labels.NewSubjects')"
              ><v-row>
                  <v-col sm="6" class="py-2">
                    <v-select
                      :label="`${$t('modules.academic.equivalences.create.labels.department')} *`"
                      :items="departmentsNew"
                      item-text="name"
                      @change="filterCleanSubjectsNew"
                      outlined
                      return-object
                      class="no-message"
                      v-model="newDepartments"
                    ></v-select>
                  </v-col>
                  <v-col sm="6" class="py-2">
                    <v-autocomplete
                      :error-messages="
                      ($v.selectedSubjectNew.$dirty && $v.selectedSubjectNew.$invalid) ?
                      (!$v.selectedSubjectNew.sameAsSelectedSubjectOld && $v.selectedSubjectNew.$model) ? 
                      $t('modules.academic.equivalences.validation_errors.differentSubjects') : $t('modules.academic.equivalences.validation_errors.subject') : ''"
                      :label="`${$t('modules.academic.equivalences.create.labels.subject')} *`"
                      :items="subjectsNew"
                      item-text="description"
                      item-value="id"
                      outlined
                      return-object
                      @blur="$v.selectedSubjectNew.$touch()"
                      @change="$v.selectedSubjectNew.$touch()"
                      v-model="selectedSubjectNew"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                </OutlinedCardContent>
          </OutlinedCard> 
          <div class="d-flex justify-end">
            <Button
              white
              :text="$t('actions.cancel')"
              @clicked="$router.replace('/academic/equivalences')"
              @end="resetButtonValues"
              class="mr-4"
            ></Button>
            <Button
              :loading="updateButton.loading"
              :success="updateButton.success"
              :error="updateButton.error"
              :text="$t('actions.save')"
              :successText="$t('actions.saved')"
              :errorText="$t('actions.error')"
              :disabled="!canUpdate"
              @end="resetButtonValues"
              @clicked="updateEquivalences"
            ></Button>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <SuccessMessage
      v-else
      :type="successMessage.type"
      :title="successMessage.title"
      :actionPrimary="successMessage.actionPrimary"
      :actionSecondary="successMessage.actionSecondary"
    />
  </v-container>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
import Button from '@/components/Button/Button';
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import {required} from 'vuelidate/lib/validators';
import {  $departments, $subjects, $equivalences } from '../Services';
import OutlinedCardContent from '@/components/OutlinedCardContent/OutlinedCardContent'


export default {
  name: 'EquivalencesUpdate',
  components: {
    Breadcrumbs,
    OutlinedCard,
    Button,
    SuccessMessage,
    OutlinedCardContent,
  },
  data() {
    return {
      departmentsOld: [],
      departmentsNew: [],
      id: '',
      oldSubject: {},
      newSubject: {},
      oldDepartments: {},
      newDepartments: {},
      departments: [],
      selectedDepartmentOld: [],
      selectedDepartmentNew: [],
      subjectsOld: [],
      subjectsNew: [],
      selectedSubjectNew: [],
      selectedSubjectOld: [],
      createNewEquivalencesStatus: false,
      updateButton: {
        loading: false,
        success: false,
        error: false
      },
      successMessage: {
        type: null,
        title: null,
        actionPrimary: null,
        actionSecondary: null
      }
    };
  },
  computed: {
    canUpdate() {
    return this.$permissions.portaladministrativo.academic.equivalences.UPDATE && this.$v.$anyDirty && !this.$v.$anyError;
    },
  },
  async mounted() {
    this.fetchDepartament();
    await this.fetchEquivalences();
  },
  methods: {
      async fetchEquivalences() {
      const equivalences = await $equivalences.findById(this.$route.params.id);
      this.oldDepartments = equivalences.data.oldSubjectId.department;
      this.oldSubject = equivalences.data.oldSubjectId
      this.newDepartments = equivalences.data.newSubjectId.department;
      this.newSubject = equivalences.data.newSubjectId
      this.selectedSubjectOld = equivalences.data.oldSubjectId;
      this.selectedSubjectNew = equivalences.data.newSubjectId;

      if (this.oldDepartments) {
        this.filterSubjectOld(this.oldDepartments).then(() => {
          [this.selectedSubjectOld] = this.subjectsOld.filter(sub => {
            return sub.id == equivalences.data.oldSubjectId.id;
          });
        });
      }
      if (this.newDepartments) {
        this.filterSubjectNew(this.newDepartments).then(() => {
          [this.selectedSubjectNew] = this.subjectsNew.filter(sub => {
            return sub.id == equivalences.data.newSubjectId.id;
          });
        });
      }
    },
      async updateEquivalences() {
      this.updateButton.loading = true;
      try {
        if  (this.$v.$invalid && !this.$v.selectedSubjectNew.sameAsSelectedSubjectOld) {
          this.$v.$touch();
          this.createNewEquivalencesStatus = false;
          this.updateButton.error = true;
          this.updateButton.loading = false;
        } else {
          const newEquivalences = {
            oldSubjectId: {id: this.selectedSubjectOld.id},
            newSubjectId: {id: this.selectedSubjectNew.id},
          };
          await $equivalences.update(this.$route.params.id, newEquivalences);
          this.successMessage.type = 'success';
          this.successMessage.title = this.$t('modules.academic.equivalences.update.success_message.title');
          this.successMessage.actionPrimary = {text: this.$t('actions.close'), callback: () => {
              this.$router.push('/academic/equivalences');
            }
          };
          this.successMessage.actionSecondary = {text: this.$t('modules.academic.equivalences.update.success_message.newChange'), callback: () => {
              this.createNewEquivalencesStatus = false;
            }
          }
          this.createNewEquivalencesStatus = true;
          this.updateButton.success = true;
          this.updateButton.loading = false;
        }
      } catch (error) {
        this.successMessage.type = 'error';
        this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.academic.equivalences.create.error_message.title');
        this.successMessage.actionPrimary = {
          text: this.$t('actions.return'), callback: () => {
            this.createNewEquivalencesStatus = false;
          }
        };
        this.successMessage.actionSecondary = null;
        this.createNewEquivalencesStatus = true;
        this.updateButton.loading = false;
        this.updateButton.error = true;
        throw error;
      }
    },
    resetButtonValues() {
      this.updateButton.loading = false;
      this.updateButton.success = false;
      this.updateButton.error = false;
    },
    filterCleanSubjectsOld(departmentOld) {
      this.filterSubjectOld(departmentOld)
      this.selectedSubjectOld = [];
    },
    filterCleanSubjectsNew(departmentNew) {
      this.filterSubjectNew(departmentNew)
      this.selectedSubjectNew = [];
    },
    async filterSubjectOld (departmentOld) {
      const subjectsOld = await $subjects.filterByDepartment(departmentOld.id);
      this.subjectsOld= subjectsOld.data;
      
    },
    async filterSubjectNew (departmentNew) {
      const subjectsNew = await $subjects.filterByDepartment(departmentNew.id);
      this.subjectsNew = subjectsNew.data;
    },
    async fetchDepartament () {
      const departments = await $departments.find();
      this.departmentsOld = JSON.parse(JSON.stringify(departments.data.content));
      this.departmentsNew = JSON.parse(JSON.stringify(departments.data.content));
    },
  },
  validations: {
    selectedSubjectOld: { required },
    selectedSubjectNew: { required, 
                        sameAsSelectedSubjectOld(value){
                         return this.selectedSubjectOld.id != value.id
      }
    },
  }
};
</script>