const reportsTranslations = {
    es: {
        table: {
            breadcrumbs: {
                title: 'Lista de reportes',
                description: 'Analisis de información'
            },
            filters: {
              term: 'Periodo'
            },
            drawer: {
              title: 'Mis reportes',
              description: 'Lista de los últimos reportes generados'
            },
            drawer_info: {
                title: 'Info reportes',
                description: 'Información detallada del contenido de este reporte',
                example:'Ejemplo'
              },
            request: {
                confirmationTitle: '¿Desea generar el reporte "{report}"?',
                successTitle: 'El reporte esta siendo generado',
                successContent: 'Puede descargarlo desde la opción "Mis reportes", puede existir una demora de hasta 10 minutos',
                error: 'Ocurrió un error generando el reporte',
                errorTime: 'Se ha generado un reporte de "{report}" en los últimos 10 minutos. \nPara generarlo nuevamente vuelva a intentar más tarde.'
            },
            no_report: 'No hay trámites disponibles',
            list: {
                admissions: {
                    title: 'Admisiones',
                    description: 'Reporte de nuevas matriculas',
                },
                exams: {
                    title: 'Exámenes',
                    description: 'Reporte de exámenes rendidos',
                },
                graduates: {
                    title: 'Graduados',
                    description: 'Reporte de graduados',
                },
                english: {
                    title: 'Inglés',
                    description: 'Reporte de Inglés',
                },
                inscriptions: {
                    title: 'Inscripciones',
                    description: 'Reporte de inscripciones a asignaturas',
                },
                dueAccounts: {
                    title: 'Oficina Cobranza',
                    description: 'Reportes para cobranzas',
                },
                levels: {
                    title: 'Niveles',
                    description: 'Reporte de avance académico',
                },
                studentAccount: {
                    title: 'Pagos',
                    description: 'Reporte de cuenta corriente de estudiantes',
                },
                reservations: {
                    title: 'Pedidos de examen',
                    description: 'Reporte de pedidos de examen',
                },
                reenrollments: {
                    title: 'Rematriculas',
                    description: 'Reporte de rematriculas realizadas',
                },
                nonreservations: {
                    title: 'Sin pedidos de examen',
                    description: 'Reporte de estudiantes sin pedidos de examen',
                },
                titleds: {
                    title: 'Titulados',
                    description: 'Reporte de estudiantes titulados',
                },
                processings: {
                    title: 'Trámites',
                    description: 'Reporte de trámites solicitados',
                },
            },
            dropdown: {
              headers: {
                header1: 'Cursando',
                header2: 'Prácticas Profesionales',
                header3: 'On Boarding',
                header4: 'Nivelación'
              }
            }
        },
        download: {
            headers: {
                name: 'Nombre',
                date: 'Fecha',
                period: 'Período',
                status: 'Estado'
              },
            drawer: {
                title: 'Reportes',
                description: 'Aquí podrás descargar los reportes solicitados. Ten en cuenta que solo estarán displonibles el día en que fueron generados y solo apareceran en la lista una vez que finalice su generación, esto puede llevar varios minutos.'
            },
            fileName: 'Reporte {reportName}.xlsx',
            error: 'Ocurrió un error descargando el reporte'
        },
    },
    en: {

    }
};

export default reportsTranslations