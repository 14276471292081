
export default {
    methods: {
        $paymentsIdToArray(items) {
            let intencion = ''
            items.forEach((payment, index) => {
                if (index == 0) intencion += payment.id
                else intencion += `-${payment.id}`
            })
            return intencion
        }
    }
}