
const discountsTranslations = {
  es: {

    enabled: 'Habilitado',
    disabled: 'Deshabilitado',

    table: {
      breadcrumbs: {
        title: 'Descuentos',
        description: 'Administración de descuentos.'
      },
      headers: {
        code: 'Código',
        name: 'Nombre',
        product_type: 'Tipo de producto',
        status: 'Estado',
      },
      bulk_delete: 'Eliminar descuentos',
      bulk_delete_error: 'No se pudieron eliminar los descuentos seleccionados',
      delete_error: 'No se pudo eliminar el descuento seleccionado',
    },
    create: {

      breadcrumbs: {
        title: 'Nuevo descuento',
        description: 'Crea un nuevo descuento.'
      },
      labels: {
        name: 'Nombre',
        code: 'Código',
      },
      validation_errors: {
        name: 'Ingresa el nombre del descuento',
        code: 'Ingresa el código del descuento',
        code_unique: 'Código ya existente',
        limit: 'Ingresa la cantidad límite de estudiantes',
        region: 'Completa todos los campos',
      },
      success_message: {
        title: 'Descuento creado correctamente',
        actions: {
          primary_text: 'Cerrar',
          secondary_text: 'Crear otro',
          back: 'Volver',
        },
      },
      error_message: {
        title: 'El descuento no pudo crearse',
      },
      section_name: {
        title: 'Nombre',
        subtitle: 'Selecciona un nombre para el nuevo descuento que estás creando.',
      },
      section_career: {
        title: 'Carrera',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el descuento.',
        transferList: {
          'firstTitle': 'Disponibles',
          'secondTitle': 'Seleccionadas'
        },
        no_selected_warning: 'Debes seleccionar al menos una carrera',
      },
      section_student: {
        title: 'Tipo de estudiante',
        error: 'Debes seleccionar al menos un tipo de estudiante',
        items: {
          'checkboxFirst': 'Nuevo ingresante',
          'checkboxSecond': 'Rematriculado',
          'checkboxThird': 'Otro',
        }
      },
      section_modality: {
        title: 'Modalidad',
        error: 'Debes seleccionar al menos un tipo de modalidad',
        items: {
          'checkboxFirst': 'Online plus',
          'checkboxSecond': 'Presencial'
        }
      },
      section_zone: {
        title: 'Regiones y descuentos',
        button: 'Crear nueva región',
        drawer: {
          title: 'Crea una nueva región',
          description: '',
        },
        discountTypes: [
          { text: 'Monto', value: 1 },
          { text: 'Porcentaje', value: 2 }
        ],
        items: {
          'product': 'Producto',
          'zone': 'Región',
          'from': 'Desde',
          'to': 'Hasta',
          'discountType': 'Tipo de descuento',
          'semester': 'Semestre',
          'bimester': 'Bimestre',
        }
      },
      section_restriction: {
        title: 'Restricciones',
        items: {
          'radioFirst': 'Sin restricciones',
          'radioSecond': 'Límite de estudiantes'
        }
      }
    },
    update: {
      breadcrumbs: {
        title: 'Editar descuento',
        description: 'Descuentos disponibles en el sistema.'
      },
      labels: {
        name: 'Nombre',
        code: 'Código',
        studentType: 'Tipo de estudiante',
        modality: 'Modalidad',
      },
      validation_errors: {
        name: 'Ingresa el nombre del descuento',
        limit: 'Ingresa la cantidad límite de estudiantes',
        region: 'Completa todos los campos',
      },
      success_message: {
        title: 'Descuento modificado correctamente',
        actions: {
          primary_text: 'Cerrar',
          back: 'Volver',
        },
      },
      error_message: {
        title: 'El descuento no pudo modificarse',
      },
      section_name: {
        title: 'Datos',
        switchLabel: 'Habilitado'
      },
      section_career: {
        title: 'Carrera',
        subtitle: 'Selecciona las carreras a las que quiere asignarles el descuento.',
        transferList: {
          'firstTitle': 'Disponibles',
          'secondTitle': 'Seleccionadas'
        },
        no_selected_warning: 'Debes seleccionar al menos una carrera',
      },
      section_student: {
        title: 'Tipo de estudiante',
        error: 'Debes seleccionar al menos un tipo de estudiante',
        items: {
          'checkboxFirst': 'Nuevo ingresante',
          'checkboxSecond': 'Rematriculado',
          'checkboxThird': 'Otro',
        }
      },
      section_modality: {
        title: 'Modalidad',
        error: 'Debes seleccionar al menos un tipo de modalidad',
        items: {
          'checkboxFirst': 'Online plus',
          'checkboxSecond': 'Presencial'
        }
      },
      section_zone: {
        title: 'Regiones y descuentos',
        button: 'Crear nueva región',
        drawer: {
          title: 'Crea una nueva región',
          description: '',
        },
        discountTypes: [
          { text: 'Monto', value: 1 },
          { text: 'Porcentaje', value: 2 }
        ],
        items: {
          'product': 'Productos',
          'zone': 'Región',
          'from': 'Desde',
          'to': 'Hasta',
          'discountType': 'Tipo de descuento',
          'semester': 'Semestre',
          'bimester': 'Bimestre',
        }
      },
      section_restriction: {
        title: 'Restricciones',
        items: {
          'radioFirst': 'Sin restricciones',
          'radioSecond': 'Límite de estudiantes'
        }
      }
    },
    detail: {
      breadcrumbs: {
        title: 'Detalle del descuento',
        button: {
          icon: 'mdi-pencil',
          name: 'Editar',
          color: 'primary'
        }
      },
      section_data: {
        title: 'Datos',
        items: {
          'name': 'Nombre',
          'code': 'Código',
          'status': 'Estado',
          'studentType': 'Tipo de estudiante',
          'modality': 'Modalidad',
          'careers': 'Carreras'
        }
      },
      section_zone: {
        title: 'Carrera',
        items: {
          'product': 'Productos',
          'zone': 'Región',
          'from': 'Desde',
          'to': 'Hasta',
          'semester': 'Porcentaje semestre',
          'bimester': 'Porcentaje bimestre',
        },
      },
      section_restriction: {
        title: 'Restricciones',
        items: {
          'limit': 'Límite de estudiantes'
        }
      }
    },

  },
  en: {



  }
};


export default discountsTranslations
