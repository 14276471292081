<template>
    <v-container fluid class="pa-0 pb-8">
        <v-container class="container-custom" v-if="!createNewRangeStatus">
            <Breadcrumbs
                :title="$t('modules.commissions.ranges.create.breadcrumbs.title')"
                :description="$t('modules.commissions.ranges.create.breadcrumbs.description')"
            ></Breadcrumbs>
            <v-row justify="center">
                <v-col class="viewSpaces" sm="12">
                    <OutlinedCard
                        :title="$t('modules.commissions.ranges.create.data.title')"
                        :subtitle="$t('modules.commissions.ranges.create.data.subtitle')"
                        class="mb-6"
                    >
                        <v-row>
                            <v-col sm="4">
                                <v-autocomplete
                                    v-model="$v.term.$model"
                                    :error-messages="($v.term.$dirty && $v.term.$invalid) ? $t('modules.commissions.ranges.create.validations.term') : ''"
                                    :label="`${$t('modules.commissions.ranges.create.data.period')} *`"
                                    :items="terms"
                                    @blur="$v.term.$touch()"
                                    outlined
                                    item-text="code"
                                    item-value="id"
                                    return-object
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ data.item.code }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ `${$t('modules.commissions.ranges.create.from')} ${data.item.startDate} ${$t('modules.commissions.ranges.create.to')} ${data.item.endDate}` }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="term">
                                <MultipleFormGroup
                                    :items="$v.ranges.$each.$iter"
                                    :length="$v.ranges.$model.length"
                                    @removeGroup="removeGroup"
                                    @addGroup="addGroup"
                                >
                                    <template v-slot:default="{item, index}">
                                        <v-container fluid>
                                            <v-row>
                                                <v-col sm="8">
                                                    <v-text-field
                                                        v-model="item.description.$model"
                                                        :error-messages="(item.description.$invalid && item.description.$dirty) ? $t('modules.commissions.ranges.create.validations.description') : ''"
                                                        :label="`${$t('modules.commissions.ranges.create.data.description')} *`"
                                                        @blur="item.description.$touch()"
                                                        outlined
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col sm="4">
                                                    <v-menu
                                                        :ref="`${index}-menu_from`"
                                                        v-model="item.$model.menu_from"
                                                        :return-value.sync="item.from.$model"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        bottom
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                :value="item.from.$model"
                                                                :label="`${$t('modules.commissions.ranges.create.data.from')} *`"
                                                                :error-messages="item.from.$invalid && item.from.$dirty ? 'Error' : ''"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                outlined
                                                                v-on="on"
                                                                hide-details
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="item.from.$model"
                                                            color="primary"
                                                            scrollable
                                                            no-title
                                                            :min="term.minDate"
                                                            :max="item.to.$model ? item.to.$model : term.maxDate"
                                                            @input="$refs[`${index}-menu_from`].save(item.from.$model)"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col sm="4">
                                                    <v-menu
                                                        :ref="`${index}-menu_to`"
                                                        v-model="item.$model.menu_to"
                                                        :return-value.sync="item.to.$model"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        bottom
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                :error-messages="item.to.$invalid && item.to.$dirty ? 'Error' : ''"
                                                                :value="item.to.$model"
                                                                :label="`${$t('modules.commissions.ranges.create.data.to')} *`"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                outlined
                                                                v-on="on"
                                                                hide-details
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="item.to.$model"
                                                            color="primary"
                                                            scrollable
                                                            no-title
                                                            :min="item.from.$model ? item.from.$model : term.minDate"
                                                            :max="term.maxDate"
                                                            @input="$refs[`${index}-menu_to`].save(item.to.$model)"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </MultipleFormGroup>
                            </v-col>
                        </v-row>
                    </OutlinedCard>
                    
                    <div class="d-flex justify-end">
                        <Button
                            white
                            :text="$t('actions.cancel')"
                            @clicked="$router.replace('/commissions/ranges')"
                            @end="resetButtonValues"
                            class="mr-4"
                        ></Button>

                        <Button
                            :loading="createButton.loading"
                            :success="createButton.success"
                            :error="createButton.error"
                            :text="$t('actions.create')"
                            :successText="$t('actions.created')"
                            :errorText="'Error'"
                            :disabled="!canCreate || !$v.ranges.$anyDirty || $v.ranges.$anyError"
                            @end="resetButtonValues"
                            @clicked="createRange"
                        ></Button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <SuccessMessage
            v-else
            :type="successMessage.type"
            :title="successMessage.title"
            :actionPrimary="successMessage.actionPrimary"
            :actionSecondary="successMessage.actionSecondary"
        />
    </v-container>
</template>

<script>
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import OutlinedCard from '@/components/OutlinedCard/OutlinedCard';
    import MultipleFormGroup from '@/components/MultipleFormGroup/MultipleFormGroup';
    import Button from '@/components/Button/Button';
    import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
    import { required } from 'vuelidate/lib/validators';
    import { $range, $terms } from '../Services';
    import moment from 'moment';

    export default {
        name: 'RangesCreate',
        components: {
            Breadcrumbs,
            OutlinedCard,
            MultipleFormGroup,
            Button,
            SuccessMessage,
        },
        data() {
            return {
                createNewRangeStatus: false,
                createButton: {
                    loading: false,
                    success: false,
                    error: false
                },
                successMessage: {
                    type: null,
                    title: null,
                    actionPrimary: null,
                    actionSecondary: null
                },
                terms: [],
                term: null,
                today: new Date().toISOString().substr(0, 10),
                ranges: [
                    {
                        description: null,
                        menu_from: false,
                        menu_to: false,
                        from: null,
                        to: null,
                    }
                ],
            }
        },
        computed: {
            canCreate () {
                return this.$permissions.portaladministrativo.commissions.ranges.CREATE
            },
        },
        mounted () {
            this.getTerms()
        },
        methods: {
            async getTerms() {
                try {
                    const terms = await $terms.find(null, null, {params: {page: 0, length: 20, orderBy: 'saleDateFrom', orientation: 'desc'}});
                    this.terms = terms.data.content.map(term => {
                        term.minDate = new Date(term.saleDateFrom).toISOString().substr(0, 10)
                        term.maxDate = new Date(term.saleDateTo).toISOString().substr(0, 10)
                        term.startDate = moment(term.saleDateFrom).format('DD/MM')
                        term.endDate = moment(term.saleDateTo).format('DD/MM')
                        return term
                    })
                } catch (error) {
                    this.terms = []
                    this.term = null
                    throw error
                }
            },
            addGroup () {
                this.ranges.push({
                    description: null,
                    menu_from: false,
                    menu_to: false,
                    from: null,
                    to: null,
                });
            },
            removeGroup (index) {
                this.ranges.splice(index, 1)
            },
            async createRange() {
                if (!this.canCreate) return false
                this.createButton.loading = true;

                const newRange = this.ranges.map(range => {
                    return {
                        description: range.description,
                        startDate: range.from,
                        endDate: range.to,
                    }
                });

                try {
                    await $range.create(newRange, {}, this.term.id);
                    this.successMessage.type = 'success';
                    this.successMessage.title = this.$t('modules.commissions.ranges.create.success_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.close'), callback: () => { this.$router.push('/commissions/ranges') } };
                    this.successMessage.actionSecondary = { text: this.$t('actions.create_another'), callback: () => { this.createNewRangeStatus = false } };

                    this.createNewRangeStatus = true;
                    this.createButton.success = true;
                    this.clearData();
                } catch(error) {
                    this.successMessage.type = 'error';
                    this.successMessage.title = (error.codeMeaning != '') ? error.codeMeaning : this.$t('modules.commissions.ranges.create.error_message.title');
                    this.successMessage.actionPrimary = { text: this.$t('actions.return'), callback: () => { this.createNewRangeStatus = false; }}
                    this.successMessage.actionSecondary = null;

                    this.createNewRangeStatus = true;
                    this.createButton.loading = false;
                    this.createButton.error = true;
                }
            },
            clearData () {
                this.resetButtonValues();
                this.ranges = []
                this.term = null
                this.addGroup();
                this.$v.$reset();
            },
            resetButtonValues () {
                this.createButton.loading = false;
                this.createButton.success = false;
                this.createButton.error = false;
            },
        },
        validations: {
            term: { required },
            ranges: {
                $each: {
                    description: { required },
                    from: {
                        required,
                        minDate(value) { return this.term ? new Date(value).toISOString() >= new Date(this.term.minDate).toISOString() : false },
                        maxDate(value) { return this.term ? new Date(value).toISOString() <= new Date(this.term.maxDate).toISOString() : false }
                    },
                    to: {
                        required,
                        minDate(value) { return this.term ? new Date(value).toISOString() >= new Date(this.term.minDate).toISOString() : false },
                        maxDate(value) { return this.term ? new Date(value).toISOString() <= new Date(this.term.maxDate).toISOString() : false }
                    },
                }
            },
        }
    }
</script>