<template>
    <v-container fluid class="d-flex pa-0 fullHeight">
        <v-container fluid class="scrollableContent pa-0">
            <v-container class="container-custom px-6">
                <Breadcrumbs
                    :title="$t('modules.exceptions.table.breadcrumbs.title')"
                    :description="$t('modules.exceptions.table.breadcrumbs.description')"
                ></Breadcrumbs>
                <v-row justify="center">
                    <v-col sm="12" class="viewSpaces">
                        <SuperTable
                            :headers="headers"
                            :data="users"
                            :permissions="{ debt_inscription: canDebtInscription, confirm_preregistration: canConfirmPreregistration, revert_enrollment: canRevertEnrollment, change_modality: canChangeModality, payment_adjustment: canAdjustPayments, allow_reenrollment: canAllowReenrollment }"
                            @search-action="searchAction"
                            @confirm-action="confirmationPopUp"
                        ></SuperTable>
                    </v-col>
                </v-row>

                <DocumentUploadPopup
                    v-if="student.record"
                    :modalOpen="documentPopupOpen"
                    :student="student"
                    @success="confirmAction('confirmPreregistration', '')"
                    @cancel="() => {this.documentPopupOpen = false; this.student.record = null; this.student.name = null}"
                ></DocumentUploadPopup>

            </v-container>
        </v-container>
    </v-container>
</template>

<script>
    import SuperTable from '../Components/SuperTable/SuperTable';
    import DocumentUploadPopup from '../Components/DocumentUploadPopup/DocumentUploadPopup';
    import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
    import { $students, $exceptions } from '../Services';
    import { mapGetters } from 'vuex'

    export default {
        name: 'ExceptionsTable',
        components: {
            SuperTable,
            Breadcrumbs,
            DocumentUploadPopup,
        },
        data () {
            return {
                documentPopupOpen: false,
                student: {
                    name: null,
                    record: null,
                },
                search: '',
                users: [],
                headers: [
                    {
                        text: this.$t('modules.exceptions.table.headers.identification'),
                        value: 'identification',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.name'),
                        value: 'name'
                    },{
                        text: this.$t('modules.exceptions.table.headers.lastname'),
                        value: 'lastname',
                    },{
                        text: this.$t('modules.exceptions.table.headers.career'),
                        value: 'career',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.career_status'),
                        value: 'careerStatus.meaning',
                        sortable: false
                    },{
                        text: this.$t('modules.exceptions.table.headers.term'),
                        value: 'term',
                        sortable: false,
                        align:'center',
                    },{
                        text: '',
                        value: 'action',
                        sortable: false
                    }
                ],
            }
        },
        methods: {
            searchAction(data) {
                this.search = data;
                this.getStudent();
            },
            confirmationPopUp (action, item) {
                if (action == 'confirmPreregistration') {
                    this.documentPopupOpen = true
                    this.student.name = `${item.identification}-${item.name} ${item.lastname}`
                    this.student.record = item.id
                } else if (action == 'paymentAdjustment') {
                    this.$router.push({path: `exceptions/payments/${item.id}`, query: {studentId: item.studentId}});
                } else {
                    this.$store.dispatch('commons/openPopUp', {
                        title: this.$t('modules.exceptions.table.actions.confirmation_title'),
                        content: [{ value: `${item.identification}-${item.name} ${item.lastname}` }],
                        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.confirmAction(action, item.id)},
                        actionSecondary: { text: this.$t('actions.cancel'), callback () { } },
                        icon: { color: 'warning', name: 'mdi-exclamation' },
                        color: 'primary',
                    })
                }
            },
            async confirmAction(action, studentRecord) {
                const params={studentCareer:studentRecord,user:this.userId}
                try {
                    if (action == 'debtInscription') await $exceptions.debtInscription(studentRecord)
                    else if (action == 'revertEnrollment') await $exceptions.revertEnrollment({params})
                    else if (action == 'changeModality') await $exceptions.changeModality('changeOldModality',{params: {studentCareer: `${studentRecord}`, user: `${this.userId ? this.userId : null}`}})
                    else if (action == 'allowReenrollment') await $exceptions.allowReenrollment(studentRecord)
                    else if (action == 'confirmPreregistration') {
                        this.documentPopupOpen = false;
                        this.student.name = null
                        this.student.record = null
                    }
                    this.successMessage()
                } catch (error) {
                    this.errorMessage(error)
                } finally {
                    this.searchAction(this.search)
                }
            },
            successMessage() {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.success'),
                    content: [{ value: this.$t('modules.exceptions.table.actions.success') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                    icon: { color: 'success', name: 'mdi-check' },
                    color: 'primary',
                })
            },
            errorMessage(error) {
                this.$store.dispatch('commons/openPopUp', {
                    title: this.$t('actions.error'),
                    content: [{ value: error.codeMeaning != '' ? error.codeMeaning : this.$t('modules.exceptions.table.actions.error') }],
                    actionPrimary: { text: this.$t('actions.accept'), callback() {} },
                    icon: { color: 'error', name: 'mdi-close' },
                    color: 'primary',
                })
            },
            async getStudent() {
                try {
                    const student = await $students.find('studentCareersByIdentification', null, {params: { identification: this.search }})
                    this.users = []
                    if (student.data.studentCareers.length) {
                        student.data.studentCareers.forEach(career => {
                            this.users.push({
                                id: career.studentCareer,
                                name: student.data.name,
                                lastname: student.data.lastname,
                                identification: student.data.identification,
                                career: career.careerName,
                                careerStatus: career.careerStatus,
                                term: career.termValidCode,
                                termId: career.termValidId,
                                exception: career.exception,
                                oldModality: career.oldModality,
                                allowReenrollment: career.allowReenrollment,
                                studentId: student.data.studentId
                            })
                        })
                    }
                } catch (error) {
                    this.users = [];
                }
            }
        },
        computed: {
            canDebtInscription() {
                return this.$permissions.portaladministrativo.exceptions.DEBT_INSCRIPTION
            },
            canAdjustPayments() {
                return this.$permissions.portaladministrativo.exceptions.PAYMENT_ADJUSTMENT
            },
            canConfirmPreregistration() {
                return this.$permissions.portaladministrativo.exceptions.CONFIRM_PREREGISTRATION
            },
            canRevertEnrollment() {
                return this.$permissions.portaladministrativo.exceptions.REVERT_ENROLLMENT
            },
            canChangeModality() {
                return this.$permissions.portaladministrativo.exceptions.CHANGE_MODALITY
            },
            canAllowReenrollment() {
                return this.$permissions.portaladministrativo.exceptions.ALLOW_REENROLLMENT
            },
            ...mapGetters({
                userId: 'commons/getUserID'
            })
        },
    }
</script>
