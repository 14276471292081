import StudentSubjects from '../StudentSubjects.vue'
import StudentSubjectsTable from '../Views/StudentSubjectsTable.vue'
import StudentSubjectsCreate from '../Views/StudentSubjectsCreate.vue'
import EnrollPractice from '../Views/EnrollPractice.vue'

const ExceptionsRoute = {
    path: '/student-subjects',
    meta: {
        name: {
            en: 'Student subjects',
            es: 'Asignaturas del estudiante'
        }
    },
    component: StudentSubjects,
    children: [
        {
            path: '/',
            component: StudentSubjectsTable,
            name: 'student_subjects_table',
            meta: { name: 'Index', group: 'portaladministrativo.students', path: 'subjects', permission: 'READ' }
        },{
            path: 'create/:id',
            component: StudentSubjectsCreate,
            name: 'student_subjects_create',
            meta: { name: 'Enroll subjects', group: 'portaladministrativo.students', path: 'subjects', permission: 'CREATE' }
        },{
            path: 'create/:id/practice',
            component: EnrollPractice,
            name: 'enroll_practice',
            meta: { name: 'Enroll practice', group: 'portaladministrativo.students', path: 'subjects', permission: 'CREATE' }
        }
    ]
};

export default ExceptionsRoute
