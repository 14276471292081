<template>
  <div>
    <v-container fluid class="d-flex pa-0 fullHeight">
      <v-container class="container-custom px-6">
        <Breadcrumbs
          :description="breadcrumbs.description"
          :title="breadcrumbs.title">
          <Button
            v-if="canCreate"
            @clicked="newZone"
            :icon="breadcrumbs.button.icon"
            :text="breadcrumbs.button.name"
            depressed
          ></Button>
        </Breadcrumbs>
        <v-row justify="center">
          <v-col sm="12" class="viewSpaces">
            <SuperTable
              :headers="headers"
              :users="zones"
              :clear-selected="clearSelected"
              :permissions="{update: canUpdate, delete: canDelete}"
              @bulk-delete="(zones)=>{bulkDeletePopUp(zones)}"
              @deleteUser="(id)=>deleteZonePopUp(id)"
              @updateUser="(id)=>{updateZone(id)}"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>
<script>

import SuperTable from '../components/SuperTable/SuperTable';
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs';
import Button from '@/components/Button/Button';
import { $zones } from '../Services';

export default {
  name: 'ZonesTable',
  components: {
    SuperTable,
    Breadcrumbs,
    Button
  },
  data () {
    return {
      breadcrumbs: {
        title: this.$t('modules.zones.table.breadcrumbs.title'),
        description: this.$t('modules.zones.table.breadcrumbs.description'),
        button: {
          name: this.$t('actions.new_female'),
          icon: 'mdi-plus',
          color: 'primary'
        },
      },
      headers: [
        { text: this.$t('modules.zones.table.headers.name'), value: 'name' },
        // { text: this.$t('modules.zones.table.headers.ceos'), value: 'ceos' },   // :TODO - Fix zonas, borrar linea al subir cambios
        { text: this.$t('modules.zones.table.headers.cities'), value: 'cities' },   // :TODO - Fix zonas, descomentar linea al subir cambios
        { text: this.$t('modules.zones.table.headers.status'), value: 'status' },
        { text: '', value: 'action' }
      ],
      zones: [],
      clearSelected: '0',
    }
  },
  mounted () {
    this.fetchData()
  },
  computed: {
    canCreate() {
      return this.$permissions.portaladministrativo.prices.zones.CREATE
    },
    canUpdate() {
      return this.$permissions.portaladministrativo.prices.zones.READ || this.$permissions.portaladministrativo.prices.zones.UPDATE
    },
    canDelete() {
      return this.$permissions.portaladministrativo.prices.zones.DELETE
    },
  },
  methods: {
    deleteZonePopUp (id) {
      const zone = this.zones.find(zone => zone.id == id);
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: [{ value: zone.name }],
        actionPrimary: { text: 'Aceptar', callback: () => this.deleteZone(id) },
        actionSecondary: { text: 'Cancelar', callback() {} },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    fetchData () {
      this.clearSelected = new Date().getMilliseconds().toString();
      $zones.find(null, null, { params: { page: 0, length: 500, orderBy: 'id', orientation: 'desc' } }).then(res => {
        const data = res.data.content.map(zone => {
          zone.status = zone.status == undefined ? true : zone.status;
          zone.selected = false;
          // zone.ceos = zone.ceosQuantity;   // :TODO - Fix zonas, borrar linea al subir cambios
          zone.cities = zone.citiesQuantity;   // :TODO - Fix zonas, descomentar linea al subir cambios
          zone.name = zone.zoneDescription;
          return zone;
        });
        this.zones = data;
      })
    },
    bulkDeletePopUp (zones) {
      this.$store.dispatch('commons/openPopUp', {
        title: this.$t('delete_popup_title'),
        content: zones.map(zone => ({ value: zone.name })),
        actionPrimary: { text: this.$t('actions.accept'), callback: () => this.bulkDelete(zones) },
        actionSecondary: { text: this.$t('actions.cancel'), callback() {} },
        icon: { color: 'error', name: 'mdi-delete' },
        color: 'primary',
      });
    },
    async bulkDelete (zones) {
      const itemsForDelete = zones.map((zone) => {
        return { id: zone.id }
      });
      try {
        const zonesItems = await $zones.bulkDelete(itemsForDelete)
        if (zonesItems) {
          await this.fetchData()
        }
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{value: this.$t('modules.zones.table.bulk_delete_error')}],
          actionPrimary: {
            text: this.$t('actions.accept'),
            callback: () => {
              this.fetchData()
            }
          },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        });
        throw error;
      }
    },
    newZone () {
      if (this.canCreate) this.$router.push('zones/create')
    },
    updateZone (id) {
      if (this.canUpdate) this.$router.push(`zones/update/${id}`)
    },
    async deleteZone (id) {
      try {
        await $zones.delete(id);
        this.zones = this.zones.filter(zone => zone.id !== id);
      } catch (error) {
        this.$store.dispatch('commons/openPopUp', {
          title: 'Error',
          content: [{value: this.$t('modules.zones.table.delete_error')}],
          actionPrimary: { text: this.$t('actions.accept'), callback() {} },
          icon: { color: 'error', name: 'mdi-delete' },
          color: 'primary',
        });
        throw error
      }
    }
  }
}
</script>
