<template>
  <v-dialog :value="open" @input="closeDialog" max-width="660px">
    <v-card v-if="!response" style="padding:40px;">
      <div class="alertPopup mx-auto mb-5">
          <div class="circle text-center mx-auto">
            <v-icon color="white" size="60" class="icon">mdi-eye-off-outline</v-icon>
          </div>
      </div>
        <h1 class="primary--text headline text-center" style="">¿Desea generar el reporte "{{report ? report.name : ''}}"? </h1>
        <div v-if="requiredTerms">
          <h1 class="primary--text headline text-center" style="font-size:22px;color:rgba(0, 0, 0, 0.6); margin-bottom:20px">Debe seleccionar un periodo:</h1>
          <v-autocomplete
              v-model="periodSelected"
              :items="periods"
              item-text="description"
              item-value="id"
              return-object
              label='Periodo'
              outlined
              @blur="$v.periodSelected.$touch()"
              :error-messages="$v.periodSelected.$anyError ? $t('modules.academic.periods.create.errors.required') : ''"
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.description"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.code"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </div>
        <v-row class="justify-center pt-8">
            <Button
                class="mx-8"
                :text="$t('actions.close')"
                white
                @clicked="closeDialog"
            ></Button>
            <Button
                class="mx-8"
                :text="$t('actions.confirm')"
                style="background-color:#106ca7;border-radius:38px"
                @clicked="saveSelectionPeriod"
                :loading="createButton.loading"
                :success="createButton.success"
                :error="createButton.error"
                :successText="$t('actions.created')"
                :errorText="'Error'"
                @end="resetButtonValues"
            ></Button>
        </v-row>
    </v-card>
    <SuccessMessage 
        v-else
        class="white px-10"
        :type="successMessage.type"
        :title="successMessage.title"
        :actionPrimary="successMessage.actionPrimary"
        :fullWidth="true"
      >
      <h2 class="text-center font-weight-regular">{{successMessage.text}}</h2>
    </SuccessMessage>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button/Button';
import {requiredIf } from 'vuelidate/lib/validators'
import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
export default {
  name: 'RequestReportPopUp',
  props: {
    open: { type: Boolean, default: false },
    periods: { type: Array },
    report: {type: Object},
    successMessage: {type: Object},
    response: {type: Boolean, default: false},
    requiredTerms: {type: Boolean, default: true},
    createButton: {type:Object}
  },
  components: {
      Button,
      SuccessMessage
  },
  data(){
      return {
        periodSelected: null,
      }
  },
  mounted(){
  },
  methods: {
    closeDialog () {
      if (this.open) {
        this.$emit('close');
        this.$v.$reset()
        }
    },
    saveSelectionPeriod(){
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        const objeto = {
          report: this.report,
          term: this.periodSelected
        }
        this.$emit('requestReport', objeto)
        this.resetButtonValues()
      }
    },
    resetButtonValues () {
      this.createButton.success = false;
      this.createButton.error = false;
      setTimeout(() => {
        this.periodSelected = null
        this.$v.$reset()
      }, 1000)
    },
  },
  validations: {
      periodSelected: { required: requiredIf(function () { return this.requiredTerms}) }
  }
}
</script>

<style lang="sass" scoped>
.dialog
  width: 400px
  height: 300px

.headline
  font-size: 1.5rem !important
  font-weight: 400
  line-height: 2rem
  letter-spacing: normal !important
  font-family: "Roboto", sans-serif !important

.alertPopup
  width: 160px

.circle
  border-radius: 50% !important
  background-color: #ffff9e

.icon
  background-color: #fb8c00
  margin: 35px
  padding: 15px
  position: relative
  border-radius: 50%
</style>