var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"container-custom fullHeight",attrs:{"fluid":""}},[_c('Breadcrumbs',{attrs:{"title":_vm.$t('modules.exams_group.statements.programsUpdate.breadcrumbs.title')}}),(!_vm.createNewProgram)?_c('v-container',{staticClass:"container-custom px-6"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"viewSpaces",attrs:{"sm":"12"}},[_c('OutlinedCard',{staticClass:"mb-6",attrs:{"title":_vm.$t('modules.exams_group.statements.programsCreate.programName'),"switch-value":_vm.status,"switch-label":(_vm.status) ? _vm.$t('enabled') : _vm.$t('disabled'),"switcher":""},on:{"switchChange":_vm.changeStatus}},[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('modules.exams_group.statements.programsCreate.insertName')}*`,"outlined":"","error-messages":(_vm.$v.program.description.$dirty && _vm.$v.program.description.$invalid) ?
                    ((!_vm.$v.program.description.required) ? _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.descriptionRequired') :
                    (!_vm.$v.program.description.alphaNumWithSpaces) ? _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.descriptionAlphanumericWithSpaces') :
                    _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.descriptionMaxLength')) :
                    ''},on:{"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.program.description.$model,60, true)},"blur":function($event){return _vm.$v.program.description.$touch()}},model:{value:(_vm.$v.program.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.program.description, "$model", $$v)},expression:"$v.program.description.$model"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"label":`${_vm.$t('modules.exams_group.statements.programsCreate.code')} *`,"outlined":"","error-messages":(_vm.$v.program.code.$dirty && _vm.$v.program.code.$invalid) ?
                      ((!_vm.$v.program.code.required) ? _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.codeRequired') :
                      (!_vm.$v.program.code.maxLength) ? _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.codeMaxLength') :
                      _vm.$t('modules.exams_group.statements.programsCreate.validation_errors.codeAlphanumeric')) :
                      ''},on:{"keypress":function($event){return _vm.$validateAlphanumeric($event, _vm.$v.program.code.$model,10)},"blur":function($event){return _vm.$v.program.code.$touch()}},model:{value:(_vm.$v.program.code.$model),callback:function ($$v) {_vm.$set(_vm.$v.program.code, "$model", $$v)},expression:"$v.program.code.$model"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Button',{staticClass:"ml-4",attrs:{"depressed":"","white":"","text":_vm.$t('modules.exams_group.statements.programsCreate.cancel')},on:{"clicked":function($event){return _vm.$router.go(-1)}}}),_c('Button',{staticClass:"ml-4",attrs:{"loading":_vm.createButton.loading,"success":_vm.createButton.success,"error":_vm.createButton.error,"depressed":"","text":_vm.$t('modules.exams_group.statements.programsUpdate.save'),"successText":_vm.$t('actions.created'),"errorText":_vm.$t('actions.error')},on:{"end":function($event){return _vm.resetButtonValues()},"clicked":_vm.updateProgramPopUp}})],1)],1):_c('SuccessMessage',{attrs:{"type":_vm.successMessage.type,"title":_vm.successMessage.title,"actionPrimary":_vm.successMessage.actionPrimary,"actionSecondary":_vm.successMessage.actionSecondary}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }